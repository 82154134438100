<template>
  <v-dialog v-model="show" persistent width="1200" transition="fade-transition">
    <ValidationObserver ref="obs">
      <v-card v-if="objectCopy">
        <v-card-title class="d-flex">
          <v-icon class="mr-2">{{ isGroup ? 'mdi-label-multiple' : 'mdi-label' }}</v-icon> {{ $t(title) }}
          <v-spacer />
          <v-btn icon @click="closeObjectDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <name-description
            :name.sync="objectCopy.Name"
            :description.sync="objectCopy.Description"
            class="mb-4"
            :name-label="$t(isGroup ? 'group_name' : 'object_name')"
            :description-label="$t(isGroup ? 'group_description' : 'object_description')"
          />

          <component :is="component" v-if="component" :type="objectCopy.Type" :settings="objectCopy.PolicyJson">
            <!-- also making use of the action slot to save the object new/updated values -->
            <template #actions="{ newSettings, disabled }">
              <div class="d-flex flex-row justify-end mt-4">
                <v-btn text class="text-capitalize mr-2" @click="closeObjectDialog">{{ $t('cancel') }}</v-btn>
                <v-btn
                  :disabled="disabled"
                  depressed
                  class="text-capitalize"
                  color="primary"
                  @click="onSave(newSettings)"
                >
                  <v-icon small class="mr-2">mdi-content-save</v-icon>
                  {{ $t('save') }}
                </v-btn>
              </div>
            </template>
          </component>
        </v-card-text>
        <v-overlay v-model="fetching" absolute class="text-center">
          <v-progress-circular indeterminate size="32" color="aristaMediumBlue"></v-progress-circular>
        </v-overlay>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { mapState, mapActions } from 'vuex'
  import { objectsConfig } from 'vuntangle/pm'
  import NameDescription from '../components/NameDescription.vue'
  import {
    ObjectApplication,
    ObjectGeoip,
    ObjectHostname,
    ObjectInterfaceZone,
    ObjectIpAddress,
    ObjectService,
    ObjectUser,
    ObjectVlanTag,
    GroupsEdit,
  } from '../objects'

  export default {
    components: {
      ObjectApplication,
      ObjectGeoip,
      ObjectHostname,
      ObjectInterfaceZone,
      ObjectIpAddress,
      ObjectService,
      ObjectUser,
      ObjectVlanTag,
      GroupsEdit,
      NameDescription,
    },
    data() {
      return {
        objectCopy: undefined,
        fetching: false,
      }
    },

    computed: {
      ...mapState('policyManager', ['objectDialog']),

      // the actual object passed with the dialog
      object: ({ objectDialog }) => objectDialog.object,
      // dialog is shown when object is set
      show: ({ object }) => !!object,
      // the object configuration
      config: ({ object }) => objectsConfig[object?.Type],
      // component used for editing the object/group
      component: ({ config }) => config?.editComponent,
      // boolean if group
      isGroup: ({ object }) => object?.Type.includes('group'),
      // returns dialog title translation key, based on action (create/edit) and object type (object/group)
      title: ({ object, isGroup }) => {
        if (!isGroup) {
          return object.Id.startsWith('create') ? 'create_object' : 'edit_object'
        } else {
          return object.Id.startsWith('create') ? 'create_group' : 'edit_group'
        }
      },
    },

    watch: {
      object: {
        handler(value) {
          // set local copy of object for manipulation
          this.objectCopy = cloneDeep(value)
        },
        immediate: true,
      },
    },

    methods: {
      ...mapActions('policyManager', ['closeObjectDialog', 'saveObject']),

      /**
       * Dispatches action to save the object/group
       * @param {Object} newSettings - new/modified object
       */
      async onSave(newSettings) {
        const isValid = await this.$refs.obs.validate()
        if (!isValid) return

        this.objectCopy.PolicyJson.items = newSettings.items

        this.fetching = true
        const response = await this.saveObject({ object: this.objectCopy })
        this.fetching = false

        if (response && this.objectDialog.cb) {
          this.objectDialog.cb(response)
        }
        this.closeObjectDialog()
      },
    },
  }
</script>
