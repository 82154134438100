<!--
  Component used to edit a port/protocol item for Service Endpoint object
-->
<template>
  <v-row dense>
    <v-col>
      <!-- protocols array of numbers as strings -->
      <ValidationProvider v-slot="{ errors }" rules="required">
        <u-autocomplete
          v-model="protocolValue"
          :items="protocolOptionsAsString"
          :multiple="true"
          :placeholder="$t('protocol')"
          :error-messages="errors"
        >
          <template #selection="{ item, index }">
            <span v-if="index < othersIndex" class="caption font-weight-medium mr-1">
              {{ ($t(item.text) || item) + (protocolValue.length - 1 > index ? ',' : '') }}
            </span>

            <span v-if="index === othersIndex" class="grey--text text-caption mr-1">
              {{ $t('x_others', [protocolValue.length - othersIndex]) }}
            </span>
          </template>

          <template #item="{ item, attrs, on }">
            <v-list-item v-slot="{ active }" v-bind="attrs" dense class="px-2" :ripple="false" v-on="on">
              <v-list-item-action class="my-0 mr-2">
                <v-checkbox :input-value="active" dense :ripple="false" />
              </v-list-item-action>
              <v-list-item-content class="caption font-weight-medium px-2">
                {{ $t(item.text) || item }}
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
        </u-autocomplete>
      </ValidationProvider>
    </v-col>
    <v-col cols="5">
      <!-- port number as string, comma separated list or port ranges -->
      <ValidationProvider v-slot="{ errors }" rules="port_expression">
        <u-text-field v-model="portValue" :error-messages="errors" :placeholder="$t('port')">
          <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
        </u-text-field>
      </ValidationProvider>
    </v-col>
    <v-col class="flex-grow-0 px-0">
      <slot name="actions"></slot>
    </v-col>
  </v-row>
</template>
<script>
  import { protocolOptionsAsString } from 'vuntangle/constants'

  export default {
    props: {
      protocol: { type: Array, default: undefined },
      port: { type: Array, default: undefined },
    },

    data() {
      return {
        protocolOptionsAsString,
        othersIndex: 5, // the index from which select field value show "+x others"
      }
    },

    computed: {
      // gets/sets the protocol value
      protocolValue: {
        get: ({ protocol }) => protocol,
        set(value) {
          this.$emit('update:protocol', value)
        },
      },
      // gets/sets the port value
      portValue: {
        get: ({ port }) => port.join(', '),
        set(value) {
          const newValue = value.split(',').map(item => item.trim())
          this.$emit('update:port', newValue)
        },
      },
    },
  }
</script>
