<script>
  import grids from '@/plugins/ut/ut-grids'
  import store from '@/store'

  export default {
    /**
     * Load the stores if coming directly to this page.
     *
     * @return {void}
     */
    async beforeRouteEnter(to, from, next) {
      await Promise.allSettled([
        store.dispatch('policies/fetchTemplates'),
        store.dispatch('appliances/fetchAppliances'),
      ])
      next()
    },
    data() {
      return {
        // flag to display validation error if 'sync all' is already set
        syncAllAlreadyEnabled: false,

        // holds the editing state, this will be default for new templates or
        // will be populated from existingTemplate when editing a template
        template: {
          keepInSyncEnabled: false,
          syncAllNewAppliancesEnabled: false,
          syncFrequency: 0,
          syncDayOfWeek: 0,
          syncTimeOfDay: 0,
        },
        selectedTargetAppliances: [],
        saveDialog: false,
      }
    },
    computed: {
      columnDefs() {
        return grids.getApplianceGridColumns(
          {
            appliance: {
              cellRenderer: params => {
                if (!params.data.IsLicensedForCommandCenter) {
                  return `<i aria-hidden="true" class="v-icon notranslate mdi mdi-alert" style="font-size: 16px; color: rgb(221, 44, 0); caret-color: rgb(221, 44, 0);">&nbsp;${
                    params.data.Hostname || this.$t('unknown_hostname')
                  }</i>`
                }
                return params.data.Hostname || this.$t('unknown_hostname')
              },
              tooltipValueGetter: params => {
                if (!params.data.IsLicensedForCommandCenter) {
                  return this.$t('non_complete_appliance_string')
                }
                return params.data.Hostname || this.$t('unknown_hostname')
              },
            },
          },
          this.$store.state.data.ccViewModel.Account.NoLicenseEnforcement ? ['license'] : [],
        )
      },
      /**
       * Get a list of compatible appliances.  These must not be MFW and
       * must have a complete license.
       *
       * @return {Object[]}
       */
      compatibleAppliances() {
        return (
          this.$store.state.appliances.list?.filter(
            appliance => appliance.ProductLine !== 'MFW' && appliance.HasCompleteLicense,
          ) || []
        )
      },

      /**
       * Get all possible target appliances, which would be the compatible
       * appliances without the master appliance.
       *
       * @return {Object[]}
       */
      targetAppliances() {
        // make sure appliances and masterAppliance has loaded
        if (this.compatibleAppliances.length === 0 || !this.masterAppliance) {
          return []
        }

        // get all appliances except the master appliance
        return this.compatibleAppliances.filter(appliance => appliance.Uid !== this.masterAppliance.Uid)
      },
    },
  }
</script>
