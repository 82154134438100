import { set } from 'vue'
import api from '@/plugins/ut/ut-api'

const getDefaultState = () => {
  return {
    list: null,
    fetching: false,
  }
}

const getters = {
  list: state => state.list,
  fetching: state => state.fetching,
}

const mutations = {
  RESET: state => Object.assign(state, getDefaultState()),
  SET_USERS: (state, users) => {
    state.list = users
  },
  SET_FETCHING: (state, value) => (state.fetching = value),
  ADD_USER: (state, user) => state.list.push(user),
  UPDATE_USER: (state, user) => {
    const userIndex = state.list.findIndex(r => r.UserId === user.UserId)
    set(state.list, userIndex, user)
  },
  DELETE_USER: (state, userId) => {
    const userIndex = state.list.findIndex(r => r.UserId === userId)
    state.list.splice(userIndex, 1)
  },
}

const actions = {
  /**
   * Fetch the users from the server.
   *
   * @param {Object}   state
   * @param {Function} commit
   *
   * @return {Promise<void>}
   */
  async fetchUsers({ state, commit }, options) {
    // do not call the ajax if the call is already fetching or the list exists
    // but a 'force' was not set
    if (state.fetching || (state.list !== null && !options?.force)) {
      return
    }

    commit('SET_FETCHING', true)

    const response = await api.cloud('Untangle_CommandCenter', 'GetSelectedAccountUsers')

    if (response.success && response.data) {
      commit('SET_USERS', response.data)
    }

    commit('SET_FETCHING', false)
  },

  /**
   * Create users(s).
   *
   * @return {Promise<Object>}
   */
  async createUsers({}, { userEmails, accountAccess, isFullUidAccess, uids }) {
    const response = await api.cloud('Untangle_CommandCenter', 'InviteUsers', {
      userEmails,
      accountAccess,
      isFullUidAccess,
      ...(uids ? { uids } : {}),
      paramOrder: 'userEmails accountAccess isFullUidAccess uids',
    })

    return response
  },

  /**
   * Update a user -> account relationship settings.
   *
   * @param {Object} user
   * @param {Object} change
   *
   * @return {Promise<void>}
   */
  async updateUserAccount({}, { user, accountAccess, isFullUidAccess, uids }) {
    const response = await api.cloud('Untangle_CommandCenter', 'UpdateUserAccountSettings', {
      userEmail: user.Email,
      userId: user.UserId,
      accountAccess,
      isFullUidAccess,
      ...(uids ? { uids } : {}),
      paramOrder: 'userEmail userId accountAccess isFullUidAccess uids',
    })

    return response
  },

  /**
   * Delete users.
   *
   * @param {Function} commit
   * @param {string[]} userIds
   * @return {Promise<void>}
   */
  async deleteUsers({ commit }, userIds) {
    const response = await api.cloud('Untangle_CommandCenter', 'DeleteUsersFromOrganization', {
      userIds,
      paramOrder: 'userIds',
    })

    // delete users from the store
    if (response.success) {
      userIds.forEach(userId => commit('DELETE_USER', userId))
    }

    return response
  },

  /**
   * Invite users.
   *
   * @param {String[]} userIds
   *
   * @returns {Promise<Object>}
   */
  async resendUserInvites({}, userIds) {
    return await api.cloud('Untangle_CommandCenter', 'ResendUserInvites', { userIds, paramOrder: 'userIds' })
  },
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions,
}
