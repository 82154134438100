<template>
  <div>
    <h4>{{ $t('vlan_tag') }}</h4>
    <p class="text-caption">{{ $t('input_vlan_tags') }}</p>

    <ValidationProvider v-slot="{ errors }" rules="required|vlan_tag_expression">
      <u-text-field v-model="vlanTags" :error-messages="errors" data-testid="vlan-tag">
        <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
      </u-text-field>
    </ValidationProvider>

    <v-spacer />
    <slot name="actions" :new-settings="settingsCopy" />
  </div>
</template>
<script>
  export default {
    props: {
      /**
       * the object data (PolicyJson) expected in the form
       *   id: '<id that should match Policy Id>',
       *   name: '<a name>',
       *   items: ['vlan tag 1', 'vlan tag 2'],
       *   type: <the type as expected on backend>,
       * */
      settings: { type: Object, default: () => {} },
    },

    data() {
      return {
        /**
         * using a simple spread to clone the settings
         * for more complex nested objects it would be used cloneDeep
         * */
        settingsCopy: { ...this.settings },
      }
    },

    computed: {
      /** the `items` of the object as an array of vlan tags
       * that are edited as comma separated string via the text field  */
      vlanTags: {
        get() {
          return this.settingsCopy.items.join(',')
        },
        set(value) {
          this.settingsCopy.items = value.split(',')
        },
      },
    },
  }
</script>
