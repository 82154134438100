<template>
  <u-widget :title="model" :enable-expand="false" v-on="$listeners">
    <div class="flex-grow-1 d-flex flex-column justify-center">
      <div class="text-center mb-4">
        <img
          :src="appliance.ApplianceImageUrl === null ? require('@/static/appliance-icon-new.svg') : imageURL"
          class="model-image"
        />
      </div>
      <div class="text-center">{{ $t('uid') }}: {{ displayUID }}</div>
      <div class="text-center">{{ $t('serial_number') }}: {{ displaySerialNumber }}</div>
    </div>
  </u-widget>
</template>
<script>
  import util from '@/plugins/ut/ut-util'
  import store from '@/store'

  export default {
    props: {
      appliance: {
        type: Object,
        required: true,
      },
    },
    computed: {
      model() {
        const ApplianceModel = this.appliance.ApplianceModel
        const ApplianceSerial = this.appliance.ApplianceSerialNumber
          ? this.appliance.ApplianceSerialNumber
          : this.appliance.SerialNumber
          ? this.appliance.SerialNumber
          : null
        if (ApplianceModel === 'Custom' && ApplianceSerial && ApplianceSerial.toLowerCase().startsWith('ctw')) {
          return this.$t('arista_q_series')
        }
        return this.$t('appliance_model') + ' ' + ApplianceModel
      },
      imageURL() {
        return (store.state.data.ccViewModel.StoreUrl || '') + this.appliance.ApplianceImageUrl
      },
      displayUID() {
        return this.appliance.Uid ? util.obfuscateUid(this.appliance.Uid) : this.$t('not_assigned')
      },
      displaySerialNumber() {
        return this.appliance.ApplianceSerialNumber
          ? this.appliance.ApplianceSerialNumber
          : this.appliance.SerialNumber
          ? this.appliance.SerialNumber
          : this.$t('not_found')
      },
    },
  }
</script>
<style>
  .model-image {
    max-height: 150px;
    max-width: 350px;
  }
</style>
