<template>
  <div class="my-12 flex-grow-1">
    <v-alert text color="info" width="600" class="mx-auto">
      <h1 class="headline mb-4">{{ $t('feature_not_supported', [$t(featureName)]) }}</h1>
      <p>{{ $t('feature_upgrade_message') }}</p>

      <template v-if="!$isSapphire">
        <v-divider class="my-4"></v-divider>

        <v-row align="center" no-gutters>
          <v-spacer />
          <v-col class="shrink">
            <u-btn @click="showUpgradeDialog = true">{{ $t('upgrade_now') }}</u-btn>
          </v-col>
        </v-row>
      </template>
    </v-alert>
    <ut-upgrade-dialog v-model="showUpgradeDialog" :appliance="appliance" />
  </div>
</template>
<script>
  import UtUpgradeDialog from '../UtIndividualUpgradeDialog.vue'

  export default {
    components: { UtUpgradeDialog },
    props: {
      // the locale key of the feature
      featureName: { type: String, default: undefined },
    },
    data() {
      return {
        showUpgradeDialog: false,
      }
    },
    computed: {
      appliance: ({ $store, $route }) => $store.getters['appliances/getByUniqueIdentifier']($route.params.id),
    },
  }
</script>
