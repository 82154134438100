<template>
  <u-widget :title="$t('firewalls')" :info="$t('info_appliances_grid')" v-on="$listeners">
    <u-grid
      id="mfw-appliances-grid-widget"
      :no-data-message="noDataMessage"
      :column-defs="columnDefs"
      :row-data="appliances"
      :fetching="fetching"
      :framework-components="frameworkComponents"
      @refresh="fetchData(true)"
      @row-clicked="onRowClicked"
    />
  </u-widget>
</template>
<script>
  import { AssociatedPolicyRenderer } from 'vuntangle/pm'
  import grids from '@/plugins/ut/ut-grids'
  import WidgetMixin from '@/components/widgets/WidgetMixin'
  import renderersMixin from '@/pages/policies/mfw/policy-manager/renderersMixin'

  export default {
    mixins: [WidgetMixin, renderersMixin],
    props: {
      appliances: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        frameworkComponents: {
          AssociatedPolicyRenderer,
        },
      }
    },
    computed: {
      columnDefs: ({ $store, augmentColumns }) => {
        // ETM specific columns should not be listed in grid - CD-5619
        const etmSpecificColumns = ['uid', 'software_version', 'network', 'product_line']
        const removeCols = $store.state.data.ccViewModel.Account.NoLicenseEnforcement
          ? etmSpecificColumns.concat(['license'])
          : etmSpecificColumns
        return augmentColumns(grids.getPMAppliancesColumnDefs(removeCols), ['policies', 'global_templates'])
      },
      fetching: ({ $store }) => $store.getters['appliances/fetching'],
      noDataMessage() {
        return this.appliances.length > 0 ? this.$t('no_filtered_data_firewalls') : this.$t('no_data_defined_firewalls')
      },
    },
    mounted() {
      this.fetchTemplates()
    },
    methods: {
      onRowClicked({ data: appliance }) {
        this.$router.push({ name: 'appliances-id', params: { id: appliance.UniqueIdentifier } })
      },

      fetchData(force = false) {
        this.$store.dispatch('appliances/fetchFullAppliances', { force })
        this.$store.dispatch('policyManager/getAllApplianceAssignments', { force })
        this.fetchTemplates(force)
      },

      fetchTemplates(force = false) {
        this.$store.dispatch('policyManager/fetchObjectsByPrefix', { prefix: 'mfw-config', force })
      },
    },
  }
</script>
