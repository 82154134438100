<template>
  <div class="d-flex flex-column align-stretch flex-grow-1 pa-4">
    <breadcrumbs />

    <div class="d-flex align-center mb-2">
      <h4>{{ $t('select_existing_rules') }}</h4>
      <v-spacer />
      <v-btn text class="text-capitalize mr-2" @click="goBack()">
        {{ $t('cancel') }}
      </v-btn>
      <v-btn depressed :disabled="!selection.length" color="primary" class="text-capitalize" @click="onAddRules">
        <v-icon small class="mr-2">mdi-plus</v-icon> {{ $t('add_to_policy') }}
      </v-btn>
    </div>
    <u-grid
      id="all-existing-rules"
      selection-type="multiAction"
      row-node-id="id"
      :no-data-message="noDataMessage"
      :column-defs="columnDefs"
      :row-data="rules"
      :selection.sync="selection"
      :fetching="fetching"
      :framework-components="frameworkComponents"
      @refresh="fetchRulesAndTemplates(true)"
    />
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import {
    columnDefs,
    rulesConfig,
    ConditionsRenderer,
    ConditionGroupsRenderer,
    OrderRenderer,
    ActionRenderer,
  } from 'vuntangle/pm'
  import { hydrateRulesData } from '../hydration'
  import renderersMixin from '../renderersMixin'
  import Breadcrumbs from './Breadcrumbs.vue'
  import editorMixin from './editorMixin'

  export default {
    components: { Breadcrumbs },
    mixins: [editorMixin, renderersMixin],
    data() {
      return {
        selection: [],
        frameworkComponents: {
          OrderRenderer,
          ConditionsRenderer,
          ConditionGroupsRenderer,
          ActionRenderer,
        },
      }
    },
    computed: {
      fetching: ({ $store, ruleType }) => $store.getters['policyManager/fetching'](ruleType),
      config: ({ ruleType }) => rulesConfig[ruleType],
      rules: ({ $store, ruleType }) => hydrateRulesData($store.getters['policyManager/getObjectsByType'](ruleType)),
      columnDefs: ({ augmentColumns }) => augmentColumns(columnDefs.getAllRulesColumnDefs(), ['action']),
      noDataMessage({ config }) {
        return this.rules.length > 0
          ? this.$t('no_filtered_data_' + config.text)
          : this.$t('no_data_defined_' + config.text)
      },
    },

    mounted() {
      this.fetchRulesAndTemplates()
    },

    methods: {
      /** fetches rules and templates from the store */
      fetchRulesAndTemplates(force = false) {
        this.$store.dispatch('policyManager/fetchObjectsByPrefix', { prefix: 'mfw-rule', force })
        this.$store.dispatch('policyManager/fetchObjectsByPrefix', { prefix: 'mfw-config', force })
        this.$store.dispatch('policyManager/fetchDependencyMap', { force })
      },

      onAddRules() {
        const policy = cloneDeep(this.editPolicy)
        const rulesIds = this.selection.map(row => row.id)
        const policyRules = policy.PolicyJson.rules[this.ruleType]

        if (!policyRules) policy.PolicyJson.rules[this.ruleType] = []

        policy.PolicyJson.rules[this.ruleType] = [...new Set([...policy.PolicyJson.rules[this.ruleType], ...rulesIds])]
        this.$store.commit('policyManager/SET_EDIT_OBJECT', policy)

        this.goBack()
      },
    },
  }
</script>
