<template>
  <div class="d-flex flex-column flex-grow-1 flex-basis-0 ma-4">
    <grid-wrapper
      ref="wrapper"
      :headline="$t('templates')"
      :subtitle="$t('templates_description')"
      :selection.sync="selection"
    >
      <template #actions>
        <v-menu bottom left offset-y transition="slide-y-transition">
          <template #activator="{ on, attrs }">
            <v-btn depressed color="primary" class="text-capitalize" v-bind="attrs" v-on="on">
              <v-icon small class="mr-2">mdi-plus</v-icon> {{ $t('create_new') }}
            </v-btn>
          </template>
          <v-list class="list-dense pa-2" nav dense width="240">
            <div v-for="(items, key) in menuItems" :key="key">
              <v-subheader v-if="key !== 'none'">{{ $t(key) }}</v-subheader>
              <v-list-item-group :class="key !== 'none' ? 'pl-4' : ''">
                <v-list-item v-for="item in items" :key="item.key" dense @click="onCreate({ type: item.type })">
                  <v-list-item-title>
                    {{ $t(item.text) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </div>
          </v-list>
        </v-menu>
      </template>
      <template #default="{ selectionType }">
        <u-grid
          id="all-templates"
          row-node-id="id"
          :selection-type="selectionType"
          :no-data-message="noDataMessage"
          :column-defs="columnDefs"
          :fetching="fetching"
          :row-data="templates"
          :row-actions="rowActions"
          :selection.sync="selection"
          :framework-components="frameworkComponents"
          v-on="!selectionType ? { rowClicked } : {}"
          @refresh="fetchTemplates(true)"
        />
      </template>
    </grid-wrapper>
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import {
    columnDefs,
    templatesConfig,
    ConditionsRenderer,
    ActionRenderer,
    AssociatedPolicyRenderer,
    AssociatedApplianceRenderer,
    NameRenderer,
  } from 'vuntangle/pm'
  import GridWrapper from '../components/GridWrapper.vue'
  import { hydrateTemplatesData } from '../hydration'
  import { generateDefaultMFWObject } from '../util'
  import renderersMixin from '../renderersMixin'

  export default {
    components: { GridWrapper },
    mixins: [renderersMixin],
    data() {
      return {
        selection: [],
        frameworkComponents: {
          ConditionsRenderer,
          ActionRenderer,
          AssociatedPolicyRenderer,
          AssociatedApplianceRenderer,
          NameRenderer,
        },
        templateTypes: Object.keys(templatesConfig),

        rowActions: [
          {
            icon: 'mdi-pencil',
            handler: ({ node }) => this.rowClicked({ node }),
          },
          {
            icon: 'mdi-content-copy',
            handler: ({ data }) => this.onCreate({ id: data.id }),
          },
          {
            icon: 'mdi-delete',
            handler: ({ data }) => this.$refs.wrapper.onDelete([data.id]),
          },
        ],
      }
    },

    computed: {
      templates: ({ $store, templateTypes }) => {
        let templates = []
        templateTypes.forEach(type => {
          const templatesByType = $store.getters['policyManager/getObjectsByType'](type) || []
          templates = [...new Set([...templates, ...templatesByType])]
        })
        return hydrateTemplatesData(templates)
      },
      columnDefs: ({ augmentColumns }) =>
        augmentColumns(columnDefs.getTemplatesColumnDefs(), ['policies', 'appliances']),
      /** navigation items for each template type */
      menuItems() {
        const groups = {}
        Object.entries(templatesConfig).forEach(([key, config]) => {
          const category = config.category || 'none'
          if (!groups[category]) groups[category] = []
          groups[category].push({
            key,
            text: config.text,
            type: key,
          })
        })
        // alpha sort types with respect to their translations
        for (const item in groups) {
          groups[item].sort((a, b) => this.$t(a.text).localeCompare(this.$t(b.text)))
        }
        return groups
      },

      fetching: ({ $store, templateTypes }) =>
        templateTypes.some(type => $store.getters['policyManager/fetching'](type)),
      noDataMessage() {
        return this.templates.length > 0 ? this.$t('no_filtered_data_templates') : this.$t('no_data_defined_templates')
      },
    },

    mounted() {
      this.fetchTemplates()
    },

    methods: {
      /** fetches templates from the store */
      fetchTemplates(force = false) {
        this.$store.dispatch('policyManager/fetchObjectsByPrefix', { prefix: 'mfw-config', force })
        this.$store.dispatch('policyManager/fetchDependencyMap', { force })
      },

      /** commits the rule that is going to be edited, then routes to the rule editor */
      rowClicked({ node }) {
        const configurationId = node.data.id
        const template = this.$store.getters['policyManager/getObjectById'](configurationId)
        if (!template) return
        this.$store.commit('policyManager/SET_EDIT_OBJECT', cloneDeep(template))
        this.$router.push({ name: 'pm-templates-configuration', params: { configurationId } })
      },

      /**
       * commits a new template that is going to be edited, then routes to the template config editor
       * newly created objects are using temporary object Type as Id to avoid increasing route complexity
       * @param {String} type - the template type to be created
       * @param {String} id - the object (configuration) id to be duplicated
       */
      onCreate({ type, id }) {
        const conf = id && this.$store.getters['policyManager/getObjectById'](id)
        const confType = conf?.Type || type

        this.$store.commit('policyManager/SET_EDIT_OBJECT', generateDefaultMFWObject(confType, conf))
        this.$router.push({ name: 'pm-templates-configuration', params: { configurationId: confType } })
      },
    },
  }
</script>
