import { set } from 'vue'
import vuntangle from '@/plugins/vuntangle'
import i18n from '@/plugins/vue-i18n'
import api from '@/plugins/ut/ut-api'

const getDefaultState = () => {
  return {
    alertRules: null,
    notificationProfiles: null,
    alertRulesFetching: false,
    notificationProfilesFetching: false,
    accountEvents: {
      alert: null,
      audit: null,
      threat: null,
    },
    accountEventsFetching: {
      alert: null,
      audit: null,
      threat: null,
    },
  }
}

const getProfileInitialAction = row => {
  let initialAction = row.Action
  const config = JSON.parse(row.Config)
  let payload = null

  try {
    payload = JSON.parse(config.Payload.replace(/,"custom_details":%event%/g, ''))
  } catch (e) {}

  if (
    row.Action === 'webhook' &&
    ['slack', 'pagerduty', 'victorops'].some(initialAction => config.EndpointUrl.includes(initialAction)) &&
    payload !== null
  ) {
    if (config.EndpointUrl.includes('slack')) {
      initialAction = 'slack'
    } else if (config.EndpointUrl.includes('pagerduty')) {
      initialAction = 'pagerduty'
    } else if (config.EndpointUrl.includes('victorops')) {
      initialAction = 'victorops'
    }
  }

  return initialAction
}

const getters = {
  alertRules: state => state.alertRules,
  notificationProfiles: state => state.notificationProfiles,
  alertRulesFetching: state => state.alertRulesFetching,
  notificationProfilesFetching: state => state.notificationProfilesFetching,
  getAccountEvents: state => type => state.accountEvents[type],
  getAccountEventsFetching: state => type => state.accountEventsFetching[type],
}

const mutations = {
  RESET: state => Object.assign(state, getDefaultState()),

  SET_ALERT_RULES: (state, rules) => (state.alertRules = rules),
  SET_ALERT_RULES_FETCHING: (state, value) => (state.alertRulesFetching = value),
  ADD_ALERT_RULE: (state, rule) => state.alertRules.push(rule),
  UPDATE_ALERT_RULE: (state, rule) => {
    const ruleIndex = state.alertRules.findIndex(r => r.Id === rule.Id)
    set(state.alertRules, ruleIndex, rule)
  },
  DELETE_ALERT_RULES: (state, rules) => {
    rules.forEach(rule => {
      const ruleIndex = state.alertRules.findIndex(r => r.Id === rule.Id)
      state.alertRules.splice(ruleIndex, 1)
    })
  },

  SET_NOTIFICATION_PROFILES: (state, value) => (state.notificationProfiles = value),
  SET_NOTIFICATION_PROFILES_FETCHING: (state, value) => (state.notificationProfilesFetching = value),
  ADD_NOTIFICATION_PROFILE: (state, value) => state.notificationProfiles.push(value),
  UPDATE_NOTIFICATION_PROFILE: (state, prof) => {
    const profileIndex = state.notificationProfiles.findIndex(r => r.Id === prof.Id)
    set(state.notificationProfiles, profileIndex, prof)
  },
  DELETE_NOTIFICATION_PROFILES: (state, profiles) => {
    profiles.forEach(profile => {
      const profileIndex = state.notificationProfiles.findIndex(r => r.Id === profile.Id)
      state.notificationProfiles.splice(profileIndex, 1)
    })
  },
  SET_ACCOUNT_EVENTS: (state, { type, values }) => {
    state.accountEvents[type] = values
  },
  SET_ACCOUNT_EVENTS_FETCHING: (state, { type, value }) => (state.accountEventsFetching[type] = value),
}

const actions = {
  async fetchNotificationProfiles({ state, commit }, options) {
    if (state.notificationProfiles !== null && !state.notificationProfilesFetching && !options?.force) {
      return
    }

    commit('SET_NOTIFICATION_PROFILES_FETCHING', true)
    const response = await api.cloud('Untangle_CommandCenter', 'GetNotificationProfiles')
    if (response.success) {
      for (const key in response.data) {
        const row = response.data[key]
        row.InitialAction = getProfileInitialAction(row)
      }

      commit('SET_NOTIFICATION_PROFILES', response.data)
    }
    commit('SET_NOTIFICATION_PROFILES_FETCHING', false)
  },

  async createNotificationProfile({}, profile) {
    const response = await api.payload(
      {
        handler: 'Untangle_CommandCenter',
        paramOrder: 'payload',
        method: 'CreateNotificationProfile',
      },
      profile,
    )

    if (response.success && response.data) {
      vuntangle.toast.add(i18n.t('notification_profile_create_success'))
    } else if (response.message) {
      vuntangle.toast.add(i18n.t(response.message), 'error')
    } else {
      vuntangle.toast.add(i18n.t('notification_profile_create_error'), 'error')
    }

    return response
  },

  /**
   * Update an existing notification profile
   * @param commit
   * @param profile - updated profile
   * @returns {Promise<void>}
   */
  async updateNotificationProfile({ commit }, profile) {
    const response = await api.payload(
      {
        handler: 'Untangle_CommandCenter',
        paramOrder: 'payload',
        method: 'UpdateNotificationProfile',
      },
      profile,
    )

    if (response.success && response.data) {
      profile.InitialAction = getProfileInitialAction(profile)
      commit('UPDATE_NOTIFICATION_PROFILE', profile)
      vuntangle.toast.add(i18n.t('notification_profile_edit_success'))
    } else if (response.message) {
      vuntangle.toast.add(i18n.t(response.message), 'error')
    } else {
      vuntangle.toast.add(i18n.t('notification_profile_edit_error'), 'error')
    }
  },

  async deleteNotificationProfiles({ commit }, profiles) {
    const profileIds = []
    const profileNames = []

    profiles.forEach(profile => {
      profileIds.push(profile.Id)
      profileNames.push(profile.Name)
    })

    const response = await api.cloud('Untangle_CommandCenter', 'DeleteNotificationProfiles', {
      paramOrder: 'profileIds profileNames',
      profileIds,
      profileNames,
    })

    if (response.success && response.data) {
      commit('DELETE_NOTIFICATION_PROFILES', profiles)
      vuntangle.toast.add(i18n.t('delete_notification_profile_success'))
    } else {
      vuntangle.toast.add(i18n.t('delete_notification_profile_error'), 'error')
    }
  },

  async fetchAlertRules({ state, commit }, options) {
    if (state.alertRules !== null && !state.alertRulesFetching && !options?.force) {
      return
    }

    commit('SET_ALERT_RULES_FETCHING', true)
    const response = await api.cloud('Untangle_CommandCenter', 'GetAlertRules')
    if (response.success) {
      commit('SET_ALERT_RULES', response.data)
    }
    commit('SET_ALERT_RULES_FETCHING', false)
  },

  /**
   * Gets data for various report grids: alert history, audit history, threat history
   * @param state
   * @param commit
   * @param type
   * @param count
   * @param startDate
   * @param payload
   * @returns {Promise<*>}
   */
  async fetchDashboardAlerts({ state, commit }, { type, count, startDate, payload, options }) {
    if ((state.accountEventsFetching[type] || state.accountEvents[type] != null) && !options?.force)
      return state.accountEvents[type]
    // if payload exists, then there should be some UIDs,
    // otherwise API would just return [] so no need to make the call
    if (payload && payload.length === 0) {
      commit('SET_ACCOUNT_EVENTS', { type, values: [] })
      return []
    }
    commit('SET_ACCOUNT_EVENTS_FETCHING', { type, value: true })
    const response = await api.payload(
      {
        handler: 'Untangle_CommandCenter',
        method: 'GetAccountEvents',
        type,
        count,
        startDate,
        excludePayload: 0,
        paramOrder: 'type payload count startDate excludePayload',
      },
      payload,
    )
    commit('SET_ACCOUNT_EVENTS_FETCHING', { type, value: false })
    if (response.success && response.data) {
      commit('SET_ACCOUNT_EVENTS', { type, values: response.data })
      return response.data
    } else return null
  },

  async createAlertRule({ commit }, rule) {
    const response = await api.payload(
      {
        handler: 'Untangle_CommandCenter',
        paramOrder: 'payload',
        method: 'CreateAlertRule',
      },
      rule,
    )

    if (response.success && response.data) {
      commit('ADD_ALERT_RULE', response.data)
    }

    return response
  },

  /**
   * Update an existing alert rule
   * @param commit
   * @param rule - updated rule
   * @returns {Promise<Object>}
   */
  async updateAlertRule({ commit }, rule) {
    const response = await api.payload(
      {
        handler: 'Untangle_CommandCenter',
        paramOrder: 'payload',
        method: 'UpdateAlertRule',
      },
      rule,
    )

    if (response.success && response.data) {
      commit('UPDATE_ALERT_RULE', response.data)
    }

    return response
  },

  async deleteAlertRules({ commit }, rules) {
    const ruleIds = []
    const ruleNames = []

    rules.forEach(rule => {
      ruleIds.push(rule.Id)
      ruleNames.push(rule.Name)
    })

    const response = await api.cloud('Untangle_CommandCenter', 'DeleteAlertRules', {
      paramOrder: 'ruleIds ruleNames',
      ruleIds,
      ruleNames,
    })

    if (response.success && response.data) {
      commit('DELETE_ALERT_RULES', rules)
      vuntangle.toast.add(i18n.t('delete_alert_rules_success'))
    } else {
      vuntangle.toast.add(i18n.t('delete_alert_rules_failed'), 'error')
    }
  },
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions,
}
