<!--
  component rendering one or multiple condition groups
-->
<template>
  <span style="line-height: 1.8">
    <span v-for="(group, index1) in groups" :key="index1">
      <span>(</span>
      <span v-for="(items, index2) in group" :key="index2">
        <!-- for groups is not needed to display `Any Source`, `Any Destination`-->
        ( <readable-condition-items :items="items" :use-any="{ source: false, dest: false }" /> )
        <span v-if="index2 < group.length - 1" class="text-uppercase font-weight-bold"> {{ $t('or') }} </span>
      </span>
      <span>)</span>
      <span v-if="index1 < groups.length - 1" class="text-uppercase font-weight-bold">
        <br />
        {{ $t('and') }}
      </span>
    </span>
  </span>
</template>
<script>
  import ReadableConditionItems from '../components/ReadableConditionItems.vue'

  export default {
    components: { ReadableConditionItems },
    props: {
      /**
       * groups contains an aray of items conditions from each
       * it can take the following form
       * [ // array of groups
       *   [ // group 1
       *     [], [], ... // array of condition items in group 1
       *   ],
       *   [ // group 2
       *     [], [], ... // array of condition items in group 2
       *   ]
       *   ...
       * ]
       */
      groups: { type: Array, default: () => [] },
    },
  }
</script>
