<template>
  <u-page
    v-if="isNew || existingProfile"
    :title="isNew ? $t('create_notification_profile') : $t('edit_notification_profile')"
    full-height
  >
    <u-section>
      <ValidationObserver ref="mainObs">
        <!-- name field -->
        <ValidationProvider v-slot="{ errors }" :rules="{ required: true, max: 100, unique: profileNames }">
          <u-text-field
            v-model="profile.Name"
            :label="$t('name')"
            :placeholder="$t('unique_notification_profile_name')"
            :error-messages="errors"
            class="mb-4"
          >
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-text-field>
        </ValidationProvider>

        <u-text-field
          v-model="profile.Description"
          :label="$t('description')"
          :placeholder="$t('notification_profile_description')"
          class="mb-4"
        />

        <ValidationProvider v-slot="{ errors }" rules="required">
          <u-select
            v-model="profile.Action"
            :label="$t('action')"
            :items="[
              { text: $t('email'), value: 'email' },
              { text: $t('webhook'), value: 'webhook' },
              { text: $t('slack'), value: 'slack' },
              { text: $t('pager_duty'), value: 'pagerduty' },
              { text: $t('victor_ops'), value: 'victorops' },
              { text: $t('mobile'), value: 'mobile' },
            ]"
          >
            <template v-if="errors.length" #append>
              <u-errors-tooltip :errors="errors" />
            </template>
          </u-select>
        </ValidationProvider>
      </ValidationObserver>
    </u-section>

    <!-- email config -->
    <u-section v-if="profile.Action === 'email'" :title="$t('email_configuration')">
      <div class="mb-4">
        <span v-html="$t('notification_profile_emails')"></span>
      </div>
      <ValidationObserver ref="emailObs">
        <ValidationProvider v-slot="{ errors }" rules="required|email_comma_list">
          <u-text-field
            v-model="emailConfig.ToEmails"
            :label="$t('email_to')"
            :placeholder="$t('recipient_email_addresses')"
            :error-messages="errors"
            class="mb-4"
          >
            <template v-if="errors.length" #append>
              <u-errors-tooltip :errors="errors" />
            </template>
          </u-text-field>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" rules="email_comma_list">
          <u-text-field
            v-model="emailConfig.CCEmails"
            label="CC"
            :placeholder="$t('cc_email_addresses')"
            :error-messages="errors"
            class="mb-4"
          >
            <template v-if="errors.length" #append>
              <u-errors-tooltip :errors="errors" />
            </template>
          </u-text-field>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" rules="email_comma_list">
          <u-text-field
            v-model="emailConfig.BCCEmails"
            label="BCC"
            :placeholder="$t('bcc_email_addresses')"
            :error-messages="errors"
            class="mb-4"
          >
            <template v-if="errors.length" #append>
              <u-errors-tooltip :errors="errors" />
            </template>
          </u-text-field>
        </ValidationProvider>

        <div style="font-size: smaller">
          <span v-html="$t('payload_vars')"></span>
        </div>

        <ValidationProvider v-slot="{ errors }" rules="required|max:255">
          <u-text-field
            v-model="emailConfig.Subject"
            :label="$t('email_subject')"
            class="my-4"
            :error-messages="errors"
          >
            <template v-if="errors.length" #append>
              <u-errors-tooltip :errors="errors" />
            </template>
          </u-text-field>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" rules="required|max:512">
          <v-textarea
            v-model="emailConfig.Body"
            :label="$t('email_body')"
            outlined
            hide-details
            :error-messages="errors"
          >
            <template v-if="errors.length" #append>
              <u-errors-tooltip :errors="errors" />
            </template>
          </v-textarea>
        </ValidationProvider>
      </ValidationObserver>
    </u-section>
    <!-- end email config -->

    <!-- webhook config -->
    <u-section v-if="profile.Action === 'webhook'" :title="$t('webhook_configuration')">
      <ValidationObserver ref="webhookObs">
        <ValidationProvider v-slot="{ errors }" name="EndpointUrl" rules="required|url">
          <u-text-field v-model="webhookConfig.EndpointUrl" :label="$t('endpoint_url')" :error-messages="errors">
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-text-field>
        </ValidationProvider>
        <v-card-title class="body-1 px-0 pt-4 mr-0 font-weight-light">
          {{ $t('headers') }}
          <v-divider class="mx-4" />
          <v-btn small depressed @click="addWebhookHeader">
            {{ $t('add_header') }}
          </v-btn>
        </v-card-title>
        <div v-if="!webhookConfig.Headers || !webhookConfig.Headers.length" class="caption mt-4 mb-8 text-center">
          {{ $t('no_headers_set') }}!
        </div>

        <div v-for="(header, i) in webhookConfig.Headers" :key="i" class="d-flex flex-row mb-4">
          <ValidationProvider v-slot="{ errors }" rules="required" class="flex-grow-1">
            <u-text-field v-model="header.Key" :label="$t('name')" :error-messages="errors">
              <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
            </u-text-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" rules="required" class="flex-grow-1">
            <u-text-field v-model="header.Value" :label="$t('value')" :error-messages="errors" class="ml-4">
              <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
            </u-text-field>
          </ValidationProvider>
          <v-btn icon @click="removeWebhookHeader(i)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-divider class="my-8" />

        <ValidationProvider v-slot="{ errors }" rules="required">
          <u-select
            v-model="webhookConfig.Method"
            :items="[
              { text: 'GET', value: 'GET' },
              { text: 'POST', value: 'POST' },
              { text: 'PUT', value: 'PUT' },
            ]"
            :label="$t('method')"
            class="mb-4"
          >
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-select>
        </ValidationProvider>

        <div v-if="webhookConfig.Method !== 'GET'" style="font-size: smaller">
          <span v-html="$t('payload_vars')"></span>
        </div>

        <v-textarea
          v-if="webhookConfig.Method !== 'GET'"
          v-model="webhookConfig.Payload"
          :label="$t('payload')"
          :placeholder="$t('request_body')"
          class="mt-4"
          outlined
        >
        </v-textarea>
      </ValidationObserver>
    </u-section>
    <!-- end webhook config -->

    <!-- slack config -->
    <u-section v-if="profile.Action === 'slack'" :title="$t('slack_configuration')">
      <div class="mb-4">
        <span v-html="$t('slack_info')"></span>
      </div>

      <ValidationObserver ref="slackObs">
        <ValidationProvider v-slot="{ errors }" rules="required|url">
          <u-text-field v-model="slackConfig.SlackUrl" :label="$t('endpoint_url')" :error-messages="errors">
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-text-field>
        </ValidationProvider>
      </ValidationObserver>
    </u-section>
    <!-- end slack config -->

    <!-- pagerduty config -->
    <u-section v-if="profile.Action === 'pagerduty'" :title="$t('pagerduty_configuration')">
      <div class="mb-4">
        <span v-html="$t('pagerduty_info')"></span>
      </div>

      <ValidationObserver ref="pagerdutyObs">
        <ValidationProvider v-slot="{ errors }" rules="required|max:32">
          <u-text-field
            v-model="pagerdutyConfig.PagerDutyRoutingKey"
            :label="$t('routing_key')"
            :error-messages="errors"
            class="mb-4"
          >
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-text-field>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" rules="required">
          <u-select
            v-model="pagerdutyConfig.PagerDutySeverity"
            :items="[
              { text: $t('critical'), value: 'critical' },
              { text: $t('error'), value: 'error' },
              { text: $t('warning'), value: 'warning' },
              { text: $t('info'), value: 'info' },
            ]"
            :label="$t('severity')"
          >
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-select>
        </ValidationProvider>
      </ValidationObserver>
    </u-section>
    <!-- end pagerduty config -->

    <!-- victorops config -->
    <u-section v-if="profile.Action === 'victorops'" :title="$t('victorops_configuration')">
      <div class="mb-4">
        <span v-html="$t('victorops_info')"></span>
      </div>

      <ValidationObserver ref="victoropsObs">
        <ValidationProvider v-slot="{ errors }" name="VictorOpsUrl" rules="required|url">
          <u-text-field
            v-model="victoropsConfig.VictorOpsUrl"
            :label="$t('endpoint_url')"
            :error-messages="errors"
            class="mb-4"
          >
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-text-field>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" name="VictorOpsUrl" rules="required">
          <u-select
            v-model="victoropsConfig.VictorOpsMessageType"
            :items="[
              { text: $t('critical'), value: 'CRITICAL' },
              { text: $t('warning'), value: 'WARNING' },
              { text: $t('info'), value: 'INFO' },
            ]"
            :label="$t('message_type')"
          >
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-select>
        </ValidationProvider>
      </ValidationObserver>
    </u-section>
    <!-- end victorops config -->

    <!-- mobile config -->
    <u-section
      v-if="profile.Action === 'mobile'"
      :title="$t('mobile_configuration')"
      class="d-flex flex-grow-1 flex-column"
    >
      <div class="mb-4">
        <span v-html="$t('mobile_info')"></span>
      </div>
      <u-grid
        id="mobile-devices"
        row-node-id="Token"
        selection-type="multiAction"
        :no-data-message="$t('no_data')"
        :column-defs="columnDefs"
        :fetching="$store.state.data.mobileDevicesFetching"
        :row-data="$store.state.data.mobileDevices || []"
        :selection.sync="selectedRows"
        @refresh="$store.dispatch('data/fetchMobileDevices', { force: true })"
      />
    </u-section>
    <!-- end mobile config -->

    <v-card-actions class="pa-0">
      <u-btn text @click="$router.push({ name: 'alerts-notification-profiles' })">
        <span :class="`${$vuetify.theme.dark ? 'white--text' : ''}`">
          {{ $t('cancel') }}
        </span>
      </u-btn>
      <u-btn :disabled="isFetching || (profile.Action === 'mobile' && selectedRows.length === 0)" @click="submit">{{
        isNew ? $t('create') : $t('update')
      }}</u-btn>
    </v-card-actions>
  </u-page>
  <u-page v-else-if="profileNotFound" :title="$t('profile_not_found')">
    <v-list-item>
      <v-list-item-content>
        <u-alert error>
          {{ $t('profile_not_found') }}
        </u-alert>
      </v-list-item-content>
    </v-list-item>
  </u-page>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'
  import grids from '@/plugins/ut/ut-grids'

  export default {
    data() {
      return {
        profile: {
          Id: null,
          Name: '',
          Description: '',
          Action: 'pagerduty',
          Config: '',
        },

        emailConfig: {
          ToEmails: '',
          CCEmails: '',
          BCCEmails: '',
          Subject: '',
          Body: '',
        },

        webhookConfig: {
          EndpointUrl: '',
          Headers: [],
          Method: 'GET',
          Payload: '',
        },

        slackConfig: {
          SlackUrl: '',
        },

        pagerdutyConfig: {
          PagerDutyUrl: 'https://events.pagerduty.com/v2/enqueue',
          PagerDutyRoutingKey: '',
          PagerDutySeverity: 'critical',
        },

        victoropsConfig: {
          VictorOpsUrl: '',
          VictorOpsMessageType: 'CRITICAL',
        },

        mobileConfig: {
          Tokens: '',
        },

        isFetching: false,
        profileNotFound: false,
        selectedRows: [],
      }
    },
    computed: {
      existingProfile() {
        return (
          this.$store.state.alerts.notificationProfiles?.find(profile => profile.Id === this.$route.params.id) || null
        )
      },
      /**
       * Check if the notification profile being worked on is new.
       *
       * @return {boolean}
       */
      isNew() {
        return this.$route.params.id === 'new'
      },
      /**
       * Notification profile names to validate the name is unique.  Will remove the current name on edit.
       *
       * @return {string[]}
       */
      profileNames() {
        const profileNames = this.$store.state.alerts.notificationProfiles?.map(rule => rule.Name) || []

        // remove the current name if editing
        if (!this.isNew && this.existingProfile) {
          const existingProfileIndex = profileNames.indexOf(this.existingProfile.Name)
          if (existingProfileIndex !== -1) {
            profileNames.splice(existingProfileIndex, 1)
          }
        }

        return profileNames
      },
      columnDefs: () => grids.getMobileDevicesColumnDefs(),
    },

    watch: {
      '$store.state.alerts.notificationProfiles': {
        immediate: true,

        /**
         * Make sure notifications profile store has been loaded when displaying a profile
         * @return {void}
         */
        handler() {
          // do nothing if the profile is new or profiles are not loaded
          if (this.isNew || !this.$store.state.alerts.notificationProfiles) {
            return
          }

          // find the profile from the store
          // if the profile is not found, set to display not found and return
          if (!this.existingProfile) {
            this.profileNotFound = true

            return
          }

          // set the editable template from the existing template\
          this.profile = {
            Id: this.existingProfile.Id,
            Name: this.existingProfile.Name,
            Description: this.existingProfile.Description,
            Action: this.existingProfile.Action,
            Config: this.existingProfile.Config,
          }
          const config = JSON.parse(this.existingProfile.Config)
          const webhookPayload = this.getWebhookPayload(config.Payload)

          // use initial action if editing a converted webhook
          this[this.getInitialAction(config, webhookPayload) + 'Config'] = this.parseConfig(config, webhookPayload)

          // pre-select mobile devices from existing settings
          if (this.profile?.Action === 'mobile') {
            this.selectedRows = JSON.parse(this.profile.Config).Tokens?.split(', ') || []
          }
        },
      },
      'profile.Action': {
        // when switching action reset fields if invalid
        handler(newAction, oldAction) {
          this.$refs[oldAction + 'Obs'] && this.$refs[oldAction + 'Obs'].reset()

          // load the mobile store if a mobile profile is selected
          if (newAction === 'mobile') {
            this.$store.dispatch('data/fetchMobileDevices')
          }
        },
      },
    },

    created() {
      // load the notification profiles
      if (!this.$store.state.alerts.notificationProfiles) {
        this.$store.dispatch('alerts/fetchNotificationProfiles')
      }
      // load paired mobile devices
      if (this.existingProfile?.Action === 'mobile') {
        this.$store.dispatch('data/fetchMobileDevices')
      }
      this.$store.commit('SET_ALERTS_SELECTION', this.$route.path)
    },

    methods: {
      // adds a new empty webhook Header
      addWebhookHeader() {
        this.webhookConfig.Headers.push({
          Key: '',
          Value: '',
        })
      },

      // removes an existing webhook Header
      removeWebhookHeader(idx) {
        this.webhookConfig.Headers.splice(idx, 1)
      },

      /**
       * Returns the JSON payload for slack webhooks.
       * @returns {string} - JSON payload for Slack webhook
       */
      getSlackJSONPayload() {
        return '{"attachments":[{"fallback":"Command Center Alert!\\n\\n%event.message%\\n`%eventstring%`","pretext":"_%event.timestamp%_","text":" ","fields":[{"title":"Summary","value":"%event.message%","short":true},{"title":"Details","value":"`%event.bodystring%`","short":false}],"color":"#F35A00","mrkdwn_in":["text","pretext","fields"]}]}'
      },

      /**
       * Returns the JSON payload for pagerduty webhooks.
       * @param {string} routingKey - The "Integration Key" listed on the Events API V2 integration's detail page in PagerDuty.
       * @param {string} severity - The perceived severity of the status the event is describing with respect to the
       * affected system. This can be critical, error, warning or info.
       * @returns {string} - JSON payload for PagerDuty webhook
       */
      getPagerDutyJSONPayload(routingKey, severity) {
        return (
          '{"routing_key":"' +
          routingKey +
          '","event_action":"trigger","payload":{"summary":"%event.message%","source":"%eventsource%","severity":"' +
          severity +
          '","timestamp":"%event.timestamp%","custom_details":%event%},"client":"Untangle Command Center","client_url":"https://launchpad.edge.arista.com","images":[{"src":"https://upload.wikimedia.org/wikipedia/en/c/c9/Untangle_company_logo.png","href":"https://untangle.com/","alt":"Untangle"}]}'
        )
      },

      /**
       * Returns the JSON payload for victorops webhooks.
       * @param {string} messageType - Behavior of the alert ('CRITICAL', 'INFO')
       * @returns {string} - JSON payload for VictorOps webhook
       */
      getVictorOpsJSONPayload(messageType) {
        return (
          '{"message_type":"' +
          messageType +
          '","entity_display_name":"%event.message%","state_message":"%event.bodystring%"}'
        )
      },

      /**
       * Returns the parsed payload for converted webhooks.
       * @returns {object} - payload object of webhook
       */
      getWebhookPayload(webhookPayload) {
        try {
          return JSON.parse(webhookPayload.replace(/,"custom_details":%event%/g, ''))
        } catch (e) {
          return null
        }
      },

      getMobileTokensForRow() {
        const tokens = this.selectedRows.map(row => row.Token).join(', ')
        return { 'Tokens': tokens }
      },

      /**
       * Converts slack, pagerduty and victorops actions to webhooks
       * @param {object} configValues - webhook form values to validate
       */
      convertToWebhook(profile) {
        const config = {
          Method: 'POST',
          Headers: [],
        }
        if (profile.Action === 'slack') {
          config.EndpointUrl = this.slackConfig.SlackUrl
          config.Payload = this.getSlackJSONPayload()
        }
        if (profile.Action === 'pagerduty') {
          config.EndpointUrl = this.pagerdutyConfig.PagerDutyUrl
          config.Payload = this.getPagerDutyJSONPayload(
            this.pagerdutyConfig.PagerDutyRoutingKey,
            this.pagerdutyConfig.PagerDutySeverity,
          )
        }
        if (profile.Action === 'victorops') {
          config.EndpointUrl = this.victoropsConfig.VictorOpsUrl
          config.Payload = this.getVictorOpsJSONPayload(this.victoropsConfig.VictorOpsMessageType)
        }
        return config
      },

      /**
       * Parses the profile's config. Changes converted webhooks back to their initial type (i.e. Slack, PagerDuty, VictorOps)
       * @param {object} config - parsed config of profile
       * @param {object} webhookPayload - parsed payload of profile config, given it is a webhook
       * @returns {object} - config of profile
       */
      parseConfig(config, webhookPayload) {
        if (
          this.profile.Action === 'webhook' &&
          ['slack', 'pagerduty', 'victorops'].some(initialAction => config.EndpointUrl.includes(initialAction)) &&
          webhookPayload !== null
        ) {
          if (config.EndpointUrl.includes('slack')) {
            config = {
              SlackUrl: config.EndpointUrl,
            }
            this.profile.Action = 'slack'
          } else if (config.EndpointUrl.includes('pagerduty')) {
            config = {
              PagerDutyUrl: config.EndpointUrl,
              PagerDutyRoutingKey: webhookPayload.routing_key,
              PagerDutySeverity: webhookPayload.payload.severity,
            }
            this.profile.Action = 'pagerduty'
          } else if (config.EndpointUrl.includes('victorops')) {
            config = {
              VictorOpsUrl: config.EndpointUrl,
              VictorOpsMessageType: webhookPayload.message_type,
            }
            this.profile.Action = 'victorops'
          }
        }
        return config
      },

      // submits the profile
      async submit() {
        /**
         * validate function returns a promise
         * using await is the cleanest way in this situation
         */
        // validate main fields
        const isValidMain = await this.$refs.mainObs.validate()
        if (!isValidMain) {
          return
        }
        if (this.profile.Action !== 'mobile') {
          // validate config
          const isValidConfig = await this.$refs[this.profile.Action + 'Obs'].validate()
          if (!isValidConfig) {
            return
          }
        }

        // prepare profile for submit as a new object
        this.$store.commit('SET_PAGE_LOADER', true)
        const submitProfile = cloneDeep(this.profile)

        if (
          // convert to webhook
          ['slack', 'pagerduty', 'victorops'].includes(submitProfile.Action)
        ) {
          submitProfile.Config = this.convertToWebhook(submitProfile)
          submitProfile.Action = 'webhook'
        } else if (['mobile'].includes(submitProfile.Action)) {
          submitProfile.Config = this.getMobileTokensForRow()
        } else {
          // just get the coresponding config based on action
          submitProfile.Config = this[submitProfile.Action + 'Config']
        }

        submitProfile.Config = JSON.stringify(submitProfile.Config)
        if (this.isNew) {
          const response = await this.$store.dispatch('alerts/createNotificationProfile', submitProfile)
          if (response.success && response.data) {
            await this.$store.dispatch('alerts/fetchNotificationProfiles', {
              force: true,
            })
          }
        } else {
          const storeProfile = this.$store.state.alerts.notificationProfiles.find(
            profile => profile.Id === this.$route.params.id,
          )

          submitProfile.AccountId = storeProfile.AccountId
          submitProfile.Id = storeProfile.Id
          submitProfile.DateCreated = storeProfile.DateCreated
          submitProfile.DateUpdated = `/Date(${Date.now()})/`
          await this.$store.dispatch('alerts/updateNotificationProfile', submitProfile)
        }
        this.$store.commit('SET_PAGE_LOADER', false)
        this.$router.push({ name: 'alerts-notification-profiles' })
      },

      /**
       * Returns the initial action if the profile is a converted webhook (i.e. Slack, PagerDuty or VictorOps), else returns the profile action as is.
       * @param {object} config - parsed config of profile
       * @param {object} webhookPayload - parsed payload of profile config, given it is a webhook
       * @returns {string} - initial action.
       */
      getInitialAction(config, webhookPayload) {
        return this.profile.Action !== 'webhook'
          ? this.profile.Action
          : config.EndpointUrl.includes('slack') && webhookPayload !== null
          ? 'slack'
          : config.EndpointUrl.includes('pagerduty') && webhookPayload !== null
          ? 'pagerduty'
          : config.EndpointUrl.includes('victorops') && webhookPayload !== null
          ? 'victorops'
          : 'webhook'
      },
    },
  }
</script>
