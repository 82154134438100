<template>
  <div v-if="appliance" class="d-flex flex-column flex-grow-1">
    <div class="d-flex flex-wrap align-center mx-4 my-2">
      <h1 class="headline">
        {{ appliance.Hostname || $t('unknown_hostname') }}
        <span class="text--disabled">- {{ appliance.ApplianceTag || $t('label_not_set') }}</span>
      </h1>
      <v-spacer />
      <appliance-actions />
    </div>
    <appliance-alerts />
    <div class="d-flex flex-grow-1 flex-basis-0 overflow-auto">
      <!-- show settings nav only for MFW > 5.0 -->
      <template v-if="showSettingsDrawer">
        <appliance-drawer />
        <v-divider vertical />
      </template>
      <div class="d-flex flex-grow-1 flex-basis-0 overflow-auto py-0 px-2">
        <!-- main router view for appliance dashboard/settings -->
        <!-- :key is needed to render the components properly on sapphire-->
        <router-view :key="$route.fullPath" class="d-flex flex-column flex-grow-1 flex-basis-0 overflow-auto" />
      </div>
    </div>
  </div>
</template>
<script>
  import store from '@/store'
  import { ApplianceActions, ApplianceAlerts, ApplianceDrawer } from '@/components/appliances/layout'

  export default {
    components: { ApplianceActions, ApplianceAlerts, ApplianceDrawer },

    /**
     * makes sure that appliances store is loaded before computing page data (appliance exists)
     * usually when accessing the appliance details page directly on app launch
     */
    async beforeRouteEnter(to, from, next) {
      store.commit('SET_PAGE_LOADER', true)
      await Promise.allSettled([
        // fetch appliances
        store.dispatch('appliances/fetchFullAppliances'),
        // fetch countries for wifi region dropdown
        store.dispatch('data/fetchCountries'),
        // fetch subscriptions for information to check for mfw security
        store.dispatch('subscriptions/fetchSubscriptions'),
      ])
      // get appliance from store
      const appliance = store.getters['appliances/getByUniqueIdentifier'](to.params.id)

      // CD-4114 - if appliance not found, redirect to appliances list (ETM) or policy manager overview (sapphire)
      if (!appliance) {
        store.commit('SET_PAGE_LOADER', false)
        return next(vm => {
          vm.$vuntangle.toast.add(vm.$t('appliance_does_not_exist'), 'error')
          vm.$router.push({ name: vm.$isSapphire ? 'pm-overview' : 'appliances' })
        })
      }

      /** fetch settings if MFW otherwise redirect to appliance dashboard */
      if (appliance.ProductLine === 'MFW') {
        await store.dispatch('appliances/fetchApplianceSettings', appliance)
      } else if (to.params.settings) {
        next({ name: 'appliances-id', params: { id: to.params.id } })
      }
      store.commit('SET_PAGE_LOADER', false)
      next(vm => {
        // if the appliance is offline, redirect from all other routes for that appliance to dashboard for appliance
        const rootPathName = 'appliances-id'
        if (!vm.boxSettings && vm.$route.name !== rootPathName) {
          vm.$router.push({ name: rootPathName })
        }
      })
    },

    /**
     * When switching appliances from the appliances bar the `beforeRouteUpdate`
     * fires and is needed to check if it's same appliance, in case it's not
     * data should be fetched for it
     */
    async beforeRouteUpdate(to, from, next) {
      if (to.params.id === from.params.id) {
        next()
      } else {
        // get appliance from store
        const appliance = store.getters['appliances/getByUniqueIdentifier'](to.params.id)
        // then fetch appliance settings if MFW
        if (appliance.ProductLine === 'MFW') {
          store.commit('SET_PAGE_LOADER', true)
          await store.dispatch('appliances/fetchApplianceSettings', appliance)
          store.commit('SET_PAGE_LOADER', false)
        } else if (to.params.settings) {
          next({ name: 'appliances-id', params: { id: to.params.id } })
        }
        next()
      }
    },

    computed: {
      appliance: ({ $store, $route }) => $store.getters['appliances/getByUniqueIdentifier']($route.params.id),
      boxSettings: ({ $store }) => $store.getters['appliances/getApplianceSettings'],
      showSettingsDrawer: ({ appliance }) =>
        appliance?.ProductLine === 'MFW' && parseFloat(appliance?.SoftwareVersion) >= 5.0,
    },
  }
</script>
