import { set } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import api from '@/plugins/ut/ut-api'

const getDefaultState = () => {
  return {
    templates: null,
    fetchingTemplates: false,
  }
}

const getters = {
  templates: state => state.templates,
  fetchingTemplates: state => state.fetchingTemplates,
}

const mutations = {
  RESET: state => Object.assign(state, getDefaultState()),

  SET_TEMPLATES: (state, templates) => (state.templates = templates),
  UPDATE_TEMPLATE: (state, template) => {
    const templateIndex = state.templates.findIndex(r => r.Id === template.Id)
    set(state.templates, templateIndex, template)
  },
  DELETE_TEMPLATE: (state, template) => {
    const templateIndex = state.templates.findIndex(r => r.Id === template.Id)
    state.templates.splice(templateIndex, 1)
  },
  SET_FETCHING_TEMPLATES: (state, value) => (state.fetchingTemplates = value),
}

const actions = {
  async fetchTemplates({ state, commit }, options) {
    // do not call the ajax if the call is already fetching or the templates
    // exist but a 'force' was not set
    if (state.fetchingTemplates || (state.templates !== null && !options?.force)) {
      return
    }

    commit('SET_FETCHING_TEMPLATES', true)

    const response = await api.cloud('Untangle_CommandCenter', 'GetApplianceTemplateConfigurations')

    if (response.success && response.data) {
      commit('SET_TEMPLATES', response.data)
    }

    commit('SET_FETCHING_TEMPLATES', false)
  },

  /**
   * Update policy template.
   *
   * @param {Object} network
   * @param {Object} change
   *
   * @return {Promise<void>}
   */
  async createTemplate({}, { masterUid, targetApplianceUids, templateOptions }) {
    const response = await api.cloud('Untangle_CommandCenter', 'CreateApplianceTemplateConfiguration', {
      masterUid,
      targetApplianceUids,
      keepInSyncEnabled: templateOptions.keepInSyncEnabled,
      syncFrequency: templateOptions.syncFrequency,
      syncFrequencyDayOfWeek: templateOptions.syncDayOfWeek,
      syncFrequencyTime: templateOptions.syncTimeOfDay,
      syncAllNewAppliancesEnabled: templateOptions.syncAllNewAppliancesEnabled,
      paramOrder:
        'masterUid targetApplianceUids keepInSyncEnabled syncFrequency syncFrequencyDayOfWeek syncFrequencyTime syncAllNewAppliancesEnabled',
    })

    // the endpoint does not give us the template entity, so just return the promise
    return response
  },

  /**
   * Update policy template.
   *
   * @param commit
   * @param {Object} network
   * @param {Object} change
   *
   * @return {Promise<void>}
   */
  async updateTemplate({ commit }, { template, change }) {
    const response = await api.cloud('Untangle_CommandCenter', 'UpdateApplianceTemplateConfiguration', {
      templateId: template.Id,
      targetApplianceUids: change.targetApplianceUids,
      keepInSyncEnabled: change.keepInSyncEnabled,
      syncFrequency: change.syncFrequency,
      syncFrequencyDayOfWeek: change.syncDayOfWeek,
      syncFrequencyTime: change.syncTimeOfDay,
      syncAllNewAppliancesEnabled: change.syncAllNewAppliancesEnabled,
      paramOrder:
        'templateId targetApplianceUids keepInSyncEnabled syncFrequency syncFrequencyDayOfWeek syncFrequencyTime syncAllNewAppliancesEnabled',
    })

    // custom end-point response to look at both flags
    if (response.success && response.data) {
      const updatedTemplate = cloneDeep(template)
      updatedTemplate.DateUpdated = `/Date(${Date.now()})/`
      updatedTemplate.TargetAppliances = change.targetApplianceUids
      updatedTemplate.KeepInSyncEnabled = change.keepInSyncEnabled
      updatedTemplate.SyncFrequency = change.syncFrequency
      updatedTemplate.SyncDayOfWeek = change.syncDayOfWeek
      updatedTemplate.SyncTimeOfDay = change.syncTimeOfDay
      updatedTemplate.SyncAllNewAppliancesEnabled = change.syncAllNewAppliancesEnabled

      commit('UPDATE_TEMPLATE', updatedTemplate)
    }

    return response
  },

  /**
   * Delete a policy template on the server.
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object[]} templates  array of template entities
   *
   * @return {Promise<void>}
   */
  async deleteTemplates({ commit }, templates) {
    // the api endpoint requires the templateIds and templateMasterUids
    const templateIds = []
    const templateMasterUids = []
    templates.forEach(template => {
      templateIds.push(template.Id)
      templateMasterUids.push(template.MasterApplianceUID)
    })

    const response = await api.cloud('Untangle_CommandCenter', 'DeleteApplianceTemplateConfigurations', {
      templateIds,
      templateMasterUids,
      paramOrder: 'templateIds templateMasterUids',
    })

    if (response.success && response.data) {
      // loop through and delete templates
      templates.forEach(template => commit('DELETE_TEMPLATE', template))
    }
    return response
  },
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions,
}
