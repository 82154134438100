<template>
  <div class="d-flex flex-column flex-grow-1 align-stretch pa-0">
    <grid-wrapper ref="wrapper" :headline="$t(config.text)" :selection.sync="selection" :show-delete="!config.readOnly">
      <template #actions>
        <v-btn v-if="!config.readOnly" depressed color="primary" class="text-capitalize" @click="onCreateObject(null)">
          <v-icon small class="mr-2">mdi-plus</v-icon> {{ $t('create_new') }}
        </v-btn>
      </template>
      <template #default="{ selectionType }">
        <u-grid
          id="conditions-objects"
          :selection-type="selectionType"
          row-node-id="id"
          :no-data-message="noDataMessage"
          :column-defs="columnDefs"
          :fetching="fetching"
          :row-data="objects"
          :row-actions="rowActions"
          :selection.sync="selection"
          :framework-components="frameworkComponents"
          v-on="!selectionType ? { rowClicked } : {}"
          @refresh="fetchObjectsAndGroups(true)"
        >
          <template v-if="config.autoHideGeneratedObjects" #toolbarActions>
            <v-divider vertical class="mx-4" />
            <v-switch v-model="showAutoGenerated" dense hide-details class="mr-1 mt-0 pt-0">
              <template #label>
                <span class="text-caption">{{ $t('show_autogenerated') }}</span>
              </template>
            </v-switch>
          </template>
        </u-grid>
      </template>
    </grid-wrapper>
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { columnDefs, objectsConfig, Type, ConditionsRenderer, AssociatedPolicyRenderer } from 'vuntangle/pm'
  import GridWrapper from '../components/GridWrapper.vue'
  import { generateDefaultMFWObject, getFilteredObjects } from '../util'
  import renderersMixin from '../renderersMixin'
  import { hydrateConditionsData } from '../hydration'

  export default {
    components: { GridWrapper },
    mixins: [renderersMixin],
    data() {
      return {
        fetching: false,
        // to filter in / out the auto gen objects via the toggle
        showAutoGenerated: true,
        selection: [],
        frameworkComponents: {
          ConditionsRenderer,
          AssociatedPolicyRenderer,
        },
      }
    },
    computed: {
      config: () => objectsConfig[Type.ObjectCondition],
      // if it's a group, it will have a linked object type for displaying dependencies
      linkedObjectType: ({ config }) => config.linkedObjectType,
      objects: ({ $store, config, showAutoGenerated }) =>
        hydrateConditionsData(
          getFilteredObjects(
            $store.getters['policyManager/getObjectsByType'](Type.ObjectCondition),
            config.readOnly,
            showAutoGenerated,
          ),
        ),
      isCondition: ({ type }) => type === Type.ObjectCondition,
      columnDefs: ({ augmentColumns }) => augmentColumns(columnDefs.getConditionsObjectsColumnDefs(), ['policies']),
      noDataMessage({ config }) {
        return this.objects.length > 0
          ? this.$t('no_filtered_data_' + config.text)
          : this.$t('no_data_defined_' + config.text)
      },
      rowActions: ({ rowClicked, onCreateObject, $refs }) => [
        {
          icon: 'mdi-pencil',
          handler: ({ node }) => rowClicked({ node }),
        },
        {
          icon: 'mdi-content-copy',
          handler: ({ data }) => onCreateObject(data.id),
        },
        {
          icon: 'mdi-delete',
          handler: ({ data }) => $refs.wrapper.onDelete([data.id]),
        },
      ],
    },

    watch: {
      type: {
        handler() {
          this.fetchObjectsAndGroups()
          this.selection = []
          this.showAutoGenerated = !this.config.autoHideGeneratedObjects
        },
        immediate: true,
      },
    },

    methods: {
      /**
       * Fetch data objects in parallel
       * @param {Boolean} force - refetches data from mongo if true
       */
      async fetchObjectsAndGroups(force = false) {
        const fns = [this.$store.dispatch('policyManager/fetchObjectsByType', { type: Type.ObjectCondition, force })]
        if (this.linkedObjectType) {
          fns.push(this.$store.dispatch('policyManager/fetchObjectsByType', { type: this.linkedObjectType, force }))
        }
        fns.push(this.$store.dispatch('policyManager/fetchDependencyMap', { force }))
        this.fetching = true
        await Promise.allSettled(fns)
        this.fetching = false
      },

      rowClicked({ node }) {
        if (this.config.readOnly) {
          return
        }

        const conditionId = node.data.id
        const condition = this.$store.getters['policyManager/getObjectById'](conditionId)

        if (!condition) return

        this.$store.commit('policyManager/SET_EDIT_OBJECT', cloneDeep(condition))
        this.$router.push({ name: 'pm-objects-condition', params: { objectType: Type.ObjectCondition, conditionId } })
      },

      /**
       * Creates new or duplicates existing object then goes to the editing view for it
       * @param {String} id - the object id to be duplicated
       * @returns {undefined}
       */
      onCreateObject(id) {
        const object = id && this.$store.getters['policyManager/getObjectById'](id)

        const newObject = generateDefaultMFWObject(Type.ObjectCondition, object)

        this.$store.commit('policyManager/SET_EDIT_OBJECT', newObject)
        this.$router.push({
          name: 'pm-objects-condition',
          params: { objectType: Type.ObjectCondition, conditionId: Type.ObjectCondition },
        })
      },
    },
  }
</script>
