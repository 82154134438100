<template>
  <u-widget
    :title="$t('alert_history')"
    :info="$t('info_alerts')"
    enable-refresh
    @refresh="fetchDashboardAlerts"
    v-on="$listeners"
  >
    <template #actions>
      <div v-if="alertsEnabled" class="d-flex flex-grow-1 justify-end mx-4">
        <v-btn small depressed color="primary" rounded outlined @click="$router.push({ name: 'alerts-rules' })">
          <v-icon small class="mr-2">mdi-cog</v-icon>
          {{ $t('manage') }}
        </v-btn>
      </div>
    </template>
    <u-grid
      id="alerts-received"
      :no-data-message="noDataMessage"
      :column-defs="alertColumnDefs"
      :fetching="alertsFetching"
      :row-data="rowData"
      :resize-columns.sync="resizeEvent"
      @refresh="fetchDashboardAlerts"
      @row-clicked="viewAlertDetails"
    />
    <u-dialog
      :show-dialog="!!filteredAlerts.length"
      :title="$t('additional_information')"
      :width="600"
      :buttons="[
        {
          'name': $t('ok'),
          'handler': 'close-dialog',
        },
      ]"
      @close-dialog="onCloseDialog"
    >
      <alert-details :filtered-alerts="filteredAlerts" :alert-index.sync="alertIndex" />
    </u-dialog>
  </u-widget>
</template>
<script>
  import grids from '@/plugins/ut/ut-grids'
  import WidgetMixin from '@/components/widgets/WidgetMixin'
  import AlertDetails from '@/pages/alerts/alert-details'
  import users from '@/plugins/ut/ut-users'

  export default {
    components: { AlertDetails },
    mixins: [WidgetMixin],
    props: {
      uids: {
        type: Array,
        default() {
          return []
        },
      },
    },
    data() {
      return {
        type: 'alert',
        // list of records passed to detail dialog to navigate through
        filteredAlerts: [],
        // index of the record being viewed from filteredAlerts
        alertIndex: -1,
      }
    },
    computed: {
      /**
       * Returns if the alerts feature is enabled for the user
       * Applies same logic as in main ETM drawer (see: /layouts/default/UtNewDrawer.vue)
       * @returns {Boolean}
       */
      alertsEnabled: () => users.isAlertsTabEnabled(),
      alertColumnDefs: () => grids.mergeWithApplianceColumns(grids.getAlertColumnDefs(), ['appliance', 'tag']),
      alerts: ({ $store, type }) => $store.getters['alerts/getAccountEvents'](type),
      rowData: ({ $store, alerts }) => $store.getters['appliances/populateApplianceInfoByUid'](alerts, 'DeviceId'),
      alertsFetching: ({ $store, type }) =>
        $store.getters['appliances/fetching'] || $store.getters['alerts/getAccountEventsFetching'](type),
      noDataMessage() {
        return this.rowData.length > 0
          ? this.$t('no_filtered_data_alert_history')
          : this.$t('no_data_defined_alert_history')
      },
    },
    watch: {
      uids: {
        immediate: true,
        handler() {
          this.fetchDashboardAlerts()
        },
      },
    },
    methods: {
      /**
       * fetches alerts against uids
       */
      fetchDashboardAlerts() {
        this.$store.dispatch('alerts/fetchDashboardAlerts', {
          type: this.type,
          count: 100,
          startDate: this.$vuntangle.dates.getDateString(new Date(), -30),
          payload: this.uids,
          options: { force: true },
        })
      },

      onCloseDialog() {
        this.filteredAlerts = []
        this.alertIndex = -1
      },

      /**
       * builds the props needed for alert details dialog and displays the dialog
       * @param {Object} params ag grid row click params
       */
      viewAlertDetails(params) {
        params.api.forEachNodeAfterFilterAndSort(node => {
          this.filteredAlerts.push(node.data)
        })
        this.alertIndex = params.node.rowIndex
      },
    },
  }
</script>
