<!--
This component will display a list of rules, which can be ordered and a list of appliances.
It will also call the backend to push the rules to the appliances.
-->
<template>
  <div class="d-flex flex-column pa-4 fill-height align-stretch pa-4">
    <u-section v-if="isRule" :title="$t('select_rules_order')" class="d-flex flex-column flex-grow-1">
      <u-alert class="mb-4">{{ $t('rules_order_description') }}</u-alert>
      <u-grid
        id="sync-rules-v2"
        row-node-id="Id"
        toolbar="hidden"
        :enable-refresh="false"
        :no-data-message="$t('no_data')"
        :custom-grid-options="{
          onRowDragEnd: updateRuleOrder,
        }"
        :column-defs="syncRulesColumnDefs"
        :custom-ordering="true"
        :row-data="rules"
        :framework-components="frameworkComponents"
      />
    </u-section>

    <u-section :title="$t('please_select_target_appliances')" class="d-flex flex-column flex-grow-1">
      <u-grid
        id="push-policy-appliance-grid"
        selection-type="multiAction"
        :no-data-message="$t('no_data')"
        :column-defs="basePolicyAppliancesColumnDefs"
        :fetching="$store.state.appliances.fetching"
        :row-data="filteredAppliances"
        :selection.sync="appliancesSelectedRows"
        :enable-refresh="false"
      />
    </u-section>

    <u-dialog
      :show-dialog="warningDialog"
      :title="$t('sync_now')"
      :message="$t('sync_policy_warning', { policyType: settingsName })"
      :buttons="[
        {
          name: $t('no'),
        },
        {
          name: $t('yes'),
          handler: 'sync-policy',
          showProgress: true,
        },
      ]"
      @close-dialog="warningDialog = false"
      @sync-policy="pushRules"
    />
    <u-dialog
      :show-dialog="pushPolicyNoAppliancesDialog"
      :title="$t('push_policy')"
      :message="noCompatibleApplianceMessage"
      @close-dialog="closeNoAppliancesDialog"
    />
    <div style="margin-left: 20px">
      <u-alert warning class="mb-4">{{ warningMessage }}</u-alert>
      <u-btn class="mr-4" plain :to="{ name: 'mfw-policies-types' }">
        {{ $t('cancel') }}
      </u-btn>
      <u-btn :disabled="appliancesSelectedRows.length < 1" @click="displayWarningDialog">
        {{ $t('sync_now') }}
      </u-btn>
    </div>
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import checkboxCellRenderer from './checkboxCellRenderer.vue'
  import { mfwServices, getFilteredAppliancesByService, ComponentType } from '@/util/mfwServices'
  import vuntangle from '@/plugins/vuntangle'
  import grids from '@/plugins/ut/ut-grids'
  import api from '@/plugins/ut/ut-api'
  import store from '@/store'

  export default {
    beforeRouteEnter(to, from, next) {
      return to.params.rules && to.params.rules.length > 0
        ? next()
        : next({ name: 'mfw-policies-types', params: { policyPage: to.params.policyPage } })
    },
    data() {
      return {
        rules: [],
        appliancesSelectedRows: [],
        frameworkComponents: {
          checkboxRenderer: checkboxCellRenderer,
        },
        warningMessage: '',
        warningDialog: false,
        pushPolicyNoAppliancesDialog: false,
        noCompatibleApplianceMessage: '',
        settingsName: '',
        version: '',
        filteredAppliances: [],
      }
    },
    computed: {
      serviceKey: ({ $route }) => $route.params.policyPage,
      serviceConfig: ({ serviceKey }) => mfwServices[serviceKey],
      isRule: ({ serviceConfig }) => serviceConfig.componentType === ComponentType.Rule,

      // sync rules grid columns definition
      syncRulesColumnDefs() {
        return [
          {
            headerName: this.$t('name'),
            field: 'Name',
            cellRenderer: params =>
              params.data.Name === this.$t('default_rule')
                ? `<span style="color: red; font-weight: bold;">${params.data.Name}</span>`
                : params.data.Name,
          },
          {
            headerName: this.$t('description'),
            field: 'Description',
            flex: 3,
          },
          {
            headerName: this.$t('enabled'),
            field: 'PolicyJson.enabled',
            cellRenderer: 'checkboxRenderer',
          },
          {
            headerName: this.$t('date_created'),
            field: 'DateCreated',
            valueFormatter: ({ value }) => this.$vuntangle.dates.formatDateFromApi(value),
            comparator: (a, b) => this.$vuntangle.dates.compareDates(a, b),
          },
        ]
      },
      basePolicyAppliancesColumnDefs: () =>
        grids.getPolicyApplianceColumnDefs(
          store.state.data.ccViewModel.Account.NoLicenseEnforcement ? ['license'] : [],
        ),
      securityLicenseRequired: ({ serviceConfig }) => serviceConfig.securityLicenseRequired,
    },
    created() {
      this.initializeDefaultRules()
      this.getWarningMessage()
      store.dispatch('appliances/fetchAppliances')
      this.updateFilteredAppliances()
    },
    methods: {
      // initialize default rules if syncing rule type
      initializeDefaultRules() {
        const defaults = []
        const userRules = cloneDeep(this.$route.params.rules)
        const defaultRules = this.serviceConfig.defaultRules
        const defaultRulesCopy = cloneDeep(defaultRules)
        defaultRulesCopy.forEach(rule =>
          defaults.push({
            'AccountId': '',
            'Name': this.$t('default_rule'),
            'Description': rule.description,
            'Id': rule.ruleId,
            'Type': this.serviceConfig.type,
            'DateCreated': '',
            'PolicyJson': rule,
          }),
        )
        // rules list containing first user's rules and after default rules
        this.rules = userRules ? userRules.concat(defaults) : []
      },
      // get settingsName and version based on policy's type
      computeSettingNameAndVersion() {
        this.settingsName = this.$t(this.serviceConfig.tkey)
        this.version = this.serviceConfig.version
      },
      onApplianceGridReady(params) {
        this.applianceGridApi = params.api
      },
      onFirstDataApplianceGridRendered() {
        this.applianceGridApi.forEachNode(node => {
          node.setSelected(node.data.selected)
        })
      },
      /**
       * Call the backend to push the rules to appliances
       */
      async pushRules() {
        const uids = this.appliancesSelectedRows.map(appliance => appliance.Uid)

        let pushResponse
        if (!this.isRule) {
          const ruleIds = this.rules.map(rule => rule.Id)
          pushResponse = await api.payload(
            {
              handler: 'Untangle_CommandCenter',
              paramOrder: 'payload',
              method: 'SyncServicePolicyTask',
            },
            {
              uids,
              // the mfw does not use hyphen case in the setting name, but policies in cloud are saved with hyphen names
              // to be consistent with the ngfw
              setting: this.serviceConfig.settingPathForBox,
              ruleIds,
              securityLicenseRequired: this.securityLicenseRequired,
            },
          )
        } else {
          const ruleIds = this.computeRuleIdsMap()
          pushResponse = await api.payload(
            {
              handler: 'Untangle_CommandCenter',
              paramOrder: 'payload',
              method: 'SyncRulesToAppliancesTask',
            },
            {
              uids,
              settingPath: this.serviceConfig.settingPathForBox,
              ruleIds,
            },
          )
        }

        if (pushResponse.success && pushResponse.data) {
          vuntangle.toast.add(this.$t('policy_push_initiated'))
          // redirect to previous rule page
          this.$router.go(-1)
        } else {
          vuntangle.toast.add(
            this.$t(this.$te(pushResponse.message) ? pushResponse.message : 'sync_now_could_not_be_performed'),
            'error',
          )
          this.warningDialog = false
        }
      },
      /**
       * Update the order of the rules.
       */
      updateRuleOrder(gridEvent) {
        // loop through the nodes to update the ordering
        const orderedRules = []
        gridEvent.api.forEachNode(node => {
          orderedRules.push(node.data)
        })

        this.rules = orderedRules
      },

      /**
       * Get warning message based on policy type
       */
      getWarningMessage() {
        this.computeSettingNameAndVersion()
        this.warningMessage = this.$t('sync_rule_warning', { policyType: this.settingsName })
      },
      displayWarningDialog() {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'Policies',
          eventAction: 'Sync Policy',
          eventLabel: this.serviceKey,
        })
        this.warningDialog = true
      },
      // Create no compatible message based on policy type and software version
      computeNoCompatibleMessage() {
        this.noCompatibleApplianceMessage = this.$t('no_compatible_appliances_available')

        if (this.settingsName) {
          this.noCompatibleApplianceMessage =
            this.noCompatibleApplianceMessage +
            this.$t('no_compatible_mfw_appliances_available_for_policy', {
              policy: this.settingsName,
              version: this.version,
            })
        }

        return this.noCompatibleApplianceMessage
      },
      /**
       * add appliances to this.filteredAppliances if they are compatible with sync
       */
      async updateFilteredAppliances() {
        store.commit('SET_PAGE_LOADER', true)
        // make sure subscriptions are fetched before filtering appliances
        await store.dispatch('subscriptions/fetchSubscriptions')
        this.filteredAppliances = getFilteredAppliancesByService(this.serviceKey)
        if (this.filteredAppliances.length === 0) {
          this.computeNoCompatibleMessage()
          this.pushPolicyNoAppliancesDialog = true
        }
        store.commit('SET_PAGE_LOADER', false)
      },

      /**
       * When the warning dialog is closed, redirect to the main view
       */
      closeNoAppliancesDialog() {
        this.pushPolicyNoAppliancesDialog = false
        this.$router.go(-1)
      },
      /**
       * For default rules, we should send rule id and enabled option
       * For user's rule, will send null on enabled key
       */
      computeRuleIdsMap() {
        const ruleIds = []
        this.rules.forEach(rule => {
          if (rule.PolicyJson) {
            ruleIds.push({
              'ruleId': rule.Id,
              'enabled': rule.PolicyJson.enabled,
            })
          } else {
            ruleIds.push({
              'ruleId': rule.Id,
              'enabled': null,
            })
          }
        })
        return ruleIds
      },
    },
  }
</script>
