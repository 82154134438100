import Vue from 'vue'
import VUntangle from 'vuntangle'

// determine which environment the host application is running in, used for sentry logging
const host = window.location.host
const env =
  process.env.NODE_ENV === 'development'
    ? 'devServer'
    : host.includes('cmd-ui.untangle.com') || host.includes('local.untangle.com')
    ? 'local'
    : host.includes('develop.untangle.com')
    ? 'development'
    : 'production'

export const installVuntanglePlugin = function (additionalOptions = {}) {
  Vue.use(VUntangle, { env, sentryDsn: process.env.VUE_APP_SENTRY_DSN, ...additionalOptions })
}

export default VUntangle.instance
