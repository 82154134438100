<template>
  <u-page :title="$t('add_appliance_to_account')" full-height>
    <div v-if="!$store.getters['data/hasAddApplianceAccess']">
      <u-alert error class="mt-30">
        {{ $t('add_appliance_error') }}
      </u-alert>
    </div>
    <template v-else>
      <div v-if="step === 1" :class="step === 1 ? 'd-flex flex-column flex-grow-1' : ''">
        <div class="mt-30">
          <div v-html="$t('enter_uid_or_serial_to_add', { url: uidSupportUrl })" />
        </div>
        <ValidationObserver ref="obs">
          <v-radio-group v-model="entryType" row hide-details>
            <v-radio value="uid" :label="$t('uid')" />
            <v-radio value="serial" :label="$t('serial_number')" />
            <v-spacer />
          </v-radio-group>
          <!-- uid -->
          <ValidationProvider v-slot="{ errors }" :rules="entryType === 'uid' ? 'required|uid' : 'required|serial'">
            <u-text-field
              v-model="uidOrSerial"
              :label="entryType === 'uid' ? $t('uid') : $t('serial_number')"
              class="my-4"
              :error-messages="errors"
            >
              <template v-if="errors.length" #append>
                <u-errors-tooltip :errors="errors" />
              </template>
            </u-text-field>
          </ValidationProvider>

          <div v-if="entryType === 'uid'" class="caption">
            <p class="my-1 font-weight-bold">{{ $t('sample') }}</p>
            NG Firewall: <code>xxxx-xxxx-xxxx-xxxx</code> <br />
            Micro Edge: <code>xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx</code>
          </div>
          <div v-if="entryType === 'serial'" class="caption">
            <p class="my-1 font-weight-bold">{{ $t('sample') }}</p>
            {{ $t('exact_14') }}: <code>02181WA0001003</code><br />
            {{ $t('ctw_example') }}: <code>CTW22140001</code><br />
            &nbsp; <br />
          </div>
        </ValidationObserver>
        <div class="d-flex flex-row justify-left">
          <v-card-actions class="px-0 mt-4">
            <v-spacer />
            <u-btn text :to="{ name: 'appliances' }">
              <span :class="`${$vuetify.theme.dark ? 'white--text' : ''}`">
                {{ $t('cancel') }}
              </span>
            </u-btn>
            <u-btn :disabled="!uidEntryComplete" @click="findAppliance">
              {{ $t('continue') }}
            </u-btn>
          </v-card-actions>
        </div>
      </div>
      <div v-if="step === 2" :class="step === 2 ? 'd-flex flex-column flex-grow-1' : ''">
        <v-card-title class="headline pa-0">
          {{ $t('disconnected_warning1') }}
        </v-card-title>
        <div class="mt-4 mb-4 text-secondary">{{ $t('uid') }}: {{ uidOrSerial }}</div>
        <ValidationObserver ref="obs2">
          <div class="mt-30">
            {{ $t('verify_appliance_type') }}
          </div>
          <ValidationProvider v-slot="{ errors }" :rules="step === 2 ? 'required' : ''">
            <v-radio-group v-model="prod_type" row hide-details :error-messages="errors">
              <v-radio value="NGFW" :label="$t('ng_firewall')" />
              <v-radio value="MFW" :label="$t('micro_edge')" />
            </v-radio-group>
          </ValidationProvider>
        </ValidationObserver>
        <div class="mt-10" v-html="$t('disconnected_warning2')" />
        <div v-html="$t('disconnected_warning3')" />
        <div class="d-flex flex-row justify-left">
          <v-card-actions class="px-0 mt-4">
            <v-spacer />
            <u-btn @click="onBack">
              {{ $t('back') }}
            </u-btn>
            <u-btn :disabled="!prod_type" @click="populateFields">
              {{ $t('continue') }}
            </u-btn>
          </v-card-actions>
        </div>
      </div>
      <!-- prompt for accept license for entry by serial# when appliance not found -->
      <div v-if="step === 3" :class="step === 3 ? 'd-flex flex-column flex-grow-1' : ''">
        <div class="mt-4">
          <div class="mt-30">
            {{ $t('license_setup_not_located') }}
          </div>
          <ValidationObserver ref="validateForm">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <u-checkbox v-model="acceptedLicense" :error-messages="errors" required>
                <template #label>
                  <div @click.stop="">
                    <i18n path="setup_agreement">
                      <a :href="eulaLink" target="_blank" @click.stop="">{{ $t('setup_eula') }}</a>
                    </i18n>
                  </div>
                </template>
              </u-checkbox>
            </ValidationProvider>
          </ValidationObserver>
        </div>
        <div class="d-flex flex-row justify-left">
          <v-card-actions class="px-0 mt-4">
            <v-spacer />
            <u-btn @click="onBack">
              {{ $t('back') }}
            </u-btn>
            <u-btn :disabled="!acceptedLicense" @click="configureAndSaveAppliance">
              {{ $t('add_appliance') }}
            </u-btn>
          </v-card-actions>
        </div>
      </div>
      <!-- prompt for config parameters -->
      <div v-if="step === 4" :class="step === 4 ? 'd-flex flex-column flex-grow-1' : ''">
        <v-card-subtitle class="headline pa-0">
          {{ $t('setup_ngfw_config') }}
        </v-card-subtitle>
        <div class="my-4 text-secondary">{{ $t('uid') }}: {{ appliance.Uid }}</div>
        <ValidationObserver ref="validateForm" v-slot="{ passes }">
          <v-form>
            <!-- password field -->
            <ValidationProvider v-slot="{ errors }" name="password" rules="required|min:6">
              <u-text-field
                v-model="accountPassword"
                :append-icon="passwordReveal ? 'mdi-eye' : 'mdi-eye-off'"
                :type="passwordReveal ? 'text' : 'password'"
                :label="$t('setup_admin_password') + '*'"
                class="mb-4"
                :error-messages="errors"
                @click:append="passwordReveal = !passwordReveal"
              >
                <template v-if="errors.length" #append>
                  <u-errors-tooltip :errors="errors" />
                </template>
              </u-text-field>
            </ValidationProvider>
            <!-- confirm password field -->
            <ValidationProvider v-slot="{ errors }" name="confirm" rules="required|confirmed:password">
              <u-text-field
                v-model="confirmPassword"
                :label="$t('password_confirm') + '*'"
                type="password"
                class="mb-4"
                :error-messages="errors"
              >
                <template v-if="errors.length" #append>
                  <u-errors-tooltip :errors="errors" />
                </template>
              </u-text-field>
            </ValidationProvider>

            <!-- email field -->
            <div v-if="prod_type === 'NGFW'">
              <ValidationProvider v-slot="{ errors }" name="email" rules="required|email">
                <u-text-field
                  v-model="accountEmail"
                  :label="$t('setup_admin_email')"
                  class="mb-4"
                  append-icon="mdi-email"
                  :error-messages="errors"
                >
                  <template v-if="errors.length" #append>
                    <u-errors-tooltip :errors="errors" />
                  </template>
                </u-text-field>
              </ValidationProvider>
            </div>
            <!-- install type field -->
            <ValidationProvider v-slot="{ errors }" rules="required">
              <u-select
                v-model="installType"
                :items="installTypeValues"
                :label="$t('setup_install_type')"
                class="mb-4"
                :error-messages="errors"
              >
                <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
              </u-select>
            </ValidationProvider>

            <!-- Time zone field -->
            <ValidationProvider v-slot="{ errors }" rules="required">
              <v-autocomplete
                v-if="prod_type === 'MFW'"
                v-model="timeZone"
                :items="openwrtTimeZoneValues"
                item-text="text"
                item-value="value"
                :label="$t('setup_time_zone')"
              >
                <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
              </v-autocomplete>
              <v-autocomplete
                v-else
                v-model="timeZone"
                :items="timezoneValues"
                item-text="text"
                item-value="value"
                :label="$t('setup_time_zone')"
              >
                <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
              </v-autocomplete>
            </ValidationProvider>
            <div>
              <v-icon small color="info">mdi-restart</v-icon>
              {{ $t('time_zone_change_warning') }}
            </div>

            <div>
              <ValidationProvider v-slot="{ errors }" name="tag" rules="required|max:70">
                <u-text-field
                  v-model="tag"
                  :label="$t('assign_label')"
                  class="my-4 mt-4"
                  :error-messages="errors"
                  maxlength="70"
                >
                  <template v-if="errors.length" #append>
                    <u-errors-tooltip :errors="errors" />
                  </template>
                </u-text-field>
              </ValidationProvider>

              <u-text-field v-model="hostname" :label="$t('update_hostname')" class="my-4" />
              <u-text-field v-model="domainname" :label="$t('update_domainname')" class="my-4" />

              <u-text-field v-model="location" :label="$t('update_location')" class="my-4" />
              <ValidationProvider v-if="wifiRegionCompatible" v-slot="{ errors }" name="wifiRegion" rules="required">
                <u-autocomplete
                  v-model="wifiRegion"
                  :items="countries"
                  item-text="Name"
                  item-value="Code"
                  :label="$t('update_wifi_region')"
                  class="my-4"
                  :disabled="loadingCountries"
                  :error-messages="errors"
                  clearable
                >
                  <template v-if="errors.length" #append>
                    <u-errors-tooltip :errors="errors" />
                  </template>
                </u-autocomplete>
              </ValidationProvider>
            </div>
            <v-card-title v-if="validUidAndSN" class="ma-0 px-0">
              {{ $t('assign_subscription_to_this_appliance') }}
            </v-card-title>
            <u-grid
              v-if="validUidAndSN"
              id="subscriptions"
              selection-type="singleAction"
              :column-defs="columnDefs"
              :enable-export-csv="false"
              :selection.sync="selectedRows"
              :fetching="$store.state.subscriptions.fetchingUnassigned"
              :row-data="subs"
              style="height: 300px"
              @refresh="getSubscriptionInfo(true)"
            />
            <div v-if="prod_type === 'NGFW' && templates.length">
              <v-card-title class="mt-3 mb-2">
                {{ $t('push_template') }}
              </v-card-title>
              <u-grid
                id="policies-templates-grid"
                :column-defs="templateColumnDefs"
                selection-type="singleAction"
                :selection.sync="selectedTemplates"
                :enable-csv-export="false"
                :fetching="$store.state.policies.fetchingTemplates"
                :row-data="templates"
                stateless
                style="height: 300px"
                @refresh="loadTemplates(true)"
              >
              </u-grid>
            </div>
            <div class="mt-4">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <u-checkbox v-model="acceptedLicense" :error-messages="errors" required>
                  <template #label>
                    <div @click.stop="">
                      <i18n path="setup_agreement">
                        <a :href="eulaLink" target="_blank" @click.stop="">{{ $t('setup_eula') }}</a>
                      </i18n>
                    </div>
                  </template>
                </u-checkbox>
              </ValidationProvider>
            </div>
            <div class="d-flex flex-row justify-left">
              <v-card-actions class="px-0 mt-4">
                <v-spacer />
                <u-btn @click="onBack">
                  {{ $t('back') }}
                </u-btn>
                <u-btn :disabled="!acceptedLicense" @click="passes(configureAndSaveAppliance)">
                  {{ $t('add_appliance') }}
                </u-btn>
              </v-card-actions>
            </div>
          </v-form>
        </ValidationObserver>
      </div>
    </template>
  </u-page>
</template>

<script>
  import appliances from '@/plugins/ut/ut-appliances'
  import subscriptions from '@/plugins/ut/ut-subscriptions'
  import grids from '@/plugins/ut/ut-grids'
  import vuntangle from '@/plugins/vuntangle'
  import store from '@/store'

  export default {
    /**
     * makes sure that appliances store is loaded before computing page data (appliance exists)
     * usually when accessing the appliance details page directly on app launch
     */
    async beforeRouteEnter(to, from, next) {
      store.commit('SET_PAGE_LOADER', true)
      await store.dispatch('appliances/fetchFullAppliances')
      store.commit('SET_PAGE_LOADER', false)
      next()
    },
    props: {
      // boolean to show/hide dialog
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        columnDefs: grids.getSubscriptionsColumnDefs(),
        templateColumnDefs: grids.getTemplateColumnDefs([
          'MasterApplianceUID',
          'LastSyncHash',
          'LastSynced',
          'DateCreated',
          'DateUpdated',
        ]),
        selectedRows: [],
        selectedTemplates: [],
        entryType: 'uid',
        uidOrSerial: null,
        uidSupportUrl:
          'https://support.edge.arista.com/hc/en-us/articles/115016014668-Where-do-I-find-the-UID-of-my-Untangle-server-',
        progress: false,
        step: 1,
        location: '',
        wifiRegion: '',
        prod_type: null,
        tag: null,
        hostname: 'untangle',
        domainname: 'example.com',
        appliance: null,
        connected: false,
        subs: [],
        setupStep: 1,
        timezoneValues: this.$vuntangle.dates.timeZones,
        openwrtTimeZoneValues: this.$vuntangle.dates.timeZones.filter(tz => !!tz.openwrt),
        installTypeValues: appliances.getInstallTypeValues(),
        passwordReveal: false,
        accountName: 'admin',
        accountPassword: '',
        confirmPassword: '',
        accountEmail: '',
        installType: '',
        timeZone: 'America/Boise',
        configFailed: false,
        redirectToAppliance: false,
        acceptedLicense: false,
        loadingCountries: false, // flag while fetching countries
      }
    },
    computed: {
      /**
       * Make sure policy templates are only returned once appliances has loaded.
       *
       * @return {Array}
       */
      templates() {
        return this.$store.state.appliances.list && this.$store.state.policies.templates
          ? this.$store.state.policies.templates
          : []
      },
      uidEntryComplete() {
        return (
          (this.entryType === 'serial' && this.uidOrSerial?.length >= 11) ||
          (this.entryType === 'uid' && this.uidOrSerial?.length >= 19)
        )
      },
      validUidAndSN() {
        return this.entryType === 'uid' || (this.connected && this.entryType === 'serial')
      },
      wifiRegionCompatible: ({ appliance }) => appliances.isWifiRegionCompatible(appliance),

      countries() {
        return this.$store.state.data.countries || []
      },
      eulaLink() {
        return 'https://www.arista.com/assets/data/pdf/software-agreement/EndUserLicenseAgreement.pdf'
      },
    },
    watch: {
      // load templates for NGFW
      prod_type: {
        immediate: true,
        handler(newProdType) {
          if (newProdType === 'NGFW') {
            this.loadTemplates()
          }
        },
      },
    },
    mounted() {
      this.getCountries()
    },
    created() {
      const passedInUid = this.$route.params.id
      if (passedInUid) {
        this.uidOrSerial = passedInUid
      }
      const ccViewModel = this.$store.state.data.ccViewModel
      if (ccViewModel) this.accountEmail = ccViewModel.SelectedUserAccountSettings.AccountEmail
    },
    methods: {
      // fetch countries
      async getCountries() {
        this.loadingCountries = true
        await this.$store.dispatch('data/fetchCountries')
        this.loadingCountries = false
      },
      loadTemplates(force = false) {
        this.$store.dispatch('policies/fetchTemplates', { force })
      },
      onClose() {
        this.$refs.obs?.reset()
        this.appliance = null
        this.entryType = 'uid'
        this.uidOrSerial = null
        this.show = false
        this.tag = null
        this.hostname = ''
        this.domainname = ''
        this.location = ''
        this.wifiRegion = ''
        this.connected = false
        this.prod_type = null
        this.completeLicenseChosen = false
        this.step = 1
        this.subs = []
        this.configFailed = false
      },
      onBack() {
        if (this.step === 2 || this.step === 3) this.step = 1
        else if (this.step === 4) this.step = this.entryType === 'serial' ? 3 : 2
      },
      /**
       * Queries the back end to gather information about the specified UID/Serial #
       * Back end will look value up in database and query cmd server for info.
       * Return information will either be an appliance object or null
       * Also will return error messages if UID is already in use
       */
      async findAppliance() {
        const valid = await this.$refs.obs.validate()
        if (!valid) {
          return
        }
        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await this.$store.dispatch('appliances/findAppliance', {
          uid: this.uidOrSerial,
          paramOrder: 'uid',
        })
        this.$store.commit('SET_PAGE_LOADER', false)
        if (response.message) {
          // back end came back with an issue about adding this appliance?
          vuntangle.toast.add(this.$t(response.message), 'error')
          return
        }
        // data will be sent back from find as long as appliance is either found in d-b or on cmd
        if (response.data) {
          // appliance connected to cmd server, info acquired
          this.appliance = response.data
          this.redirectToAppliance = true
          // populate input fields for modifying hostname, location, tag
          if (response.data.Hostname) {
            this.domainname = response.data.Domain
            const dLen = this.domainname.length
            const hLen = response.data.Hostname.length
            this.hostname = response.data.Hostname.substring(0, hLen - dLen - 1)
          }
          if (this.hostname === null) this.hostname = ''
          if (this.domainname === null) this.domainname = ''

          // try to prefill the location
          if (response.data.Location) {
            this.location = response.data.Location
          } else if (response.data.IpLocation?.City && response.data.IpLocation?.StateCode) {
            this.location = `${response.data.IpLocation.City}, ${response.data.IpLocation.StateCode}`
          }

          // try to prefill the wifiRegion
          if (this.wifiRegionCompatible && response.data.IpLocation?.CountryCode) {
            this.wifiRegion = response.data.IpLocation.CountryCode
          }

          this.tag = response.data.ApplianceTag
          this.timeZone = response.data.setupCompleted
            ? response.data.Timezone
            : Intl.DateTimeFormat().resolvedOptions().timeZone
          this.prod_type = response.data.ProductLine
            ? response.data.ProductLine
            : appliances.isNGFW(this.uidOrSerial)
            ? 'NGFW'
            : null
          this.connected = response.data.IsConnectedToCmd
          // get available subscriptions info
          this.getSubscriptionInfo()
          this.step = this.connected ? 4 : 2
        } else if (this.entryType === 'serial') this.step = 3
        else {
          this.step = 2
          this.prod_type = appliances.isNGFW(this.uidOrSerial) ? 'NGFW' : null
        }
      },

      /**
       * Called after user has indicated appliance type for off-line appliances.  Verifies that user has made
       * a choice before going on.
       */
      async populateFields() {
        const valid = await this.$refs.obs2.validate()
        if (!valid) {
          return
        }
        if (!this.appliance) this.appliance = {}
        this.appliance.ProductLine = this.prod_type
        if (this.prod_type === 'MFW') this.hostname = 'mfw'
        this.appliance.Uid = this.uidOrSerial
        this.appliance.setupCompleted = true
        this.step = 4
        // now that an appliance type has been defined, retrieve eligible subscriptions for subscription assignment
        this.getSubscriptionInfo()
      },

      /**
       * Looks up all unassigned subscriptions and filters out any that are not applicable to the appliance
       * product type.  These are then populated in the assign subscription grid in the second screen
       */
      async getSubscriptionInfo(force = true) {
        await this.$store.dispatch('subscriptions/fetchUnassigned', { force })
        const allUnassigned = this.$store.state.subscriptions.unassigned
        this.subs = []
        if (Array.isArray(allUnassigned)) {
          allUnassigned.forEach(nxtSub => {
            if (subscriptions.isSubscriptionAssignableToAppliance(nxtSub, this.appliance)) this.subs.push(nxtSub)
          })
        }
        this.selectedRows = []
      },
      /**
       * Called when user enters data to configure NGFW appliance.  Takes input values
       * and calls back end to push configuration
       * @returns {Promise<void>}
       */
      async configureAndSaveAppliance() {
        this.$store.commit('SET_PAGE_LOADER', true)
        // open remote access window before any ajax calls so the browser does not consider it a popup
        let remoteAccessWindow = null
        if (this.redirectToAppliance) {
          remoteAccessWindow = window.open(`${process.env.VUE_APP_BASE_PATH}appliance-remote-access.html`)
        }
        const response = await this.$store.dispatch('appliances/addAndConfigureAppliance', {
          uid: this.entryType === 'serial' ? this.uidOrSerial : this.appliance.Uid,
          adminUser: this.accountName,
          adminPassword: this.accountPassword,
          accountEmail: this.accountEmail,
          timeZone: this.timeZone,
          openWrtTimeZone: this.timezoneValues.find(tz => tz.value === this.timeZone)?.openwrt,
          installType: this.installType,
          newDomainName: this.domainname,
          newHostName: this.hostname,
          newLocation: this.location,
          newWifiRegion: this.wifiRegion,
          newTag: this.tag,
          subscription: this.selectedRows.length > 0 ? this.selectedRows[0].subscriptionName : '',
          masterUID: this.selectedTemplates.length > 0 ? this.selectedTemplates[0].MasterApplianceUID : '',
          prodType: this.prod_type,
          paramOrder:
            'uid adminUser adminPassword accountEmail timeZone openWrtTimeZone installType newDomainName newHostName newLocation newWifiRegion newTag subscription masterUID prodType',
        })
        // multiple error messages can be sent back - display any and all of them
        const msgTypes = ['addMessage', 'subMessage', 'templateMessage', 'locationMessage']
        msgTypes.forEach(msg => {
          if (response[msg]) vuntangle.toast.add(this.$t(response[msg]), 'error')
        })
        // as long as appliance object comes back populated, go ahead and redirect to detail screen for this appliance
        if (response.appliance) {
          // load remote access window or close it if config failed
          if (remoteAccessWindow) {
            if (!this.configFailed) {
              appliances.doRemoteAccess(this.appliance.Uid, remoteAccessWindow)
            } else {
              remoteAccessWindow.close()
            }
          }
          // update subscriptions store so that it reflects changes
          if (this.selectedRows.length > 0)
            await this.$store.dispatch('subscriptions/fetchSubscriptions', { force: true })
          const msg = this.redirectToAppliance
            ? this.configFailed
              ? this.$t('delayed_complete_no_config')
              : this.$t('complete_remote')
            : this.$t('complete')
          vuntangle.toast.add(msg)
          this.$store.commit('SET_PAGE_LOADER', false)
          this.$router.push({ name: 'appliances-id', params: { id: response.appliance.UniqueIdentifier } })
        } else {
          // close remote access window if appliance was not found
          if (remoteAccessWindow) {
            remoteAccessWindow.close()
          }
          this.$store.commit('SET_PAGE_LOADER', false)
          this.$router.push({ name: 'appliances' })
        }
      },
    },
  }
</script>
