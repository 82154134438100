import { set } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import { captureException } from '@sentry/vue'
import vuntangle from '@/plugins/vuntangle'
import api from '@/plugins/ut/ut-api'
import i18n from '@/plugins/vue-i18n'

const getDefaultState = () => {
  return {
    list: null,
    fetching: false,
    fetchingUnassigned: false,

    // active unassigned subscriptions
    unassigned: null,
  }
}

const getters = {
  list: state => state.list,
  fetching: state => state.fetching,
  unassigned: state => state.unassigned,
  getByApplianceId: state => uid => state.list.filter(sub => sub.serverId === uid),
}

const mutations = {
  RESET: state => Object.assign(state, getDefaultState()),

  SET_SUBSCRIPTIONS: (state, subscriptions) => (state.list = subscriptions),
  SET_UNASSIGNED: (state, subscriptions) => (state.unassigned = subscriptions),

  SET_FETCHING: (state, value) => (state.fetching = value),
  SET_FETCHING_UNASSIGNED: (state, value) => (state.fetchingUnassigned = value),

  UPDATE_SUBSCRIPTIONS: (state, updatedSubscriptions) => {
    updatedSubscriptions.forEach(updatedSub => {
      const idx = state.list.findIndex(sub => sub.subscriptionName === updatedSub.subscriptionName)
      if (idx !== -1) {
        set(state.list, idx, updatedSub)
      }
    })
  },
  ADD_SUBSCRIPTION: (state, subscription) => state.list.push(subscription),
  DELETE_SUBSCRIPTION: (state, subscriptionName) => {
    const subscriptionIndex = state.list.findIndex(r => r.subscriptionName === subscriptionName)

    if (subscriptionIndex !== -1) {
      state.list.splice(subscriptionIndex, 1)
    }
  },
  ADD_UNASSIGNED_SUBSCRIPTION: (state, subscription) => {
    // add unassigned subscription only if it does not exist
    const subscriptionIndex = state.unassigned?.findIndex(r => r.subscriptionName === subscription.subscriptionName)
    if (subscriptionIndex > -1) {
      state.unassigned.push(subscription)
    }
  },
  DELETE_UNASSIGNED_SUBSCRIPTION: (state, subscriptionName) => {
    const subscriptionIndex = state.unassigned?.findIndex(r => r.subscriptionName === subscriptionName)

    if (subscriptionIndex > -1) {
      state.unassigned.splice(subscriptionIndex, 1)
    }
  },
}

const actions = {
  /**
   * Async call to fetch subscriptions
   * @typedef {Object} args
   * @property {Object} state
   * @property {Function} commit
   *
   * @param {args}    param
   * @param {Object}  options
   * @param {Boolean} options.force - If true forces the call
   */
  async fetchSubscriptions({ state, commit }, options) {
    if (state.fetching || (state.list !== null && !options?.force)) {
      return
    }

    commit('SET_FETCHING', true)
    const response = await api.cloud('Untangle_CommandCenter', 'GetSubscriptions', {
      getExpiredSubs: true,
      paramOrder: 'getExpiredSubs',
    })

    if (response.success && response.data) {
      commit('SET_SUBSCRIPTIONS', response.data)
    }
    commit('SET_FETCHING', false)
  },

  /**
   * Async call to fetch active unassigned subscriptions
   * @typedef {Object} args
   * @property {Object} state
   * @property {Function} commit
   *
   * @param {args}    param
   * @param {Object}  options
   * @param {Boolean} options.force - If true forces the call
   */
  async fetchUnassigned({ state, commit }, options) {
    if (state.fetchingUnassigned || (state.unassigned !== null && !options?.force)) {
      return
    }

    commit('SET_FETCHING_UNASSIGNED', true)
    const response = await api.cloud('Untangle_CommandCenter', 'GetActiveUnassignedSubscriptions')

    if (response.success && response.data) {
      const subscriptions = response.data
      commit('SET_UNASSIGNED', subscriptions)
    }
    commit('SET_FETCHING_UNASSIGNED', false)
  },

  /**
   * Async call to assign subscriptions
   * @typedef {Object} args
   * @property {Object} state
   * @property {Function} commit
   *
   * @param {args}   param
   * @param {Object} data
   * @param {String} data.uid - The selected appliance UID
   * @param {String} data.subscriptionName - The selected subscription name
   * @param {String} data.paramOrder - "uid subscriptionName"
   */
  async assignSubscription({ commit }, data) {
    window.ga('send', { hitType: 'event', eventCategory: 'Subscriptions', eventAction: 'Assign Subscription' })
    const response = await api.cloud('Untangle_CommandCenter', 'AssignSubscription', data)

    if (response.data?.status && response.success && response.data) {
      // update the appliance from the server
      const updatedAppliance = response.data.uid
      commit('appliances/UPDATE_APPLIANCE', updatedAppliance, { root: true })

      // update the subscriptions from the server
      const updatedSubscriptions = response.data.updatedSubscriptions
      if (updatedSubscriptions && updatedSubscriptions.length > 0) {
        commit('UPDATE_SUBSCRIPTIONS', updatedSubscriptions)

        // check if these subscriptions should be added or deleted from the unassigned subscriptions list
        updatedSubscriptions.forEach(subscription => {
          if (subscription.serverId) {
            commit('DELETE_UNASSIGNED_SUBSCRIPTION', subscription.subscriptionName)
          } else {
            commit('ADD_UNASSIGNED_SUBSCRIPTION', subscription)
          }
        })
      }

      vuntangle.toast.add(i18n.t('assign_sub_success'))
    } else {
      let message = (response.message || '') + (response.data?.message || '')

      if (i18n.te(`${message}`)) {
        message = `${message}`
      } else if (i18n.te(`appliance.${message}`)) {
        message = `appliance.${message}`
      } else {
        message = 'assign_sub_failed'
      }

      // failed to subscribe, show error message
      vuntangle.toast.add(i18n.t(message), 'error')
    }
  },

  /**
   * Async call to unassign subscription
   * @typedef {Object} args
   * @property {Object} state
   * @property {Function} commit
   *
   * @param {args}   param
   * @param {Object} subscription
   */
  async unassignSubscription({ commit }, subscription) {
    const response = await api.cloud('Untangle_CommandCenter', 'RemoveSubscription', {
      subscriptionName: subscription.subscriptionName,
      uid: subscription.serverId,
      paramOrder: 'subscriptionName uid',
    })

    // check for error response
    const data = response.data
    if (!response.success || !data || data.status?.[subscription.subscriptionName]) {
      return response
    }

    // update appliance with the response uid, if we get an appliance back
    if (data.uid) {
      commit('appliances/UPDATE_APPLIANCE', data.uid, { root: true })
    }

    // update the subscription
    const updatedSubscription = cloneDeep(subscription)
    updatedSubscription.serverId = null
    commit('UPDATE_SUBSCRIPTIONS', [updatedSubscription])

    // add as an unassigned subscription
    commit('ADD_UNASSIGNED_SUBSCRIPTION', updatedSubscription)

    return response
  },

  /**
   * Async call to share a subscription
   * @typedef {Object} args
   * @property {Object} state
   * @property {Function} commit
   *
   * @param {args}   param
   * @param {Object} data
   * @param {String} data.subscriptionName - The selected subscription name
   * @param {String} data.targetUserEmail - The user email to whom subscription is shared
   * @param {String} data.paramOrder - "subscriptionName targetUserEmail"
   */
  async shareSubscription({ commit }, data) {
    const response = await api.cloud('Untangle_CommandCenter', 'ShareSubscription', {
      subscriptionName: data.subscription.subscriptionName,
      targetUserEmail: data.targetUserEmail,
      paramOrder: 'subscriptionName targetUserEmail',
    })

    // update the subscription
    if (!response.message) {
      const subscription = cloneDeep(data.subscription)
      subscription.serverId = null
      subscription.isShared = true
      subscription.subscriptionCustomerEmail = data.targetUserEmail.toLowerCase()
      commit('UPDATE_SUBSCRIPTIONS', [subscription])
    }

    return response
  },

  /**
   * Async call to unshare a subscription
   * @typedef {Object} args
   * @property {Object} state
   * @property {Function} commit
   *
   * @param {args}   param
   * @param {Object} data
   * @param {String} data.subscriptionName - The selected subscription name
   * @param {String} data.paramOrder - "subscriptionName"
   */
  async unshareSubscription({ commit }, subscription) {
    const response = await api.cloud('Untangle_CommandCenter', 'UnshareSubscription', {
      subscriptionName: subscription.subscriptionName,
      paramOrder: 'subscriptionName',
    })

    // update the subscription
    if (!response.message) {
      const updatedSubscription = cloneDeep(subscription)
      updatedSubscription.serverId = null
      updatedSubscription.isShared = false
      updatedSubscription.subscriptionCustomerEmail = null
      commit('UPDATE_SUBSCRIPTIONS', [updatedSubscription])
    }

    return response
  },

  /**
   * Async call to upgrade subscription(s)
   * @typedef {Object} args
   * @property {Object} state
   * @property {Function} commit
   *
   * @param {args}   param
   * @param {Object} data
   * @param {String} data.upgradeProduct - The product name
   * @param {String} data.subscriptionPeriod - The subscription period (e.g. "1 Year")
   * @param {String} data.licensedDeviceCount - The supported number of devices (e.g. "Up to 12")
   * @param {Array}  data.selectedSubscriptions - The subscription names array (e.g. ["A-S00207819", ["A-S00207820"])
   * @param {Number} data.upgradeCost - The total cost for the upgrade
   * @param {String} data.organization - The organization name for non profit or public sector clients
   * @param {String} data.poNumber - The purchase order number
   * @param {String} data.coupon - The coupon number
   * @param {String} data.paramOrder - "upgradeProduct subscriptionPeriod licensedDeviceCount selectedSubscriptions upgradeCost organization poNumber coupon",
   */
  async upgradeSubscriptions({ commit }, data) {
    const response = await api.cloud('Untangle_CommandCenter', 'UpgradeSubscriptions', data)
    if (response.success && response.data) {
      // update the balances in the billing object
      commit('financial/UPDATE_BILLING_BALANCE', response.data.billingBalance, { root: true })

      // remove old subscriptions
      data.selectedSubscriptions.forEach(subscriptionName => {
        commit('DELETE_SUBSCRIPTION', subscriptionName)
      })

      // add new subscription
      commit('ADD_SUBSCRIPTION', response.data.upgradedSubscription)
    }

    return response
  },

  /**
   * Async action call to renew/reinstate subscription
   *
   * @param {Object} data
   * @param {String} data.subscriptionName - The subscription name
   * @param {String} data.purchaseOrderNumber - The subscription PO number
   * @param {String} data.paymentId - The payment method id used to pay
   * @param {String} data.paramOrder - "subscriptionName purchaseOrderNumber paymentId"
   */
  async renewSubscription({ commit }, data) {
    const response = await api.cloud('Untangle_CommandCenter', 'RenewOrReinstateSub', data)

    // handle errors
    if (!response.success) {
      const errorMsg = response.message || 'renew_sub_failed'
      captureException(new Error(errorMsg))
      vuntangle.toast.add(i18n.t('renew_sub_failed'), 'error')
      return response
    }

    // update on success
    vuntangle.toast.add(i18n.t('renew_sub_success'))

    // update subscriptions, billing info, and possibly the appliance if it was tied to the renewed subscription
    commit('UPDATE_SUBSCRIPTIONS', response.data.subscriptions)
    commit('financial/UPDATE_BILLING_BALANCE', response.data.billingBalance, { root: true })
    if (response.data.appliance) {
      commit('appliances/UPDATE_APPLIANCE', response.data.appliance, { root: true })
    }

    // return the response as is needed in the component
    return response
  },

  /**
   * Async action call to toggle auto renewal for selected subscription
   *
   * @param {Object} data
   * @param {String} data.subNames - Array of subscriptions names to toggle
   * @param {String} data.paramOrder - "subNames reason"
   */
  async toggleAutoRenewal({}, data) {
    const response = await api.cloud('Untangle_CommandCenter', 'ToggleSubscriptionAutoRenewal', data)
    return response
  },

  /**
   * Async action call to update subscription's description
   *
   * @param {Object} data
   * @param {String} data.SelectedSubscriptions - Array of subscriptions names to toggle
   * @param {String} data.Description - Description
   * @param {String} data.paramOrder - "subNames description"
   */
  async updateDescription({ commit }, data) {
    const subscriptionNames = data.SelectedSubscriptions.map(sub => sub.subscriptionName)
    const response = await api.cloud('Untangle_CommandCenter', 'UpdateSubscriptionDescription', {
      SelectedSubscriptions: subscriptionNames,
      Description: data.Description,
      paramOrder: 'SelectedSubscriptions Description',
    })
    // handle errors
    if (!response.success || !response.data) {
      const errorMsg = response.message || 'update_sub_description_failed'
      captureException(new Error(errorMsg))
      vuntangle.toast.add(i18n.t('update_sub_description_failed'), 'error')
      return response
    }
    const subscriptions = []
    data.SelectedSubscriptions.forEach(sub => {
      const subscription = cloneDeep(sub)
      subscription.description = data.Description
      subscriptions.push(subscription)
    })

    commit('UPDATE_SUBSCRIPTIONS', subscriptions)

    vuntangle.toast.add(i18n.t('update_sub_description_success'))

    return response
  },
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions,
}
