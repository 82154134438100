<template>
  <u-page :title="$t('audit_history')" full-height>
    <template #actions>
      <u-btn :disabled="selectedRows.length !== 1" @click="addAlertRule">
        {{ $t('add_alert_rule') }}
      </u-btn>
    </template>

    <u-grid
      id="audit-history"
      selection-type="singleAction"
      :no-data-message="$t('no_data')"
      :column-defs="columnDefs"
      :fetching="$store.state.appliances.fetching || $store.state.events.auditHistoryFetching"
      :row-data="auditHistory"
      :selection.sync="selectedRows"
      @refresh="loadAuditHistory(true)"
    />
    <u-dialog
      :show-dialog="!!filteredRecords.length"
      :title="$t('additional_information')"
      :width="600"
      :buttons="[
        {
          'name': $t('ok'),
          'handler': 'close-dialog',
        },
      ]"
      @close-dialog="onCloseDialog"
    >
      <extra-details
        :filtered-records="filteredRecords"
        :current-index.sync="currentIndex"
        pre-wrap
        @get-details="getDetails"
      />
    </u-dialog>
  </u-page>
</template>
<script>
  import util from '@/plugins/ut/ut-util'
  import ExtraDetails from '@/components/widgets/ExtraDetails'
  import users from '@/plugins/ut/ut-users'

  export default {
    components: { ExtraDetails },
    /** Prevent/redirect access if user does not have rights */
    beforeRouteEnter(to, from, next) {
      if (!users.hasFullAccountAccess()) {
        next('/alerts/alerts-received')
      } else next()
    },
    data() {
      return {
        selectedRows: [],
        // list of records passed to detail dialog to navigate through
        filteredRecords: [],
        // index of the record being viewed from filteredRecords
        currentIndex: -1,
      }
    },
    computed: {
      columnDefs() {
        return [
          {
            headerName: this.$t('date_time'),
            width: 200,
            valueGetter: ({ data }) => data.DateCreated?.$date,
            valueFormatter: ({ value }) => this.$vuntangle.dates.formatDateFromApi(value) || this.$('unknown'),
            comparator: (a, b) => this.$vuntangle.dates.compareDates(a, b),
          },
          {
            headerName: this.$t('uid'),
            field: 'DeviceId',
            hide: true,
            valueGetter: params =>
              params.data.DeviceId ? util.obfuscateUid(params.data.DeviceId) : this.$t('not_assigned'),
          },
          {
            headerName: this.$t('details'),
            field: 'Message',
            valueGetter: params => util.obfuscateUidsInString(params.data.Message),
          },
          {
            headerName: this.$t('view_details'),
            field: 'ViewDetails',
            sortable: false,
            filter: false,
            cellRenderer: 'ActionButton',
            cellRendererParams: {
              icon: 'mdi-information',
              color: 'primary',
              xSmall: true,
              class: 'ml-5',
              click: params => {
                this.viewDetails(params)
              },
            },
          },
        ]
      },
      /**
       * Make sure audit history is only returned once appliances has loaded.
       *
       * @return {Array}
       */
      auditHistory() {
        const auditRecords =
          this.$store.state.appliances.list && this.$store.state.events.auditHistory
            ? this.$store.state.events.auditHistory
            : []
        // convert audit messages - convert UIDs to tags/hostnames (UID) and obfuscate UID if appropriate
        auditRecords.forEach(function (message) {
          message.Message = util.addApplianceTagsInString(message.Message)
        })
        return auditRecords
      },
    },
    created() {
      this.$store.commit('SET_ALERTS_SELECTION', this.$route.path)
      this.loadAuditHistory()
    },
    methods: {
      loadAuditHistory(force = false) {
        this.$store.dispatch('appliances/fetchAppliances')
        this.$store.dispatch('events/fetchAuditHistory', { force })
      },
      addAlertRule() {
        // pass the alert message to rule creation page
        this.$router.push({
          name: 'alerts-rules-id',
          params: {
            id: 'new', // 'new' is used as id when creating a rule
            eventMessage: this.selectedRows[0].Message,
          },
        })
      },

      onCloseDialog() {
        this.filteredRecords = []
        this.currentIndex = -1
      },

      /**
       * builds the props needed for details dialog and displays the dialog
       * @param {*} param clicked row to fetch details for
       */
      viewDetails(params) {
        params.api.forEachNodeAfterFilterAndSort(node => {
          const row = node.data
          this.filteredRecords.push({
            Id: row._id,
            DateCreated: row.DateCreated.$date,
            Message: util.addApplianceTagsInString(row.Message),
          })
        })
        this.currentIndex = params.node.rowIndex
      },

      /**
       * Calls backend to get the alert payload
       * and sends the response back to dialog using callback
       * @param {Object} row - record to fetch complete details for
       * @param {Object} callback - callback to send back complete details to dialog component
       */
      async getDetails({ row, callback }) {
        const response = await this.$store.dispatch('events/fetchAccountEventDetails', { id: row.Id })
        if (response) {
          response.Message = util.addApplianceTagsInString(response.Message)
        }
        callback(response)
      },
    },
  }
</script>
