/**
 * This plugin is a collection of globally available methods used throughout the project
 * to define and manipulation appliances data
 */
import store from '@/store'
import i18n from '@/plugins/vue-i18n'
import vuntangle from '@/plugins/vuntangle'
import api from '@/plugins/ut/ut-api'
import util from '@/plugins/ut/ut-util'

const appliances = {
  /**
   * Is the appliance wifi region compatible.
   *
   * @param {Object} appliance
   *
   * @returns {boolean}
   */
  isWifiRegionCompatible: appliance =>
    appliance.ProductLine === 'NGFW' &&
    parseFloat(appliance.SoftwareVersion) >= 17.0 &&
    (appliance.ApplianceModel.toLowerCase() === 'w8' || appliance.ApplianceModel.toLowerCase() === 'q8w'),

  /**
   * Is the appliance totp compatible
   * Right now, just ngfw version 17 and above. MFW 4.3 is capable but isn't compatible yet
   */
  isApplianceTotpCompatible: appliance =>
    appliance.ProductLine === 'NGFW' && parseFloat(appliance.SoftwareVersion) >= 17.0,

  /**
   * Get marker data from the passed in appliances.
   *
   * @param {Object[]} appliances - array of appliance data
   *
   * @returns {Object[]} array of marker data for adding to the map
   */
  getApplianceMarkers(appliances) {
    const markers = []
    appliances.forEach(appliance => {
      // if the appliance location is unknown, do not add a marker
      if (!appliance.ApplianceLatitude || !appliance.ApplianceLongitude) {
        return
      }

      markers.push({
        position: {
          lat: appliance.ApplianceLatitude,
          lng: appliance.ApplianceLongitude,
        },
        id: appliance.UniqueIdentifier,
        link: { name: 'appliances-id', params: { id: appliance.UniqueIdentifier } },
        tooltip: i18n.t('map_tooltip', {
          hostName: appliance.Hostname,
          uid: appliance.Uid ? util.obfuscateUid(appliance.Uid) : i18n.t('not_assigned'),
          tag: appliance.ApplianceTag ? appliance.ApplianceTag : i18n.t('label_not_assigned'),
          serverIp: appliance.IpAddress,
          status: appliance.IsConnectedToCmd ? i18n.t('online') : i18n.t('offline'),
          hostCount: appliance.HostCount > 0 ? appliance.HostCount : 0,
          location: appliance.ApplianceLocation ? appliance.ApplianceLocation : i18n.t('unknown'),
        }),
      })
    })

    return markers
  },

  /**
   * Get line data from the passed in appliances.
   *
   * @param {Object[]} networkAppliances - array of network appliance data - will have the VPN status as well
   * @param {Object[]} appliances - array of appliance data - will have the location details
   *
   * @returns {Object[]} array of lines data for adding to the map
   */
  getApplianceLines(networkAppliances, appliances) {
    const lines = []
    let index = 0
    // create a line from each appliance to each other appliance
    for (let i = 0; i < networkAppliances.length; i++) {
      const appliance1 = appliances?.filter(appliance => appliance.Uid === networkAppliances[i].Uid)[0]
      if (!appliance1 || !appliance1.ApplianceLocation) continue
      const appliance1Status = this.isLicensedForVPN(appliance1)
      for (let j = i + 1; j < networkAppliances.length; j++) {
        const appliance2 = appliances?.filter(appliance => appliance.Uid === networkAppliances[j].Uid)[0]
        if (!appliance2 || !appliance2.ApplianceLocation) continue
        const appliance2Status = this.isLicensedForVPN(appliance2)
        lines.push({
          'id': index,
          'enabled':
            networkAppliances[i].VpnStatus && networkAppliances[j].VpnStatus && appliance1Status && appliance2Status,
          'path': [
            {
              lat: appliance1.ApplianceLatitude,
              lng: appliance1.ApplianceLongitude,
            },
            {
              lat: appliance2.ApplianceLatitude,
              lng: appliance2.ApplianceLongitude,
            },
          ],
        })
        index++
      }
    }
    return lines
  },

  /**
   * Looks at status flags from an appliance object and determines whether it is eligible to
   * be included in a VPN configuration
   *
   * @param appliance - object containng appliance info needed for determination (doesn't have to
   *                     be an entire appliance object - just needs ProductLine and StatusFlags
   * @returns {boolean} - true if appliance is considered eligible.
   */
  isLicensedForVPN(appliance) {
    if (appliance.ProductLine === 'MFW' || appliance.HasCompleteLicense || appliance.HasWireguardLicense) return true
    const OKStatusList = [
      'appliance_status_seat_count_exceeded',
      'appliance_status_license_expiring_soon',
      'appliance_status_trial_expiring_soon',
    ]
    if (
      appliance.StatusFlags &&
      appliance.StatusFlags.length === 2 &&
      !OKStatusList.includes(appliance.StatusFlags[1].Messages[0])
    )
      return false
    else return true
  },

  /**
   * Restore appliances from a backup.  Used in backups and policy templates.
   *
   * @param {number}   masterUid
   * @param {string[]} targetUids
   * @param {string}   backupFileName
   * @param {string}   backupMd5
   * @param {boolean}  maintainDevicesHostsUsers
   * @param {number}   templateId
   * @param {Function} onSuccess
   * @param {Function} onFailure
   *
   * @returns {Promise<void>}
   */
  async restoreAppliancesFromBackup(
    masterUid,
    targetUids,
    backupFileName,
    backupMd5,
    maintainDevicesHostsUsers,
    templateId,
    onSuccess,
    onFailure,
  ) {
    const response = await api.cloud('Untangle_CommandCenter', 'RestoreAppliancesFromBackup', {
      masterUid,
      targetUids,
      backupFileName,
      backupMd5,
      settingsExceptions: [],
      maintainDevicesHostsUsers,
      templateId,
      paramOrder:
        'masterUid targetUids backupFileName backupMd5 settingExceptions maintainDevicesHostsUsers templateId',
    })

    // return if error response
    if (!response.success || !response.data) {
      vuntangle.toast.add(i18n.t('sync_failed'), 'error')

      return
    }

    // loop through response from starting a restore
    for (const uid in targetUids) {
      // get the product line for the target appliance
      const getByUid = store.getters['appliances/getByUid']
      const appliance = getByUid(targetUids[uid])

      // for MFWs, do not validate the backup data checksum
      if (appliance.ProductLine === 'MFW') {
        onSuccess(targetUids[uid])

        return
      }

      if (appliance.ProductLine === 'NGFW') {
        this.getApplianceBackupData(targetUids[uid], backupMd5, 0, onSuccess, onFailure)
      }
    }
  },

  /**
   * Get the appliance backup data to see if the sync was successful.  Will
   * be called on an interval.
   *
   * @param {string}   uid
   * @param {string}   backupMd5
   * @param {Function} onSuccess
   * @param {Function} onFailure
   *
   * @returns {Promise<void>}
   */
  async getApplianceBackupData(uid, backupMd5, totalWaitTime, onSuccess, onFailure) {
    // check the sync status every 10 seconds for up to 5 minutes
    const maxWaitTime = 300000
    const waitInterval = 10000

    const backupResponse = await api.cloud('Untangle_CommandCenter', 'GetApplianceBackupData', {
      uid,
      paramOrder: 'uid',
    })

    // backups was not successful, call error callback
    if (!backupResponse.success) {
      onFailure(uid, 'sync_failed')

      return
    }

    // backup was a success, md5 matched
    const backupData = backupResponse.data
    if (backupData && backupMd5 === backupData.LastSuccessfulBackupHash) {
      onSuccess(uid)

      return
    }

    // check the sync status again in waitInterval milliseconds
    totalWaitTime += waitInterval
    if (totalWaitTime < maxWaitTime) {
      setTimeout(() => this.getApplianceBackupData(uid, backupMd5, totalWaitTime, onSuccess, onFailure), waitInterval)
    } else {
      // assume the sync failed
      onFailure(uid, '')
    }
  },

  /**
   * Checks a UID string to see if it matches the pattern specified for an NGFW appliance
   * @param uid
   * @returns {boolean} - true if string matches pattern
   */
  isNGFW(uid) {
    const regex = /\b([A-Za-z0-9]{4})-([A-Za-z0-9]{4})-([A-Za-z0-9]{4})-([A-Za-z0-9]{4})\b/
    if (uid.match(regex)) return true
    else return false
  },
  /**
   * @param uid - uid of the appliance to link to
   * @param {Window} remoteAccessWindow - a Window object that the remote access link will be loaded into
   * api call to request remote management session for the appliance
   */
  async doRemoteAccess(uid, remoteAccessWindow = null) {
    // @todo send a different ga event for setup wizard
    // window.ga('send', {hitType: 'event', eventCategory: 'Appliances', eventAction: 'Setup Wizard Launch'})
    window.ga('send', { hitType: 'event', eventCategory: 'Appliances', eventAction: 'Remote Access' })
    const response = await api.cloud('Untangle_CommandCenter', 'GetRemoteAccessChannelLink', {
      uid,
      paramOrder: 'uid',
    })

    // try to get channel link, display a toast error if not successful
    const channelLink = response.success && response.data?.channelLink ? response.data.channelLink : null
    // handle remote access window
    if (remoteAccessWindow) {
      if (channelLink) {
        remoteAccessWindow.location.href = channelLink
      } else {
        remoteAccessWindow.close()
        vuntangle.toast.add(i18n.t('appliance_remote_access_failed'), 'error')
      }
    }

    return channelLink
  },

  /**
   * Sends back a list of options for a drop-down prompting user for isntall type in NGFW installation
   * @returns {} - JSON list of object/values
   */
  getInstallTypeValues() {
    return [
      { text: i18n.t('school'), value: 'school' },
      { text: i18n.t('higher_education'), value: 'college' },
      { text: i18n.t('state_and_local_gov'), value: 'government' },
      { text: i18n.t('federal_government'), value: 'fedgovernment' },
      { text: i18n.t('nonprofit'), value: 'nonprofit' },
      { text: i18n.t('hospitality_and_retail'), value: 'retail' },
      { text: i18n.t('healthcare'), value: 'healthcare' },
      { text: i18n.t('banking_and_financial'), value: 'financial' },
      { text: i18n.t('home'), value: 'home' },
      { text: i18n.t('student'), value: 'student' },
      { text: i18n.t('other'), value: 'other' },
    ]
  },

  /**
   * Send to the appliance API.  This is mostly used for POST, PUT, and DELETE calls on the MFW
   *
   * @param {String} uid
   * @param {String} path
   * @param {Object} payload - not mandatory
   * @param {String} method - default is POST
   *
   * @returns {Promise<void>}
   */
  async sendToApplianceApi(uid, path, payload, method) {
    const response = await api.payload(
      {
        handler: 'Untangle_CommandCenter',
        paramOrder: 'payload',
        method: 'SendToApplianceApi',
      },
      {
        uid,
        path,
        payload,
        method,
      },
    )
    return {
      success: response.success && response.data?.success,
      data: response.data?.data,
      error: response.message,
    }
  },

  /**
   * used for IP CIDR validation by wireguard interface type
   *
   * @param {string} uid appliance UID
   * @param {string} cidr IP address CIDR
   */
  async addressChecker(uid, cidr) {
    let response
    try {
      response = await this.sendToApplianceApi(uid, 'netspace/check', { cidr })
    } catch (ex) {
      return this.$t('address_conflict_detect_fail')
    }

    try {
      const details = response.data ? JSON.parse(response.data) : null
      return details?.error || null
    } catch (ex) {
      return null
    }
  },
}

export default appliances
