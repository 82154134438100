import axios from 'axios'
import store from '@/store/index'

export default {
  /**
   * Generic method for calls to Rest Api
   *
   * @param url
   * @param method
   * @param data
   *
   * @returns response from api or null on error
   */
  async fromStoreApi(url, method, data = null) {
    try {
      return await axios({
        url,
        method,
        withCredentials: true,
        baseURL: store.getters['data/ccViewModel'].StoreUrl,
        headers: {
          'AUTHORIZATION': store.getters['data/ccViewModel'].Account.ApiToken,
          'User-Authorization': store.getters['data/ccViewModel'].User.ApiToken,
          'Content-Type': 'application/json',
        },
        ...(method === 'GET' ? { 'params': data } : { data }),
      })
    } catch (err) {
      return err.response
    }
  },
}
