var render = function render(){var _vm=this,_c=_vm._self._c;return _c('u-page',{attrs:{"full-height":"","title":_vm.$t('notification_profiles')},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('u-btn',{staticClass:"mr-2",on:{"click":_vm.displayAddNotificationProfile}},[_vm._v(" "+_vm._s(_vm.$t('add_notification_profile'))+" ")]),_c('u-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.selectedRows.length !== 1},on:{"click":_vm.displayEditNotificationProfile}},[_vm._v(" "+_vm._s(_vm.$t('edit_notification_profile'))+" ")]),_c('u-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.selectedRows.length === 0},on:{"click":_vm.displayDeleteNotificationProfilesDialog}},[_vm._v(" "+_vm._s(_vm.$t('delete_notification_profile'))+" ")])]},proxy:true}])},[_c('u-grid',{attrs:{"id":"notification-profiles-grid","selection-type":"multiAction","no-data-message":_vm.$t('no_data'),"column-defs":_vm.columnDefs,"fetching":_vm.$store.state.alerts.notificationProfilesFetching,"row-data":_vm.$store.state.alerts.notificationProfiles || [],"selection":_vm.selectedRows},on:{"update:selection":function($event){_vm.selectedRows=$event},"refresh":function($event){return _vm.$store.dispatch('alerts/fetchNotificationProfiles', { force: true })}}}),_c('u-dialog',{attrs:{"show-dialog":_vm.deleteDialog,"title":_vm.$t('delete_notification_profiles'),"message":_vm.$t('confirm_notification_profile_delete'),"buttons":[
      { name: _vm.$t('cancel') },
      {
        name: _vm.$t('yes'),
        handler: 'deleteNotificationProfiles',
        showProgress: true,
      },
    ]},on:{"close-dialog":function($event){_vm.deleteDialog = false},"deleteNotificationProfiles":_vm.deleteNotificationProfiles}}),_c('u-dialog',{attrs:{"show-dialog":_vm.deleteErrorDialog,"title":_vm.$t('delete_notification_profiles_error'),"message":_vm.$t('delete_notification_profiles_error_associated_to_alert_rules'),"buttons":[{ name: _vm.$t('ok') }]},on:{"close-dialog":function($event){_vm.deleteErrorDialog = false}}},_vm._l((_vm.selectedNotificationProfilesAssociatedToAlertRules),function({ deleteNotificationProfile, deleteAlertRules },deleteNotificationProfileIndex){return _c('div',{key:`delete-notification-profile-${deleteNotificationProfileIndex}`,staticClass:"mt-2",attrs:{"dense":""}},[_vm._v(" "+_vm._s(deleteNotificationProfile.Name)+" "),_c('ul',_vm._l((deleteAlertRules),function(deleteAlertRule,deleteAlertRuleIndex){return _c('li',{key:`delete-alert-rule${deleteAlertRuleIndex}`},[_vm._v(" "+_vm._s(deleteAlertRule.Name)+" ")])}),0)])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }