<template>
  <u-page :title="syncInitiated ? $t('sync_initiated') : $t('sync_now')">
    <v-list-item v-if="templateNotFound">
      <v-list-item-content>
        <u-alert error>
          {{ $t('template_not_found') }}
        </u-alert>
      </v-list-item-content>
    </v-list-item>
    <template v-else-if="existingTemplate && $store.state.appliances.list && !syncInitiated" flat>
      <p v-if="masterAppliance !== null">
        <b>{{ $t('from_template_master') }}</b>
        <br />
        {{ masterAppliance.Hostname }} ({{
          masterAppliance.Uid ? $options.util.obfuscateUid(masterAppliance.Uid) : $t('not_assigned')
        }}
        / {{ masterAppliance.ApplianceTag ? masterAppliance.ApplianceTag : $t('label_not_assigned') }})<br />
        {{ masterAppliance.SoftwareVersion }}
      </p>
      <div>
        <b>{{ $t('to_target_appliances') }}</b>
      </div>
      <div v-if="targetAppliances.length === 0">
        {{ $t('no_appliances') }}
      </div>
      <div v-for="targetAppliance in targetAppliances" v-else :key="targetAppliance.Uid">
        {{ targetAppliance.Hostname }} ({{
          targetAppliance.Uid ? $options.util.obfuscateUid(targetAppliance.Uid) : $t('not_assigned')
        }}
        / {{ targetAppliance.ApplianceTag ? targetAppliance.ApplianceTag : $t('label_not_assigned') }})
      </div>
      <div class="mt-4">
        {{ $t('sync_now_confirmation') }}
      </div>
      <div class="d-flex flex-row justify-left">
        <v-card-actions class="px-0">
          <v-spacer />
          <u-btn :to="{ name: 'ngfw-policies-template-configuration' }">
            {{ $t('back') }}
          </u-btn>
          <u-btn :disabled="targetAppliances.length === 0" @click="syncNow">
            {{ $t('sync_now') }}
          </u-btn>
        </v-card-actions>
      </div>
    </template>
    <template v-else-if="syncInitiated" flat>
      <p>{{ $t('sync_initiated_target_appliances') }}</p>
      <u-grid
        id="sync-now_status"
        style="height: 300px"
        :no-data-message="$t('no_data')"
        :column-defs="syncColumnDefs"
        :fetching="$store.state.appliances.fetching || $store.state.policies.fetchingTemplates"
        :row-data="syncRowData"
        :enable-refresh="false"
      />
      <div class="d-flex flex-row justify-left mt-2">
        <v-card-actions class="px-0">
          <v-spacer />
          <u-btn :to="{ name: 'ngfw-policies-template-configuration' }">
            {{ $t('close') }}
          </u-btn>
        </v-card-actions>
      </div>
    </template>
  </u-page>
</template>
<script>
  import vuntangle from '@/plugins/vuntangle'
  import api from '@/plugins/ut/ut-api'
  import util from '@/plugins/ut/ut-util'
  import ApplianceSyncStatus from '@/components/renderers/ApplianceSyncStatus'

  export default {
    util,
    data() {
      return {
        templateNotFound: false,
        existingTemplate: null,
        syncInitiated: false,
        syncRowData: [],
      }
    },
    computed: {
      syncColumnDefs() {
        return [
          {
            headerName: this.$t('appliance'),
            field: 'hostname',
          },
          {
            headerName: this.$t('status'),
            field: 'status',
          },
          {
            headerName: this.$t('sync_status'),
            cellRendererFramework: ApplianceSyncStatus,
          },
        ]
      },
      /**
       * Get the master appliance from the existing template.
       *
       * @return {Object|null}
       */
      masterAppliance() {
        // make sure a master appliance was found and appliances were loaded
        if (!this.existingTemplate?.MasterApplianceUID || this.$store.state.appliances.list === null) {
          return null
        }

        // get master appliance by Uid store getter
        const getByUid = this.$store.getters['appliances/getByUid']

        return getByUid(this.existingTemplate.MasterApplianceUID) || null
      },
      /**
       * Get target appliances from the existing template.
       *
       * @return {Object[]}
       */
      targetAppliances() {
        // make sure a master appliance was found and appliances were loaded
        if (!this.existingTemplate?.TargetAppliances || this.$store.state.appliances.list === null) {
          return []
        }

        // get target appliances by Uid store getter
        const getByUid = this.$store.getters['appliances/getByUid']

        /*
         * loop through and make sure target appliance exists in the store, there was a bug where removed uids were not
         * removed from the targetAppliances in the template
         */
        const targetAppliances = []
        this.existingTemplate.TargetAppliances.forEach(targetApplianceUid => {
          const targetAppliance = getByUid(targetApplianceUid)
          if (targetAppliance) {
            targetAppliances.push(targetAppliance)
          }
        })

        return targetAppliances
      },
    },
    watch: {
      '$store.state.policies.templates': {
        immediate: true,

        /**
         * When editing a template, load the existing template after the
         * templates store has been loaded.
         *
         * @return {void}
         */
        handler() {
          // do nothing until the template store is loaded
          if (!this.$store.state.policies.templates) {
            return
          }

          // find the existing template from the templates store
          this.existingTemplate =
            this.$store.state.policies.templates.find(template => template.Id === this.$route.params.id) || null

          // if the template is not found, set to display not found and return
          if (!this.existingTemplate) {
            this.templateNotFound = true
          }
        },
      },
    },
    /**
     * Load the stores if coming directly to this page.
     *
     * @return {void}
     */
    created() {
      // load the appliances
      this.$store.dispatch('appliances/fetchAppliances')

      // load the policy templates
      this.$store.dispatch('policies/fetchTemplates')
    },
    methods: {
      /**
       *  Create a backup of the master appliance and apply it to the target appliances.
       *
       *  @returns {void}
       */
      async syncNow() {
        this.$store.commit('SET_PAGE_LOADER', true)
        this.syncInitiated = true
        const syncRowData = []
        // show appliances attempting sync in grid and show 'in progress'
        const getByUid = this.$store.getters['appliances/getByUid']
        this.existingTemplate.TargetAppliances.forEach(targetAppliance => {
          const targetApplianceInfo = getByUid(targetAppliance)
          syncRowData.push({
            Uid: targetApplianceInfo.Uid,
            hostname: targetApplianceInfo.Hostname,
            status: targetApplianceInfo.IsConnectedToCmd ? this.$t('online') : this.$t('offline'),
            syncStatus: 'IN_PROGRESS',
          })
          this.syncRowData = syncRowData
        })
        // try to sync
        this.$store.commit('SET_PAGE_LOADER', false)
        const response = await api.cloud('Untangle_CommandCenter', 'pushTemplateToAppliances', {
          masterUid: this.existingTemplate?.MasterApplianceUID,
          targetUids: this.existingTemplate?.TargetAppliances,
          paramOrder: 'masterUid targetUids',
        })
        let message = ''
        if (response.data?.success) {
          // only targets that have successfully backed up
          const restoredTargets = response.data.restoredTargets.restoredTargets
          this.existingTemplate.TargetAppliances.forEach(targetAppliance => {
            // if the target appliance isn't listed in the successes, show failure with failed toast message
            if (!restoredTargets.includes(targetAppliance)) {
              if (!targetAppliance.IsConnectedToCmd) {
                message = `not_connected_to_cmd`
              } else {
                message = `backup_download_failed`
              }
              vuntangle.toast.add(this.$t('sync_failure_toast_message'), 'error')
              this.setApplianceStatus(targetAppliance, 'FAIL', message)
            } else {
              this.setApplianceStatus(targetAppliance, 'SUCCESS')
            }
          })
        } else {
          message = response.data.message
          this.existingTemplate.TargetAppliances.forEach(targetAppliance => {
            this.setApplianceStatus(targetAppliance, 'FAIL', message)
            vuntangle.toast.add(this.$t(message || 'master_not_connected_to_cmd'), 'error')
          })
        }
      },

      /**
       * Set appliance sync status on the status column in the sync status data-grid
       *
       * @param {string} uid
       * @param {string} status
       * @param {string} message
       *
       * @returns {void]}
       */
      setApplianceStatus(uid, status, message = null) {
        for (const [i, appliance] of this.syncRowData.entries()) {
          if (appliance.Uid === uid) {
            this.syncRowData[i].syncStatus = status
            if (message) {
              this.syncRowData[i].syncStatusMessage = message
            }

            return
          }
        }
      },
    },
  }
</script>
