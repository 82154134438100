// used to find the subdomain and convert the apiServer like that exists in local development
const domainToApiServer = {
  'www.cv-dev.corp.arista.io': 'cvDev',
  'www.cv-play.corp.arista.io': 'cvPlay',
  'www.cv-staging.corp.arista.io': 'cvStaging',
  'www.arista.io': 'cvProd',
}

/**
 * Try to get the CV ApiServer that the JWT was signed at.  This is used to validate the
 * JWT signature on the backend.
 *
 * @returns {string|null}
 */
export function getCvApiServer() {
  // check for apiServer in the querystring, 'local' does not help, will need to determine further
  const apiServer = new URLSearchParams(window.location.search).get('apiServer')
  if (apiServer && apiServer !== 'local') {
    return apiServer
  }

  // check if a document referer header exists
  const parentUrl = document.referrer
  if (!parentUrl) {
    return null
  }

  // check the referer to the mapping
  for (const domain in domainToApiServer) {
    if (parentUrl.includes(domain)) {
      return domainToApiServer[domain]
    }
  }

  return null
}
