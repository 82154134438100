/**
 * mixin used for the base components that are used for specific condition types
 */

import { conditionsConfig } from 'vuntangle/pm'

export default {
  inject: ['$condition'],
  props: {
    /** validation rules for the field */
    rules: { type: [String, Object], default: 'required' },
    placeholder: { type: String, default: 'value' },
  },

  computed: {
    /** the condition being edited passed a reactive injected value */
    condition: ({ $condition }) => $condition(),
    /** condition config */
    conditionConfig: ({ condition }) => conditionsConfig[condition.type],
    /** show create object button if not on objects route, has a valid value and condition supports objects */

    /**
     * show field embedded `Create Object` button if
     * - not on objects route
     * - value is vaild (e.g. when using '==', '!=' ops)
     * - condition can have objects/groups (objectType conf set)
     */

    showCreateObject: ({ value, conditionConfig }) => !!value && !!conditionConfig.objectType,
  },
}
