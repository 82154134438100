<template>
  <u-page full-height :title="$t('alert_rules')">
    <template #actions>
      <div class="d-flex flex-wrap">
        <u-btn class="mr-2 mb-2" @click="displayAddAlertRule">
          {{ $t('add_alert_rule') }}
        </u-btn>
        <u-btn :disabled="selectedRows.length !== 1" class="mr-2 mb-2" @click="displayEditAlertRule">
          {{ $t('edit_alert_rule') }}
        </u-btn>
        <u-btn :disabled="selectedRows.length === 0" class="mr-2 mb-2" @click="displayDeleteAlertRulesDialog">
          {{ $t('delete_alert_rule') }}
        </u-btn>
      </div>
    </template>
    <u-grid
      id="alert-rules-grid"
      selection-type="multiAction"
      :no-data-message="$t('no_data')"
      :column-defs="columnDefs"
      :fetching="$store.state.alerts.notificationProfilesFetching || $store.state.alerts.alertRulesFetching"
      :row-data="alertRules"
      :selection.sync="selectedRows"
      @refresh="loadAlertRules(true)"
    />
    <u-dialog
      :show-dialog="deleteDialog"
      :title="$t('delete_alert_rule')"
      :message="$t('delete_alert_rules_confirm')"
      :buttons="[
        { name: $t('cancel') },
        {
          name: $t('yes'),
          handler: 'deleteAlertRules',
          showProgress: true,
        },
      ]"
      @close-dialog="deleteDialog = false"
      @deleteAlertRules="deleteAlertRules"
    />
  </u-page>
</template>

<script>
  import users from '@/plugins/ut/ut-users'
  export default {
    /** Prevent/redirect access if user does not have rights */
    beforeRouteEnter(to, from, next) {
      if (!users.hasFullAccountAccess()) {
        next('/alerts/alerts-received')
      } else next()
    },
    data() {
      return {
        // boolean used to show/hide the delete dialog
        deleteDialog: false,
        // array holding the selected rows from the grid
        selectedRows: [],
        // the grid columns definitions
      }
    },
    computed: {
      columnDefs() {
        return [
          {
            headerName: this.$t('id'),
            field: 'Id',
            hide: true,
          },
          {
            headerName: this.$t('name'),
            field: 'Name',
          },
          {
            headerName: this.$t('alert_rule'),
            field: 'Rule',
          },
          {
            headerName: this.$t('notification_profiles'),
            field: 'NotificationProfileIds',
            valueGetter: params => {
              if (!this.$store.state.alerts.notificationProfiles) {
                return
              }
              const profileNames = []
              const profileIds = params.data.NotificationProfileIds
              this.$store.state.alerts.notificationProfiles.forEach(profile => {
                if (profileIds.includes(profile.Id)) {
                  profileNames.push(profile.Name)
                }
              })
              return profileNames.join(', ')
            },
          },
          {
            headerName: this.$t('status'),
            field: 'Status',
            valueFormatter: ({ data }) =>
              this.$tc(`${data.Status}`) ? this.$t(`${data.Status}`).toLowerCase() : data.Status,
          },
          {
            headerName: this.$t('last_updated'),
            field: 'DateUpdated',
            valueFormatter: ({ value }) => this.$vuntangle.dates.formatDateFromApi(value),
            comparator: (a, b) => this.$vuntangle.dates.compareDates(a, b),
          },
        ]
      },
      /**
       * Make sure alert rules are only returned once notification profiles has loaded.
       *
       * @return {Array}
       */
      alertRules() {
        return this.$store.state.alerts.notificationProfiles || this.$store.state.alerts.alertRules
          ? this.$store.state.alerts.alertRules
          : []
      },
    },
    created() {
      this.$store.commit('SET_ALERTS_SELECTION', this.$route.path)
      this.loadAlertRules()
    },
    methods: {
      loadAlertRules(force = false) {
        this.$store.dispatch('alerts/fetchNotificationProfiles', { force })
        this.$store.dispatch('alerts/fetchAlertRules', { force })
      },
      /**
       * method dispatching alert rule(s) deletion
       */
      async deleteAlertRules() {
        await this.$store.dispatch('alerts/deleteAlertRules', this.selectedRows)
        this.deleteDialog = false
        this.selectedRows = []
      },
      displayAddAlertRule() {
        window.ga('send', { hitType: 'event', eventCategory: 'AlertRules', eventAction: 'Add Alert Rule' })
        this.$router.push('/alerts/rules/new')
      },
      displayDeleteAlertRulesDialog() {
        window.ga('send', { hitType: 'event', eventCategory: 'AlertRules', eventAction: 'Delete Alert Rules' })
        this.deleteDialog = true
      },
      displayEditAlertRule() {
        window.ga('send', { hitType: 'event', eventCategory: 'AlertRules', eventAction: 'Edit Alert Rule' })
        this.$router.push('/alerts/rules/' + this.selectedRows[0].Id)
      },
    },
  }
</script>
