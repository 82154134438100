var render = function render(){var _vm=this,_c=_vm._self._c;return _c('u-page',{attrs:{"full-height":"","title":_vm.$t('alert_rules')},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap"},[_c('u-btn',{staticClass:"mr-2 mb-2",on:{"click":_vm.displayAddAlertRule}},[_vm._v(" "+_vm._s(_vm.$t('add_alert_rule'))+" ")]),_c('u-btn',{staticClass:"mr-2 mb-2",attrs:{"disabled":_vm.selectedRows.length !== 1},on:{"click":_vm.displayEditAlertRule}},[_vm._v(" "+_vm._s(_vm.$t('edit_alert_rule'))+" ")]),_c('u-btn',{staticClass:"mr-2 mb-2",attrs:{"disabled":_vm.selectedRows.length === 0},on:{"click":_vm.displayDeleteAlertRulesDialog}},[_vm._v(" "+_vm._s(_vm.$t('delete_alert_rule'))+" ")])],1)]},proxy:true}])},[_c('u-grid',{attrs:{"id":"alert-rules-grid","selection-type":"multiAction","no-data-message":_vm.$t('no_data'),"column-defs":_vm.columnDefs,"fetching":_vm.$store.state.alerts.notificationProfilesFetching || _vm.$store.state.alerts.alertRulesFetching,"row-data":_vm.alertRules,"selection":_vm.selectedRows},on:{"update:selection":function($event){_vm.selectedRows=$event},"refresh":function($event){return _vm.loadAlertRules(true)}}}),_c('u-dialog',{attrs:{"show-dialog":_vm.deleteDialog,"title":_vm.$t('delete_alert_rule'),"message":_vm.$t('delete_alert_rules_confirm'),"buttons":[
      { name: _vm.$t('cancel') },
      {
        name: _vm.$t('yes'),
        handler: 'deleteAlertRules',
        showProgress: true,
      },
    ]},on:{"close-dialog":function($event){_vm.deleteDialog = false},"deleteAlertRules":_vm.deleteAlertRules}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }