const ga4 = {
  /**
   * Include GA4 script
   */
  load(tagId) {
    const ga4tag = document.createElement('script')
    ga4tag.type = 'text/javascript'
    ga4tag.async = true
    ga4tag.src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(ga4tag, s)
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())

    gtag('config', tagId)
    gtag('event', 'page_view')
  },
}

export default ga4
