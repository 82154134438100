<template>
  <div class="d-flex align-center">
    <h4 class="mr-2">{{ $t('action') }}</h4>
    <v-row dense>
      <v-col cols="4">
        <v-select
          v-model="action.type"
          :items="['SNAT', 'MASQUERADE']"
          :placeholder="$vuntangle.$t('select')"
          :menu-props="{ offsetY: true }"
          outlined
          dense
          hide-details
        >
          <template #selection="{ item }">{{ $vuntangle.$t(`rule_action_${item.toLowerCase()}`) }}</template>
          <template #item="{ item }">{{ $vuntangle.$t(`rule_action_${item.toLowerCase()}`) }}</template>
        </v-select>
      </v-col>
      <v-col v-if="action.type === 'SNAT'" cols="8">
        <ValidationProvider v-slot="{ errors }" :rules="{ required: true, ip_expression: { multiple: false } }">
          <u-text-field v-model="action.snat_address" :label="$vuntangle.$t('address')" :error-messages="errors">
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  export default {
    inject: ['$action'],
    computed: {
      action: ({ $action }) => $action(),
    },
    watch: {
      'action.type'(value) {
        if (value === 'MASQUERADE') {
          this.$delete(this.action, 'snat_address')
        } else {
          this.$set(this.action, 'snat_address', '')
        }
      },
    },
  }
</script>
