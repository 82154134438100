<!--
  component used in all policies pages
  see /appliances/policies/_category/_type.vue
-->
<template>
  <u-page :title="title" full-height>
    <template #actions>
      <u-btn :disabled="$store.state.appliances.list === null" @click="displayCreatePolicyDialog">
        {{ $t('create') }}
      </u-btn>
      <u-btn :disabled="existingPoliciesSelectedRows.length !== 1" @click="displayPushPolicyDialog">
        {{ $t('set_appliance_policy') }}
      </u-btn>
      <u-btn :disabled="!existingPoliciesSelectedRows.length" @click="displayDeletePolicyDialog">
        {{ $t('delete') }}
      </u-btn>
    </template>
    <div v-if="policyManagerRequired" class="mb-4">
      <v-icon>mdi-information</v-icon>
      {{ $t('policy_manager_alert_text') }}
    </div>
    <u-grid
      id="policies-grid"
      selection-type="multiAction"
      :no-data-message="$t('no_data')"
      :column-defs="existingPoliciesColumnDefs"
      :fetching="pendingAction"
      :row-data="existingPolicies"
      :selection.sync="existingPoliciesSelectedRows"
      @refresh="fetchExistingPolicies"
    />
    <u-dialog
      :width="700"
      :show-dialog="createPolicyDialog"
      :title="$t('create_policy')"
      :message="$t('please_select_a_source_appliance')"
      :buttons="[
        {
          'name': $t('cancel'),
        },
        {
          'name': $t('ok'),
          'handler': 'create-policy',
          disabled: createPolicySelectedRows.length === 0,
          showProgress: true,
        },
      ]"
      @close-dialog="createPolicyDialog = false"
      @create-policy="createPolicy"
    >
      <u-grid
        v-if="policiesLoaded && createPolicyDialog"
        id="create-policy-appliance-grid"
        style="height: 500px"
        selection-type="singleAction"
        :no-data-message="$t('no_data')"
        :column-defs="appliancePolicyColumnDefs"
        :fetching="$store.state.appliances.fetching"
        :row-data="createPolicyRows"
        :selection.sync="createPolicySelectedRows"
        :enable-refresh="false"
      />
    </u-dialog>
    <u-dialog
      :show-dialog="pushPolicyNoAppliancesDialog"
      title="Push Policy"
      :message="$tc('no_compatible_appliances_available', existingPolicyVersion)"
      @close-dialog="pushPolicyNoAppliancesDialog = false"
    />
    <u-dialog
      :width="700"
      :show-dialog="pushPolicyDialog"
      :title="$t('push_policy')"
      :message="$t('please_select_target_appliances')"
      :buttons="[
        {
          'name': $t('cancel'),
        },
        {
          'name': $t('ok'),
          'handler': 'create-policy',
          disabled: pushPolicySelectedRows.length === 0,
          showProgress: true,
        },
      ]"
      @close-dialog="pushPolicyDialog = false"
      @create-policy="pushPolicy"
    >
      <u-grid
        v-if="policiesLoaded && pushPolicyDialog"
        id="push-policy-appliance-grid"
        style="height: 500px"
        selection-type="multiAction"
        :no-data-message="$t('no_data')"
        :column-defs="appliancePolicyColumnDefs"
        :fetching="$store.state.appliances.fetching"
        :row-data="pushPolicyRows"
        :selection.sync="pushPolicySelectedRows"
        :enable-refresh="false"
      />
    </u-dialog>
    <u-dialog
      :show-dialog="deletePolicyDialog"
      :title="$t('delete_policy')"
      :message="$t('confirm_delete_policy')"
      :buttons="[
        {
          name: $t('cancel'),
        },
        {
          name: $t('yes'),
          handler: 'delete-policy',
          showProgress: true,
        },
      ]"
      @close-dialog="deletePolicyDialog = false"
      @delete-policy="deletePolicy"
    />
  </u-page>
</template>
<script>
  import { captureException } from '@sentry/vue'
  import cloneDeep from 'lodash/cloneDeep'
  import api from '@/plugins/ut/ut-api'
  import util from '@/plugins/ut/ut-util'
  import vuntangle from '@/plugins/vuntangle'
  import PoliciesMixin from '@/components/policies/PoliciesMixin.js'
  import taskManager from '@/plugins/ut/ut-task-manager'

  export default {
    mixins: [PoliciesMixin],
    props: {
      title: {
        type: String,
        required: true,
      },
      /**
       * Scope for the category like 'global', 'service', 'rack-specific'
       */
      scope: {
        type: String,
        required: true,
      },
      /**
       * Display the 'policy manager must be installed' alert
       */
      policyManagerRequired: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        // data related to creating a policy
        createPolicyDialog: false,
        createPolicySelectedRows: [],

        // data related to pushing policies
        pushPolicyDialog: false,
        pushPolicyNoAppliancesDialog: false,
        pushPolicySelectedRows: [],

        // used to store and display appliances/policies for creating and pushing policies
        appliancesForPolicies: [],
        basePolicyAppliancesColumnDefsUidIndex: 1,
        policiesLoaded: false,
      }
    },
    computed: {
      existingPoliciesColumnDefs() {
        // get shared base policies column and add version for ngfw
        const columnDefs = cloneDeep(this.basePoliciesColumnDefs)
        columnDefs.push({
          headerName: this.$t('version'),
          field: 'Version',
          flex: 0,
          width: 160,
        })

        return columnDefs
      },
      /**
       * Get the data for 'create policy' grid.  The policies may be appliances
       * for global policies or existing policies.
       */
      createPolicyRows() {
        // check to display 'appliances' for global policies
        if (this.scope === 'global' || this.scope === 'service') {
          return this.appliancesForPolicies
        }
        return this.getPolicies(this.appliancesForPolicies)
      },

      /**
       * Get compatible appliances/policies to push a policy to.  The appliances
       * must be the same version as the policy.
       *
       * @return {Object[]}
       */
      pushPolicyRows() {
        if (!this.existingPolicyVersion) {
          return []
        }

        // get compatible appliances for setting appliance policies
        const compatibleAppliances = this.getCompatibleAppliances(
          this.appliancesForPolicies,
          this.existingPolicyVersion,
          true,
        )

        // check to display 'appliances' for global policies
        if (this.scope === 'global' || this.scope === 'service') {
          return compatibleAppliances
        }

        return this.getPolicies(compatibleAppliances)
      },

      /**
       * Convenience method to get the version of the selected existing policy.
       *
       * @return {string}
       */
      existingPolicyVersion() {
        const [existingPolicySelectedRow] = this.existingPoliciesSelectedRows

        return existingPolicySelectedRow?.Version
      },

      /**
       * Get the columns for create/push policy grid.  The policies may be
       * appliances for global policies or existing policies.
       */
      appliancePolicyColumnDefs() {
        // check to display 'appliances' columns
        // for global policies
        if (this.scope === 'global' || this.scope === 'service') {
          return this.basePolicyAppliancesColumnDefs
        }

        // add the policy name column
        const policyColumnDefs = cloneDeep(this.basePolicyAppliancesColumnDefs)
        policyColumnDefs.push({
          headerName: this.$t('policy_name'),
          field: 'PolicyName',
        })

        // UI-359: in order to mask Uid properly the valueGetter has to be copied over also
        // The cloneDeep uses JSON.stringify to copy over which does not copy the valueGetter over
        policyColumnDefs[this.basePolicyAppliancesColumnDefsUidIndex].valueGetter =
          this.basePolicyAppliancesColumnDefs[this.basePolicyAppliancesColumnDefsUidIndex].valueGetter
        return policyColumnDefs
      },
    },
    watch: {
      '$store.state.appliances.list': {
        immediate: true,
        /**
         * When the appliances have loaded get the compatible appliances for
         * creating a policy with.
         *
         * @return {void}
         */
        handler() {
          // do nothing until the appliances store is loaded
          if (!this.$store.state.appliances.list) {
            return
          }

          // check compatible version
          let minimumVersionRequired = '12.2'
          if (this.type === 'tunnel-vpn') {
            minimumVersionRequired = '13.1'
          }

          // clone appliances for create/push policies, policies will be attached
          // later and the store should not be affected
          this.appliancesForPolicies = cloneDeep(
            this.getCompatibleAppliances(this.$store.state.appliances.list, minimumVersionRequired, false),
          )
        },
      },
    },
    created() {
      this.$store.commit('SET_NGFW_TEMPLATES_SELECTION', this.$route.path)
    },

    methods: {
      /**
       * Fetch the policies of the compatible appliances for creating policies.
       * This is loaded the first time the 'create policy' dialog is shown.
       * The policies are attached to the appliances.
       *
       * @return {void}
       */
      async fetchPoliciesForAppliances() {
        const uidCsv = this.appliancesForPolicies.map(appliance => appliance.Uid).join(',')

        const response = await api.cloud('Untangle_CommandCenter', 'GetAppliancePolicies', {
          uidCsv,
          paramOrder: 'uidCsv',
        })

        // add policies to appliances
        if (response) {
          this.appliancesForPolicies.forEach((appliance, i) => {
            appliance.policies = response.data?.[i] || []
          })
        }

        this.policiesLoaded = true
      },

      /**
       * Get compatible appliances based on the parameters passed in.  This
       * is a helper method as 'create policies' and 'push policies' have
       * different compatible appliances.
       *
       * @return {Object[]}
       */
      getCompatibleAppliances(appliances, minimumVersionRequired, exactMatch, includeOfflineAppliances = false) {
        return (
          appliances.filter(appliance => {
            // filter out non compatible appliances
            if (
              !appliance.ProductLine ||
              appliance.ProductLine === 'MFW' ||
              (!appliance.IsConnectedToCmd && !includeOfflineAppliances) ||
              !appliance.IsLicensedForCommandCenter
            ) {
              return false
            }

            // get appliance version
            let versionString
            try {
              // all versions should have at least major and minor build numbers
              const versionArray = appliance.SoftwareVersion.split('.')
              versionString = versionArray[0] + '.' + versionArray[1]
            } catch (e) {
              versionString = appliance.SoftwareVersion
              captureException(e)
            }

            // compare appliance version for compatibility
            const compareResult = util.compareVersions(versionString, minimumVersionRequired)
            if ((exactMatch && compareResult !== 0) || compareResult < 0) {
              return false
            }

            return true
          }) || []
        )
      },

      /**
       * Get policies from appliances.
       *
       * @return {Object[]}
       */
      getPolicies(appliances) {
        const policies = []
        appliances.forEach(appliance => {
          // make sure policies exist
          if (!appliance.policies) {
            return
          }

          // loop through policies
          appliance.policies.forEach(policy => {
            // make sure policy apps exist
            if (!policy.apps) {
              return
            }

            // loop through policy apps
            policy.apps.forEach(app => {
              // remove the policy prefix to check the policy category
              const appName = app.appName.replace('untangle-node-', '').replace('untangle-casing-', '')

              // add the policy for the category if it has the same app name
              if (appName === this.type) {
                policies.push({
                  Uid: appliance.Uid,
                  Hostname: appliance.Hostname,
                  IsConnectedToCmd: appliance.IsConnectedToCmd,
                  IsLicensedForCommandCenter: appliance.IsLicensedForCommandCenter,
                  SoftwareVersion: appliance.SoftwareVersion,
                  PolicyName: policy.name,
                  SettingScope: app.policyId,
                  ApplianceTag: appliance.ApplianceTag,
                  StatusFlags: appliance.StatusFlags,
                })
              }
            })
          })
        })

        return policies
      },

      /**
       * Dialog to display appliances for 'add policy'.  The policies for the
       * compatible appliances will be fetched if they have not been already.
       *
       * @returns {Promise<void>}
       */
      async displayCreatePolicyDialog() {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'Policies',
          eventAction: 'Create Policy',
          eventLabel: this.type,
        })

        if (!this.policiesLoaded) {
          // get policies for the compatible appliances
          this.$store.commit('SET_PAGE_LOADER', true)
          await this.fetchPoliciesForAppliances()
          this.$store.commit('SET_PAGE_LOADER', false)
        }

        this.createPolicyDialog = true
      },

      /**
       * Create policy action when creating a new policy.
       * @returns {Promise<void>}
       */
      async createPolicy() {
        const [createPolicySelectedRow] = this.createPolicySelectedRows

        const response = await api.cloud('Untangle_CommandCenter', 'CreatePolicyFromAppliance', {
          uid: createPolicySelectedRow.Uid,

          // this could be the policyId or global scopes
          settingScope: createPolicySelectedRow.SettingScope ? createPolicySelectedRow.SettingScope : this.scope,
          settingType: this.type,
          version: createPolicySelectedRow.SoftwareVersion,

          // only non-global policies would have a policy name
          policyName: createPolicySelectedRow.PolicyName ? createPolicySelectedRow.PolicyName : '',
          paramOrder: 'uid settingType settingScope version policyName',
        })

        this.cleanUpSelectedRows()
        if (response.success && !response.data?.ErrorMessage) {
          // get policies
          await this.fetchExistingPolicies()
          vuntangle.toast.add(this.$t('policy_create_success'))
        } else {
          vuntangle.toast.add(this.$t('policy_create_failure'), 'error')
        }

        this.createPolicyDialog = false
      },

      /**
       * Display the 'set appliance policy' dialog.  This determines to
       * display a message about no compatiable appliances or not.
       *
       * @return {void}
       */
      async displayPushPolicyDialog() {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'Policies',
          eventAction: 'Push Policy',
          eventLabel: this.type,
        })

        if (!this.policiesLoaded) {
          // get policies for the compatible appliances
          this.$store.commit('SET_PAGE_LOADER', true)
          await this.fetchPoliciesForAppliances()
          this.$store.commit('SET_PAGE_LOADER', false)
        }

        if (this.pushPolicyRows.length === 0) {
          this.pushPolicyNoAppliancesDialog = true
        } else {
          this.pushPolicyDialog = true
        }
      },

      /**
       * Push policy action.  Will create a task and use the task manager
       * to push to appliances.
       *
       * @return {void}
       */
      pushPolicy() {
        const [existingPolicySelectedRow] = this.existingPoliciesSelectedRows
        const pushTargets = this.pushPolicySelectedRows.map(({ Uid, SettingScope, PolicyName }) => {
          return { Uid, SettingScope: SettingScope !== undefined ? SettingScope : this.scope, PolicyName }
        })

        taskManager.createTask(
          'PushPolicyTask',
          {
            targets: JSON.stringify(pushTargets),
            settingType: this.type,
            settingName: existingPolicySelectedRow.Name,
            policyId: existingPolicySelectedRow.Id,
            version: existingPolicySelectedRow.Version,
            paramOrder: 'targets settingType settingName policyId version',
          },
          task => {
            // display task not found
            if (task === null) {
              vuntangle.toast.add(this.$t('failed_push', { policyName: existingPolicySelectedRow.Name }), 'error')
              return
            }
            // display general task error
            if (task.Result?.Error) {
              vuntangle.toast.add(this.$t(task.Result.Error), 'error')
              return
            }
            // task task result error
            if (task.Result?.TaskResult?.ErrorMessage) {
              vuntangle.toast.add(this.$t(task.Result?.TaskResult?.ErrorMessage), 'error')
              return
            }
            // display success
            vuntangle.toast.add(this.$t(task.Result?.TaskResult || 'task_completed_successfully'))
          },
        )

        this.cleanUpSelectedRows()
        this.pushPolicyDialog = false
        this.fetchExistingPolicies()
      },
    },
  }
</script>
