<template>
  <v-menu open-on-hover offset-y top rounded="lg" transition="fade-transition" nudge-top="10">
    <template #activator="{ on, attrs }">
      <v-icon v-bind="attrs" class="mx-1" v-on="on">mdi-information-outline</v-icon>
    </template>
    <v-card min-width="300" max-width="600">
      <div class="d-flex px-4 pt-4 align-center">
        <v-icon dense class="mr-2">mdi-information-outline</v-icon>
        <h4>{{ config ? $t(config.text) : $t('condition_info') }}</h4>
      </div>
      <v-card-text>
        <p v-if="config">
          {{ $t(`info_${config.text}`) }}
        </p>
        <p v-else>{{ $t('info_default_condition') }}</p>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
  import { conditionsConfig } from 'vuntangle/pm'
  export default {
    props: {
      type: { type: String, default: undefined },
    },
    computed: {
      config: ({ type }) => conditionsConfig[type],
    },
  }
</script>
