import { captureException } from '@sentry/vue'
import http from '@/plugins/axios'
import i18n from '@/plugins/vue-i18n'
import vuntangle from '@/plugins/vuntangle'

const api = {
  /**
   * Base method for calling POST ajax requests to the application.  This
   * method is not meant to be used on it's own.  cloud, local, or payload
   * methods should be used instead.
   *
   * @param {String}  action
   * @param {Object}  requestParams
   * @param {String}  payload
   *
   * @return {Promise<void>}
   */
  async ajaxRequest(action, requestParams = {}, payload = '', method = 'POST') {
    /**
     * There are two main types of requests, using a JSON payload or
     * using form data.  If a JSON payload is being used, the requestParams
     * will be in the query string.  If using form data, the requestParams
     * will be in the form data.
     */
    let params = {}
    let data
    // send as GET query params
    if (method === 'GET') {
      params = requestParams
    } else if (payload) {
      // send as JSON post body
      method = 'POST'
      params = requestParams
      data = payload
    } else if (method === 'POST') {
      // build form data from the requestParams
      data = new FormData()
      for (const key in requestParams) {
        const value = requestParams[key]
        if (Array.isArray(value)) {
          // add array form values
          value.forEach(item => {
            data.append(key + '[]', item)
          })
        } else {
          data.append(key, value)
        }
      }
    }

    let response

    try {
      response = await http({
        url: '/' + action,
        method,
        params,
        data,
      })
    } catch (ex) {
      response = ex.response
      captureException(ex)
      /**
       * If something like a CORS error, a response won't be returned.  Set
       * to an empty object because code checks response.data
       */
      if (response === undefined) {
        response = {}
      }
      // return on http error, axios onError interceptor will display a modal
      return response
    }

    // return response without an error toast if a 'success: true' response
    if (response.data?.success === true) {
      return response
    }

    // do not display login errors
    const errorMessage = response.data?.message
    if (
      errorMessage?.includes('login_mfa_verification_required') ||
      errorMessage?.includes('login_sso_required') ||
      errorMessage === 'mfa_verification_failed' ||
      errorMessage === 'login_email_verify_incomplete' ||
      errorMessage === 'login_failed' ||
      errorMessage === 'login_attempt_locked_out' ||
      errorMessage === 'existing_user_login' ||
      errorMessage === '' // on login success the message is an empty string	      errorMessage === '' // on login success the message is an empty string
    ) {
      return response
    }

    // check to display a custom error message or a default one
    if (errorMessage) {
      // display error toast
      vuntangle.toast.add(i18n.t('error_occurred') + '\n' + i18n.t(errorMessage), 'error')
    } else {
      // errorMessage = 'global.unknown_error'
    }

    // the POST request using axios, returning a promise
    return response
  },

  /**
   * api method specific for cloud requests
   * @param {String} handler - the action handler
   * @param {String} method - the action method
   * @param {Object} extraParams - an object to be transformed in formData
   *
   * Sample usage:
   * api.cloud('Untangle_CommandCenter', 'GetAppliances', {
   *   getExtendedInfo: true,
   *   paramOrder: 'getExtendedInfo',
   *   overrideSession: true
   * })
   *
   * @returns {Object}
   */
  async cloud(handler, method, extraParams = null) {
    // add handler and method to the params object
    let httpMethod = 'POST'
    if (extraParams) {
      extraParams.handler = handler
      extraParams.method = method
    } else {
      // if there are no extra params, request can be sent as a GET
      httpMethod = 'GET'
      extraParams = { handler, method }
    }

    const response = await this.ajaxRequest('routeCloudRequest', extraParams, null, httpMethod)

    // a cloud response should return the raw response data from axios if it exists
    return response?.data || {}
  },

  /**
   * Method that posts payload data
   * @param {*} params - the request parameters
   * @param {*} data - payload to b passed
   */
  async payload(params, data) {
    const response = await this.ajaxRequest('routeCloudRequest', params, data)

    // a payload response should return the raw response data from axios if it exists
    return response?.data || {}
  },
}

export default api
