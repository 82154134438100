<template>
  <u-widget :title="$t('appliance_map')" :info="$t('info_appliance_map')" v-on="$listeners">
    <v-card-actions v-if="showEdit" :class="'ut-location px-4' + ($store.state.isDark ? ' dark' : '')">
      <span v-if="appliances.length > 0">{{ appliances[0].ApplianceLocation }}</span>
      <v-spacer />
      <u-btn :min-width="null" @click="$emit('appliance-location')">
        <v-icon small class="mr-2">mdi-pencil</v-icon>{{ $t('edit') }}
      </u-btn>
    </v-card-actions>
    <u-gmap :markers="markers" style="width: 100%; height: 100%" />
  </u-widget>
</template>
<script>
  import appliances from '@/plugins/ut/ut-appliances'

  export default {
    props: {
      appliances: {
        type: Array,
        required: true,
      },
      appliance: {
        type: Object,
        default: null,
      },
    },
    computed: {
      markers() {
        return this.appliance
          ? appliances.getApplianceMarkers([this.appliance])
          : appliances.getApplianceMarkers(this.appliances)
      },
      showEdit() {
        return this.$route.path.startsWith('/appliances/my-appliances')
      },
    },
  }
</script>

<style>
  .ut-location {
    position: absolute;
    top: 44px;
    z-index: 1;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
  }
  .ut-location.dark {
    background: rgba(0, 0, 0, 0.5);
  }
</style>
