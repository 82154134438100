class Container {
  constructor() {
    this.instances = {}
  }

  getInstance(name) {
    return this.instances[name] || null
  }

  setInstance(name, instance) {
    this.instances[name] = instance
  }
}

export default new Container()
