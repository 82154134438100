<template>
  <u-dialog-complex
    v-model="show"
    :width="600"
    :title="$t('set_wifi_region')"
    :show-progress="progress"
    :submit-label="$t('save')"
    @cancel="onCancel"
    @submit="onSubmit"
  >
    <p>{{ $t('appliance_set_wifi_region_message') }}</p>
    <ValidationObserver ref="obs">
      <ValidationProvider v-slot="{ errors }" name="wifiRegion" rules="required">
        <u-autocomplete
          v-model="wifiRegion"
          :items="countries"
          item-text="Name"
          item-value="Code"
          :label="$t('country')"
          class="my-4"
          :error-messages="errors"
          clearable
        >
          <template v-if="errors.length" #append>
            <u-errors-tooltip :errors="errors" />
          </template>
        </u-autocomplete>
      </ValidationProvider>
    </ValidationObserver>
  </u-dialog-complex>
</template>

<script>
  export default {
    props: {
      // boolean to show/hide dialog
      value: {
        type: Boolean,
        default: false,
      },
      appliance: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        progress: false,
        wifiRegion: null,
      }
    },
    computed: {
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
      countries() {
        return this.$store.state.data.countries || []
      },
    },
    watch: {
      show(val) {
        if (val) {
          this.newTag = this.appliance.ApplianceTag
          this.wifiRegion = this.appliance.ApplianceWifiRegion
        } else {
          this.onCancel()
        }
      },
    },
    methods: {
      onCancel() {
        this.$refs.obs.reset()
        this.wifiRegion = null
        this.show = false
      },
      async onSubmit() {
        const isValid = await this.$refs.obs.validate()
        if (!isValid) {
          return
        }
        this.progress = true
        const success = await this.$store.dispatch('appliances/setWifiRegion', {
          uid: this.appliance.Uid,
          newWifiRegion: this.wifiRegion,
          paramOrder: 'uid newWifiRegion',
        })
        if (success) {
          this.$vuntangle.toast.add(this.$t('set_wifi_region_success'))
        } else {
          this.$vuntangle.toast.add(this.$t('set_wifi_region_fail'), 'error')
        }
        this.progress = false
        this.show = false
      },
    },
  }
</script>
