<template>
  <value-object v-if="valueIsObject" v-on="$listeners" />
  <value-text v-else :rules="{ required: true, vlan_tag_expression: { multiple: false } }" v-on="$listeners" />
</template>
<script>
  import { ConditionType, OperatorType } from 'vuntangle/pm'
  import { ValueObject, ValueText } from '../value'

  export default {
    components: { ValueObject, ValueText },
    inject: ['$condition'],
    computed: {
      condition: ({ $condition }) => $condition(),

      valueIsObject: ({ condition }) =>
        ConditionType.VlanTag &&
        [OperatorType.Match, OperatorType.NotMatch, OperatorType.In, OperatorType.NotIn].includes(condition.op),
    },
  }
</script>
