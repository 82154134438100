<script>
  import cloneDeep from 'lodash/cloneDeep'

  export default {
    data() {
      return {
        baseLayout: [],
        responsiveLayouts: {},
        widgetComponentMap: {},
        storedLayoutName: '',
        updateLayoutPath: '',
        getWidgetsPath: '',
      }
    },
    computed: {
      widgets() {
        return this.$store.getters[this.getWidgetsPath]
      },
    },
    methods: {
      setLayout() {
        // checks if a saved layout exists
        const storedLayout = this.$vuntangle.widgets.getStoredLayout(this.storedLayoutName)
        if (storedLayout) {
          // if there is a saved layout, use it directly
          this.responsiveLayouts = storedLayout
          this.baseLayout = cloneDeep(this.responsiveLayouts[this.$vuntangle.widgets.getBreakpoint()])
          return
        }
        // set the layout with widgets
        const enabledWidgets = []
        Object.keys(this.widgetComponentMap).forEach(key => {
          enabledWidgets.push(key)
        })
        this.responsiveLayouts = this.$vuntangle.widgets.getLayout(enabledWidgets, this.widgetComponentMap)
        this.baseLayout = cloneDeep(this.responsiveLayouts[this.$vuntangle.widgets.getBreakpoint()])
        // store the grid so it does not have to recompute
        this.storeLayout()
      },

      /**
       * Set the widget to resize to fire resize events, save the newly resized layout in local storage.
       */
      onResizedWidget(i) {
        this.baseLayout[i].resize = true
        Object.keys(this.responsiveLayouts).forEach(key => {
          this.responsiveLayouts[key][i].resize = true
        })
        this.storeLayout()
      },

      /**
       * Removes an widget (item) from the grid layouts
       * Avoid using setLayout because of the re-rendering/fetching of the widgets data
       *
       */
      onRemove(id) {
        const widgets = cloneDeep(this.widgets)
        widgets[id] = false
        this.$store.commit(this.updateLayoutPath, widgets)
        const idx = this.baseLayout.findIndex(el => {
          return el.id === id
        })
        this.baseLayout.splice(idx, 1)
        Object.keys(this.responsiveLayouts).forEach(key => {
          this.responsiveLayouts[key].splice(idx, 1)
        })
        // update the stored grid
        this.storeLayout()
      },

      /**
       * Stores the current layout
       */
      storeLayout() {
        const storedLayout = cloneDeep(this.responsiveLayouts)
        storedLayout[this.$vuntangle.widgets.getBreakpoint()] = this.baseLayout
        this.$vuntangle.widgets.setStoredLayout(this.storedLayoutName, storedLayout)
      },
    },
  }
</script>
