<template>
  <div class="d-flex align-center">
    <h4 class="mr-2">Set priority</h4>
    <div class="d-flex align-center">
      <ValidationProvider v-slot="{ errors }" rules="required" tag="div">
        <v-select
          v-model="action.priority"
          :items="priorityOptions"
          :placeholder="$vuntangle.$t('select')"
          :error-messages="errors"
          :menu-props="{ offsetY: true }"
          outlined
          dense
          hide-details
        >
          <template #selection="{ item }">{{ $vuntangle.$t(item.text) }}</template>
          <template #item="{ item }">{{ $vuntangle.$t(item.text) }}</template>
          <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
        </v-select>
      </ValidationProvider>
    </div>
  </div>
</template>
<script>
  import { priorityOptions } from 'vuntangle/constants'
  export default {
    inject: ['$action'],
    data() {
      return {
        priorityOptions,
      }
    },
    computed: {
      action: ({ $action }) => $action(),
    },
  }
</script>
