import cloneDeep from 'lodash/cloneDeep'

export default {
  methods: {
    /**
     * Enhaces columns definition CellRenderers by adding extra params via `cellRendererParams`
     * Some grids cell renderers are showing templates/policies/appliances as hyperlinks that have to be clicked
     * The `click` event is being captured by adding it to cellRendererParams
     * @param {Array} allColumns - all column defs of a given grid
     * @param {String[]} augmentedColIds - array of column ids that require click handlers
     * @returns Array
     */
    augmentColumns(allColumns, augmentedColIds) {
      const columnsCopy = cloneDeep(allColumns)
      augmentedColIds.forEach(colId => {
        const col = columnsCopy.find(col => col.colId === colId)
        if (!col) return

        // handler when click on a template link in the action column
        if (colId === 'action') {
          col.cellRendererParams = {
            click: configurationId => {
              const conf = this.$store.getters['policyManager/getObjectById'](configurationId)
              this.$store.commit('policyManager/SET_EDIT_OBJECT', cloneDeep(conf))
              this.$router.push({ name: 'pm-templates-configuration', params: { configurationId } })
            },
          }
        }

        // handler when click on a policy link in policies column
        if (colId === 'policies') {
          col.cellRendererParams = {
            click: policyId => {
              const policy = this.$store.getters['policyManager/getObjectById'](policyId)
              this.$store.commit('policyManager/SET_EDIT_OBJECT', cloneDeep(policy))
              this.$router.push({ name: 'pm-policy-conditions', params: { policyId } })
            },
          }
        }

        // handler when click on a template in global templates column
        if (colId === 'global_templates') {
          col.cellRendererParams = {
            click: configurationId => {
              const object = this.$store.getters['policyManager/getObjectById'](configurationId)
              this.$store.commit('policyManager/SET_EDIT_OBJECT', cloneDeep(object))
              this.$router.push({ name: 'pm-templates-configuration', params: { configurationId } })
            },
          }
        }

        // handler when click on a appliance link in appliances column
        if (colId === 'appliances') {
          col.cellRendererParams = {
            click: applianceUid => {
              // need the unique ID to get to the appliance page
              const uniqueId = this.$store.getters['appliances/getUniqueIdentifierByUid'](applianceUid)
              this.$router.push({ name: 'appliances-id', params: { id: uniqueId } })
            },
          }
        }
      })

      return columnsCopy
    },
  },
}
