import http from './axios'
import store from '@/store'

// if any 401 response, set the user as not authorized
// any page change will redirect them to the unauthorized page
http.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      store.commit('auth/SET_IS_AUTH', false)
    }

    return Promise.reject(error)
  },
)
