<template>
  <div>
    <div>
      <span class="font-weight-bold">{{ $t('type') }}:</span> {{ $t(type) }}
    </div>
    <div v-if="bestOfMetric">
      <span class="font-weight-bold">{{ $t('policy_type_best_of') }}:</span>
      {{ $t(bestOfMetric) }}
    </div>
    <div v-if="balanceAlgorithm">
      <span class="font-weight-bold">{{ $t('algorithm') }}:</span>
      {{ $t(balanceAlgorithm) }}
    </div>
    <div>
      <span class="font-weight-bold">{{ $t('criteria') }}:</span> {{ data.criteria.length || '-' }}
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      data: { type: Object, default: () => {} },
    },

    computed: {
      type: ({ data }) => {
        if (data.type === 'BEST_OF') return 'policy_type_best_of'
        if (data.type === 'BALANCE') return 'policy_type_balance'
      },

      bestOfMetric: ({ data }) => {
        if (data.type === 'BEST_OF') {
          switch (data.best_of_metric) {
            case 'LOWEST_LATENCY':
              return 'policy_metrics_lowest_latency'
            case 'HIGHEST_AVAILABLE_BANDWIDTH':
              return 'policy_metrics_highest_available_bandwidth'
            case 'LOWEST_JITTER':
              return 'policy_metrics_lowest_jitter'
            case 'LOWEST_PACKET_LOSS':
              return 'policy_metrics_lowest_packet_loss'
          }
        }
      },

      balanceAlgorithm: ({ data }) => {
        if (data.type === 'BALANCE') {
          switch (data.balance_algorithm) {
            case 'WEIGHTED':
              return 'policy_balance_weighted'
            case 'LATENCY':
              return 'policy_balance_latency'
            case 'AVAILABLE_BANDWIDTH':
              return 'policy_balance_available_bandwidth'
            case 'BANDWIDTH':
              return 'policy_balance_bandwidth'
          }
        }
      },
    },
  }
</script>
