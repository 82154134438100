var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height d-flex flex-column"},[_c('div',{class:_vm.actionsClassList},[_c('u-btn',{attrs:{"disabled":!_vm.selectedTasks.length},on:{"click":_vm.showRemoveTasksDialog}},[_vm._v(" "+_vm._s(_vm.$t('remove_task'))+" ")]),_c('u-btn',{staticClass:"ml-2",attrs:{"disabled":_vm.selectedTasks.length !== 1},on:{"click":_vm.viewTaskConfiguration}},[_vm._v(" "+_vm._s(_vm.$t('view_details'))+" ")])],1),_c('u-grid',{attrs:{"id":"tasks-grid-widget","row-node-id":"TaskId","selection-type":'multiAction',"selection":_vm.selectedTasks,"no-data-message":_vm.noDataMessage,"column-defs":_vm.tasksColumnDefs,"row-data":_vm.taskItems,"fetching":_vm.fetchingTasks},on:{"update:selection":function($event){_vm.selectedTasks=$event},"refresh":_vm.getTaskList,"grid-ready":_vm.onGridReady}}),_c('u-dialog',{attrs:{"show-dialog":_vm.removeTasksDialog,"title":_vm.$t('remove_task'),"message":_vm.$t('confirm_remove_task'),"buttons":[
      { name: _vm.$t('cancel') },
      {
        name: _vm.$t('yes'),
        handler: 'removeTasks',
        showProgress: true,
      },
    ]},on:{"close-dialog":function($event){_vm.removeTasksDialog = false},"removeTasks":_vm.removeTasks}}),_c('u-dialog',{attrs:{"show-dialog":!!_vm.filteredRecords.length,"title":_vm.$t('additional_information'),"width":600,"buttons":[
      {
        'name': _vm.$t('ok'),
        'handler': 'close-dialog',
      },
    ]},on:{"close-dialog":_vm.onCloseDialog}},[_c('extra-details',{attrs:{"filtered-records":_vm.filteredRecords,"current-index":_vm.currentIndex},on:{"update:currentIndex":function($event){_vm.currentIndex=$event},"update:current-index":function($event){_vm.currentIndex=$event}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }