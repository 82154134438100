<template>
  <div>
    <div class="d-flex flex-wrap gap-2">
      <v-btn
        v-for="(action, key) in actionItems"
        :key="key"
        :disabled="action.disabled"
        small
        rounded
        outlined
        color="primary"
        @click="executeAction(key)"
      >
        <v-icon small class="mr-2">{{ action.icon }}</v-icon> {{ $t(action.text) }}
      </v-btn>
    </div>

    <!-- appliance label dialog -->
    <ut-tag-dialog v-model="dialogs[$options.actions.SetLabel]" :appliance="appliance" />
    <!-- WIFI region dialog -->
    <ut-wifi-region-dialog v-model="dialogs[$options.actions.SetWifiRegion]" :appliance="appliance" />
    <!-- TOTP dialog -->
    <ut-totp-dialog v-model="dialogs[$options.actions.SetTotp]" :appliance="appliance" />
    <!-- subscription dialog -->
    <ut-subs-dialog v-model="dialogs[$options.actions.SetLicense]" :appliance="appliance" />
    <!-- reboot dialog -->
    <ut-reboot-dialog v-model="dialogs[$options.actions.Reboot]" :appliance="appliance" />
    <!-- upgrade dialog -->
    <ut-upgrade-dialog v-model="dialogs[$options.actions.Upgrade]" :appliance="appliance" />

    <!-- appliance removal dialog -->
    <u-dialog-complex
      v-model="dialogs[$options.actions.Remove]"
      :title="$t('delete_title')"
      :cancel-label="$t('no')"
      :submit-label="$t('yes')"
      :show-progress="removeApplianceProgress"
      @cancel="dialogs[$options.actions.Remove] = false"
      @submit="onRemoveAppliance"
    >
      <p v-if="lastApplianceInNetwork">{{ lastApplianceMessage }}</p>
      <p v-else>{{ $t('delete_confirm') }}</p>
    </u-dialog-complex>
  </div>
</template>
<script>
  import appliances from '@/plugins/ut/ut-appliances'
  import users from '@/plugins/ut/ut-users'
  import UtTagDialog from '@/components/appliances/UtTagDialog.vue'
  import UtWifiRegionDialog from '@/components/appliances/UtWifiRegionDialog.vue'
  import UtTotpDialog from '@/components/appliances/UtTotpDialog.vue'
  import UtSubsDialog from '@/components/appliances/UtSubsDialog.vue'
  import UtRebootDialog from '@/components/appliances/UtRebootDialog.vue'
  import UtUpgradeDialog from '@/components/appliances/UtIndividualUpgradeDialog.vue'

  export default {
    components: { UtTagDialog, UtWifiRegionDialog, UtSubsDialog, UtTotpDialog, UtRebootDialog, UtUpgradeDialog },
    // actions mappings
    actions: Object.freeze({
      // etm actions
      SetLabel: 'action-set-label',
      SetWifiRegion: 'action-set-wifi-region',
      SetTotp: 'action-set-totp',
      SetLicense: 'action-set-license',
      Remove: 'action-remove',
      // online appliance actions
      Remote: 'action-remote',
      Upgrade: 'action-upgrade',
      Reboot: 'action-reboot',
    }),
    data() {
      return {
        dialogs: {},
        lastApplianceInNetwork: false,
        lastApplianceMessage: null,
        removeApplianceProgress: false,
      }
    },
    computed: {
      appliance: ({ $store, $route }) => $store.getters['appliances/getByUniqueIdentifier']($route.params.id),
      wifiRegionCompatible: ({ appliance }) => appliances.isWifiRegionCompatible(appliance),
      totpCompatible: ({ appliance }) => appliances.isApplianceTotpCompatible(appliance),
      licenseEnforced: ({ $store }) => !$store.state.data.ccViewModel.Account.NoLicenseEnforcement,
      upgradeSupported: ({ appliance }) => {
        if (appliance?.ProductLine === 'MFW') {
          return parseFloat(appliance.SoftwareVersion) > 3.1
        }
        if (appliance?.ProductLine === 'NGFW') {
          return parseFloat(appliance?.SoftwareVersion) > 13.1
        }
        return false
      },
      isFullAccessUser: () => users.userHasAccess('appliances', 'full'),

      actionItems: ({
        $options,
        $isSapphire,
        appliance,
        wifiRegionCompatible,
        totpCompatible,
        licenseEnforced,
        upgradeSupported,
        isFullAccessUser,
      }) => {
        return {
          [$options.actions.Remote]: {
            text: 'remote_access',
            icon: 'mdi-remote-desktop',
            disabled: !appliance.IsConnectedToCmd || !appliance.IsRemoteAccessEnabled,
          },
          ...(!$isSapphire &&
            upgradeSupported && {
              [$options.actions.Upgrade]: {
                text: 'update_software',
                icon: 'mdi-upload',
                disabled: !appliance.IsConnectedToCmd || !appliance.IsRemoteAccessEnabled,
              },
            }),
          [$options.actions.Reboot]: {
            text: 'reboot',
            icon: 'mdi-refresh',
            disabled: !appliance.IsConnectedToCmd || !appliance.IsRemoteAccessEnabled,
          },
          [$options.actions.SetLabel]: {
            text: 'set_label',
            icon: 'mdi-tag-outline',
            disabled: !appliance.Uid || !appliance.ProductLine,
          },
          ...(wifiRegionCompatible && {
            [$options.actions.SetWifiRegion]: {
              text: 'set_wifi_region',
              icon: 'mdi-wifi-marker',
              disabled: !appliance.Uid,
            },
          }),
          ...(totpCompatible && {
            [$options.actions.SetTotp]: {
              text: 'totp',
              icon: 'mdi-lock',
              disabled: !appliance.Uid,
            },
          }),
          ...(licenseEnforced && {
            [$options.actions.SetLicense]: {
              text: 'license',
              icon: 'mdi-license',
              disabled: !appliance.Uid || !appliance.IsLicenseManageable,
            },
          }),
          ...(!$isSapphire && {
            [$options.actions.Remove]: {
              text: 'remove',
              icon: 'mdi-delete',
              disabled: !isFullAccessUser,
            },
          }),
        }
      },
    },

    watch: {
      appliance: {
        // resets the dialogs booleans for each action to `false`
        handler() {
          Object.values(this.$options.actions).forEach(action => this.$set(this.dialogs, action, false))
        },
        immediate: true,
      },
    },

    methods: {
      /**
       * Executes the action upon button action click
       * for `Remote` action just opensup a new remote window
       * while for the rest it shows up the dedicated dialog
       * @param {*} action - action string as defined in actionsConfig.js
       */
      executeAction(action) {
        action === this.$options.actions.Remote ? this.openRemoteAccess() : (this.dialogs[action] = true)
      },

      /** opens a new window accessing the appliance box ui remotely */
      openRemoteAccess() {
        const remoteAccessWindow = window.open(`${process.env.VUE_APP_BASE_PATH}appliance-remote-access.html`)
        appliances.doRemoteAccess(this.appliance.Uid, remoteAccessWindow)
      },

      async onRemoveAppliance() {
        this.removeApplianceProgress = true
        const appliances = [this.appliance]
        const response = await this.$store.dispatch('appliances/removeAppliances', appliances)
        this.removeApplianceProgress = false
        this.showRemoveApplianceDialog = false
        if (response.success) {
          // go back to main grid cause the appliance we're on here doesn't exist anymore
          this.$router.push({ name: 'appliances' })

          // remove the appliances from the store after redirect to not show errors from the edit appliance page
          appliances.forEach(appliance => this.$store.commit('appliances/DELETE_APPLIANCE', appliance))
          const force = true
          this.$store.dispatch('subscriptions/fetchSubscriptions', { force })
          this.$store.dispatch('networks/fetchNetworks', { force })
          this.$store.dispatch('policies/fetchTemplates', { force })
          appliances.forEach(appliance => this.$store.commit('hosts/REMOVE_HOSTS', appliance))
          this.$store.commit('SET_NETWORKS_SELECTION', { name: 'networks' })
          this.$vuntangle.toast.add(this.$t('remove_appliance_success'))
        } else {
          this.$vuntangle.toast.add(this.$t('remove_appliance_error'), 'error')
        }
      },

      async displayRemoveApplianceDialog() {
        window.ga('send', { hitType: 'event', eventCategory: 'Appliances', eventAction: 'Delete Appliance' })
        this.lastApplianceInNetwork = false
        if (this.appliance.NetworkInfo) {
          const pieces = this.appliance.NetworkInfo.split(':')
          const networkId = pieces[1]
          await this.$store.dispatch('networks/fetchNetworks')
          const network = this.$store.state.networks.list?.find(network => network.Id === networkId) || null
          if (network && network.Uids.length === 1) {
            this.lastApplianceInNetwork = true
            this.lastApplianceMessage = this.$t('delete_confirm_network_also', {
              networkName: pieces[0],
            })
          }
        }
        this.showRemoveApplianceDialog = true
      },
    },
  }
</script>
