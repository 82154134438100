<template>
  <u-widget :title="$t('tasks')" :info="$t('tasks_list')" v-on="$listeners">
    <tasks-grid :actions-class-list="'my-2 ml-2'" />
  </u-widget>
</template>

<script>
  import WidgetMixin from '@/components/widgets/WidgetMixin'
  import TasksGrid from '@/components/grids/TasksGrid'

  export default {
    components: { TasksGrid },
    mixins: [WidgetMixin],
  }
</script>
