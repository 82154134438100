<template>
  <u-widget :title="$t('total_bandwidth_30days')" @refresh="getTotalBandwidth" v-on="$listeners">
    <div class="flex-grow-1" style="position: relative">
      <u-chart-stock :fetching="fetching" :options="options" />
    </div>
  </u-widget>
</template>
<script>
  import WidgetMixin from '@/components/widgets/WidgetMixin'
  import reports from '@/plugins/ut/ut-reports'
  import chartOptions from '@/util/chartOptions'

  export default {
    mixins: [WidgetMixin],
    props: {
      uids: {
        type: Array,
        required: true,
        default() {
          return []
        },
      },
    },
    data() {
      return {
        fetching: true,
        options: chartOptions.getOptions('time', 'getBytesTooltipLineChartTotalBandwidth'),
      }
    },
    watch: {
      uids: {
        immediate: true,
        handler() {
          this.getTotalBandwidth()
        },
      },
    },
    methods: {
      async getTotalBandwidth() {
        const series = await reports.getTotalBandwidthChart(this.uids)
        if (series) {
          // process&sort data so it meets chart expectations
          series.forEach(serie => {
            serie.data.forEach(point => {
              if (point[0]) {
                point[0] = new Date(point[0]).getTime()
              }
            })
            // sort series by dat, as they might not be in order
            serie.data.sort(function (a, b) {
              return a[0] - b[0]
            })
          })
          this.options.series = series
        }
        this.fetching = false
      },
    },
  }
</script>
