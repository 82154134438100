<template>
  <u-page :title="$t('title')" :subtitle="$t('subtitle')" full-height>
    <template #actions>
      <u-btn :to="{ name: 'networks-add' }">
        {{ $t('create_network') }}
      </u-btn>
    </template>
    <u-grid
      id="networks"
      :no-data-message="$t('no_data')"
      :column-defs="columnDefs"
      :fetching="$store.state.networks.fetching"
      :row-data="$store.state.networks.list || []"
      @refresh="$store.dispatch('networks/fetchNetworks', { force: true })"
      @row-clicked="onRowClicked"
    />
  </u-page>
</template>
<script>
  import util from '@/plugins/ut/ut-util'

  export default {
    data() {
      return {
        callPending: false,
      }
    },
    computed: {
      columnDefs() {
        return [
          {
            headerName: this.$t('name'),
            field: 'Name',
          },
          {
            headerName: this.$t('number_of_appliances'),
            field: 'Uids',
            valueGetter: params => params.data.Uids.length,
          },
          {
            headerName: this.$t('date_updated'),
            field: 'DateUpdated',
            valueFormatter: params =>
              this.$vuntangle.dates.formatDateFromApi(
                params.data.DateUpdated ? params.data.DateUpdated : params.data.DateCreated,
              ),
            comparator: (a, b) => this.$vuntangle.dates.compareDates(a, b),
          },
          {
            headerName: this.$t('uids'),
            field: 'Uids',
            hide: true,
            valueFormatter: params => {
              let value = ''

              let noUnknownUids = true // Track if there are unknown uids in network. Assume there are none
              for (const uid of params.data.Uids) {
                if (uid) {
                  value += util.obfuscateUid(uid)
                } else {
                  // If a uid is undefined, then there is the unknown uid text to show
                  noUnknownUids = false
                }
                value += ','
              }
              // Add in unknown uids text if some unknown uids are found
              if (!noUnknownUids) {
                value += this.$t('unknown_uids')
              }
              return value.substring(0, value.length - 1)
            },
          },
        ]
      },
    },
    created() {
      this.$store.commit('SET_NETWORKS_SELECTION', this.$route.path)
      this.$store.dispatch('networks/fetchNetworks')
    },
    methods: {
      onRowClicked({ data: network }) {
        this.$router.push({ name: 'networks-network-id', params: { id: network.Id } })
      },
    },
  }
</script>
