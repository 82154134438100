<template>
  <u-widget :title="$t('appliance_licenses')" v-on="$listeners">
    <div v-if="activeLicenses.length === 0" class="mx-2 my-2">
      <u-alert error>
        {{ $t('appliance_status_no_licenses') }}
      </u-alert>
      <u-btn v-if="$options.users.hasFullAccountAccess()" class="mr-2" @click="$emit('add-license')">
        {{ $t('add_license') }}
      </u-btn>
      <u-btn
        v-if="$options.users.hasFullAccountAccess()"
        :href="`${$store.state.data.ccViewModel.StoreUrl}/cart`"
        target="_blank"
      >
        {{ $t('buy_license') }}
      </u-btn>
    </div>
    <div v-else style="overflow-y: auto">
      <v-list class="pa-0">
        <v-list-item v-for="(license, idx) in activeLicenses" :key="idx" style="border-bottom: 1px #eee solid">
          <!-- TODO - add some more specific icons -->
          <v-list-item-icon>
            <v-icon large>mdi-router-wireless</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <a :href="license._url" target="_blank">{{ license.displayName }}</a>
            </v-list-item-title>
            <v-list-item-subtitle>{{ $d(new Date(license.end * 1000), 'long') }}</v-list-item-subtitle>
            <!-- show buttons if trial or less than 15 days remaining -->
            <div v-if="license._isTrial || license._daysRemaining < 15" class="mt-2">
              <p v-if="license._isAutoRenewed">
                {{ $t('appliance_license_will_auto_renew', { days: license._daysRemaining }) }}
              </p>
              <p v-else>
                {{
                  $t('appliance_licenses_days_remaining', {
                    days: license._daysRemaining,
                  })
                }}
              </p>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </u-widget>
</template>
<script>
  import users from '@/plugins/ut/ut-users'
  import util from '@/plugins/ut/ut-util'

  export default {
    props: {
      appliance: {
        type: Object,
        required: true,
      },
    },
    users,
    data() {
      return {
        subscription: {},
      }
    },
    computed: {
      // compute active licenses for the appliance
      activeLicenses() {
        const licenses = []
        const unixTimeNow = Math.floor(Date.now() / 1000)
        this.appliance.Licenses.forEach(license => {
          if (license.end >= unixTimeNow) {
            // _ (underscore) props denote computed values used in this particular view
            licenses.push({
              ...license,
              _url: util.getLicenseUrl(license.displayName, this.appliance.ProductLine),
              _isTrial: license.type === 'Trial',
              _isAutoRenewed: license.isAutoRenewed === '1', // TODO - fix comparision
              _daysRemaining: Math.floor((license.end - unixTimeNow) / 60 / 60 / 24),
              _remainingSeats: license.seats - this.appliance.HostCount,
            })
          }
        })
        return licenses
      },
    },
  }
</script>
