<!--
  Component receiving/editing a single condition object
  It shows condition items grouped by SOURCE/DEST/OTHER
-->
<template>
  <div>
    <v-card v-for="target in conditionTargets" :key="target" class="d-flex" elevation="0" style="padding: 2px">
      <v-sheet
        width="200"
        class="pa-2 flex-grow-0 flex-shrink-0"
        rounded="0"
        :color="`grey ${$vuetify.theme.dark ? 'darken-4' : 'lighten-4'}`"
      >
        {{ $t(target.toLowerCase()) }}
      </v-sheet>
      <div class="flex-grow-1 pa-0">
        <div v-for="(item, idx) in items" :key="idx">
          <edit-condition-object-item
            v-if="conditionsConfig[item.type] && conditionsConfig[item.type].target === target"
            :condition.sync="items[idx]"
            :target="target"
            :target-count="getTargetCount(target)"
            @delete-item="onDeleteItem(item)"
            @get-applications="onGetApplications"
            @get-objects-and-groups="onGetObjectsAndGroups"
          />
        </div>

        <edit-condition-object-item
          :condition.sync="newConditionItem"
          :target="target"
          :target-count="getTargetCount(target)"
        />
      </div>
    </v-card>
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import isEqual from 'lodash/isEqual'
  import { mapGetters, mapActions } from 'vuex'
  import { conditionsConfig, ConditionTarget } from 'vuntangle/pm'
  import EditConditionObjectItem from '../components/EditConditionObjectItem.vue'

  export default {
    components: { EditConditionObjectItem },
    props: {
      conditionObject: { type: Object, required: true },
    },
    data() {
      return {
        // local copy of the condition object
        conditionObjectCopy: undefined,
        // new condition object helper
        newConditionItem: { type: '' },
        // SOURCE/DEST/EXTRA possible targets
        conditionTargets: [ConditionTarget.Source, ConditionTarget.Destination, ConditionTarget.Other],

        items: [],

        conditionsConfig,
      }
    },
    computed: {
      // map getters from store modules
      ...mapGetters('data', ['applications']),
      ...mapGetters('policyManager', ['getObjectsByType']),
    },
    watch: {
      conditionObject: {
        /**
         * Populates the local copy of the condition object
         * @param {Object} object - the condition object
         */
        handler(object) {
          if (isEqual(this.conditionObjectCopy, object)) return
          this.conditionObjectCopy = cloneDeep(object)
          // populate items
          this.items = this.conditionObjectCopy.PolicyJson.items
        },
        immediate: true,
        deep: true,
      },
      conditionObjectCopy: {
        /**
         * Populates the local copy of the condition object
         * @param {Object} object - the condition object
         */
        handler(object) {
          this.$emit('update:condition-object', object)
        },
        immediate: true,
        deep: true,
      },
      newConditionItem: {
        /**
         * Adds condition item to object when `type` is set
         * @param {Object} value - the condition item
         */
        handler(value) {
          if (value.type) {
            this.conditionObjectCopy.PolicyJson.items.push(value)
            // then resets the value of new condition
            this.newConditionItem = { type: '' }
          }
        },
        deep: true,
        immediate: true,
      },
    },

    methods: {
      // map actions from store modules
      ...mapActions('data', ['fetchApplications']),
      ...mapActions('policyManager', ['fetchObjectsByType', 'saveObject']),

      /**
       * Returns the number of condition items of a specific target SOURCE/DEST/OTHER
       * Used by ConditionType to show placeholder `Any` or `Select` for SOURCE/DEST
       * @param {String } target
       */
      getTargetCount(target) {
        return this.items.filter(item => {
          if (!this.conditionsConfig[item.type]) return false
          return this.conditionsConfig[item.type].target === target
        }).length
      },

      /**
       * Deletes an item from condition object
       * @param {Object} item - item to be removed
       */
      onDeleteItem(item) {
        const index = this.conditionObjectCopy.PolicyJson.items.findIndex(itm => isEqual(itm, item))
        if (index >= 0) this.conditionObjectCopy.PolicyJson.items.splice(index, 1)
      },

      /**
       * event handler from the conditions that require applications as values
       * e.g. APPLICATION_NAME or APPLICATION_CATEGORY
       * @param {Function} cb - the callback when done
       */
      async onGetApplications(cb) {
        await this.fetchApplications()
        cb(this.applications)
      },

      /**
       * event handler from the conditions that require objects as values
       * @param {String} conditionConfig - condition config having the `objectType` & `objectGroupType`
       * @param {Function} cb - the callback when done
       */
      async onGetObjectsAndGroups(conditionConfig, cb) {
        const objectType = conditionConfig?.objectType
        const objectGroupType = conditionConfig?.objectGroupType
        const fns = []
        if (objectType) {
          fns.push(this.fetchObjectsByType({ type: objectType }))
        }
        if (objectGroupType) {
          fns.push(this.fetchObjectsByType({ type: objectGroupType }))
        }
        await Promise.allSettled(fns)
        const objects = this.getObjectsByType(objectType) || []
        const objectGroups = this.getObjectsByType(objectGroupType) || []
        // callback with data as needed to be used in Selector component {text, value}
        cb(objects, objectGroups)
      },
    },
  }
</script>
