import { captureException } from '@sentry/vue'
import VueRouter, { isNavigationFailure, NavigationFailureType } from 'vue-router'

/**
 * Override .push() to catch navigation failures.
 * Log nav guard redirection
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    // do not show a navigation duplicated error, this is done when the users clicks on the same page they are at
    if (isNavigationFailure(err, NavigationFailureType.duplicated)) {
      return
    }

    if (isNavigationFailure(err, NavigationFailureType.redirected)) {
      // eslint-disable-next-line no-console
      console.debug('redirected by navigation guard')
    } else {
      // eslint-disable-next-line no-console
      console.error(err)
      captureException(err)
    }
  })
}

export default VueRouter
