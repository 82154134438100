<template>
  <div>
    <report-details
      :view-id="viewId"
      :box-settings="boxSettings"
      @fetch-data="onFetchData"
      @view-report="onViewReport"
    />
  </div>
</template>
<script>
  import { ReportDetails } from 'vuntangle'
  import settingsMixin from './settingsMixin'
  import vuntangle from '@/plugins/vuntangle'
  import appliance from '@/plugins/ut/ut-appliances'

  export default {
    components: { ReportDetails },

    mixins: [settingsMixin],

    computed: {
      /**
       * Returns the view id passed to the vuntangle component
       * @param {Object} vm - the vue instance
       * @param {Object} vm.$route - the route
       * @returns {String}
       */
      viewId: ({ $route }) => $route.params.viewId,

      /**
       * Returns the appliance box settings via store
       * @param {Object} vm - the vue instance
       * @param {Object} vm.$store - the app store
       * @returns {Object}
       */
      boxSettings: ({ $store }) => $store.getters['appliances/getApplianceSettings'],
    },

    methods: {
      /**
       * Handler when setting up the report view that redirect to the report view details
       * @param {String} viewId - one of the view ids as defined in vuntangle
       * @returns {undefined}
       */
      onViewReport(viewId) {
        this.$router.push({ name: 'appliances-report-details', params: { viewId } })
      },

      /**
       * Fetches the report data from the box
       * @param {String} report - the report configuration
       * @returns {Array<Object>}
       */
      async getReportData(report) {
        const data = [] // the full data from all chunks

        if (!this.$route.params.id) return []

        const uid = this.uid
        // create query
        const queryResponse = await appliance.sendToApplianceApi(uid, 'reports/create_query', report)
        // make sure the create query is successful before trying to get the data with that id
        if (queryResponse.success && queryResponse.data) {
          const queryId = parseInt(queryResponse.data)

          // get data chunks
          let error = false
          while (!error) {
            // if user is no longer on report page, return and close query
            if (!this.$route.params.id) {
              // close query
              await appliance.sendToApplianceApi(uid, `reports/close_query/${queryId}`)
              return []
            }

            const dataResponse = await appliance.sendToApplianceApi(uid, `reports/get_data/${queryId}`, {}, 'GET')
            // errors are sent as a message in the data but success is its own key
            if (dataResponse.data?.error || !dataResponse.success) {
              error = true
            } else if (dataResponse.data) {
              data.push(...dataResponse.data)
            }
          }

          // close query
          await appliance.sendToApplianceApi(uid, `reports/close_query/${queryId}`)
        }
        return data
      },

      /**
       * Handler for fetch event from vuntangle component
       * It builds up the chunk of promises that are fetching the data for a report
       * @param {arg} - the event data from vuntangle component
       * @param {arg.query} - the report query that gets requested
       * @param {arg.resolve} - the callback method after the fetching is done
       * @returns {undefined}
       */
      async onFetchData({ query, resolve }) {
        const timeData = await vuntangle.promiseQueue.add(() => this.getReportData(query))
        resolve(timeData)
      },
    },
  }
</script>
