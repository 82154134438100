<template>
  <u-page v-if="templateNotFound" :title="$t('template_configuration')">
    <v-list-item>
      <v-list-item-content>
        <u-alert error>
          {{ $t('template_not_found') }}
        </u-alert>
      </v-list-item-content>
    </v-list-item>
  </u-page>
  <u-page v-else :title="$t('edit_template')" full-height>
    <p class="mb-0 font-weight-bold">{{ $t('template_master') }}:</p>
    <div v-if="masterAppliance">
      {{ masterAppliance.Hostname }} ({{
        masterAppliance.Uid ? $options.util.obfuscateUid(masterAppliance.Uid) : $t('not_assigned')
      }}
      / {{ masterAppliance.ApplianceTag ? masterAppliance.ApplianceTag : $t('label_not_assigned') }}) /
      {{ masterAppliance.SoftwareVersion }}
    </div>
    <div v-else>
      {{ $t('not_assigned') }}
    </div>

    <p class="mb-2 mt-4 font-weight-bold">{{ $tc('target_appliance', 2) }}</p>
    <u-grid
      id="edit-template-configuration-target-appliances"
      row-node-id="Uid"
      selection-type="multiAction"
      :no-data-message="$t('no_data')"
      :column-defs="columnDefs"
      :fetching="$store.state.appliances.fetching"
      :row-data="targetAppliances"
      :selection.sync="selectedTargetAppliances"
      @refresh="$store.dispatch('appliances/fetchAppliances', { force: true })"
    />
    <template-options :selected-values.sync="template" :sync-all-already-enabled.sync="syncAllAlreadyEnabled" />
    <div class="d-flex flex-row justify-left">
      <v-card-actions class="px-0 mt-4">
        <v-spacer />
        <u-btn text @click="$router.push({ name: 'ngfw-policies-template-configuration' })">
          <span :class="`${$vuetify.theme.dark ? 'white--text' : ''}`">
            {{ $t('cancel') }}
          </span>
        </u-btn>
        <u-btn :disabled="selectedTargetAppliances.length === 0" @click="saveTemplate">
          {{ $t('update') }}
        </u-btn>
      </v-card-actions>
    </div>
  </u-page>
</template>
<script>
  import vuntangle from '@/plugins/vuntangle'
  import util from '@/plugins/ut/ut-util'
  import TemplateOptions from '@/components/policies/TemplateOptions'
  import TemplateMixin from '@/components/policies/TemplateMixin'

  export default {
    components: { TemplateOptions },
    mixins: [TemplateMixin],
    util,
    data() {
      return {
        templateNotFound: false,
        existingTemplate: null,
        selectedTargetAppliances: [],
      }
    },
    computed: {
      /**
       * Get the master appliance from the existing template.
       *
       * @return {Object|null}
       */
      masterAppliance() {
        // make sure a master appliance was found and appliances were loaded
        if (!this.existingTemplate?.MasterApplianceUID || this.$store.state.appliances.list === null) {
          return null
        }

        // get master appliance by Uid store getter
        const getByUid = this.$store.getters['appliances/getByUid']

        return getByUid(this.existingTemplate.MasterApplianceUID) || null
      },
    },
    watch: {
      '$store.state.policies.templates': {
        immediate: true,

        /**
         * When editing a template, load the existing template after the
         * templates store has been loaded.
         *
         * @return {void}
         */
        handler() {
          // do nothing until the template store is loaded
          if (!this.$store.state.policies.templates) {
            return
          }

          // find the existing template from the templates store
          this.existingTemplate =
            this.$store.state.policies.templates.find(template => template.Id === this.$route.params.id) || null

          // if the template is not found, set to display not found and return
          if (!this.existingTemplate) {
            this.templateNotFound = true
            return
          }

          // set the editable template from the existing template
          this.template = {
            keepInSyncEnabled: this.existingTemplate.KeepInSyncEnabled,
            syncAllNewAppliancesEnabled: this.existingTemplate.SyncAllNewAppliancesEnabled,
            syncFrequency: this.existingTemplate.SyncFrequency,
            syncDayOfWeek: this.existingTemplate.SyncDayOfWeek,
            syncTimeOfDay: this.existingTemplate.SyncTimeOfDay,
          }

          // set the initially selected target appliances
          this.selectedTargetAppliances = [...this.existingTemplate.TargetAppliances]
        },
      },
    },
    methods: {
      /**
       * Save the existing template.  This will update the store on success.
       *
       * @return {void}
       */
      async saveTemplate() {
        // get selected target appliances
        this.$store.commit('SET_PAGE_LOADER', true)
        const targetApplianceUids = this.selectedTargetAppliances.map(appliance => appliance.Uid)

        // dispatch a 'update template' update
        const response = await this.$store.dispatch('policies/updateTemplate', {
          template: this.existingTemplate,
          change: {
            targetApplianceUids,
            ...this.template,
          },
        })

        // redirect if success
        this.$store.commit('SET_PAGE_LOADER', false)
        if (response.success && response.data) {
          vuntangle.toast.add(this.$t('policy_update_success'))
          this.$router.push({ name: 'ngfw-policies-template-configuration' })
        } else {
          vuntangle.toast.add(this.$t('policy_update_failure'), 'error')
        }
      },
    },
  }
</script>
