export const commonWidgetMapComponents = {
  total_bandwidth: { component: 'TotalBandwidth', width: 1 },
  top_applications: { component: 'TopApplications', width: 1 },
  top_domains: { component: 'TopDomains', width: 1 },
  top_hostnames: { component: 'TopHostNames', width: 1 },
  detected_intrusions: { component: 'DetectedIntrusions', width: 2 },
}

/**
 *
 * Remove all layouts from storage.  Happens when logging out or saving widgets in preferences.
 *
 * @returns {void}
 */
export function removeAllStoredLayouts() {
  window.sessionStorage.removeItem('dashboard-layout')
  window.sessionStorage.removeItem('appliance-layout')
  window.sessionStorage.removeItem('network-layout')
}
