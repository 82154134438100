import { captureException } from '@sentry/vue'
import api from '@/plugins/ut/ut-api'
import storeApi from '@/plugins/ut/ut-store-api'
import vuntangle from '@/plugins/vuntangle'
import i18n from '@/plugins/vue-i18n'

const getDefaultState = () => {
  return {
    vouchers: null,
    fetchingVouchers: false,
    skus: null,
    fetchingSkus: false,
  }
}

const getters = {
  vouchers: state => state.vouchers,
  fetchingVouchers: state => state.fetchingVouchers,
}

const mutations = {
  RESET: state => Object.assign(state, getDefaultState()),
  SET_FETCHING_VOUCHERS: (state, value) => (state.fetchingVouchers = value),
  SET_VOUCHERS: (state, info) => (state.vouchers = info),
  ADD_VOUCHERS: (state, info) => {
    state.vouchers.push(...info)
  },
  SET_FETCHING_SKUS: (state, value) => (state.fetchingSkus = value),
  SET_SKUS: (state, info) => (state.skus = info),
}
const actions = {
  /**
   * Async call to create vouchers
   *
   * @param {args}    param
   * @param {Object}  data
   * @param {String}  data.Sku - The selected product for which vouchers are generated
   * @param {Number}  data.Quantity - The number of vouchers to be generated
   * @param {Boolean} data.IsPrePaid - Boolean if pre-paid
   * @param {Number}  data.PoNumber - The purchase order number
   * @param {String}  data.Coupons - The coupons
   * @param {String}  data.ParamOrder - "sku quantity isPrePaid poNumber coupons"
   */
  async createSubscriptionVoucher({ commit }, data) {
    commit('SET_FETCHING_VOUCHERS', true)
    const response = await api.cloud('Untangle_CommandCenter', 'CreateSubscriptionVouchers', data)
    if (response.success && response.data) {
      commit('ADD_VOUCHERS', response.data.vouchers)
      commit('financial/UPDATE_BILLING_BALANCE', response.data.billingBalance, { root: true })
      vuntangle.toast.add(i18n.t('generate_voucher_success'))
    } else {
      vuntangle.toast.add(i18n.t(`${'' + response.message}`), 'error')
    }
    commit('SET_FETCHING_VOUCHERS', false)
  },
  /**
   * retrieves any vouchers generated by the current user.  Updates the store with results
   * @param state
   * @param commit
   * @param options
   */
  async getVouchers({ state, commit }, options) {
    if (state.vouchers !== null && !state.fetchingVouchers && !options?.force) {
      return
    }
    commit('SET_FETCHING_VOUCHERS', true)
    const vouchers = await storeApi.fromStoreApi('api/v1/voucher/GetVouchers', 'GET')
    if (vouchers.status === 200) {
      commit('SET_VOUCHERS', vouchers.data)
    }
    commit('SET_FETCHING_VOUCHERS', false)
  },

  /**
   * Requests back end to delete one or more vouchers
   *
   * @param selectedVouchers - array of voucher names
   * @returns {Promise<*>}
   */
  async deleteVouchers({}, VoucherCodes) {
    const response = await storeApi.fromStoreApi('api/v1/voucher/DeleteVouchers', 'DELETE', {
      VoucherCodes,
      paramOrder: 'VoucherCodes',
    })
    return response
  },

  /**
   * Retrieves data about all skus logged in account is enabled to generate
   * vouchers for
   * @param commit
   * @param options
   * @returns {Promise<void>}
   */
  async getVoucherSkus({ commit }) {
    commit('SET_FETCHING_SKUS', true)
    const response = await storeApi.fromStoreApi('api/v1/voucher/GetVoucherSkusForAccount', 'GET')
    if (response.status === 200 && response.data) {
      commit('SET_SKUS', response.data)
    }
    commit('SET_FETCHING_SKUS', false)
  },

  /**
   * Async call to redeem voucher
   *
   * @param {Object} data
   * @param {String} data.VoucherCode - The voucher code to redeem
   * @param {String} data.Organization - The organization name ( for psc and npc )
   * @param {String} data.ParamOrder - "voucherCode organization"
   */
  async redeemSubscriptionVoucher({ commit }, data) {
    const response = await api.cloud('Untangle_CommandCenter', 'RedeemSubscriptionVoucher', data)

    if (response.success && response.data) {
      commit('subscriptions/ADD_SUBSCRIPTION', response.data.subscription, { root: true })
      if (response.data.billingBalance) {
        // customers without Service Provider account will get no Billing back
        commit('financial/UPDATE_BILLING_BALANCE', response.data.billingBalance, { root: true })
      }
      return null
    }

    const errorMsg = response.message
    captureException(new Error(errorMsg))

    return errorMsg
  },
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions,
}
