<!--
  Component wrapping grid having actions like delete, create etc...
-->
<template>
  <div class="d-flex flex-column flex-grow-1">
    <!-- toolbar above grid -->
    <div class="d-flex flex-column mb-2">
      <div class="mr-2">
        <!-- `headline` used for main views e.g. Rules view -->
        <h1 v-if="headline" class="headline">{{ headline }}</h1>
        <span v-if="subtitle" class="caption my-2">{{ subtitle }}</span>
        <!-- `title` used for in component grid e.g. Conditions inside a Rule view -->
        <h4 v-if="title">{{ title }}</h4>
      </div>
      <v-divider v-if="subtitle" class="my-2" />
      <div class="d-flex justify-end">
        <template v-if="!selectionType">
          <v-btn
            v-if="showDelete"
            text
            class="text-capitalize mr-2 align-self-end"
            :ripple="false"
            @click="toggleSelection"
          >
            <v-icon small class="mr-2">mdi-delete</v-icon> {{ $t('delete') }} ...
          </v-btn>

          <!-- slot in which other specific actions related to the grid data, e.g. Create New action -->
          <div class="d-flex align-self-end">
            <slot name="actions"></slot>
          </div>
        </template>
        <template v-else>
          <v-alert dense text type="info" class="mr-4 mb-0 py-1"> {{ $t('select_delete_items') }} </v-alert>
          <v-divider vertical class="mx-2" />
          <v-btn text class="text-capitalize mr-2 align-self-end" :ripple="false" @click="toggleSelection">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            :disabled="!selection.length"
            depressed
            color="primary"
            class="text-capitalize align-self-end"
            :ripple="false"
            @click="onDelete(selectionIds)"
          >
            <v-icon small class="mr-2">mdi-delete</v-icon> {{ $t('delete') }}
          </v-btn>
        </template>
      </div>
    </div>
    <!-- slot in which the grid is rendered passing also the selection type to it -->
    <slot :selection-type="selectionType"></slot>
  </div>
</template>
<script>
  import { deleteMFWObjects } from '../util'

  export default {
    props: {
      /** used for views headers e.g. Rules, Templates */
      headline: { type: String, default: undefined },
      /** used for embedded grids in another components, e.g. Conditions grid inside Rule editing view */
      title: { type: String, default: undefined },
      /** subtitle text used to add description */
      subtitle: { type: String, default: undefined },
      /** boolean to identify whether the rendered rows are policies or templates **/
      isPolicyOrTemplate: { type: Boolean, default: false },
      /** hides/shows the `Delete...` button if the grid has data or is empty */
      showDelete: { type: Boolean, default: true },
      /** the selected rows */
      selection: { type: Array, default: undefined },
      /**
       * there are 2 delete modes:
       * - permanent (from db) when the removal checks for dependencies
       * - reference - when is't just removing a reference id and not the object itself
       * (e.g. when removing a condition from a rule)
       * */
      permanentDelete: { type: Boolean, default: true },
    },
    data() {
      return {
        /**
         * controls weather the grid rows are selectable and is showing the selection checkbox column
         * - `multiAction` - shows selection
         * - `undefined` - rows are not selectable, but when clicking on them will route to a new view
         * */
        selectionType: undefined,
      }
    },

    computed: {
      /**
       * Returns an array of objects ids based on selection
       * @param vm - view model
       * @param vm.selection - the selected grid rows
       */
      selectionIds: ({ selection }) => selection.map(row => row.id),
    },

    watch: {
      '$route.path': {
        handler() {
          /** toggle to default not selectable state on route change */
          this.selectionType = undefined
        },
        immediate: true,
      },
    },

    methods: {
      /** toggles in/out from the row selection mode */
      toggleSelection() {
        // clear the slection on every toggle
        this.$emit('update:selection', [])
        // this gets passed to the grid through the default slot props
        this.selectionType = !this.selectionType ? 'multiAction' : undefined
      },

      /**
       * handles the delete action
       * - if `permanentDelete` - deletes objects from DB if no dependencies
       * - otherwise - emits an event passing the ids references to be removed from an object (e.g. conditions from a rule)
       * @param {Array<String>} objectIds - object(s) ids to be deleted
       */
      onDelete(objectIds) {
        if (this.permanentDelete) {
          const objects = this.$store.getters['policyManager/getObjectsByIds'](objectIds)
          deleteMFWObjects(objects, this.isPolicyOrTemplate, response => {
            if (response.data?.deleted && this.selectionType) {
              this.toggleSelection()
            }
          })
        } else {
          this.$emit('delete', objectIds)
          if (this.selectionType) this.toggleSelection()
        }
      },
    },
  }
</script>
