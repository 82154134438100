<template>
  <div>
    <v-img v-if="image" :src="image" max-width="100" max-height="100" />

    <div class="mt-2">
      <span class="font-weight-bold">{{ $t('welcome_text') }}:</span> {{ data.welcomeText }}
    </div>
    <div class="mt-2">
      <span class="font-weight-bold">{{ $t('message_text') }}:</span> {{ data.messageText }}
    </div>
    <div class="mt-2">
      <span class="font-weight-bold">{{ $t('timeout') }}:</span> {{ data.timeoutValue }}{{ data.timeoutPeriod }}
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      data: { type: Object, default: () => {} },
    },
    computed: {
      image: ({ data }) => data.logo?.imageData,
    },
  }
</script>
