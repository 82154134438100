import { set } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import api from '@/plugins/ut/ut-api'
import { formatForVuntangle } from '@/util/mfwPolicies'

const getDefaultState = () => {
  return {
    countries: null,
    countriesFetching: false,
    ccViewModel: null,
    sapphireOptions: null,
    mfwPoliciesOptions: null,
    billingInfo: null,
    billingInfoFetching: false,
    avIntegrations: null,
    avIntegrationsFetching: false,
    applications: null,
    applicationsFetching: false,
    wanPolicies: null,
    wanPoliciesFetching: false,
    mobileDevices: null,
    mobileDevicesFetching: false,
  }
}

const getters = {
  countries: state => state.countries,
  countriesFetching: state => state.countriesFetching,
  ccViewModel: state => state.ccViewModel,
  sapphireOptions: state => state.sapphireOptions,
  mfwPoliciesOptions: state => state.mfwPoliciesOptions,
  downloadLinks: state => state.ccViewModel.DownloadLinks,
  hostLocation: state => state.ccViewModel.HostLocation,
  billingInfo: state => state.billingInfo,

  userAccounts: state => state.ccViewModel?.User?.UserAccounts || null,
  userSettings: state => state.ccViewModel?.User?.Settings || null,

  avIntegrations: state => state.avIntegrations,
  avIntegrationsFetching: state => state.avIntegrationsFetching,

  applications: state => formatForVuntangle(state.applications),
  applicationsFetching: state => state.applicationsFetching,

  wanPolicies: state => formatForVuntangle(state.wanPolicies),
  wanPoliciesFetching: state => state.wanPoliciesFetching,

  mobileDevices: state => state.mobileDevices,
  mobileDevicesFetching: state => state.mobileDevicesFetching,

  ipm: state => state.ccViewModel?.InProductMessage,
  widgets: state => state.ccViewModel.SelectedUserAccountSettings.Settings.DashboardWidgets || {},
  policyManagerOverviewWidgets: state =>
    state.ccViewModel.SelectedUserAccountSettings.Settings.PolicyManagerOverviewWidgets || {},
  applianceWidgets: state => {
    // get all appliance widgets
    const widgets = state.ccViewModel?.SelectedUserAccountSettings.Settings.ApplianceWidgets
    // get wordpress admin toggle value for appliance policies widget
    const toggle = state.ccViewModel?.MfwApplianceOptions.AppliancePoliciesWidget
    // remove appliance policies from widgets list if toggle is off
    if (!toggle) {
      delete widgets.appliance_policies
    }
    return widgets || {}
  },
  hasAddApplianceAccess: state =>
    state.ccViewModel?.SelectedUserAccountSettings?.Uids === null ||
    state.ccViewModel?.SelectedUserAccountSettings?.Role === 'primary',

  isUserPrimary: state => userAccount => {
    const settings = userAccount || state.ccViewModel.SelectedUserAccountSettings
    return settings?.Role === 'primary'
  },
  isUserPartnerForAccount: state => {
    return state.ccViewModel.Account.PartnerInfo?.is_partner
  },
  isUserPartnerAny: state => {
    return state.ccViewModel.IsUserPartner
  },
  isUserUntangler: state => {
    return state.ccViewModel.ShowWPAdminLink
  },

  // used for passing in a simple string name of the theme for props in components
  theme: state => (state.ccViewModel.Account.AccountSettings.Theme === 'Dark Theme' ? 'dark' : 'light'),
  NoLicenseEnforcement: state => state.ccViewModel.Account.NoLicenseEnforcement,
  // flag to indicate if account is allowed to buy new subscriptions
  canAccountBuy: state => state.ccViewModel.Account.CanBuy,
}

const mutations = {
  RESET: state => {
    if (!state.ccViewModel) {
      Object.assign(state, getDefaultState())
      return
    }
    // add back static information we need for users who are not logged in, primarily used for login page
    const staticCcViewModel = {
      Options: state.ccViewModel.Options,
      StoreUrl: state.ccViewModel.StoreUrl,
      SiteUrl: state.ccViewModel.SiteUrl,
      DownloadLinks: state.ccViewModel.DownloadLinks,
      HostLocation: state.ccViewModel.HostLocation,
      IsSandboxMode: state.ccViewModel.IsSandboxMode,
      GoogleMapsApiKey: state.ccViewModel.GoogleMapsApiKey,
      IsVpnTabVisible: state.ccViewModel.IsVpnTabVisible,
    }

    // do not reset saphire options, needed for login page
    const staticSapphireOptions = state.sapphireOptions ? cloneDeep(state.sapphireOptions) : null

    Object.assign(state, getDefaultState())
    state.ccViewModel = staticCcViewModel
    state.sapphireOptions = staticSapphireOptions
  },

  SET_COUNTRIES: (state, value) => (state.countries = value),
  SET_COUNTRIES_FETCHING: (state, value) => (state.countriesFetching = value),

  // sets the ccViewModel
  SET_CC_VIEW_MODEL: (state, value) => set(state, 'ccViewModel', value),

  // sets the sapphireOptions
  SET_SAPPHIRE_OPTIONS: (state, value) => set(state, 'sapphireOptions', value),

  // sets the mfwPoliciesOptions
  SET_MFW_POLICIES_OPTIONS: (state, value) => set(state, 'mfwPoliciesOptions', value),

  // sets the mfwApplianceOptions
  SET_MFW_APPLIANCE_OPTIONS: (state, value) => set(state, 'mfwApplianceOptions', value),

  // sets user consent
  SET_PRIVACY_CONSENT: (state, value) => set(state.ccViewModel.User, 'HasConsentedToPrivacyPolicy', value),

  // sets the billing info
  SET_BILLING_INFO: (state, value) => (state.billingInfo = value),
  SET_BILLING_INFO_FETCHING: (state, value) => (state.billingInfoFetching = value),

  // sets the av integrations
  SET_AV_INTEGRATIONS: (state, value) => (state.avIntegrations = value),
  SET_AV_INTEGRATIONS_FETCHING: (state, value) => (state.avIntegrationsFetching = value),

  // sets the list of applications
  SET_APPLICATIONS: (state, value) => (state.applications = value.sort((a, b) => a.Name.localeCompare(b.Name))),
  SET_APPLICATIONS_FETCHING: (state, value) => (state.applicationsFetching = value),

  // sets the list of wan policies
  SET_WAN_POLICIES: (state, value) => (state.wanPolicies = value),
  SET_WAN_POLICIES_FETCHING: (state, value) => (state.wanPoliciesFetching = value),

  // updates the user preferences
  SET_USER_ACCOUNT_PREFERENCES: (state, value) => set(state.ccViewModel.SelectedUserAccountSettings, 'Settings', value),
  // updates ccViewModel.Account
  UPDATE_ACCOUNT: (state, value) => set(state.ccViewModel, 'Account', value),
  // updates ccViewModel.User
  UPDATE_USER: (state, value) => set(state.ccViewModel, 'User', value),
  UPDATE_USER_SETTINGS: (state, value) => set(state.ccViewModel.User, 'Settings', value),

  // updates addresses
  UPDATE_ADDRESSES: (state, value) => {
    set(state.ccViewModel.Account.ShoppCustomer, 'Billing', value.billing)
    set(state.ccViewModel.Account.ShoppCustomer, 'Shipping', value.shipping)
  },
  // updates partner info
  UPDATE_PARTNER: (state, value) => set(state.ccViewModel.Account, 'PartnerInfo', value),

  UPDATE_ACCOUNT_SETTINGS: (state, value) => set(state.ccViewModel.Account, 'AccountSettings', value),

  UPDATE_ACCOUNT_CAN_BUY: (state, value) => set(state.ccViewModel.Account, 'CanBuy', value),

  SET_DEFAULT_ORGANIZATION: (state, organizationId) => {
    const account = state.ccViewModel.User.UserAccounts.find(a => a.AccountId === organizationId)
    set(account, 'IsDefaultOrganization', 'yes')
  },
  UNSET_DEFAULT_ORGANIZATION: state => {
    state.ccViewModel.User.UserAccounts.forEach(account => {
      // account.IsDefaultOrganization = 'no'
      set(account, 'IsDefaultOrganization', 'no')
    })
  },

  SET_MOBILE_DEVICES: (state, value) => (state.mobileDevices = value),
  SET_MOBILE_DEVICES_FETCHING: (state, value) => (state.mobileDevicesFetching = value),

  SET_THEME: (state, value) => {
    set(state.ccViewModel.Account.AccountSettings, 'Theme', value)
  },

  UPDATE_DASHBOARD_WIDGETS: (state, value) => {
    set(state.ccViewModel?.SelectedUserAccountSettings.Settings, 'DashboardWidgets', value)
  },
  UPDATE_POLICY_MANAGER_OVERVIEW_WIDGETS: (state, value) => {
    set(state.ccViewModel?.SelectedUserAccountSettings.Settings, 'PolicyManagerOverviewWidgets', value)
  },
  UPDATE_APPLIANCE_WIDGETS: (state, value) => {
    set(state.ccViewModel?.SelectedUserAccountSettings.Settings, 'ApplianceWidgets', value)
  },
  // sets account sso organization
  SET_SSO_ORGANIZATION: (state, value) => set(state.ccViewModel.Account, 'SsoOrganization', value),

  SET_SSO_ORGANIZATION_LOGIN_TYPE: (state, value) => set(state.ccViewModel.Account, 'SsoOrganizationLoginType', value),
  // sets account saml configuration, clone the value as it's an object
  SET_SAML_CONFIGURATION: (state, value) => set(state.ccViewModel.Sso, 'saml', cloneDeep(value)),
  // sets account oauth configuration, clone the value as it's an object
  SET_OAUTH_CONFIGURATION: (state, value) => set(state.ccViewModel.Sso, 'oauth2', cloneDeep(value)),
  // sets SSO enabled flag in account settings
  SET_SSO_ENFORCED: (state, value) => set(state.ccViewModel.Account.AccountSettings, 'EnforceSso', value),
}

const actions = {
  async fetchCountries({ state, commit }) {
    // fetch countries only if not already fetched
    if (state.countries !== null && !state.countriesFetching) {
      return
    }

    commit('SET_COUNTRIES_FETCHING', true)
    const response = await api.ajaxRequest('getCountries')
    if (response.status === 200 && response.data) {
      commit('SET_COUNTRIES', Object.freeze(response.data))
    }
    commit('SET_COUNTRIES_FETCHING', false)
  },

  async fetchCcViewModel({ commit }, requestParams = null) {
    const response = await api.ajaxRequest('fetchCcViewModel', requestParams, null, 'GET')
    commit('auth/SET_IS_AUTH', !!response.data?.IsUserLoggedIn, { root: true })

    if (response.data) {
      commit('SET_CC_VIEW_MODEL', response.data)
      commit('SET_SAPPHIRE_OPTIONS', response.data.SapphireOptions)
      commit('SET_MFW_POLICIES_OPTIONS', response.data.MfwPolicies)
      commit('SET_MFW_APPLIANCE_OPTIONS', response.data.MfwApplianceOptions)
    } else {
      commit('SET_CC_VIEW_MODEL', null)
      commit('SET_SAPPHIRE_OPTIONS', null)
      commit('SET_MFW_POLICIES_OPTIONS', null)
      commit('SET_MFW_APPLIANCE_OPTIONS', null)
    }
  },

  async fetchBillingInfo({ state, commit }) {
    if (state.billingInfo !== null && !state.billingInfoFetching) {
      return
    }

    commit('SET_BILLING_INFO_FETCHING', true)
    const response = await api.cloud('Untangle_CommandCenter', 'GetBillingInfo')
    if (response.success && response.data) {
      commit('SET_BILLING_INFO', Object.freeze(response.data))
    }
    commit('SET_BILLING_INFO_FETCHING', false)
  },

  /**
   * fetches the AntiVirus integrations settings
   * @param {*} commit
   */
  async fetchAvIntegrations({ state, commit }, options) {
    if (state.avIntegrationsFetching || (state.avIntegrations !== null && !options?.force)) {
      return
    }
    commit('SET_AV_INTEGRATIONS_FETCHING', true)
    const response = await api.cloud('Untangle_CommandCenter', 'GetAVUsers')
    if (response.success && response.data) {
      // NOTE: the response is a json string and has to be parsed
      commit('SET_AV_INTEGRATIONS', JSON.parse(response.data))
    }
    commit('SET_AV_INTEGRATIONS_FETCHING', false)
  },

  /**
   * fetches the applications from protolist.csv
   * @param {*} commit
   */
  async fetchApplications({ state, commit }) {
    // only fetch applications if not already fetched, or not currently fetching
    if (state.applications != null && !state.applicationsFetching) {
      return
    }
    commit('SET_APPLICATIONS_FETCHING', true)
    const response = await api.ajaxRequest('getApplications')
    if (response.status === 200 && response.data) {
      commit('SET_APPLICATIONS', response.data)
    }
    commit('SET_APPLICATIONS_FETCHING', false)
  },

  /**
   * fetches user policies and default policies
   * @param {*} commit
   */
  async fetchWanPolicies({ state, commit }) {
    // only fetch wan policies if not currently fetching
    if (state.wanPoliciesFetching) {
      return
    }
    commit('SET_WAN_POLICIES_FETCHING', true)
    const response = await api.ajaxRequest('getUserAndDefaultPolicies')
    if (response.status === 200 && response.data) {
      commit('SET_WAN_POLICIES', response.data)
    }
    commit('SET_WAN_POLICIES_FETCHING', false)
  },

  /**
   * Updates user account preferences (dashboard and appliance widgets)
   * @param {*} commit
   * @param {*} data - payload data
   */
  async updateUserAccountPreferences({ commit }, data) {
    const response = await api.payload(
      {
        handler: 'Untangle_CommandCenter',
        method: 'SetUserAccountPreferences',
        paramOrder: 'payload',
      },
      data,
    )
    if (response.success && response.data && response.data.success !== false) {
      // update store with new settings received via response
      commit('SET_USER_ACCOUNT_PREFERENCES', response.data.NewSettings)
    }

    return response
  },

  /**
   * Updates user account information
   * @param {*} commit
   * @param {*} data - payload data
   */
  async updateUserInfo({ commit }, data) {
    const response = await api.cloud('Untangle_CommandCenter', 'UpdateUserInfo', data)
    if (response.success && response.data) {
      commit('UPDATE_ACCOUNT', response.data.Account)
      commit('UPDATE_USER', response.data.User)
    }
    return response
  },

  /**
   * Leave organization from the organizations page
   * @param commit
   * @param accountId - accountId for the org user wishes to leave
   */
  async leaveOrganization({}, accountId) {
    const response = await api.cloud('Untangle_CommandCenter', 'DeleteUserFromOrganization', {
      accountId,
      paramOrder: 'accountId',
    })

    return response
  },

  /**
   * Updates user account preferences (dashboard and appliance widgets)
   * @param {*} commit
   * @param {*} data - payload data
   */
  async updateAddresses({ commit }, data) {
    const response = await api.payload(
      {
        handler: 'Untangle_CommandCenter',
        method: 'UpdateAddresses',
        paramOrder: 'payload',
      },
      data,
    )
    if (response.success && response.data) {
      // update store with new settings received via response
      commit('UPDATE_ADDRESSES', {
        billing: response.data.ShoppCustomer?.Billing,
        shipping: response.data.ShoppCustomer?.Shipping,
      })
      commit('UPDATE_ACCOUNT_CAN_BUY', response.data.CanBuy)
    }
    return response
  },

  /**
   * Updates user account preferences (dashboard and appliance widgets)
   * @param {*} commit
   * @param {*} data - payload data
   */
  async updatePartner({ commit }, data) {
    const response = await api.payload(
      {
        handler: 'Untangle_CommandCenter',
        method: 'UpdatePartnerInfo',
        paramOrder: 'payload',
      },
      data,
    )
    if (response.success) {
      // update store with data posted (as response does not contain data)
      commit('UPDATE_PARTNER', data)
    }
    return response
  },

  /**
   * Updates user account settings
   * @param {*} commit
   * @param {*} data - payload data
   */
  async UpdateUserSettings({ commit }, data) {
    const response = await api.payload(
      {
        handler: 'Untangle_CommandCenter',
        method: 'UpdateUserSettings',
        paramOrder: 'payload',
      },
      data,
    )

    if (response.success && response.data?.success) {
      // update store with data posted (as response does not contain data)
      commit('UPDATE_USER_SETTINGS', response.data.NewSettings)
    }
    return response
  },

  /**
   * Updates user account settings
   * @param {*} commit
   * @param {*} data - payload data
   */
  async updateAccountSettings({ commit }, data) {
    const response = await api.payload(
      {
        handler: 'Untangle_CommandCenter',
        method: 'SetAccountSettings',
        paramOrder: 'payload',
      },
      data,
    )
    if (response.success && response.data) {
      // update store with data posted (as response does not contain data)
      commit('UPDATE_ACCOUNT_SETTINGS', response.data.NewSettings)
    }
    return response
  },

  /**
   * Updates AV partner integration
   * @param {Object} data - partner data
   * @param {String} data.vendor - The vendor name/key
   * @param {String} data.username - The user
   * @param {String} data.password - The password
   * @param {String} data.parentKeycode - The key code for WebRoot integration
   * @param {String} data.paramOrder - "vendor username password parentKeycode"
   */
  async setAvSettings({}, data) {
    return await api.cloud('Untangle_CommandCenter', 'SetAVSettings', data)
    // nothing to commit, the settings are refreshed inside page
  },

  /**
   * Deletes AV partner integration
   * @param {Object} data - data
   * @param {String} data.vendor - partner vendor identification
   * @param {String} data.paramOrder - "vendor"
   */
  async deleteAvSettings({}, data) {
    return await api.cloud('Untangle_CommandCenter', 'DeleteAVSettings', data)
  },

  /**
   * sends a certificate ID to the back end and requests change in account's tax
   * exempt status
   *
   * @param certificateId
   * @returns {Promise<*>}
   */
  async updateTaxExemptStatus({ commit }, certificateId) {
    const response = await api.cloud('Untangle_CommandCenter', 'UpdateTaxExemptStatus', {
      certificateId,
      paramOrder: 'certificateId',
    })
    if (response.data?.BillingInfo) {
      commit('SET_BILLING_INFO', Object.freeze(response.data.BillingInfo))
    }
    return response
  },

  async updateTaxVatNumber({ commit }, taxVatNumber) {
    const response = await api.cloud('Untangle_CommandCenter', 'UpdateTaxVATNumber', {
      taxVATNumber: taxVatNumber,
      paramOrder: 'taxVATNumber',
    })
    if (response.data?.BillingInfo) {
      commit('SET_BILLING_INFO', Object.freeze(response.data.BillingInfo))
    }
    return response
  },

  /**
   * Sets/Unsets the default organization for an account with multiple organizations
   * @param {Function} commit
   * @param {Object} data
   * @param {String} data.defaultOrganizationId - The account organization ID
   * @param {String} data.paramOrder - "defaultOrganizationId"
   */
  async setUnsetDefaultOrganization({ commit }, data) {
    const response = await api.ajaxRequest('updateUserDefaultOrganization', data)
    if (response.data?.success) {
      if (data.defaultOrganizationId && data.defaultOrganizationId !== '') {
        // set
        commit('SET_DEFAULT_ORGANIZATION', data.defaultOrganizationId)
      } else {
        // unset
        commit('UNSET_DEFAULT_ORGANIZATION')
      }
    }
  },

  /**
   * fetches the paired mobile devices
   * @param {*} commit
   */
  async fetchMobileDevices({ state, commit }, options) {
    if (state.mobileDevicesFetching || (state.mobileDevices !== null && !options?.force)) {
      return
    }
    commit('SET_MOBILE_DEVICES_FETCHING', true)
    const response = await api.cloud('Untangle_CommandCenter', 'GetMobileDevices')
    if (response.success && response.data) {
      commit('SET_MOBILE_DEVICES', response.data)
    }
    commit('SET_MOBILE_DEVICES_FETCHING', false)
  },

  /**
   * removes paired devices
   *
   * @param {Object} data
   * @param {String} data.tokens - The devices tokens to be removed
   * @param {String} data.paramOrder - "tokens"
   */
  async removeDevices({}, data) {
    return await api.cloud('Untangle_CommandCenter', 'RemoveMobileDevices', data)
  },

  /**
   * Updates SSO settings
   * @param {*} commit
   * @param {*} data - payload data
   */
  async saveEnforceSso({ commit }, enforceSso) {
    const response = await api.cloud('Untangle_CommandCenter', 'SaveEnforceSso', {
      enforceSso,
      paramOrder: 'enforceSso',
    })
    if (response.success && !response.data.message) {
      commit('SET_SSO_ENFORCED', enforceSso)
    }
    return response
  },

  /**
   * Updates SAML settings
   * @param {*} commit
   * @param {*} data - payload data
   */
  async saveSsoOrganization({ commit }, data) {
    const response = await api.payload(
      {
        handler: 'Untangle_CommandCenter',
        method: 'SaveSsoOrganization',
        paramOrder: 'payload',
      },
      data,
    )
    if (response.success && !response.data.message) {
      commit('SET_SSO_ORGANIZATION', data.organizationName)
      commit('SET_SSO_ORGANIZATION_LOGIN_TYPE', data.organizationLoginType)

      if (data.organizationLoginType === 'saml') {
        commit('SET_SAML_CONFIGURATION', cloneDeep(data.config))
      } else if (data.organizationLoginType === 'oauth2') {
        commit('SET_OAUTH_CONFIGURATION', cloneDeep(data.config))
      }
    }
    return response
  },

  /**
   * Delete sso config
   *
   * @param {*} commit
   * @param {*} type - config type
   */
  async deleteSsoConfig({ commit }, type) {
    const response = await api.cloud('Untangle_CommandCenter', 'DeleteSsoConfig', {
      type,
      paramOrder: 'type',
    })

    if (response.success && !response.data.message) {
      if (type === 'saml') {
        commit('SET_SAML_CONFIGURATION', null)
      } else if (type === 'oauth2') {
        commit('SET_OAUTH_CONFIGURATION', null)
      }
    }
    return response
  },
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions,
}
