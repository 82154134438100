var render = function render(){var _vm=this,_c=_vm._self._c;return _c('u-page',{attrs:{"title":_vm.title,"full-height":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('u-btn',{attrs:{"disabled":_vm.$store.state.appliances.list === null},on:{"click":_vm.displayCreatePolicyDialog}},[_vm._v(" "+_vm._s(_vm.$t('create'))+" ")]),_c('u-btn',{attrs:{"disabled":_vm.existingPoliciesSelectedRows.length !== 1},on:{"click":_vm.displayPushPolicyDialog}},[_vm._v(" "+_vm._s(_vm.$t('set_appliance_policy'))+" ")]),_c('u-btn',{attrs:{"disabled":!_vm.existingPoliciesSelectedRows.length},on:{"click":_vm.displayDeletePolicyDialog}},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])]},proxy:true}])},[(_vm.policyManagerRequired)?_c('div',{staticClass:"mb-4"},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v(" "+_vm._s(_vm.$t('policy_manager_alert_text'))+" ")],1):_vm._e(),_c('u-grid',{attrs:{"id":"policies-grid","selection-type":"multiAction","no-data-message":_vm.$t('no_data'),"column-defs":_vm.existingPoliciesColumnDefs,"fetching":_vm.pendingAction,"row-data":_vm.existingPolicies,"selection":_vm.existingPoliciesSelectedRows},on:{"update:selection":function($event){_vm.existingPoliciesSelectedRows=$event},"refresh":_vm.fetchExistingPolicies}}),_c('u-dialog',{attrs:{"width":700,"show-dialog":_vm.createPolicyDialog,"title":_vm.$t('create_policy'),"message":_vm.$t('please_select_a_source_appliance'),"buttons":[
      {
        'name': _vm.$t('cancel'),
      },
      {
        'name': _vm.$t('ok'),
        'handler': 'create-policy',
        disabled: _vm.createPolicySelectedRows.length === 0,
        showProgress: true,
      },
    ]},on:{"close-dialog":function($event){_vm.createPolicyDialog = false},"create-policy":_vm.createPolicy}},[(_vm.policiesLoaded && _vm.createPolicyDialog)?_c('u-grid',{staticStyle:{"height":"500px"},attrs:{"id":"create-policy-appliance-grid","selection-type":"singleAction","no-data-message":_vm.$t('no_data'),"column-defs":_vm.appliancePolicyColumnDefs,"fetching":_vm.$store.state.appliances.fetching,"row-data":_vm.createPolicyRows,"selection":_vm.createPolicySelectedRows,"enable-refresh":false},on:{"update:selection":function($event){_vm.createPolicySelectedRows=$event}}}):_vm._e()],1),_c('u-dialog',{attrs:{"show-dialog":_vm.pushPolicyNoAppliancesDialog,"title":"Push Policy","message":_vm.$tc('no_compatible_appliances_available', _vm.existingPolicyVersion)},on:{"close-dialog":function($event){_vm.pushPolicyNoAppliancesDialog = false}}}),_c('u-dialog',{attrs:{"width":700,"show-dialog":_vm.pushPolicyDialog,"title":_vm.$t('push_policy'),"message":_vm.$t('please_select_target_appliances'),"buttons":[
      {
        'name': _vm.$t('cancel'),
      },
      {
        'name': _vm.$t('ok'),
        'handler': 'create-policy',
        disabled: _vm.pushPolicySelectedRows.length === 0,
        showProgress: true,
      },
    ]},on:{"close-dialog":function($event){_vm.pushPolicyDialog = false},"create-policy":_vm.pushPolicy}},[(_vm.policiesLoaded && _vm.pushPolicyDialog)?_c('u-grid',{staticStyle:{"height":"500px"},attrs:{"id":"push-policy-appliance-grid","selection-type":"multiAction","no-data-message":_vm.$t('no_data'),"column-defs":_vm.appliancePolicyColumnDefs,"fetching":_vm.$store.state.appliances.fetching,"row-data":_vm.pushPolicyRows,"selection":_vm.pushPolicySelectedRows,"enable-refresh":false},on:{"update:selection":function($event){_vm.pushPolicySelectedRows=$event}}}):_vm._e()],1),_c('u-dialog',{attrs:{"show-dialog":_vm.deletePolicyDialog,"title":_vm.$t('delete_policy'),"message":_vm.$t('confirm_delete_policy'),"buttons":[
      {
        name: _vm.$t('cancel'),
      },
      {
        name: _vm.$t('yes'),
        handler: 'delete-policy',
        showProgress: true,
      },
    ]},on:{"close-dialog":function($event){_vm.deletePolicyDialog = false},"delete-policy":_vm.deletePolicy}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }