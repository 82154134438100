<template>
  <settings-wan-policies v-if="boxSettings" ref="policy" :settings="policy" :wans="wans" show-description>
    <template #actions="{ newSettings }">
      <u-btn class="mr-2" @click="$router.push({ name: 'appliances-wan-policies' })">{{ $t('back_to_list') }}</u-btn>
      <u-btn :min-width="null" @click="onSave(newSettings)">{{ $t('save') }}</u-btn>
    </template>
  </settings-wan-policies>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { SettingsWanPolicies } from 'vuntangle'
  import settingsMixin from './settingsMixin'

  export default {
    components: { SettingsWanPolicies },
    mixins: [settingsMixin],
    computed: {
      policyId: ({ $route }) => $route.params.policyId,
      policies: ({ boxSettings }) => boxSettings?.wan.policies || [],
      policy: ({ policies, policyId }) => policies.find(policy => policy.policyId === policyId),
      wans: ({ boxSettings }) => boxSettings.network.interfaces?.filter(intf => intf.wan || intf.management) || [],
    },

    methods: {
      /**
       * Updates existing policy or adds a new one
       * @param {Object} updatedPolicy - the updated policy set via #actions slot props
       */
      async onSave(updatedPolicy) {
        const isValid = await this.$refs.policy.validate()
        if (!isValid) return

        // add to or update policies for saving
        const policies = cloneDeep(this.policies)
        const interfaces = cloneDeep(this.interfaces)
        let needsGuid
        if (updatedPolicy.policyId) {
          needsGuid = false
          // replace index of existing policies
          const index = policies.findIndex(policy => policy.policyId === updatedPolicy.policyId)
          if (index >= 0) {
            policies.splice(index, 1, updatedPolicy)
          }
        } else {
          needsGuid = true
          policies.push(updatedPolicy)
        }

        const response = await this.saveSettings('wan/policies', policies, needsGuid)
        if (response.success) {
          this.$router.push({ name: 'appliances-wan-policies', params: { policiesRule: this.policies, interfaces } })
        }
      },
    },
  }
</script>
