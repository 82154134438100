import { captureException } from '@sentry/vue'
import store from '@/store'
import http from '@/plugins/axios'
import Container from '@/util/container'

const autoLogout = {
  // hold the 'setTimeout' timer for checking the 'inactivity logout period' on the server
  autoLogoutTimer: null,

  /**
   * Start an auto logout timer that will log the user out automatically when it hits their 'inactivity logout period'
   * in the users account settings.
   *
   * @param {Function} commit
   * @param {Function} dispatch
   *
   * @returns {void}
   */
  startAutoLogoutTimer() {
    // start the auto logout timer, increment idle minute
    this.autoLogoutTimer = setTimeout(() => this.runAutoLogout(), 60000)
  },

  /**
   * Run the auto logout timer that will check if their session is logged out on the server and log them out
   * from the application.
   *
   * @param {Function} dispatch
   * @param {Function} state
   *
   * @returns {void}
   */
  async runAutoLogout() {
    // clear any previous timer in case of any overlapping from login and main app bootstrapping
    this.clearAutoLogoutTimer()
    let response = {}
    try {
      response = await http({
        url: '/clearInactiveSessions',
        method: 'GET',
        headers: { 'session-checker': true },
      })
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error(ex)
      captureException(ex)
    }

    /**
     * Check the 'expired' response to see if the user is logged out on the server.
     * A 401 response will be handled by $axios.onError
     */
    if (response.data?.expired === true || response.status === 503) {
      this.autoLogout()
      const router = Container.getInstance('router')
      router.push({
        path: '/login',
        query: router.currentRoute.fullPath !== '/' ? { redirectUrl: router.currentRoute.fullPath } : {},
      })
      return
    }
    // start the auto logout timer again
    this.startAutoLogoutTimer()
  },

  /**
   * Clear the auto logout timer.  This is done during logout and checked before a timer does an ajax call
   * to make sure another timer was not queued.
   *
   * @param {Function} commit
   * @param {Function} state
   *
   * @returns {void}
   */
  clearAutoLogoutTimer() {
    if (this.autoLogoutTimer) {
      clearTimeout(this.autoLogoutTimer)
      this.autoLogoutTimer = null
    }
  },

  /**
   * Autologout from the system when session times out on the server.
   *
   * @param {Function} commit
   * @param {Function} dispatch
   *
   * @returns {void}
   */
  autoLogout() {
    store.commit('auth/SET_LOGIN_MESSAGE', 'session_expired')
    store.dispatch('cleanupBeforeLogout')
  },
}

export default autoLogout
