var render = function render(){var _vm=this,_c=_vm._self._c;return _c('u-widget',_vm._g({attrs:{"title":_vm.$t('appliances')}},_vm.$listeners),[_c('v-card-actions',{staticClass:"my-1 px-0"},[_c('u-btn',{staticClass:"ml-2 mr-2",attrs:{"disabled":_vm.network == null},on:{"click":_vm.doAdd}},[_vm._v(" "+_vm._s(_vm.$t('add_appliance'))+" ")]),_c('u-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.deleteAppliancesSelected.length === 0 ||
        _vm.appliancesRowData.length === 1 ||
        _vm.appliancesRowData.length === _vm.deleteAppliancesSelected.length},on:{"click":function($event){_vm.deleteAppliancesDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('remove_appliances'))+" ")])],1),_c('u-grid',{attrs:{"id":"network-delete-appliances","toolbar":"hidden","row-node-id":"Uid","selection-type":"multiAction","no-data-message":_vm.$t('no_data'),"column-defs":_vm.appliancesColumnDefs,"fetching":_vm.$store.state.networks.fetching || _vm.$store.state.appliances.fetching,"row-data":_vm.appliancesRowData,"selection":_vm.deleteAppliancesSelected},on:{"update:selection":function($event){_vm.deleteAppliancesSelected=$event},"refresh":function($event){return _vm.$store.dispatch('appliances/fetchAppliances', { force: true })}}}),_c('u-dialog',{attrs:{"show-dialog":_vm.addAppliancesDialog,"title":_vm.$t('add_appliance'),"buttons":_vm.addAppliancesDialogButtons,"width":"80%"},on:{"close-dialog":function($event){_vm.addAppliancesDialog = false},"addAppliances":_vm.addAppliances}},[(_vm.addAppliancesDialog)?_c('u-grid',{staticStyle:{"height":"300px"},attrs:{"id":"network-add-appliances","row-node-id":"Uid","selection-type":"multiAction","no-data-message":_vm.$t('no_data'),"column-defs":_vm.addAppliancesColumnDefs,"fetching":_vm.$store.state.appliances.fetching,"row-data":_vm.addAppliancesRowData,"selection":_vm.addAppliancesSelected},on:{"update:selection":function($event){_vm.addAppliancesSelected=$event},"refresh":function($event){return _vm.$store.dispatch('appliances/fetchAppliances', { force: true })}}}):_vm._e(),(_vm.addAppliancesSaving)?_c('div',{staticClass:"d-flex flex-column align-stretch fill-height"},[_c('v-overlay',{staticClass:"text-center",attrs:{"absolute":true,"opacity":"0.5","color":"#333","z-index":"999"}})],1):_vm._e()],1),_c('u-dialog',{attrs:{"show-dialog":_vm.deleteAppliancesDialog,"title":_vm.$t('remove_appliances'),"message":_vm.$t('confirm_remove_appliances'),"buttons":[
      { name: _vm.$t('cancel') },
      {
        name: _vm.$t('yes'),
        handler: 'deleteAppliances',
        showProgress: true,
      },
    ]},on:{"close-dialog":function($event){_vm.deleteAppliancesDialog = false},"deleteAppliances":_vm.deleteAppliances}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }