<template>
  <div class="py-2">
    <h3>{{ configuration.Name }}</h3>
    <span class="text-caption">{{ configuration.Description }}</span>
    <v-divider class="my-2" dark />

    <div v-if="showEnabled" class="mb-2">
      <span class="font-weight-bold">{{ $t('enabled') }}: </span> {{ data.enabled ? $t('yes') : $t('no') }}
    </div>

    <component :is="tooltipComponent" v-if="tooltipComponent" :data="data" />
  </div>
</template>
<script>
  import { templatesConfig, Type } from 'vuntangle/pm'

  import {
    TooltipGeoipFilter,
    TooltipWebFilter,
    TooltipApplicationControl,
    TooltipCaptivePortal,
    TooltipThreatPrevention,
    TooltipWanPolicy,
  } from '../tooltips'

  export default {
    components: {
      TooltipGeoipFilter,
      TooltipWebFilter,
      TooltipApplicationControl,
      TooltipCaptivePortal,
      TooltipThreatPrevention,
      TooltipWanPolicy,
    },
    props: {
      configuration: { type: Object, default: () => {} },
    },

    computed: {
      data: ({ configuration }) => configuration.PolicyJson,
      tooltipComponent: ({ configuration }) => templatesConfig[configuration.Type].tooltipComponent,
      showEnabled: ({ configuration }) => configuration.Type !== Type.ConfigWanPolicy,
    },
  }
</script>
