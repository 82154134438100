<template>
  <v-toolbar
    v-if="isNewDrawer"
    dense
    dark
    flat
    color="primary white--text px-0"
    class="flex-grow-0"
    :style="`position: sticky; z-index: 4; top: ${!$isSapphire ? '50px' : '0'}`"
  >
    <template v-if="$isSapphire">
      <v-btn small text @click="$router.push({ name: 'me-policies' })">
        <v-icon>mdi-chevron-left</v-icon>
        {{ $t('back_to_policies') }}
      </v-btn>
      <v-divider vertical class="mx-4" />
    </template>
    <v-autocomplete
      v-model="id"
      :items="config.list()"
      :filter="filter"
      :placeholder="$t('filter_placeholder')"
      :no-data-text="$t('no_data')"
      solo
      hide-details
      item-text="firstText"
      item-value="id"
      dense
      filled
      return-object
      background-color="transparent"
      flat
      prepend-inner-icon="mdi-magnify"
    >
      <template #selection="data">
        <selection-bar-item :item="data.item" type="selection" class="ml-1" />
      </template>
      <template #item="data" class="mx-0 px-0">
        <selection-bar-item :item="data.item" type="data" />
      </template>
    </v-autocomplete>
    <u-btn
      v-if="config.actionButton && !$isSapphire"
      :min-width="null"
      :disabled="config.actionButton.disabled"
      class="ml-4"
      color="white"
      text
      @click="actionButtonHandler"
    >
      <v-icon class="mr-2 d-block d-lg-none">mdi-plus-circle</v-icon>
      <span class="d-none d-lg-block">{{ config.actionButton().label }} </span>
    </u-btn>
  </v-toolbar>
</template>
<script>
  // eslint-disable-next-line import/order
  import SelectionBarItem from './SelectionBarItem.vue'
  import selectionBarHelper from './selectionBarHelper'
  import store from '@/store'

  export default {
    components: { SelectionBarItem },

    props: {
      configType: { type: String, default: undefined },
    },

    computed: {
      config: ({ configType }) => selectionBarHelper[configType],
      id: {
        get: ({ $route }) => $route.params.id,
        set(item) {
          this.$router.push(item.to)
        },
      },
      isNewDrawer() {
        return store.state.data.sapphireOptions && store.state.data.sapphireOptions.SideMenu
      },
    },
    methods: {
      /**
       * Filter method for the items
       * @param {Object} item - an item from the list
       * @param {String} queryText - the filter query string
       */
      filter(item, queryText) {
        const searchText = queryText.toLowerCase()
        if (!item.filterText) return false
        let match = false
        item.filterText.forEach(str => {
          if (!match && str?.toLowerCase().includes(searchText)) {
            match = true
          }
        })
        return match
      },

      /**
       * Handler for Action Button click
       */
      actionButtonHandler() {
        if (this.config.actionButton().ga) {
          window.ga('send', this.config.actionButton().ga)
        }
        this.$router.push(this.config.actionButton().to)
      },
    },
  }
</script>
