import Vue from 'vue'
import Vuetify, {
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VAlert,
  VAutocomplete,
  VAvatar,
  VBottomNavigation,
  VBottomSheet,
  VBreadcrumbs,
  VBreadcrumbsItem,
  VBtn,
  VBtnToggle,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VCarousel,
  VCarouselItem,
  VCheckbox,
  VChip,
  VCol,
  VCombobox,
  VContainer,
  VDatePicker,
  VDialog,
  VDivider,
  VForm,
  VHover,
  VIcon,
  VImg,
  VLayout,
  VList,
  VListGroup,
  VListItem,
  VListItemAction,
  VListItemAvatar,
  VListItemContent,
  VListItemGroup,
  VListItemIcon,
  VListItemSubtitle,
  VListItemTitle,
  VMain,
  VMenu,
  VMessages,
  VNavigationDrawer,
  VOverlay,
  VProgressCircular,
  VRadio,
  VRadioGroup,
  VRow,
  VSelect,
  VSheet,
  VSimpleTable,
  VSlider,
  VSlideGroup,
  VSlideItem,
  VSnackbar,
  VSpacer,
  VSubheader,
  VSwitch,
  VTextarea,
  VTextField,
  VTimePicker,
  VToolbar,
  VToolbarTitle,
  VTooltip,
  VTab,
  VTabs,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
} from 'vuetify/lib'
import { vuntangleTheme } from 'vuntangle'

Vue.use(Vuetify, {
  components: {
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAlert,
    VAutocomplete,
    VAvatar,
    VBottomNavigation,
    VBottomSheet,
    VBreadcrumbs,
    VBreadcrumbsItem,
    VBtn,
    VBtnToggle,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VCarousel,
    VCarouselItem,
    VCheckbox,
    VChip,
    VCol,
    VCombobox,
    VContainer,
    VDatePicker,
    VDialog,
    VDivider,
    VForm,
    VHover,
    VIcon,
    VImg,
    VLayout,
    VList,
    VListGroup,
    VListItem,
    VListItemAction,
    VListItemAvatar,
    VListItemContent,
    VListItemGroup,
    VListItemIcon,
    VListItemSubtitle,
    VListItemTitle,
    VMain,
    VMenu,
    VMessages,
    VNavigationDrawer,
    VOverlay,
    VProgressCircular,
    VRadio,
    VRadioGroup,
    VRow,
    VSelect,
    VSheet,
    VSimpleTable,
    VSlider,
    VSlideGroup,
    VSlideItem,
    VSnackbar,
    VSpacer,
    VSubheader,
    VSwitch,
    VTextarea,
    VTextField,
    VTimePicker,
    VToolbar,
    VToolbarTitle,
    VTooltip,
    VTab,
    VTabs,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent,
  },
})

export default new Vuetify(vuntangleTheme)
