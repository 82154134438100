<template>
  <u-widget :title="$t('network_performance')" v-on="$listeners">
    <u-chart-stock :fetching="networkPerformanceFetching" :options="options" />
  </u-widget>
</template>

<script>
  import merge from 'lodash/merge'
  import i18n from '@/plugins/vue-i18n'
  import { timeReportOptions, baseReportOptions } from '@/util/chartOptions'

  export default {
    props: {
      networkPerformanceData: {
        type: Array,
        required: true,
      },
      networkPerformanceFetching: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        fetching: true,
        options: merge({}, baseReportOptions, timeReportOptions, {
          series: this.networkPerformanceData,
          tooltip: {
            formatter() {
              let name
              switch (this.series.name) {
                case 'jitter':
                  name = i18n.t('average_jitter')
                  break
                case 'latency':
                  name = i18n.t('average_latency')
                  break
                case 'packetLoss':
                  name = i18n.t('average_packet_loss')
                  break
              }

              return '<strong>' + name + '</strong>: ' + this.y.toFixed(2) + 'ms'
            },
          },
        }),
      }
    },
    watch: {
      // update the series in the chart when network performance data changes
      networkPerformanceData: {
        deep: true,
        handler(newNetworkPerformanceData) {
          this.options.series = newNetworkPerformanceData
        },
      },
    },
  }
</script>
