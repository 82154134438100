import api from '@/plugins/ut/ut-api'

const getDefaultState = () => {
  return {
    list: null,
    fetching: false,
  }
}

const getters = {
  hosts: state => state.list,
  fetching: state => state.fetching,
}

const mutations = {
  RESET: state => Object.assign(state, getDefaultState()),

  SET_HOSTS: (state, hosts) => (state.list = hosts),
  SET_FETCHING: (state, value) => (state.fetching = value),
  REMOVE_HOSTS: (state, appliance) => {
    if (state.list === null) return
    const filteredHostsList = state.list.filter(item => {
      return item.UID !== appliance.Uid ? item : ''
    })
    state.list = filteredHostsList
  },
}

const actions = {
  async fetchHosts({ state, commit }, options) {
    if (state.list !== null && !state.fetching && !options?.force) {
      return
    }

    commit('SET_FETCHING', true)

    const response = await api.cloud('Untangle_CommandCenter', 'GetHosts', {
      allHosts: true,
      paramOrder: 'allHosts',
    })

    if (response.success && response.data) {
      commit('SET_HOSTS', response.data)
    }
    commit('SET_FETCHING', false)
  },
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions,
}
