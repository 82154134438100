<template>
  <u-page :title="$t('add_template')" full-height>
    <div v-show="step === 1" :class="step === 1 ? 'd-flex flex-column flex-grow-1' : ''">
      <v-card-title class="headline pa-0">
        {{ $t('select_template_appliance') }}
      </v-card-title>
      <v-card-subtitle class="ma-0 px-0">
        {{ $tc('step_n_of_3', 1) }}
      </v-card-subtitle>
      <v-card-subtitle class="px-0">
        {{ $t('select_master_appliance') }}
      </v-card-subtitle>
      <u-grid
        id="create-template-master-appliance"
        row-node-id="Uid"
        selection-type="singleAction"
        :no-data-message="$t('no_data')"
        :column-defs="columnDefs"
        :fetching="$store.state.appliances.fetching"
        :row-data="compatibleAppliances"
        :selection.sync="selectedMasterAppliance"
        @refresh="$store.dispatch('appliances/fetchAppliances', { force: true })"
      />
      <div class="d-flex flex-row justify-left">
        <v-card-actions class="px-0 mt-4">
          <v-spacer />
          <u-btn text :to="{ name: 'ngfw-policies-template-configuration' }">
            <span :class="`${$vuetify.theme.dark ? 'white--text' : ''}`">
              {{ $t('cancel') }}
            </span>
          </u-btn>
          <!-- disable advancing to next step if no master appliance selected -->
          <u-btn :disabled="selectedMasterAppliance.length === 0" @click="step = 2">
            {{ $t('next') }}
          </u-btn>
        </v-card-actions>
      </div>
    </div>
    <div v-show="step === 2" :class="step === 2 ? 'd-flex flex-column flex-grow-1' : ''">
      <v-card-title class="headline pa-0">
        {{ $t('select_appliances_to_sync') }}
      </v-card-title>
      <v-card-subtitle class="ma-0 px-0">
        {{ $tc('step_n_of_3', 2) }}
      </v-card-subtitle>
      <v-card-subtitle class="px-0">
        {{ $t('select_appliances_sync_instructions') }}
      </v-card-subtitle>
      <u-grid
        id="create-template-add-appliances"
        row-node-id="Uid"
        selection-type="multiAction"
        :no-data-message="$t('no_data')"
        :column-defs="columnDefs"
        :fetching="$store.state.appliances.fetching"
        :row-data="targetAppliances"
        :selection.sync="selectedTargetAppliances"
        @refresh="$store.dispatch('appliances/fetchAppliances', { force: true })"
      />
      <template-options :selected-values.sync="template" :sync-all-already-enabled.sync="syncAllAlreadyEnabled" />
      <div class="d-flex flex-row justify-left">
        <v-card-actions class="px-0 mt-4">
          <v-spacer />
          <u-btn @click="step = 1">
            {{ $t('back') }}
          </u-btn>
          <u-btn @click="step = 3">
            {{ $t('next') }}
          </u-btn>
        </v-card-actions>
      </div>
    </div>
    <div v-show="step === 3" :class="step === 3 ? 'd-flex flex-column flex-grow-1' : ''">
      <v-card-title class="headline pa-0">
        {{ $t('final_step') }}
      </v-card-title>
      <v-card-subtitle class="ma-0 px-0">
        {{ $tc('step_n_of_3', 3) }}
      </v-card-subtitle>
      <div>
        <p v-if="masterAppliance !== null">
          <b>{{ $t('from_template_master') }}</b>
          <br />
          {{ masterAppliance.Hostname }} ({{
            masterAppliance.Uid ? $options.util.obfuscateUid(masterAppliance.Uid) : $t('not_assigned')
          }}
          / {{ masterAppliance.ApplianceTag ? masterAppliance.ApplianceTag : $t('label_not_set') }})<br />
          {{ masterAppliance.SoftwareVersion }}
        </p>
        <div>
          <b>{{ $t('to_target_appliances') }}</b>
        </div>
        <div v-if="selectedTargetAppliances.length === 0">
          {{ $t('no_appliances') }}
        </div>
        <div v-for="targetAppliance in selectedTargetAppliances" v-else :key="targetAppliance.Uid">
          {{ targetAppliance.Hostname }}
          ({{ targetAppliance.Uid ? $options.util.obfuscateUid(targetAppliance.Uid) : $t('not_assigned') }} /
          {{ targetAppliance.ApplianceTag ? targetAppliance.ApplianceTag : $t('label_not_assigned') }})
        </div>
      </div>
      <p class="mt-4">
        {{ $t('create_template_instructions') }}
      </p>
      <div class="d-flex flex-row justify-left">
        <v-card-actions class="px-0">
          <v-spacer />
          <u-btn @click="step = 2">
            {{ $t('back') }}
          </u-btn>
          <u-btn @click="createTemplate">
            {{ $t('create_template') }}
          </u-btn>
        </v-card-actions>
      </div>
    </div>
  </u-page>
</template>
<script>
  import vuntangle from '@/plugins/vuntangle'
  import util from '@/plugins/ut/ut-util'
  import TemplateOptions from '@/components/policies/TemplateOptions'
  import TemplateMixin from '@/components/policies/TemplateMixin'

  export default {
    components: { TemplateOptions },
    mixins: [TemplateMixin],
    util,
    data() {
      return {
        step: 1,
        selectedMasterAppliance: [],
      }
    },
    computed: {
      /**
       * Get the master appliance from the row selected.
       *
       * @return {Object|null}
       */
      masterAppliance() {
        if (this.selectedMasterAppliance.length === 0) {
          return null
        }

        const [selectedMasterAppliance] = this.selectedMasterAppliance

        return selectedMasterAppliance
      },
    },
    methods: {
      /**
       * Create a new template.  This will reload the template store on
       * success.
       *
       * @return {void}
       */
      async createTemplate() {
        this.$store.commit('SET_PAGE_LOADER', true)
        // get selected target appliances
        const targetApplianceUids = this.selectedTargetAppliances.map(appliance => appliance.Uid)
        // dispatch a 'create template' action
        const response = await this.$store.dispatch('policies/createTemplate', {
          masterUid: this.masterAppliance?.Uid,
          targetApplianceUids,
          templateOptions: this.template,
        })
        if (response.success && response.data) {
          // reload the template store, the endpoint does not return the new template
          await this.$store.dispatch('policies/fetchTemplates', { force: true })
          this.$store.commit('SET_PAGE_LOADER', false)
          vuntangle.toast.add(this.$t('policy_create_success'))
          this.$router.push({ name: 'ngfw-policies-template-configuration' })
        } else {
          // check for an error message
          let errorMessage = response.message
          if (!errorMessage || !this.$te(errorMessage)) {
            errorMessage = 'policy_create_failure'
          }

          this.$store.commit('SET_PAGE_LOADER', false)
          vuntangle.toast.add(this.$t(errorMessage), 'error')
        }
      },
    },
  }
</script>
