<template>
  <appliance-clients
    v-if="boxSettings"
    :devices="devices"
    :fetching="fetching"
    :show-grid="showGrid"
    :box-settings="boxSettings"
    class="flex-grow-1"
    @refresh="fetchDevices"
  />
</template>
<script>
  import { ApplianceClients } from 'vuntangle'
  import settingsMixin from './settingsMixin'

  export default {
    components: { ApplianceClients },
    mixins: [settingsMixin],
    data() {
      return {
        devices: [],
        fetching: false,
        showGrid: undefined,
      }
    },

    computed: {
      boxSettings: ({ $store }) => $store.getters['appliances/getApplianceSettings'],
    },

    mounted() {
      this.computeShowGrid()
      this.fetchDevices()
    },

    methods: {
      async fetchDevices() {
        this.fetching = true
        const response = await this.getFromAppliance('status/devices')
        this.fetching = false

        if (response.success && response.data) {
          this.devices = response?.data?.result || []
        } else {
          this.$vuntangle.toast.add(this.$t('an_error_occurred'), 'error')
        }
      },

      // grid should be shown if current account does not require a license
      // or appliance has an active security licence
      computeShowGrid() {
        // if the account is not license enforced, security license is not required
        if (this.$store.state.data.ccViewModel.Account.NoLicenseEnforcement) {
          this.showGrid = true
          return
        }
        this.showGrid = false
        this.appliance.Subscriptions.forEach(subscription => {
          const sub = this.$store.state.subscriptions.list.find(
            sub => sub.subscriptionName === subscription.subscriptionName,
          )
          if (sub.sku.includes('SWSE')) {
            this.showGrid = true
          }
        })

        return this.showGrid
      },
    },
  }
</script>
