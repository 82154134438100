<template>
  <u-widget
    :title="$t('general_information')"
    style="overflow: hidden"
    :enable-refresh="false"
    :enable-expand="false"
    :fetching="$store.state.appliances.fetching"
    v-on="$listeners"
  >
    <div class="pa-2">
      <div class="text-center">
        <strong>{{ $t('logged_in_as') }}</strong> {{ accountStatus.user }}
      </div>
      <div v-if="userExtraInfo" class="text-center">
        <strong>{{ $t('account_owner') }}</strong> {{ userExtraInfo.owner }}
      </div>
      <div v-if="appliancesCount.total === 0" class="red ma-2 white--text">
        <div class="text-center py-1">
          {{ $t('widgets_no_appliances') }}
        </div>
      </div>
      <div v-else-if="appliancesCount.offline === 0" class="primary ma-2 white--text">
        <div class="text-center py-1">
          {{ $t('all_online') }}
        </div>
      </div>
      <div v-else-if="appliancesCount.online === 0" class="red ma-2 white--text">
        <div class="text-center py-1">
          {{ $t('all_offline') }}
        </div>
      </div>
      <div v-else>
        <div class="text-center ma-2 white--text">
          <v-row no-gutters>
            <v-col class="text-uppercase py-1 primary">
              {{ appliancesCount.online }} {{ $t('appliances') }} {{ $t('online') }}
            </v-col>
            <v-col class="text-uppercase py-1 red">
              {{ appliancesCount.offline }} {{ $t('appliances') }} {{ $t('offline') }}
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="text-center">
        {{ $t('firewalls_managed') }} <b>{{ appliancesCount.total }}</b>
      </div>
      <div class="text-center">
        {{ $t('online_firewalls') }} <b>{{ appliancesCount.online }}</b>
      </div>
      <div class="text-center">
        {{ $t('total_number_of_policies') }} <b>{{ policies.length }}</b>
      </div>
      <div class="text-center">
        {{ $t('logged_in_from') }} <b>{{ accountStatus.loggedInFrom }}</b>
      </div>
      <div class="text-center">
        {{ $t('location') }}
        <b>{{ $options.util.formatGeoLocation(accountStatus.loggedInFromLocation) }}</b>
      </div>
    </div>
  </u-widget>
</template>
<script>
  import WidgetMixin from '@/components/widgets/WidgetMixin'
  import util from '@/plugins/ut/ut-util'

  export default {
    mixins: [WidgetMixin],
    util,
    props: {
      appliances: {
        type: Array,
        default() {
          return []
        },
      },
      policies: {
        type: Array,
        default() {
          return []
        },
      },
      accountStatus: {
        type: Object,
        required: true,
      },
    },
    computed: {
      userExtraInfo: ({ $store }) => {
        if ($store.state.data.ccViewModel.User?.UserAccounts?.length > 1) {
          return {
            role: $store.state.data.ccViewModel.SelectedUserAccountSettings.Role,
            owner: $store.state.data.ccViewModel.SelectedUserAccountSettings.AccountDisplayName.split('[')[0],
          }
        }
        return null
      },
      appliancesCount() {
        const total = this.appliances?.length
        const online = this.appliances?.filter(appl => appl.uptime > 0).length
        return {
          total,
          online,
          offline: total - online,
        }
      },
    },
  }
</script>
