<template>
  <u-page :title="$t('alerts_received')" full-height>
    <template #actions>
      <u-btn v-if="$options.users.hasFullAccountAccess()" :disabled="selectedRows.length !== 1" @click="addAlertRule">
        {{ $t('add_alert_rule') }}
      </u-btn>
    </template>
    <u-grid
      id="alerts-received"
      selection-type="singleAction"
      :no-data-message="$t('no_data')"
      :column-defs="columnDefs"
      :fetching="$store.state.appliances.fetching || $store.state.events.accountEventsFetching"
      :row-data="rowData"
      :selection.sync="selectedRows"
      @refresh="loadAlertsReceived(true)"
    />
    <u-dialog
      :show-dialog="!!filteredAlerts.length"
      :title="$t('additional_information')"
      :width="600"
      :buttons="[
        {
          'name': $t('ok'),
          'handler': 'close-dialog',
        },
      ]"
      @close-dialog="onCloseDialog"
    >
      <alert-details :filtered-alerts="filteredAlerts" :alert-index.sync="alertIndex" />
    </u-dialog>
  </u-page>
</template>
<script>
  import AlertDetails from '@/pages/alerts/alert-details'
  import users from '@/plugins/ut/ut-users'
  import grids from '@/plugins/ut/ut-grids'

  export default {
    components: { AlertDetails },
    users,
    data() {
      return {
        selectedRows: [],
        // list of records passed to detail dialog to navigate through
        filteredAlerts: [],
        // index of the record being viewed from filteredAlerts
        alertIndex: -1,
      }
    },
    computed: {
      columnDefs() {
        const customColumns = [
          {
            headerName: this.$t('view_details'),
            field: 'ViewDetails',
            sortable: false,
            filter: false,
            cellRenderer: 'ActionButton',
            cellRendererParams: {
              icon: 'mdi-information',
              color: 'primary',
              xSmall: true,
              class: 'ml-5',
              click: params => {
                this.viewAlertDetails(params)
              },
            },
          },
        ]

        const alertGridColumn = [...grids.getAlertColumnDefs(), ...customColumns]

        return grids.mergeWithApplianceColumns(alertGridColumn, ['appliance', 'tag'])
      },
      /**
       * Make sure alerts received is only returned once appliances has loaded.
       *
       * @return {Array}
       */
      alertsReceived() {
        return this.$store.state.appliances.list && this.$store.state.events.accountEvents
          ? this.$store.state.events.accountEvents
          : []
      },
      /**
       * Merge alerts data with appliances data
       */
      rowData: ({ $store, alertsReceived }) =>
        $store.getters['appliances/populateApplianceInfoByUid'](alertsReceived, 'DeviceId'),
    },
    created() {
      this.$store.commit('SET_ALERTS_SELECTION', this.$route.path)
      this.loadAlertsReceived()
    },
    methods: {
      loadAlertsReceived(force = false) {
        this.$store.dispatch('appliances/fetchAppliances')
        this.$store.dispatch('events/fetchAccountEvents', { force })
      },
      addAlertRule() {
        // pass the alert message to rule creation page
        this.$router.push({
          name: 'alerts-rules-id',
          params: {
            id: 'new', // 'new' is used as id when creating a rule
            eventMessage: this.selectedRows[0].Message,
          },
        })
      },

      onCloseDialog() {
        this.filteredAlerts = []
        this.alertIndex = -1
      },

      /**
       * builds the props needed for alert details dialog and displays the dialog
       * @param {Object} params ag grid row click params
       */
      viewAlertDetails(params) {
        params.api.forEachNodeAfterFilterAndSort(node => {
          this.filteredAlerts.push(node.data)
        })
        this.alertIndex = params.node.rowIndex
      },
    },
  }
</script>
