<template>
  <div class="d-flex flex-column flex-grow-1 flex-basis-0 overflow-auto">
    <!-- sapphire toolbar with back to policies button -->
    <v-toolbar v-if="$isSapphire" dense dark flat class="flex-grow-0" color="primary white--text px-0">
      <v-btn small text @click="$router.push({ name: 'me-policies' })">
        <v-icon>mdi-chevron-left</v-icon>
        {{ $t('back_to_policies') }}
      </v-btn>
      <v-divider vertical class="mx-4" />

      <v-toolbar-title>{{ $t('alerts') }}</v-toolbar-title>
    </v-toolbar>

    <div class="d-flex flex-grow-1 flex-basis-0 overflow-auto">
      <template v-if="$isSapphire">
        <sapphire-alerts-drawer class="ma-2" />
        <v-divider vertical />
      </template>

      <router-view class="d-flex flex-column flex-grow-1 flex-basis-0 overflow-auto" />
    </div>
  </div>
</template>
<script>
  import SapphireAlertsDrawer from '@/layouts/drawers/SapphireAlertsDrawer.vue'

  export default {
    components: { SapphireAlertsDrawer },
    /**
     * Store alerts page
     *
     * @returns {void}
     */
    created() {
      this.$store.commit('SET_ALERTS_SELECTION', this.$route.path)
    },
  }
</script>
