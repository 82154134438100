<template>
  <u-dialog-complex
    v-model="show"
    :width="600"
    :title="$t('edit_location')"
    :show-progress="progress"
    :submit-label="$t('save')"
    @cancel="onClose"
    @submit="onSubmit"
  >
    <ValidationObserver ref="obs">
      <p class="body-1 mt-4">{{ $t('edit_location_text') }}</p>
      <!-- uid -->
      <ValidationProvider v-slot="{ errors }" rules="required">
        <u-text-field v-model="location" :label="$t('location')" class="my-4" :error-messages="errors">
          <template v-if="errors.length" #append>
            <u-errors-tooltip :errors="errors" />
          </template>
        </u-text-field>
      </ValidationProvider>
    </ValidationObserver>
  </u-dialog-complex>
</template>

<script>
  import vuntangle from '@/plugins/vuntangle'

  export default {
    props: {
      // boolean to show/hide dialog
      value: {
        type: Boolean,
        default: false,
      },
      appliance: {
        type: Object,
        default() {
          return {}
        },
      },
    },
    data() {
      return {
        location: null,
        progress: false,
      }
    },
    computed: {
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
    },
    watch: {
      show(val) {
        if (val) {
          this.location = this.appliance.ApplianceLocation
        } else {
          this.onClose()
        }
      },
    },
    methods: {
      onClose() {
        this.$refs.obs.reset()
        this.location = null
        this.show = false
      },
      async onSubmit() {
        const valid = await this.$refs.obs.validate()
        if (!valid) {
          return
        }
        this.progress = true
        const response = await this.$store.dispatch('appliances/updateLocation', {
          uid: this.appliance.Uid,
          newLocation: this.location,
          paramOrder: 'uid newLocation',
        })
        if (response) {
          vuntangle.toast.add(this.$t('update_appliances_location_success'))
        } else {
          vuntangle.toast.add(this.$t('update_appliances_location_failed'), 'error')
        }
        this.progress = false
        this.show = false
      },
    },
  }
</script>
