import api from '@/plugins/ut/ut-api'

const getDefaultState = () => {
  return {
    notificationLog: null,
    accountEvents: null,
    auditHistory: null,
    notificationLogFetching: false,
    accountEventsFetching: false,
    auditHistoryFetching: false,
  }
}

const getters = {
  notificationLog: state => state.notificationLog,
  accountEvents: state => state.accountEvents,
  auditHistory: state => state.auditHistory,
  notificationLogFetching: state => state.notificationLogFetching,
  accountEventsFetching: state => state.accountEventsFetching,
  auditHistoryFetching: state => state.auditHistoryFetching,
}

const mutations = {
  RESET: state => Object.assign(state, getDefaultState()),

  SET_NOTIFICATION_LOG: (state, value) => (state.notificationLog = value),
  SET_ACCOUNT_EVENTS: (state, value) => (state.accountEvents = value),
  SET_AUDIT_HISTORY: (state, value) => (state.auditHistory = value),
  SET_NOTIFICATION_LOG_FETCHING: (state, value) => (state.notificationLogFetching = value),
  SET_ACCOUNT_EVENTS_FETCHING: (state, value) => (state.accountEventsFetching = value),
  SET_AUDIT_HISTORY_FETCHING: (state, value) => (state.auditHistoryFetching = value),
}

const actions = {
  async fetchNotificationLog({ state, commit }, options) {
    if (state.notificationLog !== null && !state.notificationLogFetching && !options?.force) {
      return
    }

    commit('SET_NOTIFICATION_LOG_FETCHING', true)

    const response = await api.cloud('Untangle_CommandCenter', 'GetAccountEvents', {
      type: 'notification_log',
      count: 0,
      startDate: '2020-05-24', // TODO use a global method to get the date
      excludePayload: 0,
      paramOrder: 'type payload count startDate excludePayload',
    })
    if (response.success && response.data) {
      commit('SET_NOTIFICATION_LOG', response.data)
    } else {
      commit('SET_NOTIFICATION_LOG', [])
    }

    commit('SET_NOTIFICATION_LOG_FETCHING', false)
  },

  async fetchAccountEvents({ state, commit }, options) {
    if (state.accountEventsFetching || (state.accountEvents !== null && !options?.force)) {
      return
    }

    commit('SET_ACCOUNT_EVENTS_FETCHING', true)

    const response = await api.cloud('Untangle_CommandCenter', 'GetAccountEvents', {
      type: 'alert',
      paramOrder: 'type',
    })

    if (response.success && response.data) {
      commit('SET_ACCOUNT_EVENTS', response.data)
    } else {
      commit('SET_ACCOUNT_EVENTS', [])
    }

    commit('SET_ACCOUNT_EVENTS_FETCHING', false)
  },

  async fetchAccountEventDetails({}, { id }) {
    const response = await api.cloud('Untangle_CommandCenter', 'GetAccountEventDetails', {
      eventId: id,
      paramOrder: 'eventId',
    })

    if (response.success && response.data) {
      return { DateCreated: response.data.DateCreated.$date, ...response.data.Payload }
    }

    return null
  },

  async fetchAuditHistory({ state, commit }, options) {
    if (state.auditHistoryFetching || (state.auditHistory !== null && !options?.force)) {
      return
    }

    commit('SET_AUDIT_HISTORY_FETCHING', true)

    const response = await api.cloud('Untangle_CommandCenter', 'GetAccountEvents', {
      type: 'audit',
      paramOrder: 'type',
    })
    if (response.success && response.data) {
      commit('SET_AUDIT_HISTORY', response.data)
    } else {
      commit('SET_AUDIT_HISTORY', [])
    }

    commit('SET_AUDIT_HISTORY_FETCHING', false)
  },
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions,
}
