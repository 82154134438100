<!-- Shows breadcrumbs when inside an edit route e.g. rule, condition, object or configuration -->
<template>
  <div class="d-flex">
    <div class="d-flex flex-column flex-grow-1">
      <!-- This is a headline above the actual breadcrumbs, e.g. `Rules`, `Conditions` etc...-->
      <h1 class="headline mb-2">{{ headline }}</h1>
      <v-breadcrumbs class="pa-0 mb-4 text-uppercase" :items="breadcrumbItems">
        <template #item="{ item }">
          <v-breadcrumbs-item
            :disabled="item.disabled"
            exact
            class="text-caption font-weight-bold"
            @click="$router.push(item.to)"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
        <template #divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <slot name="actions" />
  </div>
</template>
<script>
  import { rulesConfig, objectsConfig, templatesConfig } from 'vuntangle/pm'

  import editorMixin from './editorMixin'
  import i18n from '@/plugins/vue-i18n'

  export default {
    mixins: [editorMixin],

    computed: {
      ruleConfig: ({ ruleType, editRule }) => rulesConfig[ruleType || editRule?.Type],
      objectConfig: ({ editObject }) => objectsConfig[editObject?.Type],
      templateConfig: ({ editConfiguration }) => templatesConfig[editConfiguration?.Type],

      allConditions: ({ $route }) => $route.name.includes('all-conditions'),
      allRules: ({ $route }) => $route.name.includes('all-rules'),

      /**
       * computed headline based on route params
       */
      headline: ({
        policyId,
        ruleId,
        ruleType,
        conditionId,
        configurationId,
        objectConfig,
        ruleConfig,
        allConditions,
      }) => {
        if (ruleType) return i18n.t(ruleConfig?.text)
        if (ruleId) return policyId ? i18n.t(ruleConfig?.text) : i18n.t('rules')
        if (conditionId) return i18n.t('conditions')
        if (objectConfig) return !policyId || !ruleId ? i18n.t(objectConfig?.text) : i18n.t('conditions')
        if (configurationId) return i18n.t('templates')
        if (allConditions) return i18n.t('conditions')
      },

      /**
       * computed breadcrumbs based on route params and configurations
       */
      breadcrumbItems: ({
        policyId,
        ruleId,
        ruleType,
        conditionId,
        objectId,
        configurationId,
        ruleConfig,
        objectConfig,
        templateConfig,
        routeNamePrefix,

        allConditions,
        allRules,
      }) => {
        const items = []

        /**
         * show `Rules` when editing a rule
         * routes to policy type rules or main Rules view
         */
        if (ruleId || ruleType) {
          items.push({
            text: i18n.t('rules'),
            to: { name: policyId ? 'pm-policy-rules' : 'pm-rules' },
          })
          if (allRules) {
            items.push({
              text: i18n.t('select_rules'),
            })
          }
        }

        /**
         * shows `Configurations` when editing a configuration down from templates view
         */
        if (templateConfig && !policyId && !ruleId) {
          items.push({ text: i18n.t('configurations'), to: { name: 'pm-templates' } })
        }

        /**
         * shows `[Create/Edit] Rule (<rule type>)` when editing a rule
         */
        if (ruleId) {
          items.push({
            text: `${ruleId.startsWith('mfw-') ? i18n.t('create_rule') : i18n.t('edit_rule')}
             ${!policyId ? ' (' + i18n.t(ruleConfig?.text) + ')' : ''}`,
            to: { name: `${routeNamePrefix}`, params: { ruleId } },
            disabled: !conditionId && !configurationId && !objectId && !allConditions,
          })
        }

        if (conditionId) {
          /**
           * shows `[Create/Edit] Condition` when editing condition within policy or rule
           */
          items.push({
            text: conditionId.startsWith('mfw-') ? i18n.t('create_condition') : i18n.t('edit_condition'),
            to: { name: `${routeNamePrefix}-condition`, params: { conditionId } },
            disabled: !objectId,
          })
        }

        /**
         * shows `[Create/Edit] [Object/Group] (<object/group type>)`
         */
        if (objectId) {
          const isGroup = !!objectConfig?.objectType
          let editText
          if (objectId.startsWith('mfw-')) {
            editText = isGroup ? i18n.t('create_group') : i18n.t('create_object')
          } else {
            editText = isGroup ? i18n.t('edit_group') : i18n.t('edit_object')
          }

          items.push({
            text: `${editText} ${policyId || ruleId || conditionId ? ' (' + i18n.t(objectConfig?.text) + ')' : ''}`,
            disabled: true,
          })
        }

        /**
         * shows `[Create/Edit] Configuration (<template type>)`
         */
        if (configurationId) {
          items.push({
            text: `${configurationId.startsWith('mfw-') ? i18n.t('create_configuration') : i18n.t('edit_configuration')}
            ${!ruleId ? ' (' + i18n.t(templateConfig?.text) + ')' : ''}`,
            disabled: true,
          })
        }

        if (allConditions) {
          items.push({
            text: i18n.t('select_conditions'),
            disabled: true,
          })
        }

        return items
      },
    },
  }
</script>
