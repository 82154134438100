<template>
  <u-widget :title="$t('resources')" :enable-expand="false" v-on="$listeners">
    <div class="d-flex flex-row justify-space-around ma-8">
      <div v-for="(res, key) in resources" :key="key" class="text-center">
        {{ $t(res.name) }} <br />
        <strong>{{ res.total || $t('not_applicable') }}</strong>
        <br />
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="10"
          :value="online && res.percent ? res.percent : 0"
          :class="`mt-4 ${$store.state.isDark ? 'ut-dark' : 'ut-light'}`"
          :color="computeColor(res.percent)"
        >
          <span
            :class="`text-center text-h5 font-weight-light ${$store.state.isDark ? 'white--text' : 'black--text'}`"
            v-text="
              online
                ? key !== 'cpu'
                  ? res.percent && res.percent >= 0
                    ? res.percent + '%'
                    : $t('not_applicable')
                  : res.total
                : $t('not_applicable')
            "
          />
        </v-progress-circular>
      </div>
    </div>
  </u-widget>
</template>
<script>
  import util from '@/plugins/ut/ut-util'

  export default {
    props: {
      appliance: {
        type: Object,
        required: true,
      },
    },
    computed: {
      online() {
        return this.appliance.StatusFlags[0]?.Messages[0] === 'appliance_status_online'
      },
      resources() {
        const appl = this.appliance
        return {
          memory: {
            name: 'memory',
            total: this.computeTotal(appl.memTotal),
            percent: this.computePercent(appl.memTotal, appl.memFree),
          },
          swap: {
            name: 'swap',
            total: this.computeTotal(appl.swapTotal),
            percent: this.computePercent(appl.swapTotal, appl.swapFree),
          },
          disk: {
            name: 'disk',
            total: this.computeTotal(appl.diskTotal),
            percent: this.computePercent(appl.diskTotal, appl.diskFree),
          },
          cpu: {
            name: 'cpu_load',
            total: this.appliance.load15?.toFixed(2),
            percent: this.computeCpuPercent(),
          },
        }
      },
    },
    methods: {
      computeTotal: total => (total > 0 ? util.formatBytes(total, 2, true) : null),
      computePercent(total, free) {
        return total > 0 && free > 0 ? 100 - Math.round((free * 100) / total) : null
      },
      computeCpuPercent() {
        if (this.appliance.CoreCount > 0 && this.appliance.load15 >= 0) {
          const cpuFactor = this.appliance.CoreCount + 2
          return ((this.appliance.load15 * 100) / cpuFactor).toFixed(2)
        }
        return null
      },
      computeColor(percent) {
        if (percent && percent > 50) return 'orange'
        if (percent && percent > 90) return 'red'
        return 'aristaMediumBlue'
      },
    },
  }
</script>
<style>
  .ut-dark .v-progress-circular__underlay {
    stroke: rgba(255, 255, 255, 0.2);
  }
</style>
