<template>
  <u-widget :title="$t('network_map')" v-on="$listeners">
    <u-gmap :markers="applianceMarkers" :map-lines="getMapLines" style="width: 100%; height: 100%" />
  </u-widget>
</template>
<script>
  export default {
    props: {
      applianceMarkers: {
        type: Array,
        default: null,
      },
      mapLines: {
        type: Array,
        default: null,
      },
    },
    computed: {
      /**
       * Returns the map lines if the VPN config tab is enabled, null otherwise
       */
      getMapLines() {
        return this.$store.state.data.ccViewModel.IsVpnTabVisible ? this.mapLines : null
      },
    },
  }
</script>
