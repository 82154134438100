// import styles
import './scss/sapphire.scss'

import store from './store'
import router from './router/sapphire'

// run axios interceptors for the app
import './plugins/sapphire-axios-interceptors'

import loadVue from './load-vue'
import Sapphire from './layouts/sapphire'
import { initFullStory } from '@/plugins/fullstory'

// init fullstory
initFullStory(true)

// if this is used in an iframe, tell the iframe parent the application was loaded
parent.postMessage(JSON.stringify({ loaded: true }), '*')

loadVue(h => h(Sapphire), store, 'initSapphireApp', router, true)
