var render = function render(){var _vm=this,_c=_vm._self._c;return _c('u-widget',_vm._g({attrs:{"title":_vm.$t('cloud_backups'),"info":_vm.$t('info_cloud_backups'),"enable-refresh":""},on:{"refresh":_vm.fetchCloudBackups}},_vm.$listeners),[(_vm.backups.length)?_c('u-grid',{attrs:{"id":"cloud-backups","selection-type":"singleAction","no-data-message":_vm.$t('no_data'),"column-defs":_vm.columnDefs,"fetching":_vm.backupsFetching,"row-data":_vm.backups,"selection":_vm.backupRowsSelected,"resize-columns":_vm.resizeEvent},on:{"update:selection":function($event){_vm.backupRowsSelected=$event},"update:resizeColumns":function($event){_vm.resizeEvent=$event},"update:resize-columns":function($event){_vm.resizeEvent=$event},"refresh":_vm.fetchCloudBackups}}):_vm._e(),(_vm.backups.length === 0)?[_c('u-alert',[(_vm.appliance.ProductLine === 'NGFW')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('no_backups_available'))}}):_vm._e(),(_vm.appliance.ProductLine === 'MFW')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('no_mfw_backups_available'))}}):_vm._e()]),_c('v-spacer')]:_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('u-btn',{on:{"click":function($event){_vm.showCreateBackupDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('create_backup'))+" ")]),_c('u-btn',{attrs:{"disabled":_vm.backupRowsSelected.length !== 1},on:{"click":function($event){_vm.showRestoreBackupDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('restore_backup'))+" ")])],1),_c('u-dialog',{attrs:{"show-dialog":_vm.showCreateBackupDialog,"title":_vm.$t('create_backup'),"message":_vm.$t('cloud_backups_create_confirmation'),"buttons":[
      { name: _vm.$t('cancel') },
      {
        name: _vm.$t('yes'),
        handler: 'createBackup',
        showProgress: true,
      },
    ]},on:{"close-dialog":function($event){_vm.showCreateBackupDialog = false},"createBackup":_vm.createBackup}}),_c('u-dialog',{attrs:{"show-dialog":_vm.showRestoreBackupDialog,"title":_vm.$t('cloud_backups_confirmation_title'),"buttons":[
      { name: _vm.$t('no') },
      {
        name: _vm.$t('yes'),
        handler: 'restoreBackup',
        showProgress: true,
      },
    ]},on:{"close-dialog":function($event){_vm.showRestoreBackupDialog = false},"restoreBackup":_vm.restoreBackup}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('cloud_backups_confirmation'))}})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }