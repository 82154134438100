<template>
  <v-carousel height="100%" hide-delimiter-background show-arrows-on-hover cycle>
    <!-- Untangle GO -->
    <v-carousel-item height="100%" class="mk-slide" :style="{ backgroundImage: `url(${backgrounds.untangle_go})` }">
      <div class="mk-content">
        <v-img :src="require('@/static/marketing/icons/untangle-go-icon.svg')" width="80" style="margin: 16px auto">
        </v-img>
        <h1 class="mk-title">{{ $t('untangle_go') }}</h1>
        <h2 class="mk-description">
          {{ $t('untangle_go_text') }}
        </h2>
        <v-btn
          light
          rounded
          :href="`${$store.state.data.ccViewModel.StoreUrl}cloud/etm-dashboard/mobile-app/`"
          target="_blank"
          class="mx-2"
        >
          {{ $t('learn_more') }}
        </v-btn>
      </div>
    </v-carousel-item>

    <!-- Inside Untangle Blog -->
    <v-carousel-item
      height="100%"
      class="mk-slide"
      :style="{ backgroundImage: `url(${backgrounds.inside_untangle_blog})` }"
    >
      <div class="mk-content">
        <v-img
          :src="require('@/static/marketing/icons/inside-untangle-blog-icon.svg')"
          width="80"
          style="margin: 16px auto"
        >
        </v-img>
        <h1 class="mk-title">{{ $t('inside_untangle_blog') }}</h1>
        <h2 class="mk-description">
          {{ $t('inside_untangle_blog_text') }}
        </h2>
        <v-btn
          light
          rounded
          :href="`${$store.state.data.ccViewModel.StoreUrl}category/blog/`"
          target="_blank"
          class="mx-2"
        >
          {{ $t('learn_more') }}
        </v-btn>
      </div>
    </v-carousel-item>

    <!-- Untangle Support -->
    <v-carousel-item
      height="100%"
      class="mk-slide"
      :style="{ backgroundImage: `url(${backgrounds.untangle_support})` }"
    >
      <div class="mk-content">
        <v-img :src="require('@/static/marketing/icons/support-icon.svg')" width="80" style="margin: 16px auto">
        </v-img>
        <h1 class="mk-title">{{ $t('untangle_support') }}</h1>
        <h2 class="mk-description">{{ $t('untangle_support_text') }}</h2>
        <v-btn light rounded href="https://support.edge.arista.com/hc/en-us" target="_blank">
          {{ $t('get_support') }}
        </v-btn>
      </div>
    </v-carousel-item>

    <!-- PARTNER PROGRAM -->
    <v-carousel-item height="100%" class="mk-slide" :style="{ backgroundImage: `url(${backgrounds.partner})` }">
      <div class="mk-content">
        <v-img :src="require('@/static/marketing/icons/partner-icon.svg')" width="80" style="margin: 16px auto">
        </v-img>
        <h1 class="mk-title">{{ $t('partner_program') }}</h1>
        <h2 class="mk-description">
          {{ $t('partner_program_text') }}
        </h2>
        <v-btn light rounded :href="`${$store.state.data.ccViewModel.StoreUrl}partners/`" target="_blank">
          {{ $t('learn_more') }}
        </v-btn>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  export default {
    data() {
      return {
        /**
         * because how webpack references the assets paths
         * will use below data to set slides backgrounds
         */
        backgrounds: {
          untangle_go: require('@/static/marketing/cmd-login-blue-bg/untangle-go-background.jpg'),
          inside_untangle_blog: require('@/static/marketing/cmd-login-blue-bg/blog-background.jpg'),
          untangle_support: require('@/static/marketing/cmd-login-blue-bg/support-background.jpg'),
          partner: require('@/static/marketing/cmd-login-blue-bg/partners-background.jpg'),
        },
      }
    },
  }
</script>

<style>
  .mk-slide {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 0;
  }

  .mk-content {
    width: 50%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    line-height: 1.2;
  }

  .mk-title {
    font-size: 24px;
    font-weight: normal;
    text-shadow: -2px 2px 0 rgba(0, 0, 0, 0.25);
  }
  .mk-description {
    font-size: 44px;
    font-weight: bold;
    margin: 36px 0 48px 0;
    text-shadow: -2px 2px 0 rgba(0, 0, 0, 0.25);
  }
  .mk-text {
    font-size: 36px;
    font-weight: bold;
    text-shadow: -2px 2px 0 rgba(0, 0, 0, 0.25);
  }
</style>
