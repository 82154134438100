<!-- display list of policies for the selected feature passed in the route -->
<template>
  <u-page :title="$t(title)" full-height>
    <div v-if="wanPolicy" class="mb-2">
      <span v-html="$t('wan_policies_sync_info_start')" />
      <router-link :to="{ name: 'mfw-policies-types', params: { policyPage: 'wan-rules' } }" v-html="$t('wan_rules')" />
      <span v-html="$t('wan_policies_sync_info_end')" />
    </div>
    <template #actions>
      <u-btn :to="`${$route.path}/add`">
        {{ $t('create') }}
      </u-btn>
      <u-btn
        :disabled="existingPoliciesSelectedRows.length !== 1"
        :to="
          existingPoliciesSelectedRows.length !== 1
            ? ''
            : `${$route.path}/${existingPoliciesSelectedRows[0] && existingPoliciesSelectedRows[0].Id}`
        "
      >
        {{ $t('edit') }}
      </u-btn>
      <u-btn :disabled="!existingPoliciesSelectedRows.length" @click="displayDeletePolicyDialog">
        {{ $t('delete') }}
      </u-btn>
      <u-btn v-if="showSync" :disabled="!shouldShowSyncNowButton" @click="syncNow">
        {{ $t('sync_now') }}
      </u-btn>
      <u-btn v-if="canImport" @click="showImportDialog = true">
        {{ $t('import') }}
      </u-btn>
    </template>
    <v-alert v-if="licenseRequired" text color="primary">
      <div class="d-flex align-center">
        <div class="d-flex align-center">
          <v-icon color="primary" class="mr-4">mdi-license</v-icon>
          {{ $t('requires_security_license', [$t(title)]) }}
        </div>
        <div class="d-flex ml-auto">
          <u-btn class="ml-4" :to="{ name: 'account-subscriptions' }">{{ $t('manage_subscriptions') }}</u-btn>
          <u-btn
            class="ml-4"
            :href="`${$store.state.data.ccViewModel.StoreUrl}configurator/?sku=SR-11-SWSE-0100-1YEAR`"
            target="_blank"
          >
            {{ $t('buy_subscriptions') }}
            <v-icon right> mdi-open-in-new </v-icon>
          </u-btn>
        </div>
      </div>
    </v-alert>
    <u-grid
      :id="`mfw-policies-grid-${title}`"
      selection-type="multiAction"
      :no-data-message="$t('no_data')"
      :column-defs="basePoliciesColumnDefs"
      :fetching="pendingAction"
      :row-data="existingPolicies"
      :selection.sync="existingPoliciesSelectedRows"
      @refresh="fetchExistingPolicies"
    />
    <div>
      <u-dialog
        :show-dialog="deletePolicyDialog"
        :title="$t('delete_policy')"
        :message="!rulesToDelete.length ? deletePolicyMessage : ''"
        :buttons="[
          {
            name: $t('cancel'),
          },
          {
            name: $t('yes'),
            handler: 'delete-policy',
            showProgress: true,
          },
        ]"
        @close-dialog="deletePolicyDialog = false"
        @delete-policy="deletePolicy"
      >
        <div v-if="rulesToDelete.length">
          <ul>
            <li v-for="(rule, index) in rulesToDelete" :key="`rule-to-delete${index}`">
              {{ rule.Name }}
            </li>
          </ul>
          <p>{{ $t('confirm_delete_policy') }}</p>
        </div>
      </u-dialog>
      <u-dialog
        v-model="showImportDialog"
        :show-dialog="showImportDialog"
        title="Import"
        :reset-state="resetDialog"
        :buttons="[{ name: $t('cancel') }, { name: $t('import'), handler: 'import-json', showProgress: true }]"
        @import-json="importJson"
        @close-dialog="showImportDialog = false"
      >
        <ValidationObserver ref="extraFields">
          <name-description
            :name.sync="cloudPolicy.Name"
            :description.sync="cloudPolicy.Description"
            description-required
          />
          <ValidationProvider v-slot="{ errors }" ref="jsonInput" :rules="{ required: true }">
            <u-text-area
              v-model="cloudPolicy.PolicyJson"
              :label="$t('configuration_json')"
              :error-messages="errors"
              no-resize
              rows="7"
            >
              <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
            </u-text-area>
          </ValidationProvider>
        </ValidationObserver>
      </u-dialog>
    </div>
  </u-page>
</template>
<script>
  import { SettingSchemas } from 'vuntangle'
  import cloneDeep from 'lodash/cloneDeep'
  import NameDescription from '@/pages/policies/mfw/policy-manager/components/NameDescription.vue'
  import PoliciesMixin from '@/components/policies/PoliciesMixin.js'
  import {
    mfwServices,
    getFilteredAppliancesByService,
    savePolicy,
    SyncAction,
    ComponentType,
  } from '@/util/mfwServices'
  import vuntangle from '@/plugins/vuntangle'

  export default {
    components: { NameDescription },
    mixins: [PoliciesMixin],

    data() {
      return {
        showImportDialog: false,
        resetDialog: false,
        cloudPolicy: {
          Name: '',
          Description: '',
          PolicyJson: null,
          Type: '',
        },
        schema: null,
      }
    },
    computed: {
      serviceKey: ({ $route }) => $route.params.policyPage,
      serviceType: ({ serviceConfig }) => serviceConfig.type,
      serviceConfig: ({ serviceKey }) => mfwServices[serviceKey],

      title: ({ serviceConfig }) => serviceConfig.tkey,
      policyId() {
        return this.existingPoliciesSelectedRows[0]?.Id || null
      },
      wanPolicy: ({ serviceKey }) => serviceKey === 'wan-policies',

      /** do not show sync for data/policies that are not sync-able */
      showSync: ({ serviceConfig }) => serviceConfig.syncAction !== SyncAction.None,
      // check if 'Sync Now' button should be displayed based on page type (policy/rule)
      // For policies, "Sync now" is available only when one policy is selected
      // For rules, "Sync now" is available when one or more rules are selected
      shouldShowSyncNowButton: ({ serviceConfig, existingPoliciesSelectedRows }) =>
        serviceConfig.syncAction === SyncAction.Single
          ? existingPoliciesSelectedRows.length === 1
          : existingPoliciesSelectedRows.length >= 1,
      /**
       * check the mfwPolicies.js object to determine if the current policy requires a security edition license
       * @return - boolean true if policyRequires a security license, false otherwise
       */
      licenseRequired: ({ serviceConfig, $store }) =>
        serviceConfig.securityLicenseRequired && !$store.state.data.ccViewModel.Account.NoLicenseEnforcement,

      // currently 'group' services can not use the JSON import feature
      canImport: ({ serviceConfig }) => serviceConfig.componentType !== ComponentType.Group,
      /**
       * Selection used for delete dialog
       */
      selection: {
        get() {
          return this.existingPoliciesSelectedRows
        },
        set(newValue) {
          this.existingPoliciesSelectedRows = newValue
        },
      },

      /**
       * Options used for delete dialog
       */
      allOptions: {
        get() {
          return this.existingPolicies
        },
        set(newOption) {
          this.existingPolicies = newOption
        },
      },
    },
    watch: {
      showImportDialog(newVal) {
        if (!newVal) {
          // empty out the form
          this.cloudPolicy = {
            Name: '',
            Description: '',
            PolicyJson: null,
            Type: '',
          }
          // by doing the reset() the form won't show errors on the fields if dialog was previously closed/used
          this.$refs.extraFields.reset()
        }
      },
    },
    created() {
      this.$store.commit('SET_MFW_TEMPLATES_SELECTION', this.$route.path)
    },
    methods: {
      async syncNow() {
        await this.$store.dispatch('appliances/fetchAppliances')
        await this.$store.dispatch('subscriptions/fetchSubscriptions')
        const compatibleAppliances = getFilteredAppliancesByService(this.serviceKey)
        if (compatibleAppliances.length === 0) {
          vuntangle.toast.add(this.$t('no_compatible_appliances_available'), 'error')
        } else {
          this.$router.push({
            name: 'mfw-policies-types-rule-sync',
            params: { type: this.serviceKey, rules: this.existingPoliciesSelectedRows },
          })
        }
      },
      checkIfJson(str) {
        try {
          JSON.parse(str)
          return true
        } catch (e) {
          return false
        }
      },
      async importJson() {
        this.resetDialog = false
        const valid = await this.$refs.extraFields.validate()
        this.cloudPolicy.Type = this.serviceType
        if (!valid || !this.cloudPolicy.PolicyJson) {
          this.resetDialog = true
          return
        }
        this.schema = SettingSchemas[this.serviceKey]
        // make sure the policyJson is not null and that it is in fact able to be a json
        if (!this.checkIfJson(this.cloudPolicy.PolicyJson)) {
          vuntangle.toast.add(this.$t('invalid_json'), 'error')
          this.resetDialog = true
          return
        }
        // clone so ui doesn't show [object object]
        const cloudPolicyClone = cloneDeep(this.cloudPolicy)
        // convert to json
        cloudPolicyClone.PolicyJson = JSON.parse(cloudPolicyClone.PolicyJson)
        // compare json input to the schema
        const result = vuntangle.util.validateSchema(cloudPolicyClone.PolicyJson, this.schema)
        // if not validated or is an empty object like {}
        if (!result) {
          vuntangle.toast.add(this.$t('invalid_json'), 'error')
          this.resetDialog = true
          return
        }
        // save the import as a new policy
        const response = await savePolicy(cloudPolicyClone, cloudPolicyClone.Type)

        if (response.success && response.data) {
          this.fetchExistingPolicies()
          vuntangle.toast.add(this.$t('saved_successfully', [this.$t('policy')]))
        } else {
          vuntangle.toast.add(this.$t('unable_to_save', [this.$t('policy')]), 'error')
        }
        this.showImportDialog = false
      },
    },
  }
</script>
