<template>
  <div>
    <template v-if="params.data.syncStatus === 'IN_PROGRESS'">
      <v-progress-circular indeterminate size="13" width="2" class="mb-2px" color="aristaMediumBlue" />&nbsp;{{
        $t('sync_in_progress')
      }}
    </template>
    <template v-else-if="params.data.syncStatus === 'SUCCESS'">
      <div v-html="$t('success_green')" />
    </template>
    <template v-else-if="params.data.syncStatus === 'FAIL'" class="red">
      <div
        v-html="
          $t('sync_failure_formatted_message', {
            error: $t(params.data.syncStatusMessage),
          })
        "
      />
    </template>
  </div>
</template>
<script>
  import Vue from 'vue'

  export default Vue.extend({})
</script>
<style lang="scss">
  .mb-2px {
    margin-bottom: 2px;
  }
</style>
