var render = function render(){var _vm=this,_c=_vm._self._c;return _c('u-page',{attrs:{"title":_vm.$t('appliances_update'),"subtitle":_vm.$t('update_message'),"full-height":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('u-btn',{attrs:{"text":"","to":{ name: 'appliances' }}},[_c('span',{class:`${_vm.$vuetify.theme.dark ? 'white--text' : ''}`},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]),_c('u-btn',{attrs:{"disabled":!_vm.selectedAppliances.length},on:{"click":function($event){_vm.updateSoftwareConfirm = true}}},[_vm._v(" "+_vm._s(_vm.$t('update'))+" ")])]},proxy:true}])},[_c('u-grid',{attrs:{"id":"appliances-grid","selection-type":"multiAction","no-data-message":_vm.$t('no_data'),"column-defs":_vm.columnDefs,"fetching":_vm.$store.state.appliances.fetching || _vm.$store.state.appliances.fetchingUnassigned,"row-data":_vm.appliances,"selection":_vm.selectedAppliances},on:{"update:selection":function($event){_vm.selectedAppliances=$event},"refresh":function($event){return _vm.$store.dispatch('appliances/fetchFullAppliances', { force: true })}}}),_c('u-dialog',{attrs:{"show-dialog":_vm.updateSoftwareConfirm,"title":_vm.$t('update_software'),"message":_vm.$t('update_appliances_confirmation'),"buttons":[
      { name: _vm.$t('no') },
      {
        name: _vm.$t('yes'),
        handler: 'updateSoftware',
        showProgress: true,
      },
    ]},on:{"updateSoftware":_vm.updateSoftware,"close-dialog":function($event){_vm.updateSoftwareConfirm = false}}}),_c('u-dialog',{attrs:{"show-dialog":_vm.updateSoftwareFailed.length > 0,"title":_vm.$t('appliance_update_software_failed_title'),"buttons":[{ name: _vm.$t('close') }]},on:{"close-dialog":function($event){_vm.updateSoftwareFailed = []}}},[_c('table',{staticStyle:{"width":"100%"},attrs:{"cellpadding":"10"}},_vm._l((_vm.updateSoftwareFailed),function(appliance){return _c('tr',{key:appliance.uid},[_c('td',[_vm._v(_vm._s(appliance.hostname))]),_c('td',[_vm._v(_vm._s(appliance.tag))]),_c('td',[_vm._v(_vm._s(appliance.errMsg))])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }