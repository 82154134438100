<template>
  <div class="fill-height d-flex flex-column">
    <div :class="actionsClassList">
      <u-btn :disabled="!selectedTasks.length" @click="showRemoveTasksDialog">
        {{ $t('remove_task') }}
      </u-btn>
      <u-btn :disabled="selectedTasks.length !== 1" class="ml-2" @click="viewTaskConfiguration">
        {{ $t('view_details') }}
      </u-btn>
    </div>
    <u-grid
      id="tasks-grid-widget"
      row-node-id="TaskId"
      :selection-type="'multiAction'"
      :selection.sync="selectedTasks"
      :no-data-message="noDataMessage"
      :column-defs="tasksColumnDefs"
      :row-data="taskItems"
      :fetching="fetchingTasks"
      @refresh="getTaskList"
      @grid-ready="onGridReady"
    />
    <u-dialog
      :show-dialog="removeTasksDialog"
      :title="$t('remove_task')"
      :message="$t('confirm_remove_task')"
      :buttons="[
        { name: $t('cancel') },
        {
          name: $t('yes'),
          handler: 'removeTasks',
          showProgress: true,
        },
      ]"
      @close-dialog="removeTasksDialog = false"
      @removeTasks="removeTasks"
    />
    <u-dialog
      :show-dialog="!!filteredRecords.length"
      :title="$t('additional_information')"
      :width="600"
      :buttons="[
        {
          'name': $t('ok'),
          'handler': 'close-dialog',
        },
      ]"
      @close-dialog="onCloseDialog"
    >
      <extra-details :filtered-records="filteredRecords" :current-index.sync="currentIndex" />
    </u-dialog>
  </div>
</template>

<script>
  import api from '@/plugins/ut/ut-api'
  import ExtraDetails from '@/components/widgets/ExtraDetails'
  import TasksGridMixin from '@/components/mixins/TasksGridMixin'

  export default {
    components: { ExtraDetails },
    mixins: [TasksGridMixin],
    props: {
      actionsClassList: {
        type: String,
        default: () => '',
      },
    },
    data() {
      return {
        removeTasksDialog: false,
        tasksGridApi: undefined,
        // list of records passed to detail dialog to navigate through
        filteredRecords: [],
        // index of the record being viewed from filteredRecords
        currentIndex: -1,
      }
    },
    computed: {
      noDataMessage() {
        return this.taskItems.length > 0 ? this.$t('no_filtered_data_tasks') : this.$t('no_data_defined_tasks')
      },
    },
    methods: {
      showRemoveTasksDialog() {
        this.removeTasksDialog = true
      },
      async removeTasks() {
        this.$store.commit('SET_PAGE_LOADER', true)
        const selectedTaskIds = this.selectedTasks.map(el => el.TaskId)
        const response = await api.cloud('Untangle_CommandCenter', 'removeTasks', {
          selectedTasks: selectedTaskIds,
          paramOrder: 'selectedTasks',
        })
        if (response.success && !response.message) {
          this.$vuntangle.toast.add(this.$t('tasks_remove_successful'))
          this.selectedTasks = []
          // remove the deleted tasks from the tasks array
          this.taskItems = this.taskItems.filter(item => !selectedTaskIds.includes(item.TaskId))
        } else {
          this.$vuntangle.toast.add(
            this.$t(this.$te(`${response.message}`) ? `${response.message}` : 'tasks_remove_failed'),
            'error',
          )
        }
        this.removeTasksDialog = false
        this.$store.commit('SET_PAGE_LOADER', false)
      },

      onGridReady(params) {
        this.tasksGridApi = params.api
      },

      onCloseDialog() {
        this.filteredRecords = []
        this.currentIndex = -1
      },

      /**
       * Called when user clicks on a row
       * builds the props needed for details dialog and displays the dialog
       */
      viewTaskConfiguration() {
        const taskId = this.selectedTasks[0].TaskId
        this.tasksGridApi.forEachNodeAfterFilterAndSort(({ data }, index) => {
          const config = data.Configuration
          // In CompleteApplianceSetup we have appliance's password as third parameter
          // CompleteApplianceSetup($uid, $adminUser, $adminPassword, $adminEmail, $timeZone, $openWrtTimeZone, $installType)
          if (config.Method === 'CompleteApplianceSetup') {
            const pass = config.Params[2]
            config.Params[2] = '*'.repeat(pass.length)
          }
          this.filteredRecords.push(config)
          if (data.TaskId === taskId) {
            this.currentIndex = index
          }
        })
      },
    },
  }
</script>
