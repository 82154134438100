<!--
  generic rules listing component used by following routes:
  /network/port-forward
  /network/shaping
  /network/nat
  /routing/wan-rules
  /firewall/filter
  /firewall/access

  where
  - `network`, `routing` and `firewall` represents the `category` of the rules
  - `port-forward`, `shaping`, `nat`, `wan-rules`, `filter`, `access` are the rule types

  each rule type has associated a specific chain name for which rules are manipulated
-->

<template>
  <!--
    using the shared vuntangle component RulesList
    see details about it's props and usage in vuntangle
  -->
  <rules-list
    :title="$t(ruleType.replace(/-/g, '_'))"
    :description="ruleType === 'shaping' ? $t('shaping_rules_description') : undefined"
    :rule-type="ruleType"
    :rules="rules"
    :etm-rules="etmRules"
    :remote-data="{ wanPolicies, zoneInterfaces }"
    :disabled="readOnly"
    @edit-rule="onEditRule"
    @delete-rule="onDeleteRule"
  >
    <template #actions="{ updatedRules, updatedEtmRules }">
      <u-btn :min-width="null" :disabled="readOnly" class="mr-2" @click="onReset">{{ $t('reset_to_defaults') }}</u-btn>
      <u-btn :min-width="null" class="mr-2" @click="fetchSettings">{{ $t('refresh') }}</u-btn>
      <u-btn
        :min-width="null"
        :disabled="readOnly"
        class="mr-2"
        @click="$router.push({ name: 'appliances-rules-edit', params: { ruleId: 'add' } })"
      >
        {{ $t('add_rule') }}
      </u-btn>
      <u-btn
        :min-width="null"
        :disabled="saveDisabled(updatedRules, updatedEtmRules)"
        @click="onSave(updatedRules, updatedEtmRules)"
      >
        {{ $t('save') }}
      </u-btn>
    </template>
  </rules-list>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { RulesList } from 'vuntangle'
  import settingsMixin from './settingsMixin'
  import { mfwServices } from '@/util/mfwServices'

  export default {
    components: { RulesList },
    mixins: [settingsMixin],

    computed: {
      /**
       * one of the 5 available rule types
       * `port-forward`, `shaping`, `nat`, `wan-rules`, `filter`, `access`
       */
      ruleType: ({ $route }) => $route.params.settings,
      // the path where settings are going to be saved against the box
      settingsPath: ({ ruleType }) => (ruleType === 'wan-rules' ? 'wan' : `firewall/tables/${ruleType}`),
      // the settings to be updates/saved
      settings: ({ boxSettings, ruleType }) =>
        ruleType === 'wan-rules' ? boxSettings?.wan : boxSettings?.firewall.tables[ruleType],
      // all chains within those settings
      chains: ({ settings }) => settings?.chains || settings?.policy_chains,
      // the chain being edited
      chain: ({ chains, ruleType }) => chains?.find(c => c.name === mfwServices[ruleType].chainName),
      // local defined rules within the chain
      rules: ({ chain }) => chain?.rules || [],
      // etm defined wan-rules from `command-center-rules` chain
      etmRules: ({ ruleType, chains }) => {
        if (ruleType !== 'wan-rules') return []
        const etmChain = chains?.find(c => c.name === 'command-center-rules')
        return etmChain?.rules || []
      },

      // Wan Policies used for the WAN Rules
      wanPolicies: ({ boxSettings }) => boxSettings?.wan.policies || [],
      // interfaces used for _INTERFACE_ZONE conditions types
      zoneInterfaces: ({ $store }) => $store.getters['appliances/zoneInterfaces'],
    },

    methods: {
      /**
       * if no changes detected (enabled/disabled rule or order changed)
       * the `save` action button is disabled
       * @param {Array} updatedRules - edited rules
       */
      saveDisabled(updatedRules, updatedEtmRules) {
        const localRulesUnchanged = this.rules.every(
          (rule, ruleIndex) =>
            rule.enabled === updatedRules[ruleIndex]?.enabled && rule.ruleId === updatedRules[ruleIndex]?.ruleId,
        )
        let etmRulesUnchanged = true
        if (updatedEtmRules.length) {
          etmRulesUnchanged = this.etmRules.every(
            (rule, ruleIndex) =>
              rule.enabled === updatedEtmRules[ruleIndex]?.enabled &&
              rule.ruleId === updatedEtmRules[ruleIndex]?.ruleId,
          )
        }
        return localRulesUnchanged && etmRulesUnchanged
      },

      /**
       * redirect to the rule editing route (specific to MFW)
       * @param {String} id - the rule id
       */
      onEditRule(id) {
        this.$router.push({ name: 'appliances-rules-edit', params: { ruleId: id } })
      },

      /**
       * removes a rule from a chain and saves the settings
       * this is called from a vuntangle removal confirmation dialog
       * @param {String} id - the rule id
       */
      onDeleteRule(id) {
        const settings = cloneDeep(this.settings)
        const chains = settings.chains || settings.policy_chains
        if (!chains) return
        const chain = chains.find(chain => !!chain.rules.find(rule => rule.ruleId === id))
        if (!chain) return
        chain.rules = chain.rules.filter(rule => rule.ruleId !== id)

        this.saveSettings(this.settingsPath, settings)
      },

      /**
       * calls the mixin `saveSettings` method
       * @param {Array} updatedRules - the Rule(s) as updated in shared component
       */
      onSave(updatedRules, updatedEtmRules) {
        const settings = cloneDeep(this.settings)
        const chains = settings.chains || settings.policy_chains
        if (!chains) return
        const chain = chains.find(chain => chain.name === this.chain.name)
        if (!chain) return

        // set the updated rules on the settings chain
        chain.rules = updatedRules

        if (updatedEtmRules.length) {
          const ccChain = chains.find(chain => chain.name === 'command-center-rules')
          ccChain.rules = updatedEtmRules
        }

        this.saveSettings(this.settingsPath, settings)
      },

      /**
       * calls the mixin `onResetToDefaults`
       */
      onReset() {
        this.onResetToDefaults(this.ruleType)
      },
    },
  }
</script>
