var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-4"},[(_vm.expiredCertWarning)?_c('u-alert',{attrs:{"error":""}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(_vm.expiredCertWarning))}})]):_vm._e(),(_vm.cmdConnectWarning)?_c('u-alert',{attrs:{"error":""}},[_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.$t(_vm.cmdConnectWarning, { to: _vm.$isSapphire ? _vm.$t('cloud_vision') : _vm.$t('etm_dashboard'), uid: _vm.appliance.Uid })
      )}})]):_vm._e(),(_vm.isBasicEditionMfw)?_c('v-alert',{attrs:{"text":"","color":"primary"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"primary"}},[_vm._v("mdi-license")]),_vm._v(" "+_vm._s(_vm.$t('security_license_not_found'))+" ")],1),_c('div',{staticClass:"d-flex ml-auto"},[_c('u-btn',{staticClass:"ml-4",attrs:{"to":{ name: 'account-subscriptions' }}},[_vm._v(_vm._s(_vm.$t('manage_subscriptions')))]),_c('u-btn',{staticClass:"ml-4",attrs:{"href":`${_vm.$store.state.data.ccViewModel.StoreUrl}configurator/?sku=SR-11-SWSE-0100-1YEAR`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('buy_subscriptions'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-open-in-new ")])],1)],1)])]):_vm._e(),(_vm.licenseExpireMessage)?_c('u-alert',{attrs:{"error":""}},[_c('span',{domProps:{"innerHTML":_vm._s(
        `${_vm.licenseExpireMessage} ${_vm.$t('maintain_security_renew_your_subscription', {
          baseUrl: _vm.$options.baseUrl,
        })}`
      )}})]):_vm._e(),(!_vm.licensedForCommandCenter)?_c('u-alert',{attrs:{"error":""}},[_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.$t(_vm.appliance.ProductLine === 'NGFW' ? 'ngfw_no_complete_subscription' : 'mfw_no_subscription', [
          _vm.$store.state.data.ccViewModel.StoreUrl,
        ])
      )}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }