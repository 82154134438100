<template>
  <reports :features="features" @view-report="onViewReport" />
</template>
<script>
  import { Reports } from 'vuntangle'

  export default {
    components: { Reports },
    data() {
      return {
        features: {
          hasApplianceSettings: true,
        },
      }
    },

    methods: {
      onViewReport(id) {
        this.$router.push({ name: 'pm-report-details', params: { id } })
      },
    },
  }
</script>
