<template>
  <u-widget :title="$t('information')" :enable-expand="false" v-on="$listeners">
    <div class="text-center mt-2 mb-2">
      {{ network.Name }}
    </div>
    <div class="text-center">
      {{ $tc('appliance_count', network.Uids.length) }}
    </div>
    <div class="text-center">
      {{ `${$t('created')}: ${$d(network.DateCreated.replace('/Date(', '').replace(')/', ''), 'long')}` }}
    </div>
    <div class="text-center">
      <u-btn class="my-2" @click="deleteNetworkDialog = true">
        {{ $t('delete_network') }}
      </u-btn>
    </div>

    <u-dialog
      :show-dialog="deleteNetworkDialog"
      :title="$t('delete_network')"
      :message="$t('confirm_delete_network')"
      :buttons="[
        { name: $t('cancel') },
        {
          name: $t('yes'),
          handler: 'deleteNetwork',
          showProgress: true,
        },
      ]"
      @close-dialog="deleteNetworkDialog = false"
      @deleteNetwork="deleteNetwork"
    />
  </u-widget>
</template>

<script>
  export default {
    props: {
      network: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        deleteNetworkDialog: false,
      }
    },
    methods: {
      /**
       * Delete the current network.
       *
       * @return {void}
       */
      async deleteNetwork() {
        // dispatch to delete the current network
        await this.$store.dispatch('networks/deleteNetwork', this.network)

        this.deleteNetworkDialog = false

        // redirect back to network list page
        this.$router.push({ name: 'networks' })
      },
    },
  }
</script>
