<template>
  <u-page :title="$t('backups')" enable-refresh full-height>
    <template #actions>
      <div>
        <u-btn :disabled="selectedRows.length === 0" @click="showRestorePage">
          {{ $t('restore_backup') }}
        </u-btn>
      </div>
    </template>
    <u-grid
      id="backups-grid"
      selection-type="singleAction"
      :no-data-message="$t('no_data')"
      :column-defs="columnDefs"
      :fetching="backupsFetching"
      :row-data="accountBackups"
      :enable-export-csv="true"
      :selection.sync="selectedRows"
      @refresh="fetchAccountBackups"
    />
  </u-page>
</template>

<script>
  import grids from '@/plugins/ut/ut-grids'

  export default {
    data() {
      return {
        accountBackups: [],
        selectedRows: [],
        backupsFetching: true,
        showCreateBackupDialog: false,
      }
    },
    computed: {
      // merge account backups columns with appliance columns
      columnDefs: () => grids.mergeWithApplianceColumns(grids.getAccountBackupsColumnDefs(), ['uid', 'tag']),
    },
    created() {
      this.$store.commit('SET_BACKUPS_SELECTION', this.$route.path)
      this.fetchAccountBackups()
    },
    methods: {
      async fetchAccountBackups() {
        this.backupsFetching = true
        await this.$store.dispatch('appliances/fetchFullAppliances')
        const response = await this.$store.dispatch('appliances/fetchAccountBackups')
        if (response.success && response.data) {
          // backups data
          const backups = response.data
          // merge backups data with appliance data
          this.accountBackups = this.$store.getters['appliances/populateApplianceInfoByUid'](backups, 'Uid')
        } else {
          this.$vuntangle.toast.add(response.message || this.$t('an_error_occurred'), 'error')
        }
        this.backupsFetching = false
      },
      /**
       * pushes user to the restore page and sends the selected backup
       */
      showRestorePage() {
        this.$router.push({
          name: 'appliances-backups-restore',
          params: {
            backup: this.selectedRows[0],
          },
        })
      },
    },
  }
</script>
