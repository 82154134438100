<template>
  <u-page :title="$t('notification_log')" full-height>
    <u-grid
      id="notifications-log"
      :no-data-message="$t('no_data')"
      :column-defs="columnDefs"
      :fetching="$store.state.events.notificationLogFetching"
      :row-data="$store.state.events.notificationLog || []"
      @refresh="$store.dispatch('events/fetchNotificationLog', { force: true })"
      @row-clicked="getDetails"
    />
    <u-dialog
      :show-dialog="showExtraDetails"
      :title="$t('additional_information')"
      :buttons="[
        {
          'name': $t('ok'),
          'handler': 'close-dialog',
        },
      ]"
      @close-dialog="showExtraDetails = false"
    >
      <notification-details :extra-details="extraDetails" />
    </u-dialog>
  </u-page>
</template>
<script>
  import NotificationDetails from '@/pages/alerts/notification-details'
  import users from '@/plugins/ut/ut-users'

  export default {
    components: { NotificationDetails },
    /** Prevent/redirect access if user does not have rights */
    beforeRouteEnter(to, from, next) {
      if (!users.hasFullAccountAccess()) {
        next('/alerts/alerts-received')
      } else next()
    },
    data() {
      return {
        showExtraDetails: false,
        extraDetails: [],
      }
    },
    computed: {
      columnDefs() {
        return [
          {
            headerName: this.$t('date_time'),
            flex: 0,
            width: 200,
            valueGetter: ({ data }) => data.DateCreated?.$date,
            valueFormatter: ({ value }) => this.$vuntangle.dates.formatDateFromApi(value),
            comparator: (a, b) => this.$vuntangle.dates.compareDates(a, b),
          },
          {
            headerName: this.$t('alert_rule'),
            field: 'Payload.AlertRuleName',
          },
          {
            headerName: this.$t('notification_profile'),
            field: 'Payload.NotificationProfileName',
          },
          {
            headerName: this.$t('status'),
            field: 'Payload.Status',
          },
        ]
      },
    },
    created() {
      this.$store.commit('SET_ALERTS_SELECTION', this.$route.path)
      this.$store.dispatch('events/fetchNotificationLog')
    },
    methods: {
      async getDetails({ data: row }) {
        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await this.$store.dispatch('events/fetchAccountEventDetails', {
          id: row.Payload.EventId,
        })
        if (response) {
          this.extraDetails = response
          this.showExtraDetails = true
        }
        this.$store.commit('SET_PAGE_LOADER', false)
      },
    },
  }
</script>
