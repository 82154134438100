<template>
  <ngfw-policies-grid
    class="flex-grow-1"
    type="alertRules"
    :title="$t('page_title_app', { 'app': 'Alert' })"
    scope="global"
    :policy-manager-required="false"
  />
</template>
<script>
  import NgfwPoliciesGrid from '@/components/policies/NgfwPoliciesGrid'

  export default {
    components: { NgfwPoliciesGrid },
  }
</script>
