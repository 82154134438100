<template>
  <u-page v-if="confirmRestore" :title="$t('restore_backup')" enable-refresh full-height>
    <div class="d-flex flex-column flex-grow-1">
      <v-card-title class="headline pa-0">
        {{ $t('confirm_restore') }}
      </v-card-title>
      <div class="mt-4">
        <b> {{ $t('appliance_backup') }} </b>
        <br />
        {{ $t('backup_hostname') }}{{ backupHostname }}
        <br />
        {{ $t('backup_uid') }}{{ backup.Uid }}
        <br />
        {{ $t('backup_label') }}{{ backupLabel }}
        <br />
        {{ $t('version') }}{{ backupVersion }}
        <br />
        {{ $t('backup_date') }}{{ timestamp }}
      </div>
      <div class="mt-4">
        <b> {{ $t('appliance_to_restore') }} </b>
        <div v-for="targetAppliance in targetAppliances" :key="targetAppliance">
          {{ targetAppliance }}
          <br />
        </div>
      </div>
      <div class="mt-8">
        {{ $t('restore_instructions', { settingsExceptions: exceptionsText }) }}
      </div>
      <div class="d-flex flex-row justify-left">
        <v-card-actions class="px-0 mt-4">
          <v-spacer />
          <u-btn text :to="{ name: 'appliances-backups' }">
            <span :class="`${$vuetify.theme.dark ? 'white--text' : ''}`">{{ $t('cancel') }}</span>
          </u-btn>
          <u-btn @click="doRestore">
            {{ $t('confirm_restore_button') }}
          </u-btn>
        </v-card-actions>
      </div>
    </div>
  </u-page>
  <u-page v-else :title="$t('restore_backup')" enable-refresh full-height>
    <div class="d-flex flex-column flex-grow-1">
      <v-card-title class="headline pa-0">
        {{ $t('select_appliances_to_restore') }}
      </v-card-title>
      <v-card-subtitle class="ma-0 px-0">
        {{ $t('select_appliances_restore_instructions') }}
      </v-card-subtitle>
      <u-grid
        id="appliances-grid"
        selection-type="multiAction"
        :no-data-message="$t('no_data')"
        :column-defs="columnDefs"
        :fetching="$store.state.appliances.fetching"
        :row-data="appliances"
        :selection.sync="selectedTargetAppliances"
        @refresh="$store.dispatch('appliances/fetchFullAppliances', { force: true })"
      />
    </div>
    <div v-if="backupApplianceType === 'MFW'">
      <v-row class="mt-4 ms-0 font-weight-bold"> {{ $t('restore_all_settings_except') }} </v-row>
      <v-row class="mt-2">
        <v-col cols="auto" class="mr-4">
          <v-checkbox
            v-model="accountsException"
            class="my-0 mr-4"
            label="Accounts"
            :ripple="false"
            @change="!accountsException"
        /></v-col>
        <v-col cols="auto">
          <v-checkbox
            v-model="interfaceException"
            class="my-0 mr-4"
            label="Interfaces"
            :ripple="false"
            @change="!accountsException"
          />
        </v-col>
      </v-row>
      <u-alert info class="mb-0" style="display: inline-block">
        <span v-html="$t('restore_warning')"></span>
      </u-alert>
    </div>
    <div class="d-flex flex-row justify-left">
      <v-card-actions class="px-0 mt-4">
        <v-spacer />
        <u-btn @click="returnToBackups">
          {{ $t('back') }}
        </u-btn>
        <u-btn :disabled="selectedTargetAppliances.length === 0" @click="confirmRestorePage">
          {{ $t('restore_backup') }}
        </u-btn>
      </v-card-actions>
    </div>
  </u-page>
</template>

<script>
  import grids from '@/plugins/ut/ut-grids'
  import api from '@/plugins/ut/ut-api'
  import vuntangle from '@/plugins/vuntangle'
  import store from '@/store'
  import util from '@/plugins/ut/ut-util'
  export default {
    data() {
      return {
        selectedTargetAppliances: [],
        backup: this.$route.params.backup,
        confirmRestore: false,
        targetUids: '',
        timestamp: '',
        accountsException: false,
        interfaceException: false,
      }
    },
    computed: {
      columnDefs() {
        return grids.getApplianceGridColumns(
          {},
          this.$store.state.data.ccViewModel.Account.NoLicenseEnforcement ? ['license'] : [],
        )
      },
      /**
       * get list of compatible appliances
       */
      appliances() {
        if (!this.backup) {
          return []
        }

        const version = this.getVersionFromFileName()
        const ngfwAppliaces = this.$store.getters['appliances/getNgfwAppliances']
        const mfwAppliances = this.$store.getters['appliances/getMfwAppliances']

        return this.backupApplianceType === 'NGFW'
          ? this.getCompatibleAppliances(ngfwAppliaces, version)
          : this.getCompatibleAppliances(mfwAppliances, version)
      },
      /**
       * get target readable string of target appliances to show in the confirmation page
       */
      targetAppliances() {
        return this.selectedTargetAppliances.map(
          Appliances => util.addApplianceTagsInString(Appliances.Uid) + ', ' + Appliances.SoftwareVersion,
        )
      },
      backupAppliance() {
        const getByUid = this.$store.getters['appliances/getByUid']
        return getByUid(this.backup?.Uid)
      },
      backupApplianceType() {
        return this.backupAppliance?.ProductLine
      },
      backupHostname() {
        return this.backupAppliance?.Hostname
      },
      backupLabel() {
        return this.backupAppliance?.ApplianceTag || this.$t('label_not_assigned')
      },
      backupVersion() {
        return this.backupAppliance?.SoftwareVersion
      },
      exceptionsToRestore() {
        const exceptions = []
        if (this.accountsException) {
          exceptions.push('accounts')
        }
        if (this.interfaceException) {
          exceptions.push('network')
        }
        return exceptions
      },

      /**
       * get exceptions to show in the confirmation page
       */
      exceptionsText() {
        return this.backupApplianceType === 'NGFW'
          ? this.$t('restore_instructions_exceptions', { settingsExceptions: 'Network' })
          : this.exceptionsToRestore.length < 1
          ? ''
          : this.$t('restore_instructions_exceptions', {
              settingsExceptions: this.exceptionsToRestore.join(' and '),
            })
      },
    },
    created() {
      // if user comes to this page without going through appliances/backup/ send them back before checking Uid
      if (!this.backup) {
        this.returnToBackups()
        return
      }

      this.$store.commit('SET_BACKUPS_SELECTION', this.$route.path)
      this.$store.dispatch('appliances/fetchFullAppliances')
    },
    methods: {
      /**
       * called to return to appliances/backups page
       */
      returnToBackups() {
        this.confirmRestore = false
        this.$router.push({ name: 'appliances-backups' })
      },
      /**
       * gather information to de displayed in the confirmation page
       */
      confirmRestorePage() {
        this.timestamp = this.$vuntangle.dates.formatDateFromApi(this.backup.Timestamp)
        this.confirmRestore = true
      },
      /**
       * create array of target Uids from the selected appliances
       */
      getTargetUids() {
        return this.selectedTargetAppliances.map(Appliances => Appliances.Uid)
      },
      /**
       * restores selected appliances using the backup file passed from the appliances/backups page
       */
      async doRestore() {
        store.commit('SET_PAGE_LOADER', true)
        const targetUids = this.getTargetUids()
        const response = await api.cloud('Untangle_CommandCenter', 'RestoreAppliancesFromBackup', {
          masterUid: this.backup.Uid,
          targetUids,
          backupFileName: this.backup.Name,
          backupMd5: this.backup.FileMd5,
          settingExceptions: this.exceptionsToRestore,
          paramOrder: 'masterUid targetUids backupFileName backupMd5 settingExceptions',
        })
        // check if each uid was successfully restored
        if (response.data === null) {
          vuntangle.toast.add(this.$t('restore_failure'), 'error')
          this.returnToBackups()
          return
        }
        if (response.data) {
          vuntangle.toast.add(this.$t('restore_success'))
        } else {
          vuntangle.toast.add(targetUids.join(', ') + this.$t('restore_failure'), 'error')
        }
        store.commit('SET_PAGE_LOADER', false)
        this.returnToBackups()
      },
      /**
       * Get version from backup file name
       */
      getVersionFromFileName() {
        const fileName = this.backup.Name
        const fileNameWithoutExtension = fileName.replace('.tar.gz', '')
        const fileNamePieces = fileNameWithoutExtension.split('_')

        // backup file name is according to new format: {date}_{time}_{hash}_{version}.{extension}
        let version = ''
        if (fileNamePieces.length === 4) {
          version = fileNamePieces[3]
        }

        return version
      },
      /**
       * Get compatible appliances based on version
       * @param appliances
       * @param version
       * @returns {*}
       */
      getCompatibleAppliances(appliances, version) {
        if (version === '' || typeof appliances === 'undefined') {
          return []
        }

        const compatibleAppliances = []
        appliances.forEach(appliance => {
          const applianceVersion = appliance.SoftwareVersion
          // check for major version
          if (parseFloat(applianceVersion) > parseFloat(version)) {
            compatibleAppliances.push(appliance)
          }

          // in case of same major version, check release
          // applianceVersion and version might look like this x.y.z or x.y
          if (parseFloat(applianceVersion) === parseFloat(version)) {
            const applianceVersionParts = applianceVersion.split('.')
            // in case of this x.y format, convert to x.y.0
            const applianceReleaseVersion = applianceVersionParts.length === 3 ? applianceVersionParts[2] : 0
            const versionParts = version.split('.')
            const releaseVersion = versionParts.length === 3 ? versionParts[2] : 0

            if (parseInt(applianceReleaseVersion) >= parseInt(releaseVersion)) {
              compatibleAppliances.push(appliance)
            }
          }
        })

        return compatibleAppliances
      },
    },
  }
</script>
