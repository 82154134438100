<template>
  <div class="d-flex flex-column flex-grow-1">
    <u-grid
      id="object-groups"
      selection-type="multiAction"
      row-node-id="id"
      :no-data-message="noDataMessage"
      :column-defs="columnDefs"
      :fetching="fetching"
      :row-data="objects"
      :selection.sync="selection"
      :framework-components="frameworkComponents"
      style="height: 400px"
      @refresh="fetchObjects(true)"
    >
      <template v-if="autoHideGeneratedObjects" #toolbarActions>
        <v-divider vertical class="mx-4" />
        <v-switch v-model="showAutoGenerated" dense hide-details class="mr-1 mt-0 pt-0">
          <template #label>
            <span class="text-caption">{{ $t('show_autogenerated') }}</span>
          </template>
        </v-switch>
      </template>
    </u-grid>
    <slot name="actions" :new-settings="settingsCopy" :disabled="!selection.length" />
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { columnDefs, objectsConfig, Type, ConditionsRenderer, ObjectValueRenderer, NameRenderer } from 'vuntangle/pm'
  import { getFilteredObjects } from '../util'
  import { hydrateObjectsData, hydrateConditionsData } from '../hydration'

  export default {
    props: {
      settings: { type: Object, default: () => {} },
      type: { type: String, required: false, default: '' },
    },
    data() {
      return {
        objectTypeFromEditor: this.type,
        settingsCopy: undefined,
        // to filter in / out the auto gen objects via the toggle
        showAutoGenerated: false,

        selection: [],
        frameworkComponents: {
          ConditionsRenderer,
          ObjectValueRenderer,
          NameRenderer,
        },
      }
    },
    computed: {
      groupType: ({ $route, objectTypeFromEditor }) => objectTypeFromEditor ?? $route.params.type,
      config: ({ groupType }) => objectsConfig[groupType],
      linkedObjectType: ({ config }) => config.linkedObjectType,
      isConditionGroup: ({ groupType }) => groupType === Type.ObjectConditionGroup,
      autoHideGeneratedObjects: ({ linkedObjectType }) => objectsConfig[linkedObjectType].autoHideGeneratedObjects,
      objects: ({ $store, config, linkedObjectType, showAutoGenerated, isConditionGroup }) => {
        const hydrateData = isConditionGroup ? hydrateConditionsData : hydrateObjectsData
        return hydrateData(
          getFilteredObjects(
            $store.getters['policyManager/getObjectsByType'](linkedObjectType),
            config.readOnly,
            showAutoGenerated,
          ),
        )
      },
      fetching: ({ $store, linkedObjectType }) => $store.getters['policyManager/fetching'](linkedObjectType),
      columnDefs: ({ isConditionGroup }) =>
        isConditionGroup ? columnDefs.getCommonColumnDefs(false) : columnDefs.getObjectsColumnDefs(),
      itemsSelected: ({ selection }) => selection.length > 0,
      noDataMessage({ config }) {
        const configNoGroup = config.text.replace('_group', '')
        return this.objects.length > 0
          ? this.$t('no_filtered_data_' + configNoGroup)
          : this.$t('no_data_defined_' + configNoGroup)
      },
    },
    watch: {
      settings: {
        handler(settings) {
          this.settingsCopy = cloneDeep(settings)
          this.selection = [...this.settingsCopy.items]
        },
        immediate: true,
        deep: true,
      },
      selection: {
        handler(list) {
          // if list is an array of Objects, use the Id property otherwise use the value as is
          this.settingsCopy.items = list.map(item => item.id || item)
        },
      },
    },
    mounted() {
      this.fetchObjects()
    },
    methods: {
      /**
       * fetches objects
       */
      fetchObjects(force = false) {
        this.$store.dispatch('policyManager/fetchObjectsByType', { type: this.linkedObjectType, force })
      },
    },
  }
</script>
