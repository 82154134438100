<template>
  <div class="my-2">
    <v-row no-gutters>
      <v-col :cols="4">
        <strong> {{ $t('date_created') }} </strong>
      </v-col>
      <v-col :cols="8">{{ $vuntangle.dates.formatDateFromApi(extraDetails.DateCreated) }}</v-col>
    </v-row>
    <v-row no-gutters>
      <v-col :cols="4"
        ><strong> {{ $t('message') }} </strong></v-col
      >
      <v-col :cols="8">{{ $options.util.obfuscateUidsInString(extraDetails.Message) }}</v-col>
    </v-row>
    <v-row no-gutters>
      <v-col :cols="4"
        ><strong> {{ $t('email') }} </strong></v-col
      >
      <v-col :cols="8">{{ extraDetails.Email }}</v-col>
    </v-row>
    <v-row no-gutters>
      <v-col :cols="4"
        ><strong> {{ $t('user_agent') }} </strong></v-col
      >
      <v-col :cols="8">{{ extraDetails.UserAgent }}</v-col>
    </v-row>
    <v-row no-gutters>
      <v-col :cols="4"
        ><strong> {{ $t('account_id') }}</strong></v-col
      >
      <v-col :cols="8">{{ extraDetails.AccountId }}</v-col>
    </v-row>
  </div>
</template>
<script>
  import util from '@/plugins/ut/ut-util'

  export default {
    props: {
      extraDetails: { type: Object, required: true },
    },
    util,
  }
</script>
