<!--
  Component receiving one or multiple condition object IDs that are going to be edited
-->
<template>
  <div class="d-flex flex-column">
    <u-alert v-if="!isValid" error class="mb-4"> {{ $t('policy_conditions_validation_failure') }}</u-alert>
    <div class="d-flex flex-column mb-2">
      <v-card v-if="showSummary" rounded outlined class="pa-2 mb-4 pl-4">
        <div class="caption text--secondary">{{ $t('summary') }}</div>
        <readable-condition-items
          v-if="allConditionObjectsItems.length || !allGroupsConditionsObjectsItems.length"
          :items="allConditionObjectsItems"
          :use-any="useAny"
        />
        <span
          v-if="allConditionObjectsItems.length && allGroupsConditionsObjectsItems.length"
          class="text-uppercase font-weight-bold"
        >
          <br />
          {{ $t('and') }}
        </span>
        <template v-if="allGroupsConditionsObjectsItems.length">
          <readable-condition-groups :groups="allGroupsConditionsObjectsItems" :use-any="useAny" />
        </template>
      </v-card>
      <!--
        if there are multiple objects, those are displayed in expand/collapse fashion
 -->
      <v-card outlined>
        <div v-for="(conditionObject, index) in objectsCopy" :key="conditionObject.Id">
          <v-sheet
            class="d-flex align-center pa-1 text-body-2"
            height="46"
            :color="`grey ${$vuetify.theme.dark ? 'darken-4' : 'lighten-4'}`"
            rounded
          >
            <v-btn v-if="objectsCopy.length > 1" icon @click="toggleExpand(index)">
              <v-icon>{{ expanded[index] === 0 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
            </v-btn>
            <readable-condition-items
              v-if="conditionObject.Type === Type.ObjectCondition"
              :items="conditionObject.PolicyJson.items"
              class="ml-2 flex-grow-1"
            />
            <div v-else-if="conditionObject.Type === Type.ObjectConditionGroup" class="ml-2 flex-grow-1">
              <v-chip small class="text-uppercase mr-2">
                {{ $t('group') }}
              </v-chip>
              {{ conditionObject.Name.startsWith('Autogenerated') ? 'Autogenerated' : conditionObject.Name }}
            </div>
            <div v-if="conditionObject.Type === Type.ObjectCondition" class="mx-3 text--disabled">
              {{ conditionObject.Name.startsWith('Autogenerated') ? 'Autogenerated' : conditionObject.Name }}
            </div>
            <v-btn
              v-if="conditionObject.Type === Type.ObjectConditionGroup"
              small
              text
              color="primary"
              class="mr-1"
              @click="onEditConditionGroup(conditionObject)"
            >
              {{ $t('edit_group') }}
            </v-btn>
            <v-btn v-if="objectsCopy.length > 1" icon class="mr-1" @click="onDeleteConditionObject(index)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-sheet>
          <v-divider />
          <v-expand-transition>
            <!-- `expanded` representing the 0 based index of the expanded cond object  -->
            <div v-show="expanded[index] === 1">
              <edit-condition-object
                v-if="objectsCopy[index].Type === Type.ObjectCondition"
                :condition-object.sync="objectsCopy[index]"
                class="pa-1"
                v-on="$listeners"
              />
              <edit-condition-group
                v-if="objectsCopy[index].Type === Type.ObjectConditionGroup"
                :condition-object-group.sync="objectsCopy[index]"
                class="pa-1"
                v-on="$listeners"
              />
            </div>
          </v-expand-transition>
        </div>
      </v-card>
    </div>
    <!-- the add existing condition lookup -->
    <condition-lookup-grid class="flex-grow-1 my-2" @add-condition="onAddCondition" />
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import isEqual from 'lodash/isEqual'
  import { mapGetters, mapActions } from 'vuex'
  import { VExpandTransition } from 'vuetify/lib'
  import { Type, ConditionTarget } from 'vuntangle/pm'

  import { conditionItemsValue } from '../valueGetters'
  import { generateDefaultMFWObject, uuidv4 } from '../util'
  import ReadableConditionItems from '../components/ReadableConditionItems.vue'
  import ReadableConditionGroups from '../components/ReadableConditionGroups.vue'
  import ConditionLookupGrid from './ConditionLookupGrid.vue'
  import EditConditionObject from './EditConditionObject.vue'
  import EditConditionGroup from './EditConditionGroup.vue'

  export default {
    components: {
      EditConditionObject,
      EditConditionGroup,
      VExpandTransition,
      ConditionLookupGrid,
      ReadableConditionItems,
      ReadableConditionGroups,
    },
    inject: ['$conditionsStats'],
    props: {
      // condition objects ids
      ids: { type: Array, default: () => [] },
      // the object policy/rule Id that's being created/edited
      objectId: { type: String, default: undefined },
      isClone: { type: Boolean, default: false },
    },

    data() {
      return {
        // list of new or existing objects that are manipulated
        objectsCopy: [],
        expanded: [1],
        // flag to determine if all conditions are valid
        isValid: true,
        Type,
      }
    },

    computed: {
      // map getters from store modules
      ...mapGetters('policyManager', ['getObjectById', 'getObjectsByTypeIds', 'getObjectsByIds']),
      conditions: ({ ids, getObjectsByTypeIds }) => getObjectsByTypeIds(Type.ObjectCondition, ids),
      conditionGroups: ({ ids, getObjectsByTypeIds }) => getObjectsByTypeIds(Type.ObjectConditionGroup, ids),

      objects: ({ conditions, conditionGroups }) => [...conditions, ...conditionGroups],
      // whether any object get modified
      isChanged: ({ objects, objectsCopy }) => {
        // when creating a new policy/rule return false if no condition items present
        if (!objects?.length && !objectsCopy?.some(obj => obj.PolicyJson.items.length > 0)) return false
        return !isEqual(objects, objectsCopy)
      },
      // aggregates condition items from all condition objects used for readable conditions string
      allConditionObjectsItems: ({ objectsCopy }) =>
        objectsCopy
          ?.filter(object => object.Type === Type.ObjectCondition)
          .map(object => object.PolicyJson.items)
          .flat(),

      // aggregated condition items from all condition objects withing groups
      allGroupsConditionsObjectsItems: ({ objectsCopy, getObjectsByIds }) => {
        const groups = []
        objectsCopy
          ?.filter(object => object.Type === Type.ObjectConditionGroup)
          .forEach(group => {
            // extract condition objects withing group
            const conditionObjects = getObjectsByIds(group.PolicyJson.items)
            groups.push(conditionObjects.map(object => object.PolicyJson.items))
          })
        return groups
      },
      // used to updated provided conditionStats changed, letting parent components know about changes
      conditionsStats: ({ $conditionsStats }) => $conditionsStats(),

      // hods the current objects ids for the edited policy/rule
      idsCopy: ({ objectsCopy }) => objectsCopy.map(object => object.Id),

      // computes if in the summary shoudl be used `Any Source` and/or `Any Destination`
      useAny: ({ allConditionObjectsItems, allGroupsConditionsObjectsItems }) => {
        const allItems = [...allConditionObjectsItems, ...allGroupsConditionsObjectsItems].flat().flat()

        const sourceItems = conditionItemsValue(allItems, ConditionTarget.Source)
        const destItems = conditionItemsValue(allItems, ConditionTarget.Destination)
        return {
          source: !sourceItems.length,
          dest: !destItems.length,
        }
      },

      // symmary is shown only when having more than one condition objects or there are groups
      showSummary: ({ objectsCopy }) =>
        objectsCopy.length > 1 || (objectsCopy.length === 1 && objectsCopy[0].Type === Type.ObjectConditionGroup),
    },

    watch: {
      ids: {
        handler(ids) {
          // if editing rule/policy already has conditions objects
          if (ids.length) {
            this.objectsCopy = [
              ...cloneDeep(this.getObjectsByTypeIds(Type.ObjectCondition, ids)),
              ...cloneDeep(this.getObjectsByTypeIds(Type.ObjectConditionGroup, ids)),
            ]

            // initialize the expanded stae (first being expoanded by default)
            this.expanded = Array(ids.length).fill(0)
            this.expanded[0] = 1

            if (this.isClone) {
              this.objectsCopy.forEach(object => {
                const newId = `create-${object.Id}`
                object.Id = newId
              })
            }

            return
          }
          // if editing objects list empty just add the first object condition
          if (!this.objectsCopy.length) {
            this.onCreateNew()
          }
        },
        immediate: true,
      },
      objectsCopy: {
        /**
         * When just a single object left that one should be expanded
         * @param {Array} objects - the locally manipulated objects
         */
        handler(objects) {
          if (objects.length === 1) this.expanded = [1]
        },
        immediate: true,
      },
      isChanged: {
        handler(val) {
          // updated `changed` state of the conditions
          this.conditionsStats.changed = val
          this.isValid = true
        },
        immediate: true,
      },
    },

    methods: {
      ...mapActions('policyManager', ['editObjectDialog']),
      /**
       * Adds a new condition object to the list of conditions
       */
      onCreateNew() {
        // get the default new object based on type
        const newConditionObject = generateDefaultMFWObject(Type.ObjectCondition)
        // set a temporary Id for a new object, temp uuid needed for proper key-ing v-for items
        newConditionObject.Id = `create-${uuidv4()}`
        // empty items set, as doesn't have to be prepopulated with an empty condition item
        newConditionObject.PolicyJson.items = []
        // adds the new object at the top of the list
        this.objectsCopy.unshift(newConditionObject)
      },

      /**
       * Removes an existing condition object from list, also updates the expanded states
       * @param {Number} index - condition index to be removed
       */
      onDeleteConditionObject(index) {
        if (index >= 0) {
          this.objectsCopy.splice(index, 1)
          this.expanded.splice(index, 1)
        }
      },

      /**
       * Adds an existing condition object to the list
       * @param {String} id - existing condition object/group Id
       */
      onAddCondition(id) {
        // prevent adding same existing condition object twice (if already exists)
        if (this.idsCopy.includes(id)) {
          this.$vuntangle.toast.add(this.$t('condition_object_already_added'))
          return
        }

        // otherwise get the full object based on provided id and add it to the list
        const conditionObject = this.getObjectById(id)
        if (!conditionObject) return

        // adds a condition object to objectsCopy clone and expands the latter if multiple condition objects
        if (this.objectsCopy.length === 1 && !this.objectsCopy[0].PolicyJson.items.length) {
          this.objectsCopy = [{ ...conditionObject, isExisting: true }]
        } else {
          // collapses all objects except the one that has been added
          this.expanded = Array(this.objectsCopy.length).fill(0)
          this.objectsCopy.push({ ...conditionObject, isExisting: true })
          this.expanded.push(1)
        }
      },

      /**
       * saves all conditions objects attached to a rule/policy
       * important: this is called from parent components (EditRule, or PolicyConditions), not by itself
       * @returns the objects that got saved/created
       */
      async saveConditionObjects() {
        return await Promise.all(
          this.objectsCopy.map(async object => await this.$store.dispatch('policyManager/saveObject', { object })),
        )
      },

      /**
       * Expands/collapses a condition object entry
       * @param {Number} index - the object index
       */
      toggleExpand(index) {
        this.$set(this.expanded, index, this.expanded[index] === 0 ? 1 : 0)
      },

      /**
       * Expand all condition object entries
       */
      toggleAll() {
        this.isValid = false
        this.expanded = Array(this.objectsCopy.length).fill(1)
      },

      /**
       * Opens a dialog to edit a condition group
       * @param groupObject - the group being edited
       */
      onEditConditionGroup(groupObject) {
        this.editObjectDialog({
          object: groupObject,
          /**
           * Callback after the object was edited
           * @param {Object} newObject - the response holding the modified Object
           */
          cb: newObject => (groupObject.PolicyJson = newObject.PolicyJson),
        })
      },
    },
  }
</script>
