// application categories criteria with value as the key, and translation key as the value
export const applicationCategories = {
  'Collaboration': 'collaboration',
  'Database': 'database',
  'File Transfer': 'file_transfer',
  'Games': 'games',
  'Mail': 'mail',
  'Messaging': 'messaging',
  'Network Monitoring': 'network_monitoring',
  'Networking': 'networking',
  'Proxy': 'proxy',
  'Remote Access': 'remote_access',
  'Social Networking': 'social_networking',
  'Streaming Media': 'streaming_media',
  'VPN and Tunneling': 'vpn_and_tunneling',
  'Web Services': 'web_services',
}

/**
 * Value mapping for ApplicationLevel criteria component.
 * The key is the criteria value and the value is the translation key.
 */
export const applicationLevels = {
  1: 'very_low',
  2: 'low',
  3: 'medium',
  4: 'high',
  5: 'very_high',
}

// application names criteria
export const applicationNames = [
  '[24]7 Inc.',
  '050Plus',
  '12306.cn',
  '123movies',
  '126.com',
  '17173.com',
  '1fichier',
  '2345.com',
  '24/7 Media',
  '2channel',
  '33Across',
  '360 AntiVirus',
  '39.net',
  '3COM-TSMUX',
  '3PC',
  '4399.com',
  '4chan',
  '4Shared',
  '51.com',
  '56.com',
  '58.com.cn',
  '914CG',
  '9GAG',
  'about.com',
  'ABS-CBN',
  'ACA Services',
  'accuweather.com',
  'AcFun',
  'achetezfacile.com',
  'ACI',
  'ACR-NEMA',
  'Active Directory',
  'ActiveSync',
  'Ad Analytics',
  'AD Backup',
  'AD DRS',
  'AD DSAOP',
  'AD DSROL',
  'AD File Replication',
  'AD NSP',
  'Ad Redirector',
  'AD Restore',
  'AD XDS',
  'AD-X Tracking',
  'Ad4mat',
  'Adcash.com',
  'Adconion',
  'Addictive Mobility',
  'AddThis',
  'Adf.ly',
  'adfonic',
  'adgear',
  'Adify',
  'AdJuggler',
  'AdMarvel',
  'AdMaster',
  'Admeld',
  'Admeta',
  'Admin5',
  'AdMob',
  'adnStream',
  'Ado Tube',
  'Adobe',
  'Adobe Analytics',
  'Adobe Creative Cloud',
  'Adobe Flash',
  'Adobe font',
  'Adometry',
  'ADP GlobalView',
  'ADP LLC sites',
  'ADP Resource',
  'ADP Streamline',
  'ADP TotalSource',
  'ADP Vantage',
  'ADP Workforce Now',
  'Adready',
  'Adrive.com',
  'AdRoll',
  'Adsage',
  'AdSame',
  'ADTECH',
  'Adtegrity',
  'adultfriendfinder',
  'adultworld',
  'AdvancedHosters CDN',
  'Advertising',
  'AdXpose',
  'AED512',
  'AFP',
  'AfreecaTV',
  'After School',
  'Aggregate Knowledge',
  'AH',
  'AIM',
  'Airbnb',
  'Aizhan.com',
  'Akamai',
  'Akamai NetSession',
  'Albawaba',
  'Alias',
  'Alibaba',
  'Alipay.com',
  'Aliwangwang',
  'Aliyun.com',
  'Allegro.pl',
  'Amazon',
  'Amazon instant video',
  'Amazon Unbox',
  'Amazon Web Services',
  'ameba',
  'American Express',
  'Ammyy Admin',
  'Amobee',
  'Amp',
  'AMQP',
  'AN',
  'Ancestry',
  'Android',
  'ANET',
  'Anghami',
  'ANSA Notify',
  'ANSA REX Trader',
  'Answers.com',
  'AnyConnect',
  'Anydesk',
  'AOL',
  'AOL Ads',
  'APNS',
  'AppGlu',
  'Appier',
  'Apple',
  'Apple ARP',
  'Apple Maps',
  'Apple Music',
  'Apple TV Plus',
  'Apple Update',
  'Applejuice',
  'Applejuice GUI',
  'AppleShare',
  'AppleTalk',
  'AppNeta',
  'AppNeta Delivery',
  'AppNeta PathTest',
  'AppNexus',
  'Aptean',
  'ARCISDMS',
  'Ares',
  'Ariel',
  'ARIS',
  'ARNS',
  'ARP',
  'ASA',
  'Asana',
  'Ask.com',
  'Ask.fm',
  'asos',
  'Astraweb',
  'AT&T',
  'Atlas Solutions',
  'Atlassian',
  'ATM FATE',
  'ATM MPOA',
  'Atom',
  'AudienceScience',
  'AUDIT',
  'Auditd',
  'Aurora',
  'Autodesk',
  'Autohome.com.cn',
  'Avast.com',
  'Avaya',
  'Avaya Audio',
  'Avaya Scopia',
  'Avaya Video',
  'AVG',
  'Avira',
  'Avito',
  'Avocarrot',
  'Avocent',
  'Avoidr',
  'AX25',
  'Azar Live',
  'AZLyrics',
  'Azure',
  'Azure Service Bus',
  'Azurecom',
  'Babylon',
  'Babytree',
  'BackBlaze',
  'badoo',
  'Baidu',
  'Baidu Yun',
  'BaiduHi',
  'Baike.com',
  'Ballina Beach Village',
  'Bank of America',
  'Barnes&Noble',
  'Battle.net',
  'Battle.net Game',
  'Battle.net Launcher',
  'Battle.net Website',
  'Baydin Inc.',
  'Bazaar Voice',
  'BBC iPlayer',
  'bbc.co.uk',
  'BBNRCC',
  'Bebo',
  'beeg',
  'BEETPH',
  'Behance',
  'Benefitfocus',
  'Best Arabic Games',
  'Best Buy',
  'Bet365',
  'Betclic',
  'Betternet',
  'Beweb',
  'BFTP',
  'BGMP',
  'BGP',
  'BH611',
  'BHEVENT',
  'BHFHS',
  'BHMDS',
  'BigUpload',
  'Bild.de',
  'Bilibili',
  'BillDesk',
  'Bing',
  'Bing Maps',
  'Bingbot',
  'BitAuto',
  'Bitcasa',
  'BitDefender',
  'Bitly',
  'BITS',
  'Bittorrent',
  'BittorrentWeb',
  'bizo.com',
  'BJNP',
  'BJNP Discovery',
  'Blackbaud',
  'Blackboard',
  'Blackjack',
  'Blast',
  'Blasting News',
  'BlazeFS',
  'BLIDM',
  'Blizzard client',
  'Blizzard downloader',
  'Blizzard Game Data',
  'Blizzard.com website',
  'Blog.jp',
  'BlogFa',
  'Blogger',
  'Blokus',
  'Bloomberg',
  'BlueJeans',
  'Bluekai',
  'BlueLithium',
  'BNA',
  'BNet',
  'BongaCams',
  'Bonjour',
  'Booking.com',
  'Boom Beach',
  'Boomerang for Gmail',
  'Box.net',
  'Boxcar.io',
  'Brave Browser',
  'bravotube',
  'BRCDN',
  'Bright Roll',
  'Brighttalk',
  'brilig',
  'Brothersoft.com',
  'Browsec',
  'BRSATMON',
  'BRSRVR',
  'Bukalapak',
  'Business Insider',
  'BusinessObjects BI Edge',
  'BuzzFeed',
  'BuzzHand',
  'BV! Media',
  'C2DM',
  'C3 Metrics',
  'CA Certificate',
  'Cableport AX',
  'Caijing',
  'CAIlic',
  'cam4',
  'Canva',
  'CAP',
  'Capital One',
  'Captive Network Assistance',
  'CAPWAP',
  'Caraytech',
  'Carbonite',
  'Casale',
  'Catholic Education',
  'cbs.com',
  'cbsinteractive.com',
  'CBT',
  'CCP',
  'CCP Games',
  'CCTV.com',
  'CDC',
  'Cdn13.com',
  'Cedexis',
  'Cerner Corporation',
  'CFDPTKT',
  'CFTP',
  'Chango',
  'Channel4',
  'CHAOS',
  'CHAP',
  'Chargen',
  'Chart Beat',
  'Chase Bank',
  'Chaturbate',
  'China News',
  'china.com',
  'Chinaren.com',
  'chinauma',
  'Ci123',
  'CIFS',
  'CimaClub',
  'Circuit',
  'Cisco DRP',
  'Cisco FNATIVE',
  'Cisco GDP',
  'Cisco SLA',
  'Cisco SYSMAINT',
  'Cisco TelePresence',
  'Cisco TNATIVE',
  'CISCOUC',
  'CISUCAUD',
  'CISUCVID',
  'Citibank',
  'Citrix CGP',
  'Citrix GoToMeeting',
  'Citrix GoToMyPC',
  'Citrix GoToTraining',
  'Citrix GoToWebinar',
  'Citrix ICA',
  'Citrix IMA',
  'Citrix Jedi',
  'Citrix Licensing',
  'Citrix Online',
  'Citrix RTMP',
  'Citrix ShareFile',
  'Citrix SLG',
  'Citrix WANScaler',
  'CK101',
  'CL1',
  'Clarizen',
  'Clash of Clans',
  'Clash Royale',
  'Classmates',
  'CLDAP',
  'Clearcase',
  'Clickbooth',
  'Clicks Gear',
  'ClickTale',
  'Clip Converter',
  'CLOANTO',
  'Cloud VPN',
  'CloudApp',
  'CloudFlare',
  'Cloudinary',
  'Cloudnymous',
  'CloudSponge',
  'Clubbox',
  'CMIP',
  'CMTP',
  'CNBC',
  'Cnblogs',
  'CNET',
  'CNET Download',
  'CNN',
  'CNNIC',
  'CNTV',
  'CNZZ',
  'Coc Coc',
  'Coda Auth',
  'Code42',
  'Code42 CrashPlan',
  'Cognitive Match',
  'Commission Junction',
  'Common CDNs',
  'Commvault',
  'COMPAQ',
  'Compete',
  'CompressNET',
  'Compuware',
  'COMSCM',
  'comScore',
  'Concur',
  'Conduit',
  'Connexity',
  'Connextra',
  'Conservative Tribune',
  'Constant Contact',
  'Contextweb',
  'Contnet',
  'Conviva Ads sites',
  'CoolMath Games',
  'Coral CDN',
  'CORBA',
  'Core Audience',
  'corerjd',
  'Cornerstone OnDemand',
  'COSEM',
  'Covia CI',
  'CPHB',
  'CPNX',
  'CPX Interactive',
  'CR List',
  'Craigslist',
  'Crashlytics',
  'Cricbuzz.com',
  'Criteo',
  'Crittercism',
  'Crowd Science',
  'CRTP',
  'CRUDP',
  'Csdn.net',
  'CSISGWP',
  'CSNET-NS',
  'CTF',
  'CVCHOSTD',
  'Cvent, Inc.',
  'cXense',
  'CyberGhost',
  'Cydia',
  'Cyworld',
  'DAAP',
  'Dai Ky Nguyen',
  'Daily Mail',
  'Dailymotion',
  'Dainik Bhaskar',
  'DASP',
  'Datalogix',
  'Datei.to',
  'DATEX-ASN',
  'Daum.net',
  'Daytime',
  'dBase',
  'DC Storm',
  'DCAP',
  'DCCP',
  'DCE/RPC',
  'dcinside.com',
  'DCNMEAS',
  'DCP',
  'DDP',
  'DDX',
  'Dealertrack Tech.',
  'DEC Auth',
  'DEC Debug',
  'declk',
  'DECVMS',
  'Delicious',
  'Dell',
  'Delta Search',
  'Demandbase Marketing',
  'DeNA Comm',
  'DeNA websites',
  'DEOS',
  'DepartApp',
  'Deposit Files',
  'detikcom',
  'Detonate.com',
  'DeviantART',
  'DGP',
  'DHCP',
  'DHCPv6',
  'Diameter',
  'Dictionary.com',
  'Digg',
  'Digikala',
  'Dingit.tv',
  'Diply',
  'Direct',
  'DirectConnect',
  'DirectREV',
  'Discard',
  'Discord',
  'Discover',
  'Disney',
  'Disney Plus',
  'divShare',
  'DIXIE',
  'DLS',
  'DMM',
  'DNA-CML',
  'DNP3',
  'DNS',
  'DNSIX',
  'Docstoc',
  'DocuSign',
  'domaintools.com',
  'doof',
  'Dotomi',
  'DotVPN',
  'Douban',
  'Doubleclick',
  'doublepimp',
  'Douyu',
  'DPSI',
  'Draw.io',
  'Drawbridge',
  'Drift',
  'Dropbox',
  'DSFGW',
  'DSP',
  'DSP3270',
  'DSR',
  'DSSETUP',
  'DTAG',
  'DTK',
  'Dynamic Intranet',
  'Dynamic Logic',
  'DynGate',
  'EarthCam',
  'Eastday',
  'Eastmoney',
  'eBay',
  'eBuddy',
  'eBuddy XMS',
  'Echo',
  'EdgeCast Networks',
  'Editgrid',
  'eDonkey',
  'Effective Measure',
  'EGP',
  'eHow',
  'EIGRP',
  'ekantipur',
  'Eksi sozluk',
  'EL PALS',
  'Elisa Viihde',
  'Elmogaz',
  'EMBLNDT',
  'EMCON',
  'EMFIS',
  'ENCAP',
  'enet.com.cn',
  'Engadget',
  'Engage BDR',
  'eNovance',
  'EntrustTime',
  'Envato',
  'Epic',
  'Epmap',
  'EQ Ads',
  'ERPC',
  'Eset',
  'ESP',
  'ESPN',
  'ESRO',
  'etao.com',
  'ETH',
  'ETHERIP',
  'EtherNet/IP',
  'ETOS',
  'Etsy',
  'ETtoday',
  'Eve Online',
  'Evernote',
  'Evidon',
  'Evony',
  'Exchange',
  'Exchange Online',
  'eXelate Media',
  'ExoClick',
  'Expedia',
  'Exponential Interactive',
  'ExpressVPN',
  'Extratorrent.com',
  'extremetube',
  'eyeReturn',
  'F-Prot',
  'Facebook',
  'Facebook Ackit',
  'Facebook Apps',
  'Facebook Event',
  'Facebook Messages',
  'Facebook Post',
  'Facebook Search',
  'Facebook Video',
  'Facebook Video Chat',
  'Facebook VoIP',
  'FaceTime',
  'Farmville',
  'FASP',
  'Fatmen',
  'FC',
  'FC2.com',
  'Federated Media',
  'FedEx',
  'feedly',
  'Fidelity Investments',
  'Fiesta',
  'FileMaker',
  'Filer.cx',
  'FileServe',
  'Filesonic',
  'FilesTube',
  'Finger',
  'fingta',
  'FinTS',
  'FIRE',
  'FireFLy Education',
  'Fiserv',
  'Fiverr',
  'FIX',
  'Flickr',
  'Flightradar24',
  'fling',
  'Flipkart',
  'Flixster',
  'Fluent',
  'Flurry',
  'Fogbugz',
  'Font Awesome',
  'Forbes',
  'foursquare',
  'Fox News',
  'FOX Sports',
  'Free',
  'freeetv',
  'Freegate',
  'Freelancer',
  'Freenet',
  'freeones',
  'Freepik',
  'Freewheel Media',
  'FriendFeed',
  'Friendster',
  'Fring',
  'Fring A/V',
  'FRIV',
  'Frozenway',
  'FTP',
  'FTP Control',
  'FTP Data',
  'FTPS',
  'FTPSDATA',
  'Funshion',
  'Funshion Video',
  'Fuze',
  'FXP',
  'GACP',
  'Gamer.com.tw',
  'GAMERSKY',
  'Ganji',
  'Gantter',
  'Gap',
  'GearBest',
  'Genesis PPP',
  'Genie',
  'GENRAD',
  'Gfycat',
  'GG',
  'GG Media',
  'GGP',
  'Ghaneely',
  'Giganews',
  'GIPHY',
  'GISMETEO',
  'GIST',
  'GitHub',
  'Gizmodo',
  'globo',
  'Glype Proxy',
  'gmail',
  'GMTP',
  'GMW.cn',
  'GMX',
  'Gnutella',
  'goal.com',
  'GoGoBox',
  'GOM Remote',
  'GOMTV.com',
  'GOMTV.net',
  'goo.ne.jp',
  'Goodreads',
  'Google',
  'Google +',
  'Google Ads',
  'Google Analytics',
  'Google APIs',
  'Google App Engine',
  'Google Calendar',
  'Google Cloud Messaging',
  'Google Desktop',
  'Google Drive',
  'Google Earth',
  'Google Hangouts',
  'Google Helpouts',
  'Google Maps',
  'Google Play',
  'Google Safe',
  'Google Sites',
  'Google Talk',
  'Google Translate',
  'Google Video',
  'Google zip',
  'Googlebot',
  'GoogleDuo',
  'GOOSE',
  'Gopher',
  'Gothere.sg',
  'GoToMeeting',
  'GPFS',
  'Grammerly',
  'GRE',
  'Gree games',
  'Grooveshark',
  'Groupon',
  'Groupwise',
  'GSE',
  'GSIFTP',
  'GSMArena',
  'Gss License',
  'GSUITE',
  'GTalk Audio',
  'GTalk File Transfer',
  'GTalk Gadget',
  'GTalk Video',
  'GTP Control',
  'GTP Prime',
  'GTP User',
  'Gulf Times',
  'GungHo',
  'H.225',
  'H.245',
  'H.245 audio',
  'H.248',
  'H.323',
  'H&M',
  'Haber7',
  'Hamachi',
  'HandyCafe',
  'Hao123.com',
  'hardsextube',
  'Hassle',
  'Hatena Blog',
  'HBO Nordic',
  'HDAP',
  'HDFC Bank',
  'HEMS',
  'hi5',
  'Hidemevpn',
  'HideMyIp',
  'Hightail',
  'HIP',
  'HiveStor',
  'HL7',
  'HMP',
  'Hola',
  'HOLA.com',
  'Honey',
  'HootSuite',
  'Hopster',
  'Hostname',
  'Hotfile',
  'Hotspot Shield',
  'hotstar',
  'HowardForums',
  'Hoxxvpn',
  'HP Perf',
  'HP VMM',
  'HP Website',
  'HSRP',
  'HTFacile',
  'HTTP',
  'HTTP 2.0',
  'HTTP AUDIO',
  'HTTP Proxy',
  'HTTP Tunnel',
  'HTTPMGT',
  'HTTPS',
  'Huanqiu',
  'HubPages',
  'HubSpot',
  'Huffington Post',
  'Hulu',
  'hupu.com',
  'Hurriyet',
  'Hushmail.com',
  'HWCDN',
  'Hyper-G',
  'Hyves',
  'IASD',
  'IATP',
  'IAX2',
  'IBM',
  'IBM APP',
  'IBM DB2',
  'IBM OPC',
  'ibVPN',
  'ICAD',
  'iCall',
  'Icecast',
  'ICICI Bank',
  'iCloud',
  'ICMP',
  'ICMPv6',
  'ICP',
  'ICQ',
  'ICQ file transfer',
  'Ident',
  'IDP',
  'IDPR',
  'IDRP',
  'IEC 60870-5-104',
  'Ifeng.com',
  'ifile.it',
  'iflix',
  'IFMP',
  'IGMP',
  'IGN',
  'IKEA',
  'IL',
  'Image Venue',
  'ImageShack',
  'IMAP',
  'IMDb',
  'iMesh',
  'IMGames',
  'Imgur',
  'imo.im',
  'impress.co.jp',
  'Improve Digital',
  'IMSP',
  'IMzog',
  'In.com',
  'InBusiness',
  'Indeed',
  'Indiatimes',
  'Infonline',
  'InfoSeek',
  'Infostore',
  'Infusionsoft',
  'INGRES-NET',
  'INLSP',
  'Innovation Interactive',
  'InQuest Technologies',
  'Inskin Media',
  'Inspectlet',
  'Instagram',
  'Instagram Images',
  'Instagram Video',
  'Instructure',
  'Integral Ad Science',
  'InterClick',
  'Internet Download Manager',
  'IntraLinks',
  'Intuit Financial',
  'Intuit Mint',
  'Intuit QuickBase',
  'Intuit QuickBooks',
  'Intuit Quicken',
  'Intuit TurboTax',
  'Invisible Internet Protocol',
  'IP',
  'IPComp',
  'IPCP',
  'IPCV',
  'IPerceptions',
  'Iperf',
  'IPFIX',
  'IPIP',
  'IPLT',
  'IPPC',
  'IPsec',
  'IPv6',
  'IPV6CP',
  'IPX',
  'IRC',
  'IRC file transfer',
  'IRCTC',
  'IRTP',
  'IS-99',
  'ISAKMP',
  'ISCHAT',
  'iSCSI',
  'ISI Graphics',
  'ISIS',
  'Island Mob',
  'ISO-TSAP',
  'ISOIP',
  'iStockPhoto',
  'IT168.com',
  'Itsfogo',
  'iTunes',
  'ITV.com',
  'IxChariot',
  'ixxx',
  'Jabong.com',
  'Jango',
  'JARGON',
  'Jaspersoft',
  'Java RMI',
  'Java Update',
  'Jingdong Mall',
  'Jive Cloud VoIP',
  'Jondo',
  'juicyads',
  'Jungle Disk',
  'Kaixin',
  'kakaku',
  'Kakao',
  'Kakao Audio',
  'KapanLagi',
  'KASKUS',
  'Kaspersky',
  'KAT',
  'Kazaa',
  'Kblock',
  'Kerberos',
  'KFTP',
  'KFTPDATA',
  'Kickass Torrents',
  'Kickstarter',
  'Kik Messenger',
  'kinogo',
  'kinopoisk',
  'KIS',
  'KissAnime',
  'KNETCMP',
  "Kohl's",
  'Komli',
  'Kompas.com',
  'Kool IM',
  'Kooora.com',
  'Krux',
  'KRYPTLAN',
  'Ktelnet',
  'Kugou',
  'KVM',
  'KWDB',
  "L'equipe.fr",
  'L2TP',
  'La Repubblica',
  'LA Times',
  'LA-Maint',
  'largeporntube',
  'LARP',
  'Last.fm',
  'LASTPASS',
  'LCP',
  'LDAP',
  'LeadBolt',
  'LeadLander',
  'LEAF1',
  'LEAF2',
  'Leboncoin',
  'Legent',
  'Letitbit',
  'Letv',
  'Level3',
  'Libero.it',
  'LIFE',
  'LifeBuzz',
  'Lifehacker',
  'Ligatus',
  'Likes',
  'Limelight',
  'LINE',
  'LINE Games',
  'LINE Media',
  'Line2',
  'LINK',
  'LinkedIn',
  'LinkVPN',
  'Liputan 6',
  'literotica',
  'Live365',
  'livedoor',
  'LiveJasmin',
  'LiveJournal',
  'Livemeeting',
  'LivePerson',
  'LiveRail',
  'LLMNR',
  'Locus Conn',
  'Locus Map',
  'LogMein',
  'Lokalisten',
  'Lotame',
  'Lotus Notes',
  "Lowe's",
  'LSARPC',
  'LTN',
  'Lucidchart',
  'Luminate',
  'Lyft',
  'Lync',
  'Lync Audio',
  'Lync Control',
  'Lync Media',
  'Lync Share',
  'Lync Video',
  "Macy's",
  'Mafiawars',
  'Magenta Logic',
  'MagicJack',
  'Mail.ru',
  'MailChimp',
  'MAILQ',
  'Mama.cn',
  'MANET',
  'Manolito',
  'Manorama',
  'MAPI',
  'Marca',
  'Marine Traffic',
  'Marketo Ads sites',
  'Mashable',
  'Masqdialer',
  'match.com',
  'Mathrubhumi',
  'MATIP',
  'MawDoo3',
  'MaxDB',
  'MaxPoint Interactive',
  'MC-FTP',
  'McAfee',
  'McIDAS',
  'mck-ivpip',
  'MDNS',
  'MdotM',
  'Media Innovation Group',
  'Media Math',
  'Media6Degrees',
  'MediaFire',
  'Mediamind',
  'Mediaplex',
  'MediaV',
  'Medium',
  'Meebo',
  'Meerkat',
  'Meeting Maker',
  'Meetup',
  'MEGA',
  'Megashares',
  'Melon.com',
  'Mercado Livre',
  'Mercis',
  'merdeka.com',
  'MERIT',
  'Meta5',
  'Metacafe',
  'Metagram',
  'MF Cobol',
  'MFENSP',
  'MFTP',
  'MGCP',
  'Microsoft',
  'Microsoft Dynamics CRM',
  'Microsoft FrontPage',
  'Microsoft OneDrive',
  'Microsoft Store',
  'Microsoft Teams',
  'Microsoft WSD',
  'Microsoft WSMan',
  'Middle East Broadcasting Center',
  'Millennial Media',
  'Milliyet',
  'Minecraft',
  'Mini SQL',
  'MIT Spooler',
  'mit-ml-dev',
  'Mitel Suite',
  'Mixi',
  'Mixpanel',
  'MLB',
  'MLN Advertising',
  'MMS',
  'MMS System',
  'Mobaga Town',
  'MOBILE',
  'mobile theory',
  'mobileCore',
  'MobileIP',
  'Modbus',
  'Mojang',
  'Mojiva',
  'Monetate',
  'Monster VPN',
  'Mop.com',
  'MoPub',
  'morefreecamsecrets',
  'MortgageWare',
  'Motley Fool',
  'Motrixi',
  'movie2k',
  'Mozilla',
  'MPEG Transport Stream',
  'MPLS Multicast',
  'MPLS Unicast',
  'MPLSINIP',
  'MPM',
  'MPP',
  'MPTN',
  'MS CDN',
  'MS CRS',
  'MS Office 365',
  'MS OLAP',
  'MS Online',
  'MS Outlook',
  'MS Power BI',
  'MS SMServer',
  'MS SQL',
  'MSDN',
  'MSG',
  'MSMQ',
  'MSN',
  'MSN2Go',
  'MSNP',
  'MSP',
  'MTA',
  'MTP',
  'MTV',
  'MTV3',
  'Multiplex',
  'multiply.com',
  'Multiupload',
  'MUMPS',
  'musical.ly',
  'MUTE-net',
  'MUX',
  'MUZU.TV',
  'MyBuys',
  'MySpace',
  'MySQL',
  'MyWay',
  'mywebsearch',
  'nabber',
  'nametests.com',
  'NAMP',
  'NARP',
  'Nate Video',
  'Nate.com',
  'NateOn',
  'NateOn File',
  'NateOn Phone',
  'NateOn Remote',
  'National Institutes of Health',
  'Naukri.com',
  'Naver',
  'Naverisk',
  'NBA',
  'NBC',
  'NCED',
  'NCLD',
  'NDS Auth',
  'NDTV',
  'Nelson Net',
  'Net2Phone',
  'Net2Phone Media',
  'NetBIOS DDS',
  'NetBIOS NS',
  'NetBIOS SS',
  'NETBLT',
  'Netease',
  'Netflix Site',
  'Netflix Video Stream',
  'NetFlow',
  'Netinfo',
  'Netlogon',
  'NetMeeting',
  'NETSC',
  'NetScout',
  'NetSeer',
  'NetSuite',
  'Netware',
  'Netweaver',
  'New Relic',
  'newegg.com',
  'news.com.au',
  'Nexage',
  'NFA',
  'NFL.com',
  'NFS',
  'NI FTP',
  'NI Mail',
  'Niantic Labs',
  'niconico',
  'niconico Live',
  'Nielsen',
  'Nimbuzz',
  'Nimbuzz MMS',
  'Nimbuzz World',
  'Ninite',
  'Nintendo',
  'NIP',
  'NNSP',
  'NNTP',
  'Nokia',
  'Nokia Maps',
  'Nokia Message',
  'Nokia Music',
  'Nokia Store',
  'Nokia Sync',
  'NoteFlight',
  'NovaBACKUP',
  'NOWnews',
  'nPario',
  'NPP',
  'NrData',
  'NSFNETIG',
  'NSIIOPS',
  'NSRMP',
  'NSS',
  'NSSTP',
  'NTD TV',
  'NTP',
  'Nugg',
  'nur.kz',
  'NVPII',
  'NXEdit',
  'NXTSTEP',
  'Nyaa Torrents',
  'Nytimes.com',
  'OCBinder',
  'OCS',
  'OCServer',
  'OCSP',
  'ODMR',
  'Odnoklassniki.ru',
  'OfferJuice',
  'Offline Maps Pro',
  'OFTP',
  'OFTPS',
  'Ohana',
  'Okezone',
  'Olive Media',
  'OLX.pl',
  'Oman Airways',
  'On click',
  'Onavo',
  'Onavo Count',
  'Onavo Extend',
  'onedio',
  'Onet',
  'Online File Folder',
  'Onmux',
  'Onshape',
  'onTests.Me',
  'ooVoo',
  'Ooyala',
  'Opalis Robot',
  'OPC UA',
  'Open Webmail',
  'OpenCandy',
  'OPENLOAD',
  'Openport',
  'OpenVPN',
  'OpenX',
  'Operavpn',
  'Optimax',
  'Optimizely',
  'Oracle',
  'Oracle CRMOD',
  'Oracle Marketing Cloud',
  'Oracle SQLNET',
  'Oracle TNS',
  'oracle.com',
  'Orange',
  'Orkut',
  'OSCAR',
  'OSPF',
  'OSUNMS',
  'Othervpn',
  'ouo.io',
  'Outbrain',
  'OwnerIQ',
  'Ozock',
  'Paltalk Chat',
  'Paltalk File Transfer',
  'Paltalk Messenger',
  'Paltalk Video',
  'Paltalk Voice',
  'Panda',
  'Panda TV',
  'Pando',
  'Pandora',
  'Pandora Audio',
  'Pandora.tv',
  'PAP',
  'PAWSERV',
  'PayPal.com',
  'Paytm',
  'pcAnywhere',
  'pchome.net',
  'PCMAIL',
  'PCoIP',
  'PDAP',
  'PDbox',
  'PDbox P2P',
  'Periscope',
  'PersonalLink',
  'PFTP',
  'PGM',
  'Photobucket',
  'PHP',
  'Picasa',
  'Piksel',
  'PIM',
  'Pinger',
  'Pinterest',
  'PIP',
  'PIPE',
  'Pixabay',
  'Pixiv',
  'Pixnet',
  'PKIX Timestamp',
  'Plaxo',
  'Playstation Network',
  'Playstation Website',
  'Plista',
  'PNNI',
  'Pogo.com',
  'Pokemon Go',
  'POLITICO',
  'Polldaddy',
  'POP2',
  'POP3',
  'PopAds',
  'PopCash',
  'Pornhub',
  'PORNVIDEOS',
  'PostgreSQL',
  'Ppomppu',
  'PPP',
  'PPP Discovery',
  'PPP Session',
  'PPPCOMP',
  'PPStream',
  'PPTP',
  'PPTV',
  'PPTV P2P',
  'Prezi',
  'Princess Polly',
  'Printer',
  'Printer Job Language',
  'PRINTSRV',
  'PrivateHomeClips',
  'Privax',
  'PRM',
  'Proclivity',
  'Prodigy Games',
  'PROFILE',
  'PROSPERO',
  'Proxistore',
  'PS3 Game',
  'PS3 Match',
  'Psiphon',
  'PTP',
  'PubMatic',
  'PubNub',
  'PUP',
  'Purevpn',
  'Putlocker',
  'PVP',
  'PWDGEN',
  'Qatar Airways',
  'Qatar Government',
  'Qatar Living',
  'Qatar Ministry of Interior',
  'Qatar University',
  'Qbik',
  'QFT',
  'Qiita',
  'QlikView',
  'QMTP',
  'QNX',
  'QOTD',
  'QQ',
  'QQ Game',
  'QQ Mail',
  'QQ Music',
  'QQ Video',
  'Quake Live',
  'quant code',
  'Quantcast',
  'Quic',
  'Quicktime',
  'Quizlet',
  'Quora',
  'Quote.com',
  'Rackspace',
  'RadiumOne',
  'RADIUS',
  'RADIUS-ACCT',
  'Raging Bull',
  'Rakuten',
  'Rambler.ru',
  'RAP',
  'Rapidgator',
  'RapidShare',
  'Rapleaf',
  'Rapportive',
  'RarBG',
  'RARP',
  'Rdio',
  'RDP',
  'RDT',
  'Real Player Cloud',
  'Realtor',
  'Reddit',
  'rediff',
  'RedTube',
  'redux media',
  'Reimage',
  'REMAIL',
  'Remote Job Service',
  'Remote Telnet',
  'Renren',
  'ResCap',
  'ResearchGate',
  'Resonate Networks',
  'Reuters',
  'RFR',
  'Rhapsody',
  'Rich Relevance',
  'RingCentral',
  'RIP',
  'RIPNG',
  'RIS',
  'RJE',
  'rlogin',
  'RLP',
  'RMT',
  'ROBLOX',
  'Rocket Fuel',
  'ROHC',
  'Roku',
  'RPC2PMAP',
  'RRP',
  'RSH',
  'RSS',
  'RSVD',
  'RSVP',
  'RSVP Tunnel',
  'RSVPE2EI',
  'Rsync',
  'RT',
  'RTCP',
  'RTMP',
  'RTP',
  'RTP Audio',
  'RTP Video',
  'RTSP',
  'RTSPS',
  'Rubicon Project',
  'RUDP',
  'Ruten',
  'RuTracker.org',
  'RVD',
  'Sabah.com.tr',
  'Sahibinden',
  'Salesforce',
  'SAMR',
  'Samsung',
  'SAP',
  'SAP protocol',
  'SASCDN',
  'SATEXPAK',
  'SATMON',
  'SaveFrom.net',
  'Sberbank of Russia',
  'SBNTBCST',
  'SCCM',
  'SCCM Remote Control',
  'SCCP',
  'SCCSP',
  'Schmedley',
  'ScienceDirect',
  'SCOI2DLG',
  'Scopia audio',
  'Scopia video',
  'Scorecard Research',
  'SCPS',
  'Scribd',
  'Scribol',
  'SCSI-ST',
  'SCTP',
  'SDRP',
  'Sears',
  'Seasonvar',
  'Secure AMQP',
  'Secure RTCP',
  'Secured WSD',
  'SecurityKISS',
  'SecurSight',
  'Semantix',
  'SEND',
  'Sendspace',
  'ServiceNow',
  'SET',
  'Seznam',
  'SFTP',
  'SGCP',
  'SGMP',
  'Share P2P',
  'Share This',
  'Shareman',
  'Sharepoint',
  'Sharepoint Online',
  'SHAZAM',
  'Shockwave',
  'Shopify',
  'shorte.st',
  'SHOUTcast',
  'ShowMyPC',
  'Shrinkwrap',
  'Shutterfly',
  'Shutterstock',
  'Silverlight',
  'Silverplatter',
  'Silverpop',
  'Sina',
  'Sina Video',
  'SIP',
  'Siri',
  'SiteAdvisor',
  'Siteimprove',
  'SiteScout',
  'six apart',
  'Skimlinks',
  'SKIP',
  'Sky Go',
  'Sky Mail',
  'SkyDrive',
  'Skype',
  'Skype Auth',
  'Skype Click to Call',
  'Skype Ctrl',
  'Skype File Transfer',
  'Skype for business',
  'Skype Message',
  'Skype Out',
  'Skype p2p',
  'Skype Photo Sharing',
  'Skype Probe',
  'Skype Video',
  'Skype Video Message',
  'Skype Voice',
  'skyZIP',
  'Slack',
  'SLI Systems',
  'Slickdeals',
  'SlideRocket',
  'SlideShare',
  'Slingbox',
  'Slingmedia',
  'slither.io',
  'SLOW',
  'SMAKYNET',
  'Smart AdServer',
  'Smart SDP',
  'Smartapp',
  'Smartfox',
  'SMP',
  'SMPTE',
  'SMSP',
  'SMTP',
  'SMUX',
  'SNA Gateway',
  'Snapchat',
  'Snapdeal',
  'Snapmirror',
  'SNET',
  'SNMP',
  'SNP',
  'SNPP',
  'SO.com',
  'SOAP',
  'SOCKS',
  'SoftEther',
  'Softonic',
  'SoftPC',
  'Softpedia',
  'Sogou',
  'Sohu',
  'Soku.com',
  'Sony Liv',
  'Sopcast',
  'Sophos',
  'Sophos RED',
  'Soso.com',
  'SoundCloud',
  'SoundHound',
  'Souq',
  'Sourceforge',
  'Southwest Airlines',
  'SpankBang',
  'spankwire',
  'SPC Media',
  'speedtest.net',
  'Speedy',
  'SpiderOak',
  'Spiegel Online',
  'Spinrilla',
  'Spooler Subsystem',
  'Sports Illustrated',
  'Spotflux',
  'Spotify',
  'SpotXchange',
  'SPRITE',
  'SPS',
  'SQL Services',
  'Squidoo',
  'SRC',
  'SRMP',
  'SRP',
  'SRS Send',
  'SRTP',
  'SRTP Audio',
  'SRTP Video',
  'SSCOPMCE',
  'SSDP',
  'SSH',
  'SSL',
  'ST',
  'Stack Exchange Network',
  'Statcounter',
  'State Bank of India',
  'STATSRV',
  'Steam',
  'Steam Client',
  'Steam DLC',
  'Steam Game',
  'Steam Social',
  'Stile',
  'Store Admin',
  'Storehouse',
  'STP',
  'streamate',
  'Stripe',
  'StumbleUpon',
  'STUN',
  'Su-Mit Telnet',
  'Subscene',
  'SuccessFactors',
  'SugarSync',
  'Sun RPC',
  'Suning.com',
  'SUNND',
  'SUPDUP',
  'Super Mario Run',
  'Supercell',
  'SuperNews',
  'SuperVPN',
  'SureSome',
  'Surfeasyvpn',
  'surikate',
  'SURMEAS',
  'Surrogafier',
  'SurveyMonkey',
  'SVMTP',
  'SVN',
  'SVRLOC',
  'Swagbucks',
  'Swift RVFP',
  'Sybase SQL',
  'Symantec',
  'Symantec LiveUpdate',
  'Syncplicity',
  'SynOptics',
  'Sysatt',
  'Syslog',
  'Systat',
  'T-Online',
  'T.120',
  'Tabelog',
  'Tableau',
  'Taboola',
  'TAC News',
  'TACACS',
  'TAFE NSW',
  'Tagged',
  'Taleo',
  'Tamil Rockers',
  'Tango',
  'Taobao',
  'Tappx',
  'Target',
  'TARGUSinfo',
  'Taringa',
  'TCF',
  'TCP',
  'TCPMUX',
  'TCX Flash',
  'TCX Multimedia',
  'TCX Sound',
  'TCX USB',
  'TDS',
  'TeacherTube',
  'TeamViewer',
  'TechCrunch',
  'TechInline',
  'TEEPR',
  'Telecom Express',
  'Telegram',
  'Telegraph',
  'Telemetry',
  'Telly',
  'Telnet',
  'Tencent',
  'Tencent Cloud',
  'Tencent E-Commerce',
  'Tencent Weiyun',
  'Tenpay',
  'Teracent',
  'Teredo',
  'Texar',
  'TFTP',
  'TFTPS',
  'That Viral Feed',
  'The Free Dictionary',
  'the guardian',
  'The Home Depot',
  'The Independent',
  'The Internet Archive',
  'The LAD Bible',
  'The Pirate Bay',
  'The Trade Desk',
  'The Verge',
  'The Wall Street Journal',
  'Theme Forest',
  'TheSPORTbible',
  'Tianya Club',
  'tibco.com',
  'Ticketmaster',
  'Tidal',
  'TidalTv',
  'TikTok',
  'Timbuktu',
  'Time',
  'Tistory',
  'Tivoli',
  'TLSP',
  'TLV Media',
  'Tmall.com',
  'Tmobile',
  'TN3270',
  'Tobit',
  'Tokbox',
  'Tokopedia',
  'Tor',
  'Tor Directory Services',
  'torrentz.eu',
  'TP',
  'TPPP',
  'TRACEROUTE',
  'Trello',
  'Tribal Fusion',
  'Tribunnews',
  'Triggit',
  'TripAdvisor',
  'TripIt',
  'Tripwire',
  'Tritone Hosting',
  'TRUNK1',
  'TRUNK2',
  'TTP',
  'Tube8',
  'TubeMogul',
  'TubeREL',
  'Tudou',
  'Tumblr',
  'TuneIn Radio',
  'TunnelBear',
  'Turbo VPN',
  'Turn',
  'TweetDeck',
  'TWILIO',
  'Twinkl',
  'TWITCH',
  'TwitPic',
  'Twitter',
  'TXXX',
  'U.S.State',
  'UAAC',
  'UARPS',
  'UBER',
  'Udemy',
  'UDN',
  'UDP',
  'UDPLITE',
  'UEFA',
  'UIS',
  'ULSTPROC',
  'Ultimate Software',
  'Ultrasurf',
  'UltraViolet',
  'UMA',
  'Undertone',
  'Unidata LDM',
  'UNIFY',
  'United States Postal Service',
  'UOL',
  'Upfront',
  'Uploaded',
  'Uploading.com',
  'Upornia',
  'uProxy',
  'UPS',
  'ups.com',
  'Uptodown',
  'Upwork',
  'UrduPoint.com',
  'USA Today',
  'USAA',
  'USAIP',
  'Usenet',
  'UStream',
  'UTI',
  'UTMP',
  'UTorrent',
  'UUCP',
  'UULA',
  'Vagaa',
  'ValueClick Media',
  'Varzesh3',
  'Vchat',
  'Veeva CRM',
  'Verizon Wireless',
  'vettcp',
  'Viaplay',
  'Viber',
  'Vibrant',
  'Vice',
  'Viddler',
  'videobb',
  'Videomega.tv',
  'videoplaza',
  'videosexarchive',
  'VIEWON',
  'Viewsurf',
  'Vimeo',
  'Vine',
  'VINES',
  'Viral Thread',
  'Virtual Extensible LAN',
  'VISA',
  'VKontakte',
  'VMNET',
  'VMPWSCS',
  'VMTP',
  'VMware',
  'VMware View',
  'VMware vMotion',
  'VMware vSphere',
  'VNC',
  'Voice Five',
  'Vonage',
  'VPN Master',
  'VPN Monster',
  'VPNReactor',
  'VRRP',
  'VSLMP',
  'W3Schools',
  'Walkme',
  'Walmart',
  'Warlight',
  'Warrior Forum',
  'Washington Post',
  'Watan',
  'watchmygf',
  'Waze',
  'WBEXPAK',
  'WBMON',
  'WCCP',
  'Weather Channel',
  'Weather Underground',
  'Web Services Discovery',
  'WEB.DE',
  'WebDAV',
  'WebEx',
  'WebEx Media',
  'WebEx Sharing',
  'WebFilter',
  'weblio',
  'WebMD',
  'Weborama',
  'WebRTC',
  'Webs.com',
  'WebSocket',
  'Webtrends',
  'Webtretho',
  'WeChat',
  'WeChat Heart Beat',
  'WeChat Login',
  'WeChat Media',
  'WeChat Message',
  'WeChat Update',
  'Weebly',
  'Weibo.com',
  'Wells Fargo',
  'WESP',
  'Western Journalism',
  'wetpaint',
  'WeTransfer',
  'WhatsApp',
  'WhatsApp Location',
  'WhatsApp Media Message',
  'WhatsApp Voice Calling',
  'Whois',
  'Wifi Calling',
  'Wikia',
  'wikidot',
  'wikiHow',
  'Wikipedia',
  'Wikispaces',
  'Windows Live',
  'Windows Media',
  'Windows Phone',
  'Windows Update',
  'Windscribe',
  'WinMX',
  'Winny',
  'WINS',
  'Wirtualna Polska',
  'WittyFeed',
  'WIX',
  'Woolik',
  'Word Reference',
  'Wordpress',
  'Workday',
  'Workplace',
  'World Of Warcraft',
  'Wowhead',
  'Wretch',
  'WSDL Event Receiver',
  'WSN',
  'WSP',
  'Wyse TCX',
  'X Plus One',
  'X Window System',
  'X.25',
  'Xanga',
  'Xaxis',
  'Xbone',
  'Xbox',
  'Xbox Live',
  'XCAR',
  'XDA-Developers',
  'XDMCP',
  'Xfer',
  'Xfinity',
  'xHamster',
  'Xiaomi',
  'XING',
  'Xinhua',
  'XiTi',
  'XMPP',
  'XMPP File Transfer',
  'XNET',
  'XNS',
  'XNS Authentication',
  'XNS Clearinghouse',
  'XNS Mail',
  'XNS Time',
  'XNXX',
  'XTP',
  'Xunlei',
  'XVIDEOS',
  'XVPN',
  'Xyplex',
  'XYWY.COM',
  'Y8.com',
  'Yabuka',
  'Yahoo',
  'Yahoo Messenger',
  'Yahoo Slurp',
  'Yahoo video',
  'Yahoo Web Analytics',
  'Yammer',
  'Yandex',
  'Yandex AppMetrica',
  'Yandex Disk',
  'Yandex Images',
  'Yandex Mail',
  'Yandex Maps',
  'Yandex Market',
  'Yandex Money',
  'Yandex Music',
  'Yandex Translate',
  'Yandex Video',
  'Ybrant Digital',
  'Yelp',
  'Yemonisoni',
  'Yesky',
  'YieldManager',
  'Yik Yak',
  'YiXin',
  'Yle Areena',
  'YMSG Audio',
  'YMSG Chat',
  'YMSG File Transfer',
  'YMSG SMS',
  'YMSG Video',
  'ynews',
  'Youdao',
  'youjizz',
  'Youku',
  'Youm7',
  'YouPorn',
  'youporngay',
  'YouSendIt',
  'Youth.cn',
  'YouTube',
  'YouTubemp3',
  'Z3950',
  'Zabbix',
  'ZanNet',
  'Zanox',
  'Zebra',
  'Zedo',
  'Zendesk',
  'Zenmate VPN',
  'ZenVPN',
  'Zero VPN',
  'Zerodha',
  'Zhihu',
  'Zillow',
  'Zippyshare',
  'Zoho',
  'Zol.com.cn',
  'Zomato',
  'Zoom',
  'ZRTP',
  'Zscaler',
  'Zynga',
  'Zynga Poker',
]

// protocols with the value as the key, and translation key as the value
export const protocols = {
  0: 'custom',
  21: 'FTP (21)',
  22: 'SSH  (22)',
  '25,465,587': 'SMTP (25, 465, 587)',
  53: 'DNS (53)',
  80: 'HTTP (80)',
  443: 'HTTPS (443)',
  '5060,5061': 'SIP (5060, 5061)',
}

/**
 * Mapping for criteria types when adding a wan rule.  The key is the name in the backend.
 *
 * textKey:  translation key for displaying the criteria name.
 * ops:  possible operations for the operator drop down.
 * field: 'text or 'select' for the value field
 * values: if using a select field, this holds the drop down values as an array of object of value for the key
 *         and translation key for value
 * rules: the validation rules on the 'value' field.
 * labelKey: used as the label prop on the value field, otherwise the label 'Value' is used by default
 *
 */
export const wanCriteriaTypes = {
  'APPLICATION_NAME_INFERRED': {
    textKey: 'application_name',
    ops: ['=='],
    field: 'autocomplete',
    values: applicationNames,
    rules: 'required',
  },
  'APPLICATION_CATEGORY_INFERRED': {
    textKey: 'application_cat',
    ops: ['=='],
    field: 'select',
    values: applicationCategories,
    rules: 'required',
  },
  'APPLICATION_RISK_INFERRED': {
    textKey: 'application_risk',
    ops: ['==', '!=', '>', '<', '>=', '<='],
    field: 'select',
    values: applicationLevels,
    rules: 'required',
  },
  'APPLICATION_PRODUCTIVITY_INFERRED': {
    textKey: 'application_productivity',
    ops: ['==', '!=', '>', '<', '>=', '<='],
    field: 'select',
    values: applicationLevels,
    rules: 'required',
  },
  'SOURCE_INTERFACE_NAME': {
    textKey: 'source_intf_name',
    ops: ['==', '!='],
    field: 'text',
    rules: 'required',
  },
  'DESTINATION_ADDRESS': {
    textKey: 'destination_address',
    ops: ['==', '!='],
    field: 'text',
    rules: 'required|ip_any_expression_cidr_list',
    labelKey: 'ip_address_hint',
  },
  'DESTINATION_PORT': {
    textKey: 'destination_port',
    ops: ['==', '!='],
    field: 'select',
    values: protocols,
    rules: 'required',
  },
  'SERVER_DNS_HINT': {
    textKey: 'server_dns_hint',
    ops: ['==', '!='],
    field: 'text',
    rules: 'required',
  },
}

// policies a WAN rule may have with the value as the key, and translation key as the value
export const wanPolicies = {
  100001: 'lowest_latency_any_wan',
  100002: 'most_bandwidth_any_wan',
  100003: 'lowest_jitter',
  100004: 'lowest_latency_non_lte',
  100005: 'most_bandwidth_non_lte',
  100006: 'lowest_jitter_non_lte',
  100007: 'specific_wan0',
  100008: 'specific_wan1',
  100009: 'specific_lte',
}
