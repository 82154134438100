<!--
  Simple Country/State selection based on v-model object
  having 'country' and 'state' props
-->
<template>
  <div class="d-flex flex-row">
    <!-- country selector -->
    <ValidationProvider v-slot="{ errors }" :rules="!disabled ? 'required' : ''">
      <u-select
        :value="value.country"
        :items="countries"
        item-text="Name"
        item-value="Code"
        :label="$t('country')"
        class="mb-4 mr-4"
        :disabled="disabled || countriesFetching"
        :placeholder="countriesFetching ? 'Fetching countries...' : ''"
        :error-messages="errors"
        @input="updateCountry"
      >
        <template v-if="errors.length" #append>
          <u-errors-tooltip :errors="errors" />
        </template>
      </u-select>
    </ValidationProvider>

    <!-- state selector -->
    <ValidationProvider v-slot="{ errors }" :rules="!disabled && states.length ? 'required' : ''">
      <u-select
        v-if="states.length"
        :value="value.state"
        :items="states"
        item-text="Name"
        item-value="Code"
        :label="$t('state')"
        class="mb-4"
        :disabled="disabled || countriesFetching"
        :error-messages="errors"
        @input="updateState"
      >
        <template v-if="errors.length" #append>
          <u-errors-tooltip :errors="errors" />
        </template>
      </u-select>
    </ValidationProvider>

    <!-- state free entry -->
    <u-text-field
      v-if="!states.length && freeEntryState"
      v-model="state"
      :label="$t('state')"
      :disabled="disabled || countriesFetching"
      :placeholder="countriesFetching ? 'Fetching countries...' : ''"
      @input="updateState"
    >
    </u-text-field>
  </div>
</template>

<script>
  export default {
    props: {
      // object passed via v-model having 'country' and 'state' properties
      value: {
        type: Object,
        default() {
          return {}
        },
      },

      /**
       * boolean indicating to use a free entry textfield for the state,
       * if no states predifined for the selected country (used in create account page)
       */
      freeEntryState: {
        type: Boolean,
        default: false,
      },

      // boolean indicating disabled state
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        states: [],
        country: null,
        state: null,
      }
    },

    computed: {
      // the countries array from the data store
      countries() {
        return this.$store.state.data.countries || []
      },

      // flag if countries are fetching, retrieved from the data store too
      countriesFetching() {
        return this.$store.state.data.countriesFetching
      },
    },

    watch: {
      // update country selection object, used for checking states
      'value.country'() {
        this.computeStates()
      },
      /**
       * Update local 'data' from v-model
       */
      value: {
        immediate: true,
        handler(newValue) {
          this.country = newValue.country
          this.state = newValue.state
        },
      },
    },
    mounted() {
      this.fetchCountries()
    },

    methods: {
      // fetch countries will trigger request only if countries not loaded
      async fetchCountries() {
        await this.$store.dispatch('data/fetchCountries')
        this.computeStates()
      },

      // generates state combo items, if selected country has states defined
      computeStates() {
        if (!this.value.country) {
          return
        }
        const states = []
        const countryObj = this.countries.find(c => c.Code === this.value.country)
        if (countryObj && Array.isArray(countryObj.States) && countryObj.States.length) {
          countryObj.States.forEach(s => states.push({ Code: s[0], Name: s[1] }))
        }
        this.states = states
      },

      /**
       * TODO FIX MUTATIONS ISSUES
       */

      // updates the country in model
      updateCountry(country) {
        // emit input value with new country and reset state (watcher will update local 'data')
        this.$emit('input', { ...this.value, country, state: null })
      },

      // updates the state in model
      updateState(state) {
        // emit input value with country and new state values (watcher will local 'data')
        this.$emit('input', { ...this.value, country: this.country, state })
      },
    },
  }
</script>
