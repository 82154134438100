var render = function render(){var _vm=this,_c=_vm._self._c;return _c('u-page',{attrs:{"title":_vm.$t('template_configuration'),"full-height":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap"},[_c('u-btn',{staticClass:"mb-2",attrs:{"to":{ name: 'ngfw-policies-template-configuration-add' }}},[_vm._v(" "+_vm._s(_vm.$t('add_template'))+" ")]),_c('u-btn',{staticClass:"mb-2",attrs:{"to":_vm.selectedTemplates.length === 1
            ? { name: 'ngfw-policies-template-configuration-id', params: { id: _vm.selectedTemplates[0].Id } }
            : '',"disabled":_vm.selectedTemplates.length !== 1}},[_vm._v(" "+_vm._s(_vm.$t('edit_template'))+" ")]),_c('u-btn',{staticClass:"mb-2",attrs:{"disabled":_vm.selectedTemplates.length < 1},on:{"click":function($event){_vm.deleteTemplatesDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('delete_templates'))+" ")]),_c('u-btn',{staticClass:"mb-2",attrs:{"disabled":_vm.selectedTemplates.length !== 1 ||
          !_vm.selectedTemplates[0].TargetAppliances ||
          _vm.selectedTemplates[0].TargetAppliances.length === 0},on:{"click":_vm.checkAndRedirect}},[_vm._v(" "+_vm._s(_vm.$t('sync_now'))+" ")])],1)]},proxy:true}])},[_c('u-grid',{attrs:{"id":"policies-templates-grid","selection-type":"multiAction","no-data-message":_vm.$t('no_data'),"column-defs":_vm.columnDefs,"fetching":_vm.$store.state.appliances.fetching || _vm.$store.state.policies.fetchingTemplates,"row-data":_vm.templates,"selection":_vm.selectedTemplates},on:{"update:selection":function($event){_vm.selectedTemplates=$event},"refresh":function($event){return _vm.loadTemplates(true)}}}),_c('u-dialog',{attrs:{"show-dialog":_vm.deleteTemplatesDialog,"title":_vm.$t('delete_templates'),"message":_vm.$t('confirm_delete_templates'),"buttons":[
      { name: _vm.$t('cancel') },
      {
        name: _vm.$t('yes'),
        handler: 'deleteTemplates',
        showProgress: true,
      },
    ]},on:{"close-dialog":function($event){_vm.deleteTemplatesDialog = false},"deleteTemplates":_vm.deleteTemplates}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }