<template>
  <div class="d-flex flex-column overflow-auto">
    <v-navigation-drawer permanent floating color="transparent" width="240">
      <v-list class="list-dense px-4" nav dense>
        <v-list-item
          v-for="item in mfwDefaultNavItems"
          :key="item.to"
          :class="{
            'v-list-item--active': item.fullPath ? $route.fullPath.startsWith(item.fullPath) : $route.name === item.to,
          }"
          :disabled="item.disabled"
          @click="$router.push({ name: item.to })"
        >
          <v-list-item-title class="d-flex align-center">
            <v-icon size="16" class="mr-2">{{ item.icon }}</v-icon>
            <span>{{ $t(item.text) }}</span>
          </v-list-item-title>
        </v-list-item>

        <v-divider v-if="hasApplianceSettings" class="my-4" />

        <v-card v-if="hasApplianceSettings" flat :disabled="!settingsEnabled" color="transparent">
          <h4 class="heading mx-2 my-2">{{ $t('settings') }}</h4>
          <v-list-group v-for="category in mfwSettingsItems" :key="category.name" :value="isActiveCategory(category)">
            <template #activator>
              <v-list-item-title class="d-flex align-center">
                <v-icon size="16" class="mr-2" v-text="category.icon" /> {{ $t(category.name) }}
              </v-list-item-title>
            </template>
            <v-list-item
              v-for="(item, idx) in category.items"
              :key="idx"
              :disabled="category.disabled || !settingsEnabled"
              :class="($route.fullPath.includes(item.fullPath) ? 'v-list-item--active ' : '') + 'pl-9'"
              @click="$router.push(item.to)"
            >
              <v-list-item-title>{{ $t($options.mfwServices[item.name].tkey) }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-card>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
  import { getSettingsItems } from '@/util/mfwApplianceNav'
  import { mfwServices } from '@/util/mfwServices'

  export default {
    mfwServices,
    computed: {
      appliance: ({ $store, $route }) => $store.getters['appliances/getByUniqueIdentifier']($route.params.id),
      boxSettings: ({ $store }) => $store.getters['appliances/getApplianceSettings'],

      /** return the settings navigation items for MFW online appliances */
      mfwSettingsItems: ({ $store, $router, liveSettingsEnabled, appliance }) => {
        // get the enabled settings
        const enabledOptions = $store.state.data.mfwApplianceOptions
        const settings = getSettingsItems(enabledOptions, appliance.features)

        settings.forEach(section => {
          section.disabled = !!section.disableWhenOffline && !liveSettingsEnabled
          // compute full path to use for active class for subcategories
          section.items.forEach(item => (item.fullPath = $router.resolve(item.to).resolved.fullPath))
          // sort all items in settings array alphabetically
          section.items.sort((a, b) => a.name.localeCompare(b.name))
        })

        return settings
      },

      /** shows the settings if MFW appliance cmd connected with settings enabled and licensed (or doesn't need license) */
      settingsEnabled: ({ appliance, boxSettings, $store }) =>
        boxSettings &&
        appliance?.ProductLine === 'MFW' &&
        (appliance?.ActiveSubscriptionCount > 0 || $store.state.data.ccViewModel.Account.NoLicenseEnforcement),

      // flag to remove appliance settings when `hasApplianceSettings` feature is false
      hasApplianceSettings: ({ appliance }) => appliance.features.hasApplianceSettings,

      // flag to determine if device is online and we can access live data
      liveSettingsEnabled: ({ settingsEnabled, appliance }) => appliance?.IsConnectedToCmd && settingsEnabled,
      // list of default nav items rendered above settings
      mfwDefaultNavItems: ({ liveSettingsEnabled, settingsEnabled, hasApplianceSettings, $router }) => {
        return [
          {
            to: 'appliances-id',
            text: 'dashboard',
            icon: 'mdi-view-dashboard',
          },
          ...(hasApplianceSettings
            ? [
                {
                  to: 'appliances-policies',
                  text: 'policies',
                  icon: 'mdi-file-cog-outline',
                  disabled: !settingsEnabled,
                },
              ]
            : []),
          {
            to: 'appliances-clients',
            text: 'clients',
            icon: 'mdi-server-network',
            disabled: !liveSettingsEnabled,
          },
          {
            to: 'appliances-active-sessions',
            text: 'active_sessions',
            icon: 'mdi-table-network',
            disabled: !liveSettingsEnabled,
          },
          {
            to: 'appliances-reports',
            text: 'reports',
            icon: 'mdi-chart-areaspline',
            disabled: !liveSettingsEnabled,
            fullPath: $router.resolve({ name: 'appliances-reports' }).resolved.fullPath,
          },
        ]
      },
    },

    methods: {
      /**
       * Returns true/false if a specific category is "expanded" based on route path/params
       * Used for expand/collapse of the navigation group items
       * @param {Object} category - category object
       * @returns {Boolean}
       */
      isActiveCategory(category) {
        const matches = category.items.map(item => item.name || item.to.params?.settings)

        return matches.some(
          match =>
            this.$route.path.split('/').pop() === match ||
            this.$route.name.includes(match) ||
            this.$route.params?.settings === match,
        )
      },
    },
  }
</script>
