// import Vue from 'vue'
import * as FullStory from '@fullstory/browser'
import util from '@/plugins/ut/ut-util'

const orgId = 'MZF4'
const devMode = util.isDev

/**
 * Initializes FullStory
 * @param {Boolean} embedded - true if the app is running inside an iframe (e.g. sapphire)
 * @returns {undefined}
 */
export const initFullStory = function (embedded = false) {
  FullStory.init({ orgId, devMode, recordOnlyThisIFrame: embedded })
}
