import { loadGmapApi } from 'gmap-vue'

import vuntangle from '@/plugins/vuntangle'
import autoLogout from '@/plugins/ut/ut-auto-logout'
import gtm from '@/plugins/gtm'
import ga from '@/plugins/ga'
import ga4 from '@/plugins/ga4'
import keepalive from '@/plugins/ut/ut-keepalive'
import api from '@/plugins/ut/ut-api'
import taskManager from '@/plugins/ut/ut-task-manager'
import { getCvApiServer } from '@/util/sapphire'

/**
 * The root store module
 * It should contain just UI state related data
 */
const getDefaultState = () => {
  return {
    // boolean used to show a loading indicator on a page
    pageLoader: false,
    // boolean used to show a logout indicator on a page
    logoutPending: false,
    // string used to hold the selected tab on the hosts page - when switching hosts, the tab selection should remain the same. Defaults to summary tab
    hostsSelectedTab: 'summary',
    // string used to hold the selected tab on the network page, configuration widget - when switching tabs, the tab selection should remain the same. Defaults to wan-rules tab
    networkConfigSelectedTab: 'wan_rules',
    // string used to hold the selected tab on the link information widget - when switching appliances, the selection should remain the same.
    // Defaults to the summary tab
    linkInformationSelectedTab: 'summary',
    // boolean if it's a light ot dark theme
    isDark: false,

    appDrawer: false,

    // boolean used to toggle mini drawer in new side menu
    miniDrawer: localStorage.getItem('stateMiniDrawer') === 'true',

    /**
     * current state of My Account section
     */
    accountSelection: '/account/contact',
    /**
     * Current state of sd wan tab
     */
    networkSelection: '/appliances/networks',
    /**
     * Current state of alerts tab
     */
    alertsSelection: '/alerts',
    /**
     * current state of appliances tab
     */
    appliancesSelection: { name: 'appliances' },

    /**
     * current state of backups tab
     */
    backupsSelection: { name: 'backups' },
    /**
     * current state of hosts tab
     */
    hostsSelection: '/hosts',

    /**
     * Current state of mfw policies tab
     */
    mfwPoliciesSelection: null,

    /**
     * Current state of ngfw templates tab
     */
    // ngfwTemplatesSelection: '/appliances/policies/ngfw/template-configuration',
    ngfwTemplatesSelection: { name: 'ngfw-policies-template-configuration' },
    /**
     * Current state of reports tab
     */
    reportsSelection: '/reports/top-applications',

    /**
     * Drawer offset used to accommodate IPM messages for `top` placement
     */
    drawerOffsetTop: 0,

    /**
     * Object to hold information to switch an organization.  Switching an organization may be done on the header or the
     * organizations page when logged in.  The SwitchOrganization object listens for changes in this store object.
     */
    switchOrganization: null,

    /**
     * string used to hold selected tab on appliance policies widget; defaults to policies tab
     */
    appliancePoliciesSelectedTab: 'policies',
  }
}

/**
 * Check if google plugins have already been loaded.  Will return an error if google is loaded again.  Google
 * needs to be loaded when ccViewModel is returned, which could be on hard refresh in initApp() or on login.
 */
const googleLoaded = false

export const state = () => {
  return { ...getDefaultState(), googleLoaded }
}

export const mutations = {
  RESET: state => Object.assign(state, getDefaultState()),
  SET_PAGE_LOADER: (state, payload) => (state.pageLoader = payload),
  SET_LOGOUT_PENDING: (state, payload) => (state.logoutPending = payload),
  SET_ACCOUNT_SELECTION: (state, payload) => (state.accountSelection = payload),
  SET_ALERTS_SELECTION: (state, payload) => (state.alertsSelection = payload),
  SET_APPLIANCES_SELECTION: (state, payload) => (state.appliancesSelection = payload),
  SET_BACKUPS_SELECTION: (state, payload) => (state.backupsSelection = payload),
  SET_HOSTS_SELECTION: (state, payload) => (state.hostsSelection = payload),
  SET_MFW_TEMPLATES_SELECTION: (state, payload) => (state.mfwTemplatesSelection = payload),
  SET_NGFW_TEMPLATES_SELECTION: (state, payload) => (state.ngfwTemplatesSelection = payload),
  SET_REPORTS_SELECTION: (state, payload) => (state.reportsSelection = payload),
  SET_NETWORKS_SELECTION: (state, payload) => (state.networkSelection = payload),
  SET_HOSTS_SELECTED_TAB: (state, payload) => (state.hostsSelectedTab = payload),
  SET_NETWORK_CONFIG_SELECTED_TAB: (state, payload) => (state.networkConfigSelectedTab = payload),
  SET_LINK_INFORMATION_SELECTED_TAB: (state, payload) => (state.linkInformationSelectedTab = payload),
  SET_IS_DARK: (state, value) => (state.isDark = value),
  SET_GOOGLE_LOADED: (state, value) => (state.googleLoaded = value),
  SET_APP_DRAWER: (state, value) => (state.appDrawer = value),
  SET_MINI_DRAWER: (state, value) => (state.miniDrawer = value),
  SET_DRAWER_OFFSET_TOP: (state, value) => (state.drawerOffsetTop = value),
  SET_SWITCH_ORGANIZATION: (state, value) => (state.switchOrganization = value),
  SET_APPLIANCE_POLICIES_SELECTED_TAB: (state, payload) => (state.appliancePoliciesSelectedTab = payload),
}

export const actions = {
  /**
   * Action dispatched once, before app initialization
   * to check if user is logged in (has a valid session)
   * - if successful - redirects user to dashboard
   * - if failed - shows the login page
   *
   * The call is triggered from initialization plugin
   * (see plugins/ut-init.js)
   */
  async initApp({ dispatch, state }) {
    await dispatch('data/fetchCcViewModel')

    dispatch('configureGooglePlugins')

    // run the auto logout timer if they are logged in, initialize plugins
    if (state.auth.isAuth) {
      dispatch('boostrapApp')
    }
  },

  /**
   * Action dispatched once, before sapphire app initialization
   * to check if user has a valid session
   * - if successful - redirects user to dashboard
   * - if failed - shows the /not-authenticated page
   *
   * The call is triggered from sapphire.js entry point
   */
  async initSapphireApp({ dispatch }) {
    const apiServer = getCvApiServer()
    await dispatch('data/fetchCcViewModel', apiServer ? { apiServer } : null)
    dispatch('configureGooglePlugins')
  },

  /**
   * Resets entire store and it's modules to an initial state. Usually after user logs out
   * @param {Function} commit
   */
  resetState({ commit }) {
    vuntangle.toast.clear()

    commit('auth/RESET')
    commit('alerts/RESET')
    commit('appliances/RESET')
    commit('data/RESET')
    commit('events/RESET')
    commit('financial/RESET')
    commit('hosts/RESET')
    commit('networks/RESET')
    commit('policies/RESET')
    commit('reports/RESET')
    commit('subscriptions/RESET')
    commit('users/RESET')
    commit('policyManager/RESET')
    commit('RESET')
  },

  /**
   * Configures Google Analytic and maps plugins
   *
   * @param {Object} state
   *
   * @returns {void}
   */
  configureGooglePlugins({ state, commit }) {
    // do nothing if google plugins have been loaded
    if (state.googleLoaded) {
      return
    }

    // load google tag manager
    const gtmTag = state.data.ccViewModel.IsSandboxMode ? 'GTM-M73CZVL' : 'GTM-MSPVNBF'
    gtm.load(gtmTag)

    // load google analytics
    ga.load()
    window.ga('create', state.data.ccViewModel.IsSandboxMode ? 'UA-21777083-9' : 'UA-21777083-8', 'auto')
    window.ga('send', 'pageview')

    // load GA4 tag
    const tagId = state.data.ccViewModel.IsSandboxMode ? 'G-R4N6W7K0YP' : 'G-1CEKBFLTFQ'
    ga4.load(tagId)

    // set google maps api
    loadGmapApi({ key: state.data.ccViewModel.GoogleMapsApiKey })

    // set google loaded flag so it does not try to load again on a logout/login
    commit('SET_GOOGLE_LOADED', true)
  },

  /**
   * Boostrap the application.  This is called after the ccViewModel is loaded to get environment
   * specific options.
   *
   * @returns {void}
   */
  boostrapApp() {
    // set keepalive and auto logout timers
    keepalive.resetTimer()
    autoLogout.startAutoLogoutTimer()
  },

  /**
   * Performs logout api call and redirects to login on success
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async logout({ commit, dispatch }) {
    commit('SET_LOGOUT_PENDING', true)

    const response = await api.ajaxRequest('logout')
    if (response.data?.success) {
      dispatch('cleanupBeforeLogout')
    }

    commit('SET_LOGOUT_PENDING', false)

    return response.data?.success || false
  },

  /**
   * Clear any data stores specific to user sensitive data, stop the 'session inactive' timer, redirect to login
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {string}   queryString
   *
   * @returns {void}
   */
  cleanupBeforeLogout({ commit }) {
    /**
     * avoid setting ccViewModel null at this point
     * because errors will be thrown if the page from where logout is made
     * has computed data based on ccViewModel
     * ccViewModel will become null on login page, after redirect
     */
    // state.commit('data/SET_CC_VIEW_MODEL', null)
    commit('auth/SET_IS_AUTH', false)

    // stop auto logout timer
    autoLogout.clearAutoLogoutTimer()

    // stop the keepalive timer
    keepalive.clearTimer()

    // stop the task manager timer
    taskManager.clearTimer()
  },

  /**
   * Api call to create a new account
   * @param {Function}  commit
   * @param {Object}    data - The account details
   * @param {String}    data.email - The account email
   * @param {String}    data.password - The account password
   * @param {String}    data.firstName - The user first name
   * @param {String}    data.lastName - The user last name
   * @param {String}    data.phone - The user phone
   * @param {String}    data.company - The user company
   * @param {Boolean}   data.hasAcceptedPrivacyPolicy - Boolean for Privacy Policy acknowledgment
   * @param {String}    data.country - The user country
   * @param {String}    data.state - The user state
   * @param {String}    data.createSource - The window location from where this call is made
   * @param {String}    data.paramOrder - 'email password firstName lastName phone company hasAcceptedPrivacyPolicy country state createSource',
   *
   * @return {String}   return the response message translation string
   */
  async createAccount({}, data) {
    const response = await api.ajaxRequest('createAccount', data)
    if (!response.data?.success) {
      return response.data?.message === 'existing_user_login' ? 'existing_user_login' : 'unexpected_error_occurred'
    }

    return 'registration_success_with_verification'
  },

  /**
   * Api call to create an account from an invitation link
   * @param {Function}  commit
   * @param {Object}    data - The account details
   * @param {String}    data.email - The account email
   * @param {String}    data.password - The account password
   * @param {String}    data.firstName - The user first name
   * @param {String}    data.lastName - The user last name
   * @param {String}    data.phone - The user phone
   * @param {String}    data.company - The user company
   * @param {Boolean}   data.hasAcceptedPrivacyPolicy - Boolean for Privacy Policy acknowledgment
   * @param {String}    data.country - The user country
   * @param {String}    data.state - The user state
   * @param {String}    data.createSource - The window location from where this call is made
   * @param {String}    data.inviteToken - The invitation token for this registration
   * @param {String}    data.paramOrder - 'email password firstName lastName phone company hasAcceptedPrivacyPolicy country state createSource inviteToken',
   */
  async registerUser({}, data) {
    return await api.ajaxRequest('registerUser', data)
  },

  /**
   * Select the organization for an multiple organizations account
   * @param {Object} state - The store state
   * @param {Object} data
   * @param {String} data.accountId - The account id
   * @param {String} data.mfaCode - The MFA code
   * @param {String} data.paramOrder - "accountId mfaCode"
   */
  async switchOrganization(state, data) {
    const response = await api.ajaxRequest('switchOrganization', data)
    if (response.data?.success && response.data?.ccViewModel) {
      state.commit('data/SET_CC_VIEW_MODEL', response.data.ccViewModel)
    }

    return response
  },

  /**
   * Deletes an account
   * @param {Function} dispatch
   */
  async deleteAccount() {
    const response = await api.cloud('Untangle_CommandCenter', 'DeleteSelectedAccount')
    const ns = []

    for (const n of ns) {
      const match = n.match(/^[a-f0-9]{1,4}$/i)
      if (match?.[0] !== n) {
        throw new Error('Invalid address: ')
      }
    }

    return (response.success && response.data) || false
  },

  /**
   * Privacy Policy consent
   * @param {Function} dispatch
   */
  async consentPrivacyPolicy({ commit, dispatch }) {
    const response = await api.ajaxRequest('updatePrivacyPolicyConsent', {})
    if (response.data?.success) {
      commit('data/SET_PRIVACY_CONSENT', true)
      return true
    }
    dispatch('cleanupBeforeLogout')

    return false
  },
}
