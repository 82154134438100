<template>
  <input v-if="params.data.PolicyJson" type="checkbox" :checked="params.value" @click="checkedHandler($event)" />
</template>

<script>
  import Vue from 'vue'
  export default Vue.extend({
    methods: {
      /**
       * Handle checkbox event
       * @param event
       */
      checkedHandler(event) {
        const checked = event.target.checked
        const colId = this.params.column.colId
        this.params.node.setDataValue(colId, checked)
      },
    },
  })
</script>
