<template>
  <u-widget
    :title="$t('audit_history')"
    :info="$t('info_audit_history')"
    enable-refresh
    @refresh="fetchAuditHistory"
    v-on="$listeners"
  >
    <u-grid
      id="audit-history"
      :no-data-message="noDataMessage"
      :column-defs="auditColumnDefs"
      :fetching="auditsFetching"
      :row-data="audits"
      :resize-columns.sync="resizeEvent"
      @refresh="fetchAuditHistory"
      @row-clicked="viewDetails"
    />
    <u-dialog
      :show-dialog="!!filteredRecords.length"
      :title="$t('additional_information')"
      :width="600"
      :buttons="[
        {
          'name': $t('ok'),
          'handler': 'close-dialog',
        },
      ]"
      @close-dialog="onCloseDialog"
    >
      <extra-details :filtered-records="filteredRecords" :current-index.sync="currentIndex" pre-wrap />
    </u-dialog>
  </u-widget>
</template>
<script>
  import grids from '@/plugins/ut/ut-grids'
  import WidgetMixin from '@/components/widgets/WidgetMixin'
  import ExtraDetails from '@/components/widgets/ExtraDetails'
  import util from '@/plugins/ut/ut-util'

  export default {
    components: { ExtraDetails },
    mixins: [WidgetMixin],
    props: {
      uids: {
        type: Array,
        default() {
          return []
        },
      },
    },
    data() {
      return {
        audits: [],
        type: 'audit',
        // list of records passed to detail dialog to navigate through
        filteredRecords: [],
        // index of the record being viewed from filteredRecords
        currentIndex: -1,
      }
    },
    computed: {
      auditColumnDefs: () => grids.getAuditColumnDefs(),
      auditsFetching: ({ $store, type }) => $store.getters['alerts/getAccountEventsFetching'](type),
      noDataMessage() {
        return this.audits.length > 0
          ? this.$t('no_filtered_data_audit_history')
          : this.$t('no_data_defined_audit_history')
      },
    },
    mounted() {
      this.fetchAuditHistory()
    },
    methods: {
      /**
       * fetches audit events against uids
       */
      async fetchAuditHistory() {
        const response = await this.$store.dispatch('alerts/fetchDashboardAlerts', {
          type: this.type,
          count: 100,
          startDate: this.$vuntangle.dates.getDateString(new Date(), -30),
          payload: null,
          options: { force: true },
        })
        if (response) {
          response.forEach(function (message) {
            message.Message = util.addApplianceTagsInString(message.Message)
          })
          this.audits = response
        }
      },

      onCloseDialog() {
        this.filteredRecords = []
        this.currentIndex = -1
      },

      /**
       * builds the props needed for details dialog and displays the dialog
       * @param {*} param clicked row to fetch details for
       */
      viewDetails(params) {
        params.api.forEachNodeAfterFilterAndSort(node => {
          const row = node.data
          this.filteredRecords.push({
            ...row.Payload,
            DateCreated: row.DateCreated.$date,
            Message: util.addApplianceTagsInString(row.Payload.Message),
          })
        })
        this.currentIndex = params.node.rowIndex
      },
    },
  }
</script>
