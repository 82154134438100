<template>
  <div class="d-flex align-center">
    <condition-info :type="condition.type" class="mr-2" />
    <v-select
      ref="select"
      v-model="condition.type"
      data-testid="conditionType"
      :items="conditionTypes"
      flat
      solo
      dense
      hide-details
      :placeholder="placeholder"
      :menu-props="{ offsetY: true, maxHeight: 400 }"
      @input="resetValueOrObject"
    >
      <template #item="{ item, attrs, on }">
        <v-list-item v-bind="attrs" dense :ripple="false" v-on="on">
          <v-list-item-content>
            {{ item.text }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>
<script>
  import {
    ConditionType,
    ConditionTarget,
    conditionsConfig,
    rulesConfig,
    eqOps,
    matchOps,
    inOps,
    allConditions,
  } from 'vuntangle/pm'
  import ConditionInfo from './ConditionInfo.vue'

  export default {
    components: { ConditionInfo },
    inject: ['$condition', '$ruleType', '$target', '$targetCount'],

    computed: {
      condition: ({ $condition }) => $condition(),
      ruleType: ({ $ruleType }) => $ruleType(),
      targetCount: ({ $targetCount }) => $targetCount(),

      config: ({ condition }) => conditionsConfig[condition.type],
      operators: ({ config }) => config?.operators,

      /**
       * Returns the condition type select items based on target (SOURCE/DEST/OTHER) and ruleType (in the future)
       */
      conditionTypes: ({ $target, $i18n, ruleType }) => {
        let conditionTypes = allConditions

        /**
         * this might be used when we want to use predefined condition types baed on rule type
         * see rulesConfig on how condition types are set on a rule
         */
        if (ruleType) {
          conditionTypes = allConditions || rulesConfig[ruleType]?.conditions
        }

        return conditionTypes
          .filter(type => conditionsConfig[type].target === $target)
          .map(filteredType => ({
            value: filteredType,
            text: $i18n.t(conditionsConfig[filteredType]?.text || filteredType),
          }))
      },

      /**
       * Return condition type placeholder which can be `Any` (for SOURCE/DEST) or `Select`
       */
      placeholder: ({ $i18n, $target, targetCount }) => {
        if ([ConditionTarget.Source, ConditionTarget.Destination].includes($target)) {
          return targetCount === 0 ? $i18n.t('any') : $i18n.t('select')
        }
        return `${$i18n.t('select')}`
      },
    },

    watch: {
      'condition.type': {
        handler(type) {
          if (!type) {
            this.$refs.select?.reset()
          }
        },
        immediate: true,
        deep: true,
      },
    },

    methods: {
      /** on condition type change reset operator and value/object */
      resetValueOrObject() {
        if (!this.condition.type) return

        /** on type change reset op to the first available for the condition config */
        this.$set(this.condition, 'op', this.operators[0])

        if (eqOps.includes(this.condition.op)) {
          this.$set(this.condition, 'value', [])
          this.$delete(this.condition, 'object')
        }
        if (matchOps.includes(this.condition.op) || inOps.includes(this.condition.op)) {
          this.$set(this.condition, 'object', [])
          this.$delete(this.condition, 'value')
        }

        // remove `rate_unit` in case condition type is not LIMIT_RATE
        if (![ConditionType.LimitRate].includes(this.condition.type)) {
          this.$delete(this.condition, 'rate_unit')
        }
        // remove `port_protocol` in case condition type is not ClientPort or ServerPort
        if (![ConditionType.ClientPort, ConditionType.ServerPort].includes(this.condition.type)) {
          this.$delete(this.condition, 'port_protocol')
        }
      },
    },
  }
</script>
