<template>
  <value-object v-if="useObjects" v-on="$listeners" />
  <value-autocomplete v-else v-model="condition.value" :values="countryOptions" />
</template>
<script>
  import { OperatorType } from 'vuntangle/pm'
  import { countryOptions } from 'vuntangle/constants'
  import { ValueObject, ValueAutocomplete } from '../value'

  export default {
    components: { ValueObject, ValueAutocomplete },
    inject: ['$condition'],

    data() {
      return {
        countryOptions,
      }
    },
    computed: {
      condition: ({ $condition }) => $condition(),
      useObjects: ({ condition }) =>
        [OperatorType.Match, OperatorType.NotMatch, OperatorType.In, OperatorType.NotIn].includes(condition.op),
    },
  }
</script>
