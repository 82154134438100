<template>
  <active-sessions
    :sessions="sessions"
    :fetching="fetching"
    :box-settings="boxSettings"
    class="flex-grow-1"
    @refresh="fetchSessions"
  />
</template>
<script>
  import { ActiveSessions } from 'vuntangle'
  import settingsMixin from './settingsMixin'

  export default {
    components: { ActiveSessions },
    mixins: [settingsMixin],
    data() {
      return {
        sessions: [],
        fetching: false,
      }
    },

    computed: {
      boxSettings: ({ $store }) => $store.getters['appliances/getApplianceSettings'],
    },

    mounted() {
      this.fetchSessions()
    },

    methods: {
      async fetchSessions() {
        this.fetching = true
        const response = await this.getFromAppliance('status/sessions')
        this.fetching = false
        if (response.success && response.data) {
          this.sessions = response.data
        } else {
          this.$vuntangle.toast.add(this.$t('an_error_occurred'), 'error')
        }
      },
    },
  }
</script>
