<template>
  <div class="d-flex flex-column flex-grow-1 flex-basis-0 ma-4">
    <grid-wrapper
      ref="wrapper"
      :headline="$t('policies')"
      :subtitle="$t('policies_description')"
      :selection.sync="selection"
      is-policy-or-template
    >
      <template #actions>
        <v-btn depressed color="primary" class="text-capitalize mr-2" @click="onCreatePolicy(null)">
          <v-icon small class="mr-2">mdi-plus</v-icon> {{ $t('create_new') }}
        </v-btn>
        <v-btn depressed color="primary" class="text-capitalize mr-2" @click="onCreateDefaultPolicy">
          <v-icon small class="mr-2">mdi-plus</v-icon> {{ $t('create_default') }}
        </v-btn>
        <v-btn depressed color="primary" class="text-capitalize" @click="onImportPolicy">
          <v-icon small class="mr-2">mdi-import</v-icon> {{ $t('import') }}
        </v-btn>
      </template>
      <template #default="{ selectionType }">
        <u-grid
          id="policies"
          :selection-type="selectionType"
          row-node-id="id"
          :no-data-message="noDataMessage"
          :column-defs="columnDefs"
          :fetching="fetching"
          :row-data="policies"
          :row-actions="rowActions"
          :selection.sync="selection"
          :framework-components="frameworkComponents"
          v-on="!selectionType ? { rowClicked } : {}"
          @refresh="fetchPoliciesAndAppliances(true)"
        />
      </template>
    </grid-wrapper>
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import {
    Type,
    ConditionsRenderer,
    ConditionGroupsRenderer,
    AssociatedApplianceRenderer,
    NameRenderer,
    columnDefs,
  } from 'vuntangle/pm'
  import GridWrapper from '../components/GridWrapper.vue'
  import { generateDefaultMFWObject } from '../util'
  import renderersMixin from '../renderersMixin'
  import { hydratePoliciesData } from '../hydration'
  import ImportPolicyDialog from '../policies/ImportPolicyDialog.vue'
  import vuntangle from '@/plugins/vuntangle'

  export default {
    components: { GridWrapper },
    mixins: [renderersMixin],
    data() {
      return {
        selection: [],
        fetching: false,
        frameworkComponents: {
          ConditionsRenderer,
          ConditionGroupsRenderer,
          AssociatedApplianceRenderer,
          NameRenderer,
        },

        rowActions: [
          {
            icon: 'mdi-pencil',
            handler: ({ node }) => this.rowClicked({ node }),
          },
          {
            icon: 'mdi-content-copy',
            handler: ({ data }) => this.onClonePolicy(data.id),
          },
          {
            icon: 'mdi-delete',
            handler: ({ data }) => this.$refs.wrapper.onDelete([data.id]),
          },
        ],
      }
    },

    computed: {
      policies: ({ $store }) => {
        const policies = $store.getters['policyManager/getObjectsByType'](Type.Policy)
        return hydratePoliciesData(policies)
      },
      columnDefs: ({ augmentColumns }) =>
        augmentColumns(columnDefs.getPoliciesWithAppliancesColumnDefs(), ['appliances']),
      noDataMessage() {
        return this.policies.length > 0 ? this.$t('no_filtered_data_policies') : this.$t('no_data_defined_policies')
      },
    },

    mounted() {
      this.fetchPoliciesAndAppliances()
    },

    methods: {
      /**
       * dispatch action to fetch policies
       * @param {Boolean} force - flag to force or not fetching data
       */
      async fetchPoliciesAndAppliances(force = false) {
        this.fetching = true
        await Promise.allSettled([
          // run the dispatch to get appliances so they're accessible on a refresh
          this.$store.dispatch('appliances/fetchAppliances', { force }),
          // run the dispatch to get policies so they're accessible on a refresh
          this.$store.dispatch('policyManager/getAllApplianceAssignments', { force }),
          this.$store.dispatch('policyManager/fetchObjectsByType', { type: Type.Policy, force }),
        ])
        this.fetching = false
      },

      /** commits the rule that is going to be edited, than routes to the rule editor */
      rowClicked({ node }) {
        const policyId = node.data.id
        const policy = this.$store.getters['policyManager/getObjectById'](policyId)
        if (!policy) return
        this.$store.commit('policyManager/SET_EDIT_OBJECT', cloneDeep(policy))
        this.$router.push({ name: 'pm-policy-conditions', params: { policyId } })
      },

      /** create a new policy with default name */
      onCreatePolicy(id) {
        const policy = id && this.$store.getters['policyManager/getObjectById'](id)

        const newPolicy = generateDefaultMFWObject(Type.Policy, policy)
        this.$store.commit('policyManager/SET_EDIT_OBJECT', newPolicy)
        this.$router.push({ name: 'pm-policy-conditions', params: { policyId: newPolicy.Id } })
      },

      /** clone the whole policy, including conditions and rules, and return the result */
      async onClonePolicy(policyId) {
        this.fetching = true
        const clonePolicy = await this.$store.dispatch('policyManager/clonePolicy', { policyId })
        // if cloning fails, send toast about failure and return
        if (clonePolicy === null) {
          this.$vuntangle.toast.add(this.$t('policy_cloned_failure'), 'error')
          this.fetching = false
          return
        }
        this.$vuntangle.toast.add(this.$t('policy_cloned_success'))
        // set the edit object as the cloned policy
        this.$store.commit('policyManager/SET_EDIT_OBJECT', clonePolicy)
        // fetch all again
        await this.fetchPoliciesAndAppliances()
        // route to the new or cloned policy
        this.$router.push({ name: 'pm-policy-conditions', params: { policyId: clonePolicy.Id } })
      },

      /** shows confirmation to create default policy */
      onCreateDefaultPolicy() {
        vuntangle.confirm.show({
          title: this.$t('confirm'),
          message: this.$t('create_default_policy_confirmation'),
          confirmLabel: this.$t('yes'),
          cancelLabel: this.$t('no'),
          action: async resolve => {
            const response = await this.$store.dispatch('policyManager/createDefaultPolicy')
            if (response) {
              vuntangle.toast.add(this.$t('saved_successfully', [this.$t('policy')]))
            } else {
              vuntangle.toast.add(this.$t('unable_to_save', [this.$t('policy')]), 'error')
            }
            resolve()
          },
        })
      },

      /**
       * shows dialog to import policy settings from a json file
       */
      onImportPolicy() {
        vuntangle.dialog.show({
          title: this.$t('import_policy'),
          actionLabel: this.$t('import'),
          cancelLabel: this.$t('cancel'),
          actionEnabled: false,
          component: ImportPolicyDialog,
        })
      },
    },
  }
</script>
