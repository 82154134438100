/**
 * Utility methods used to format various chart tooltips
 */
import util from '@/plugins/ut/ut-util'

export default {
  // tooltip formatter used for Top Hostnames graph
  getBytesTooltipPieChartTopHosts(data) {
    return `<strong>Hostname:</strong> ${data.point.name} <br />
      <strong>Appliance:</strong> ${data.point.hostname || ''}
      [ ${data.point.uid || ''} / ${data.point.applianceTag || ''} ] <br />
      <strong>Bandwidth:</strong> ${util.formatBytes(data.y)}`
  },

  // tooltip formatter used in Top Applications report and widgets
  getBytesTooltipPieChart(data) {
    return `<strong>${data.point.name}:</strong> ${util.formatBytes(data.y)}`
  },

  // tooltip formatter used in some pie graphs
  getHitsTooltipPieChart(data) {
    return `<strong>${data.point.name}:</strong> ${data.y} hits`
  },

  // tooltip formatter used in Total Bandwidth report and widget
  getBytesTooltipLineChart(data) {
    return `<strong>${data.series.name}:</strong> ${util.formatBytes(data.y)}`
  },

  // tooltip formatter used in Total Bandwidth chart
  getBytesTooltipLineChartTotalBandwidth(data) {
    const hostname = util.uidToHostname(data.series.name)
    const uid = util.obfuscateUid(data.series.name)
    const applianceTag = util.uidToApplianceTag(data.series.name)
    const formattedBandwidth = util.formatBytes(data.point.y)
    return `<strong>Appliance:</strong> ${hostname}
    [ ${uid} / ${applianceTag} ] <br />
    <strong>Bandwidth:</strong> ${formattedBandwidth}`
  },

  // tooltip formatter used in LinkInformation widget for jitter, latency and packet_loss
  // use 4 decimals because the values can be really small
  getFixedTooltipLineChart(data, unit) {
    return `<strong>${data.series.name}:</strong> ${data.y.toFixed(4)} ${unit}`
  },
}
