var render = function render(){var _vm=this,_c=_vm._self._c;return _c('u-widget',_vm._g({attrs:{"title":_vm.$t('information'),"enable-expand":false}},_vm.$listeners),[_c('div',{staticClass:"text-center mt-2 mb-2"},[_vm._v(" "+_vm._s(_vm.network.Name)+" ")]),_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$tc('appliance_count', _vm.network.Uids.length))+" ")]),_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(`${_vm.$t('created')}: ${_vm.$d(_vm.network.DateCreated.replace('/Date(', '').replace(')/', ''), 'long')}`)+" ")]),_c('div',{staticClass:"text-center"},[_c('u-btn',{staticClass:"my-2",on:{"click":function($event){_vm.deleteNetworkDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('delete_network'))+" ")])],1),_c('u-dialog',{attrs:{"show-dialog":_vm.deleteNetworkDialog,"title":_vm.$t('delete_network'),"message":_vm.$t('confirm_delete_network'),"buttons":[
      { name: _vm.$t('cancel') },
      {
        name: _vm.$t('yes'),
        handler: 'deleteNetwork',
        showProgress: true,
      },
    ]},on:{"close-dialog":function($event){_vm.deleteNetworkDialog = false},"deleteNetwork":_vm.deleteNetwork}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }