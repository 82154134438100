<template>
  <div class="mx-4">
    <!-- expired cert alert -->
    <u-alert v-if="expiredCertWarning" error><span v-html="$t(expiredCertWarning)" /></u-alert>
    <!-- cmd not connected alert, 'ETM Dashboard' or 'Cloud Vision' are english only names, regardless of locale -->
    <u-alert v-if="cmdConnectWarning" error>
      <span
        v-html="
          $t(cmdConnectWarning, { to: $isSapphire ? $t('cloud_vision') : $t('etm_dashboard'), uid: appliance.Uid })
        "
      />
    </u-alert>
    <!-- don't show security edition warning if appliance is not connected, we can't see the subscription -->
    <v-alert v-if="isBasicEditionMfw" text color="primary">
      <div class="d-flex align-center">
        <div class="d-flex align-center">
          <v-icon color="primary" class="mr-4">mdi-license</v-icon>
          {{ $t('security_license_not_found') }}
        </div>
        <div class="d-flex ml-auto">
          <u-btn class="ml-4" :to="{ name: 'account-subscriptions' }">{{ $t('manage_subscriptions') }}</u-btn>
          <u-btn
            class="ml-4"
            :href="`${$store.state.data.ccViewModel.StoreUrl}configurator/?sku=SR-11-SWSE-0100-1YEAR`"
            target="_blank"
          >
            {{ $t('buy_subscriptions') }}
            <v-icon right> mdi-open-in-new </v-icon>
          </u-btn>
        </div>
      </div>
    </v-alert>
    <!-- alert when license is about to expire -->
    <u-alert v-if="licenseExpireMessage" error>
      <span
        v-html="
          `${licenseExpireMessage} ${$t('maintain_security_renew_your_subscription', {
            baseUrl: $options.baseUrl,
          })}`
        "
      />
    </u-alert>

    <u-alert v-if="!licensedForCommandCenter" error>
      <span
        v-html="
          $t(appliance.ProductLine === 'NGFW' ? 'ngfw_no_complete_subscription' : 'mfw_no_subscription', [
            $store.state.data.ccViewModel.StoreUrl,
          ])
        "
      />
    </u-alert>
  </div>
</template>
<script>
  import util from '@/plugins/ut/ut-util'

  export default {
    computed: {
      appliance: ({ $store, $route }) => $store.getters['appliances/getByUniqueIdentifier']($route.params.id),

      /** alert message if expired cert for MFW lower than 4.0.1 and NGFW lower than 16.5.2  */
      expiredCertWarning: ({ appliance }) => {
        let compareResult = 0
        const mfwCompareVersion = '4.0.1'
        const ngfwCompareVersion = '16.5.2'
        // if appliance is not connected to ETM Dashboard
        if (!appliance?.IsConnectedToCmd) {
          if (appliance.ProductLine === 'MFW') {
            // if the appliance MFW software version is smaller than version 4.0.1
            compareResult = util.compareVersions(appliance.SoftwareVersion, mfwCompareVersion)
          }
          if (appliance.ProductLine === 'NGFW') {
            // if the appliance NGFW software version is smaller than version 16.5.2
            compareResult = util.compareVersions(appliance.SoftwareVersion, ngfwCompareVersion)
          }
        }

        return compareResult < 0 ? 'disconnected_appliance_expired_certificate' : undefined
      },

      /** alert message if appliance not connected to CMD */
      cmdConnectWarning: ({ appliance }) => (!appliance.IsConnectedToCmd ? 'appliance_not_connected_cmd' : undefined),

      /** Check if the appliance is a mfw without a security edition subscription  */
      isBasicEditionMfw: ({ appliance, $store }) => {
        // if th account doesn't need licenses, return early
        if (appliance?.ProductLine !== 'MFW' || $store.state.data.ccViewModel.Account?.NoLicenseEnforcement) {
          return false
        }
        let isBasicEdition = true
        appliance?.Subscriptions.forEach(subscription => {
          const sub = $store.state.subscriptions.list.find(
            sub => sub.subscriptionName === subscription.subscriptionName,
          )
          if (sub.sku.includes('SWSE')) {
            isBasicEdition = false
          }
        })
        return isBasicEdition
      },

      /** computes the lowest expire days */
      subscriptionExpireDays: ({ appliance }) => {
        let lowestExpireDays
        const unixTimeNow = Math.floor(Date.now() / 1000)
        let expireDays

        appliance?.Subscriptions.forEach(subscription => {
          expireDays = Math.floor((subscription.end - unixTimeNow) / 60 / 60 / 24)
          if ((!lowestExpireDays || expireDays < lowestExpireDays) && subscription.isAutoRenewed === '0') {
            lowestExpireDays = expireDays
          }
        })

        return lowestExpireDays
      },

      /** alert if appliance requires license and is expired */
      licenseExpireMessage() {
        // if the account doesn't need a license, don't show anything
        if (this.$store.state.data.ccViewModel.Account.NoLicenseEnforcement) return null
        // display a message saying they have a subscription expiring
        if (this.subscriptionExpireDays > 0 && this.subscriptionExpireDays < 30) {
          return this.$tc(
            `subscription_assigned_will_expire_day${this.subscriptionExpireDays > 1 ? 's' : ''}`,
            this.appliance.Subscriptions.length,
            [this.subscriptionExpireDays],
          )
        }

        // display a message saying they have a subscription that recently expired
        if (this.subscriptionExpireDays <= 0 && this.subscriptionExpireDays >= -15) {
          return this.$tc('subscription_assigned_expired', this.appliance.Subscriptions.length)
        }

        return null
      },

      /** Checks if the appliance has a license for Command Center or the account needs a license at all */
      licensedForCommandCenter: ({ appliance, $store }) =>
        appliance?.IsLicensedForCommandCenter || $store.state.data.ccViewModel.Account.NoLicenseEnforcement,
    },
  }
</script>
