import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/plugins/vue-i18n'
import vuetify from '@/plugins/vuetify'
import vuntangle, { installVuntanglePlugin } from '@/plugins/vuntangle'

// register global vue components
import './components'

// install the vuntangle plugin
installVuntanglePlugin()

/**
 * Common method to boostrap the vue instance.  This can be used for seperate UI builds and
 * entrypoints.  It will automatically inject our common i18n, vuetify, vuntangle and
 * global components.  The only required argument is the render method.
 *
 * @param {Function}       render
 * @param {Store|null}     store
 * @param {String|null}    dispatch
 * @param {VueRouter|null} router
 * @param {Boolean}        isSapphire // flag telling that the app context is CloudVision
 *
 * @returns {Promise<void>}
 */
export default async function (render, store = null, dispatch = null, router = null, isSapphire = false) {
  // set CVaaS flag on Vue instances
  Vue.prototype.$isSapphire = isSapphire

  // set default options used in all entrypoints / builds
  const options = {
    vuetify,
    vuntangle,
    i18n,
    render,
  }

  // load store plugin, wait for initial dispatch method if provided
  if (store) {
    options.store = store

    if (dispatch) {
      await store.dispatch(dispatch)
    }
  }

  // load router plugin
  if (router) {
    Vue.use(VueRouter)
    options.router = router
  }

  // remove initial app loader
  document.body.removeChild(document.getElementById('loader'))

  // mount vue instance
  new Vue(options).$mount('#app')
}
