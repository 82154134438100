<template>
  <interfaces
    :interfaces="interfaces"
    :interfaces-status="interfacesStatus"
    :features="appliance.features"
    :disabled="readOnly"
    @refresh="refresh"
    @add-interface="type => $router.push({ name: 'appliances-interfaces-add', params: { type } })"
    @edit-interface="device => $router.push({ name: 'appliances-interfaces-edit', params: { device } })"
    @delete-interface="onDelete"
  />
</template>
<script>
  import { Interfaces } from 'vuntangle'
  import interfaceMixin from './applianceInterfaceMixin'

  export default {
    components: { Interfaces },
    mixins: [interfaceMixin],
    data: () => ({
      // all interfaces status, async fetched
      interfacesStatus: undefined,
    }),

    mounted() {
      this.getInterfacesStatus()
    },

    methods: {
      // fetches all interfaces status
      async getInterfacesStatus() {
        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await this.getFromAppliance('status/interfaces/all')
        this.$store.commit('SET_PAGE_LOADER', false)
        if (response.data?.length) {
          this.interfacesStatus = response.data
        }
      },

      /**
       * Removes the interface
       *
       * @param deviceId device id of the interface to delete
       */
      onDelete(device) {
        const intf = this.interfaces.find(intf => intf.device === device)
        if (intf) {
          this.deleteInterfaceHandler(intf)
        }
      },

      refresh() {
        Promise.allSettled([this.fetchSettings(), this.getInterfacesStatus()])
      },
    },
  }
</script>
