// base options for the highcharts graphs

import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import chartTooltipFormatter from '@/util/chartTooltipFormatter'

export const baseReportOptions = {
  chart: {
    animation: false,
    marginTop: 10,
    backgroundColor: 'transparent',
  },
  navigator: { enabled: false },
  rangeSelector: { enabled: false },
  scrollbar: { enabled: false },
  tooltip: {
    enabled: true,
    animation: false,
    outside: true,
    shared: false,
    // followPointer: true,
    split: false,
    padding: 10,
    hideDelay: 0,
    backgroundColor: 'rgba(247, 247, 247, 0.95)',
    useHTML: false,
    xDateFormat: '%A, %b %e, %l:%M %p',
    headerFormat: `<p style="font-weight: bold; margin: 0 0 5px 0; color: #555;">{point.key}</p>`,
  },
  legend: {
    enabled: true,
    margin: 0,
    padding: 0,
    itemStyle: {
      fontSize: '12px',
      fontWeight: 300,
      width: '100px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  yAxis: {
    opposite: false,
    allowDecimals: false,
    gridLineColor: '#EEE',
    gridLineDashStyle: 'Dash',
    title: {
      align: 'high',
      offset: 0,
      reserveSpace: true,
      y: 3,
      rotation: 0,
      textAlign: 'left',
      style: {
        color: '#555',
        fontSize: '12px',
        fontWeight: 600,
      },
    },
  },
  plotOptions: {
    series: { animation: false },
    pie: {
      borderColor: '#fafafa',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
      },
    },
  },
  series: [],
}

// time series specific options
export const timeReportOptions = {
  chart: {
    type: 'spline',
    zoomType: 'x',
  },
  plotOptions: {
    column: {
      stacking: undefined,
      colorByPoint: false,
      dataGrouping: {
        enabled: true,
        approximation: 'sum',
        pointPadding: 0.2,
        groupPixelWidth: 30,
      },
      dateTimeLabelFormats: {
        millisecond: ['%A, %b %e, %l:%M:%S.%L %p', '%A, %b %e, %l:%M:%S.%L %p', ' - %l:%M:%S.%L %p'],
        second: ['%A, %b %e, %l:%M:%S %p', '%A, %b %e, %l:%M:%S %p', ' - %l:%M:%S %p'],
        minute: ['%A, %b %e, %l:%M %p', '%A, %b %e, %l:%M %p', ' - %l:%M %p'],
        hour: ['%A, %b %e, %l:%M %p', '%A, %b %e, %l:%M %p', ' - %l:%M %p'],
        day: ['%A, %b %e, %Y', '%A, %b %e', ' - %A, %b %e, %Y'],
        week: ['Week from %A, %b %e, %Y', '%A, %b %e', ' - %A, %b %e, %Y'],
        month: ['%B %Y', '%B', ' - %B %Y'],
        year: ['%Y', '%Y', ' - %Y'],
      },
    },
  },
}

// pie series specific options
export const pieReportOptions = {
  chart: {
    type: 'pie',
    marginTop: 0,
    marginBottom: 0,
  },
}

export const pieOptions = () => {
  return merge(pieReportOptions, baseReportOptions)
}

/**
 * Generates options for a time series graph
 * @param {String} chartType
 * @param {String} tooltipFormatter
 * @param {Object} customOptions
 * @returns merged chart options
 */
export const getOptions = (chartType, tooltipFormatter, customOptions = {}) => {
  let tooltip = {}
  if (tooltipFormatter && chartTooltipFormatter[tooltipFormatter]) {
    tooltip = {
      tooltip: {
        formatter() {
          return chartTooltipFormatter[tooltipFormatter](this)
        },
      },
    }
  }
  const typeOptions = chartType === 'pie' ? pieReportOptions : timeReportOptions

  // cloneDeep needed, otherwise the options will be inherited in other charts leading to issues at least in dashboard
  return merge(cloneDeep(baseReportOptions), cloneDeep(typeOptions), tooltip, customOptions)
}

export default {
  getOptions,
}
