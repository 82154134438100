<!--
  Route component that enables editing Policy Name/Description
  plus the conditions which are associated with the Policy
-->
<template>
  <div v-if="policyCopy" class="d-flex flex-column flex-grow-1 pa-4">
    <ValidationObserver ref="obs" class="flex-grow-1">
      <!-- policy Name/Description -->
      <name-description
        :name.sync="policyCopy.Name"
        :description.sync="policyCopy.Description"
        :name-label="$t('policy_name')"
        :description-label="$t('policy_description')"
        class="flex-grow-1"
      />
      <v-divider class="my-4" />
      <p class="font-weight-bold mb-2">{{ $t('policy_conditions_info') }}</p>

      <div class="d-flex flex-column flex-grow-1 flex-basis-0 pr-2">
        <edit-condition-objects
          ref="conditionsEditor"
          :ids.sync="policyCopy.PolicyJson.conditions"
          :object-id="policyCopy.Id"
        />
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
  import NameDescription from '../components/NameDescription.vue'
  import EditConditionObjects from '../components/EditConditionObjects.vue'
  import policyMixin from './_policyMixin'

  export default {
    components: { NameDescription, EditConditionObjects },
    mixins: [policyMixin],

    provide() {
      return {
        $ruleType: () => undefined,
      }
    },

    methods: {
      /**
       * Method called from `EditPolicy` upon saving policy conditions
       * @returns {String[]} - the conditions ids that were updated/created
       */
      async savePolicyConditions() {
        const isValid = await this.$refs.obs.validate()
        if (!isValid) {
          this.$refs.conditionsEditor.toggleAll()
          return false
        }

        // save changed conditions objects first
        const result = await this.$refs.conditionsEditor.saveConditionObjects()
        if (!result) {
          this.$vuntangle.toast.add(this.$t('unable_to_save', [this.$t('global.rule')]), 'error')
          return false
        }
        return result.map(object => object.Id)
      },
    },
  }
</script>
