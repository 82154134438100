<template>
  <u-widget :title="$t('information')" enable-refresh :enable-expand="false" v-on="$listeners">
    <div class="text-center mt-2 mb-2" v-html="applianceHostname" />
    <div v-if="appliance.uptime > 0" class="text-center py-1">
      <v-icon small class="mr-2">mdi-alarm</v-icon>
      {{ $t('up_time') }}: <b>{{ uptime ? $vuntangle.util.getReadableTime(uptime) : '-' }} </b>
    </div>
    <div v-else class="text-center py-1">
      <v-icon small class="mr-2">mdi-alarm</v-icon>
      <span class="red--text" style="text-transform: uppercase"> {{ $t('offline') }}</span>
      {{ $t('since') }}
      {{ calculatedOffLine }}
    </div>
    <div class="text-center">
      <v-tooltip v-if="appliance.uptime > 0" bottom>
        <template #activator="{ on }">
          <v-icon small color="secondary" class="mr-2" v-on="on">mdi-network</v-icon>
        </template>
        <span style="text-transform: uppercase">{{ $t('online') }}</span>
      </v-tooltip>
      <v-tooltip v-else bottom>
        <template #activator="{ on }">
          <v-icon small color="error" class="mr-2" v-on="on">mdi-network-off</v-icon>
        </template>
        <span style="text-transform: uppercase">{{ $t('offline') }}</span>
      </v-tooltip>
      <v-tooltip v-if="showWarningLicenseTooltip" bottom>
        <template #activator="{ on }">
          <v-icon small color="warning" class="mr-2" v-on="on">mdi-alert</v-icon>
        </template>
        <!--UI-316: Add in white-space: pre-wrap so multiple warnings/errors show
                    on different lines in the tooltip -->
        <span style="white-space: pre-wrap">{{ licenseTooltipText }}</span>
      </v-tooltip>
      <v-tooltip v-if="showErrorLicenseTooltip" bottom>
        <template #activator="{ on }">
          <v-icon small color="error" class="mr-2" v-on="on">mdi-alert</v-icon>
        </template>
        <!--UI-316: Add in white-space: pre-wrap so multiple warnings/errors show
            on different lines in the tooltip -->
        <span style="white-space: pre-wrap">{{ licenseTooltipText }}</span>
      </v-tooltip>
    </div>
    <div v-if="isMFW" class="text-center">
      {{ $t('system_time') }}: <b>{{ systemTime ? systemTime.toLocaleString($vuntangle.locale) : '-' }}</b>
    </div>
    <div class="text-center">
      {{ $t('cpu_count') }}: <b>{{ cpuCount }}</b>
    </div>
    <div class="text-center">
      {{ $t('architecture') }}: <b>{{ platform }}</b>
    </div>
    <div class="text-center">
      {{ $t('host_count') }}: <b>{{ hostCount }}</b>
    </div>
    <div class="text-center">
      {{ $t('ip_address') }}: <b>{{ ipAddress }}</b>
    </div>
    <div class="text-center">
      {{ $t('network_name') }}: <b>{{ networkName }}</b>
    </div>
  </u-widget>
</template>
<script>
  import appliances from '@/plugins/ut/ut-appliances'

  // hold a one second interval for updating system time and uptime
  let secondTimer = null

  export default {
    props: {
      appliance: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        systemTime: '',
        uptime: '',
      }
    },
    computed: {
      /**
       * Calculate string for hostname field
       * @returns {string}
       */
      applianceHostname() {
        let hostname =
          '<span class="font-weight-bold">' +
          (this.appliance.Hostname ? this.appliance.Hostname : this.$t('unknown_hostname'))
        hostname += '</span></br>' + this.$t('version') + ': '
        if (this.appliance.isUpdating === true) {
          hostname += ' <span class="red--text">'
          hostname += this.formattedSoftwareVersion
          hostname += ' [' + this.$t('update_initiated') + ' ] </span>'
        } else if (this.appliance.updatesAvailable === true) {
          hostname += '<span class="orange--text">'
          hostname += this.formattedSoftwareVersion
          hostname += ' [' + this.$t('updates_available') + ' ] </span>'
        } else hostname += this.formattedSoftwareVersion

        return hostname
      },
      /**
       * Returns string for software version field
       * @returns {string}
       */
      formattedSoftwareVersion() {
        return this.appliance.SoftwareVersion && this.appliance.SoftwareVersion !== false
          ? this.appliance.SoftwareVersion
          : this.$t('unknown_version')
      },
      /**
       * Returns string for how long since an appliance has connected
       * @returns {string}
       */
      calculatedOffLine() {
        if (this.appliance.LastSeen) return this.$vuntangle.dates.formatDateFromApi(this.appliance.LastSeen)
        else return this.$t('forever')
      },
      /**
       * Returns string for cpu count
       * @returns {*}
       */
      cpuCount() {
        return this.appliance.CoreCount > 0 ? this.appliance.CoreCount : this.$t('not_available')
      },
      /**
       * Returns string for appliance architecture
       * @returns {any}
       */
      platform() {
        return this.appliance.Platform ? this.appliance.Platform : this.$t('unknown_platform')
      },
      /**
       * Returns host count (accounts for fields not >=0
       * @returns {int}
       */
      hostCount() {
        return this.appliance.HostCount > 0 ? this.appliance.HostCount : 0
      },
      /**
       * Returns string with IP Address or 'Unknwon IP'
       * @returns {String}
       */
      ipAddress() {
        return this.appliance.IpAddress ? this.appliance.IpAddress : this.$t('unknown_ip')
      },
      /**
       * Returns name of network this appliance is assigned to, or 'Not Assigned'
       * @returns {string|*}
       */
      networkName() {
        if (!this.appliance.NetworkInfo) return this.$t('not_assigned')
        const pieces = this.appliance.NetworkInfo.split(':')
        return pieces[0]
      },
      /**
       * Returns if the warning tooltip for license status has to be shown
       *   this happens if StatusFlags have status flags and the state is warning
       * @returns {boolean}
       */
      showWarningLicenseTooltip() {
        return (
          this.appliance.StatusFlags.length === 2 &&
          this.appliance.StatusFlags[1].State === 'status_warning' &&
          !this.$store.state.data.ccViewModel.Account.NoLicenseEnforcement
        )
      },
      /**
       * Returns if the error tooltip for license status has to be shown
       *    this happens if StatusFlags have status flags and the state is error
       * @returns {boolean}
       */
      showErrorLicenseTooltip() {
        return (
          this.appliance.StatusFlags.length === 2 &&
          this.appliance.StatusFlags[1].State === 'status_error' &&
          !this.$store.state.data.ccViewModel.Account.NoLicenseEnforcement
        )
      },
      /**
       * Returns the license tooltip status if the license status is a warning or an error
       * @returns {String}
       */
      licenseTooltipText() {
        // Get the appliance's status flags
        const statusFlags = this.appliance.StatusFlags
        // StatusFlags to show exist if statusFlags has a length of 2
        if (statusFlags.length === 2) {
          // Get the statusFlag messages
          const messages = statusFlags[1].Messages
          let tooltipText = ''
          // Loop through messages to show
          for (const message of messages) {
            // Get the translation for the message
            const combinedTranslationText = this.$t(message)
            // Add the message to tooltip text if it is defined
            if (combinedTranslationText !== undefined) {
              tooltipText += combinedTranslationText
              tooltipText += '\n'
            }
          }
          // If the tooltip comes out as a length of 0, the messages were not found in the translations
          if (tooltipText.length === 0) {
            return this.$t('appliance_status_invalid_license_settings')
          }
          return tooltipText
        }
        return this.$t('valid_license')
      },
      timeZone: ({ $store }) =>
        $store.getters['appliances/getApplianceSettings']?.system?.timeZone?.displayName || 'UTC',
      isMFW() {
        return this.appliance.ProductLine === 'MFW'
      },
    },
    deactivated() {
      this.clearSecondTimer()
    },
    beforeDestroy() {
      this.clearSecondTimer()
    },
    mounted() {
      if (this.appliance.ProductLine === 'NGFW') this.uptime = this.appliance.uptime
      if (this.isMFW) this.setSystemTime()
    },
    methods: {
      /**
       * Compute system time property
       * @returns {Promise<void>}
       */
      async setSystemTime() {
        const response = await appliances.sendToApplianceApi(this.appliance.Uid, 'status/system', null, 'GET')

        if (response.success && response.data) {
          // set uptime
          this.uptime = response.data.uptime ? Math.round(response.data.uptime) : ''

          const systemClock = response.data.system_clock
          if (systemClock) {
            // set system time
            this.systemTime = this.$vuntangle.tz.getTimezoneDate(new Date(systemClock), this.timeZone)
          } else {
            this.systemTime = ''
          }
        }

        // set system and uptime interval
        this.clearSecondTimer()
        if (this.systemTime && this.uptime) {
          secondTimer = setInterval(() => {
            this.systemTime.setSeconds(this.systemTime.getSeconds() + 1)
            this.uptime++
          }, 1000)
        }
      },
      clearSecondTimer: () => clearInterval(secondTimer),
    },
  }
</script>
