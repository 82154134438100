<template>
  <div class="d-flex align-center">
    <u-btn
      icon
      outlined
      :small="false"
      :min-width="null"
      :disabled="!hasPrev"
      @click="updateCurrentIndex(currentIndex - 1)"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </u-btn>
    <div class="my-2 mx-4 flex-grow-1 overflow-y-auto" style="max-height: 45vh">
      <div v-for="formattedDetail in formattedDetails" :key="formattedDetail.name">
        <v-row no-gutters>
          <v-col :cols="5"
            ><strong> {{ formattedDetail.name }} </strong></v-col
          >
          <v-col :cols="7">
            <span class="text-left" :style="preWrap ? 'white-space: pre-wrap' : ''">{{
              formattedDetail.value
            }}</span></v-col
          >
        </v-row>
      </div>
    </div>
    <u-btn
      icon
      outlined
      :small="false"
      :min-width="null"
      :disabled="!hasNext"
      @click="updateCurrentIndex(currentIndex + 1)"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </u-btn>
    <v-overlay :value="detailsFetching" class="text-center" absolute color="rgba(255, 255, 255, 0.5)">
      <v-progress-circular indeterminate size="32" color="aristaMediumBlue"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
  import startCase from 'lodash/startCase'
  import util from '@/plugins/ut/ut-util'

  export default {
    props: {
      // list of records passed to detail dialog to navigate through
      filteredRecords: { type: Array, required: true },
      // index of the record being viewed from filteredAlerts
      currentIndex: { type: Number, required: true },
      preWrap: { type: Boolean, required: false, default: false },
    },
    util,
    data() {
      return {
        formattedDetails: [],
        details: undefined,
        detailsFetching: false,
      }
    },
    computed: {
      hasPrev: ({ currentIndex }) => currentIndex > 0,
      hasNext: ({ filteredRecords, currentIndex }) => currentIndex + 1 < filteredRecords.length,
    },
    watch: {
      currentIndex: {
        immediate: true,
        handler(index) {
          if (index > -1) {
            this.getDetails()
          }
        },
      },
    },
    methods: {
      updateCurrentIndex(value) {
        this.$emit('update:currentIndex', value)
      },

      /**
       * fetches more details if parent component passed getDetails listener
       *  otherwise uses the record we have to populate formattedDetails
       */
      getDetails() {
        this.detailsFetching = true
        const row = this.filteredRecords[this.currentIndex]
        if (this.$listeners['get-details']) {
          this.$emit('get-details', { row, callback: response => this.parseDetails(response) })
        } else {
          this.parseDetails(row)
        }
      },

      /**
       * populates formattedDetails using passed in object
       *
       * @param {Object} details record from which to populate formattedDetails
       */
      parseDetails(details) {
        this.details = details
        this.formattedDetails = []
        for (const property in details) {
          let val = details[property]
          if (property === 'DeviceId') val = util.obfuscateUid(val)
          if (property === 'Message') val = util.obfuscateUidsInString(val)
          const displayVal = util.formatValueForDisplay(property, val)
          const nxtObj = { name: startCase(property), value: displayVal }
          this.formattedDetails.push(nxtObj)
        }
        this.detailsFetching = false
      },
    },
  }
</script>
