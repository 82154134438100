/**
 * This plugin is a collection of globally available methods used throughout the project
 */
import api from '@/plugins/ut/ut-api'

const taskManager = {
  // hold the 'monitorTaskTimer' timer for keeping the current timer value
  monitorTaskTimer: null,

  /**
   * Reset the keep monitoring task timer.  This will get reset the base task timer
   *
   * @param {Object} timerValue
   *
   * @returns {void}
   */
  resetTimer(timerValue) {
    this.clearTimer()
    this.monitorTaskTimer = timerValue
  },

  /**
   * Clear the monitorTaskTimer.  This is done during logout and cleared before so that error toast message
   * should be eliminated if the user logs out.
   *
   * @returns {void}
   */
  clearTimer() {
    if (this.monitorTaskTimer) {
      clearTimeout(this.monitorTaskTimer)
      this.monitorTaskTimer = null
    }
  },

  /**
   * Creates an asynchronous task and optionally waits for the task to finish.
   *
   * @param {string}  execMethod   method in the backend
   * @param {[]}      execParams   method parameters, as an indexed array
   * @param {number}  priority     priority number for the backend
   * @param {boolean} startTaskNow true to bre processes now, false for
   *                               whenever the cron gets to it
   * @param {number}  statusCheckInterval milliseconds to wait for checking
   *                                      status
   *
   * @return {void}
   */
  async createTask(execMethod, execParams = [], onTaskFinished, startTaskNow = true, statusCheckInterval = 5000) {
    if (execParams) {
      execParams.execParams = execParams
    }

    const response = await api.cloud('Untangle_CommandCenter', execMethod, execParams)

    // if the task creation was not successful, run the callback without a
    // taskId and return
    const taskId = response.data
    if (!response.success || taskId === null) {
      if (onTaskFinished) {
        onTaskFinished(null)
      }

      return
    }
    // process the task if the task is to start immediately
    if (startTaskNow) {
      this.launchTaskProcessor([taskId])
    }

    // set to run method after task finishes
    if (onTaskFinished) {
      this.checkTaskStatus(taskId, onTaskFinished, statusCheckInterval)
    }
  },

  /**
   * Checks the status of the given task after the given time interval.
   * If the task has finished, onTaskFinished will be called and this
   * function will be exited. If the task has not yet finished, the status
   * will be rechecked until it has finished.
   *
   * @param {number} taskId - ID of the task to check
   * @param {Function} onTaskFinished function to call when the task has finished. this function will be provided a
   *                                   single parameter which task instance or null if the task was not found.
   * @param {number} statusCheckInterval number of milliseconds to wait before checking the task status
   *
   * @return {void}
   */
  async checkTaskStatus(taskId, onTaskFinished, statusCheckInterval) {
    // set a timeout to check if the task is finished and assign the timer value to be cleared later
    this.resetTimer(
      await setTimeout(async () => {
        // call the cloud to check if the task is finished
        const response = await api.cloud('Untangle_CommandCenter', 'FromTaskId', {
          taskId,
          paramOrder: 'taskId',
        })

        // if the task is finished run the callback
        const task = response.data
        if (
          response.success &&
          (task === null || task.Status === 'completed' || task.Status === 'failed' || task.Status === 'error')
        ) {
          onTaskFinished(task)
        } else {
          // run this method again to check if the task is finished
          this.checkTaskStatus(taskId, onTaskFinished, statusCheckInterval)
        }
      }, statusCheckInterval),
    )
  },

  /**
   * Launches a cron job that processes the given tasks
   * WARNING - this WILL check the current task state and will only process
   * tasks that are in queued state
   *
   * @param {int[]} taskIds array of IDs of the tasks that need to be
   *                        processed.
   *
   * @return {void}
   */
  launchTaskProcessor(taskIds) {
    api.cloud('Untangle_CommandCenter', 'LaunchTaskProcessor', {
      taskIds,
      paramOrder: 'taskIds',
    })
  },
}

export default taskManager
