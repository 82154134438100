<!-- page to display the add or edit page of mfw policies -->
<template>
  <component
    :is="`${componentName}`"
    ref="component"
    :disabled="false"
    :settings="settings"
    :applications="applications"
    classic-view
  >
    <template #actions="{ newSettings }">
      <u-btn text :to="{ name: 'mfw-policies-types' }">
        <span :class="`${$vuetify.theme.dark ? 'white--text' : ''}`">
          {{ $t('back_to_list') }}
        </span>
      </u-btn>
      <u-btn @click="onSaveSetting(newSettings)">
        {{ $t('save') }}
      </u-btn>
    </template>
    <template #extra-fields>
      <ValidationObserver ref="extraFields">
        <!-- name and description field -->
        <name-description
          :name.sync="cloudPolicy.Name"
          :description.sync="cloudPolicy.Description"
          description-required
        />
      </ValidationObserver>
    </template>
  </component>
</template>
<script>
  import {
    SettingsGeoipFilter,
    SettingsNetworkDiscovery,
    SettingsThreatPrevention,
    SettingsWebFilter,
    WanPolicy,
    SettingsApplicationControl,
    Dns,
    SettingsCaptivePortal,
  } from 'vuntangle'
  import NameDescription from '@/pages/policies/mfw/policy-manager/components/NameDescription.vue'
  import store from '@/store'
  import vuntangle from '@/plugins/vuntangle'
  import i18n from '@/plugins/vue-i18n'
  import { mfwServices, getPolicy, savePolicy } from '@/util/mfwServices'

  export default {
    components: {
      NameDescription,
      SettingsGeoipFilter,
      SettingsNetworkDiscovery,
      SettingsThreatPrevention,
      SettingsWebFilter,
      WanPolicy,
      SettingsApplicationControl,
      Dns,
      SettingsCaptivePortal,
    },
    // load the policy before displaying the page
    async beforeRouteEnter(to, from, next) {
      // return if no policy to load before this is an 'add' action
      await store.dispatch('data/fetchApplications')
      if (to.params.id === 'add') {
        next(async vm => {
          vm.applications = store.getters['data/applications']

          /*
           * get any default settings from ETM if needed, currently used in policy manager to get already built configs
           */
          const getDefaultSettings = mfwServices[to.params.policyPage].getDefaultSettings
          if (getDefaultSettings) {
            vm.settings = await getDefaultSettings()
          }
        })
        return
      }

      // get policy for an edit
      store.commit('SET_PAGE_LOADER', true)
      const response = await getPolicy(to.params.id)
      store.commit('SET_PAGE_LOADER', false)

      // display an error and redirect if policy of an 'edit' was not found
      if (!response.data) {
        vuntangle.toast.add(i18n.t('policy_not_found'), 'error')
        next({ name: 'mfw-policies-types', params: { policyPage: to.params.policyPage } })
        return
      }

      // pass data to component, done this way as the component itself can only be accessed via a callback in
      // beforeRouteEnter
      next(async vm => {
        vm.cloudPolicy = response.data
        vm.settings = response.data.PolicyJson

        /*
         * get any default settings from ETM if needed, currently used in policy manager to get already built configs
         */
        const getDefaultSettings = mfwServices[to.params.policyPage].getDefaultSettings
        if (getDefaultSettings) {
          vm.settings = await getDefaultSettings(vm.settings)
        }

        vm.applications = store.getters['data/applications'] || []
      })
    },
    props: {
      policyPageTitle: { type: String, required: false, default: '' },
      showSettings: { type: Boolean, required: false, default: true },
    },
    data() {
      return {
        cloudPolicy: {
          Name: '',
          Description: '',
        },
        settings: null,
        applications: [],
      }
    },
    computed: {
      // the policy page based on the route
      serviceKey() {
        return this.$route.params.policyPage
      },
      policyId() {
        return this.$route.params.id
      },

      type: ({ serviceKey }) => mfwServices[serviceKey].type,
      componentName: ({ serviceKey }) => mfwServices[serviceKey].componentName,
    },
    methods: {
      /**
       * Trigger when save button is pressed.  Check validation, send a create/update policy to the backend,
       * display toast on response.
       *
       * @param settingObj
       * @returns {Promise<*>}
       */
      async onSaveSetting(settingObj) {
        // validate name and description fields
        const valid = await this.$refs.extraFields.validate()
        const componentValid = this.$refs.component.validate ? await this.$refs.component.validate() : true
        if (!valid || !componentValid) {
          return
        }

        this.cloudPolicy.Type = this.type === 'application-control' ? this.type + '-mfw' : this.type
        if (this.policyId !== 'add') {
          this.cloudPolicy.Id = this.policyId
        }

        this.cloudPolicy.PolicyJson = settingObj

        // add or update policy in the backend
        store.commit('SET_PAGE_LOADER', true)
        const response = await savePolicy(this.cloudPolicy, this.cloudPolicy.Type)
        store.commit('SET_PAGE_LOADER', false)

        if (response.success && response.data) {
          vuntangle.toast.add(this.$t('saved_successfully', [this.$t('template')]))
          this.settings = settingObj
        } else {
          vuntangle.toast.add(this.$t('unable_to_save', [this.$t('template')]), 'error')
        }

        // get back to the correct policy listing page
        this.$router.push({ name: 'mfw-policies-types', params: { policyPage: this.serviceKey } })
      },
    },
  }
</script>
