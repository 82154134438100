<template>
  <u-widget
    :title="$t('network_performance')"
    :info="$t('info_network_performance')"
    enable-refresh
    @refresh="fetchNetworkPerformance"
    v-on="$listeners"
  >
    <u-grid
      id="network-performance"
      toolbar="hidden"
      :no-data-message="$t('no_data')"
      :custom-grid-options="{ pinnedTopRowData: networkPerformanceSummaryRow }"
      :column-defs="networkPerformanceColumnDefs"
      :fetching="networkPerformanceFetching"
      :row-data="networkPerformance"
      :resize-columns.sync="resizeEvent"
      @refresh="fetchNetworkPerformance"
      @row-clicked="viewDetails"
    />
    <u-dialog
      :show-dialog="!!filteredRecords.length"
      :title="$t('additional_information')"
      :width="700"
      :buttons="[
        {
          'name': $t('ok'),
          'handler': 'close-dialog',
        },
      ]"
      @close-dialog="onCloseDialog"
    >
      <extra-details :filtered-records="filteredRecords" :current-index.sync="currentIndex" />
    </u-dialog>
  </u-widget>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import grids from '@/plugins/ut/ut-grids'
  import reports from '@/plugins/ut/ut-reports'
  import util from '@/plugins/ut/ut-util'
  import WidgetMixin from '@/components/widgets/WidgetMixin'
  import ExtraDetails from '@/components/widgets/ExtraDetails'

  export default {
    components: { ExtraDetails },
    mixins: [WidgetMixin],
    props: {
      uids: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        networkPerformance: [],
        networkPerformanceFetching: false,
        networkPerformanceSummaryRow: [],
        // list of records passed to detail dialog to navigate through
        filteredRecords: [],
        // index of the record being viewed from filteredRecords
        currentIndex: -1,
      }
    },
    computed: {
      networkPerformanceColumnDefs: () =>
        grids.mergeWithApplianceColumns(grids.getNetworkPerformanceColumnDefs({ appliance: { hide: false } }), [
          'appliance',
          'tag',
          'uid',
        ]),
    },
    watch: {
      uids: {
        immediate: true,
        handler() {
          this.fetchNetworkPerformance()
        },
      },
    },
    methods: {
      // NOTE - this needs logic for single UID filled in
      async fetchNetworkPerformance() {
        this.networkPerformanceFetching = true
        const response = await reports.getReportData(
          'NetworkPerformance',
          this.uids,
          this.$vuntangle.dates.getDateString(new Date(), -1),
          '',
        )
        const gridData = []
        for (const key in response) {
          // check to make sure that the key is an actual property of the object, and doesn't come from the prototype.
          const row = cloneDeep(response[key])
          // These fields require some customization before sending to grid
          row.ApplianceHostname = util.uidToHostname(row.Uid)
          row.ApplianceTag = util.uidToApplianceTag(row.Uid)
          row.PacketLoss = row.AveragePacketLossSending + row.AveragePacketLossReceiving
          // Push this row onto the grid
          gridData.push(row)
        }
        // compute summary information for grid
        const hostName = this.$t('network_average')
        let count = 0
        let averageDownloadRate = 0
        let totalDownload = 0
        let averageUploadRate = 0
        let totalUpload = 0
        let averageJitter = 0
        let averageLatency = 0
        let packetLoss = 0
        gridData.forEach(row => {
          count++
          averageDownloadRate += row.AverageDownloadRate
          totalDownload += row.TotalDownload
          averageUploadRate += row.AverageUploadRate
          totalUpload += row.TotalUpload
          averageJitter += row.AverageJitter
          averageLatency += row.AverageLatency
          packetLoss += row.PacketLoss
        })
        if (count > 0) {
          averageDownloadRate = averageDownloadRate / count
          totalDownload = totalDownload / count
          averageUploadRate = averageUploadRate / count
          totalUpload = totalUpload / count
          averageJitter = averageJitter / count
          averageLatency = averageLatency / count
          packetLoss = packetLoss / count
        }
        this.networkPerformanceSummaryRow = [
          {
            ApplianceHostName: hostName,
            ApplianceTag: '',
            InterfaceName: '',
            Uid: '',
            AverageDownloadRate: averageDownloadRate,
            TotalDownload: totalDownload,
            AverageUploadRate: averageUploadRate,
            TotalUpload: totalUpload,
            AverageJitter: averageJitter,
            AverageLatency: averageLatency,
            PacketLoss: packetLoss,
            StartDate: '',
            EndDate: '',
          },
        ]

        /**
         * Merge gridData - network performance data with appliance data
         */
        this.networkPerformance = this.$store.getters['appliances/populateApplianceInfoByUid'](gridData, 'Uid')
        this.networkPerformanceFetching = false
      },

      onCloseDialog() {
        this.filteredRecords = []
        this.currentIndex = -1
      },

      /**
       * Called when user clicks on a row
       * builds the props needed for details dialog and displays the dialog
       * @param {*} param clicked row to fetch details for
       */
      viewDetails(params) {
        params.api.forEachNodeAfterFilterAndSort(node => {
          this.filteredRecords.push(node.data)
        })
        this.currentIndex = params.node.rowIndex
      },
    },
  }
</script>
