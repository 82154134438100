<template>
  <u-page :title="$t('appliances')" :subtitle="$t('manage_message')" full-height>
    <template #actions>
      <u-btn :disabled="!$store.getters['data/hasAddApplianceAccess']" @click="addAppliancePage">
        {{ $t('add_appliance') }}
      </u-btn>
      <u-btn :to="{ name: 'appliances-update' }" :disabled="!appliances" @click="isUpdateMode = true">
        {{ $t('update_software') }}
      </u-btn>
    </template>

    <u-grid
      id="appliances-grid"
      :selection-type="rowSelect"
      :no-data-message="$t('no_data')"
      :column-defs="columnDefs"
      :fetching="$store.state.appliances.fetching || $store.state.appliances.fetchingUnassigned"
      :row-data="appliances"
      :selection.sync="selectedAppliances"
      @refresh="$store.dispatch('appliances/fetchFullAppliances', { force: true })"
      @row-clicked="onRowClicked"
    >
    </u-grid>
  </u-page>
</template>

<script>
  import grids from '@/plugins/ut/ut-grids'

  export default {
    data() {
      return {
        selectedAppliances: [], // array of selected appliances for update
        addApplianceDialog: false, // boolean for showing the appliance add dialog
        rowSelect: null, // the grid selection type ('multiple')
      }
    },
    computed: {
      // grid data returning all appliances or only upradable appliances
      appliances() {
        return this.$store.getters['appliances/fullList']
      },
      // appliances columns definitions and order
      columnDefs() {
        return grids.getApplianceGridColumns(
          {},
          this.$store.state.data.ccViewModel.Account.NoLicenseEnforcement ? ['license'] : [],
        )
      },
    },

    created() {
      this.$store.commit('SET_APPLIANCES_SELECTION', this.$route.path)
      this.$store.dispatch('appliances/fetchFullAppliances')
    },

    methods: {
      onRowClicked({ data: appliance }) {
        this.$router.push({ name: 'appliances-id', params: { id: appliance.UniqueIdentifier } })
      },
      /**
       * Called if an appliance is added to the system from this page.  Routes to the newly-added appliance
       * @param uniqueIdentifier
       */
      onApplianceAdded(uniqueIdentifier) {
        this.$router.push({ name: 'appliances-id', params: { id: uniqueIdentifier } })
      },
      /**
       * Executed when user presses "Add Appliance" button
       */
      addAppliancePage() {
        window.ga('send', { hitType: 'event', eventCategory: 'Appliances', eventAction: 'Add Appliance' })
        this.$router.push({ name: 'appliances-add' })
      },
    },
  }
</script>
