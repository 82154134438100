<template>
  <div class="d-flex align-center">
    <h4 class="mr-2">Set new destination:</h4>
    <v-row dense class="align-center">
      <v-col cols="6">
        <ValidationProvider v-slot="{ errors }" rules="required|ip">
          <u-text-field v-model="action.dnat_address" :label="$vuntangle.$t('address')" :error-messages="errors">
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-text-field>
        </ValidationProvider>
      </v-col>
      <v-col>
        <ValidationProvider v-slot="{ errors }" rules="port">
          <u-text-field
            v-model="action.dnat_port"
            :label="$vuntangle.$t('port_optional')"
            :error-messages="errors"
            clearable
          >
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  export default {
    inject: ['$action'],
    computed: {
      action: ({ $action }) => $action(),
    },
    watch: {
      'action.dnat_port'(value) {
        if (!value) {
          this.$delete(this.action, 'dnat_port')
        }
      },
    },
  }
</script>
