<template>
  <not-compatible v-if="notCompatible" :feature-name="serviceConfig.tkey" />
  <analyzers-list
    v-else
    :settings="settings"
    :features="features"
    :interfaces="interfaces"
    :is-changed.sync="isChanged"
    :disabled="readOnly"
    @delete-ping-analyzer="onDeletePingAnalyzer"
    @delete-interface-tracker="onDeleteInterfaceTracker"
    @save-analyzers="onSave"
  >
    <template #actions="{ newSettings, isDirty }">
      <u-btn class="mr-2" :disabled="readOnly" @click="onReset()">{{ $t('reset_to_defaults') }}</u-btn>
      <u-btn :min-width="null" :disabled="!isDirty" @click="onSave(newSettings.pingAnalyzers)">
        {{ $t('save') }}
      </u-btn>
    </template>
  </analyzers-list>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { AnalyzersList } from 'vuntangle'
  import { NotCompatible } from '../layout'
  import settingsMixin from './settingsMixin'
  import { mfwServices, ServiceName } from '@/util/mfwServices'
  import appliance from '@/plugins/ut/ut-appliances'

  export default {
    components: { NotCompatible, AnalyzersList },
    mixins: [settingsMixin],
    computed: {
      settings: ({ boxSettings }) => ({
        pingAnalyzers: boxSettings.stats.pingAnalyzers,
        track: boxSettings.network.track,
      }),
      features: ({ enableInterfaceTracking }) => ({ interfaceTracking: enableInterfaceTracking }),
      enableInterfaceTracking: ({ appliance }) => appliance?.features.hasInterfaceTracking || false,
      interfaces: ({ boxSettings }) => boxSettings.network.interfaces,
      serviceConfig: () => mfwServices[ServiceName.StatusAnalyzers],
      interfaceTrackerConfig: () => mfwServices[ServiceName.InterfaceTrackers],
      settingsPath: ({ serviceConfig }) => serviceConfig.settingPathForBox,
      notCompatible: ({ appliance, serviceConfig }) => parseFloat(appliance?.SoftwareVersion) < serviceConfig?.version,
    },
    methods: {
      // delete analyzer based on index
      onDeletePingAnalyzer(index) {
        const analyzersCopy = cloneDeep(this.settings.pingAnalyzers)
        analyzersCopy.splice(index, 1)
        this.onSave(analyzersCopy)
      },

      // delete interface tracker based on index
      onDeleteInterfaceTracker(index) {
        const trackersCopy = cloneDeep(this.settings.track)
        const tracker = trackersCopy.splice(index, 1)
        this.unlinkTrackerFromInterfaces(tracker[0])
        this.onSave(trackersCopy, false)
      },

      unlinkTrackerFromInterfaces(tracker) {
        let isLinkedToIntf = false
        const interfacesCopy = cloneDeep(this.interfaces)
        interfacesCopy.forEach(intf => {
          intf.vrrptrack = (intf.vrrptrack || []).filter(({ name }) => {
            const result = name !== tracker.name
            if (!result) {
              isLinkedToIntf = true
            }
            return result
          })
        })
        if (!isLinkedToIntf) {
          return
        }
        appliance.sendToApplianceApi(this.uid, 'settings/network/interfaces', interfacesCopy)
      },

      /**
       * saves the new settings object against the appliance
       * this method is called when you change the enabled state of a ping analyzer
       * @param {Object} newSettings settings Object to be saved
       * @param {boolean} isPingAnalyzer whether to save ping analyzers or interface trackers
       */
      onSave(newSettings, isPingAnalyzer = true) {
        const path = isPingAnalyzer ? this.settingsPath : this.interfaceTrackerConfig.settingPathForBox
        this.saveSettings(path, newSettings)
      },

      /**
       * reverts the ping analyzers back to the defaults
       */
      onReset() {
        this.$vuntangle.confirm.show({
          title: this.$t('warning'),
          message: this.$t('reset_analyzers_warning'),
          confirmLabel: this.$t('yes'),
          cancelLabel: this.$t('no'),
          action: async resolve => {
            const result = await appliance.sendToApplianceApi(this.appliance.Uid, `defaults/stats/pingAnalyzers`)
            if (result.success) {
              await this.fetchSettings()
              this.$vuntangle.toast.add(this.$t('reset_analyzers_success'))
            } else {
              this.$vuntangle.toast.add(this.$t('an_error_occurred'), 'error')
            }
            resolve()
          },
        })
      },
    },
  }
</script>
