<template>
  <ngfw-policies-grid
    v-if="category"
    class="flex-grow-1"
    :type="type"
    :title="category.name"
    :scope="category.scope"
    :policy-manager-required="category.policyManagerRequired"
  />
  <u-page v-else :title="$t('ngfw_policies')">
    <v-list-item>
      <v-list-item-content>
        <u-alert error>
          {{ $t('policy_not_found') }}
        </u-alert>
      </v-list-item-content>
    </v-list-item>
  </u-page>
  <!-- </u-page> -->
</template>

<script>
  import NgfwPoliciesGrid from '@/components/policies/NgfwPoliciesGrid'

  export default {
    components: { NgfwPoliciesGrid },
    computed: {
      category() {
        return this.categories[this.type]
      },
      categories() {
        return {
          'firewall': {
            id: 'firewall',
            name: this.$t('firewall'),
            scope: 'rack-specific',
            policyManagerRequired: true,
          },
          'phish-blocker': {
            id: 'phishblocker',
            name: this.$t('phish_blocker'),
            scope: 'rack-specific',
            policyManagerRequired: true,
          },
          'virus-blocker': {
            id: 'virusblocker',
            name: this.$t('virus_blocker'),
            scope: 'rack-specific',
            policyManagerRequired: true,
          },
          'application-control': {
            id: 'applicationcontrol',
            name: this.$t('application_control'),
            scope: 'rack-specific',
            policyManagerRequired: true,
          },
          'ssl-inspector': {
            id: 'sslinspector',
            name: this.$t('ssl_inspector'),
            scope: 'rack-specific',
            policyManagerRequired: true,
          },
          'spam-blocker': {
            id: 'spamblocker',
            name: this.$t('spam_blocker'),
            scope: 'service',
            policyManagerRequired: true,
          },
          'web-filter': {
            id: 'webfilter',
            name: this.$t('web_filter'),
            scope: 'rack-specific',
            policyManagerRequired: true,
          },
          'bandwidth-control': {
            id: 'bandwidthcontrol',
            name: this.$t('bandwidth_control'),
            scope: 'rack-specific',
            policyManagerRequired: true,
          },
          'wan-balancer': {
            id: 'wanbalancer',
            name: this.$t('wan_balancer'),
            scope: 'service',
            policyManagerRequired: false,
          },
          'wan-failover': {
            id: 'wanfailover',
            name: this.$t('wan_failover'),
            scope: 'service',
            policyManagerRequired: false,
          },
          'web-cache': {
            id: 'webcache',
            name: this.$t('web_cache'),
            scope: 'rack-specific',
            policyManagerRequired: true,
          },
          'captive-portal': {
            id: 'captiveportal',
            name: this.$t('captive_portal'),
            scope: 'rack-specific',
            policyManagerRequired: true,
          },
          'ipsec-vpn': {
            id: 'ipsecvpn',
            name: this.$t('ipsec_vpn'),
            scope: 'service',
            policyManagerRequired: false,
          },
          'openvpn': {
            id: 'openvpn',
            name: this.$t('openvpn'),
            scope: 'service',
            policyManagerRequired: false,
          },
          'tunnel-vpn': {
            id: 'tunnelvpn',
            name: this.$t('tunnel_vpn'),
            scope: 'service',
            policyManagerRequired: false,
          },
          'directory-connector': {
            id: 'directoryconnector',
            name: this.$t('directory_connector'),
            scope: 'service',
            policyManagerRequired: false,
          },
          'policy-manager': {
            id: 'policymanager',
            name: this.$t('policy_manager'),
            scope: 'service',
            policyManagerRequired: false,
          },
          'branding-manager': {
            id: 'brandingmanager',
            name: this.$t('branding_manager'),
            scope: 'service',
            policyManagerRequired: false,
          },
        }
      },
      type() {
        return this.$route.params.type
      },
    },
  }
</script>
