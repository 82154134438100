<template>
  <div>
    <h4>{{ $t('user') }}</h4>
    <p class="text-caption">{{ $t('input_a_comma_separated_list_of_ips_cidrs') }}</p>

    <ValidationProvider v-slot="{ errors }" rules="required|ip_any_expression_cidr_list">
      <u-text-field v-model="users" :error-messages="errors" data-testid="user-list">
        <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
      </u-text-field>
    </ValidationProvider>

    <v-spacer />
    <slot name="actions" :new-settings="settingsCopy" />
  </div>
</template>
<script>
  export default {
    props: {
      /**
       * the object data (PolicyJson) expected in the form
       *   id: '<id that should match Policy Id>',
       *   name: '<a name>',
       *   items: ['user1', 'user2', ...],
       *   type: <the type as expected on backend>,
       * */
      settings: { type: Object, default: () => {} },
    },
    data() {
      return {
        // local cloned settings
        settingsCopy: { ...this.settings },
      }
    },
    computed: {
      /** getter/setter to edit users via a comma separated values textfield */
      users: {
        get() {
          return this.settingsCopy.items.join(', ')
        },
        set(value) {
          this.settingsCopy.items = value.split(',').map(item => item.trim())
        },
      },
    },
  }
</script>
