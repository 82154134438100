<template>
  <u-widget :title="$t('appliances')" v-on="$listeners">
    <v-card-actions class="my-1 px-0">
      <u-btn class="ml-2 mr-2" :disabled="network == null" @click="doAdd">
        {{ $t('add_appliance') }}
      </u-btn>
      <u-btn
        :disabled="
          deleteAppliancesSelected.length === 0 ||
          appliancesRowData.length === 1 ||
          appliancesRowData.length === deleteAppliancesSelected.length
        "
        class="mr-2"
        @click="deleteAppliancesDialog = true"
      >
        {{ $t('remove_appliances') }}
      </u-btn>
    </v-card-actions>
    <u-grid
      id="network-delete-appliances"
      toolbar="hidden"
      row-node-id="Uid"
      selection-type="multiAction"
      :no-data-message="$t('no_data')"
      :column-defs="appliancesColumnDefs"
      :fetching="$store.state.networks.fetching || $store.state.appliances.fetching"
      :row-data="appliancesRowData"
      :selection.sync="deleteAppliancesSelected"
      @refresh="$store.dispatch('appliances/fetchAppliances', { force: true })"
    />
    <u-dialog
      :show-dialog="addAppliancesDialog"
      :title="$t('add_appliance')"
      :buttons="addAppliancesDialogButtons"
      width="80%"
      @close-dialog="addAppliancesDialog = false"
      @addAppliances="addAppliances"
    >
      <u-grid
        v-if="addAppliancesDialog"
        id="network-add-appliances"
        row-node-id="Uid"
        selection-type="multiAction"
        style="height: 300px"
        :no-data-message="$t('no_data')"
        :column-defs="addAppliancesColumnDefs"
        :fetching="$store.state.appliances.fetching"
        :row-data="addAppliancesRowData"
        :selection.sync="addAppliancesSelected"
        @refresh="$store.dispatch('appliances/fetchAppliances', { force: true })"
      />
      <div v-if="addAppliancesSaving" class="d-flex flex-column align-stretch fill-height">
        <v-overlay :absolute="true" opacity="0.5" color="#333" z-index="999" class="text-center" />
      </div>
    </u-dialog>
    <u-dialog
      :show-dialog="deleteAppliancesDialog"
      :title="$t('remove_appliances')"
      :message="$t('confirm_remove_appliances')"
      :buttons="[
        { name: $t('cancel') },
        {
          name: $t('yes'),
          handler: 'deleteAppliances',
          showProgress: true,
        },
      ]"
      @close-dialog="deleteAppliancesDialog = false"
      @deleteAppliances="deleteAppliances"
    />
  </u-widget>
</template>

<script>
  import grids from '@/plugins/ut/ut-grids'
  import store from '@/store'
  export default {
    props: {
      network: {
        type: Object,
        default: null,
      },
      appliancesRowData: {
        type: Array,
        default: () => [],
      },
      addAppliancesRowData: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        addAppliancesDialog: false,
        addAppliancesDialogButtons: [
          { name: this.$t('cancel') },
          {
            name: this.$t('add_selected_appliances'),
            handler: 'addAppliances',
            disabled: true,
            showProgress: true,
          },
        ],
        deleteAppliancesDialog: false,
        deleteAppliancesSelected: [],
        addAppliancesSaving: false,
        addAppliancesSelected: [],
        addAppliancesRequired: false,
      }
    },
    computed: {
      addAppliancesColumnDefs: () =>
        grids.getApplianceGridColumns({}, store.state.data.ccViewModel.Account.NoLicenseEnforcement ? ['license'] : []),
      appliancesColumnDefs: () =>
        grids.getApplianceGridColumns({}, store.state.data.ccViewModel.Account.NoLicenseEnforcement ? ['license'] : []),
    },
    watch: {
      /**
       * Enebles the add appliances button when a selection is made
       */
      addAppliancesSelected() {
        const [, addButton] = this.addAppliancesDialogButtons
        addButton.disabled = this.addAppliancesSelected.length === 0
      },
      /**
       * Deselect selected rows when retrieving row data
       */
      appliancesRowData: {
        handler() {
          this.deleteAppliancesSelected = []
        },
      },
    },
    created() {
      this.$store.dispatch('networks/fetchNetworks')
      this.$store.dispatch('appliances/fetchAppliances')
    },
    methods: {
      /**
       * Add an appliance to a network.
       *
       * @return {void}
       */
      async addAppliances() {
        this.addAppliancesSaving = true

        // get selected uids for adding
        const addUids = this.addAppliancesSelected.map(appliance => appliance.Uid)

        // dispatch a 'add appliances' update
        const success = await this.$store.dispatch('networks/addNetworkAppliances', {
          network: this.network,
          addUids,
        })
        if (success) {
          this.$vuntangle.toast.add(this.$t('add_appliances_success'))
        } else {
          this.$vuntangle.toast.add(this.$t('add_appliances_failure'), 'error')
        }

        this.addAppliancesSaving = false
        this.addAppliancesDialog = false
        this.addAppliancesSelected = []
      },
      doAdd() {
        this.deleteAppliancesSelected = []
        this.addAppliancesDialog = true
      },

      /**
       * Delete an appliance from the network.
       *
       * @return {void}
       */
      async deleteAppliances() {
        // get selected uids for removal
        const removeUids = this.deleteAppliancesSelected.map(appliance => appliance.Uid)

        // dispatch a 'delete appliances' update
        const success = await this.$store.dispatch('networks/removeNetworkAppliances', {
          network: this.network,
          removeUids,
        })
        if (success) {
          this.$vuntangle.toast.add(this.$t('remove_network_appliance_success'))
        } else {
          this.$vuntangle.toast.add(this.$t('remove_appliance_failure'), 'error')
        }

        this.deleteAppliancesDialog = false
        this.deleteAppliancesSelected = []
      },
    },
  }
</script>
