var render = function render(){var _vm=this,_c=_vm._self._c;return _c('u-widget',_vm._g({attrs:{"title":_vm.$t('resources'),"enable-expand":false}},_vm.$listeners),[_c('div',{staticClass:"d-flex flex-row justify-space-around ma-8"},_vm._l((_vm.resources),function(res,key){return _c('div',{key:key,staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t(res.name))+" "),_c('br'),_c('strong',[_vm._v(_vm._s(res.total || _vm.$t('not_applicable')))]),_c('br'),_c('v-progress-circular',{class:`mt-4 ${_vm.$store.state.isDark ? 'ut-dark' : 'ut-light'}`,attrs:{"rotate":-90,"size":100,"width":10,"value":_vm.online && res.percent ? res.percent : 0,"color":_vm.computeColor(res.percent)}},[_c('span',{class:`text-center text-h5 font-weight-light ${_vm.$store.state.isDark ? 'white--text' : 'black--text'}`,domProps:{"textContent":_vm._s(
            _vm.online
              ? key !== 'cpu'
                ? res.percent && res.percent >= 0
                  ? res.percent + '%'
                  : _vm.$t('not_applicable')
                : res.total
              : _vm.$t('not_applicable')
          )}})])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }