<template>
  <u-widget :title="$t('sdn')" v-on="$listeners">
    <div class="d-flex flex-grow-1 flex-column">
      <v-card-actions>
        <u-btn
          class="mr-2"
          :disabled="vpnApplianceSelected.length === 0 || isNotOnLine === true || isLicensed === false"
          @click="getApplianceConfiguration"
        >
          {{ $t('configuration') }}
        </u-btn>
        <u-btn class="mr-2" :disabled="configuredAppliances <= 1" @click="syncVpnInfo">
          {{ $t('sync_vpn_settings') }}
        </u-btn>
        <u-btn class="mr-2" @click="resetVpnTunnelsDialog = true">
          {{ $t('reset_to_factory') }}
        </u-btn>
        <div v-if="syncMessage">
          {{ syncMessage }}
          <router-link :to="{ name: 'alerts-audit-history' }"> {{ $t('audit_history') }} </router-link>
        </div>
      </v-card-actions>
      <u-grid
        v-if="network !== null"
        id="network-vpn-appliances"
        class="d-flex fill-height"
        toolbar="hidden"
        row-node-id="Uid"
        is-widget
        :row-select="{ type: 'single', checkbox: false }"
        :row-data="rowData"
        :column-defs="vpnAppliancesColumnDef"
        :selection.sync="vpnApplianceSelected"
        :enable-refresh="false"
      />
      <u-dialog
        v-if="vpnApplianceSelected.length > 0"
        :show-dialog="editConfiguration"
        :title="$t('configuration') + ' - ' + vpnApplianceSelected[0].Hostname"
        :message="$t('enable_vpn_message')"
        width="80%"
        :buttons="[
          { name: $t('cancel') },
          {
            name: $t('save'),
            handler: 'saveConfiguration',
            showProgress: true,
            disabled: configSaveButtonDisabled,
          },
        ]"
        @close-dialog="closeConfigurationWindow"
        @saveConfiguration="saveConfiguration"
      >
        <v-switch v-model="selectedStatus" :label="$t('enable')" hide-details class="mb-4"></v-switch>
        <u-alert v-if="conflictingSubnets.length > 0">
          {{ $t('conflicting_subnets') }}
        </u-alert>
        <div v-if="selectedStatus">
          <ValidationObserver ref="validateForm">
            <u-section :title="$t('endpoint_address')">
              <v-switch v-model="automatic" :label="autoPrompt" hide-details class="mb-4"></v-switch>
              <div v-if="automatic == true">{{ $t('automatic_config') }}</div>
              <div v-else>
                <ValidationProvider v-slot="{ errors }" name="tag" rules="required|hostname">
                  <u-text-field
                    v-model="overrideIP"
                    :label="$t('endpoint_address_override')"
                    class="my-4 mt-4"
                    :error-messages="errors"
                    maxlength="70"
                  >
                    <template v-if="errors.length" #append>
                      <u-errors-tooltip :errors="errors" />
                    </template>
                  </u-text-field>
                </ValidationProvider>
              </div>
            </u-section>
            <u-section :title="$t('shared_subnets')">
              <div v-if="selectedProduct === 'MFW'">
                {{ $t('edit_shared_subnets_message') }}
              </div>
              <div v-else>
                {{ $t('edit_shared_subnets_message_ngfw') }}
              </div>
              <v-list dense>
                <v-list-item v-for="(subnet, index) in subnets" :key="index" dense>
                  <u-checkbox
                    v-model="selectedSubnets"
                    :label="$t(subnet)"
                    :value="subnet"
                    :disabled="conflictingSubnets.includes(subnet)"
                    :color="conflictingSubnets.includes(subnet) ? 'red' : ''"
                    hide-details
                    class="mt-0 pa-0"
                  >
                    <template #label>
                      <span :class="conflictingSubnets.includes(subnet) ? 'red--text' : ''">
                        {{ $t(subnet) }}
                      </span>
                    </template>
                  </u-checkbox>
                </v-list-item>
              </v-list>
            </u-section>
          </ValidationObserver>
        </div>
      </u-dialog>
      <u-dialog
        :show-dialog="showVpnExplanation"
        :title="$t('appliance_incompatible_with_vpn_title')"
        width="80%"
        :message="incompatibilityMessage"
        @close-dialog="showVpnExplanation = false"
      >
      </u-dialog>
      <u-dialog
        :show-dialog="resetVpnTunnelsDialog"
        :title="$t('reset_to_factory')"
        :message="$t('confirm_reset_vpn_tunnels')"
        :buttons="[
          { name: $t('cancel') },
          {
            name: $t('yes'),
            handler: 'resetVpnInfo',
            showProgress: true,
          },
        ]"
        @close-dialog="resetVpnTunnelsDialog = false"
        @resetVpnInfo="resetVpnInfo"
      />
    </div>
  </u-widget>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import vuntangle from '@/plugins/vuntangle'
  import grids from '@/plugins/ut/ut-grids'
  import appliances from '@/plugins/ut/ut-appliances'
  import api from '@/plugins/ut/ut-api'
  export default {
    props: {
      network: {
        type: Object,
        default: null,
      },
      appliancesRowData: {
        type: Array,
        default: null,
      },
    },
    data() {
      return {
        vpnApplianceSelected: [],
        editConfiguration: false,
        editVpnAccess: false,
        subnets: [],
        configFetching: false,
        showVpnExplanation: false,
        incompatibilityList: '',
        versionError: '',
        selectedSubnets: [],
        selectedStatus: false,
        selectedProduct: '',
        automatic: true,
        overrideIP: null,
        publicIP: null,
        selectedAppliances: [],
        conflictingSubnets: [],
        syncMessage: '',
        configuredAppliances: 0,
        resetVpnTunnelsDialog: false,
      }
    },
    computed: {
      vpnAppliancesColumnDef: () =>
        grids.mergeWithApplianceColumns(grids.getVpnAppliancesColumnDefs(), ['appliance', 'uid', 'ip_address']),

      /**
       * Checks appliance status flags, returns true if it's not currently connected to cmd
       */
      isNotOnLine() {
        const getByUid = this.$store.getters['appliances/getByUid']
        const appliance = getByUid(this.vpnApplianceSelected[0].Uid)
        return appliance ? !appliance.IsConnectedToCmd : true
      },
      /**
       * Checks appliance status flags, returns true if it's status does not indicate a complete license
       * currently in effect.
       */
      isLicensed() {
        if (!this.vpnApplianceSelected) return false
        else return appliances.isLicensedForVPN(this.vpnApplianceSelected[0])
      },
      /**
       * Get additional information from appliance record and add to NetworkAppliances array.  Needed
       * to validate whether appliance is licensed and which product it is
       */
      enhancedNetworkAppliances() {
        // going to add in StatusFlags field to each element of NetworkAppliances array
        const applianceData = cloneDeep(this.network.NetworkAppliances)
        const getByUid = this.$store.getters['appliances/getByUid']
        if (applianceData && this.appliancesRowData) {
          for (const nxtAppliance of applianceData) {
            const moreData = getByUid(nxtAppliance.Uid)
            if (moreData) {
              nxtAppliance.StatusFlags = moreData.StatusFlags
              nxtAppliance.ProductLine = moreData.ProductLine
              nxtAppliance.Licenses = moreData.Licenses
              nxtAppliance.HasWireguardLicense = moreData.HasWireguardLicense
              if (moreData.StatusFlags && moreData.StatusFlags[0].Messages[0] !== 'appliance_status_online')
                nxtAppliance.Notes = this.$t('not_on_line')
              else if (!appliances.isLicensedForVPN(nxtAppliance))
                nxtAppliance.Notes = this.$t('unlicensed_not_eligible')
              else nxtAppliance.Notes = ''
            }
          }
          return applianceData
        } else return []
      },
      /**
       * Merge network appliance data with appliance data
       */
      rowData: ({ $store, enhancedNetworkAppliances }) =>
        $store.getters['appliances/populateApplianceInfoByUid'](enhancedNetworkAppliances, 'Uid'),
      incompatibilityMessage() {
        if (this.versionError) return this.$t(this.versionError)
        else return this.$t('appliance_incompatible_with_vpn', { applist: this.incompatibilityList })
      },
      autoPrompt() {
        return this.$t('choose_endpoint', { IP: this.publicIP })
      },
      configSaveButtonDisabled() {
        if (
          this.conflictingSubnets.length > 0 ||
          (this.selectedStatus && this.selectedProduct === 'NGFW' && this.selectedSubnets.length <= 0)
        )
          return true
        else return false
      },
    },
    watch: {
      /**
       * Keep track of changes to network definition so as to validate when "sync VPN" button should be enabled
       */
      network: {
        immediate: true,
        handler(network) {
          this.configuredAppliances = 0
          if (!network || !network.NetworkAppliances) {
            return
          }

          for (const nxtAppliance of network.NetworkAppliances) {
            if (nxtAppliance.VpnStatus === true) this.configuredAppliances++
          }
        },
      },
      /**
       * Deselect selected rows when retrieving row data
       */
      enhancedNetworkAppliances: {
        handler() {
          this.vpnApplianceSelected = []
        },
      },
    },
    methods: {
      /**
       * Saves all the shared subnets in the network. Key is Uid, value is an array of shared subnets
       * @return array
       */
      networkSubnets() {
        const existingSubnets = []
        if (!this.network) {
          return existingSubnets
        }
        this.network?.NetworkAppliances.forEach(el => {
          existingSubnets[el.Uid] = []
          el.SharedSubnets.forEach(subnet => {
            existingSubnets[el.Uid].push(subnet)
          })
        })
        return existingSubnets
      },
      /**
       * Called when user saves the shared subnets
       */
      async saveConfiguration() {
        if (this.selectedStatus) {
          const valid = await this.$refs.validateForm.validate()
          if (!valid) {
            return
          }
        } else {
          this.overrideIP = this.publicIP
        }
        this.$store.commit('SET_PAGE_LOADER', true)
        // dispatch a 'save Shared Subnets' call
        await this.$store.dispatch('networks/saveConfiguration', {
          network: this.network,
          selectedApplianceUid: this.vpnApplianceSelected[0].Uid,
          selectedSubnets: this.selectedSubnets,
          selectedStatus: this.selectedStatus,
          automatic: this.automatic,
          IP: this.overrideIP,
        })
        this.$store.commit('SET_PAGE_LOADER', false)
        this.closeConfigurationWindow()
        this.vpnApplianceSelected = []
      },

      /**
       * Called when user clicks on Configuration button - it will fetch all subnets from the box
       */
      async getApplianceConfiguration() {
        const uid = this.vpnApplianceSelected[0].Uid
        this.$store.commit('SET_PAGE_LOADER', true)
        this.configFetching = true
        this.conflictingSubnets = []
        this.selectedStatus = this.vpnApplianceSelected[0].VpnStatus
        this.selectedSubnets = this.vpnApplianceSelected[0].SharedSubnets
        this.overrideIP = this.vpnApplianceSelected[0].IP
        this.automatic = this.vpnApplianceSelected[0].Automatic
        const getByUid = this.$store.getters['appliances/getByUid']
        const appliance = getByUid(uid)
        this.selectedProduct = appliance.ProductLine
        this.publicIP = appliance.IpAddress
        const response = await api.cloud('Untangle_CommandCenter', 'GetApplianceConfiguration', {
          uid,
          networkId: this.network.Id,
          paramOrder: 'uid networkId',
        })
        this.configFetching = false
        if (response.data) {
          this.subnets = response.data.map(el => el.subnet)
          this.conflictingSubnets = response.data.filter(el => el.conflicting).map(el => el.subnet)

          if (this.conflictingSubnets.length > 0) {
            // if there are conflicts, disable the VpnStatus in the UI
            this.vpnApplianceSelected[0].VpnStatus = false
            this.selectedStatus = false
          }
          this.editConfiguration = true
        } else {
          this.showVpnExplanation = true
          if (response.message === 'appliance_version_incompatible_with_vpn') this.versionError = response.message
          else {
            this.versionError = ''
            this.incompatibilityList = response.message
          }
        }
        this.$store.commit('SET_PAGE_LOADER', false)
      },

      /**
       * Sync vpn info to appliances.
       */
      async syncVpnInfo() {
        this.$store.commit('SET_PAGE_LOADER', true)
        await api.cloud('Untangle_CommandCenter', 'SyncVpnInfo', {
          network: JSON.stringify(this.network),
          paramOrder: 'network',
        })
        vuntangle.toast.add(this.$t('vpn_sync_initiating'))

        this.$store.commit('SET_PAGE_LOADER', false)
        const dt = vuntangle.dates.formatLocaleDate(new Date())
        this.syncMessage = this.$t('last_sync_status', { syncTime: dt })
      },

      /**
       * Handles request to remove all tunnels created by command center
       */
      async resetVpnInfo() {
        this.$store.commit('SET_PAGE_LOADER', true)
        await this.$store.dispatch('networks/resetVpnTunnels', {
          network: this.network,
        })
        vuntangle.toast.add(this.$t('vpn_reset_initiating'))
        this.$store.commit('SET_PAGE_LOADER', false)
        this.resetVpnTunnelsDialog = false
        const dt = vuntangle.dates.formatLocaleDate(new Date())
        this.syncMessage = this.$t('reset_status', { syncTime: dt })
      },

      /**
       * Called when the configuration window is closed (either cancel or save)
       */
      closeConfigurationWindow() {
        this.editConfiguration = false
        this.selectedSubnets = []
        this.selectedStatus = null
      },
    },
  }
</script>
