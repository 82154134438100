<template>
  <v-hover v-slot="{ hover }">
    <ValidationProvider v-slot="{ errors }" :rules="rules">
      <u-autocomplete
        ref="field"
        v-model="condition.object"
        data-testid="conditionValue"
        :items="objectItems"
        solo
        flat
        :outlined="false"
        item-value="Id"
        item-text="Name"
        :loading="loading"
        :placeholder="isGroup ? $t('select_group') : $t('select_object')"
        :error-messages="errors"
        multiple
        attach
        :menu-props="{ offsetY: true }"
      >
        <template #selection="{ item, index }">
          <template v-if="index < othersIndex">
            <v-tooltip bottom max-width="300px" transition="false" color="primary">
              <template #activator="{ on, atrrs }">
                <span class="text-truncate mt-1 pr-2" v-bind="atrrs" v-on="on">
                  <v-icon dense color="grey">{{ isGroup ? 'mdi-label-multiple' : 'mdi-label' }}</v-icon>
                  {{ item.Name }}
                </span>
              </template>
              <span>{{ getTooltip(item) }}</span>
            </v-tooltip>
          </template>
          <span v-else-if="index === othersIndex" class="grey--text text-caption mr-1">
            {{ $t('x_others', [condition.object.length - othersIndex]) }}
          </span>
        </template>

        <template #item="{ item, attrs, on }">
          <v-list-item v-slot="{ active }" v-bind="attrs" dense class="px-2" :ripple="false" v-on="on">
            <v-list-item-action class="my-0 mr-2">
              <v-checkbox :input-value="active" dense :ripple="false" />
            </v-list-item-action>
            <v-tooltip left max-width="300px" transition="false" color="primary" nudge-left="25">
              <template #activator="props">
                <v-list-item-content class="d-block" v-bind="props.atrrs" v-on="props.on">
                  <span class="text-truncate">
                    <v-icon dense color="grey">{{ isGroup ? 'mdi-label-multiple' : 'mdi-label' }}</v-icon>
                    {{ item.Name }}
                  </span>
                </v-list-item-content>
              </template>
              <span>{{ getTooltip(item) }}</span>
            </v-tooltip>
          </v-list-item>
        </template>

        <template #no-data>
          <div class="d-flex align-center pa-4 justify-center">
            <span class="caption">{{ $t('no_data') }} </span>
          </div>
        </template>

        <!-- appends Create Object and Create Group at the bottom of the menu listing -->
        <template #append-item>
          <v-sheet class="append-sticky py-2 mb-n2">
            <v-divider class="my-2" />
            <div v-if="!isGroup" class="mx-2">
              <v-btn small depressed color="primary" class="text-capitalize" @click="onCreateObject">
                <v-icon small class="mr-2">mdi-plus</v-icon> {{ $t('create_object') }}
              </v-btn>
            </div>
            <div v-else class="mx-2">
              <v-btn small depressed color="primary" class="text-capitalize" @click="onCreateGroup">
                <v-icon small class="mr-2">mdi-plus</v-icon> {{ $t('create_group') }}
              </v-btn>
            </div>
          </v-sheet>
        </template>

        <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
        <!-- appends Create Group inside the match/not_match field -->
        <template v-else-if="hover && showCreateGroup" #append>
          <v-btn
            small
            text
            style="margin-top: -2px; margin-right: -6px"
            color="primary"
            class="text-capitalize"
            @click="onCreateGroup"
          >
            {{ $t('create_group') }}
          </v-btn>
        </template>
      </u-autocomplete>
    </ValidationProvider>
  </v-hover>
</template>
<script>
  import { conditionsConfig, OperatorType } from 'vuntangle/pm'
  import { objectValue } from '../../../valueGetters'
  import mixin from './mixin'

  export default {
    mixins: [mixin],
    data() {
      return {
        objects: [],
        objectGroups: [],
        loading: false,
        // max number of selected items shown after which will put `(+x more)`
        othersIndex: 4,
      }
    },
    computed: {
      objectType: ({ conditionConfig }) => conditionConfig?.objectType,
      objectGroupType: ({ conditionConfig }) => conditionConfig?.objectGroupType,
      isGroup: ({ condition }) => [OperatorType.In, OperatorType.NotIn].includes(condition.op),
      objectItems: ({ isGroup, objects, objectGroups }) => (isGroup ? objectGroups : objects),
      /**
       * show field embedded `Create Group` button if
       * - object is not a group (e.g. it has Match/NotMatch ops)
       * - and at least 2 objects are selected
       */
      showCreateGroup: ({ isGroup, condition }) => !isGroup && condition.object.length >= 2, //
    },

    watch: {
      'condition.type'() {
        this.getObjects()
      },
      'condition.object'() {
        this.getObjects()
      },
    },

    beforeMount() {
      this.getObjects()
    },

    methods: {
      getObjects() {
        this.loading = true
        this.$emit('get-objects-and-groups', conditionsConfig[this.condition.type], (objects, objectGroups) => {
          this.objects = objects
          this.objectGroups = objectGroups
          this.loading = false
        })
      },

      /**
       * Returns an object value as a tooltip string for an object/object group
       * @param object - an object
       */
      getTooltip(object) {
        const tooltip = objectValue(object)
        if (object.Type.includes('group')) {
          return tooltip.map(el => `${el.name} (${el.tooltip})`).join(` ${this.$t('or')} `)
        }
        return tooltip
      },

      /**
       * emits `create-object` event handled in ConditionItem.vue component
       */
      onCreateObject() {
        this.$refs.field.blur() // closes the object selection menu
        this.$emit('create-object', this.objectType) // will openup the dialog
      },

      onCreateGroup() {
        this.$refs.field.blur() // closes the group selection menu
        this.$emit('create-object-group', this.objectGroupType) // will openup the dialog
      },
    },
  }
</script>

<style scoped>
  .v-autocomplete >>> .v-select__selections {
    flex-wrap: wrap !important;
  }
</style>
