<template>
  <div v-if="item" class="d-flex">
    <v-icon v-if="item.icon" small :color="item.icon.color" class="mr-2"> {{ item.icon.name }} </v-icon>
    <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
      <span class="font-weight-bold">{{ item.firstText }}</span>
      <span v-if="item.secondText && showInfoText" class="font-weight-light text--secondary">
        - {{ item.secondText }}
      </span>
    </span>
    <span
      v-if="lastText && showInfoText"
      class="flex-grow-0"
      style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; position: absolute; right: 80px"
      v-html="lastText"
    />
  </div>
</template>
<script>
  export default {
    props: {
      item: { type: Object, default: null }, // see SelectionBar for info
      type: { type: String, default: 'selection' }, // 'selection' or 'data'
    },
    computed: {
      lastText() {
        if (!this.item.lastText) return null
        if (Array.isArray(this.item.lastText) && this.item.lastText.length > 0)
          return this.item.lastText.join(' &nbsp;&bull;&nbsp; ')
        return this.item.lastText
      },
      showInfoText() {
        switch (this.$vuetify.breakpoint.name) {
          case 'lg':
          case 'xl':
            return true
          default:
            return false
        }
      },
    },
  }
</script>
