<template>
  <div>
    <div>
      <span class="font-weight-bold">{{ $t('block') }}:</span> {{ data.actions.block.length }} {{ $t('applications') }}
    </div>
    <div>
      <span class="font-weight-bold">{{ $t('flag') }}:</span> {{ data.actions.flag.length }} {{ $t('applications') }}
    </div>
    <div>
      <span class="font-weight-bold">{{ $t('reject') }}:</span> {{ data.actions.reject.length }}
      {{ $t('applications') }}
    </div>

    <div class="font-weight-bold mt-2">{{ $t('ac_custom_rules') }}: {{ data.custom_rules.length }}</div>

    <div class="mt-2">
      <span class="font-weight-bold">{{ $t('ac_cloud_classification') }}: </span>
      {{ data.cloud_classification ? $t('yes') : $t('no') }}
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      data: { type: Object, default: () => {} },
    },
  }
</script>
