<template>
  <div>
    <h4>{{ $t('ip_addresses') }}</h4>
    <p class="text-caption">{{ $t('input_a_comma_separated_list_of_ips_cidrs') }}</p>

    <ValidationProvider v-slot="{ errors }" rules="required|ip_any_expression_cidr_list">
      <u-text-field v-model="ips" :error-messages="errors" data-testid="ip-list">
        <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
      </u-text-field>
    </ValidationProvider>

    <v-spacer />
    <slot name="actions" :new-settings="settingsCopy" />
  </div>
</template>
<script>
  export default {
    props: {
      /**
       * the object data (PolicyJson) expected in the form
       *   id: '<id that should match Policy Id>',
       *   name: '<a name>',
       *   items: ['1.2.3.4', '2.3.4.5',...],
       *   type: <the type as expected on backend>,
       * */
      settings: { type: Object, default: () => {} },
    },

    data() {
      return {
        // local cloned settings
        settingsCopy: { ...this.settings },
      }
    },

    computed: {
      // computed prop to manipulate object `items`
      ips: {
        get() {
          return this.settingsCopy.items?.join(', ')
        },
        set(value) {
          this.settingsCopy.items = value.split(',').map(item => item.trim())
        },
      },
    },
  }
</script>
