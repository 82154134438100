<template>
  <value-text rules="required|port_expression" placeholder="list_of_ports_or_ranges" multiple v-on="$listeners" />
</template>
<script>
  import { ValueText } from '../value'

  export default {
    components: { ValueText },
  }
</script>
