<script>
  import api from '@/plugins/ut/ut-api'
  import grids from '@/plugins/ut/ut-grids'

  export default {
    data() {
      return {
        fetchingTasks: false,
        taskItems: [],
        selectedTasks: [],
      }
    },
    computed: {
      tasksColumnDefs: () => grids.getTasksColumnDefs(),
    },
    /**
     * Load tasks and set selected ones
     *
     * @return {void}
     */
    created() {
      this.getTaskList()
    },

    methods: {
      /**
       * Gets a list of tasks for this account
       *
       * @returns {Promise<void>}
       */
      async getTaskList() {
        this.fetchingTasks = true
        const response = await api.cloud('Untangle_CommandCenter', 'getTasks')
        if (response.success && response.data) {
          this.taskItems = response.data
        }
        if (this.getTasks) this.getTasks(this.tasks)
        this.fetchingTasks = false
      },
    },
  }
</script>
