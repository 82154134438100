// prettier-ignore
// NOTE: using js module instead of JSON to be able to add comments

/**
 * DE localization
 * Keys to be matched 1:1 with english translations (see en.js)
 *
 *
 * Pluralization
 * Is achieved by defining locale using pipes
 * e.g.
 * english: network: "network | networks"
 * french: network: "réseau | réseaux"
 *
 * In template, is needed to use $tc() instead of $t()
 * $tc('network', 1) => "network"
 * $tc('network', 2) => "networks"
 */
export default {
  accept_link_label: 'Link-Label akzeptieren',
  access_event: 'Zugriffsregelblock',
  account: 'Mein Konto',
  account_balance: 'Kontostand',
  account_billed_info: 'Die Belastung Ihres Kontos erfolgt am Tag der Verlängerung des Abonnements',
  account_billing_cycle_info: 'Ihr Abrechnungszyklustag für jeden Monat ist',
  account_id: 'Konto-ID',
  account_management: 'Abonnements, Abrechnung, Rechnungen, Benutzerverwaltung',
  account_no_address: 'Gutschein konnte nicht eingelöst werden. Sie haben keine Adresse definiert.',
  account_number: 'Accountnummer',
  account_owner: 'Kontoinhaber:',
  account_preferences_save_failed: 'Kontoeinstellungen konnten nicht aktualisiert werden.',
  account_preferences_save_success: 'Die Kontoeinstellungen wurden erfolgreich aktualisiert. Möglicherweise ist eine Aktualisierung erforderlich, um Änderungen an der Benutzeroberfläche zu übernehmen.',
  account_safety_only_two_factor_enabled: 'Zu Ihrer Kontosicherheit ist diese Option nur aktiviert, wenn Ihre Zwei-Faktor-Authentifizierung aktiviert ist',
  account_settings_av_save_failed: 'Authentifizierung fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten und versuchen Sie es erneut.',
  account_settings_confirmation_required: 'Die angeforderten Änderungen der Kontoeinstellungen erfordern eine zusätzliche Bestätigung. Eine E-Mail wurde an den Kontoinhaber gesendet.',
  account_settings_save_failed: 'Kontoeinstellungen konnten nicht aktualisiert werden.',
  account_settings_save_success: 'Die Kontoeinstellungen wurden erfolgreich aktualisiert.',
  account_type: 'Konto Typ',
  accounts_credentials_update_fail: 'Die Anmeldeinformationen des Kontos konnten nicht aktualisiert werden.',
  accounts_credentials_update_success: 'Die Anmeldeinformationen des Kontos wurden erfolgreich aktualisiert.',
  ach: 'ACH',
  ach_electronic_funds: 'ACH / Elektronischer Geldtransfer',
  ach_payment_error: 'Bei der Verarbeitung Ihrer Zahlung ist ein Problem aufgetreten. Bitte versuchen Sie es erneut, oder wenden Sie sich direkt an Ihre Bank.',
  active: 'Aktiv',
  active_hosts: 'Aktive Gastgeber:',
  activity: 'Aktivität',
  ad_monitoring_agent: 'Active Directory-Anmeldemonitor-Agent',
  ad_monitoring_summary:
     "Der AD Login Monitor-Agent wird auf einem Active Directory-Server installiert und überwacht die Anmeldeereignisse des Servers und aktualisiert NGFW, wenn sich ein Benutzer an einem Computer anmeldet. <p>Weitere Details zum Agenten finden Sie im <a target='_blank' href='https://wiki.untangle.com/index.php/Directory_Connector#Active_Directory_Server_Login_Monitor_Agent'>NG Firewall Wiki.</a>",
  add_account_failed: 'Wir konnten keine Einladung an die von Ihnen angegebene E-Mail-Adresse senden.',
  add_account_success: 'Eine Einladung wurde erfolgreich an die von Ihnen angegebene E-Mail-Adresse gesendet',
  add_ach: 'ACH hinzufügen',
  add_ach_failure: 'Das Hinzufügen einer ACH-Zahlungsmethode ist fehlgeschlagen.',
  add_ach_success: 'Die ACH-Zahlungsmethode wurde erfolgreich hinzugefügt',
  add_alert_rule: 'Warnregel hinzufügen',
  add_appliance: 'Gerät hinzufügen',
  add_appliance_error: 'Sie sind nicht berechtigt, dieser Organisation ein Gerät hinzuzufügen. Wenden Sie sich an Ihren Organisationsmanager, um die Berechtigungen zu ändern',
  add_appliance_to_account: 'Gerät zum Konto hinzufügen',
  add_appliance_totp: 'Appliance-TOTP hinzufügen',
  add_appliances_failure: 'Die Appliances konnten nicht zum Netzwerk hinzugefügt werden.',
  add_appliances_success: 'Die Appliances wurden erfolgreich zum Netzwerk hinzugefügt.',
  add_cc_failed: 'Die eingegebene Kreditkarte konnte nicht hinzugefügt werden',
  add_child_account: 'Konto hinzufügen',
  add_credit_card: 'Kreditkarte hinzufügen',
  add_credit_card_confirm: 'Die Kreditkarte wurde erfolgreich zu Ihrem Konto hinzugefügt.',
  add_existing: 'Vorhandenes hinzufügen',
  add_existing_condition: 'Vorhandene Bedingung hinzufügen ...',
  add_existing_rule: 'Vorhandene Regel hinzufügen ...',
  add_header: 'Kopfzeile hinzufügen',
  add_license: 'Lizenz hinzufügen',
  add_notification_profile: 'Benachrichtigungsprofil hinzufügen',
  add_policy_to_appliance: 'Fügen Sie der Appliance eine Richtlinie hinzu',
  add_selected_appliances: 'Ausgewählte Geräte zu diesem Netzwerk hinzufügen',
  add_template: 'Vorlage hinzufügen',
  add_to_policy: 'Zur Richtlinie hinzufügen',
  add_user: 'Benutzer hinzufügen',
  add_users: 'Benutzer hinzufügen',
  add_wan_rule: 'WAN-Regel hinzufügen',
  additional_information: 'Weitere Informationen',
  address_line2: 'Adresszeile 2',
  address_not_verified: 'Wir konnten die Adresse für diese Zahlungsmethode nicht überprüfen. Bitte überprüfen Sie Ihre Adressinformationen und wenden Sie sich für Unterstützung an den Vertrieb von Arista Edge Threat Management.',
  advertising: 'Werbung & Marketing',
  agni_user: 'AGNI-Benutzer',
  agni_user_groups: 'AGNI-Benutzergruppen',
  agree_and_continue: 'Zustimmen und fortfahren',
  agreement: 'Ich stimme den {0} von Arista Edge Threat Management zu.',
  agriculture: 'Landwirtschaft',
  alert_create_failure: 'Die Warnungsregel konnte nicht erstellt werden.',
  alert_create_success: 'Die neue Warnregel wurde erfolgreich erstellt. Es kann bis zu 20 Minuten dauern, bis die Änderungen wirksam werden.',
  alert_edit_failure: 'Die Warnungsregel konnte nicht bearbeitet werden.',
  alert_edit_success: 'Die Warnregel wurde erfolgreich bearbeitet. Es kann bis zu 20 Minuten dauern, bis die Änderungen wirksam werden.',
  alert_history: 'Alarmverlauf',
  alert_login_failed: 'Fehlgeschlagener Anmeldeversuch',
  alert_login_success: 'Erfolgreicher Anmeldeversuch',
  alert_new_device_discovered: 'Neues Gerät von Network Discovery gefunden',
  alert_rule: 'Alarmregel',
  alert_rule_hint: 'Zeichenfolge, die diese Warnung auslöst, wenn sie in der Ereigniszusammenfassungsnachricht gefunden wird',
  alert_rule_name_hint: 'Eindeutiger Name der Warnungsregel',
  alert_rules: 'Alarmregeln',
  alerts: 'Warnungen',
  alerts_received: 'Empfangene Warnungen',
  all_appliances: 'Alle Geräte',
  all_current_and_future_appliances: 'Alle aktuellen und <b>zukünftigen</b> Geräte',
  all_offline: 'ALLE GERÄTE OFFLINE',
  all_online: 'ALLE GERÄTE ONLINE',
  all_traffic_local: 'Der gesamte Datenverkehr aus lokalen Netzwerken',
  analyze: 'Analysieren',
  antivirus_scan: 'Antiviren-Scan',
  api_key: 'API-Schlüssel',
  api_token: 'API-Token',
  appliance: 'Gerät',
  appliance_action_not_found: 'Appliance-Aktion nicht gefunden',
  appliance_add_licenses_sub_not_assignable: 'Diese Lizenz kann keiner Appliance zugewiesen werden',
  appliance_add_totp_message: 'Fügen Sie der Appliance eine zeitbasierte Einmalpasswort-Authentifizierung hinzu',
  appliance_backup: 'Appliance-Backup',
  appliance_count: 'keine Geräte | ein Gerät | {n} Geräte',
  appliance_details: 'Gerätedetails',
  appliance_does_not_exist: 'Gerät existiert nicht!',
  appliance_incompatible_with_vpn: 'App(s) {applist} sind aktiviert. Deaktivieren Sie diese Apps, um diese Appliance für softwaredefinierte Netzwerke zu konfigurieren',
  appliance_incompatible_with_vpn_title: 'Dieses Gerät ist nicht mit WireGuard VPN kompatibel',
  appliance_interface_disabled: 'Die Schnittstelle wird deaktiviert',
  appliance_invalid_search: 'Ungültiger Suchbegriff.',
  appliance_license_will_auto_renew: 'Dieses Abonnement verlängert sich automatisch in {days} Tagen',
  appliance_licenses: 'Appliance-Lizenzen',
  appliance_licenses_days_remaining: '{days} verbleibende Tage',
  appliance_lose_connectivity: 'Dies kann dazu führen, dass die Appliance kurzzeitig die Verbindung verliert',
  appliance_map: 'Gerätekarte',
  appliance_model: 'Gerätemodell',
  appliance_must_be_online: 'Das Gerät muss mit ETM verbunden sein, um seine TOTP-Einstellungen festzulegen.',
  appliance_not_connected_cmd: 'Die Appliance konnte keine Verbindung zum {to} herstellen. Überprüfen Sie, ob das Gerät online ist und über einen Internetzugang verfügt. Überprüfen Sie, ob die UID ({uid}) oder Seriennummer für die Appliance korrekt ist.',
  appliance_offline_totp_message: 'Fügen Sie der Appliance eine zeitbasierte Einmalpasswort-Authentifizierung hinzu oder entfernen Sie sie, wenn sie online ist',
  appliance_policies: 'Geräterichtlinien',
  appliance_remote_access_failed: 'Es konnte keine Remoteverbindung zur Appliance hergestellt werden',
  appliance_remove_totp_message: 'Für diese Appliance ist bereits ein zeitbasiertes Einmalpasswort festgelegt',
  appliance_search_updates: 'Suchen nach Software-Updates',
  appliance_set_label_message: 'Bitte geben Sie ein Etikett für Ihr Gerät ein',
  appliance_set_wifi_region_message: 'Bitte wählen Sie eine WLAN-Region für Ihr Gerät aus',
  appliance_settings_update_initiated: 'Eine Aufgabe zur Aktualisierung der Appliance-Einstellungen wurde in die Warteschlange gestellt.',
  appliance_status_invalid_license_settings: 'Ungültige Lizenzeinstellungen',
  appliance_status_license_expired: 'Ein oder mehrere Abonnements sind abgelaufen!',
  appliance_status_license_expiring_soon: 'Ein oder mehrere Abonnements laufen bald ab',
  appliance_status_no_licenses: 'Keine aktiven Lizenzen gefunden',
  appliance_status_nolicense_command_center: 'Für die vollständige ETM-Dashboard-Funktionalität muss diese Appliance auf eine Komplettlizenz aktualisiert werden',
  appliance_status_offline: 'OFFLINE',
  appliance_status_online: 'ONLINE',
  appliance_status_seat_count_exceeded: 'Anzahl der Lizenzplätze überschritten! Nicht alle Hosts sind geschützt!',
  appliance_status_trial_expired: 'Eine oder mehrere Testversionen sind abgelaufen!',
  appliance_status_trial_expiring_soon: 'Eine oder mehrere Testversionen laufen bald ab',
  appliance_to_restore: 'Appliance(s) zur Wiederherstellung',
  appliance_update_software_failed_title: 'Softwareaktualisierung fehlgeschlagen',
  appliance_upgrade_available: '[Aktualisierung verfügbar]',
  appliance_version_incompatible_with_vpn: 'Inkompatible Appliance-Version – Appliance muss mindestens Version 16.0 (für NGFW) und 3.0 (für Micro Edge) haben.',
  appliance_widgets: 'Appliance-Widgets',
  appliance_widgets_text: 'Sie können die Widgets auswählen, die angezeigt werden sollen. Per Drag & Drop legen Sie die Reihenfolge fest, in der sie angezeigt werden sollen.',
  appliance_without_a_policy_assigned: 'Appliance ohne zugewiesene Richtlinie',
  appliances: 'Haushaltsgeräte',
  appliances_map: 'Gerätekarte',
  appliances_out_of_sync: '{0} Appliances nicht synchron',
  appliances_selected: 'ein Gerät ausgewählt | {n} Geräte ausgewählt',
  appliances_title: 'Haushaltsgeräte',
  appliances_update: 'Software-Update für Geräte',
  appliances2: 'Haushaltsgeräte:',
  application_cat: 'Anwendungskategorie',
  application_control_create_fail: 'Anwendungskontrolle konnte nicht erstellt werden.',
  application_control_create_success: 'Anwendungskontrolle erfolgreich erstellt.',
  application_control_delete_fail: 'Application Control konnte nicht gelöscht werden.',
  application_control_delete_success: 'Application Control erfolgreich gelöscht.',
  application_control_description: 'Verwenden Sie eine Anwendungskontrollvorlage, um zu definieren, welche Anwendungen durch diese Richtlinie zugelassen oder abgelehnt werden sollen',
  application_control_list_group: 'Anwendungskontrolllistengruppen',
  application_control_update_fail: 'Anwendungskontrolle konnte nicht aktualisiert werden.',
  application_control_update_success: 'Anwendungssteuerung erfolgreich aktualisiert.',
  application_groups: 'Anwendungsgruppen',
  application_productivity: 'Anwendungsproduktivitätsniveau',
  application_risk: 'Risikostufe der Anwendung',
  applicationcontrol_session_blocked: 'Sitzung von Application Control blockiert',
  applicationcontrol_session_flagged: 'Von Application Control gekennzeichnete Sitzung',
  applicationcontrol_session_rejected: 'Sitzung von Application Control abgelehnt',
  applications_control_list: 'Anwendungskontrolllisten',
  architecture: 'Die Architektur',
  arista_authorized: 'Arista-autorisiert',
  arista_edge_threat_management: 'Arista Edge-Bedrohungsmanagement',
  arista_q_series: 'Gerät der Arista Q-Serie',
  assign_a_policy: 'Weisen Sie eine Richtlinie zu',
  assign_appliance_duplicate_failed: 'Dieses Gerät kann nicht hinzugefügt werden. Die Appliance ist diesem Konto bereits zugewiesen.',
  assign_appliance_organization_failed: 'Dieses Gerät kann nicht hinzugefügt werden. Wenn es bereits zu einer anderen Organisation gehört, muss es aus seiner aktuellen Organisation entfernt werden, bevor es dieser hinzugefügt werden kann.',
  assign_label: 'Weisen Sie Ihrem Gerät ein Etikett zu',
  assign_sub_failed: 'Das Abonnement konnte der Appliance nicht zugewiesen werden.',
  assign_sub_success: 'Das Abonnement wurde erfolgreich zugewiesen.',
  assign_sub_to_appliance: 'Weisen Sie einer Appliance ein Abonnement zu',
  assign_subscription: 'Abonnement zuweisen',
  assign_subscription_to_this_appliance: 'Weisen Sie dieser Appliance ein Abonnement zu',
  assigned_to: 'Zugewiesen an',
  assignment_description: 'Weisen Sie einer oder mehreren Sicherheits-Appliances Richtlinien zu und synchronisieren Sie diese',
  assignment_status: 'Zuweisungsstatus',
  audit_history: 'Audit-Historie',
  audit_info: 'Audit-Info',
  authorization_endpoint_url: 'URL des Autorisierungsendpunkts',
  authorized_reseller: 'Autorisierter Wiederverkäufer',
  auto_assign_subscriptions: 'Abonnements automatisch zuweisen',
  auto_assign_subscriptions_note: 'Hinweis: Dadurch werden alle nicht zugewiesenen Abonnements den neuen Geräten zugewiesen, die dem Konto hinzugefügt werden',
  auto_renew: 'Automatische Verlängerung',
  auto_renew_failed: 'Automatische Verlängerung für Abonnement(s) fehlgeschlagen',
  auto_renew_toggled: 'Die automatische Verlängerung wurde für das Abonnement aktualisiert',
  automatic_config: 'Der VPN-Tunnel-Endpunkt wird mithilfe der im ETM Dashboard registrierten Internet-IP-Adresse konfiguriert.',
  automatic_login: 'Automatische Anmeldung',
  automotive: 'Automobil',
  available_physical_memory: 'Verfügbarer physischer Speicher',
  available_virtual_memory: 'Verfügbarer virtueller Speicher',
  available_zones: 'Verfügbare Zonen',
  average_jitter: 'Durchschnittlicher Jitter',
  average_latency: 'Durchschnittliche Latenz',
  average_packet_loss: 'Durchschnittlicher Paketverlust',
  back: 'Zurück',
  back_to_policies: 'Zurück zu den Richtlinien',
  backup_appliance_settings: 'Backup-Appliance-Einstellungen',
  backup_date: 'Backup-Datum:',
  backup_download_failed: 'Die Sicherungsdatei konnte nicht heruntergeladen werden.',
  backup_generation_failure: 'Es konnte kein Backup der Master-Appliance erstellt werden.',
  backup_hostname: 'Hostname:',
  backup_label: 'Etikett:',
  backup_no_license: 'Backup konnte nicht erstellt werden. Das Gerät verfügt nicht über eine vollständige Lizenz',
  backup_not_generated: 'Die Sicherung konnte nicht erstellt werden.',
  backup_uid: 'UID:',
  backups: 'Backups',
  bad_credit_card_exp_info: 'Die Kreditkarte ist abgelaufen. Bitte geben Sie sie erneut ein',
  bad_credit_card_num_info: 'Die von Ihnen eingegebene Kreditkartennummer ist ungültig. Bitte geben Sie sie erneut ein',
  band_tier: 'Band/Stufe',
  bandwidth_control: 'Bandbreitenkontrolle',
  bank_name: 'Bank Name',
  bank_routing_number: 'Bankleitzahl',
  banking_and_financial: 'Bank- und Finanzwesen',
  bcc_email_addresses: 'CSV-Liste der BCC-E-Mail-Adressen',
  beta: 'Beta',
  billing: 'Abrechnung',
  billing_address: 'Rechnungsadresse',
  billing_agreement_id: 'Abrechnungsvereinbarungs-ID:',
  billing_email: 'Abrechnung per E-Mail',
  billing_email_info:
    'Erhält Folgendes: <br />' +
    '* Rechnungen <br/>' +
    '* Zahlungsbenachrichtigungen fehlgeschlagen <br/>' +
    '* Benachrichtigungen über ablaufende Kreditkarten <br/>' +
    '* 30-Tage-Erneuerungsbenachrichtigungen',
  billing_info: 'Rechnungsinfo',
  billing_info_account_positive_balance: '<b>Hinweis: </b>Ihr Kontostand beträgt derzeit: {val}.<br/>Durch die Aktualisierung Ihrer Rechnungsinformationen werden alle ausstehenden Beträge eingezogen.<br/>',
  billing_info_credit_positive_balance: '<b>Hinweis: </b> Ihr Guthaben beträgt derzeit: {val}.<br/>Diese Beträge stellen Guthaben dar, die von Ihrer nächsten Zahlung abgezogen werden.<br/>',
  billing_total: '12-Monats-Abrechnungssumme',
  billing_trend: '12-Monats-Abrechnungstrend',
  bitdefender_access_url: 'Zugriffs-URL',
  bitdefender_credentials: 'Bitdefender Endpoint Security',
  bitdefender_credentials_instructions:
     'Bitte geben Sie den API-Schlüssel ein, den Sie in Ihrem <a href="https://cloud.gravityzone.bitdefender.com/" target="_blank">Bitdefender GravityZone</a>-Konto bereitgestellt haben. Dadurch wird der Abschnitt „Hosts“ um zusätzliche Informationen und Funktionen erweitert. Mehr darüber können Sie <a href="https://support.edge.arista.com/hc/en-us/articles/360051479273" target="_blank">hier</a> lesen.',
  blocked: 'Ist blockiert',
  blocked_threats: 'Blockierte Bedrohungen',
  branding_manager: 'Branding-Manager',
  business: 'Geschäft',
  buy: 'Kaufen',
  buy_license: 'Lizenz kaufen',
  buy_subscriptions: 'Abonnements kaufen',
  bypassed: 'Wird umgangen',
  callback_url: 'Rückruf-URL',
  cannot_delete_account_because_haas: 'Das Konto kann nicht gelöscht werden, da aktive HaaS-Abonnements vorhanden sind',
  cannot_leave_only_org: 'Sie können Ihre einzige Organisation nicht verlassen',
  cannot_leave_org_error: 'Organisation konnte nicht verlassen werden',
  cannot_leave_primary_org: 'Sie können Ihre primäre Organisation nicht verlassen',
  captive_portal_description: 'Verwenden Sie eine Captive Portal-Vorlage, um zu definieren, wann Captive Portal auf Geräte im Netzwerk angewendet werden soll',
  captive_portal_user: 'Captive-Portal-Benutzer',
  captiveportal_create_fail: 'Captive Portal konnte nicht erstellt werden.',
  captiveportal_create_success: 'Captive Portal erfolgreich erstellt.',
  captiveportal_session_blocked: 'Sitzung durch Captive Portal blockiert',
  captiveportal_session_redirected: 'Sitzung wurde von Captive Portal umgeleitet',
  captiveportal_update_fail: 'Captive Portal konnte nicht aktualisiert werden.',
  captiveportal_update_success: 'Captive Portal erfolgreich aktualisiert.',
  card_expiration_not_verified: 'Wir konnten das Ablaufdatum Ihrer Karte nicht überprüfen. Bitte versuchen Sie es erneut, oder wenden Sie sich direkt an Ihren Kreditkartenanbieter.',
  card_number: 'Kartennummer',
  card_type: 'Speicherkarten-Typ',
  cardholder_name: 'Name des Karteninhabers',
  cc_email_addresses: 'CSV-Liste der CC-E-Mail-Adressen',
  cc_expired_update_in_billing_info: 'Ihre Kreditkarte ist abgelaufen. Bitte nehmen Sie sich einen Moment Zeit, um es zu aktualisieren, indem Sie zu Konto -> Mein Konto -> Rechnungsinformationen gehen.',
  cert: 'Zert',
  certificate_id: 'Zertifikats-ID',
  change_password: 'Kennwort ändern',
  change_policy_first_packet: 'Ändern Sie die Richtlinienbedingung in eine Bedingung, die beim ersten Paket ausgewertet werden kann',
  changed: 'Geändert',
  charged_payment_method: 'Zahlungsmethode, die belastet wird:',
  checking: 'Überprüfung',
  checksum: 'Prüfsumme',
  choose_endpoint: 'Automatisch ({IP})',
  choose_global_configuration: 'Wählen Sie globale Konfiguration',
  city: 'Stadt',
  class: 'Klasse',
  class_type: 'Klassentyp',
  clear_default: 'Standard löschen',
  client_authentication_method: 'Client-Authentifizierungsmethode',
  client_endpoint_link: '&nbsp;&nbsp;&nbsp;Gehen Sie zu meiner {vendorname}-Konsole',
  client_geoip: 'Client-Geo-IP',
  client_id: 'Kunden ID',
  client_secret: 'Client-Geheimnis',
  cloud_backups: 'Cloud-Backups',
  cloud_backups_confirmation: 'Sind Sie sicher, dass Sie diese Appliance mit der ausgewählten Sicherung wiederherstellen möchten? Die Appliance verwendet die Konfiguration und Richtlinien aus der Sicherung mit Ausnahme der Netzwerkeinstellungen.<br/><br/>Sobald der Vorgang abgeschlossen ist, wird auf Ihrem Bildschirm eine Toastmeldung mit dem Ergebnis der Wiederherstellung angezeigt.',
  cloud_backups_confirmation_title: 'Bestätigen Sie die Wiederherstellung der Sicherung',
  cloud_backups_create_backup_error: 'Das Erstellen des Backups ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal.',
  cloud_backups_create_backup_success: 'Backup erfolgreich erstellt!',
  cloud_backups_create_confirmation: 'Sind Sie sicher, dass Sie ein Backup von der ausgewählten Appliance erstellen möchten?',
  cloud_vision: 'Cloud Vision',
  command_executed_successfully: 'Befehl erfolgreich ausgeführt',
  command_failed: 'Der angeforderte Befehl konnte nicht ausgeführt werden.',
  command_failure: 'Es konnte keine Verbindung zum Gerät hergestellt werden',
  command_sent_successfully: 'Der Befehl wurde erfolgreich in die Warteschlange gestellt.',
  company: 'Unternehmen',
  company_description: 'Firmen Beschreibung',
  company_information: 'Firmeninformation',
  company_name: 'Name der Firma',
  company_website: 'Unternehmenswebseite',
  compare: 'Vergleichen',
  compare_description:
    'Auf der linken Seite sind die aktuell auf dem Gerät festgelegten Einstellungen. Auf der rechten Seite sind die Einstellungen, die bei der nächsten Synchronisierung auf das Gerät übertragen werden.',
  compare_no_data: 'Daten zum Vergleich können nicht abgerufen werden.',
  comparison: 'Vergleich',
  complete: 'Appliance erfolgreich hinzugefügt. Wenn Sie eine Änderung des Hostnamens oder einen Template-Push angefordert haben, ist dies geplant und wird bald abgeschlossen sein.',
  complete_appliance_setup: 'Komplette Appliance-Einrichtung',
  complete_remote: 'Appliance erfolgreich hinzugefügt. Wenn Sie eine Änderung des Hostnamens oder einen Template-Push angefordert haben, wurde dies geplant und wird in Kürze abgeschlossen. Nun wird ein neues Fenster mit Zugriff auf diese Appliance geöffnet, sodass Sie mit der Einrichtung fortfahren können',
  completed: 'Vollendet',
  condition_description: 'Zustandsbeschreibung',
  condition_in_use:
    'Diese Bedingung ist mehreren Richtlinien zugeordnet. Ziehen Sie in Betracht, eine Kopie anzufertigen, da sonst alle von Ihnen vorgenommenen Änderungen auf die folgenden Richtlinien angewendet werden: {0}',
  condition_name: 'Bedingungsname',
  condition_object_already_added: 'Bedingungsobjekt bereits in der Liste',
  conditions_description: 'Verwenden Sie Bedingungen, um zu definieren, wann diese Richtlinie angewendet werden soll. Keine Bedingungen bedeuten, dass die Richtlinie für alle Verkehrsströme gilt',
  config_failed: 'Es konnte keine Verbindung zu dieser Appliance hergestellt werden. Ihre Konfigurationsoptionen werden übertragen, sobald die Appliance verbunden und verfügbar ist',
  config_incomplete: 'Die Konfiguration für diese Appliance wurde nicht erfolgreich abgeschlossen. {errorMessage}. Möglicherweise müssen Sie einige Schritte auf der Appliance erneut ausführen',
  configuration_json: 'Konfiguration JSON',
  configuration_templates: 'Konfigurationsvorlagen',
  configure_appliance: 'Appliance konfigurieren',
  confirm_delete_child_dialog: 'Wenn Sie dieses Konto löschen, wird die Freigabe aller damit geteilten Abonnements aufgehoben und Sie werden als Administrator aus dem Konto entfernt. Bist du dir sicher, dass du weitermachen willst?',
  confirm_delete_data: 'Sind Sie sicher, dass Sie die ausgewählten Elemente löschen möchten?',
  confirm_delete_network: 'Sind Sie sicher, dass Sie das ausgewählte Netzwerk löschen möchten?',
  confirm_delete_policy: 'Sind Sie sicher, dass Sie die ausgewählten Richtlinien löschen möchten?',
  confirm_delete_templates: 'Sind Sie sicher, dass Sie die ausgewählten Vorlagen löschen möchten?',
  confirm_delete_wan_policy: 'Durch das Löschen ausgewählter Richtlinien werden auch die folgenden WAN-Regeln gelöscht.',
  confirm_delete_wan_rules: 'Sind Sie sicher, dass Sie die ausgewählten WAN-Regeln löschen möchten?',
  confirm_generate_vouchers: 'Ihr Konto wird belastet und Sie erhalten Gutschein(e) dafür',
  confirm_generate_vouchers_2: 'Sind Sie sicher, dass Sie diese Gutscheine generieren möchten?',
  confirm_leave_org: 'Sind Sie sicher, dass Sie diese Organisation verlassen möchten?',
  confirm_notification_profile_delete: 'Sind Sie sicher, dass Sie die ausgewählten Benachrichtigungsprofile löschen möchten?',
  confirm_proceed: 'Sind Sie sicher, dass Sie fortfahren möchten?',
  confirm_remove_appliances: 'Sind Sie sicher, dass Sie die ausgewählten Geräte entfernen möchten?',
  confirm_remove_task: 'Sind Sie sicher, dass Sie die ausgewählten Aufgaben entfernen möchten?',
  confirm_remove_users: 'Sind Sie sicher, dass Sie die ausgewählten Benutzer aus Ihrem Konto entfernen möchten?',
  confirm_reset_vpn_tunnels: 'Sind Sie sicher, dass Sie alle VPN-Tunnel entfernen und deaktivieren möchten?',
  confirm_reset_wan_rules: 'Sind Sie sicher, dass Sie alle ETM Dashboard-WAN-Regeln aus dem Netzwerk und allen Geräten entfernen möchten?',
  confirm_restore: 'Bitte bestätigen Sie die folgende Wiederherstellung',
  confirm_restore_button: 'Bestätigen Sie die Wiederherstellung',
  conflicting_subnets: 'Rot angezeigte Subnetze sind bereits auf einer anderen Appliance in Ihrem Netzwerk konfiguriert, sodass diese Appliance nicht VPN-fähig sein kann',
  connect: 'Verbinden',
  construction: 'Konstruktion',
  contact: 'Kontakt',
  contact_information: 'Kontaktinformationen',
  could_not_authenticate: 'Konnte nicht authentifizieren!',
  could_not_find_org: 'Die Organisation, die Sie verlassen möchten, konnte nicht gefunden werden',
  countries: 'Länder',
  coupons: 'Gutscheine',
  cpu_count: 'CPU-Anzahl',
  create: 'Erstellen',
  create_account: 'Benutzerkonto erstellen',
  create_account_msg_other: 'Bitte geben Sie weitere Kontoinformationen ein',
  create_account_msg_required: 'Bitte geben Sie die Anmeldeinformationen ein',
  create_account_validate_email_and_token_error: 'Ihr Aktivierungstoken oder Ihre E-Mail-Adresse ist ungültig. Bitte überprüfen Sie Ihr E-Mail-Konto erneut und fordern Sie eine neue Einladung an, wenn die Probleme weiterhin bestehen.',
  create_alert_rule: 'Erstellen Sie eine Alarmregel',
  create_backup: 'Ein Backup erstellen',
  create_condition: 'Bedingung erstellen',
  create_configuration: 'Konfiguration erstellen',
  create_default: 'Standard erstellen',
  create_default_policy_confirmation: 'Sind Sie sicher, dass Sie eine Standardrichtlinie erstellen möchten?',
  create_defaults: 'Erstellen Sie Standards',
  create_filter_group: 'Erstellen Sie eine Filterregelgruppe',
  create_group: 'Gruppe erstellen',
  create_network: 'Netzwerk erstellen',
  create_new: 'Erstelle neu',
  create_notification_profile: 'Benachrichtigungsprofil erstellen',
  create_notification_profile_failed: 'Das Erstellen des Benachrichtigungsprofils ist fehlgeschlagen',
  create_object: 'Objekt erstellen',
  create_paypal_agreement: 'Erstellen Sie eine PayPal-Abrechnungsvereinbarung',
  create_paypal_billing_agreement_failed: 'Beim Erstellen einer Abrechnungsvereinbarung ist ein Problem aufgetreten. Für weitere Informationen wenden Sie sich bitte an den Vertrieb von Arista Edge Threat Management.',
  create_paypal_billing_agreement_redirect_failed: 'Beim Einrichten einer Verbindung mit PayPal ist ein Problem aufgetreten. Für weitere Informationen wenden Sie sich bitte an den Vertrieb von Arista Edge Threat Management.',
  create_paypal_billing_agreement_success: 'Die PayPal-Abrechnungsvereinbarung wurde erfolgreich erstellt',
  create_policy: 'Richtlinie erstellen',
  create_rule: 'Regel erstellen',
  create_template: 'Vorlage erstellen',
  create_template_instructions:
    'Dieser Vorgang erstellt eine Vorlage zum Aktualisieren des Arista Edge Threat Management' +
    'Appliances in der Ziel-Appliance-Liste mit den Konfigurationen und ' +
    'Richtlinien aus dem Vorlagenmaster (mit Ausnahme von Netzwerk ' +
    'Aufbau).',
  create_update_wan_rule: 'WAN-Regel erstellen/aktualisieren',
  created: 'Erstellt',
  creating_network: 'Netzwerk schaffen',
  creating_updating_alert: 'Alarmregel erstellen/aktualisieren',
  creating_updating_notification: 'Benachrichtigungsprofil erstellen/aktualisieren',
  credit_card: 'Kreditkarte',
  credit_cards: 'Kreditkarten',
  criteria: 'Kriterien',
  ctw_example: 'CTW gefolgt von 8 Ziffern',
  current: 'aktuelle Organisation',
  current_password: 'Aktuelles Passwort',
  current_product: 'Aktuelles Produkt',
  current_server_datetime: 'Aktuelles Datum/Uhrzeit des Geräts',
  current_subscriptions: 'Aktuelle Abonnements',
  custom_interface_zone: 'Benutzerdefinierte Schnittstellenzone',
  customer_accounts: 'Kundenkonten',
  customer_not_found: 'Ein Benutzer mit der angegebenen E-Mail-Adresse wurde nicht gefunden.',
  daily: 'Täglich',
  dashboard_widgets_text: 'Sie können die Widgets auswählen, die angezeigt werden sollen. Per Drag & Drop legen Sie die Reihenfolge fest, in der sie angezeigt werden sollen.',
  data: 'Daten',
  database_schema_mismatch: 'Nichtübereinstimmung des Datenbankschemas.',
  database_status_offline: 'Datenbankstatus offline.',
  database_status_online: 'Datenbankstatus online.',
  database_write_failed: 'Das Schreiben in die Datenbank ist fehlgeschlagen.',
  databases_create_fail: 'Datenbank konnte nicht erstellt werden.',
  databases_create_success: 'Datenbank erfolgreich erstellt.',
  databases_delete_fail: 'Datenbank konnte nicht gelöscht werden.',
  databases_delete_success: 'Datenbank erfolgreich gelöscht.',
  databases_update_fail: 'Datenbank konnte nicht aktualisiert werden.',
  databases_update_success: 'Datenbank erfolgreich aktualisiert.',
  date: 'Datum',
  date_created: 'Datum erstellt',
  date_installed: 'Datum der Installation',
  date_redeemed: 'Datum der Einlösung',
  date_time: 'Terminzeit',
  date_updated: 'Datum aktualisiert',
  day_of_the_week: 'Wochentag',
  default_payment_method: 'Standardzahlungsmethode',
  default_payment_update: 'Ihre Standardzahlungsmethode wurde erfolgreich aktualisiert',
  default_payment_update_failed: 'Ihre Standardzahlungsmethode konnte nicht gespeichert werden',
  default_rule: 'Standardregel',
  delayed_complete_no_config: 'Appliance erfolgreich hinzugefügt. Es wurden Aufgaben in die Warteschlange gestellt, um die Einrichtung Ihrer Appliance abzuschließen und alle anderen angeforderten Konfigurationsänderungen vorzunehmen',
  delete: 'Löschen',
  delete_account: 'Konto löschen',
  delete_account_confirmation:
     "<strong>ACHTUNG!</strong> Durch das Löschen des Kontos werden alle mit Ihrem Konto verknüpften Daten gelöscht und Sie werden abgemeldet. <strong>Dieser Vorgang kann NICHT rückgängig gemacht werden!</strong> <br/><br/>Bitte geben Sie Folgendes ein Wort 'LÖSCHEN' unten. <br/>",
  delete_account_failed: 'Beim Löschen des Kontos ist ein Fehler aufgetreten',
  delete_account_success: 'Das Konto wurde erfolgreich entfernt',
  delete_account_warning: 'WARNUNG! Durch das Löschen des Kontos wird gelöscht',
  delete_ach_failed: 'Es muss mindestens ein ACH hinterlegt sein oder es muss eine andere Standardzahlungsmethode ausgewählt werden.',
  delete_ach_success: 'Die von Ihnen ausgewählte ACH-Zahlungsmethode wurde erfolgreich gelöscht',
  delete_alert_rule: 'Alarmregeln löschen',
  delete_alert_rules_confirm: 'Sind Sie sicher, dass Sie die ausgewählten Warnungsregeln löschen möchten?',
  delete_alert_rules_failed: 'Die ausgewählten Warnungsregeln konnten nicht gelöscht werden.',
  delete_alert_rules_success: 'Die ausgewählten Warnungsregeln wurden erfolgreich gelöscht.',
  delete_av_confirm_text: 'Sind Sie sicher, dass Sie die Antiviren-Einstellungen von Ihrem Konto entfernen möchten?',
  delete_av_confirm_title: 'Löschen Sie die Anti-Virus-Einstellungen',
  delete_cc_confirm_text: 'Möchten Sie die ausgewählte Kreditkarte wirklich aus Ihrem Konto entfernen?',
  delete_cc_confirm_title: 'Ausgewählte Kreditkarte löschen',
  delete_cc_failed: 'Es muss mindestens eine Kreditkarte hinterlegt sein oder eine andere Standardzahlungsmethode ausgewählt werden.',
  delete_child_dialog: 'Konto löschen',
  delete_confirm: 'Sind Sie sicher, dass Sie ausgewählte Geräte aus Ihrem Konto entfernen möchten?',
  delete_confirm_network_also: 'Sind Sie sicher, dass Sie die ausgewählte Appliance aus Ihrem Konto entfernen möchten? Beachten Sie, dass dies die letzte Appliance im Netzwerk {networkName} ist, daher wird dieses Netzwerk ebenfalls entfernt.',
  delete_credit_card_confirm: 'Die von Ihnen ausgewählte Kreditkarte wurde erfolgreich gelöscht.',
  delete_duplicate_condition: 'Doppelte Bedingung löschen',
  delete_duplicate_group: 'Doppelte Gruppe löschen',
  delete_duplicate_object: 'Doppeltes Objekt löschen',
  delete_duplicate_rule: 'Doppelte Regel löschen',
  delete_failure: 'Ausgewähltes {0} konnte nicht gelöscht werden',
  delete_network: 'Netzwerk löschen',
  delete_notification_profile: 'Benachrichtigungsprofil löschen',
  delete_notification_profile_error: 'Wir konnten die ausgewählten Benachrichtigungsprofile nicht löschen.',
  delete_notification_profile_failed: 'Das Löschen des Benachrichtigungsprofils ist fehlgeschlagen',
  delete_notification_profile_success: 'Die ausgewählten Benachrichtigungsprofile wurden erfolgreich gelöscht.',
  delete_notification_profiles: 'Benachrichtigungsprofile löschen',
  delete_notification_profiles_error: 'Fehler beim Löschen der Benachrichtigungsprofile',
  delete_notification_profiles_error_associated_to_alert_rules: 'Die folgenden Benachrichtigungsprofile können nicht gelöscht werden, da sie an Warnregeln angehängt sind:',
  delete_policies: 'Richtlinien löschen',
  delete_policy: 'Richtlinie löschen',
  delete_success: 'Ausgewählte {0} wurden erfolgreich gelöscht',
  delete_templates: 'Vorlagen löschen',
  delete_title: 'Appliance löschen',
  delete_voucher_fail_not_owner: 'Fehler, Sie können keine Gutscheine löschen, deren Eigentümer Sie nicht sind',
  delete_vouchers: 'Gutscheine löschen',
  delete_vouchers_failure: 'Die ausgewählten Gutscheine konnten nicht gelöscht werden.',
  delete_vouchers_message: 'Sind Sie sicher, dass Sie die ausgewählten Gutscheine löschen möchten?',
  delete_vouchers_success: 'Die ausgewählten Gutscheine wurden erfolgreich gelöscht.',
  delete_vpn_failed: 'VPN-Tunnel konnten nicht gelöscht werden',
  delete_vpn_tunnels: 'VPN-Tunnel löschen',
  delete_wan_rules: 'WAN-Regeln löschen',
  deleted: 'Gelöscht',
  deleting_ach_payment: 'ACH-Zahlung löschen',
  deleting_credit_card: 'Kreditkarte löschen',
  denial_of_service_create_fail: 'Denial-of-Service konnte nicht erstellt werden.',
  denial_of_service_create_success: 'Denial-of-Service erfolgreich erstellt.',
  denial_of_service_delete_fail: 'Denial of Service konnte nicht gelöscht werden.',
  denial_of_service_delete_success: 'Denial of Service erfolgreich gelöscht.',
  denial_of_service_update_fail: 'Denial of Service konnte nicht aktualisiert werden.',
  denial_of_service_update_success: 'Denial of Service erfolgreich aktualisiert.',
  destination_dns_hint: 'Ziel-DNS-Hinweis',
  details: 'Einzelheiten',
  detected_intrusions: 'Einbrüche erkannt',
  dev: 'Entwickler',
  device_id: 'Geräte ID',
  dhcp_create_fail: 'DHCP konnte nicht erstellt werden.',
  dhcp_create_success: 'DHCP erfolgreich erstellt.',
  dhcp_update_fail: 'DHCP konnte nicht aktualisiert werden.',
  dhcp_update_success: 'DHCP erfolgreich aktualisiert.',
  directory_connector: 'Verzeichnis-Connector',
  disagree: 'Verschiedener Meinung sein',
  discard: 'Verwerfen',
  disconnected_appliance_expired_certificate: 'Möglicherweise wird Ihr Gerät aufgrund eines abgelaufenen Zertifikats als nicht verbunden angezeigt. Bitte aktualisieren Sie auf die neueste Version, um die ETM-Dashboard-Konnektivität wiederherzustellen.',
  disconnected_warning1: 'Das angegebene Gerät ist derzeit nicht mit unserem System verbunden.',
  disconnected_warning2: 'Auf dem folgenden Bildschirm können Sie Konfigurationsinformationen für Ihr Gerät definieren.<br>Wir werden versuchen, eine Verbindung herzustellen und es 7 Tage lang mit diesen Informationen zu aktualisieren.',
  disconnected_warning3: 'Nach dieser Zeit müssen Sie eine Verbindung zu dieser Appliance herstellen und sie manuell aktualisieren, sobald sie verfügbar ist',
  discount: 'Rabatt',
  discovery_create_fail: 'Netzwerkerkennung konnte nicht erstellt werden.',
  discovery_create_success: 'Netzwerkerkennung erfolgreich erstellt.',
  discovery_update_fail: 'Netzwerkerkennung konnte nicht aktualisiert werden.',
  discovery_update_success: 'Netzwerkerkennung erfolgreich aktualisiert.',
  disk: 'Scheibe',
  disk_information: 'Festplatteninformationen',
  dns_create_fail: 'DNS konnte nicht erstellt werden.',
  dns_create_success: 'DNS erfolgreich erstellt.',
  dns_hint: 'DNS-Hinweis',
  dns_update_fail: 'DNS konnte nicht aktualisiert werden.',
  dns_update_success: 'DNS erfolgreich aktualisiert.',
  docker_container: 'Docker-Container',
  domain: 'Domain',
  domain_field: 'Bitte geben Sie Ihre Domain ein',
  download_saml: 'Laden Sie SAML herunter',
  download_sp_metadata: 'Laden Sie SP-Metadaten herunter',
  duplicate_condition: 'Doppelter Zustand von',
  duplicate_group: 'Doppelte Gruppe von',
  duplicate_object: 'Doppeltes Objekt von',
  duplicate_reboot_time: 'Dieser Zeitpunkt ist bereits für einen Neustart eingeplant',
  duplicate_rule: 'Doppelte Regel von',
  duplicate_transaction: 'Es wurde eine doppelte Transaktion erkannt. Bitte erkundigen Sie sich bei Ihrem Zahlungsanbieter oder wenden Sie sich an den Vertrieb von Arista Edge Threat Management, um Unterstützung zu erhalten.',
  dynamic_lists_update_fail: 'Dynamische Listen konnten nicht aktualisiert werden.',
  dynamic_lists_update_success: 'Dynamische Listen erfolgreich aktualisiert.',
  dynamiclists_session_blocked: 'Sitzung durch dynamische Listen blockiert',
  edit_alert_rule: 'Alarmregel bearbeiten',
  edit_condition: 'Bedingung bearbeiten',
  edit_conditions: 'Bedingungen bearbeiten',
  edit_configuration: 'Konfiguration bearbeiten',
  edit_credit_card: 'Kreditkarte bearbeiten',
  edit_email_template: 'E-Mail-Vorlage bearbeiten',
  edit_email_template_fail: 'Beim Speichern der E-Mail-Vorlage ist ein Fehler aufgetreten',
  edit_email_template_success: 'E-Mail-Vorlage erfolgreich gespeichert',
  edit_group: 'Gruppe bearbeiten',
  edit_location: 'Standort bearbeiten',
  edit_location_text: 'Geben Sie eine Adresse für Ihr Gerät ein',
  edit_notification_profile: 'Benachrichtigungsprofil bearbeiten',
  edit_policy_order: 'Richtlinienauftrag bearbeiten',
  edit_shared_subnets_message: 'Wählen Sie Subnetze hinter dieser Appliance aus, die für alle anderen Peer-Netzwerke in diesem VPN zugänglich sein müssen. Eine leere Auswahl bedeutet, dass sich die Appliance im Client-Modus befindet.',
  edit_shared_subnets_message_ngfw: 'Wählen Sie Subnetze hinter dieser Appliance aus, die für alle anderen Peer-Netzwerke in diesem VPN zugänglich sein müssen. Es ist mindestens ein aktiviertes Subnetz erforderlich, da der Client-Modus derzeit für NGFW nicht unterstützt wird',
  edit_template: 'Vorlage bearbeiten',
  edit_user_permissions: 'Benutzerberechtigungen bearbeiten',
  edit_user_permissions_fail: 'Die Berechtigungen des Benutzers können nicht aktualisiert werden.',
  edit_user_permissions_success: 'Benutzerberechtigungen wurden erfolgreich aktualisiert.',
  edit_wan_rule: 'Bearbeiten Sie die WAN-Regel',
  education: 'Ausbildung',
  electronic_record: 'Elektronische Aufzeichnung',
  electronic_record_button: 'Kopie anfordern',
  electronic_record_text: 'Fordern Sie eine Kopie Ihrer elektronischen Akte an',
  email: 'Email',
  email_body: 'Körper',
  email_configuration: 'E-Mail-Konfiguration',
  email_contact: 'Email Kontakt',
  email_from: 'Aus',
  email_hint: 'Geben Sie durch Kommas getrennte E-Mail-Adressen für Benutzer ein, die Sie einladen möchten',
  email_in_use: 'Die von Ihnen eingegebene Haupt-E-Mail-Adresse wird bereits verwendet.',
  email_not_found: 'Emailadresse wurde nicht gefunden.',
  email_send_failed: 'E-Mail zum Zurücksetzen des Passworts konnte nicht gesendet werden.',
  email_subject: 'Thema',
  email_to: 'Zu',
  email_validation: 'Dieses Feld muss eine gültige E-Mail-Adresse sein',
  enable_auto_login: 'Aktivieren Sie die automatische Anmeldung für den Fernzugriff',
  enable_mfa: 'Aktivieren Sie die Zwei-Faktor-Authentifizierung',
  enable_mfa_description: 'Die Zwei-Faktor-Authentifizierung bietet eine bessere Sicherheit für Ihr Konto. Nachdem Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort angemeldet haben, werden Sie außerdem aufgefordert, einen Bestätigungscode einzugeben. <a target="_blank" href="https://support.edge.arista.com/hc/en-us/articles/360006054634">Erfahren Sie mehr</a> über die Einrichtung von 2FA zur Sicherung Ihres Kontos',
  enable_sso: 'Aktivieren Sie SSO',
  enable_vpn_message: 'Durch die Aktivierung des VPN-Zugriffs werden Tunnel mit allen VPN-fähigen Geräten im aktuellen Netzwerk hinzugefügt.',
  encryption_certificate: 'Verschlüsselungszertifikat',
  end_date: 'Endtermin',
  end_time: 'Endzeit',
  endpoint_address_override: 'Überschreibung der Endpunktadresse',
  endpoint_id: 'Endpunkt-ID',
  endpoint_security: 'Endpunktsicherheit',
  endpoint_security_title: 'Welches Endpoint Security System verwenden Sie?',
  endpoint_url: 'Endpunkt-URL',
  engine_version: 'Motorversion',
  enter_md5_hash_ip_url_mac: 'Geben Sie eine IP-Adresse, MAC-Adresse, URL oder einen Datei-MD5-Hash ein, um detaillierte Informationen zum Artikel abzurufen.',
  enter_purchase_order_number: 'Bitte geben Sie eine neue Bestellnummer für dieses Abonnement ein',
  enter_uid_or_serial_to_add: 'Geben Sie die UID oder Seriennummer einer Appliance ein. &nbsp;<a href="{url}" target="_blank">Wie finde ich meine UID?</a>',
  entertainment: 'Unterhaltung',
  entitled: 'Ist berechtigt',
  entity_id: 'Entitäts-ID',
  error_message: 'Fehlermeldung',
  error_occurred: 'Ein Fehler ist aufgetreten:',
  etm_dashboard: 'ETM Dashboard',
  event_log: 'Ereignisprotokoll',
  exact_14: 'Genau 14 alphanumerische Zeichen',
  existing_user_login: 'Für diese E-Mail-Adresse kann kein neues Konto erstellt werden.',
  exit: 'Ausfahrt',
  expiration_date: 'Verfallsdatum',
  expiration_month: 'Monat des Ablaufens',
  expiration_year: 'Ablaufjahr',
  expired_reset_key: 'Ihr Reset-Schlüssel ist ungültig oder abgelaufen. Bitte setzen Sie Ihr Passwort erneut zurück.',
  expires: 'Läuft ab',
  export_settings_failed: 'Der Versuch, die Einstellungen zu exportieren, ist fehlgeschlagen',
  failed_completing_invite: 'Wir können die Einladung derzeit nicht abschließen',
  failed_push: 'Die Richtlinie {policyName} konnte nicht auf die ausgewählten Appliances übertragen werden.',
  failed_redeem_no_zuora: 'Gutschein konnte nicht eingelöst werden. Sie haben keine Zahlungsmethode definiert.',
  failed_save_user_settings: 'Benutzereinstellungen konnten nicht gespeichert werden',
  fax: 'Fax',
  feature_not_supported: '{0}-Funktion wird nicht unterstützt',
  feature_upgrade_message: 'Das Gerät unterstützt diese Funktion nicht. Bitte aktualisieren Sie das Gerät, um alle neuesten Funktionen nutzen zu können.',
  federal_government: 'Bundesregierung',
  file_type: 'Dateityp',
  filesize: 'Dateigröße',
  filter_event: 'Regelblock filtern',
  filter_group_description: 'Filterregeln zur Gruppe hinzufügen',
  final_step: 'Letzter Schritt',
  finance: 'Finanzen und Versicherungen',
  firewall_filter_create_fail: 'Filterregeln konnten nicht erstellt werden.',
  firewall_filter_create_success: 'Filterregeln erfolgreich erstellt.',
  firewall_filter_update_fail: 'Filterregeln konnten nicht aktualisiert werden.',
  firewall_filter_update_success: 'Filterregeln erfolgreich aktualisiert.',
  firewall_nat_create_fail: 'NAT-Regeln konnten nicht erstellt werden.',
  firewall_nat_create_success: 'NAT-Regeln erfolgreich erstellt.',
  firewall_nat_update_fail: 'Die NAT-Regeln konnten nicht aktualisiert werden.',
  firewall_nat_update_success: 'NAT-Regeln erfolgreich aktualisiert.',
  firewall_port_forward_create_fail: 'Es konnten keine Portweiterleitungsregeln erstellt werden.',
  firewall_port_forward_create_success: 'Portweiterleitungsregeln erfolgreich erstellt.',
  firewall_port_forward_update_fail: 'Die Portweiterleitungsregeln konnten nicht aktualisiert werden.',
  firewall_port_forward_update_success: 'Portweiterleitungsregeln erfolgreich aktualisiert.',
  firewall_shaping_create_fail: 'Gestaltungsregeln konnten nicht erstellt werden.',
  firewall_shaping_create_success: 'Gestaltungsregeln erfolgreich erstellt.',
  firewall_shaping_update_fail: 'Die Gestaltungsregeln konnten nicht aktualisiert werden.',
  firewall_shaping_update_success: 'Gestaltungsregeln erfolgreich aktualisiert.',
  firewalls: 'Firewalls',
  firewalls_managed: 'Verwaltete Firewalls:',
  first_known: 'Zuerst bekannt',
  first_name: 'Vorname',
  first_seen: 'Zum ersten Mal gesehen',
  flagged: 'Ist markiert',
  following_attributes_returned: 'Die folgenden Attribute wurden vom SAML AuthNRequest zurückgegeben:',
  following_attributes_returned_oauth2: 'Die folgenden Attribute wurden vom Benutzerinfo-Endpunkt zurückgegeben:',
  food: 'Essen & Getränke',
  forever: 'für immer',
  forgot_password: 'Haben Sie Ihr Passwort vergessen?',
  free: 'frei',
  free_space: 'Freiraum',
  from_template_master: 'Von: Vorlagenmaster:',
  general_information: 'Allgemeine Informationen',
  generate_prepaid_vouchers: 'Generieren Sie Prepaid-Gutscheine',
  generate_voucher_success: 'Ihr(e) Gutschein(e) wurden erstellt. Sie werden in Kürze eine Bestätigung per E-mail erhalten.',
  generate_vouchers: 'Gutscheine generieren',
  generic: 'Generisch',
  generic_error: 'Ein Fehler ist aufgetreten.',
  geo_ip_description: 'Verwenden Sie eine Geo-IP-Filtervorlage, um zu definieren, welche Länder durch diese Richtlinie blockiert oder zugelassen werden sollen',
  geoip_blocked_session: 'Sitzung durch Geo-IP blockiert',
  geoip_create_fail: 'Geo-IP konnte nicht erstellt werden.',
  geoip_create_success: 'Geo-IP erfolgreich erstellt.',
  geoip_update_fail: 'Geo-IP konnte nicht aktualisiert werden.',
  geoip_update_success: 'Geo-IP erfolgreich aktualisiert.',
  get: 'ERHALTEN',
  get_billing_data_failed: 'Wir können die Daten für diese Seite nicht abrufen. Bitte versuchen Sie es erneut und wenden Sie sich an den Vertrieb von Arista Edge Threat Management, wenn weiterhin Probleme auftreten',
  get_notification_profile_failed: 'Beim Abrufen des Benachrichtigungsprofils ist ein Fehler aufgetreten',
  get_screenshot: 'Holen Sie sich einen Screenshot',
  get_started: 'loslegen',
  get_status: 'Status bekommen',
  get_support: 'Hole dir Unterstützung',
  get_upgrade_price_failed: 'Der Upgrade-Preis kann nicht ermittelt werden. Bitte aktualisieren Sie und versuchen Sie es erneut',
  getting_started_untangle_go: "<a href='https://support.edge.arista.com/hc/en-us/articles/1500003534002-Getting-Started-with-Untangle-Go-mobile-app-' target='_blank'>Erste Schritte mit Untangle Go</a>.",
  global: 'Allgemein',
  global_configuration: 'Globale Konfiguration',
  global_templates: 'Globale Vorlagen',
  go_back: 'Geh zurück',
  gold_partner: 'Goldpartner',
  government: 'Regierung / öffentliche Verwaltung',
  group_conditions: 'Gruppenbedingungen',
  group_description: 'Gruppenbeschreibung',
  group_name: 'Gruppenname',
  group_not_found: 'Richtlinie nicht gefunden',
  hardware_appliances: 'Hardware-Geräte',
  headers: 'Überschriften',
  healthcare: 'Gesundheitswesen und Pharmazeutik',
  help: 'HELFEN',
  higher_education: 'Höhere Bildung',
  home: 'Startseite / Persönlich',
  home_protect_basic: 'Home Protect Basic',
  home_protect_plus: 'Home Protect Plus',
  hospitality: 'Gastfreundschaft',
  hospitality_and_retail: 'Gastgewerbe und Einzelhandel',
  host_count: 'Hostanzahl',
  host_details: 'Gastgeberdetails',
  host_type: 'Hosttyp',
  hosts: 'Gastgeber',
  hosts_selector_lookup: 'Suchen Sie nach Hostname, IP, MAC-Adresse oder Betriebssystem',
  http_user_agent: 'HTTP-Benutzeragent',
  icon: 'Symbol',
  ignore_failure: 'Ausgewähltes {0} konnte nicht ignoriert werden',
  ignore_for_now: 'Vorerst ignorieren',
  ignore_success: 'Ausgewähltes {0} wurde erfolgreich ignoriert',
  immediately: 'Sofort',
  import: 'Importieren',
  in_sync: 'Synchron',
  incoming_alerts: 'eingehende Benachrichtigungen',
  incompatible_product_license: 'Dieses Produkt kann nicht auf der Appliance installiert werden, da es nicht mit der vorhandenen Produktlizenz kompatibel ist.',
  industry: 'Industrie',
  info: 'Die Info',
  info_alerts: 'Liste der von Appliances generierten Warnungen. Häufige/wiederholte Warnungen werden über einen Zeitraum von 15 Minuten zusammengefasst',
  info_appliance_map: 'Karte der Gerätestandorte',
  info_appliance_policies: 'Liste der mit dieser Appliance verknüpften Richtlinien.',
  info_appliances_grid: 'Liste der Geräte',
  info_audit_history: 'Liste der Kontoaktivitäten',
  info_blocked_threats: 'Liste der Bedrohungen und zugehörigen Metadaten, die von der Virus Blocker-App blockiert wurden',
  info_cloud_backups: 'Liste der Cloud-Backups',
  info_equals: 'Entspricht einem definierten Wert',
  info_greater: 'Ist größer als ein definierter Wert',
  info_greater_or_equal: 'Ist größer oder gleich einem definierten Wert',
  info_in: 'Befindet sich in einer Objektgruppe',
  info_intrusions_detected: 'Liste von bis zu 25 vom Intrusion Prevention Service erkannten Einbrüchen',
  info_less: 'Ist kleiner als ein definierter Wert',
  info_less_or_equal: 'Ist kleiner oder gleich einem definierten Wert',
  info_match: 'Entspricht einem Objekt',
  info_network_performance: 'Liste der Appliances und der zugehörigen Netzwerkleistung. Klicken Sie auf eine Appliance, um weitere Details anzuzeigen',
  info_not_equals: 'Entspricht keinem definierten Wert',
  info_not_in: 'Befindet sich nicht in einer Objektgruppe',
  info_not_match: 'Entspricht keinem Objekt',
  info_policy_analytics: 'Informationen zur Richtlinienanalyse',
  info_wan_link_information: 'Historische Informationen zur Nutzung und Leistung der WAN-Verbindung.',
  input_a_comma_separated_list_of_ips_cidrs: 'Geben Sie eine durch Kommas getrennte Liste von IPs/CIDRs oder Bereichen ein',
  input_placeholder_text: 'example.com',
  input_vlan_tags: 'Bitte geben Sie Ihre VLAN-Tags ein',
  inside_untangle_blog: 'Blog',
  inside_untangle_blog_text: 'Erhalten Sie die neuesten Sicherheitstrends, Neuigkeiten, Tipps und Tricks, um Ihr Netzwerk sicher zu halten',
  installed_software: 'Installierte Software',
  integrations: 'Integrationen',
  interface_id: 'Schnittstellen-ID',
  interface_offline: 'Offline-WAN-Schnittstelle erkannt',
  interface_type: 'Oberflächentyp',
  internal_error_occurred: 'Ein interner Fehler ist aufgetreten. Bitte wenden Sie sich an den Vertrieb von Arista Edge Threat Management.',
  internal_server_error: 'Interner Serverfehler',
  intrusion_info: 'Einbruchsinformationen',
  intrusions_detected: 'Einbrüche erkannt',
  intrusions_detected_last_30_days: 'Erkannte Einbrüche – Letzte 30 Tage',
  invalid_backup_file_version: 'Die Version der Sicherungsdatei wird auf dieser Appliance nicht unterstützt.',
  invalid_coupons_note: 'Hinweis: Gutschein(e) {invalidCoupons} gelten nicht für die aktuelle Bestellung',
  invalid_credit_card_number: 'Ihre Kreditkartennummer ist ungültig. Bitte versuchen Sie es erneut, oder wenden Sie sich direkt an Ihren Kreditkartenanbieter.',
  invalid_csrf_token: 'Es wurde ein ungültiges Sicherheitstoken gesendet. Bitte versuchen Sie es erneut.',
  invalid_information: 'Ungültige Informationen bereitgestellt',
  invalid_json: 'Ungültiges JSON-Format',
  invalid_name: 'Ungültiger Name',
  invalid_notification_profile_emails: 'Benachrichtigungsprofil kann nicht erstellt werden. Die E-Mail-Adresse muss ein Benutzer Ihrer Organisation sein.',
  invalid_policy_condition: 'Ungültige Versicherungsbedingung',
  invalid_uid: 'Ungültige UID',
  invalid_voucher: 'Der von Ihnen eingegebene Gutschein ist ungültig.',
  invitation_missing: 'Für diesen Partner fehlen Einladungsinformationen. Bitte löschen Sie sie und versuchen Sie es erneut',
  invite: 'Einladen',
  invite_body: 'Bitte klicken Sie unten auf die Schaltfläche „Bestätigen“, um den Einladungsvorgang abzuschließen.',
  invite_title: 'Bestätigen Sie die Einladung',
  invited_email: 'E-Mail-Adresse, die Sie einladen möchten',
  invites_being_resent: 'Einladungen werden erneut an die ausgewählten Benutzer gesendet',
  invoice_balance: 'Rechnungssaldo',
  invoice_date: 'Rechnungsdatum',
  invoice_number: 'Rechnungsnummer',
  invoice_total: 'Rechnungsbetrag',
  invoices: 'Rechnungen',
  ip: 'IP',
  ip_address_hint: 'z.B. 192.168.1.0/24,192.168.2.0/24',
  ip_addresses: 'IP-Adressen',
  ip_info: 'IP-Info',
  ip_location: 'IP-Standort',
  is_pre_paid: 'Ist im Voraus bezahlt',
  iso_installer: 'ISO Installer (Grafikmodus)',
  issue: 'Ausgabe',
  item_delete_fail: 'Warnung: Artikel konnten nicht gelöscht werden.',
  item_dependant_items: 'Elemente haben die folgenden Abhängigkeiten:',
  job_id: 'Job-ID',
  keep_in_sync: 'Bleiben Sie synchron',
  keep_in_sync_hint: 'Wenn diese Einstellung aktiviert ist, werden Ziel-Appliances automatisch mit der Master-Appliance synchronisiert, wenn eine Änderung auf der Master-Appliance erkannt wird (mit Ausnahme von Änderungen der Netzwerkkonfiguration).',
  label: 'Etikett',
  label_not_assigned: 'Label nicht zugewiesen',
  label_not_set: 'Bezeichnung nicht festgelegt',
  last_login: 'Letzte Anmeldung',
  last_name: 'Familienname, Nachname',
  last_scan_results: 'Letzte Scan-Ergebnisse',
  last_session: 'Letzte Sitzung',
  last_sync_md5: 'Letzte Synchronisierung MD5',
  last_sync_status: 'Letzte Synchronisierung initiiert um {syncTime}. Status anzeigen in',
  last_synced: 'Zuletzt synchronisiert',
  latest: 'Letzte',
  latitude: 'Breite',
  learn_more: 'Erfahren Sie mehr',
  leave_organization: 'Organisation verlassen',
  legal: 'Rechtsberatung',
  license_entitled: 'Lizenz berechtigt',
  license_setup_not_located: 'Ein Gerät mit dieser Seriennummer wurde in unserem System nicht gefunden. Das Gerät wird Ihrem Konto hinzugefügt, wenn eine Verbindung hergestellt wird',
  licensed_devices: 'Lizenzierte Geräte',
  list_of_ips_cidrs: 'Durch Kommas getrennte Liste von IPs/CIDRs oder Bereichen',
  list_of_ports_or_ranges: 'Durch Kommas getrennte Liste von Ports oder Portbereichen',
  loading_remote_access: 'Fernzugriff wird geladen',
  location: 'Standort',
  lock_workstation: 'Arbeitsplatz sperren',
  logged_in_as: 'Angemeldet als:',
  logged_in_from: 'Angemeldet von:',
  logged_users: 'Angemeldete Benutzer',
  login: 'Anmeldung',
  login_attempt_locked_out: 'Sie wurden wegen {loginAttempts} erfolgloser Anmeldeversuche von Ihrem Konto ausgeschlossen. Sie können nach {lockoutMinutes} Minuten erneut versuchen, sich anzumelden.',
  login_email_verify_complete: 'Deine E-Mail-Adresse wurde verifiziert. Der Zugriff auf Ihr Arista ETM-Konto steht Ihnen frei.',
  login_email_verify_error: 'Ihr Aktivierungstoken oder Ihre E-Mail-Adresse ist ungültig. Bitte überprüfen Sie Ihr E-Mail-Konto und versuchen Sie es erneut oder senden Sie die Bestätigungs-E-Mail erneut.',
  login_email_verify_incomplete: 'Ihr Kontoprofil ist nicht vollständig. Bitte überprüfen Sie Ihre E-Mails und bestätigen Sie Ihre E-Mail-Adresse oder senden Sie die Bestätigungs-E-Mail erneut.',
  login_email_verify_resend: 'Die Bestätigungs-E-Mail wurde erneut gesendet.',
  login_failed: 'Falscher Benutzername oder Passwort. Bitte versuche es erneut.',
  login_mfa_verification_required_email: 'Für dieses Konto ist die <strong>Zwei-Faktor-Authentifizierung</strong> aktiviert. Bitte geben Sie den Bestätigungscode ein, der an die mit Ihrem Konto verknüpfte E-Mail-Adresse gesendet wurde.',
  login_mfa_verification_required_totp: 'Für dieses Konto ist die <strong>Zwei-Faktor-Authentifizierung</strong> aktiviert. Bitte geben Sie den Bestätigungscode aus Ihrer TOTP-Authentifizierungsanwendung ein.',
  login_password_reset_form: 'Bitte geben Sie Ihr neues Passwort ein.',
  login_sso_required: 'Für dieses Konto ist eine SSO-Anmeldung über Google oder Microsoft erforderlich. Bitte verwenden Sie die entsprechende Anmeldeoption',
  login_sso_required_google: 'Für dieses Konto ist eine SSO-Anmeldung über Google erforderlich',
  login_sso_required_microsoft: 'Für dieses Konto ist eine SSO-Anmeldung über Microsoft erforderlich',
  login_sso_required_organization: 'Für dieses Konto ist eine SSO-Anmeldung über den Namen Ihrer Organisation erforderlich',
  login_url: 'Anmelde-URL (HTTP-REDIRECT)',
  login_welcome_back: 'Willkommen zurück,',
  login_welcome_downloads: 'Greifen Sie mit dem ETM-Dashboard auf <a href="{baseUrl}download/ng-firewall" target="_blank">Downloads</a> zu',
  login_welcome_notindividual: 'Nicht,',
  longitude: 'Längengrad',
  lookup_geo: 'Geokoordinaten nachschlagen',
  lowest_jitter: 'Geringster Jitter – jedes WAN',
  lowest_jitter_non_lte: 'Geringster Jitter – Nicht-LTE-WANs',
  lowest_latency_any_wan: 'Niedrigste Latenz – jedes WAN',
  lowest_latency_non_lte: 'Niedrigste Latenz – Nicht-LTE-WANs',
  mac_address_info: 'Informationen zur MAC-Adresse',
  mac_address_vendor: 'Anbieter der MAC-Adresse',
  main_email: 'Haupt-E-Mail',
  main_email_info: 'Empfängt alle E-Mail-Kommunikationen für dieses Konto',
  maintain_security_renew_your_subscription: 'Um die Sicherheit und wesentliche Funktionalität aufrechtzuerhalten, <a href="{baseUrl}organization/subscriptions">erneuern Sie bitte Ihr Abonnement</a>.',
  make_selection: 'Bitte treffen Sie eine Auswahl zum Speichern',
  malwarebytes_credentials: 'Malwarebytes Endpoint Security',
  malwarebytes_credentials_instructions: 'Bitte geben Sie Ihre Anmeldeinformationen für das <a href="https://cloud.malwarebytes.com/" target="_blank">Malwarebytes Nebula Console</a>-Konto ein. Dadurch wird der Abschnitt „Hosts“ um zusätzliche Informationen und Funktionen erweitert.',
  manage_disabled: 'Deaktiviert',
  manage_full_access: 'Voller Zugriff',
  manage_message: 'Wählen Sie eine zu verwaltende Appliance aus',
  manage_read_only: 'Schreibgeschützt',
  manage_subscription_payfail: 'Bitte übermitteln Sie die Zahlung für {subscriptionName}, bevor Sie dieses Abonnement verwalten',
  manage_subscriptions: 'Abonnements verwalten',
  managed_service_provider: 'Managed Service Provider',
  management_address: 'Verwaltungsadresse',
  managing: 'Verwaltung:',
  manufacturer: 'Hersteller',
  manufacturing: 'Herstellung',
  map_platinum: 'MAP Platin',
  map_tooltip: '<b>Hostname:</b> {hostName}<br/><b>UID:</b> {uid}<br/><b>Label:</b> {tag}<br/>< b>Server-IP:</b> {serverIp}<br/><b>Status:</b> {status}<br/><b>Hostanzahl:</b> {hostCount}<br/>< b>Standort:</b> {location}',
  mask_uids: 'UIDs maskieren',
  master_appliance: 'Master-Appliance',
  master_appliance_not_found: '  Sicherung fehlgeschlagen, Master-Appliance nicht gefunden',
  master_not_connected_to_cmd: 'Die Master-Appliance ist offline. Bitte stellen Sie sicher, dass die Master-Appliance online ist, und versuchen Sie es erneut.',
  md5: 'MD5',
  md5_hash_info: 'MD5-Hash-Info',
  memory_details: 'Details zum Hostspeicher',
  message: 'Nachricht',
  message_type: 'Nachrichtentyp',
  method: 'Methode',
  mfa: 'MFA',
  mfa_verification_failed: 'Die <strong>Zwei-Faktor-Authentifizierung</strong> für dieses Konto ist fehlgeschlagen.',
  mfw_image_e3: 'GZ (e3-Bild)',
  mfw_image_e6: 'GZ (e6-Bild)',
  mfw_no_subscription: 'Für die Appliance ist kein Abonnement vorhanden. Die Verbindungsgeschwindigkeit ist eingeschränkt und Sicherheitsfunktionen sind deaktiviert. Weisen Sie ein Abonnement zu oder <a href="{0}configurator/?sku=SR-11-SWBE-0100-1YEAR" target="_blank">klicken Sie hier</a>, um ein Abonnement zu kaufen.',
  mfw_setup_wizard_refresh_text: '<h1>Setup-Assistent</h1>Bitte aktualisieren Sie die Appliances, sobald Sie den Setup-Assistenten auf dem Gerät abgeschlossen haben.<br/><br/>',
  mfw_setup_wizard_text: '<h1>Setup-Assistent</h1>Vielen Dank, dass Sie sich für Arista Edge Threat Management entschieden haben! Ein Assistent führt Sie durch die Ersteinrichtung und Konfiguration des Geräts.<br/><br/>',
  mfw_upgrade_51: 'Um darauf zuzugreifen, aktualisieren Sie bitte über das obere Menü auf Version 5.1 oder höher',
  micro_edge: 'Mikrokante',
  micro_edge_basic: 'Micro Edge Basic Edition',
  micro_edge_basic_nonprofit: 'Micro Edge Basic Edition, gemeinnützig',
  micro_edge_basic_public_sector: 'Micro Edge Basic Edition Öffentlicher Sektor',
  micro_edge_description: 'Micro Edge ist eine leichte Netzwerk-Edge-Appliance. Es kann entweder mit NG Firewall bereitgestellt werden, um eine Netzwerklösung für Büros mit mehreren Standorten bereitzustellen, oder allein, um die Netzwerkleistung zu optimieren. <br/><br/>Virtuelle Appliances erfordern mindestens 256 MB RAM und 128 MB Speicher. Weitere Informationen finden Sie unter <a href="https://support.edge.arista.com/hc/en-us/articles/360022862034-Untangle-SD-WAN-Router-System-Requirements" target="_blank">Micro Edge System Anforderungen</a> für weitere Informationen.',
  micro_edge_policies: 'Micro Edge-Richtlinien',
  micro_edge_security: 'Micro Edge Security Edition',
  micro_edge_security_nonprofit: 'Micro Edge Security Edition, gemeinnützige Organisation',
  micro_edge_security_public_sector: 'Micro Edge Security Edition für den öffentlichen Sektor',
  micro_edge_templates: 'Micro Edge-Vorlagen',
  micro_edge_vmdk_info: 'Sie müssen beide Dateien herunterladen und installieren',
  mining: 'Bergbau',
  missing_templates: 'Folgende Vorlagenkonfigurationen: {policyTypes} wurden nicht erfolgreich erstellt',
  mobile: 'Untangle Go (Mobil)',
  mobile_beta: 'Los entwirren',
  mobile_configuration: 'Mobile Konfiguration',
  mobile_date_paired: 'Datum gepaart',
  mobile_device_type: 'Gerätetyp',
  mobile_info: 'Bitte wählen Sie die Mobilgeräte aus, auf denen Sie die Benachrichtigungen erhalten möchten.',
  mobile_number: 'Handynummer',
  mobile_summary:
    "Untangle Go ist eine mobile App für Android- und iOS-basierte Geräte, mit der Sie Informationen zu Ihren Edge Threat Management-Appliances und -Abonnements, einschließlich Status, Warnungen und Systemressourcen, auf Ihrem Mobilgerät anzeigen können. Untangle Go ergänzt das ETM Dashboard, ist jedoch kein Ersatz. Wir freuen uns über Ihr <a href='https://aristaetm.featureupvote.com' target='_blank'>Feedback</a>.",
  modal_content:
    '<div style="width:100%;"><p>Bei Arista Edge Threat Management nehmen wir Ihre Privatsphäre ernst. Wir haben kürzlich<br/>unsere Datenschutzrichtlinie aktualisiert, um der Datenschutz-Grundverordnung der Europäischen Union zu entsprechen. Diese<br/>Richtlinie erklärt:</p><ul><li>Welche Informationen wir sammeln und warum wir sie sammeln.</li><li>Wie wir die Informationen verwenden.</li><li>Wie wir auf die Informationen zugreifen, sie aktualisieren oder entfernen.</li></ul> <p>Wir bemühen uns, diese Richtlinie einfach zu lesen und zu verstehen. Bitte lesen und prüfen Sie die Richtlinie hier:<br/><a href="https://www.arista.com/en/privacy-policy" target="_blank">https://www.arista.com/en /privacy-policy</a><br/><br/>Bitte bestätigen Sie, dass Sie die Richtlinie gelesen haben und erteilen Sie Untangle Ihr Einverständnis, Ihre persönlichen Daten wie in unserer Datenschutzrichtlinie beschrieben zu verwenden.< /p><div style="text-align: center; padding-top: 10px;"></div></div>',
  model: 'Modell',
  most_bandwidth_any_wan: 'Höchste verfügbare Bandbreite – jedes WAN',
  most_bandwidth_non_lte: 'Größte verfügbare Bandbreite – Nicht-LTE-WANs',
  msp: 'MSP',
  msp_authorized: 'MSP-autorisiert',
  msp_gold: 'MSP-Gold',
  msp_silver: 'MSP Silber',
  my_info: 'Meine Information',
  my_organization: 'Meine Organisation',
  name_on_account: 'Name auf dem Konto',
  nat_description: 'Verwenden Sie NAT-Regeln, um Network Address Translation (NAT) auf bestimmte Datenverkehrsströme anzuwenden',
  negative_price_explanation: 'Der negative Betrag wird Ihrem Guthaben gutgeschrieben.',
  network_average: 'Netzwerkdurchschnitt',
  network_details: 'Netzwerkdetails',
  network_interfaces_create_fail: 'Netzwerkschnittstelle konnte nicht erstellt werden.',
  network_interfaces_create_success: 'Netzwerkschnittstelle erfolgreich erstellt.',
  network_interfaces_update_fail: 'Netzwerkschnittstelle konnte nicht aktualisiert werden.',
  network_interfaces_update_success: 'Netzwerkschnittstelle erfolgreich aktualisiert.',
  network_map: 'Netzwerkkarte',
  network_name: 'Netzwerkname',
  network_not_found: 'Netzwerk nicht gefunden',
  network_performance: 'Netzwerkleistung',
  network_selector_lookup: 'Nachschlagen des Netzwerknamens',
  network_summary: 'Netzwerkzusammenfassung',
  networks: 'Netzwerke',
  never: 'Niemals',
  new_product: 'Neues Produkt',
  next: 'Nächste',
  next_step: 'Nächster Schritt',
  ng_firewall: 'NG-Firewall',
  ng_firewall_complete: 'NG-Firewall – abgeschlossen',
  ng_firewall_homepro: 'NG Firewall HomePro',
  ng_image_img: 'IMG (USB-Image)',
  ng_image_iso: 'ISO (Installationsimage)',
  ng_image_linksys32X_factory: 'IMG (Linksys WRT32X Beta) Factory',
  ng_image_linksys32X_upgrade: 'IMG-Upgrade (Linksys WRT32X Beta).',
  ng_image_linksys1900_factory: 'IMG (Linksys WRT1900ACS Beta) Factory',
  ng_image_linksys1900_upgrade: 'IMG-Upgrade (Linksys WRT1900ACS Beta).',
  ng_image_linksys3200ACM_factory: 'IMG (Linksys WRT3200ACM Beta) Factory',
  ng_image_linksys3200ACM_upgrade: 'IMG (Linksys WRT3200ACM Beta) Upgrade',
  ng_image_ova: 'OVA (VMware Appliance)',
  ng_image_vdi: 'VDI (Oracle VirtualBox)',
  ng_image_vmdk: 'VMDK (VMWare-Image)',
  ngfw_iso_description:
    "Verwenden Sie das grafische Installationsprogramm für Systeme mit hochauflösender Videoausgabe. Diese Version führt eine grafische Desktop-Umgebung für die lokale Verwaltung aus.",

  ngfw_no_complete_subscription:
    'Die Appliance verfügt nicht über ein Komplettabonnement. Erweiterte Sicherheits- und Konnektivitätsfunktionen sind deaktiviert und die Verwaltungsfunktionen des ETM-Dashboards sind eingeschränkt. Weisen Sie ein Abonnement zu oder <a href="{0}cart/" target="_blank">klicken Sie hier</a>, um ein Abonnement zu kaufen.',

  ngfw_ova_description:
    'Erfordert ESX Version 6.5 Build 3 oder neuer. Anweisungen zur Installation und Einrichtung finden Sie im <a href="https://wiki.untangle.com/index.php/Untangle_Virtual_Appliance_on_VMware" target="_blank">Installationshandbuch für NG Firewall für VMware</a>.',
  ngfw_serial_description: 'Verwenden Sie das Installationsprogramm für die serielle Konsole für Systeme mit serieller Konsole. Dazu gehören die Hardware-Appliances Q4, Q8 und Q8W.',
  ngfw_templates: 'NGFW-Vorlagen',
  nmap_port_scan: 'Sie sind dabei, einen NMAP-Scan auf den ausgewählten Hosts durchzuführen. Die Ergebnisse werden angezeigt, sobald der Scan abgeschlossen ist.',
  no_active_accounts: 'Dieser Benutzer hat keine aktiven Konten.',
  no_appliance_found: 'Gerät nicht gefunden',
  no_appliances: 'Keine Geräte',
  no_appliances_assigned: 'Keine Geräte zugewiesen',
  no_backups_available:
    'Keine Sicherungen verfügbar, siehe <a target="_blank" href="https://wiki.untangle.com/index.php/Configuration_Backup">Konfigurationssicherung</a>',
  no_compatible_appliances_available: 'Es sind keine kompatiblen Geräte verfügbar.',
  no_compatible_mfw_appliances_available_for_policy: '  Um eine {policy}-Richtlinie zu erhalten, muss die Appliance über ein Abonnement und eine Softwareversion >= {version} verfügen.',
  no_complete_license_on_master: '  Wiederherstellung fehlgeschlagen. Die Master-Appliance verfügt nicht über eine vollständige Lizenz',
  no_conditions_set: 'Keine Bedingungen festgelegt',
  no_data: 'Keine Daten verfügbar',
  no_endpoint_security:
    'Keine Endpoint Security-Software erkannt. Erfahren Sie mehr über die Verwendung von Arista Edge Threat Management mit Endpoint Security Software <a href="https://support.edge.arista.com/hc/en-us/articles/360012766294-Managing-hosts-in-Command-Center" als Ziel ="_blank">hier</a>.',
  no_headers_set: 'Keine Header festgelegt',
  no_host_selected: 'Bitte wählen Sie einen Host zur Ansicht aus',
  no_info_billing_info_tab: 'Sie haben keine Zahlungsdaten hinterlegt. Nach Ihrer ersten Bestellung können Sie hier Ihre Zahlungsdaten aktualisieren.',
  no_item_selected: 'Bitte wählen Sie mindestens ein Element aus der Liste aus',
  no_label: 'Kein Etikett',
  no_mfw_backups_available: 'Keine Backups verfügbar.',
  no_name: 'Kein Name',
  no_paypal_found: 'Keine aktive PayPal-Abrechnungsvereinbarung gefunden. Verwenden Sie die Schaltfläche unten, um eines zu erstellen.',
  no_product_selected: 'Es muss ein Produkt ausgewählt werden, für das Gutscheine generiert werden sollen',
  no_provider:
    'Integrieren Sie Ihre Endpoint Security-Lösung, um Ihr Host-Management zu erweitern und zusätzliche Informationen anzuzeigen. Erfahren Sie mehr über die Verwendung von Arista Edge Threat Management mit der Endpoint Security-Software <a href="https://support.edge.arista.com/hc/en-us/articles/360012766294-Managing-hosts-in-Command-Center" als Ziel ="_blank">hier</a>.',
  no_record_found: 'Der gesuchte Datensatz wurde nicht gefunden. Möglicherweise wurde es entfernt.',
  no_results_found: 'Keine Ergebnisse gefunden',
  no_saml_test_results_found: 'Es wurden keine SAML-Testergebnisse gefunden. Bitte überprüfen Sie Ihre SAML-Einstellungen.',
  no_subscription_found: 'Abonnement nicht gefunden',
  no_terms: 'Kein aktiver Kreditvertrag gefunden. Um die Konditionen zu beantragen, wenden Sie sich bitte an den Vertrieb von Arista Edge Threat Management.',
  no_uid_access: 'Der Benutzer hat keinen Zugriff auf dieses Gerät',
  no_zuora_account_for_customer: 'Gutschein konnte nicht erstellt werden. Sie haben keine Zahlungsmethode definiert. Bitte wenden Sie sich an den Vertrieb!',
  non_complete_appliance_string: 'Die Master-Appliance muss vor der Synchronisierung auf eine Komplettlizenz aktualisiert werden.',
  none_entered: 'Keine eingegeben',
  nonprofit: 'Gemeinnützig',
  nonprofit_complete: 'Gemeinnützig – abgeschlossen',
  not_allowed: 'Dieses Konto verfügt nicht über die Berechtigung, den angeforderten Vorgang auszuführen',
  not_applicable: 'N / A',
  not_assigned: 'Nicht zugeordnet',
  not_available: 'Nicht verfügbar',
  not_connected_to_cmd: 'Das Gerät ist offline',
  not_enough_terms: 'Ihr Guthaben reicht für diesen Kauf nicht aus. Bitte wählen Sie eine alternative Zahlungsmethode.',
  not_exempt: 'Nicht befreit',
  not_found: 'Nicht gefunden',
  not_on_line: 'Offline – Konfiguration nicht möglich',
  not_renewing: 'Nicht erneuerbar',
  not_synced: 'Nicht synchronisiert',
  notes: 'Anmerkungen',
  notification_log: 'Benachrichtigungsprotokoll',
  notification_profile: 'Benachrichtigungsprofil',
  notification_profile_create_error: 'Das Benachrichtigungsprofil konnte nicht erstellt werden.',
  notification_profile_create_success: 'Das neue Benachrichtigungsprofil wurde erfolgreich erstellt. Es kann bis zu 20 Minuten dauern, bis die Änderungen wirksam werden.',
  notification_profile_description: 'Beschreibung des Benachrichtigungsprofils',
  notification_profile_edit_error: 'Das Benachrichtigungsprofil konnte nicht bearbeitet werden.',
  notification_profile_edit_success: 'Das Benachrichtigungsprofil wurde erfolgreich bearbeitet. Es kann bis zu 20 Minuten dauern, bis die Änderungen wirksam werden.',
  notification_profile_emails: 'Das Benachrichtigungsprofil kann nur für E-Mails erstellt werden, die Benutzer Ihrer Organisation sind.',
  notification_profiles: 'Benachrichtigungsprofile',
  notification_profiles_select_error: 'Wählen Sie ein oder mehrere Benachrichtigungsprofile aus',
  notification_profiles_select_text: 'Wählen Sie die Benachrichtigungsprofile für diese Warnung aus.',
  notifications: 'Benachrichtigungen',
  now: 'Jetzt',
  now_available: 'Ab sofort verfügbar',
  nps_dislike: 'Überhaupt nicht wahrscheinlich',
  nps_like: 'Sehr wahrscheinlich',
  nps_title: 'Wie wahrscheinlich ist es, dass Sie Arista Edge Threat Management einem Freund oder Kollegen empfehlen?',
  number: 'Nummer',
  number_of_appliances: 'Anzahl der Geräte',
  number_of_attempts: 'Anzahl der Versuche',
  oauth_save_failed: 'Die OAUTH-Einstellungen konnten nicht aktualisiert werden',
  oauth_save_success: 'Die OAUTH-Einstellungen wurden erfolgreich aktualisiert',
  oauth2: 'OAuth2 / OpenID',
  oauth2_config: 'OAuth2/OpenID-Konfiguration',
  oauth2_sign_in_redirects: 'Ihr OAuth2-Anbieter benötigt möglicherweise Anmeldeumleitungs-URIs für die Anwendung. Die folgenden URIs müssen zugelassen werden:',
  oauth2_test_results: 'OAuth2-Testergebnisse anzeigen',
  oauth2_unconfigured: 'OAuth2 – Nur verfügbar, wenn konfiguriert!',
  object_description: 'Objektbeschreibung',
  object_name: 'Objektname',
  objects_description: 'Erstellen und verwalten Sie Objekte zur Verwendung in Regeln und Bedingungen',
  occurrences: '  [Ist {n} Mal(e) aufgetreten]',
  oem_ng_firewall_complete: 'OEM NG Firewall – Komplett',
  online_firewalls: 'Online-Firewalls:',
  openvpn: 'OpenVPN',
  operating_system: 'Betriebssystem',
  operator: 'Operator',
  option_ach: 'ACH: {0}',
  option_credit_card: 'Kreditkarte: {0}',
  option_paypal: 'E-Mail-Adresse des PayPal-Kontos: {0}',
  option_terms: 'Bedingungen. Verfügbares Guthaben: {0}',
  or: 'oder',
  organization_config_delete_failed: 'Die Konfiguration der SSO-Organisation konnte nicht gelöscht werden',
  organization_config_delete_success: 'Die SSO-Organisationskonfiguration wurde erfolgreich gelöscht',
  organization_info: 'Informationen zur Organisation',
  organization_login: 'Organisationsanmeldung',
  organization_login_type: 'Anmeldetyp der Organisation',
  organization_login_unconfigured: 'Organisations-Login – Nur verfügbar, wenn konfiguriert!',
  organization_name: 'Name der Organisation',
  organization_requires_mfa: 'Eine Organisation, der Sie angehören, erfordert eine Zwei-Faktor-Authentifizierung',
  organization_save_failed: 'SSO-Organisationseinstellungen konnten nicht gespeichert werden',
  organization_save_success: 'Die SSO-Einstellungen der Organisation wurden erfolgreich aktualisiert',
  organization_sso_login: 'SSO-Anmeldung der Organisation',
  organizations: 'Organisationen',
  orgtype_title: 'Was beschreibt Ihre Organisation am besten?',
  other_downloads: 'Andere Downloads',
  out_of_sync: 'Nicht mehr synchronisiert',
  overview: 'Überblick',
  owned_by_redeemer: 'Im Besitz des Erlösers',
  owner_location: 'Standort des Eigentümers',
  page_title_app: '{app} Richtlinien',
  pager_duty: 'Pager-Pflicht',
  pagerduty_configuration: 'PagerDuty-Konfiguration',
  pagerduty_info:
    'Wir konnten Ihre Adresse nicht geokodieren. Bitte geben Sie Ihren Breiten- und Längengrad manuell ein. Um Ihren Breiten- und Längengrad zu erhalten, klicken Sie mit der rechten Maustaste auf Ihren Standort in Google Maps und wählen Sie „Was ist hier?“. Die Einträge werden dann im folgenden Format (Breitengrad, Längengrad) aufgelistet: 37.374427, -122.029531.',
  pair_device: 'Gerät koppeln',
  partner_city_lat_long: 'Sie müssen Ihre Stadt und Ihr Land eingeben, um den Breiten- und Längengrad nachzuschlagen',
  partner_dashboard: 'Partner-Dashboard',
  partner_email_vars:
    '<h3>Sie können die folgenden Variablen in den folgenden Feldern verwenden:</h3><ul>' +
    '<li><strong>%account.company%</strong> – Der Name des Unternehmens in Ihrem Konto</li>' +
    '<li><strong>%account.name%</strong> – Ihr Name als Inhaber des Kontos</li>' +
    '<li><strong>%account.email%</strong> – Ihre E-Mail-Adresse als Inhaber des Kontos</li>' +
    '<li><strong>%acceptLink%</strong> – Der Link zur Annahme der Einladung</li>',
  partner_geocode_failed:
    "We could not geocode your address - please enter your latitude and longitude manually. To get your latitude and longitude, right-click on your location in Google Maps, select 'What's Here', and they will be listed in the following format (lat, long): 37.374427, -122.029531.",
  partner_information: 'Partnerinformationen',
  partner_information_message:
    'Alle Informationen werden nur zur Anzeige auf dem ETM-Dashboard <a href="{0}partners/find-a-partner" target="_blank">Karte „Partner suchen“</a> verwendet',
  partner_information_save_failed: 'Partnerinformationen konnten nicht aktualisiert werden.',
  partner_information_save_success: 'Partnerinformationen wurden erfolgreich aktualisiert.',
  partner_portal: 'Partnerportal',
  partner_program: 'Partnerprogramm',
  partner_program_text: 'Genießen Sie großartige Vorteile und eine vereinfachte Verwaltung aller Ihrer Kunden',
  partner_sales:
    'In den letzten 12 Monaten betrugen Ihre Umsätze {total_sales}, womit Sie sich auf unserem <b>{partner_level}</b>-Level befinden, das mit <b style="font-size: 18px">{partner_percent}%< ausgestattet ist /b> Rabatt auf Softwarekäufe.',
  partner_sales_no_level:
    'In den letzten 12 Monaten betrug Ihr Umsatz {total_sales} mit einem Rabatt von <b style="font-size: 18px">{partner_percent} %</b> auf Softwarekäufe.',
  password_confirm: 'Bestätige das Passwort',
  password_update_success: 'Ihre Informationen wurden erfolgreich aktualisiert. Bitte melden Sie sich zu Ihrer Sicherheit erneut mit Ihren neuen Zugangsdaten an.',
  password_updated: 'Ihr Passwort wurde erfolgreich aktualisiert.',
  past_reboot_time: 'Es kann kein in der Vergangenheit liegender Neustartzeitpunkt eingeplant werden',
  payload: 'Nutzlast',
  payload_vars:
    '<h3>Sie können die folgenden Variablen in den folgenden Feldern verwenden:</h3><ul>' +
    '<li><strong>%event%</strong> – JSON-Text des gesamten Ereignisobjekts einschließlich Umschlaginformationen</li>' +
    '<li><strong>%event.HTML%</strong> – Hauptteil des gesamten Ereignisobjekts einschließlich der in HTML konvertierten Umschlaginformationen</li>' +
    '<li><strong>%eventstring%</strong> – Hauptteil des gesamten Ereignisobjekts, einschließlich Umschlaginformationen, codiert als maskierte JSON-Zeichenfolge</li>' +
    '<li><strong>%event.message%</strong> – Ereignismeldungszeichenfolge mit Zusammenfassung des Ereignisses</li>' +
    '<li><strong>%event.body%</strong> – JSON-Ereignisnutzlast ohne Umschlaginformationen</li>' +
    '<li><strong>%event.bodyHTML%</strong> – Ereignisnutzlast ohne in HTML konvertierte Umschlaginformationen</li>' +
    '<li><strong>%event.bodystring%</strong> – Ereignisnutzlast ohne Umschlaginformationen, codiert als maskierte JSON-Zeichenfolge</li>' +
    '<li><strong>%eventsource%</strong> – Ereignisquelle – wenn das Ereignis von einer Appliance generiert wurde, UID der Appliance, andernfalls Konto-ID</li>' +
    '<li><strong>%eventsource.IP%</strong> – die IP-Adresse, von der das Ereignis empfangen wurde</li>' +
    '<li><strong>%event.type%</strong> – Ereignistyp (z. B. Warnung, Prüfung usw.)</li>' +
    '<li><strong>%eventsource.label%</strong> – Bezeichnung für eine Appliance im ETM-Dashboard</li></ul>',
  payment_declined: 'Ihre Zahlungsmethode wurde abgelehnt. Bitte versuchen Sie es mit einer anderen Zahlungsmethode oder wenden Sie sich direkt an Ihren Kreditkartenanbieter.',
  payment_include_credit_balance: '  (nach Nutzung des verfügbaren Guthabens)',
  payment_info: 'Zahlungsinformationen',
  payment_info_default_payment_method: 'Aktuelle Zahlungsmethode',
  payment_info_outstanding_balance: 'Offener Betrag',
  payment_info_tax_number: 'Steuer-/Mehrwertsteuernummer',
  payment_method_declined: 'Ihre Zahlungsmethode wurde abgelehnt. Bitte wenden Sie sich direkt an Ihren Zahlungsanbieter.',
  payment_method_info: 'Informationen zur Zahlungsmethode',
  paypal: 'PayPal',
  paypal_method: 'E-Mail-Adresse des PayPal-Kontos:',
  pending: 'Ausstehend',
  pending_verification: 'Ausstehende Bestätigung',
  perform: 'Ausführen',
  permitted_appliances: 'Zugelassene Geräte',
  phish_blocker: 'Phish-Blocker',
  phone: 'Telefon',
  phone_number: 'Telefonnummer',
  platform: 'Plattform',
  platinum_partner: 'Platin-Partner',
  please_configure_your_condition: 'Bitte konfigurieren Sie Ihre Bedingung',
  please_contact_support: 'Bitte kontaktieren Sie den Support unter',
  please_enter_message: 'Bitte geben Sie eine Nachricht ein, die an den Gastgeber gesendet werden soll:',
  please_provide_additional_info: 'Bitte geben Sie diese zusätzlichen Informationen an das Support-Team weiter',
  please_refresh_browser: 'Bitte aktualisieren Sie Ihren Browser und versuchen Sie es erneut.',
  please_select_a_source_appliance: 'Bitte wählen Sie eine Quell-Appliance aus',
  please_select_appliance: 'Bitte wählen Sie ein Gerät aus',
  please_select_target_appliances: 'Bitte wählen Sie Zielgerät(e) aus.',
  please_wait: 'Bitte warten ...',
  policies_action_policy_page: '{policy-page} | {action} {policy-page}',
  policies_category_type: '{category} | {type}',
  policies_description: 'Erstellen und verwalten Sie Richtlinien, um eine konsistente Konfiguration über die gesamte Bereitstellung von Sicherheits-Appliances hinweg aufrechtzuerhalten',
  policy_analytics: 'Richtlinienanalyse',
  policy_cloned_failure: 'Die Richtlinie konnte nicht dupliziert werden',
  policy_cloned_success: 'Richtlinie erfolgreich geklont',
  policy_conditions_info: 'Bedingungen, die bestimmen, wann diese Richtlinie angewendet wird',
  policy_conditions_validation_failure: 'Mindestens eine Bedingung ist ungültig. Bitte beheben Sie das Problem vor dem Speichern.',
  policy_create_failure: 'Es konnte keine neue Richtlinie von der Appliance erstellt werden. Bitte stellen Sie sicher, dass die Policy Manager-Anwendung und diese Anwendung auf Ihrem Gerät installiert sind.',
  policy_create_success: 'Neue Richtlinie erfolgreich von der Appliance erstellt.',
  policy_delete_failure: 'Die ausgewählten Richtlinien konnten nicht entfernt werden.',
  policy_delete_success: 'Die ausgewählten Richtlinien wurden erfolgreich entfernt.',
  policy_description: 'Richtlinienbeschreibung',
  policy_group_dependency: 'Das Löschen ist nicht möglich, da andere Richtlinien davon abhängen.',
  policy_manager_alert_text: 'Um diese Funktionalität nutzen zu können, muss Policy Manager auf der Appliance installiert sein.',
  policy_manager_flows: 'Richtlinienmanager – Flows',
  policy_manager_objects: 'Richtlinienmanager – Objekte',
  policy_manager_policies: 'Richtlinienmanager – Richtlinien',
  policy_manager_update_fail: 'Der Richtlinien-Manager konnte nicht aktualisiert werden.',
  policy_manager_update_success: 'Policy Manager erfolgreich aktualisiert.',
  policy_name: 'Versicherungsname',
  policy_name_validation: 'Der Richtlinienname darf nicht leer sein und nicht mehr als 100 Zeichen enthalten',
  policy_objects: 'Richtlinienobjekte',
  policy_order: 'Richtlinienreihenfolge',
  policy_push_initiated: 'Der politische Vorstoß wurde eingeleitet.',
  policy_syncing_initiated: 'Die Richtliniensynchronisierung wurde eingeleitet',
  policy_update_failure: 'Die ausgewählten Richtlinien konnten nicht aktualisiert werden.',
  policy_update_success: 'Die ausgewählte Richtlinie wurde erfolgreich aktualisiert.',
  policymanager_session_blocked: 'Sitzung vom Policy Manager blockiert',
  policymanager_session_rejected: 'Sitzung vom Richtlinienmanager abgelehnt',
  port_forward_description: 'Verwenden Sie Portweiterleitungsregeln, um eingehenden Datenverkehr umzuleiten, indem Sie die Zieladresse übersetzen',
  port_number: 'Port-Nummer',
  port_scan: 'Port-Scan',
  post: 'POST',
  postal_zip_code: 'Postleitzahl',
  price_with_discount: 'Preis mit Rabatt',
  product: 'Produkt',
  product_installed: 'Dieses Produkt ist bereits auf dem Gerät installiert.',
  product_line: 'Produktlinie',
  product_name: 'Produktname',
  product_upgrade_invalid: 'Das Produkt, auf das aktualisiert werden soll, ist ungültig',
  professional: 'Professionelle / technische Dienstleistungen',
  profile_not_found: 'Profil nicht gefunden',
  protect: 'Schützen',
  provision: 'Bestimmung',
  provision_appliance: 'Appliance bereitstellen',
  public_cloud_deployments: 'Öffentliche Cloud-Bereitstellungen',
  public_sector_complete: 'Öffentlicher Sektor – abgeschlossen',
  purchase_order_number: 'Bestellnummer',
  push_appliance_wan_policies: 'Push-Appliance-WAN-Richtlinien',
  push_policy: 'Push-Richtlinie',
  push_template: 'Push-Vorlage',
  qualifying_institution: 'Qualifizierende Institution',
  quantity: 'Menge',
  quarantined: 'Unter Quarantäne gestellt',
  queued: 'In Warteschlange',
  quick_filter_text: 'Es werden {0} von {1} Einträgen angezeigt',
  quota_usage: 'Kontingent/Nutzung',
  real_estate: 'Immobilie',
  reboot_info: 'Beim Neustart der Appliance geht jeder geplante Neustart verloren',
  reboot_now: 'Jetzt neustarten',
  reboot_now_successful: 'Ihr Gerät wird neu gestartet. Die Funktionen sind möglicherweise erst verfügbar, wenn es wieder online ist',
  reboot_options: 'Neustartoptionen',
  reboot_scheduled: 'Geplanter Neustart',
  received: 'Erhalten',
  recent_hosts: 'Aktuelle Gastgeber',
  recent_hosts_info: 'Liste der Hosts, die innerhalb der letzten 12 Stunden aktiv waren. Klicken Sie auf einen Host, um weitere Details anzuzeigen',
  recipient_email_addresses: 'CSV-Liste der E-Mail-Adressen der Empfänger',
  recommendation: 'Empfehlung',
  redeem: 'Tilgen',
  redeem_voucher: 'Gutschein einlösen',
  redeem_voucher_failure: 'Gutschein konnte nicht eingelöst werden.',
  redeem_voucher_success: 'Gutschein wurde erfolgreich eingelöst',
  redeemed_by: 'Eingelöst von',
  register_now: 'Jetzt registrieren',
  registration_failed: 'Registrierung fehlgeschlagen.',
  registration_success: 'Registrierung erfolgreich',
  registration_success_simple: 'Sie haben erfolgreich ein Konto erstellt. Bitte melden Sie sich mit Ihren Zugangsdaten an.',
  registration_success_with_verification: 'Sie haben erfolgreich ein Konto erstellt, es muss jedoch überprüft werden. Sie erhalten eine E-Mail mit Anweisungen zum Abschluss der Einrichtung an die angegebene Adresse.',
  reinstate: 'Wiedereinsetzen',
  reinstate_popup_message: 'Die Kosten für die Wiederherstellung dieses Abonnements betragen <strong>{renewCost}</strong>.<br/>Ihr aktuelles Guthaben beträgt <strong>{balance}</strong>. <br/>Ihr Abonnement wird ab dem Enddatum der Laufzeit wiederhergestellt und Ihnen wird eine Gebühr in Rechnung gestellt. <br/>Sind Sie sicher, dass Sie dieses abgelaufene Abonnement wiederherstellen möchten?',
  reinstate_subscription: 'Abonnement wieder aktivieren',
  release_candidate: 'Release-Kandidat',
  release_notes:
    '<a target="_blank" href="https://support.edge.arista.com/hc/en-us/articles/360050838353">Versionshinweise</a>',
  religious: 'Religiös',
  remove_ach: 'ACH entfernen',
  remove_ach_confirm: 'Möchten Sie den ausgewählten Eintrag wirklich aus Ihrem Konto entfernen?',
  remove_appliance: 'Entfernen Sie das Gerät',
  remove_appliance_error: 'Fehler beim Entfernen der ausgewählten Appliance',
  remove_appliance_failure: 'Das Gerät konnte nicht aus dem Netzwerk entfernt werden.',
  remove_appliance_success: 'Die ausgewählte Appliance wurde erfolgreich entfernt',
  remove_appliance_totp: 'Entfernen Sie das Appliance-TOTP',
  remove_appliances: 'Entfernen Sie Geräte',
  remove_confirm: 'Möchten Sie die ausgewählten Elemente wirklich entfernen?',
  remove_credit_card: 'Kreditkarte entfernen',
  remove_network_appliance_success: 'Die Appliance wurde erfolgreich aus dem Netzwerk entfernt.',
  remove_sub_failed: 'Wir konnten Ihr Abonnement nicht aktualisieren, es wurden keine Änderungen vorgenommen.',
  remove_sub_success: 'Die Zuweisung des Abonnements wurde erfolgreich aufgehoben.',
  remove_task: 'Aufgabe entfernen',
  remove_unused_condition: 'Unbenutzten Zustand entfernen',
  remove_unused_condition_group: 'Entfernen Sie nicht verwendete Bedingungsgruppe',
  remove_unused_object: 'Nicht verwendetes Objekt entfernen',
  remove_unused_object_group: 'Nicht verwendete Objektgruppe entfernen',
  remove_unused_policy: 'Entfernen Sie nicht verwendete Richtlinien',
  remove_unused_rule: 'Nicht verwendete Regel entfernen',
  remove_unused_template: 'Nicht verwendete Vorlage entfernen',
  remove_user_fail: 'Die Benutzer konnten nicht entfernt werden.',
  remove_user_success: 'Benutzer wurden erfolgreich entfernt.',
  remove_users: 'Benutzer entfernen',
  renew_license: 'Lizenz erneuern',
  renew_now: 'Jetzt erneuern',
  renew_popup_message: 'Die Kosten für die Verlängerung dieses Abonnements betragen <strong>{renewCost}</strong>.<br/>Ihr aktuelles Guthaben beträgt <strong>{balance}</strong>. <br/>Ihr Abonnement wird zum Ende der Laufzeit verlängert und Ihnen wird eine Gebühr in Rechnung gestellt. <br/>Sind Sie sicher, dass Sie dieses Abonnement verlängern möchten?',
  renew_sub_failed: 'Das von Ihnen ausgewählte Abonnement kann nicht verlängert werden. Bitte überprüfen Sie die Zahlungsinformationen und versuchen Sie es erneut oder wenden Sie sich für weitere Informationen an den Vertrieb von Arista Edge Threat Management.',
  renew_sub_success: 'Das ausgewählte Abonnement wurde erfolgreich verlängert.',
  renew_subscription: 'Abonnement verlängern',
  renewal_date: 'Verlängerungsdatum',
  renewal_period: 'Verlängerungszeitraum',
  renewal_price: 'Preis für eine Verlängerung',
  renewal_quote: 'Erneuerungsangebot',
  renewal_quote_failed: 'Für das von Ihnen ausgewählte Abonnement kann kein Verlängerungsangebot abgerufen werden. Bitte wenden Sie sich für weitere Informationen an den Vertrieb von Arista Edge Threat Management.',
  renewal_quote_success: 'Ihr Angebot kann jetzt angezeigt werden. Es wird in einem anderen Tab oder Fenster geöffnet, wenn Sie auf die Schaltfläche „Angebot anzeigen“ klicken.',
  renewal_quote_view_quote: 'Angebot ansehen',
  reports_audit_history: 'Audit-Historie',
  request_body: 'Anforderungstext',
  request_copy_failed: 'Ihre Anfrage ist fehlgeschlagen. Für weitere Informationen wenden Sie sich bitte an den Arista Edge Threat Management Support',
  request_copy_success: 'Wir haben Ihre Anfrage erhalten. Eine E-Mail mit den angeforderten Informationen wird an den Kontoinhaber gesendet.',
  request_electronic_account_record: 'Fordern Sie einen elektronischen Kontodatensatz an',
  request_tax_exempt_status: 'Fordern Sie den Steuerbefreiungsstatus an',
  requests: 'Anfragen',
  require_all_users_two_factor: 'Fordern Sie alle Benutzer auf, die Zwei-Faktor-Authentifizierung zu verwenden',
  requires_security_license: '{0} erfordert eine Security Edition-Lizenz',
  reseller: 'Wiederverkäufer',
  resend_invite: 'Einladung erneut senden',
  resend_invite_success: 'Einladungen wurden erfolgreich erneut an die ausgewählten Benutzer gesendet.',
  resend_verification_email: 'Bestätigungsmail erneut senden',
  resent_invite_fail: 'Das erneute Senden der Einladungen an die ausgewählten Benutzer ist fehlgeschlagen',
  reset: 'Zurücksetzen',
  reset_email_template_fail: 'Beim Zurücksetzen der E-Mail-Vorlage ist ein Fehler aufgetreten',
  reset_email_template_success: 'E-Mail-Vorlage erfolgreich zurückgesetzt',
  reset_password: 'Passwort zurücksetzen',
  reset_password_failed: 'Passwort konnte nicht zurückgesetzt werden.',
  reset_password_msg: 'Bitte geben Sie unten Ihre E-Mail-Adresse ein. Sie erhalten einen Link zum Zurücksetzen Ihres Passworts.',
  reset_password_success: 'Wenn ein mit dieser E-Mail-Adresse verknüpftes Konto vorhanden ist, wird eine E-Mail mit weiteren Anweisungen zum Zurücksetzen Ihres Passworts gesendet.',
  reset_status: 'Zurücksetzen wurde um {syncTime} eingeleitet. Status anzeigen in',
  reset_to_factory: 'Auf Standardeinstellung zurücksetzen',
  resolve: 'Lösen',
  resources: 'Ressourcen',
  restore_appliance_from_backup: 'Appliance aus Backup wiederherstellen',
  restore_called: 'Befehl zum Wiederherstellen der Sicherung gesendet',
  restore_failure: '  Appliance(s) wurden nicht korrekt wiederhergestellt. Stellen Sie sicher, dass alle Geräte online sind, bevor Sie es erneut versuchen',
  restore_instructions: 'Durch diesen Vorgang werden die ausgewählten Appliances mit der angezeigten Sicherung wiederhergestellt.{settingsExceptions} Bitte stellen Sie sicher, dass alle Appliances, die Sie wiederherstellen möchten, online sind. Sind Sie sicher, dass Sie fortfahren möchten?',
  restore_instructions_exceptions: '  Alle Einstellungen außer {settingsExceptions} werden wiederhergestellt.',
  restore_success: 'Appliance-Wiederherstellung erfolgreich gestartet',
  restore_warning: 'Das Wiederherstellen von <strong>Schnittstellen</strong> kann zu Problemen mit der Netzwerkverbindung führen! <br /> Das Wiederherstellen von <strong>Konten</strong> verhindert möglicherweise den Zugriff auf den Administrator!',
  retail: 'Einzelhandel',
  retry: 'Wiederholen',
  revert: 'Zurückkehren',
  review: 'Rezension',
  role: 'Rolle',
  role_admin: 'Administrator – Kein Zugriff',
  role_admin_account_full: 'Administrator – Vollzugriff',
  role_admin_account_read: 'Administrator – Lesezugriff',
  role_appliance_account_full: 'Appliance-Administrator – Vollzugriff',
  role_appliance_account_read: 'Appliance-Administrator – Lesezugriff',
  role_primary: 'Primär',
  role_user: 'Benutzer – Kein Zugriff',
  role_user_account_full: 'Benutzer – Vollzugriff',
  role_user_account_read: 'Benutzer – Lesezugriff',
  routes_create_fail: 'Statische Routen konnten nicht erstellt werden.',
  routes_create_success: 'Statische Routen erfolgreich erstellt.',
  routes_update_fail: 'Statische Routen konnten nicht aktualisiert werden.',
  routes_update_success: 'Statische Routen erfolgreich aktualisiert.',
  routing_key: 'Routing-Schlüssel',
  rule_cloned_failure: 'Die Regel konnte nicht geklont werden',
  rule_cloned_success: 'Regel erfolgreich geklont',
  rule_description: 'Regelbeschreibung',
  rule_name: 'Regelname',
  rule_not_found: 'Regel nicht gefunden',
  rules_description: 'Erstellen und verwalten Sie Regeln zur Verwendung in Richtlinien',
  rules_order_description: 'Sie können die Regeln unten in der gewünschten Reihenfolge neu anordnen. Dies bestimmt die Priorität, die sie auf die Appliance(s) anwenden.',
  rules_reset_initiating: 'Das Zurücksetzen der Regeln wird für alle Geräte im Netzwerk eingeleitet. Überprüfen Sie den Prüfverlauf auf den Status Ihrer Rücksetzanforderung.',
  rules_sync_failed: 'Synchronisierungsregeln sind fehlgeschlagen',
  rules_sync_initiating: 'Die Synchronisierung der Regeln wird für alle Geräte im Netzwerk initiiert. Überprüfen Sie den Überwachungsverlauf auf den Status Ihrer Synchronisierung.',
  run_analytics_error: 'Fehler beim Ausführen der Analyse',
  run_analytics_sent_successfully:
    'Der Richtlinienanalyseprozess wurde gestartet. Aktualisieren Sie das Raster, um die Daten zu aktualisieren, sobald der Vorgang abgeschlossen ist',
  run_analytics_success: 'Analyse abgeschlossen',
  same_shipping_address: 'Gleiche Lieferadresse',
  saml: 'SAML',
  saml_config: 'SAML-Konfiguration',
  saml_save_failed: 'Die SAML-Einstellungen konnten nicht aktualisiert werden',
  saml_save_success: 'Die SAML-Einstellungen wurden erfolgreich aktualisiert',
  saml_test_results: 'SAML-Testergebnisse',
  saml_unconfigured: 'SAML – Nur verfügbar, wenn konfiguriert!',
  sample: 'Probe',
  save_appliance_setting: 'Appliance-Einstellung speichern',
  save_changes: 'Änderungen speichern',
  save_condition: 'Zustand speichern',
  save_failed: 'Kontoeinstellungen konnten nicht aktualisiert werden.',
  save_oauth: 'Speichern Sie OAuth2',
  save_policy_changes: 'Richtlinienänderungen speichern',
  save_policy_changes_text: 'Die Richtlinie wurde geändert. Möchten Sie Änderungen speichern?',
  save_rule: 'Regel speichern',
  save_rule_changes: 'Regeländerungen speichern',
  save_rule_changes_text: 'Die Regel wurde geändert. Möchten Sie Änderungen speichern?',
  save_saml: 'Speichern Sie SAML',
  save_vat_number: 'Steuer-/Mehrwertsteuernummer speichern',
  saving_ach_payment: 'ACH-Zahlung sparen',
  saving_credit_card: 'Kreditkarte sparen',
  saving_policy_template: 'Richtlinienvorlage speichern',
  saving_settings_dialog: 'Wir speichern weiterhin Ihre bisherigen Einstellungen. Bitte erneut versuchen!',
  saving_user_permissions: 'Benutzerberechtigungen speichern',
  savings: 'Ersparnisse',
  scan_duration: 'Scandauer',
  scan_qrcode: 'Bitte scannen Sie den Code mit der <strong>Untangle Go</strong>-App',
  scan_type: 'Scan-Typ',
  school: 'Schule',
  sdn: 'Softwaredefinierte Netzwerke',
  search: 'Suchen',
  seats_5: 'Bis zu 5',
  seats_12: 'Bis zu 12',
  seats_25: 'Bis zu 25',
  seats_50: 'Bis zu 50',
  seats_75: 'Bis 75',
  seats_100: 'Bis zu 100',
  seats_150: 'Bis zu 150',
  seats_250: 'Bis zu 250',
  seats_500: 'Bis zu 500',
  seats_1000: 'Bis zu 1000',
  seats_1000000: 'unbegrenzt',
  seats_home: 'heim',
  seats_unlimited: 'unbegrenzt',
  security_code_not_verified: 'Wir konnten Ihren Sicherheitscode nicht verifizieren. Bitte versuchen Sie es erneut, oder wenden Sie sich direkt an Ihren Zahlungsanbieter.',
  security_description: 'Verwenden Sie Sicherheitsregeln, um den Datenverkehr zwischen Netzwerksegmenten zu filtern',
  security_id: 'Sicherheits-ID',
  security_license_not_found: 'Dieses Gerät verfügt über keine Sicherheitslizenz. Die meisten Dienste sind nur mit einer Security Edition-Lizenz verfügbar!',
  select_appliance: 'Wählen Sie Appliance aus',
  select_appliances_restore_instructions: 'Bitte wählen Sie eine oder mehrere Appliances zur Wiederherstellung aus',
  select_appliances_sync_instructions: 'Wählen Sie Geräte aus, auf die die Vorlage übertragen werden soll',
  select_appliances_to_restore: 'Wählen Sie die wiederherzustellenden Geräte aus',
  select_appliances_to_sync: 'Wählen Sie Appliances aus, die mit der Master-Vorlage synchronisiert werden sollen',
  select_applications: 'Bitte wählen Sie eine oder mehrere Anwendungen aus',
  select_conditions: 'Wählen Sie Bedingungen aus',
  select_delete_items: 'Wählen Sie die zu löschenden Elemente aus',
  select_existing_conditions: 'Wählen Sie Vorhandene Bedingungen aus',
  select_existing_rules: 'Wählen Sie Vorhandene Regeln aus',
  select_firewall: 'Firewall auswählen',
  select_group: 'Wähle die Gruppe',
  select_host: 'Wählen Sie Host aus',
  select_master_appliance: 'Wählen Sie die Appliance aus, die Sie als Master-Appliance verwenden möchten. Es werden nur lizenzierte Geräte unterstützt.',
  select_network: 'Wählen Sie Netzwerk',
  select_new_product: 'Wählen Sie Neues Produkt',
  select_object: 'Wählen Sie Objekt aus',
  select_one_appliance: 'Wählen Sie mindestens eine Appliance aus, um ein Netzwerk zu erstellen',
  select_organization: 'Wählen Sie eine Organisation aus',
  select_payment_method: 'Wählen Sie die Zahlungsmethode',
  select_product: 'Bitte wählen Sie ein Produkt aus, um Gutscheine zu generieren für:',
  select_rules: 'Wählen Sie Regeln aus',
  select_rules_order: 'Wählen Sie die Reihenfolge der Regeln aus',
  select_template_appliance: 'Wählen Sie die Vorlagen-Appliance aus',
  send_invitation: 'Einladung senden',
  send_invitation_to_users: 'Senden einer Einladung an Benutzer',
  send_message: 'Nachricht senden',
  sender_email_address: 'E-Mail-Adresse des Absenders',
  sent: 'Gesendet',
  serial_installer: 'ISO Installer (serielle Konsole)',
  serial_number: 'Seriennummer',
  server_geoip: 'Geo-IP des Servers',
  services_reset_warning: 'Dieser Dienst wird auf die Standardeinstellungen zurückgesetzt. <br /> Möchten Sie fortfahren?',
  session_blocked: 'Sitzung durch Threat Prevention blockiert',
  session_expired: 'Deine Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
  session_redirected: 'Sitzung wird von Threat Prevention umgeleitet',
  set_account_settings: 'Legen Sie die Kontoeinstellungen fest',
  set_appliance_policy: 'Legen Sie die Appliance-Richtlinie fest',
  set_as_default: 'Als Standard einstellen',
  set_description: 'Beschreibung festlegen',
  set_despription_message: 'Bitte geben Sie eine Beschreibung für Ihr(e) Abonnement(s) ein.',
  set_label: 'Bezeichnung setzen',
  set_label_fail: 'Das Etikett konnte nicht geändert werden. Bitte stellen Sie sicher, dass Sie vollen Zugriff auf dieses Gerät haben',
  set_label_success: 'Appliance-Label erfolgreich aktualisiert',
  set_schedule: 'Zeitplan festlegen',
  set_wifi_region: 'Legen Sie die WLAN-Region fest',
  set_wifi_region_fail: 'Die WLAN-Region konnte nicht geändert werden',
  set_wifi_region_success: 'WLAN-Region der Appliance erfolgreich aktualisiert',
  settings_rolledback: 'Einstellungen zurückgesetzt',
  settings_sso: 'Einmalige Anmeldung',
  settings_sso_any_provider: 'Jeder Anbieter',
  settings_sso_condition: 'Aus Gründen der Sicherheit Ihres Kontos ist diese Option nur aktiviert, wenn Sie sich mit Single Sign-On anmelden',
  settings_sso_enabled_condition: 'Zur Sicherheit Ihres Kontos können Sie nur das SSO auswählen, mit dem Sie sich angemeldet haben',
  settings_sso_google: 'Google',
  settings_sso_microsoft: 'Microsoft',
  settings_sso_not_required: 'Nicht benötigt',
  settings_sso_requirement: 'Single-Sign-On-Anforderung',
  settings_update_failed: 'Einstellungen konnten nicht geändert werden',
  settings_updated: 'Einstellungen erfolgreich geändert',
  setup: 'Aufstellen',
  setup_admin_email: 'Admin-E-Mail',
  setup_admin_password: 'Administrator-Passwort',
  setup_admin_username: 'Admin-Benutzername',
  setup_agreement: 'Ich stimme den {0} von Arista Edge Threat Management zu.',
  setup_appliance_is_master: 'Der Vorlagen-Push wurde nicht ausgeführt, da diese Appliance bereits als Master einer vorhandenen Vorlage festgelegt ist',
  setup_configure: 'Richten Sie Ihr Gerät ein',
  setup_eula: 'Endbenutzer-Lizenzvereinbarung',
  setup_incomplete: 'Das System konnte keine Verbindung zu Ihrer Appliance herstellen und die Konfiguration nicht abschließen. Fehler {msg}. Es wurde eine Aufgabe erstellt, die erneut versucht, eine Verbindung herzustellen.',
  setup_install_type: 'Installationstyp',
  setup_ngfw_config: 'Geben Sie Konfigurationsinformationen für diese Appliance ein',
  setup_time_zone: 'Zeitzone',
  severity: 'Schwere',
  shaping_description: 'Verwenden Sie Shaping-Regeln, um die Netzwerkleistung für bestimmte Verkehrsströme zu optimieren',
  share_sub_description: 'Erlauben Sie einem anderen Benutzer, dieses Abonnement anzuzeigen und zuzuweisen bzw. die Zuweisung aufzuheben.',
  share_sub_failed: 'Das Abonnement konnte nicht geteilt werden.',
  share_sub_success: 'Das Abonnement wurde erfolgreich geteilt.',
  share_sub_with_self: 'Sie können sich kein Abonnement zuordnen.',
  shared_subnets: 'Geteilte Subnetze',
  shipping_address: 'Lieferanschrift',
  shipping_name: 'Versandname',
  show_autogenerated: 'Automatisch generiert anzeigen',
  show_more: 'Zeig mehr...',
  show_on_partner_map: 'Auf der Partnerkarte anzeigen',
  show_qr_code: 'QR-Code anzeigen',
  show_rules: 'Regeln für Appliance anzeigen',
  sign_out: 'Abmelden',
  signing_certificate_if_different: 'Signaturzertifikat (falls vom Verschlüsselungszertifikat abweichend)',
  signing_out: 'Abmelden',
  silver_partner: 'Silber-Partner',
  since: 'seit',
  single_reboot_time_restriction: 'Es ist nur ein Neustartzeitpunkt zulässig. Löschen Sie den vorherigen Neustartzeitpunkt, um einen neuen Zeitpunkt zu erhalten',
  size: 'Größe',
  sku: 'Artikelnummer',
  slack: 'Locker',
  slack_configuration: 'Slack-Konfiguration',
  slack_info:
    'Diese Vorlage ist für einen Slack-Webhook. Für weitere Informationen klicken Sie <a href="https://api.slack.com/incoming-webhooks" target="_blank">hier.</a>',
  software_downloads: 'Software-Downloads',
  software_it: 'Software / IT',
  source_intf_name: 'Name der Quellschnittstelle',
  source_reverse_dns: 'Quell-Reverse-DNS',
  spam_blocker: 'Spam-Blocker',
  specific_lte: 'Spezifisches WAN – LTE',
  specific_wan0: 'Spezifisches WAN – WAN0',
  specific_wan1: 'Spezifisches WAN – WAN1',
  specify_port_list: 'Geben Sie einen einzelnen oder eine Liste von Ports oder Portbereichen an',
  specify_port_number: 'Geben Sie eine Portnummer an',
  ssl_cert_dns_name: 'DNS-Name des SSL-Zertifikats',
  ssl_inspector: 'SSL-Inspektor',
  sso: 'SSO',
  sso_existing_organization: 'Die Organisation, die Sie speichern möchten, existiert bereits',
  sso_required: 'SSO erforderlich',
  sso_save_failed: 'Die SSO-Einstellungen konnten nicht aktualisiert werden',
  sso_save_success: 'Die SSO-Einstellungen wurden erfolgreich aktualisiert',
  sso_type: 'SSO-Typ',
  start_date: 'Startdatum',
  start_time: 'Startzeit',
  state_and_local_gov: 'Landes- und Kommunalverwaltung',
  stats_pinganalyzers_create_fail: 'Statusanalysatoren konnten nicht erstellt werden.',
  stats_pinganalyzers_create_success: 'Statusanalysatoren erfolgreich erstellt.',
  stats_pinganalyzers_update_fail: 'Statusanalysatoren konnten nicht aktualisiert werden.',
  stats_pinganalyzers_update_success: 'Statusanalysatoren wurden erfolgreich aktualisiert.',
  step_n_of_3: 'Schritt {n} von 3',
  street_address: 'Straßenadresse',
  student: 'Student',
  sub_already_assigned: 'Dieses Abonnement ist dieser Appliance bereits zugewiesen',
  sub_charge_value: 'Neue Abonnementgebühr',
  sub_not_allowed: 'Dieses Abonnement ist auf dieser Appliance nicht zulässig.',
  sub_not_assignable: 'Dieses Abonnement kann keiner Appliance zugewiesen werden.',
  sub_owner: '{0} hat die administrative Kontrolle über dieses Abonnement',
  sub_redeemer: '{0} kann dieses Abonnement zuweisen und die Zuweisung aufheben',
  submit: 'Einreichen',
  submit_a_request: 'Senden Sie ein Support-Ticket',
  subnets: 'Subnetze',
  subscription: 'Abonnement',
  subscription_assigned_expired: 'Das dieser Appliance zugewiesene Abonnement ist abgelaufen. Kritische Sicherheitsfunktionen sind deaktiviert und Ihr Netzwerk ist möglicherweise gefährdet. | Ein oder mehrere Abonnements für diese Appliance sind abgelaufen. Kritische Sicherheitsfunktionen sind deaktiviert und Ihr Netzwerk ist möglicherweise gefährdet.',
  subscription_assigned_will_expire_day: 'Das dieser Appliance zugewiesene Abonnement läuft in einem Tag ab. | Ein oder mehrere Abonnements für diese Appliance laufen in einem Tag ab.',
  subscription_assigned_will_expire_days: 'Das dieser Appliance zugewiesene Abonnement läuft in {0} Tagen ab. | Ein oder mehrere Abonnements für diese Appliance laufen in {0} Tagen ab.',
  subscription_details: 'Abonnementdetails',
  subscription_home: 'heim',
  subscription_name: 'Abonnementname',
  subscription_not_toggling: 'Ihr automatisches Verlängerungsfenster für das/die Abonnement(s) {names} ist abgelaufen. Bitte wenden Sie sich an den Vertrieb von Arista Edge Threat Management, der Ihnen bei der Bearbeitung Ihrer Verlängerungswünsche behilflich sein wird.',
  subscription_owned_by_redeemer: 'Dieses Abonnement ist Eigentum des Einlösers',
  subscription_term: 'Abonnementlaufzeit:',
  subscription_term_1_month: 'Monatlich',
  subscription_term_12_month: '1 Jahr',
  subscription_term_15_month: '15 Monate',
  subscription_term_24_month: '2 Jahre',
  subscription_term_36_month: '3 Jahre',
  subscription_term_60_month: '5 Jahre',
  subscription_unlimited: 'unbegrenzt',
  subscription_upgrade: 'Abonnement-Upgrade',
  subscription_upgrade_failed: 'Abonnement-Upgrade fehlgeschlagen',
  subscription_upgrade_success: 'Erfolgreiches Upgrade des Abonnements',
  subscriptions: 'Abonnements',
  subscriptions_not_updated: 'Ihre Abonnements wurden nicht aktualisiert. Für weitere Informationen wenden Sie sich bitte an den Vertrieb von Arista Edge Threat Management.',
  subscriptions_not_updated_with_terms: 'Konten mit Bedingungen wie Standardzahlung können keine automatische Verlängerung einrichten',
  subtitle: 'Mit Netzwerken können Sie Appliances gruppieren, um aggregierte Leistungsdaten anzuzeigen und gemeinsame WAN-Routing-Richtlinien zu pushen.',
  success_green: '<span style="color:green;">ERFOLG</span>',
  suggest_idea: 'IDEENVORSCHLAG',
  swap: 'Tauschen',
  switch_organization_failed: 'Organisationswechsel fehlgeschlagen. Sie werden nun abgemeldet. Bitte versuchen Sie, sich erneut bei den gewünschten Organisationen anzumelden.',
  switch_organization_no_access: 'Organisationswechsel fehlgeschlagen. Sie scheinen keinen Zugriff mehr auf diese Organisation zu haben. Wenn Sie glauben, dass es sich hierbei um einen Fehler handelt, wenden Sie sich bitte an den Administrator der Organisation.',
  switch_to: 'Wechseln zu',
  sync: 'Synchronisieren',
  sync_access_rule_warning: 'Synchronisierungsregeln überschreiben die vorhandenen Zugriffsregeln auf dem Gerät',
  sync_all_new_appliances_already_enabled:
    'Die Einstellung „Alle neuen Appliances synchronisieren“ kann jeweils nur für eine Vorlage aktiviert werden. Bitte deaktivieren Sie diese Einstellung, um fortzufahren.',
  sync_cloud_vision_appliances: 'Synchronisieren Sie Cloud-Vision-Appliances',
  sync_failed: 'Ziel-Appliances konnten nicht synchronisiert werden.',
  sync_failed_no_complete_license_on_master: 'Die Zielgeräte können nicht synchronisiert werden. Die Master-Appliance verfügt nicht über eine vollständige Lizenz.',
  sync_failure_formatted_message: '<span style="color:red;">FEHLER</span> – {error}',
  sync_failure_toast_message: 'FEHLER – Eine oder mehrere Appliances konnten nicht synchronisiert werden.',
  sync_filter_rule_warning: 'Synchronisierungsregeln überschreiben die vorhandenen Filterregeln auf dem Gerät',
  sync_frequency: 'Synchronisierungsfrequenz',
  sync_in_progress: 'Die Synchronisierung läuft',
  sync_initiated: 'Synchronisierung initiiert',
  sync_initiated_target_appliances: 'Der Synchronisierungsprozess wurde auf allen Ziel-Appliances initiiert.',
  sync_now: 'Jetzt synchronisieren',
  sync_now_confirmation: 'Durch diesen Vorgang werden die Appliances in der Ziel-Appliance-Liste mit den Konfigurationen und Richtlinien aus dem Vorlagenmaster aktualisiert (mit Ausnahme der Netzwerkkonfiguration).',
  sync_now_could_not_be_performed: 'Die Synchronisierung konnte jetzt nicht durchgeführt werden',
  sync_policy_warning: 'Durch die Synchronisierung werden die {policy}-Einstellungen auf dem Gerät überschrieben. Bist du dir sicher, dass du weitermachen willst?',
  sync_rule_warning: 'Die Synchronisierung überschreibt den vorhandenen {policyType} auf dem Gerät.',
  sync_rules: 'Synchronisierungsregeln',
  sync_rules_to_mfw_appliances: 'Regeln mit MFW-Appliances synchronisieren',
  sync_service_policy: 'Dienstrichtlinie synchronisieren',
  sync_status: 'Synchronisierungsstatus',
  sync_success_toast_message: '<span style="color:green;">Erfolgreich!</span> Appliance {uid} wurde erfolgreich synchronisiert.',
  sync_target_with_template: 'Zielgerät mit Vorlage synchronisieren',
  sync_vpn_info: 'VPN-Informationen synchronisieren',
  sync_vpn_settings: 'VPN-Einstellungen synchronisieren',
  sync_wan_rule_warning: 'Synchronisierungsregeln überschreiben die vorhandenen WAN-Regeln und WAN-Richtlinien auf dem Gerät',
  sync_wan_rules: 'WAN-Regeln synchronisieren',
  synced: 'Synchronisiert',
  syncing: 'Synchronisierung',
  system_details: 'Systemdetails',
  system_setupwizard_update_fail: 'Der System-Setup-Assistent konnte nicht aktualisiert werden.',
  system_setupwizard_update_success: 'Der System-Setup-Assistent wurde erfolgreich aktualisiert.',
  system_timezone_update_fail: 'Systemzeitzone konnte nicht aktualisiert werden.',
  system_timezone_update_success: 'Systemzeitzone erfolgreich aktualisiert.',
  system_type: 'Systemtyp',
  system_update_fail: 'Das System konnte nicht aktualisiert werden.',
  system_update_success: 'System erfolgreich aktualisiert.',
  sysupgrade_failed: 'Fehlgeschlagener Sysupgrade-Versuch',
  target_appliance: 'Zielgerät',
  target_appliance_failure_toast_message: 'Eine oder mehrere Ihrer Ziel-Appliances können nicht synchronisiert werden. Bearbeiten Sie die Vorlage und entfernen Sie falsch konfigurierte Appliances.',
  task: 'Aufgabe',
  task_completed_successfully: 'Die Aufgabe wurde erfolgreich abgeschlossen',
  task_id: 'Aufgaben-ID',
  tasks: 'Aufgaben',
  tasks_list: 'Liste der Aufgaben',
  tasks_remove_failed: 'Beim Entfernen der ausgewählten Aufgaben ist ein Fehler aufgetreten',
  tasks_remove_successful: 'Die ausgewählten Aufgaben wurden erfolgreich entfernt',
  tax_exempt_status: 'Steuerbefreiungsstatus (nur USA):',
  tax_information: 'Steuerinformation',
  tax_vat_number: 'Steuer-/Mehrwertsteuernummer. Was Sie hier eingeben, erscheint auf Ihrer Rechnung.',
  taxes: 'Steuern',
  technical_difficulties: 'Leider haben wir technische Probleme. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie uns für weitere Informationen.',
  telecommunications: 'Telekommunikation & Internet',
  template: 'Vorlage',
  template_configuration: 'Vorlagenkonfiguration',
  template_master: 'Vorlagenmaster',
  template_not_found: 'Vorlage nicht gefunden',
  template_type: 'Vorlagentyp',
  templates: 'Vorlagen',
  templates_description: 'Erstellen und verwalten Sie Dienstkonfigurationsvorlagen zur Verwendung in Richtlinien',
  temporary_payment_error: 'Wir konnten Ihre Zahlung zu diesem Zeitpunkt nicht verarbeiten. Bitte versuchen Sie es später erneut. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an Arista Edge Threat Management Accounting, um Unterstützung zu erhalten.',
  term: 'Begriff',
  terms: 'Bedingungen',
  terms_credit_balance: 'Konditionen Guthaben:',
  terms_credit_limit: 'Konditionen Kreditlimit:',
  terms_method: 'Bedingungen. Verfügbarer Kredit:',
  test_general: 'Testen Sie die SSO-Einstellungen',
  test_oauth: 'Testen Sie die OAuth2-Einstellungen',
  test_saml: 'Testen Sie die SAML-Einstellungen',
  thank_you: 'Danke schön!',
  the_following_error_occurred: 'Der folgende Fehler ist aufgetreten:',
  threat_history: 'Bedrohungsgeschichte',
  threat_info: 'Bedrohungsinformationen',
  threat_prevention_description: 'Verwenden Sie eine Threat Prevention-Vorlage, um zu definieren, ob Threat Prevention auf diese Richtlinie angewendet werden soll',
  threatprevention_create_fail: 'Bedrohungsschutz konnte nicht erstellt werden.',
  threatprevention_create_success: 'Bedrohungsprävention erfolgreich erstellt.',
  threatprevention_update_fail: 'Die Aktualisierung der Bedrohungsprävention ist fehlgeschlagen.',
  threatprevention_update_success: 'Bedrohungsprävention erfolgreich aktualisiert.',
  threats: 'Bedrohungen',
  threats_blocked: 'Blockierte Bedrohungen:',
  throughput_50: 'Bis zu 50 Mbit/s',
  throughput_100: 'Bis zu 100 Mbit/s',
  throughput_300: 'Bis zu 300 Mbit/s',
  throughput_500: 'Bis zu 500 Mbit/s',
  throughput_unlimited: 'unbegrenzt',
  time_based_one_time_password: 'Zeitbasiertes Einmalpasswort',
  time_of_day_utc: 'Tageszeit (UTC)',
  time_zone_change_warning: 'Hinweis: Zeitzonenänderungen werden erst nach dem Neustart der Appliance vollständig wirksam',
  timeout_maximum_error: 'Der Abmeldezeitraum bei Inaktivität darf 48 Stunden nicht überschreiten',
  timeout_message: 'Der Timeout kann zwischen 1 Minute und 48 Stunden eingestellt werden',
  timeout_minimum_error: 'Der Abmeldezeitraum bei Inaktivität muss mindestens 1 Minute betragen',
  timeout_section_title: 'Abmeldezeitraum bei Inaktivität',
  timestamp: 'Zeitstempel',
  title: 'Netzwerke',
  to_target_appliances: 'An: Zielgeräte:',
  toggle_auto_renew_alert_message:
    '<strong>Sind Sie sicher, dass Sie die automatische Verlängerung deaktivieren möchten?</strong> <br/><br/>Wenn das Datum der automatischen Verlängerung ohne eine Verlängerungszahlung abläuft, müssen Sie eine neue Bestellung aufgeben und Ihre Netzwerksicherheitsanwendungen aktivieren schützt Ihr Netzwerk nicht mehr. <br/><br/>Drücken Sie „Nein“, um Ihr Abonnement automatisch auf dem aktuellen Stand zu halten.',
  toggle_auto_renew_fail_due_to_default_payment: 'Sie können die automatische Verlängerung für Abonnements nicht umschalten: {names}. Bei Ihrer Standardzahlungsmethode sind zu viele aufeinanderfolgende Fehler aufgetreten. Bitte aktualisieren Sie es, um die automatische Verlängerung zu aktivieren. Weitere Informationen erhalten Sie vom Vertrieb von Arista Edge Threat Management.',
  toggle_auto_renew_not_allowed: 'Sie können die automatische Verlängerung für das Abonnement {name}: {reason} nicht umschalten. Weitere Informationen erhalten Sie vom Vertrieb von Arista Edge Threat Management.',
  toggle_auto_renewal: 'Schalten Sie die automatische Verlängerung um',
  token: 'Zeichen',
  token_endpoint_url: 'Token-Endpunkt-URL',
  tools: 'Werkzeuge',
  top_applications: 'Top-Anwendungen',
  top_applications_bandwidth: 'Top-Anwendungen (nach Bandbreite) – Letzte 30 Tage',
  top_blocked_categories_by_request: 'Am häufigsten blockierte Kategorien (auf Anfrage)',
  top_blocked_hostnames_by_request: 'Am häufigsten blockierte Hostnamen (auf Anfrage)',
  top_blocked_sites: 'Am häufigsten blockierte Websites',
  top_blocked_sites_by_request: 'Am häufigsten blockierte Websites (auf Anfrage)',
  top_domains: 'Top-Domains',
  top_domains_by_request: 'Top-Domains (auf Anfrage)',
  top_hostnames: 'Top-Hostnamen',
  top_hostnames_bandwidth: 'Top-Hostnamen (nach Bandbreite)',
  top_hosts_last_30: 'Top-Hostnamen (nach Bandbreite) – Letzte 30 Tage',
  total: 'Gesamt',
  total_amount_all: '12-Monats-Betrag für das Konto (alle Abonnements)',
  total_amount_shared: '12-Monats-Betrag für Konto (geteilte Abonnements)',
  total_bandwidth: 'Gesamtbandbreite',
  total_bandwidth_30days: 'Gesamtbandbreite – Letzte 30 Tage',
  total_bandwidth_last_30: 'Gesamtbandbreite – Letzte 30 Tage',
  total_blocked_categories: 'Gesamtzahl der blockierten Kategorien',
  total_blocked_hostnames: 'Insgesamt blockierter Hostname',
  total_cost: 'Gesamtkosten',
  total_count: 'Komplette Anzahl',
  total_download: 'Gesamtdownload',
  total_number_of_policies: 'Gesamtzahl der Richtlinien:',
  total_shared: 'Geteilte Abonnenten mit Konto',
  totp: 'TOTP',
  totp_added_successful: 'Das TOTP wurde erfolgreich zur Appliance hinzugefügt',
  totp_added_unsuccessful: 'TOTP konnte dieser Appliance nicht hinzugefügt werden',
  totp_removed_successful: 'Das TOTP wurde erfolgreich von der Appliance entfernt',
  totp_removed_unsuccessful: 'TOTP konnte nicht von dieser Appliance entfernt werden',
  transporation: 'Transport',
  tunnel_vpn: 'Tunnel-VPN',
  two_factor_authentication: 'Zwei-Faktor-Authentifizierung',
  two_factor_required: 'Zwei-Faktor-Authentifizierung erforderlich',
  uid: 'UID',
  uid_or_serial_empty_text: 'UID oder Seriennummer',
  uid_validation: 'Ungültige UID – UIDs dürfen nur die Buchstaben A–F und die Zahlen 0–9 enthalten.',
  uids: 'Benutzeroberflächen',
  unable_to_communicate: 'Kommunikation mit dem ETM-Dashboard nicht möglich.',
  unable_to_process_payment: 'Die Zahlung kann nicht verarbeitet werden',
  unable_to_save: '{0} konnte nicht gespeichert werden',
  unassign_policies: 'Richtlinienzuweisung aufheben',
  unassign_policies_from_appliance: 'Unverbindliche Richtlinien von Appliance',
  unassign_policies_warning: 'Möchten Sie die Zuweisung aller Richtlinien zu ausgewählten Appliances aufheben?',
  unassign_sub: 'Abonnementzuweisung von <strong>{0}</strong> aufheben',
  unassign_sub_failed: 'Wir konnten die Zuweisung Ihres Abonnements nicht aufheben.',
  unassign_sub_success: 'Die Zuweisung des Abonnements wurde erfolgreich aufgehoben.',
  unassign_sub_warning: '<strong>WARNUNG:</strong> Die Zuweisung des Abonnements von <strong>{uid}</strong> wird aufgehoben.',
  unassigned: 'Nicht zugewiesen',
  unassigning: 'Zuweisung aufheben',
  unexpected_error_occurred: 'Ein unerwarteter Fehler ist aufgetreten.',
  unique_name_for_rule: 'Eindeutiger Name für die Regel',
  unique_notification_profile_name: 'Eindeutiger Name des Benachrichtigungsprofils',
  unknown_error: 'Unbekannter Fehler.',
  unknown_hostname: 'Unbekannter Hostname',
  unknown_ip: 'Unbekannte IP',
  unknown_mac: 'Unbekannter MAC',
  unknown_os: 'Unbekanntes Betriebssystem',
  unknown_platform: 'Unbekannte Plattform',
  unknown_uids: 'Unbekannte UIDs',
  unknown_version: 'Unbekannte Version',
  unlicensed_not_eligible: 'Nicht lizenziert – nicht berechtigt',
  uno_application: 'UNO-Anwendung',
  uno_application_groups: 'UNO-Anwendungsgruppen',
  unshare_sub: 'Freigabe dieses Abonnements für {0} aufheben',
  unshare_sub_failed: 'Wir konnten die Freigabe Ihres Abonnements nicht aufheben.',
  unshare_sub_success: 'Die Freigabe des Abonnements wurde erfolgreich aufgehoben.',
  unshare_sub_warning: '<strong>WARNUNG:</strong> Das Abonnement wird nicht mehr mit <strong>{email}</strong> geteilt.',
  unsupported_device_type: 'Nicht-MFW-Appliance wird nicht unterstützt!',
  untangle_go: 'App',
  untangle_go_mobile_app: 'Untangle Go Mobile-App',
  untangle_go_text: 'Sehen Sie sich jederzeit und überall den Netzwerkstatus und Warnungen an.',
  untangle_go_title: 'Los entwirren',
  untangle_server_error: 'Bei der Bearbeitung Ihrer Bestellung ist ein unerwarteter Fehler aufgetreten. Bitte wenden Sie sich für Unterstützung an Arista Edge Threat Management Accounting (untangle-accounting@arista.com oder 1-408-598-4292).',
  untangle_support: 'Unterstützung',
  untangle_support_text: 'Brauchen Sie ein wenig Hilfe? Finden Sie die Antworten, die Sie brauchen, in unserem Support-Bereich',
  unused_condition: 'Unbenutzter Zustand',
  unused_condition_group: 'Nicht verwendete Bedingungsgruppe',
  unused_object: 'Unbenutztes Objekt',
  unused_object_group: 'Unbenutzte Objektgruppe',
  unused_policy: 'Ungenutzte Richtlinie',
  unused_rule: 'Unbenutzte Regel',
  unused_template: 'Unbenutzte Vorlage',
  update_appliance_assignments: 'Aktualisieren von Gerätezuweisungen',
  update_appliance_hostname: 'Aktualisieren Sie den Appliance-Hostnamen',
  update_appliance_totp: 'Aktualisieren Sie den Appliance-TOTP',
  update_appliance_wifi_region: 'Aktualisieren Sie die WLAN-Region der Appliance',
  update_appliances_confirmation: 'ETM Dashboard sucht nach Software-Updates für diese Appliances. Wenn eines verfügbar ist, werden Ihre Geräte aktualisiert. Sind Sie sicher, dass Sie jetzt aktualisieren möchten?',
  update_appliances_location_failed: 'Die Standortaktualisierung ist fehlgeschlagen',
  update_appliances_location_success: 'Standort erfolgreich aktualisiert',
  update_cc_failed: 'Die Kreditkarteninformationen konnten nicht gespeichert werden. Bitte überprüfen Sie die Gültigkeit von Datum und Kartennummer.',
  update_credit_card_confirm: 'Die Kreditkarte wurde erfolgreich aktualisiert.',
  update_day_of_week: 'Wochentag',
  update_default_payment_method_no_selection: 'Bitte stellen Sie sicher, dass Sie eine Zahlungsmethode als Standard festgelegt haben.',
  update_domainname: 'Aktualisieren Sie den Domänennamen Ihrer Appliance. Dies kann ebenfalls zu einem Neustart führen',
  update_error: 'Die Appliance kann derzeit nicht aktualisiert werden',
  update_hostname: 'Aktualisieren Sie den Hostnamen Ihrer Appliance. Wenn Sie diesen ändern, wird Ihre Appliance möglicherweise neu gestartet',
  update_initiated: 'Upgrade eingeleitet',
  update_location: 'Aktualisieren Sie den Standort Ihrer Appliance',
  update_message: 'Wählen Sie eine oder mehrere Appliances aus, die für ein Software-Update in Frage kommen',
  update_no_datetime: 'Konnte keine Verbindung herstellen',
  update_none_scheduled: 'Keine geplant',
  update_notification_profile_failed: 'Das Aktualisieren des Benachrichtigungsprofils ist fehlgeschlagen',
  update_now: 'Jetzt aktualisieren',
  update_options: 'Update-Optionen',
  update_payment_methods: 'Bitte aktualisieren Sie Ihre Zahlungsdetails, indem Sie zu „Mein Konto -> Rechnungsinformationen“ gehen',
  update_picker_info: 'Bitte wählen Sie den Tag und die Uhrzeit für die Durchführung automatischer Upgrades aus.',
  update_scheduled: 'Aktueller Wochenplan',
  update_software: 'Software aktualisieren',
  update_sub_description: 'Beschreibung aktualisieren',
  update_sub_description_empty: 'Sie müssen mindestens ein Abonnement auswählen.',
  update_sub_description_failed: 'Die Beschreibung für die von Ihnen ausgewählten Abonnements kann nicht aktualisiert werden.',
  update_sub_description_success: 'Die Beschreibung für die ausgewählten Abonnements wurde erfolgreich aktualisiert.',
  update_tax_exempt_status_failed: 'Ihr Antrag auf Steuerbefreiung konnte nicht gesendet werden.',
  update_tax_exempt_status_success: 'Ihr Antrag auf Steuerbefreiung ist bei uns eingegangen. Wir senden Ihnen in Kürze eine E-Mail mit weiteren Informationen.',
  update_tax_vat_number_failed: 'Ihre Umsatzsteuer-Identifikationsnummer konnte nicht aktualisiert werden.',
  update_tax_vat_number_success: 'Ihre Umsatzsteuer-Identifikationsnummer wurde erfolgreich aktualisiert.',
  update_wifi_region: 'Aktualisieren Sie Ihre WLAN-Region',
  updates: 'Aktualisierungen der Datenschutzrichtlinien',
  updates_available: 'Updates verfügbar',
  updating_default: 'Standardzahlung wird aktualisiert',
  upgrade_confirm_message: 'Ihre aktuellen Abonnements werden gekündigt und ein neues erstellt. Sind Sie sicher, dass Sie mit dem Upgrade fortfahren möchten?',
  upgrade_day: 'Der Tag muss zwischen {0} und {1} liegen.',
  upgrade_discount_value: 'Rabattbetrag(e).',
  upgrade_docker_waf: 'Informationen zum Upgrade von WAF in Docker-Bereitstellungen finden Sie unter:',
  upgrade_failed: 'Fehlgeschlagener Upgrade-Versuch',
  upgrade_hour: 'Die Stunde muss zwischen {0} und {1} liegen.',
  upgrade_minute: 'Minute muss zwischen {0} und {1} liegen',
  upgrade_no_options_available: 'Für ausgewählte Abonnements sind keine Upgrade-Optionen verfügbar.',
  upgrade_price: '<b>Gesamtbetrag, der Ihrem Konto gutgeschrieben wird: </b>',
  upgrade_price_estimate_no_taxes: '<br/>Dies sind Ihre geschätzten Preise, die möglicherweise keine Steuern enthalten.',
  upgrade_product: 'Produkt aktualisieren',
  upgrade_redeemed_voucher_sub_not_allowed: 'Abonnements, die für eingelöste Gutscheine erstellt wurden, können nicht aktualisiert werden.',
  upgrade_refund_value: 'Rückerstattung des aktuellen Abonnements',
  upgrade_renewal_date: '<b>Neues Verlängerungsdatum: </b>',
  upgrade_renewal_price: '<b>Bei Verlängerung zu berechnender Gesamtpreis: </b>',
  upgrade_review_price: 'Überprüfen Sie den Preis',
  upgrade_selected_subs_failed: 'Ein oder mehrere der ausgewählten Abonnements konnten nicht aktualisiert werden. Bitte stellen Sie sicher, dass Ihre Standardzahlungsmethode gültig ist, oder wenden Sie sich für weitere Informationen an den Arista Edge Threat Management Support.',
  upgrade_sub_appliance: 'Appliances können nicht aktualisiert werden.',
  upgrade_sub_cmdc: 'Das Command Center-Produkt kann nicht auf ein Komplettpaket aktualisiert werden. Bitte treffen Sie eine neue Auswahl.',
  upgrade_sub_complete: 'Komplette Pakete müssen einzeln aktualisiert werden.',
  upgrade_sub_complete_exists: 'Dieses Abonnement ist einem Server zugeordnet, der bereits ein Komplettpaket enthält. Bitte ändern Sie Ihre Auswahl.',
  upgrade_sub_contact_untangle: 'Ausgewählte Produkte mit einer Verlängerungsfrist von 5 Jahren können hier nicht aktualisiert werden. Für weitere Optionen wenden Sie sich bitte an den Vertrieb von Arista Edge Threat Management.',
  upgrade_sub_default_fail: 'Die von Ihnen ausgewählten Abonnements können nicht aktualisiert werden. Bitte versuchen Sie ein separates Upgrade oder wenden Sie sich an den Support.',
  upgrade_sub_demo_bundle: 'Demopakete können nicht aktualisiert werden.',
  upgrade_sub_denied: 'Sie können dieses Abonnement nicht upgraden.',
  upgrade_sub_diff_seats: 'Ihre Auswahl lizenziert eine unterschiedliche Anzahl an Geräten und muss einzeln aktualisiert werden.',
  upgrade_sub_diff_servers: 'Ihre Auswahl ist verschiedenen Servern zugeordnet und muss einzeln aktualisiert werden.',
  upgrade_sub_duplicate: 'Ihre Auswahl enthält doppelte Produkte. Bitte entfernen Sie die Duplikate und versuchen Sie es erneut.',
  upgrade_sub_has_payfail: 'In Ihrem Konto ist kürzlich ein Zahlungsfehler festgestellt worden. Bitte aktualisieren Sie Ihre Zahlungsinformationen und versuchen Sie es erneut.',
  upgrade_sub_home_protect: 'Home Protect-Pakete müssen einzeln aktualisiert werden',
  upgrade_sub_mfw: 'Micro Edge-Abonnements müssen einzeln aktualisiert werden.',
  upgrade_sub_multiple_complete: 'Sie können den Upgrade-Vorgang nicht fortsetzen, wenn Sie unter den mehreren ausgewählten Abonnements mindestens ein Komplettpaket haben. Bitte entfernen Sie alle vollständigen Paketauswahlen und versuchen Sie es erneut.',
  upgrade_sub_new_term: '<br/>Mit Ihrem Abonnement beginnt eine neue Laufzeit.',
  upgrade_sub_nfr: 'Nicht zum Weiterverkauf bestimmte Produkte können nicht aktualisiert werden.',
  upgrade_sub_no_further: 'Für eines oder mehrere der ausgewählten Produkte ist kein weiteres Upgrade möglich.',
  upgrade_sub_oem: 'OEM-Pakete müssen einzeln aktualisiert werden.',
  upgrade_sub_seats_assigned: 'Bitte stellen Sie sicher, dass Sie alle Ihrem Server zugewiesenen Abonnements auswählen, um die Anzahl der Geräte zu erhöhen.',
  upgrade_sub_together: 'Die von Ihnen ausgewählten Abonnements können nicht gemeinsam aktualisiert werden. Bitte separat upgraden.',
  upgrade_tax_value: 'Gesamtsteuern',
  upgraded_subscription: 'Das Abonnement wurde aktualisiert.',
  upload_rate: 'Hochladen',
  upload_total: 'Gesamt-Upload',
  ups_error_in_processing: 'Bei der Berechnung der UPS-Gebühren für diese Bestellung ist ein Fehler aufgetreten',
  uri: 'Uri',
  url_admin_info: 'URL-Administratorinformationen',
  url_info: 'URL-Info',
  url_owner_info: 'Informationen zum URL-Inhaber',
  url_tech_info: 'URL-Technische Informationen',
  used: 'gebraucht',
  user_agent: 'User-Agent',
  user_already_invited: 'Benutzer: {emails} verwalten diese Organisation bereits.',
  user_band_conflict: 'Dieses Produkt verfügt über ein anderes Benutzerband als andere Produkte auf Ihrem Gerät.',
  user_email_address: 'E-Mail-Adresse des Benutzers',
  user_emails: 'Benutzer-E-Mails',
  user_info_endpoint_url: 'Benutzerinfo-Endpunkt-URL',
  user_info_update_failed: 'Die Kontaktinformationen konnten nicht aktualisiert werden.',
  user_interface: 'Benutzeroberfläche',
  user_invite_fail: 'Die Benutzer können nicht eingeladen werden.',
  user_invite_success: 'Benutzereinladungen erfolgreich gesendet.',
  user_name: 'Nutzername',
  user_not_found: 'Benutzer nicht gefunden',
  user_not_in_org: 'Sie sind kein Mitglied der Organisation, die Sie verlassen möchten',
  user_settings_saved_success: 'Benutzereinstellungen erfolgreich gespeichert',
  users: 'Benutzer',
  utilities: 'Versorgungsunternehmen und Energie',
  valid_license: 'Gültige Lizenz',
  vendor: 'Verkäufer',
  verification_method: 'Verifikationsverfahren',
  verify_appliance_type: 'Bitte überprüfen Sie den Gerätetyp',
  version: 'Ausführung',
  victor_ops: 'VictorOps',
  victorops_configuration: 'VictorOps-Konfiguration',
  victorops_info:
    'Diese Vorlage ist für den VictorOps REST Endpoint. Für mehr Informationen <a href="https://help.victorops.com/knowledge-base/victorops-restendpoint-integration/" target="_blank">klicken Sie hier.</a>\',',
  view_details: 'Details anzeigen',
  view_rules: 'Regeln anzeigen',
  virus_blocker: 'Virenblocker',
  voucher: 'Gutschein',
  voucher_already_redeemed: 'Der von Ihnen eingegebene Gutschein wurde bereits eingelöst.',
  voucher_code_to_redeem: 'Geben Sie den Gutscheincode ein, den Sie einlösen möchten',
  voucher_expired: 'Der von Ihnen eingegebene Gutschein ist abgelaufen.',
  voucher_missing_organization: 'Für die Einlösung des Gutscheins ist der Name der qualifizierten Institution erforderlich.',
  vouchers: 'Gutscheine',
  vouchers_account_no_purchases: 'Es sind keine Käufe auf dem Konto vorhanden. Bitte schließen Sie mindestens einen Kauf ab, um die Gutscheinfunktion zu aktivieren.',
  vpn_access: 'VPN-Zugang',
  vpn_reset_initiating: 'Das Zurücksetzen der VPN-Einstellungen für alle Geräte im Netzwerk wird eingeleitet. Überprüfen Sie den Überwachungsverlauf auf den Status dieses Zurücksetzens.',
  vpn_sync_initiating: 'Die Synchronisierung der VPN-Einstellungen für alle Geräte im Netzwerk wird eingeleitet. Überprüfen Sie den Überwachungsverlauf auf den Status dieser Synchronisierung.',
  waf: 'Webanwendungs-Firewall',
  waf_connection_lost: 'Aufgrund von Inaktivität wurde Ihre aktuelle Verbindung unterbrochen. Um ein neues zu erstellen, klicken Sie bitte',
  waf_description: 'Web Application Firewall (WAF) ist eine Sicherheits-Appliance, die Webanwendungen vor Internetbedrohungen und böswilligen Angriffen schützt, die zur Gefährdung oder zum Verlust von Daten führen können. Sie können WAF als Docker-Container bereitstellen oder mithilfe der ISO als Software-Appliance installieren.',
  waf_docker_description:
    'Der Docker-Container ist eine Bereitstellungsoption für die direkte Ausführung von WAF auf einem Webserver oder einer Linux-Distribution, die Docker ausführen kann, entweder vor Ort oder in einer öffentlichen Cloud-Instanz wie Amazon AWS, Microsoft Azure, Google Cloud oder Oracle Cloud. <a href="https://support.edge.arista.com/hc/en-us/articles/4410194539159" target="_blank">Erfahren Sie mehr</a> über die Docker-Container-Bereitstellung.',
  waf_iso_description:
    'Bei der ISO-Bereitstellungsoption handelt es sich um eine Debian-basierte Betriebssysteminstallation, die als virtuelle Appliance oder Bare-Metal auf einem Server bereitgestellt werden kann. <a href="https://support.edge.arista.com/hc/en-us/articles/4410086155799" target="_blank">Erfahren Sie mehr</a> über die ISO-Bereitstellung.',
  waf_not_supported: 'WAF wird derzeit nicht unterstützt',
  waf_setup_complete: 'Die Webanwendungs-Firewall wurde Ihrem Konto erfolgreich hinzugefügt',
  waf_setup_wizard_text: '<strong>Einrichtung</strong><br/>Vielen Dank, dass Sie sich für Arista Edge Threat Management entschieden haben! Wenn Sie auf „Weiter“ klicken, wird die Appliance Ihrem Konto hinzugefügt und Sie gelangen zum Konfigurationsassistenten.<br/><br/>',
  waf_unable_to_connect: 'Wir konnten keine Verbindung zu Ihrer Web Application Firewall herstellen: <b>{0}</b>',
  wan_balancer: 'WAN-Balancer',
  wan_create_fail: 'Fehler beim Erstellen der WAN-Regeln.',
  wan_create_success: 'WAN-Regeln erfolgreich erstellt.',
  wan_failover: 'WAN-Failover',
  wan_link_information: 'WAN-Link-Informationen',
  wan_policies_create_fail: 'Fehler beim Erstellen der WAN-Richtlinien.',
  wan_policies_create_success: 'WAN-Richtlinien erfolgreich erstellt.',
  wan_policies_sync_info_end: '. Richtlinien erfordern eine zugehörige WAN-Regel zur Synchronisierung mit dem Gerät.',
  wan_policies_sync_info_start: 'Erstellen Sie WAN-Richtlinien zur Verwendung in',
  wan_policies_update_fail: 'Die WAN-Richtlinien konnten nicht aktualisiert werden.',
  wan_policies_update_success: 'WAN-Richtlinien erfolgreich aktualisiert.',
  wan_rule_criteria_specified: 'Für eine gültige WAN-Regel muss mindestens ein Kriterium angegeben sein',
  wan_rule_description: 'Verwenden Sie WAN-Regeln, um Datenverkehr über bevorzugte Links weiterzuleiten',
  wan_update_fail: 'Die WAN-Regeln konnten nicht aktualisiert werden.',
  wan_update_success: 'WAN-Regeln erfolgreich aktualisiert.',
  web_cache: 'Web-Cache',
  web_events: 'Web-Events',
  web_filter_blocked: 'Ist blockiert',
  web_filter_category: 'Kategorie',
  web_filter_description: 'Verwenden Sie eine Webfilter-Vorlage, um zu definieren, welche URL-Kategorien durch diese Richtlinie zugelassen oder abgelehnt werden sollen',
  web_filter_flagged: 'Ist markiert',
  web_filter_reason: 'Grund',
  webfilter_block_session: 'Sitzung durch Webfilter blockiert',
  webfilter_create_fail: 'Webfilter konnte nicht erstellt werden.',
  webfilter_create_success: 'Webfilter erfolgreich erstellt.',
  webfilter_flag_session: 'Sitzung vom Webfilter markiert',
  webfilter_update_fail: 'Webfilter konnte nicht aktualisiert werden.',
  webfilter_update_success: 'Webfilter erfolgreich aktualisiert.',
  webhook: 'Webhook',
  webhook_configuration: 'WebHook-Konfiguration',
  webroot_credentials: 'Webroot Endpoint Security',
  webroot_credentials_instructions:
    'Bitte geben Sie Ihre Anmeldeinformationen für das <a href="https://identity.webrootanywhere.com/v1/Account/login" target="_blank">Webroot SecureAnywhere</a>-Konto ein. Dadurch wird der Abschnitt „Hosts“ um zusätzliche Informationen und Funktionen erweitert. Mehr darüber können Sie <a href="https://support.edge.arista.com/hc/en-us/articles/360041456633-Managing-endpoints-via-Webroot-integration" target="_blank">hier lesen .</a>',
  webroot_key_tooltip: 'Der übergeordnete Schlüsselcode, der für die Verbindung zur Webroot Cloud-Plattform verwendet wird',
  webroot_parent_keycode: 'Webroot-Parent-Schlüsselcode',
  website: 'Webseite',
  weekly: 'Wöchentlich',
  widgets_no_appliances: 'KEINE GERÄTE AUF IHREM KONTO',
  will_renew: 'Wird erneuert',
  workgroup: 'Arbeitsgruppe',
  x_others: '+{0} andere',
  yes: 'Ja',
  you_must_save_oauth2_settings: 'Sie müssen die OAuth2-Einstellungen speichern, bevor Sie einen Test ausführen.',
  you_must_save_saml_settings: 'Sie müssen die SAML-Einstellungen speichern, bevor Sie einen Test ausführen oder SP-Metadaten herunterladen',
  zuora_company_pattern: "Muster ./' kann nicht verwendet werden"
}
