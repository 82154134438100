<template>
  <u-widget
    :title="$t('appliances_title')"
    :info="$t('info_appliances_grid')"
    enable-refresh
    @refresh="$store.dispatch('appliances/fetchAppliances', { force: true })"
    v-on="$listeners"
  >
    <u-grid
      id="AppliancesGridWidget"
      toolbar="hidden"
      :no-data-message="$t('no_data')"
      :column-defs="applianceColumnDefs"
      :fetching="$store.state.appliances.fetching"
      :row-data="$store.state.appliances.list || []"
      :resize-columns.sync="resizeEvent"
      @refresh="$store.dispatch('appliances/fetchAppliances', { force: true })"
      @row-clicked="onRowClicked"
    />
  </u-widget>
</template>
<script>
  import grids from '@/plugins/ut/ut-grids'
  import WidgetMixin from '@/components/widgets/WidgetMixin'

  export default {
    mixins: [WidgetMixin],
    computed: {
      applianceColumnDefs() {
        return grids.getApplianceGridColumns(
          {
            tag: { hide: true },
            ip_address: { flex: 0, width: 120 },
            software_version: { flex: 0, width: 100 },
            uid: { hide: true },
            host_count: { flex: 0, width: 100 },
            last_seen: { flex: 0, width: 150, sort: 'desc' },
          },
          this.$store.state.data.ccViewModel.Account.NoLicenseEnforcement ? ['license'] : [],
        )
      },
    },
    created() {
      this.$store.dispatch('appliances/fetchAppliances')
    },
    methods: {
      onRowClicked({ data: appliance }) {
        this.$router.push({ name: 'appliances-id', params: { id: appliance.UniqueIdentifier } })
      },
    },
  }
</script>
