import Vue from 'vue'
import Vuex, { Store } from 'vuex'

// import modules
import data from './data'
import alerts from './alerts'
import events from './events'
import hosts from './hosts'
import appliances from './appliances'
import networks from './networks'
import policies from './policies'
import financial from './financial'
import reports from './reports'
import auth from './auth'
import subscriptions from './subscriptions'
import users from './users'
import vouchers from './vouchers'
import policyManager from './policyManager'
import { state, actions, mutations } from './base'

Vue.use(Vuex)

const store = new Store({
  modules: {
    data,
    appliances,
    hosts,
    alerts,
    events,
    auth,
    networks,
    policies,
    financial,
    reports,
    subscriptions,
    users,
    vouchers,
    policyManager,
  },
  state,
  actions,
  mutations,
})

export default store
