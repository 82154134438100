<template>
  <wan-policies-list
    v-if="boxSettings"
    :policies="policies"
    :wans="wans"
    :disabled="readOnly"
    @edit-policy="onEditPolicy"
    @delete-policy="onDeletePolicy"
  >
    <template #actions>
      <u-btn :min-width="null" :disabled="readOnly" class="mr-2" @click="onReset">{{ $t('reset_to_defaults') }}</u-btn>
      <u-btn :min-width="null" class="mr-2" @click="fetchSettings">{{ $t('refresh') }}</u-btn>
      <u-btn
        :min-width="null"
        :disabled="readOnly"
        @click="$router.push({ name: 'appliances-wan-policies-edit', params: { policyId: 'add' } })"
      >
        {{ $t('add_wan_policy') }}
      </u-btn>
    </template>
  </wan-policies-list>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { WanPoliciesList } from 'vuntangle'
  import DeleteWanPolicyDialog from './DeleteWanPolicyDialog.vue'
  import settingsMixin from './settingsMixin'

  export default {
    components: { WanPoliciesList },
    mixins: [settingsMixin],

    computed: {
      // wan policies from settings
      policies: ({ boxSettings }) => boxSettings?.wan.policies || [],
      // WAN interfaces from the box
      wans: ({ boxSettings }) => boxSettings?.network.interfaces?.filter(intf => intf.wan) || [],
      // WAN rules from the box
      rules: ({ boxSettings }) => boxSettings.wan.policy_chains.rules || [],
    },

    methods: {
      /**
       * Removes a WAN policy if not in use
       * @param {String} id - UUID of the Wan policy to be removed
       */
      onDeletePolicy(id) {
        const deleteIndex = this.policies.findIndex(policy => policy.policyId === id)
        if (deleteIndex === -1) {
          return
        }
        // Filters out rules for the selected policy
        const affectedRules = this.rules.filter(rule => rule.action.policy === this.policies[deleteIndex].policyId)
        if (affectedRules.length > 0) {
          // policy with > 0 rules
          this.$vuntangle.dialog.show({
            title: `${this.$t('delete_policy')}`,
            component: DeleteWanPolicyDialog,
            width: 800,
            actionLabel: this.$t('yes'),
            cancelLabel: this.$t('no'),
            componentProps: {
              deleteIndex,
              affectedRules,
              settings: this.settings,
            },
            componentEvents: {
              update: updatedSettings => {
                // update policies from dialog
                this.settings = updatedSettings
                this.policies = updatedSettings.policies
              },
            },
          })
        } else {
          // policy with no rules
          const policies = cloneDeep(this.policies)
          policies.splice(deleteIndex, 1)
          this.onSave(policies)
        }
      },

      /**
       * redirect to the Wan Policy editing page
       * @param {String} policyId - UUID of the Wan policy to be edited
       */
      onEditPolicy(policyId) {
        this.$router.push({ name: 'appliances-wan-policies-edit', params: { policyId } })
      },

      /**
       * calls the mixin `saveSettings`
       * @param {Array} updatedPolicies - the updated list of Wan Policies to be saved
       */
      onSave(updatedPolicies) {
        this.saveSettings('wan/policies', updatedPolicies)
      },

      /**
       * calls the mixin `onResetToDefaults`
       */
      onReset() {
        this.onResetToDefaults('wan-rules')
      },
    },
  }
</script>
