<template>
  <!-- display qr code area with gray border -->
  <div :style="{ position: 'relative' }" class="qr-code-image-size light-grey-border mt-4 ml-4 mr-4">
    <!-- display the qr code image, display a loader or the image and a close button -->
    <div v-show="displayQRCode">
      <!-- display an close button to hide the qr code image -->
      <v-icon style="position: absolute; top: 2px; right: 0px" @click="displayQRCode = false">mdi-close</v-icon>
      <!-- display loader image while qr code image is loading -->
      <div v-if="!qrImageLoaded" class="d-flex qr-code-image-size">
        <v-progress-circular style="display: block; margin: auto" indeterminate size="32" color="aristaMediumBlue" />
      </div>
      <!-- v-if is used to only load the qr code if totp is selected, and to not load it again -->
      <img class="fs-exclude fs-exclude-without-consent" :src="qrCodeImage" @load="qrImageLoaded = true" />
    </div>
    <u-btn v-show="!displayQRCode" style="position: absolute; top: 82px; right: 37px" @click="displayQRCode = true">{{
      $t('show_qr_code')
    }}</u-btn>
  </div>
</template>

<script>
  export default {
    props: {
      qrCodeImage: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        // toggle to display QR code or not, for increased security
        displayQRCode: false,
        // if the qr image has been loaded or not, used to display the loader
        qrImageLoaded: false,
      }
    },
  }
</script>

<style lang="css">
  .qr-code-image-size {
    width: 200px;
    height: 200px;
  }

  .light-grey-border {
    border: 1px solid lightgrey;
  }
</style>
