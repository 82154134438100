<template>
  <u-widget
    :title="$t('intrusions_detected')"
    :info="$t('info_intrusions_detected')"
    enable-refresh
    @refresh="fetchDetectedIntrusions"
    v-on="$listeners"
  >
    <u-grid
      id="detected-intrusions"
      toolbar="hidden"
      :no-data-message="$t('no_data')"
      :column-defs="detectedIntrusionsDefs"
      :fetching="fetching"
      :row-data="detectedIntrusions"
      :resize-columns.sync="resizeEvent"
      @refresh="fetchDetectedIntrusions"
      @row-clicked="viewDetails"
    />
    <u-dialog
      :show-dialog="!!filteredRecords.length"
      :title="$t('additional_information')"
      :width="700"
      :buttons="[
        {
          'name': $t('ok'),
          'handler': 'close-dialog',
        },
      ]"
      @close-dialog="onCloseDialog"
    >
      <extra-details :filtered-records="filteredRecords" :current-index.sync="currentIndex" />
    </u-dialog>
  </u-widget>
</template>
<script>
  import grids from '@/plugins/ut/ut-grids'
  import reports from '@/plugins/ut/ut-reports'
  import util from '@/plugins/ut/ut-util'
  import WidgetMixin from '@/components/widgets/WidgetMixin'
  import ExtraDetails from '@/components/widgets/ExtraDetails'

  export default {
    components: { ExtraDetails },
    mixins: [WidgetMixin],
    props: {
      uids: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        detectedIntrusions: [],
        fetching: false,
        // list of records passed to detail dialog to navigate through
        filteredRecords: [],
        // index of the record being viewed from filteredRecords
        currentIndex: -1,
      }
    },
    computed: {
      detectedIntrusionsDefs: () =>
        grids.mergeWithApplianceColumns(grids.getDetectedIntrusionsColumnDefs(), ['appliance', 'uid', 'tag']),
    },
    watch: {
      uids: {
        immediate: true,
        handler() {
          this.fetchDetectedIntrusions()
        },
      },
    },
    methods: {
      async fetchDetectedIntrusions() {
        this.fetching = true
        const response = await reports.getReportData(
          'DetectedIntrusions',
          this.uids,
          this.$vuntangle.dates.getDateString(new Date(), -30),
          '',
        )
        const localDetectedIntrusions = reports.unpackGridData(response)
        /**
         * Merge localDetectedIntrusions data with appliances data
         */
        this.detectedIntrusions = this.$store.getters['appliances/populateApplianceInfoByUid'](
          localDetectedIntrusions,
          'Uid',
        )
        this.fetching = false
      },

      onCloseDialog() {
        this.filteredRecords = []
        this.currentIndex = -1
      },

      /**
       * Called when user clicks on a row
       * builds the props needed for details dialog and displays the dialog
       * @param {*} param clicked row to fetch details for
       */
      viewDetails(params) {
        params.api.forEachNodeAfterFilterAndSort(node => {
          const row = node.data
          this.filteredRecords.push({
            msg: row.msg,
            class_id: row.class_id,
            classtype: row.classtype,
            gen_id: row.gen_id,
            rule_id: row.rule_id,
            protocol: row.protocol,
            blocked: row.blocked,
            source_port: row.source_port,
            dest_addr: row.dest_addr,
            sig_id: row.sig_id,
            category: row.category,
            source_addr: row.source_addr,
            dest_port: row.dest_port,
            uid: util.obfuscateUid(row.uid),
            'Appliance Hostname': util.uidToHostname(row.uid),
            appliance: util.uidToApplianceTag(row.uid),
            date: row.time_stamp?.time
              ? this.$vuntangle.dates.formatDateFromApi(row.time_stamp.time)
              : this.$t('unknown'),
          })
        })
        this.currentIndex = params.node.rowIndex
      },
    },
  }
</script>
