/**
 * This plugin is a collection of methods relevant to subscriptions
 */
import i18n from '@/plugins/vue-i18n'
import util from '@/plugins/ut/ut-util'

const subscriptions = {
  isProductAssignable(productName) {
    return productName ? !productName.includes('Command Center') : false
  },

  /**
   * Determines if the specified subscription is assignable to the specified appliance
   * @param {object} subscription - subscription
   * @param {object} appliance - appliance
   * @returns {boolean} - true is subscription can be assigned to the specified appliance, false otherwise
   */
  isSubscriptionAssignableToAppliance(subscription, appliance) {
    if (!this.isProductAssignable(subscription.productName)) return false
    if (
      subscription.allowedApplianceNames !== '*' &&
      subscription.allowedApplianceNames !== 'NGFW' &&
      subscription.allowedApplianceNames !== 'MFW'
    ) {
      const nameArr = subscription.allowedApplianceNames.split(',').map(appliance => appliance.toLowerCase())
      // this is an Unlimited for subscription. only display the allowed appliances
      if (typeof appliance.ApplianceModel === 'string' && !nameArr.includes(appliance.ApplianceModel.toLowerCase()))
        return false
    } else {
      // if we don't know the appliance type, allow assignment of any subscription (*, NGFW, or MFW)
      if (util.isUndefinedOrNullOrEmpty(appliance.ProductLine)) return true
      // if the product-line is known, make sure the subscription allowedAppliances actually matches
      if (subscription.allowedApplianceNames !== '*' && appliance.ProductLine !== subscription.allowedApplianceNames)
        // if the appliance product line doesn't match the allowed type for subscription, skip it
        return false
    }
    return true
  },

  /**
   * Returns the value that should be used in the 'Assigned To' field in the subscription model.
   * @param subscription - subscription
   * @returns {*|string} - value that should be used based on subscription parameters
   */
  calculateSubscriptionAssignedToField(subscription) {
    const assignedServer = !util.isUndefinedOrNullOrEmpty(subscription.serverId)
      ? subscription.serverId === 'N/A'
        ? 'N/A'
        : util.obfuscateUid(subscription.serverId)
      : '* ' + i18n.t('not_assigned')
    let returnValue = assignedServer
    if (subscription.isShared) {
      returnValue = subscription.customerIsRedeemer
        ? assignedServer
        : subscription.subscriptionCustomerEmail + ' [' + assignedServer + ']'
    }
    return returnValue
  },

  /**
   * Checks if the given subscription has a payment failure.
   * @param subscription - subscription data
   * @return {boolean} - true if subscription has a payment failure, false otherwise
   */
  isPayfailSubscription(subscription) {
    const paymentStatus = subscription.paymentStatus
    const paymentFailureStatus = !util.isUndefinedOrNull(subscription.paymentStatus)
      ? paymentStatus.match('PAYFAIL')
      : ''
    return paymentFailureStatus === 'PAYFAIL'
  },

  /**
   * Checks if the given subscription is a home subscription.
   * @param {*} subscription - subscription data
   * @returns {boolean} - true if this is a home subscription, false otherwise
   */
  isHomeSubscription(subscription) {
    return subscription.sku?.toLowerCase().includes('home')
  },

  /**
   * Returns the max device count for the given subscription.
   * @param {*} subscription - subscription data
   * @returns {string} - max device count or N/A if subscription does not have a device count
   */
  getSubscriptionMaxDevices(subscription) {
    const sort = subscription.userBand?.sort
    const isHome = this.isHomeSubscription(subscription)
    if (sort && sort > 0) {
      if (isHome) {
        return 'home'
      }
      return sort
    }
    return 'N/A'
  },

  /**
   * Returns subscription status for the given subscription.
   * @param {string} status - subscription status
   * @returns {string} - formatted status
   */
  formatSubscriptionStatus(status) {
    if (status) {
      // prevent showing 'Voucher/' prefix
      return status.replace(/Voucher\//g, '')
    }
    return ''
  },
}

export default subscriptions
