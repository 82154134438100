<template>
  <div>
    <div>
      <span class="font-weight-bold">{{ $t('blocked_traffic_assessed_as') }}</span>
      {{ threatLevels[data.sensitivity] }}
    </div>

    <div class="mt-2">
      <span class="font-weight-bold">{{ $t('pass_list') }}:</span> {{ passList || '-' }}
    </div>

    <div class="font-weight-bold mt-2">
      {{ $t('toggle_threat_prevention_redirect', [data.redirect ? $t('enabled') : $t('disabled')]) }}
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      data: { type: Object, default: () => {} },
    },

    computed: {
      threatLevels: ({ $i18n }) => ({
        20: $i18n.t('high_risk'),
        40: $i18n.t('suspicious'),
        60: $i18n.t('moderate_risk'),
      }),
      passList: ({ data }) => data.passList.map(list => list.host).join(', '),
    },
  }
</script>
