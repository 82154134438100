import api from '@/plugins/ut/ut-api'

const keepalive = {
  // hold the 'keepalive' timer for keeping the user logged onto the server
  keepaliveTimer: null,

  // keep last active timestamp in seconds for 'keepalive' checking
  activeTimestamp: Date.now(),

  /**
   * Reset the keep alive timer.  This will get reset after a server side call since the call will keep the
   * user alive.
   *
   * @param {Function} commit
   * @param {Object}   state
   * @param {Function} dispatch
   *
   * @returns {void}
   */
  resetTimer(timeout = 40000) {
    this.clearTimer()
    this.keepaliveTimer = setTimeout(() => this.sendKeepalive(this), timeout)
  },

  /**
   * Clear the keepalive timer.  This is done during logout and cleared before setting another timer.
   *
   * @param {Function} commit
   * @param {Function} state
   *
   * @returns {void}
   */
  clearTimer() {
    if (this.keepaliveTimer) {
      clearTimeout(this.keepaliveTimer)
      this.keepaliveTimer = null
    }
  },

  /**
   * Send a keepalive request to the server.  Will set the timer to send again in a minute.
   *
   * @param state
   * @param dispatch
   *
   * @returns {Promise<void>}
   */
  async sendKeepalive(self) {
    // send a keepalive request if they have been active within the last minute
    if (Date.now() - self.activeTimestamp < 60000) {
      await api.cloud('Untangle_CommandCenter', 'Keepalive')
    }

    self.resetTimer(60000)
  },

  /**
   * Set the active timestamp to the current time.  Called when a route is changed.
   *
   * @param commit
   *
   * @returns {void}
   */
  setActiveTimestamp() {
    this.activeTimestamp = Date.now()
  },
}

export default keepalive
