<template>
  <component
    :is="component"
    v-if="actionCopy"
    :configurations="configurations"
    :config-type="configType"
    v-on="$listeners"
  />
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import isEqual from 'lodash/isEqual'
  import { rulesConfig, Type } from 'vuntangle/pm'

  import {
    ActionSecurity,
    ActionDestination,
    ActionSource,
    ActionPriority,
    ActionWanPolicy,
    ActionConfiguration,
  } from './component'

  export default {
    components: {
      ActionSecurity,
      ActionDestination,
      ActionSource,
      ActionPriority,
      ActionWanPolicy,
      ActionConfiguration,
    },
    provide() {
      return {
        $action: () => this.actionCopy,
      }
    },
    props: {
      action: { type: Object, default: undefined },
      ruleType: { type: String, default: undefined },
      configurations: { type: Array, default: undefined },
    },

    data() {
      return {
        actionCopy: undefined,
      }
    },
    computed: {
      configType: ({ ruleType }) => rulesConfig[ruleType]?.text,
      component: ({ ruleType }) => {
        switch (ruleType) {
          case Type.RuleSecurity:
            return 'ActionSecurity'
          case Type.RulePortForward:
            return 'ActionDestination'
          case Type.RuleShaping:
            return 'ActionPriority'
          case Type.RuleNat:
            return 'ActionSource'
          case Type.RuleWanPolicy:
            return 'ActionWanPolicy'
          default:
            return 'ActionConfiguration'
        }
      },
    },
    watch: {
      action: {
        handler(action) {
          if (isEqual(action, this.actionCopy)) return
          this.actionCopy = cloneDeep(action)
        },
        immediate: true,
        deep: true,
      },
      actionCopy: {
        handler(action) {
          this.$emit('update:action', action)
        },
        immediate: true,
        deep: true,
      },
    },
  }
</script>
