<!--
  Component editing a condition object item: { type, op, value/object }
  Uses a compact way of displaying looking like those 3 fields are tight together
-->
<template>
  <v-sheet class="d-flex mb-0 px-1" outlined elevation="0" rounded>
    <!-- condition type selector, using a fixed with as we want all items to be perfectly aligned -->
    <condition-type style="width: 360px" class="flex-grow-0 flex-shrink-0" />

    <v-divider vertical class="mx-1" />

    <!-- op & value when condition type is set -->
    <template v-if="condition.type">
      <condition-operator style="width: 200px" class="flex-grow-0 flex-shrink-0" />
      <v-divider vertical class="mx-1" />
      <component
        :is="conditionComponent"
        v-if="conditionComponent"
        :condition-type="condition.type"
        class="flex-grow-1"
        v-on="$listeners"
        @create-object="onCreateObject"
        @create-object-group="onCreateGroup"
      />
      <v-divider vertical class="mx-1" />
      <v-btn icon @click="$emit('delete-item')"><v-icon>mdi-close</v-icon></v-btn>
    </template>
  </v-sheet>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import isEqual from 'lodash/isEqual'
  import { mapActions } from 'vuex'
  import { ValidationObserver } from 'vee-validate'
  import { OperatorType, conditionsConfig } from 'vuntangle/pm'
  import { generateDefaultMFWObject } from '../util'
  import ConditionOperator from './condition/ConditionOperator.vue'
  import ConditionType from './condition/ConditionType.vue'

  import {
    ValueSelect,
    ValueText,
    ValueNumber,
    ValueAutocomplete,
    ValueBoolean,
    ValueObject,
    ValueTime,
  } from './condition/value'

  import {
    Address,
    Application,
    Geoip,
    Interface,
    // CD-4834: hide Limit Rate for now
    // LimitRate,
    PortNumber,
    VlanTag,
    Hostname,
    Placeholder,
    DnsHint,
  } from './condition/component'

  export default {
    components: {
      ValueSelect,
      ValueText,
      ValueNumber,
      ValueAutocomplete,
      ValueBoolean,
      ValueObject,
      ValueTime,

      ConditionType,
      ConditionOperator,
      Address,
      Application,
      Geoip,
      Interface,
      // CD-4834: hide Limit Rate for now
      // LimitRate,
      PortNumber,
      VlanTag,
      Hostname,
      DnsHint,

      Placeholder,
      ValidationObserver,
    },

    /** provide extra data for the child components */
    provide() {
      return {
        $condition: () => this.condition,
        $target: this.target,
        $targetCount: () => this.targetCount,
      }
    },
    props: {
      // a condition item
      condition: { type: Object, default: () => ({ type: '' }) },
      // whether the target is SOURCE/DEST/OTHER
      target: { type: String, required: true },
      // number of existing conditions for a given SOURCE/DEST/OTHER
      targetCount: { type: Number, default: 0 },
    },
    data() {
      return {
        conditionCopy: undefined,
      }
    },
    computed: {
      // the condition configuration
      config: ({ condition }) => conditionsConfig[condition.type],

      // condition component rendering
      conditionComponent: ({ config }) => config?.component || 'Placeholder',
    },

    watch: {
      condition: {
        handler(condition) {
          if (isEqual(condition, this.conditionCopy)) return
          this.conditionCopy = cloneDeep(condition)
        },
        immediate: true,
        deep: true,
      },
      // update the condition upon change
      conditionCopy: {
        handler(condition) {
          this.$emit('update:condition', condition)
        },
        immediate: true,
        deep: true,
      },
    },

    methods: {
      ...mapActions('policyManager', ['editObjectDialog']),

      /**
       * Creates a new object using the dialog editor
       * @param {String} objectType - the object type to be created, e.g. `mfw-object-ipaddress`
       * @param {String} presetValue - a value used on creating a new object (optional), e.g. `1.2.3.4, 2.3.4.5`
       */
      onCreateObject(objectType, presetValue) {
        // generate a new object
        const newObject = generateDefaultMFWObject(objectType)
        // and use the `create` temporary id instead of object type as done with routing
        newObject.Id = 'create'
        // set `items` if presetValue is passed
        newObject.PolicyJson.items = presetValue?.split(',') || []

        // show create dialog
        this.editObjectDialog({
          object: newObject,
          /**
           * Callback after the new object was created
           * @param {Object} createdObject - the response holding the newly created Object
           */
          cb: createdObject => {
            if (this.conditionCopy.value) {
              // if it's a value turn it into an object with match/non_match
              this.conditionCopy.op = OperatorType.Equal ? OperatorType.Match : OperatorType.NotMatch
              delete this.conditionCopy.value
              this.$set(this.conditionCopy, 'object', [createdObject.Id])
            } else {
              // if it's already an object just push the new object to list
              this.conditionCopy.object.push(createdObject.Id)
            }
            this.$emit('validate')
          },
        })
      },

      /**
       * Creates a new group using the dialog editor
       * @param {String} objectGroupType - the object group type to be created, e.g. `mfw-object-ipaddress-group`
       */
      onCreateGroup(objectGroupType) {
        // generate a new object
        const newObject = generateDefaultMFWObject(objectGroupType)
        // and use the `create` temporary id instead of object type as done with routing
        newObject.Id = 'create'
        // populate group items with the existing objects (if they do exists)
        newObject.PolicyJson.items = this.conditionCopy.object

        // show create dialog
        this.editObjectDialog({
          object: newObject,
          /**
           * Callback after the new object group was created
           * @param {Object} createdObjectGroup - the response holding the newly created Object
           */
          cb: createdObjectGroup => {
            if ([OperatorType.Match, OperatorType.NotMatch].includes(this.conditionCopy.op)) {
              // if has objects turn it into an group with in/not_in
              this.conditionCopy.op = OperatorType.Match ? OperatorType.In : OperatorType.NotIn
              this.$set(this.conditionCopy, 'object', [createdObjectGroup.Id])
            } else {
              // if it's already group just push the new group to list
              this.conditionCopy.object.push(createdObjectGroup.Id)
            }
            this.$emit('validate')
          },
        })
      },
    },
  }
</script>
