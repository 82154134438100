<template>
  <div class="d-flex flex-column fill-height pa-0">
    <div class="d-flex">
      <div class="mr-2" style="flex: 2">
        <div class="mb-4">
          <h4>{{ $t('port_number') }}</h4>
          <span class="caption">{{ $t('specify_port_list') }}</span>
        </div>
        <ValidationProvider v-slot="{ errors }" rules="required|port_expression">
          <u-text-field v-model="port" :error-messages="errors" :placeholder="$t('port')">
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-text-field>
        </ValidationProvider>
      </div>
      <div style="flex: 3">
        <div class="mb-4">
          <h4>{{ $t('ip_addresses') }}</h4>
          <span class="caption">{{ $t('input_a_comma_separated_list_of_ips_cidrs') }}</span>
        </div>
        <ValidationProvider v-slot="{ errors }" rules="required|ip_any_expression_cidr_list">
          <u-text-field v-model="ips" :error-messages="errors" data-testid="ip-list">
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-text-field>
        </ValidationProvider>
      </div>
    </div>
    <slot name="actions" :new-settings="settingsCopy" />
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import isEqual from 'lodash/isEqual'

  export default {
    props: {
      /**
       * the object data (PolicyJson) expected in the form
       *   id: '<id that should match Policy Id>',
       *   name: '<a name>',
       *   items: [port: '222', ips: ['124.33.33.4']],
       *   type: <the type as expected on backend>,
       * */
      settings: { type: Object, default: () => {} },
    },
    data() {
      return {
        // local cloned settings
        settingsCopy: undefined,
      }
    },
    computed: {
      // computed prop to manipulate object `items`
      ips: {
        get() {
          return this.settingsCopy.items[0].ips.join(', ')
        },
        set(value) {
          this.settingsCopy.items[0].ips = value.split(',').map(item => item.trim())
        },
      },

      port: {
        get() {
          return this.settingsCopy.items[0].port.join(', ')
        },
        set(value) {
          this.settingsCopy.items[0].port = value.split(',').map(item => item.trim())
        },
      },
    },
    watch: {
      settings: {
        handler(settings) {
          if (isEqual(settings, this.settingsCopy)) return
          this.settingsCopy = cloneDeep(settings)
        },
        immediate: true,
      },
      // make sure the settings have a port+ips structure, if it's empty
      settingsCopy: {
        handler(settings) {
          if (!settings) return
          if (!settings.items.length) {
            this.settingsCopy.items.push({ port: [], ips: [] })
          }
        },
        immediate: true,
      },
    },
  }
</script>
