<template>
  <u-page :title="$t('appliances_update')" :subtitle="$t('update_message')" full-height>
    <template #actions>
      <u-btn text :to="{ name: 'appliances' }">
        <span :class="`${$vuetify.theme.dark ? 'white--text' : ''}`">
          {{ $t('cancel') }}
        </span>
      </u-btn>
      <u-btn :disabled="!selectedAppliances.length" @click="updateSoftwareConfirm = true">
        {{ $t('update') }}
      </u-btn>
    </template>

    <u-grid
      id="appliances-grid"
      selection-type="multiAction"
      :no-data-message="$t('no_data')"
      :column-defs="columnDefs"
      :fetching="$store.state.appliances.fetching || $store.state.appliances.fetchingUnassigned"
      :row-data="appliances"
      :selection.sync="selectedAppliances"
      @refresh="$store.dispatch('appliances/fetchFullAppliances', { force: true })"
    >
    </u-grid>

    <u-dialog
      :show-dialog="updateSoftwareConfirm"
      :title="$t('update_software')"
      :message="$t('update_appliances_confirmation')"
      :buttons="[
        { name: $t('no') },
        {
          name: $t('yes'),
          handler: 'updateSoftware',
          showProgress: true,
        },
      ]"
      @updateSoftware="updateSoftware"
      @close-dialog="updateSoftwareConfirm = false"
    />
    <u-dialog
      :show-dialog="updateSoftwareFailed.length > 0"
      :title="$t('appliance_update_software_failed_title')"
      :buttons="[{ name: $t('close') }]"
      @close-dialog="updateSoftwareFailed = []"
    >
      <table cellpadding="10" style="width: 100%">
        <tr v-for="appliance in updateSoftwareFailed" :key="appliance.uid">
          <td>{{ appliance.hostname }}</td>
          <td>{{ appliance.tag }}</td>
          <td>{{ appliance.errMsg }}</td>
        </tr>
      </table>
    </u-dialog>
  </u-page>
</template>

<script>
  import vuntangle from '@/plugins/vuntangle'
  import grids from '@/plugins/ut/ut-grids'
  import util from '@/plugins/ut/ut-util'

  export default {
    data() {
      return {
        isUpdateMode: false, // boolean to switch between listing and update selection
        selectedAppliances: [], // array of selected appliances for update
        addApplianceDialog: false, // boolean for showing the appliance add dialog
        rowSelect: null, // the grid selection type ('multiple')
        updateSoftwareConfirm: false,
        updateSoftwareFailed: [],
      }
    },
    computed: {
      // grid data returning all appliances or only upradable appliances
      appliances() {
        return this.$store.getters['appliances/upgradable']
      },
      // appliances columns definitions and order
      columnDefs() {
        return grids.getApplianceGridColumns(
          {},
          this.$store.state.data.ccViewModel.Account.NoLicenseEnforcement ? ['license'] : [],
        )
      },
    },

    watch: {
      // on update mode switch the grid will set or remove multiple selection
      isUpdateMode(val) {
        if (val) {
          this.rowSelect = 'multiAction'
        } else {
          this.rowSelect = null
        }
      },
    },

    created() {
      this.$store.commit('SET_APPLIANCES_SELECTION', this.$route.path)
      this.$store.dispatch('appliances/fetchFullAppliances')
    },

    methods: {
      // Method dispatching the update software async coll
      async updateSoftware() {
        if (this.selectedAppliances.length === 0) {
          return
        }
        const uids = this.selectedAppliances.map(appl => appl.Uid || appl.DisplaySerialNumber)

        window.ga('send', { hitType: 'event', eventCategory: 'Appliances', eventAction: 'Update Software' })
        this.updateSoftwareConfirm = false
        vuntangle.toast.add(this.$t('appliance_search_updates'))
        const response = await this.$store.dispatch('appliances/updateAppliances', {
          uids,
          paramOrder: 'uids',
        })

        // display model of any software that failed to update
        const failedUids = response.data === null ? null : response.data
        const displaySoftwareFailed = []
        if (failedUids) {
          const getByUid = this.$store.getters['appliances/getByUid']
          for (const uid in failedUids) {
            const appliance = getByUid(uid)
            displaySoftwareFailed.push({
              uid,
              hostname: appliance.Hostname ? util.formatHostname(appliance.Hostname) : '',
              tag: appliance.ApplianceTag,
              errMsg: response.data[uid],
            })
          }
        }
        this.updateSoftwareFailed = [...displaySoftwareFailed]
        await this.$router.push({ name: 'appliances' })
      },
      /**
       * Called if an appliance is added to the system from this page.  Routes to the newly-added appliance
       * @param uniqueIdentifier
       */
      onApplianceAdded(uniqueIdentifier) {
        this.$router.push({ name: 'appliances-id', params: { id: uniqueIdentifier } })
      },
      /**
       * Executed when user presses "Add Appliance" button
       */
      addAppliancePage() {
        window.ga('send', { hitType: 'event', eventCategory: 'Appliances', eventAction: 'Add Appliance' })
        this.$router.push({ name: 'appliances-add' })
      },
    },
  }
</script>
