import cloneDeep from 'lodash/cloneDeep'
import settingsMixin from './settingsMixin'
import appliance from '@/plugins/ut/ut-appliances'
import UtDeleteInterfaceDialog from '@/components/appliances/UtDeleteInterfaceDialog.vue'
import i18n from '@/plugins/vue-i18n'
import { mfwServices, ServiceName } from '@/util/mfwServices'

export default {
  mixins: [settingsMixin],
  computed: {
    // returns the path for the rules/settings from the box settings.json
    settingsPath: () => mfwServices[ServiceName.Interfaces].settingPathForBox,
    interfaces: ({ boxSettings }) => boxSettings?.network.interfaces || [],
  },
  methods: {
    /**
     * Removes the interface
     * - shows a confirm dialog
     *
     * @param {*} intf the interface to delete
     * @param {*} successCallback callback to be executed in case of success
     */
    async deleteInterfaceHandler(intf, successCallback = () => {}) {
      const showDialog = intf.wan === false || (intf.wan === true && !this.hasWanPolicy(intf.interfaceId))
      if (intf.type === 'VLAN' && showDialog) {
        this.$vuntangle.dialog.show({
          title: `${this.$t('delete_interface')}`,
          component: UtDeleteInterfaceDialog,
          width: 800,
          actionLabel: this.$t('yes'),
          cancelLabel: this.$t('no'),
          componentProps: {
            intf,
          },
          componentEvents: {
            update: successCallback,
          },
        })
      } else {
        const interfacesCopy = cloneDeep(this.interfaces)
        const index = interfacesCopy.findIndex(({ interfaceId }) => interfaceId === intf.interfaceId)
        interfacesCopy.splice(index, 1)
        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await appliance.sendToApplianceApi(this.uid, 'settings/network/interfaces', interfacesCopy)
        // check the response to display a confirm dialog
        if (response?.data?.error) {
          const matches = /^(.{0,11}CONFIRM):([\s\S]*?)$/gm.exec(response.data.error)
          if (matches?.[2]) {
            const result = await this.displayConfirm(this.uid, matches[2], interfacesCopy)
            if (!result) {
              // user clicked cancel
              this.$store.commit('SET_PAGE_LOADER', false)
              return
            }
          }
        }
        // deletion request went through
        await this.fetchSettings()
        successCallback()
        this.$store.commit('SET_PAGE_LOADER', false)
      }
    },

    /**
     * Display a confirmation dialog from a POST response from the API.  If 'confirm' is pressed the method will
     * send the API request again with a 'force: true' param.
     *
     * @param uid  the uid of the appliance
     * @param {string} confirmOutput  this is a string from the API response parsed from 'CONFIRM:', this will be JSON decoded
     * @param interfacesCopy
     * @param isDisabled - check if the interface in question is disabled
     */
    displayConfirm(uid, confirmOutput, interfacesCopy, isDisabled) {
      const decodeOutputAll = JSON.parse(confirmOutput)
      const message = `
        <p>${isDisabled ? `${i18n.t('appliance_interface_disabled')}` : ''}.
          ${i18n.t(decodeOutputAll[0].mainTranslationString, [
            decodeOutputAll[0].invalidReason === 'enabled' ? '' : decodeOutputAll[0].invalidReason,
          ])}
        </p>
        <ul>${decodeOutputAll
          .map(decodeOutput => {
            const uniqueValues = new Set()
            return decodeOutput.affectedValues
              .filter(item => {
                // Check if the combination of affectedType and affectedValue is unique
                const key = `${item.affectedType}:${item.affectedValue}`
                if (!uniqueValues.has(key)) {
                  uniqueValues.add(key)
                  return true // keep this item
                }
                return false // filter out duplicates
              })
              .map(item => {
                return `<li>${item.affectedType}: ${item.affectedValue}</li>`
              })
              .join('')
          })
          .join('')}</ul>`
      return new Promise(resolve => {
        // wait for confirmation
        this.$vuntangle.confirm.show({
          title: i18n.t('appliance_lose_connectivity'),
          message,
          width: 700,
          cancel() {
            this.onClose()
            resolve()
          },
          cancelLabel: i18n.t('cancel').toUpperCase(),
          async action() {
            // try sending the same request with 'force: true'
            let response
            try {
              response = await appliance.sendToApplianceApi(
                uid,
                'settings/network/interfaces?force=true',
                interfacesCopy,
              )
            } catch (ex) {
              response = ex.response
            }

            this.onClose()
            const result = response?.success
            if (!result) {
              // if the backend returned an error, use that, else use a default error message
              this.$vuntangle.toast.add(
                this.$t(response?.data?.error ? response.data.error : 'unable_communicate_refresh_browser'),
                'error',
              )
            }
            resolve({ result, force: true })
          },
          confirmLabel: i18n.t('ok').toUpperCase(),
        })
      })
    },

    /**
     * checks whether the intf is part of a wan policy
     *
     * @param interfaceId id of the interface to delete
     * @returns boolean
     */
    hasWanPolicy(interfaceId) {
      return (
        this.boxSettings.wan?.policies
          ?.map(policy => policy.interfaces)
          .flat()
          .filter(intf => intf.interfaceId === interfaceId).length > 0
      )
    },
  },
}
