<!--
 Component used to display readable conditions outside grid context
 e.g. When editing conditions
-->
<template>
  <span style="line-height: 1.8">
    <condition-items :items="sourceItems" with-prefix :break-and="false" target="SOURCE" :use-any="useAny" />
    <span v-if="sourceAnd" class="font-weight-bold text-uppercase">
      {{ $t('and') }}
    </span>
    <condition-items :items="destItems" with-prefix :break-and="false" target="DESTINATION" :use-any="useAny" />
    <span v-if="destAnd" class="font-weight-bold text-uppercase">
      {{ $t('and') }}
    </span>
    <condition-items :items="otherItems" with-prefix :break-and="false" target="OTHER" />
  </span>
</template>
<script>
  import { ConditionItems, ConditionTarget } from 'vuntangle/pm'
  import { conditionItemsValue } from '../valueGetters'

  export default {
    components: { ConditionItems },
    props: {
      items: { type: Array, default: () => [] },
      useAny: { type: Object, default: () => ({ source: true, dest: true }) },
    },
    computed: {
      sourceItems: ({ items }) => conditionItemsValue(items, ConditionTarget.Source),
      destItems: ({ items }) => conditionItemsValue(items, ConditionTarget.Destination),
      otherItems: ({ items }) => conditionItemsValue(items, ConditionTarget.Other),

      // whether to display `AND` after source
      sourceAnd: ({ sourceItems, destItems, otherItems, useAny }) =>
        (sourceItems.length || useAny.source) && (destItems.length || useAny.dest || otherItems.length),

      // whether to display `AND` before Other
      destAnd: ({ destItems, otherItems, useAny }) => (destItems.length || useAny.dest) && otherItems.length,
    },
  }
</script>
