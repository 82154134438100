<template>
  <div>
    <div>
      <span class="font-weight-bold">{{ $t('blocked_countries') }}:</span> {{ blockedCountries }}
    </div>
    <div class="mt-2">
      <span class="font-weight-bold">{{ $t('pass_networks') }}:</span> {{ passedNetworks }}
    </div>
    <div class="mt-2">
      <span class="font-weight-bold">{{ $t('log_blocked_sessions') }}:</span>
      {{ data.enabledLog ? $t('yes') : $t('no') }}
    </div>
  </div>
</template>
<script>
  import { countryCodes } from 'vuntangle/constants'

  export default {
    props: {
      data: { type: Object, default: () => {} },
    },

    computed: {
      blockedCountries: ({ data }) => {
        if (data.blockedCountries) {
          return data.blockedCountries.map(code => countryCodes[code]).join(', ')
        }
      },

      passedNetworks: ({ data }) => {
        if (data.passedNetworks) {
          return data.passedNetworks.map(network => `${network.address} (${network.description})`).join(', ')
        }
      },
    },
  }
</script>
