import axios from 'axios'

// if axios is sending calls to https, use the withCredentials flag
const addCredentials = config => {
  if (!/^https?:\/\//i.test(config.url) || config.url.indexOf(config.baseURL) === 0) {
    config.withCredentials = true
  }
}

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

http.interceptors.request.use(config => {
  addCredentials(config)

  return config
})

export default http
