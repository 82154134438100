<template>
  <div>
    <div class="font-weight-bold">{{ $t('categories') }}:</div>
    <span class="text-secondary">
      <span v-for="(stat, key) in categories" :key="key">
        {{ $t(key) }} ({{ stat.blocked }} {{ $t('blocked') }}, {{ stat.flagged }} {{ $t('flagged') }})<br />
      </span>
    </span>

    <div class="mt-2">
      <span class="font-weight-bold">{{ $t('block_sites') }}:</span> {{ blockSites || '-' }}
    </div>
    <div class="mt-2">
      <span class="font-weight-bold">{{ $t('pass_sites') }}:</span> {{ passSites || '-' }}
    </div>
  </div>
</template>
<script>
  import { webFilterCategoriesDefs } from 'vuntangle'

  export default {
    props: {
      data: { type: Object, default: () => {} },
    },

    computed: {
      categoriesMap: () => webFilterCategoriesDefs.reduce((acc, el) => ({ ...acc, [el.id]: el }), {}),
      categories: ({ data, categoriesMap }) => {
        const out = {}
        data.categories?.forEach(cat => {
          const cateDef = categoriesMap[cat.id]
          if (cat.enabled || cat.flagged) {
            if (!out[cateDef.category]) out[cateDef.category] = { blocked: 0, flagged: 0 }
          }
          if (cat.enabled) out[cateDef.category].blocked += 1
          if (cat.flagged) out[cateDef.category].flagged += 1
        })
        return out
      },

      blockSites: ({ data }) => data.blockList.map(list => list.name).join(', '),
      passSites: ({ data }) => data.passList.map(list => list.name).join(', '),
    },
  }
</script>
