import { set } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import api from '@/plugins/ut/ut-api'
import storeApi from '@/plugins/ut/ut-store-api'

const getDefaultState = () => {
  return {
    billingInfo: null,
    fetching: false,
    invoices: null,
    fetchingInvoices: false,
  }
}

const getters = {
  billingInfo: state => state.billingInfo,
  isOnTerms: state => state.billingInfo?.IsOnTerms || false,
  fetching: state => state.fetching,
  invoices: state => state.invoices,
}

const mutations = {
  RESET: state => Object.assign(state, getDefaultState()),

  SET_BILLING_INFO: (state, info) => (state.billingInfo = info),
  SET_FETCHING: (state, value) => (state.fetching = value),
  UPDATE_BILLING_INFO: (state, info) => {
    set(state, 'billingInfo', info)
  },

  /**
   * Used to partially update the billingInfo store will new balance info
   * like when redeeming vouchers, renew/upgrade subscriptions, etc)
   *
   * @param state
   * @param billingBalance.AccountBalance
   * @param billingBalance.CreditBalance
   * @param billingBalance.TermsCreditBalance
   *
   */
  UPDATE_BILLING_BALANCE: (state, billingBalance) => {
    const billingInfo = cloneDeep(state.billingInfo)
    billingInfo.AccountBalance = billingBalance.AccountBalance
    billingInfo.CreditBalance = billingBalance.CreditBalance
    billingInfo.TermsCreditBalance = billingBalance.TermsCreditBalance
    set(state, 'billingInfo', billingInfo)
  },
  SET_FETCHING_INVOICES: (state, value) => (state.fetchingInvoices = value),
  SET_INVOICES: (state, info) => (state.invoices = info),
}

const actions = {
  /**
   * Retrieves billingInfo data from back end.  This info is used in a lot of My Account sections.
   * Updates the store if fetch is successful
   * @param state
   * @param commit
   * @param options
   */
  async fetchBillingInfo({ state, commit }, options) {
    if (state.billingInfo !== null && !state.fetching && !options?.force) {
      return
    }
    commit('SET_FETCHING', true)
    const response = await api.cloud('Untangle_CommandCenter', 'GetBillingInfo')
    if (response.success && response.data) {
      commit('SET_BILLING_INFO', response.data)
    }
    commit('SET_FETCHING', false)
  },
  /**
   * Change default payment method.  Update state with
   * modified billingInfo
   * @param commit
   * @param paymentMethodId - id of new default payment method
   * @returns {Promise<void>}
   */
  async updateDefaultPaymentMethod({ commit }, paymentMethodId) {
    const response = await api.cloud('Untangle_CommandCenter', 'UpdateDefaultPaymentMethod', {
      paramOrder: 'newDefaultPaymentMethodId',
      newDefaultPaymentMethodId: paymentMethodId,
    })
    if (response.success && response.data) {
      commit('UPDATE_BILLING_INFO', response.data.BillingInfo)
    }
    return response
  },

  /**
   * deletes a credit card from the user's account
   * @param commit
   * @param paymentMethodId
   * @returns {Promise<unknown>}
   */
  async deletePaymentMethod({ commit }, paymentMethodId) {
    const response = await api.cloud('Untangle_CommandCenter', 'DeletePaymentMethod', {
      paramOrder: 'paymentMethodId',
      paymentMethodId,
    })
    if (response.success && response.data) {
      commit('UPDATE_BILLING_INFO', response.data.BillingInfo)
    }
    return response
  },

  /**
   * called to add a new credit card, or update an existing card
   * @param commit
   * @param ccInfo - object with all info needed
   * @returns {Promise<unknown>}
   */
  async saveCreditCard({ commit }, ccInfo) {
    const response = await api.cloud('Untangle_CommandCenter', 'SaveCreditCard', {
      paramOrder: 'paymentId ccType ccExpirationMonth ccExpirationYear ccName ccNumber ccSecurityId',
      paymentId: ccInfo.paymentId,
      ccType: ccInfo.ccType,
      ccExpirationMonth: ccInfo.ccExpirationMonth,
      ccExpirationYear: ccInfo.ccExpirationYear,
      ccName: ccInfo.ccName,
      ccNumber: ccInfo.ccNumber,
      ccSecurityId: ccInfo.ccSecurityId,
    })
    if (response.success && response.data) {
      commit('UPDATE_BILLING_INFO', response.data.BillingInfo)
    }
    return response
  },
  /**
   * called to add a new ach payment entry
   * @param commit
   * @param achInfo - object with fields needed
   * @returns {Promise<unknown>}
   */
  async addAchPayment({ commit }, achInfo) {
    const response = await api.cloud('Untangle_CommandCenter', 'UpdateAchPaymentMethod', {
      paramOrder: 'paymentId bankName accountName routingNumber accountNumber accountType',
      paymentId: achInfo.paymentId,
      bankName: achInfo.bankName,
      accountName: achInfo.accountName,
      routingNumber: achInfo.routingNumber,
      accountNumber: achInfo.accountNumber,
      accountType: achInfo.accountType,
    })
    if (response.success && response.data) {
      commit('UPDATE_BILLING_INFO', response.data.BillingInfo)
    }
    return response
  },
  /** pulls back invoice data for the logged in user
   * @param state
   * @param commit
   * @param options - pass in force: true if you wish to refresh this data
   * @returns {Promise<void>}
   */
  async getInvoices({ state, commit }, options) {
    if (state.invoices !== null && !state.fetchingInvoices && !options?.force) {
      return
    }
    commit('SET_FETCHING_INVOICES', true)
    const response = await storeApi.fromStoreApi('/api/v1/sp/GetInvoices')
    if (response.data) {
      commit('SET_INVOICES', response.data)
    }
    commit('SET_FETCHING_INVOICES', false)
  },
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions,
}
