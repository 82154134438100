<template>
  <div class="d-flex flex-column pa-4 fill-height align-stretch">
    <div class="d-flex align-center mb-2">
      <h1 class="headline">{{ $t('filter_groups') }}</h1>
      <v-spacer />
      <u-btn text :to="{ name: 'mfw-policies-types' }">
        <span :class="`${$vuetify.theme.dark ? 'white--text' : ''}`">
          {{ $t('back_to_list') }}
        </span>
      </u-btn>
      <u-btn :min-width="null" @click="onSave(newGroup)">{{ $t('save') }}</u-btn>
    </div>
    <u-section class="d-flex flex-column">
      <ValidationObserver ref="extraFields">
        <!-- name field -->
        <ValidationProvider v-slot="{ errors }" :rules="{ required: true, max: 100 }">
          <u-text-field
            v-model="filterGroup.Name"
            :placeholder="$t('name')"
            :error-messages="errors"
            class="flex-grow-1 pb-4"
          >
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-text-field>
        </ValidationProvider>
        <!-- description field -->
        <ValidationProvider v-slot="{ errors }" :rules="{ required: true, max: 100 }">
          <u-text-field
            v-model="filterGroup.Description"
            :placeholder="$t('description')"
            :error-messages="errors"
            class="flex-grow-1"
          >
            <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
          </u-text-field>
        </ValidationProvider>
      </ValidationObserver>
    </u-section>
    <div class="d-flex flex-row pt-2 fill-height">
      <u-section :title="$t('filter_groups_description')" class="d-flex flex-column mb-0 mr-2" style="flex: 1 1 0">
        <!-- Selection options -->
        <div class="d-flex align-center mb-9">
          <h4 class="font-weight-bold mb-2">{{ $t('select_filter_rules') }}</h4>
          <v-spacer />
          <u-btn
            class="mb-2"
            :disabled="!selectedFilterRules.length || !existingFilterRules.length"
            @click="onAddSelected"
          >
            {{ $t('add') }}
          </u-btn>
        </div>
        <u-grid
          id="mfw-policies-grid-filter_rules"
          selection-type="multiAction"
          :no-data-message="$t('no_data')"
          :column-defs="baseFilterRulesColumnDefs"
          :fetching="pendingAction"
          :row-data="existingFilterRules"
          :selection.sync="selectedFilterRules"
          :enable-export-csv="false"
          :enable-refresh="false"
          @refresh="fetchFilterRules"
        />
      </u-section>

      <u-section :title="$t('filter_groups_description')" class="d-flex flex-column mb-0 ml-2" style="flex: 1 1 0">
        <!-- Selection options -->
        <div class="d-flex align-center mb-2">
          <h4 class="font-weight-bold mb-2 mt-2">{{ $t('selected_filter_rules') }}</h4>
          <v-spacer />
          <u-btn :disabled="!selectedGroupRules.length || !groupRules.length" @click="onRemoveSelected">
            {{ $t('remove') }}
          </u-btn>
        </div>
        <h5 class="font-weight-regular mb-1">
          {{ $t('rules_order_description') }}
        </h5>
        <u-grid
          id="sync-rules-v2"
          row-node-id="Id"
          selection-type="multiAction"
          :enable-refresh="false"
          :fetching="pendingAction"
          :no-data-message="$t('no_data')"
          :custom-grid-options="{
            rowDragManaged: true,
            onRowDragEnd: updateRuleOrder,
            enableCellTextSelection: false,
          }"
          :column-defs="filterGroupRulesColumnDefs"
          :row-data="groupRules"
          :framework-components="frameworkComponents"
          :selection.sync="selectedGroupRules"
          :enable-export-csv="false"
        />
      </u-section>
    </div>
  </div>
</template>
<script>
  import checkboxCellRenderer from './checkboxCellRenderer.vue'
  import { mfwServices, savePolicy, getPolicy, TemplateType } from '@/util/mfwServices'
  import api from '@/plugins/ut/ut-api'
  import store from '@/store'
  import vuntangle from '@/plugins/vuntangle'
  export default {
    data() {
      return {
        pendingAction: false,
        existingFilterRules: [],
        groupRules: [],
        selectedGroupRules: [],
        defaultRules: [],
        filterGroup: {
          Name: '',
          Description: '',
        },
        frameworkComponents: {
          checkboxRenderer: checkboxCellRenderer,
        },
        selectedFilterRules: [],
        newGroup: [],
      }
    },
    computed: {
      // the policy page based on the route
      policyPage: ({ $route }) => $route.params.policyPage,
      type: ({ policyPage }) => mfwServices[policyPage].type,
      policyId: ({ $route }) => $route.params.id,

      // base columns when showing existing filter rules
      baseFilterRulesColumnDefs() {
        return [
          {
            headerName: this.$t('name'),
            field: 'Name',
          },

          {
            headerName: this.$t('description'),
            field: 'Description',
          },
          {
            headerName: this.$t('date_created'),
            field: 'DateCreated',
            valueFormatter: ({ value }) => this.$vuntangle.dates.formatDateFromApi(value),
            comparator: (a, b) => this.$vuntangle.dates.compareDates(a, b),
          },
        ]
      },

      // columns when showing the selected filter rules
      filterGroupRulesColumnDefs() {
        return [
          {
            headerName: this.$t('name'),
            field: 'Name',
            rowDrag: true,
            sortable: false,
          },
          {
            headerName: this.$t('description'),
            field: 'Description',
            flex: 3,
            sortable: false,
          },
          {
            headerName: this.$t('date_created'),
            field: 'DateCreated',
            valueFormatter: ({ value }) => this.$vuntangle.dates.formatDateFromApi(value),
            comparator: (a, b) => this.$vuntangle.dates.compareDates(a, b),
          },
        ]
      },
    },
    async created() {
      store.commit('SET_PAGE_LOADER', true)
      await this.fetchFilterRules()
      if (this.$route.params?.id !== 'add') {
        const response = await getPolicy(this.$route.params.id)
        this.filterGroup.Name = response.data.Name
        this.filterGroup.Description = response.data.Description
        const ruleIds = response.data.PolicyJson
        this.groupRules = this.populateGroupRules(ruleIds)
      }
      store.commit('SET_PAGE_LOADER', false)
    },
    methods: {
      async fetchFilterRules() {
        this.pendingAction = true

        const response = await api.cloud('Untangle_CommandCenter', 'GetPolicies', {
          type: TemplateType.TemplateRuleFilter,
          paramOrder: 'type',
        })

        if (response.success) {
          this.existingFilterRules = response.data
        }

        this.pendingAction = false
      },

      populateGroupRules(ids) {
        const list = []
        let rule
        for (const id of ids) {
          rule = this.existingFilterRules.filter(rule => rule.Id === id)
          list.push(rule[0])
          this.existingFilterRules = this.existingFilterRules.filter(rule => rule.Id !== id)
        }
        return list
      },
      async onSave(groupObj) {
        // validate name and description fields
        const valid = await this.$refs.extraFields.validate()
        if (!valid) {
          return
        }

        if (Object.keys(groupObj).includes('description')) {
          this.filterGroup.Description = groupObj.description
        }

        this.filterGroup.Type = this.type
        if (this.policyId !== 'add') {
          this.filterGroup.Id = this.policyId
        }

        const ruleIds = []
        this.groupRules.forEach(rule => {
          ruleIds.push(rule.Id)
        })
        this.filterGroup.PolicyJson = ruleIds

        // add or update policy in the backend
        store.commit('SET_PAGE_LOADER', true)
        const response = await savePolicy(this.filterGroup, this.filterGroup.Type)
        store.commit('SET_PAGE_LOADER', false)

        if (response.success && response.data) {
          vuntangle.toast.add(this.$t('saved_successfully', [this.$t('template')]))
          this.settings = groupObj
        } else {
          vuntangle.toast.add(this.$t('unable_to_save', [this.$t('template')]), 'error')
        }

        // get back to the correct policy listing page
        this.$router.push({ name: 'mfw-policies-types', params: { policyPage: this.policyPage } })
      },

      /**
       * Adds selected filter rules to group.
       */
      onAddSelected() {
        const userRules = this.selectedFilterRules
        const existingRules = []
        if (!userRules) return
        // rules list containing first user's rules
        this.groupRules = this.groupRules.concat(userRules)
        // filter rules list
        this.existingFilterRules.forEach(rule => {
          if (!this.selectedFilterRules.includes(rule)) {
            existingRules.push(rule)
          }
        })
        this.existingFilterRules = existingRules
        this.selectedFilterRules = []
      },
      /**
       * Removes selected filter rule from group.
       */
      onRemoveSelected() {
        const rules = []
        this.groupRules.forEach(rule => {
          if (!this.selectedGroupRules.includes(rule)) {
            rules.push(rule)
          } else {
            this.existingFilterRules.push(rule)
          }
        })
        this.groupRules = rules
        this.selectedGroupRules = []
      },

      /**
       * Update the order of the rules.
       */
      updateRuleOrder(gridEvent) {
        // loop through the nodes to update the ordering
        const orderedRules = []
        gridEvent.api.forEachNode(node => {
          orderedRules.push(node.data)
        })

        this.groupRules = orderedRules
        this.selectedGroupRules = []
      },
    },
  }
</script>
