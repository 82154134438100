import store from '@/store'
import i18n from '@/plugins/vue-i18n'

const selectionBar = {
  appliances: {
    list() {
      const appliances = store.getters['appliances/fullList']
      if (!appliances) return []

      const items = []
      appliances.forEach(appl => {
        const online = appl.StatusFlags && appl.StatusFlags[0]?.Messages[0] === 'appliance_status_online'
        const label = appl.ApplianceTag || i18n.t('label_not_assigned')
        const product = appl.ProductLine + ' ' + (appl.SoftwareVersion || i18n.t('unknown_version'))
        const ip = appl.IpAddress || i18n.t('unknown_ip')
        items.push({
          id: appl.UniqueIdentifier,
          to: { name: 'appliances-id', params: { id: appl.UniqueIdentifier } },
          firstText: appl.Hostname || i18n.t('unknown_hostname'),
          lastText: [label, product, ip],
          filterText: [
            appl.Hostname,
            appl.ApplianceTag,
            appl.IpAddress,
            appl.ProductLine,
            appl.SoftwareVersion,
            appl.ApplianceSerialNumber,
            appl.SerialNumber,
            appl.NetworkInfo?.split(':')[0],
            appl.ApplianceLocation,
            appl.Location,
            appl.Uid,
          ],
          icon: online
            ? { name: 'mdi-checkbox-blank-circle', color: 'rgba(76, 175, 80, 1)' }
            : { name: 'mdi-checkbox-blank-circle', color: 'rgba(244, 67, 54, 1)' },
        })
      })
      return items
    },
    // action button from selection toolbar
    actionButton() {
      return {
        label: i18n.t('add_appliance'),
        to: { name: 'appliances-add' },
        ga: { hitType: 'event', eventCategory: 'Appliances', eventAction: 'Add Appliance' },
        disabled: !store.getters['data/hasAddApplianceAccess'],
      }
    },
  },

  networks: {
    list() {
      const networks = store.state.networks.list
      if (!networks) return []

      const items = []
      networks.forEach(network => {
        let count = 0
        if (network.NetworkAppliances && Array.isArray(network.NetworkAppliances)) {
          count = network.NetworkAppliances.length
        }
        const lastText = count + ' ' + (count > 1 ? i18n.t('appliances') : i18n.t('appliance'))
        items.push({
          id: network.Id,
          firstText: network.Name,
          lastText: [lastText],
          filterText: [network.Name, ...network.Uids],
          to: { name: 'networks-network-id', params: { id: network.Id } },
        })
      })
      return items
    },
    actionButton() {
      return {
        label: i18n.t('create_network'),
        to: { name: 'networks-add' },
      }
    },
  },

  hosts: {
    list() {
      const hosts = store.state.hosts.list
      if (!hosts) return []

      const items = []
      hosts.forEach(host => {
        const ip = host.IP || i18n.t('unknown_ip')
        const mac = host.MacAddress || i18n.t('unknown_mac')
        const os = host.OS || i18n.t('unknown_os')
        items.push({
          id: host.UniqueId,
          firstText: host.HostName || i18n.t('unknown_hostname'),
          lastText: [ip, mac, os],
          filterText: [host.HostName, host.IP, host.MacAddress, host.OS],
          to: { name: 'hosts-id', params: { id: host.UniqueId } },
        })
      })
      return items
    },
  },
}

export default selectionBar
