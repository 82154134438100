<template>
  <reports :features="features" @view-report="onViewReport" />
</template>
<script>
  import { Reports } from 'vuntangle'

  export default {
    components: { Reports },

    computed: {
      appliance: ({ $store, $route }) => $store.getters['appliances/getByUniqueIdentifier']($route.params.id),
      features: ({ appliance }) => appliance.features,
    },

    methods: {
      /**
       * Redirects to the report details view
       * @param {String} viewId - one of the view ids as defined in vuntangle
       * @returns {undefined}
       */
      onViewReport(viewId) {
        this.$router.push({ name: 'appliances-report-details', params: { viewId } })
      },
    },
  }
</script>
