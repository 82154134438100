<!--
  Route Component editing a Condition Object
  The condition is expected in the form as any other common object has:
  {
    Id: '<guid>'
    Name: '',
    Description: '',
    Type: 'mfw-object-condition'
    PolicyJson:   {
      items: [
        { type: 'CLIENT_ADDRESS', op: '==', value: ['2.3.4.5', '3.4.5.6'] },
        { type: 'CLIENT_ADDRESS', op: 'in', object: ['b0d7c481-047f-4154-8656-ea48a90264a6', ...] },
      ]
    }
  }
  For equality ops is using `value` field
  For in/not_in ops is using `object`

  Note that the value and object can hold "multiple" values.
  This does not makes it an Object Group. This is still a single Object
  The `object` prop just holds guid references to other existing objects
-->
<template>
  <div v-if="condition" class="d-flex flex-column flex-grow-1 pa-0">
    <breadcrumbs>
      <template #actions>
        <v-btn depressed class="text-capitalize mr-2" color="transparent" @click="goBack">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          v-if="allowDelete"
          :small="false"
          color="error"
          data-testid="delete-pm-entity"
          class="text-capitalize mr-2"
          @click="onDeleteObject(condition)"
        >
          <v-icon small class="mr-2">mdi-delete</v-icon> {{ $t('delete') }}
        </v-btn>
        <v-btn depressed :disabled="!hasItems" class="text-capitalize" color="primary" @click="onSave">
          <v-icon small class="mr-2">mdi-content-save</v-icon>
          {{ $t('save') }}
        </v-btn>
      </template>
    </breadcrumbs>

    <ValidationObserver ref="obs">
      <!-- condition name and description -->
      <name-description
        :name.sync="condition.Name"
        :description.sync="condition.Description"
        :name-label="$t('condition_name')"
        :description-label="$t('condition_description')"
      />

      <v-sheet rounded outlined class="pa-2 my-4">
        <readable-condition-items :items="condition.PolicyJson.items" />
      </v-sheet>

      <edit-condition-object :condition-object.sync="condition" v-on="$listeners" />
    </ValidationObserver>
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { rulesConfig, allConditions } from 'vuntangle/pm'
  import NameDescription from '../components/NameDescription.vue'
  import EditConditionObject from '../components/EditConditionObject.vue'
  import ReadableConditionItems from '../components/ReadableConditionItems.vue'
  import Breadcrumbs from './Breadcrumbs.vue'
  import editorMixin from './editorMixin'
  import i18n from '@/plugins/vue-i18n'

  export default {
    components: { Breadcrumbs, NameDescription, EditConditionObject, ReadableConditionItems },
    mixins: [editorMixin],

    provide() {
      return {
        $ruleType: () => undefined,
      }
    },

    beforeRouteEnter(to, from, next) {
      // if the condition isn't found, return back to the condition list
      next(({ $store, $vuntangle, $router }) => {
        const conditionId = to.params.conditionId
        const foundConObj = $store.getters['policyManager/getEditObjectById'](conditionId)
        if (!foundConObj) {
          $vuntangle.toast.add(i18n.t('no_record_found'), 'error')
          $router.push({ name: 'pm-objects-condition-list' })
        }
      })
    },

    data() {
      return {
        condition: undefined,
        // new condition item helper
        newConditionItem: { type: '' },
      }
    },

    computed: {
      // rule configuration based on condition type
      config: ({ condition }) => rulesConfig[condition.Type],
      // the conditions which are available for this rule type based on it's config
      conditionTypes: ({ config }) => config?.conditions || allConditions,
      /**
       * flag to determine if delete operation is allowed
       * only allowed if we are editing a condition directly,
       * and not when viewing a condition which is attached to a rule / policy
       */
      allowDelete: ({ conditionId, ruleId, policyId }) => !conditionId.startsWith('mfw-') && !policyId && !ruleId,

      /**
       * boolean indicating if the condition object has at least a condition item
       * for an empty one the PolicyJson looks like:
       * {..., "PolicyJson": { "items": [ { "type": "" } ] } }
       * so it checks against `type` to be set
       */
      hasItems: ({ condition }) => condition.PolicyJson.items.some(item => !!item.type),
    },

    watch: {
      condition: {
        handler(condition) {
          if (!condition) return
          /** edits made to the condition gets committed into the store to be available between routes */
          this.$store.commit('policyManager/SET_EDIT_OBJECT', condition)
        },
        deep: true,
        immediate: true,
      },

      newConditionItem: {
        /**
         * Adds condition item to object when `type` is set
         * @param {Object} value - the condition item
         */
        handler(value) {
          if (value.type) {
            this.condition.PolicyJson.items.push(value)
            // then resets the value of new condition
            this.newConditionItem = { type: '' }
          }
        },
        deep: true,
        immediate: true,
      },
    },

    mounted() {
      // populates the condition with temporary `editCondition` object (see editorMixin)
      this.condition = cloneDeep(this.editCondition)
    },

    methods: {
      /**
       * event handler from the conditions that require applications as values
       * e.g. APPLICATION_NAME or APPLICATION_CATEGORY
       * @param {Function} cb - the callback when done
       */
      async onGetApplications(cb) {
        await this.$store.dispatch('data/fetchApplications')
        const apps = this.$store.getters['data/applications']
        cb(apps)
      },

      /**
       * event handler from the conditions that require objects as values
       * @param {String} conditionConfig - condition config having the `objectType` & `objectGroupType`
       * @param {Function} cb - the callback when done
       */
      async onGetObjectsAndGroups(conditionConfig, cb) {
        const objectType = conditionConfig?.objectType
        const objectGroupType = conditionConfig?.objectGroupType

        const fns = []
        if (objectType) {
          fns.push(this.$store.dispatch('policyManager/fetchObjectsByType', { type: objectType }))
        }
        if (objectGroupType) {
          fns.push(this.$store.dispatch('policyManager/fetchObjectsByType', { type: objectGroupType }))
        }

        await Promise.allSettled(fns)

        const objects = this.$store.getters['policyManager/getObjectsByType'](objectType) || []
        const objectGroups = this.$store.getters['policyManager/getObjectsByType'](objectGroupType) || []

        // callback with data as needed to be used in Selector component {text, value}
        cb(objects, objectGroups)
      },

      /**
       * removes a condition item from condition
       * @param {Number} index - the condition item index to be removed
       */
      onDeleteConditionItem(index) {
        this.condition.PolicyJson.items.splice(index, 1)
      },

      /**
       * When new objects/groups are added to the field, validation has to be triggered
       */
      async onValidate() {
        return await this.$refs.obs.validate()
      },

      /**
       * Dispatch action to save the edited rule
       */
      async onSave() {
        const isValid = await this.onValidate()
        if (!isValid) return

        if (this.condition.Id.startsWith('mfw-')) this.condition.Id = ''

        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await this.$store.dispatch('policyManager/saveObject', { object: this.condition })
        this.$store.commit('SET_PAGE_LOADER', false)

        if (response) {
          this.$vuntangle.toast.add(this.$t('saved_successfully', [this.$t('condition')]))
          this.goBack()
        } else {
          this.$vuntangle.toast.add(this.$t('unable_to_save', [this.$t('condition')]), 'error')
        }
      },
    },
  }
</script>
