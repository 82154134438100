<template>
  <appliance-system
    v-if="appliance"
    :box-settings="boxSettings"
    :support-access-enabled="appliance.supportAccessEnabled === true"
    :show-refresh-settings="true"
    :show-remote-support="true"
    @save-system="onSaveSystem"
    @save-web-ports="onSaveWebPorts"
    @save-credentials="onSaveCredentials"
    @export-backup="onExportBackup"
    @restore-backup="onRestoreBackup"
    @factory-reset="onFactoryReset"
    @reboot="onReboot"
    @save-remote-support="onSaveRemoteSupport"
    @refresh-settings="onRefreshSettings"
  />
</template>
<script>
  import { ApplianceSystem } from 'vuntangle'
  import settingsMixin from './settingsMixin'
  import appliances from '@/plugins/ut/ut-appliances'
  import api from '@/plugins/ut/ut-api'
  import util from '@/plugins/ut/ut-util'

  export default {
    components: { ApplianceSystem },

    mixins: [settingsMixin],

    methods: {
      /**
       * Saves system settings: `hostName`, `domainName` or `timeZone`
       * using dedicated cloud methods
       * @param {Object} system - system settings to be saved
       * @returns {undefined}
       */
      async onSaveSystem(system) {
        // base check that values are defined
        if (!system?.hostName || !system?.domainName || !system?.timeZone) {
          return
        }

        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await api.cloud('Untangle_CommandCenter', 'UpdateSystemSettings', {
          uid: this.uid,
          hostName: system.hostName,
          domainName: system.domainName,
          timezoneName: system.timeZone.displayName,
          timezoneValue: system.timeZone.value,
          paramOrder: 'uid hostName domainName timezoneName timezoneValue',
        })
        this.$store.commit('SET_PAGE_LOADER', false)

        response.success && response.data.success
          ? this.$vuntangle.toast.add(this.$t('settings_updated'))
          : this.$vuntangle.toast.add(this.$t('settings_update_failed'), 'error')
      },

      /**
       * Saves system settings: `httpPort`, `httpsPort` directly into appliance settings.json
       *
       * @param {Object} data
       * @param {Object} data.system - system settings to be saved
       * @param {Function} data.cb - callback for vuntangle confirm dialog
       *
       * @returns {undefined}
       */
      async onSaveWebPorts({ system, cb }) {
        const response = await appliances.sendToApplianceApi(this.uid, 'settings/system', system)
        if (response.success) {
          await this.fetchSettings()
        }
        cb(response.success)
      },

      /**
       * Saves the new "admin" password on the appliance using `saveSettings` from mixin
       * The admin password is passed as clear text, being hashed on the backend side
       * @param {Array<Object>} credentials - array of all appliance acounts including the "admin" having the new password
       * @returns {undefined}
       */
      onSaveCredentials(credentials) {
        this.saveSettings('accounts/credentials', credentials)
      },

      /**
       * Use cloud backup service to export a new backup and save it on disk
       */
      async onExportBackup() {
        this.$store.commit('SET_PAGE_LOADER', true)
        // create backup into cloud
        const createResponse = await api.cloud('Untangle_CommandCenter', 'CreateApplianceBackup', {
          uid: this.uid,
          paramOrder: 'uid',
        })

        if (createResponse.success && createResponse.data) {
          // fetch backups after creation
          const fetchResponse = await api.cloud('Untangle_CommandCenter', 'GetApplianceBackupListing', {
            uid: this.uid,
            paramOrder: 'uid',
          })

          if (fetchResponse.success && fetchResponse.data) {
            const backups = fetchResponse.data
            // sort backups descending by Timestamp
            backups.sort((a, b) => b.Timestamp - a.Timestamp)
            const lastBackup = backups[0]

            // create download link that will trigger save to disk instead of using `window.open` which might be blocked
            const link = document.createElement('a')
            link.id = 'backupLink'
            link.href = util.mapBackupUrl(lastBackup.Link)
            link.target = '_blank'
            document.getElementsByTagName('body')[0].appendChild(link)
            link.click()
            // remove link after download
            document.getElementById('backupLink').remove()
          } else {
            this.$vuntangle.toast.add(fetchResponse.message || this.$vuntangle.$t('export_failed'), 'error')
          }
        } else {
          this.$vuntangle.toast.add(this.$vuntangle.$t('export_failed'), 'error')
        }
        this.$store.commit('SET_PAGE_LOADER', false)
      },

      /**
       * Handler for restoring a backup file
       * restoreData holds an object of the form
       * {
       *   exceptions: ["accounts", "network"] // to NOT restore `accounts` and `network`
       *   file: "data:application/gzip;base64,....."
       * }
       * @param {Object} obj
       * @param {Object} obj.restoreData - data to be restored and exceptions if any
       * @param {Function} obj.cb - callback needed for the restore dialog after pushing restore data, sending the success status
       */
      async onRestoreBackup({ restoreData, cb }) {
        const response = await appliances.sendToApplianceApi(this.uid, 'settings-restore', restoreData)
        // refresh settings if success
        if (response.success) {
          await this.fetchSettings()
        }
        // tell vuntangle dialog component it's done
        cb(response.success)
      },

      /**
       * Handler for factory reset; upon success redirects to appliances listing
       * @param {Function} cb - tells vuntangle dialog it's done
       */
      async onFactoryReset(cb) {
        const response = await appliances.sendToApplianceApi(this.uid, 'factory-reset')
        if (response.success) {
          this.$router.push({ name: 'appliances' })
        }
        cb(response.success)
      },

      /**
       * Handler to reboot appliance, using same endpoint as in generic appliance actions (not the box endpoint)
       * @param {Function} cb - callback for vuntangle confirmation dialog
       */
      async onReboot(cb) {
        // using `rebootNow` type for an instant reboot
        const rebootType = 'rebootNow'

        const response = await api.cloud('Untangle_CommandCenter', 'RebootAppliance', {
          uid: this.uid,
          rebootType,
          rebootDate: null,
          rebootTime: null,
          paramOrder: 'uid rebootType rebootDate rebootTime',
        })

        if (response.success && response.data) {
          // redirect to appliances
          this.$router.push({ name: 'appliances' })
        }

        // tell vuntangle dialog is done
        cb(response.success)
      },

      /**
       * Handle Show Support action
       */
      async onSaveRemoteSupport(remoteSupport) {
        this.$store.commit('SET_PAGE_LOADER', true)

        // call backend to sync the settings to cmd
        const response = await api.cloud('Untangle_CommandCenter', 'SetRemoteSupport', {
          uid: this.uid,
          remoteSupport,
          paramOrder: 'uid remoteSupport',
        })

        this.$store.commit('SET_PAGE_LOADER', false)

        if (response.success && response.data) {
          this.$vuntangle.toast.add(this.$vuntangle.$t('toggle_support_success'))
        } else {
          this.$vuntangle.toast.add(this.$vuntangle.$t('toggle_support_failure'), 'error')
        }
      },

      /**
       * Handler to refresh settings from settings.json
       */
      async onRefreshSettings() {
        this.$store.commit('SET_PAGE_LOADER', true)

        // re-fetch settings in the appliance store
        await this.$store.dispatch('appliances/fetchApplianceSettings', this.appliance)

        // call backend to sync the settings to cloud
        const response = await api.cloud('Untangle_CommandCenter', 'RefreshApplianceSettings', {
          uid: this.uid,
          paramOrder: 'uid',
        })

        this.$store.commit('SET_PAGE_LOADER', false)

        if (response.success && !response.message) {
          this.$vuntangle.toast.add(this.$vuntangle.$t('refresh_settings_success'))
        } else {
          this.$vuntangle.toast.add(this.$vuntangle.$t('refresh_settings_failure'), 'error')
        }
      },
    },
  }
</script>
