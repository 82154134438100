import store from '@/store'
import { mfwServices, getDefaultPolicyPage, getPolicyRouteRegex, ComponentType } from '@/util/mfwServices'
import { breadCrumbBuilder } from '@/util/navigation'

import {
  SyncRule,
  AddEditRule,
  AddEditMfwPolicies,
  MfwTemplates,
  AddEditFilterGroups,
} from '@/pages/policies/mfw/templates'

// regex of mfw policies types and rule types. All has them both (they all have the main grid component).
const mfwPolicyTypes = getPolicyRouteRegex(ComponentType.Service)
const mfwRuleTypes = getPolicyRouteRegex(ComponentType.Rule)
const filterGroup = getPolicyRouteRegex(ComponentType.Group)

// slice the trailing ) from mfwPolicyTypes and the leading ( from mfwRuleTypes
const allTypes = mfwPolicyTypes.slice(0, -1) + '|' + mfwRuleTypes.slice(1, -1) + '|' + filterGroup.slice(1)
export default [
  {
    path: '',
    name: 'mfw-templates',
    // the base route is a redirect to the route they left off on in this section
    redirect: () => (store.state.mfwTemplatesSelection ? store.state.mfwTemplatesSelection : getDefaultPolicyPage()),
  },
  {
    path: `:policyPage${allTypes}/sync`,
    name: 'mfw-policies-types-rule-sync',
    component: SyncRule,
    // set the 'type' on the path as a prop to the PoliciesMixin, this converts the policy route to policy type
    // props: route => ({ type: appliances.mfwPolicyPageToType[route.params.policyPage] }),
    meta: {
      breadCrumb(route) {
        return breadCrumbBuilder([
          [
            [
              'policies_action_policy_page',
              {
                'action': 'sync',
                'policy-page': route.params.policyPage.replace('-', '_'),
              },
            ],
            { name: 'mfw-policies-types-rule-sync' },
          ],
        ]).breadCrumb
      },
    },
  },
  {
    path: `:policyPage${filterGroup}/:id`,
    name: 'mfw-filter-groups',
    component: AddEditFilterGroups,
    // set the 'type' on the path as a prop to the PoliciesMixin, this converts the policy route to policy type
    // props: route => ({ type: appliances.mfwPolicyPageToType[route.params.policyPage] }),
    meta: {
      breadCrumb(route) {
        return breadCrumbBuilder([
          [
            [
              'policies_action_policy_page',
              {
                'action': route.params.id === 'add' ? 'create' : 'edit',
                'policy-page': route.params.policyPage.replace('-', '_'),
              },
            ],
            { name: 'mfw-filter-groups' },
          ],
        ]).breadCrumb
      },
    },
  },
  {
    path: `:policyPage${mfwRuleTypes}/:id`,
    name: 'mfw-policies-types-rule',
    component: AddEditRule,
    // set the 'type' on the path as a prop to the PoliciesMixin, this converts the policy route to policy type
    // props: route => ({ type: appliances.mfwPolicyPageToType[route.params.policyPage] }),
    meta: {
      breadCrumb(route) {
        return breadCrumbBuilder([
          [
            [
              'policies_action_policy_page',
              {
                'action': route.params.id === 'add' ? 'create' : 'edit',
                'policy-page': route.params.policyPage,
              },
            ],
            { name: 'mfw-policies-types-rule' },
          ],
        ]).breadCrumb
      },
    },
  },
  {
    path: `:policyPage${mfwPolicyTypes}/:id`,
    name: 'mfw-policies-types-details',
    component: AddEditMfwPolicies,
    meta: {
      breadCrumb(route) {
        return breadCrumbBuilder([
          [
            [
              'policies_action_policy_page',
              {
                'action': route.params.id === 'add' ? 'create' : 'edit',
                'policy-page': route.params.policyPage.replace(/-/g, '_'),
              },
            ],
            { name: 'mfw-policies-types-details' },
          ],
        ]).breadCrumb
      },
    },
  },
  {
    path: `:policyPage${allTypes}`,
    name: 'mfw-policies-types',
    component: MfwTemplates,
    // set the 'type' on the path as a prop to the PoliciesMixin, this converts the policy route to policy type
    props: route => ({ type: mfwServices[route.params.policyPage].type }),
    meta: {
      breadCrumb(route) {
        return breadCrumbBuilder([[route.params.policyPage.replace(/-/g, '_'), { name: 'mfw-policies-types' }]])
          .breadCrumb
      },
    },
  },
]
