<template>
  <u-widget :title="$t('top_hosts_last_30')" @refresh="getHostsData" v-on="$listeners">
    <div class="flex-grow-1" style="position: relative">
      <u-chart-stock :fetching="fetching" :options="options" />
    </div>
  </u-widget>
</template>
<script>
  import WidgetMixin from '@/components/widgets/WidgetMixin'
  import reports from '@/plugins/ut/ut-reports'
  import chartOptions from '@/util/chartOptions'

  export default {
    mixins: [WidgetMixin],
    props: {
      uids: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        fetching: true,
        options: chartOptions.getOptions('pie', 'getBytesTooltipPieChartTopHosts'),
      }
    },
    watch: {
      uids: {
        immediate: true,
        // NOTE - this needs logic for single UID filled in
        handler() {
          this.getHostsData()
        },
      },
    },
    methods: {
      async getHostsData() {
        const data = await reports.getDashboardPieChart('TopHosts', this.uids)
        if (data) {
          this.options.series = data
        }
        this.fetching = false
      },
    },
  }
</script>
