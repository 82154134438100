<template>
  <u-widget
    :title="$t('information')"
    style="overflow: hidden"
    :enable-refresh="false"
    :enable-expand="false"
    :fetching="fetching"
    v-on="$listeners"
  >
    <div v-if="accountStatus">
      <div class="text-center mt-2">
        <strong>{{ $t('logged_in_as') }}</strong> {{ accountStatus.user }}
      </div>
      <div v-if="userExtraInfo" class="text-center mt-2 mb-2">
        <strong>{{ $t('account_owner') }}</strong> {{ userExtraInfo.owner }}
      </div>
      <div v-if="appliancesCount.total === 0" class="red ma-2 white--text">
        <div class="text-center py-1">
          {{ $t('widgets_no_appliances') }}
        </div>
      </div>
      <div v-else-if="appliancesCount.total === appliancesCount.online" class="aristaMediumBlue ma-2 white--text">
        <div class="text-center py-1">
          {{ $t('all_online') }}
        </div>
      </div>
      <div v-else-if="appliancesCount.online === 0" class="red ma-2 white--text">
        <div class="text-center py-1">
          {{ $t('all_offline') }}
        </div>
      </div>
      <div v-else>
        <div class="text-center ma-2 white--text">
          <v-row no-gutters>
            <v-col class="text-uppercase py-1 aristaMediumBlue">
              {{ appliancesCount.online }} {{ $t('appliances') }} {{ $t('online') }}
            </v-col>
            <v-col class="text-uppercase py-1 red">
              {{ appliancesCount.total - appliancesCount.online }} {{ $t('appliances') }}
              {{ $t('offline') }}
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="text-center">
        <div>
          {{ $t('appliances2') }} <b> {{ appliancesCount.total }}</b>
          <br />
          {{ $t('active_hosts') }} <b> {{ hostCount }}</b>
          <br />
        </div>
        <div>
          {{ $t('threats_blocked') }} <b>{{ accountStatus.threatsBlocked }}</b>
          <br />
        </div>
        <div>
          {{ $t('logged_in_from') }} <b>{{ accountStatus.loggedInFrom }}</b>
        </div>
        <div>
          {{ $t('location') }}
          <b>{{ $options.util.formatGeoLocation(accountStatus.loggedInFromLocation) }}</b>
        </div>
      </div>
    </div>
  </u-widget>
</template>
<script>
  import { mapGetters } from 'vuex'
  import util from '@/plugins/ut/ut-util'

  export default {
    props: {
      accountStatus: {
        type: Object,
        required: true,
      },
      fetching: {
        type: Boolean,
        required: false,
      },
    },
    util,
    computed: {
      ...mapGetters({
        ccvm: 'data/ccViewModel',
        appliances: 'appliances/list',
      }),
      userExtraInfo() {
        if (this.ccvm.User?.UserAccounts?.length > 1) {
          return {
            role: this.ccvm.SelectedUserAccountSettings.Role,
            owner: this.ccvm.SelectedUserAccountSettings.AccountDisplayName,
          }
        }
        return null
      },
      appliancesCount() {
        const total = this.appliances?.length
        const online = this.appliances?.filter(appl => appl.uptime > 0).length
        return {
          total,
          online,
          offline: total - online,
        }
      },
      hostCount() {
        let count = 0
        this.appliances?.forEach(appl => {
          if (appl.HostCount && appl.HostCount > 0) count += parseInt(appl.HostCount, 10)
        })
        return count
      },
    },
  }
</script>
