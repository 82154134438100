<!--
  Route Component editing an Object or Object Group
-->
<template>
  <v-container v-if="object" fluid class="d-flex flex-column align-stretch flex-grow-1 pa-0">
    <breadcrumbs>
      <template v-if="allowDelete" #actions>
        <u-btn
          :small="false"
          data-testid="delete-pm-entity"
          color="error"
          class="text-capitalize"
          @click="onDeleteObject(object)"
        >
          <v-icon small class="mr-2">mdi-delete</v-icon> {{ $t('delete') }}
        </u-btn>
      </template>
    </breadcrumbs>

    <ValidationObserver ref="obs" tag="div" class="d-flex flex-column flex-grow-1">
      <!-- using the PolicyNameDescription component to edit it's Name/Description -->
      <name-description
        :name.sync="object.Name"
        :description.sync="object.Description"
        class="mb-4"
        :name-label="$t(isObjectGroup ? 'group_name' : 'object_name')"
        :description-label="$t(isObjectGroup ? 'group_description' : 'object_description')"
      />

      <!-- use predefined components to edit object values based on it's type -->
      <component :is="component" v-if="component" :type="object.Type" :settings="object.PolicyJson">
        <!-- also making use of the action slot to save the object new/updated values -->
        <template #actions="{ newSettings, disabled }">
          <div class="d-flex flex-row justify-left my-2">
            <v-btn text class="text-capitalize mr-2" @click="goBack">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn :disabled="disabled" depressed class="text-capitalize" color="primary" @click="onSave(newSettings)">
              <v-icon small class="mr-2">mdi-content-save</v-icon>
              {{ $t('save') }}
            </v-btn>
          </div>
        </template>
      </component>
    </ValidationObserver>
  </v-container>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { objectsConfig } from 'vuntangle/pm'

  import {
    ObjectApplication,
    ObjectGeoip,
    ObjectHostname,
    ObjectInterfaceZone,
    ObjectIpAddress,
    ObjectService,
    ObjectUser,
    ObjectVlanTag,
    GroupsEdit,
  } from '../objects/'
  import NameDescription from '../components/NameDescription.vue'
  import Breadcrumbs from './Breadcrumbs.vue'
  import editorMixin from './editorMixin'
  import i18n from '@/plugins/vue-i18n'

  export default {
    // for each object type will use dedicated component
    components: {
      ObjectApplication,
      ObjectGeoip,
      ObjectHostname,
      ObjectInterfaceZone,
      ObjectIpAddress,
      ObjectService,
      ObjectUser,
      ObjectVlanTag,
      GroupsEdit,
      NameDescription,
      Breadcrumbs,
    },
    mixins: [editorMixin],

    beforeRouteEnter(to, from, next) {
      // if the object isn't found, return back to the object list
      next(({ $store, $vuntangle, $router }) => {
        const objectType = to.params.objectType
        const objectId = to.params.objectId
        const foundObj = $store.getters['policyManager/getEditObjectById'](objectId)
        if (!foundObj) {
          $vuntangle.toast.add(i18n.t('no_record_found'), 'error')
          $router.push({ name: 'pm-objects-list', params: { objectType } })
        }
      })
    },

    data() {
      return {
        object: undefined,
      }
    },

    computed: {
      /** the object configuration based on Type as defined in objectsConfig */
      config: ({ object }) => objectsConfig[object?.Type],
      /** the component name used to edit the object value, as defined in the above config */
      component: ({ config }) => config?.editComponent,
      /** boolean telling if the object is a group */
      isObjectGroup: ({ object }) => object.Type.includes('group'),
      /**
       * flag to determine if delete operation is allowed
       * only allowed if we are editing an object directly,
       * and not when viewing an object / group which is attached to a rule / policy / condition
       */
      allowDelete: ({ objectId, conditionId, ruleId, policyId }) =>
        !objectId.startsWith('mfw-') && !conditionId && !ruleId && !policyId,
    },

    watch: {
      editObject: {
        handler(editObject) {
          if (!editObject) return
          // populates the object with temporary `editObject` (see _editorsMixin)
          this.object = cloneDeep(this.editObject)
        },
        deep: true,
        immediate: true,
      },
      config: {
        immediate: true,
        handler(config) {
          // make sure this object is not read only, if so return them to the previous page
          if (config?.readOnly) {
            this.goBack()
          }
        },
      },
    },

    mounted() {
      // populates the object with temporary `editObject` (see editorMixin)
      this.object = cloneDeep(this.editObject)
    },

    methods: {
      /**
       * The `settings` are passed via slot params (see template section above)
       * @param {Object} settings - the edited settings to be stored in PolicyJson upon save
       */
      async onSave(settings) {
        const isValid = await this.$refs.obs.validate()
        if (!isValid) return

        if (this.object.Id.startsWith('mfw-')) this.object.Id = ''
        // update items with values coming from the editing component
        this.object.PolicyJson.items = settings.items

        // show loader and dispatch the action to save the object
        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await this.$store.dispatch('policyManager/saveObject', { object: this.object })
        this.$store.commit('SET_PAGE_LOADER', false)

        const itemType = this.$t(this.isObjectGroup ? 'group' : 'object')
        if (response) {
          this.$vuntangle.toast.add(this.$t('saved_successfully', [itemType]))

          this.goBack()
        } else {
          this.$vuntangle.toast.add(this.$t('unable_to_save', [itemType]), 'error')
        }
      },
    },
  }
</script>
