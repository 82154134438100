// breadcrumb builder
export function breadCrumbBuilder(crumbs) {
  return {
    breadCrumb: crumbs.map(crumb => {
      return {
        text: crumb[0],
        to: crumb[1],
      }
    }),
  }
}
