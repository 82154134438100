<template>
  <value-object v-if="useObjects" v-on="$listeners" />
  <value-text v-else :rules="rules" placeholder="list_of_ips_cidrs" multiple v-on="$listeners" />
</template>
<script>
  import { OperatorType } from 'vuntangle/pm'
  import { ValueObject, ValueText } from '../value'

  export default {
    components: { ValueObject, ValueText },
    inject: ['$condition'],
    computed: {
      condition: ({ $condition }) => $condition(),
      useObjects: ({ condition }) =>
        [OperatorType.Match, OperatorType.NotMatch, OperatorType.In, OperatorType.NotIn].includes(condition.op),
      rules: () => 'required|ip_any_expression_cidr_list',
    },
  }
</script>
