<template>
  <div>
    <v-file-input outlined dense :label="$vuntangle.$t('choose_policy_file')" hide-details @change="fileHandler">
    </v-file-input>
    <div v-if="summary" class="mt-4">
      <div v-for="item in summary" :key="item.text">
        <strong>{{ $t(item.text) }}:</strong> {{ item.value }}
      </div>
    </div>
    <u-alert v-else-if="importError" class="mt-4" error>
      {{ $t(importError) }}
    </u-alert>
  </div>
</template>
<script>
  import { VFileInput } from 'vuetify/lib'

  export default {
    components: { VFileInput },
    data() {
      return {
        // imported policy read as text from JSON file
        importedPolicy: undefined,
        // parsed policy JSON
        importedPolicyJson: undefined,
        // any import error strings
        importError: undefined,
      }
    },

    computed: {
      summary: ({ importedPolicyJson }) => {
        if (!importedPolicyJson) return
        return [
          { text: 'policy_name', value: importedPolicyJson.policies[0]?.name },
          { text: 'policy_conditions', value: importedPolicyJson.policies[0]?.conditions.length },
          { text: 'total_conditions', value: importedPolicyJson.conditions.length },
          { text: 'rules', value: importedPolicyJson.rules.length },
          { text: 'configurations', value: importedPolicyJson.configurations.length },
          { text: 'objects', value: importedPolicyJson.objects.length },
          { text: 'object_groups', value: importedPolicyJson.object_groups.length },
        ]
      },
    },

    watch: {
      importedPolicy: {
        /**
         * process the imported file, if exists
         */
        handler(importedPolicy) {
          // disable dialog action
          this.$emit('action-enabled', false)

          if (!importedPolicy) return

          // extract/parse JSON from the file content, otherwise throw exception/error
          try {
            this.importedPolicyJson = JSON.parse(importedPolicy)
          } catch (ex) {
            this.importError = 'invalid_policy_settings'
          }
          if (!this.importedPolicyJson) return

          // do a sanity check to make sure the JSON object represents a valid policy
          if (
            !this.importedPolicyJson.policies ||
            !this.importedPolicyJson.conditions ||
            !this.importedPolicyJson.rules ||
            !this.importedPolicyJson.configurations ||
            !this.importedPolicyJson.objects ||
            !this.importedPolicyJson.object_groups
          ) {
            this.importError = 'invalid_policy_settings'
          }

          // if policy data ok and no errors, enable the dialog import action
          if (this.importedPolicyJson && !this.importError) {
            this.$emit('action-enabled', true)
          }
        },
      },
      immediate: true,
    },

    methods: {
      /**
       * Reads the file contents from file input field
       * @param {Object} file - the File object
       */
      fileHandler(file) {
        // reset the data()
        this.importedPolicy = undefined
        this.importedPolicyJson = undefined
        this.importError = undefined

        if (!file) return

        // file must be of type JSON (.json file)
        if (file.type !== 'application/json') {
          this.importError = 'invalid_policy_file'
          return
        }

        // file must be less than 2M
        if (file.size > 1024 * 2000) {
          this.importError = 'invalid_policy_file_too_large'
          return
        }

        // use FileReader to read the content as text
        const reader = new FileReader()
        reader.onload = function (e) {
          this.importedPolicy = e.target.result
        }.bind(this)
        reader.readAsText(file)
      },

      /**
       * The import dialog action calling the import endpoint
       */
      async action() {
        // show dialog progress
        this.$emit('progress-show')

        const importPolicy = await this.$store.dispatch('policyManager/importPolicy', {
          policyJson: JSON.stringify(this.importedPolicyJson),
        })

        if (importPolicy === null) {
          this.$vuntangle.toast.add(this.$t('policy_import_failure'), 'error')
        } else {
          this.$vuntangle.toast.add(this.$t('policy_import_success'))
        }

        // close dialog
        this.$emit('close')
      },
    },
  }
</script>
