/**
 * This plugin is abstraction layer for all reports.  It has
 * base method to retrieve report data and additional utility
 * methods to retrieve and populate specific reports
 */
import api from '@/plugins/ut/ut-api'
import store from '@/store'
import util from '@/plugins/ut/ut-util'
import vuntangle from '@/plugins/vuntangle'

const reports = {
  /**
   * Gets a simple key/value report response from the server.  This will be
   * formatted as:  [{name, y},{name,y}] so it can be easily passed to line charts.
   *
   * @param {string}   reportName  report name sent to the server
   * @param {string[]} uids        array of UIDs to get report data for
   * @param {string}   startDate   start date in YYYY-MM-DD format for the server
   * @param {string}   endDate
   *
   * @returns {Object[]}
   */
  async getSimpleReport(reportName, uids, startDate = null, endDate = '') {
    if (!startDate) startDate = vuntangle.dates.getDateString(new Date(), -30)
    const response = await this.getReportData(reportName, uids, startDate, endDate)
    const reportData = []
    if (response) {
      for (const key in response) {
        reportData.push({ name: key, y: response[key] })
      }
    }

    // freeze the object as the report data does not need to be reactive
    return Object.freeze(reportData)
  },

  /**
   * Get top hostnames report.
   *
   * @param {string[]}  uids
   * @param {string}    startDate
   *
   * @returns {Object[]>}
   */
  async getTopHostnames(uids, startDate = null) {
    // get the report data from the uids
    const response = await this.getReportData(
      'TopHosts',
      uids,
      startDate || vuntangle.dates.getDateString(new Date(), -30),
      '',
    )
    // return an empty array if the response was not a success
    const reportData = []
    if (!response) {
      return reportData
    }

    // build the data array
    for (const key in response) {
      // split the key, which looks like {hostname}|{uid}
      const [hostname, uid] = key.split('|')
      const data = {
        hostname,
        uid: util.obfuscateUid(uid),
        appliance: util.uidToHostname(uid),
        applianceTag: util.uidToApplianceTag(uid),
        bandwidth: response[key],
      }
      data.tooltip =
        'Hostname: ' +
        hostname +
        '<br />' +
        'Appliance: ' +
        data.appliance +
        ' [' +
        data.uid +
        ' / ' +
        data.applianceTag +
        ' ]' +
        '<br />' +
        'Bandwidth: ' +
        util.formatBytes(response[key], 2)

      reportData.push(data)
    }

    // freeze the object as the report data does not need to be reactive
    return Object.freeze(reportData)
  },

  /**
   * Get total bandwidth report.
   *
   * @param {string[]}  uids
   * @param {string}    startDate
   *
   * @returns {Object[]>}
   */
  async getTotalBandwidth(uids, startDate = null) {
    // get the report data from the uids
    const response = await this.getReportData(
      'TotalBandwidth',
      uids,
      startDate || vuntangle.dates.getDateString(new Date(), -30),
      '',
    )

    // return an empty array if the response was not a success
    const reportData = []
    if (!response) {
      return reportData
    }

    // build the data array, the response looks like
    // {"timePeriod": {"Uid": "Bandwidth"}, {"Uid": "Bandwidth"}}
    for (const timePeriod in response) {
      // loop through the time period object
      for (const uid in response[timePeriod]) {
        const bandwidth = response[timePeriod][uid]
        const formattedBandwidth = util.formatBytes(bandwidth, 2)
        const hostname = util.uidToHostname(uid)
        const data = {
          timePeriod,
          uid: util.obfuscateUid(uid),
          applianceTag: util.uidToApplianceTag(uid),
          hostname,
          formattedValue: formattedBandwidth,
          value: bandwidth,
          bandwidth: formattedBandwidth,
        }
        data[uid] = bandwidth

        reportData.push(data)
      }
    }

    // freeze the object as the report data does not need to be reactive
    return Object.freeze(reportData)
  },

  /**
   * Get data formatted for total bandwidth line chart
   *
   * @param {string[]}  uids
   * @param {string}    startDate
   *
   * @returns {Object[]>}
   */
  async getTotalBandwidthChart(uids, startDate = null) {
    const response = await this.getReportData(
      'TotalBandwidth',
      uids,
      startDate || vuntangle.dates.getDateString(new Date(), -30),
      '',
    )
    if (response) {
      return this.convertDataForLineChart(response)
    } else return null
  },

  /**
   * Get data formatted for top applications pie chart
   *
   * @param {string} reportName
   * @param {string[]}  uids
   * @param {string}    startDate
   *
   * @returns {Object[]>}
   */
  async getDashboardPieChart(reportName, uids, startDate = null) {
    const response = await this.getReportData(
      reportName,
      uids,
      startDate || vuntangle.dates.getDateString(new Date(), -30),
      '',
    )
    if (!response || Object.keys(response).length === 0) return null
    const rawData = this.convertDataForPieChart(response, reportName)
    const returnData = [
      {
        name: reportName,
        colorByPoint: true,
        data: rawData,
      },
    ]
    return returnData
  },

  /**
   * Get data formatted for top domains pie chart
   *
   * @param {string[]}  uids
   * @param {string}    startDate
   *
   * @returns {Object[]>}
   */
  async getTopDomainsChart(uids, startDate = null) {
    const response = await this.getReportData(
      'TopDomains',
      uids,
      startDate || vuntangle.dates.getDateString(new Date(), -30),
      '',
    )
    if (response) {
      const rawData = this.convertDataForPieChart(response, 'TopDomains')
      const returnData = [
        {
          name: 'TopDomains',
          colorByPoint: true,
          data: rawData,
        },
      ]
      return returnData
    } else return null
  },

  /**
   * Base method to retrieve report data.  This should not be called directly but use method above.
   *
   * @param {string[]} uids       array of UIDs to fetch data for
   * @param {string}   reportName name of report to be retrieved
   * @param {string}   startDate  empty for all data from beginning
   * @param {string}   endDate    empty for all data going forward
   *
   * @returns {Promise<*>}
   */
  async getReportData(reportName, uids, startDate, endDate) {
    let reQuery = false
    // check to see if this is an appliance page query - do not use the store because it
    //  should contain data for all cmd-enabled appliances.  Instead, call directly to
    //  back end to get data
    if (uids.length === 1) {
      const allUIDs = store.getters['appliances/getCmdEnabledAppliances']
      if (allUIDs.length > 1) reQuery = true
    }
    if (reQuery) {
      const response = await api.cloud('Untangle_CommandCenter', 'GetAggregatedReport', {
        reportName,
        uids,
        startDate,
        endDate,
        paramOrder: 'uids reportName startDate endDate',
      })
      if (response.success) return response.data
      else return null
    } else {
      await store.dispatch('reports/fetchReport', { reportName, uids, startDate, endDate, options: { force: true } })
      return store.state.reports.reports[reportName]
    }
  },
  /**
   * Requests report data for a single UID.  makes sure that reports store is loaded with this report
   * data for all eligible UIDs and then returns data for just the requested UID
   * @param reportName
   * @param uid
   * @param startDate
   * @param endDate
   * @returns {Promise<*>}
   */
  async getReportDataByUid(reportName, uid, startDate = null, endDate = null) {
    const uids = store.getters['appliances/getCmdEnabledAppliances']
    const allData = await this.getReportData(
      reportName,
      uids,
      startDate || vuntangle.dates.getDateString(new Date(), -30),
      endDate || '',
    )
    if (allData && allData[uid]) {
      return allData[uid]
    }
    return []
  },
  /**
   * Takes raw data obtained for a report and formats it in a series variable
   * which will work correctly with the LineChart widget
   * @param rawData - data obtained from a call to getReportData (above)
   * @returns {[]}
   */
  convertDataForLineChart(rawData) {
    const series = {}
    const arr = []

    for (const [date, row] of Object.entries(rawData)) {
      for (const [uid, value] of Object.entries(row)) {
        if (!series[uid]) {
          series[uid] = []
        }
        series[uid].push([date, value])
      }
    }

    for (const [uid, data] of Object.entries(series)) {
      arr.push({
        name: uid,
        data,
      })
    }
    return arr
  },
  /**
   * Takes raw data obtained for a report and formats it in a series variable
   * which will work correctly with the PieChart widget
   * @param rawData
   * @param reportName
   * @returns {[]}
   */
  convertDataForPieChart(rawData, reportName) {
    // creation of series for each uid
    const MaxNumberOfSlices = 25
    const MinimumPercent = 0.035

    const chartData = []
    if (rawData !== null) {
      for (const key in rawData) {
        // check to make sure that the key is an actual property of the object, and doesn't come from the prototype.
        if (Object.prototype.hasOwnProperty.call(rawData, key)) {
          // fill in the key, value, and tooltip text for each pie chart entry
          const chartDataPoint = {}
          let name = key
          let hostname = ''
          let uid = ''
          let applianceTag = ''

          chartDataPoint.y = rawData[key]

          // TopHosts reports needs more information for the tooltip
          if (reportName === 'TopHosts') {
            const splitKey = key.split('|')
            name = splitKey[0]
            hostname = util.uidToHostname(splitKey[1])
            uid = util.obfuscateUid(splitKey[1])
            applianceTag = util.uidToApplianceTag(splitKey[1])
          }
          chartDataPoint.name = name
          chartDataPoint.hostname = hostname
          chartDataPoint.reportName = reportName
          chartDataPoint.uid = uid
          chartDataPoint.applianceTag = applianceTag

          chartData.push(chartDataPoint)
        }
      }
    }
    let total = 0
    for (let i = 0; i < chartData.length; i++) {
      total += chartData[i].y
    }

    // sort the data highest to lowest
    const newData = chartData.sort(function (a, b) {
      return b.y - a.y
    })

    const displayData = []
    let otherDataTotal = 0
    // Loop through and add top MaxNumberOfSlices -1 to chart if the % is greater than MinimumPercent
    // if there are only 2 items, add them both even if one is < MinimumPercent, as they won't overlap
    for (let i = 0; i < newData.length; i++) {
      if (newData.length === 2 || (i < MaxNumberOfSlices - 1 && newData[i].y / total >= MinimumPercent)) {
        displayData.push(newData[i])
      } else {
        otherDataTotal += newData[i].y
      }
    }
    if (otherDataTotal > 0) {
      const otherData = {}
      otherData.y = otherDataTotal
      otherData.name = 'Other'
      displayData.push(otherData)
    }
    return displayData
  },
  /**
   * called for grid-based reports whose content comes back as a series of json-encoded strings
   * @param {Object} rawData - data received from cloud request
   * @returns {[]} array of objects
   */
  unpackGridData(rawData) {
    const returnRows = []
    for (const key in rawData) {
      const rows = rawData[key]
      const rowObj = JSON.parse(rows)
      rowObj.forEach(nxtRow => {
        nxtRow.Uid = key
        returnRows.push(nxtRow)
      })
    }
    return returnRows
  },
  async getNetworkPerformanceGraphData(uids) {
    const response = await api.cloud('Untangle_CommandCenter', 'GetSDWanPerformanceData', {
      uids,
      startDate: vuntangle.dates.getDateTimeString(new Date(), -1),
      endDate: vuntangle.dates.getDateTimeString(new Date(), 0),
      paramOrder: 'uids startDate endDate',
    })

    const results = [
      { name: 'jitter', data: [] },
      { name: 'latency', data: [] },
      { name: 'packetLoss', data: [] },
    ]

    const data = response.data
    if (data) {
      // start the graph one day in the past
      const dateInterval = new Date()
      dateInterval.setDate(dateInterval.getDate() - 1)

      // loop through two hour intervals for data points
      for (let i = 0; i < 12; i++) {
        dateInterval.setHours(dateInterval.getHours() + 2)

        // loop through results to add the data point for the date and performance line
        results.forEach(performanceLine => {
          performanceLine.data.push([dateInterval.getTime(), data[performanceLine.name][i] / data.uidCount])
        })
      }
    }
    return results
  },
}

export default reports
