<template>
  <div class="d-flex flex-grow-1 flex-basis-0">
    <v-navigation-drawer permanent class="flex-grow-0 flex-shrink-0 pl-4" style="width: 250px" color="transparent">
      <h1 class="headline mt-4">{{ $t('policy_objects') }}</h1>
      <span class="caption my-1 pr-4 d-inline-block">{{ $t('objects_description') }}</span>
      <v-divider class="mt-2 mr-4" />
      <v-list class="list-dense mt-2 pl-0 mr-2" app nav dense>
        <div v-for="(object, key, index) in filteredObjectsConfig" :key="key">
          <v-list-item
            v-if="!object.hidden"
            dense
            active-class="primary--text"
            :class="selectedClass(key)"
            @click="onClick(key)"
          >
            <v-list-item-title>{{ $t(object.text) }}</v-list-item-title>
          </v-list-item>
          <!-- conditions are first so add a divider to stand out from the rest of the objects -->
          <v-divider v-if="index === 1 || (isUserUntangler && index === bottomDividerPosition)" class="my-2" />
        </div>
      </v-list>
    </v-navigation-drawer>

    <router-view class="pa-4" />
  </div>
</template>
<script>
  import { Type, objectsConfig } from 'vuntangle/pm'

  export default {
    data: () => ({
      objectsConfig,
    }),
    computed: {
      /**
       * Calculate a bottom divider in the objects listing by displaying it above the last
       * 4 items which are uno/agni items.
       *
       * @param {Object} objectsConfig
       *
       * @returns {Number}
       */
      bottomDividerPosition: ({ objectsConfig }) => Object.keys(objectsConfig).length - 5,
      isUserUntangler: ({ $store }) => $store.getters['data/isUserUntangler'],
      // filter objectsConfig; remove Uno and Angni objects for non Arista users
      filteredObjectsConfig: ({ isUserUntangler }) => {
        if (isUserUntangler) {
          return objectsConfig
        }
        const typesToCheck = [Type.ObjectAgni, Type.ObjectAgniGroup, Type.ObjectUno, Type.ObjectUnoGroup]
        return Object.fromEntries(
          Object.entries(objectsConfig).filter(([key]) => isUserUntangler || !typesToCheck.includes(key)),
        )
      },
    },

    methods: {
      selectedClass(key) {
        if (key === Type.ObjectCondition && this.$route.name === 'pm-objects-condition-list')
          return 'v-list-item--active primary--text'
        return this.$route.params.objectType === key ? 'v-list-item--active primary--text' : ''
      },
      onClick(objectType) {
        if (objectType === Type.ObjectCondition) {
          this.$router.push({ name: 'pm-objects-condition-list' })
        } else {
          this.$router.push({ name: 'pm-objects-list', params: { objectType } })
        }
      },
    },
  }
</script>
