<template>
  <u-widget
    :title="$t('threat_history')"
    :info="$t('info_blocked_threats')"
    enable-refresh
    @refresh="fetchDashboardAlerts(true)"
    v-on="$listeners"
  >
    <u-grid
      id="blocked-threats"
      toolbar="hidden"
      :no-data-message="$t('no_data')"
      :column-defs="threatColumnDefs"
      :fetching="threatsFetching"
      :row-data="rowData"
      :resize-columns.sync="resizeEvent"
      @refresh="fetchDashboardAlerts(true)"
    />
  </u-widget>
</template>
<script>
  import grids from '@/plugins/ut/ut-grids'
  import WidgetMixin from '@/components/widgets/WidgetMixin'

  export default {
    mixins: [WidgetMixin],
    props: {
      uids: {
        type: Array,
        required: true,
        default() {
          return []
        },
      },
    },
    data() {
      return {
        type: 'threat',
        threatsFetching: false,
      }
    },
    computed: {
      threatColumnDefs: () => grids.mergeWithApplianceColumns(grids.getHostsGridColumns(), ['appliance', 'tag', 'uid']),
      threats: ({ $store, type }) => $store.getters['alerts/getAccountEvents'](type),
      /**
       * Merge threats data with appliances data
       */
      rowData: ({ $store, threats }) => $store.getters['appliances/populateApplianceInfoByUid'](threats, 'DeviceId'),
    },
    watch: {
      uids: {
        immediate: true,
        handler() {
          this.fetchDashboardAlerts()
        },
      },
    },
    methods: {
      async fetchDashboardAlerts(force = false) {
        this.threatsFetching = true
        await this.$store.dispatch('alerts/fetchDashboardAlerts', {
          type: 'threat',
          count: 100,
          startDate: this.$vuntangle.dates.getDateString(new Date(), -30),
          uids: this.uids,
          options: { force },
        })
        this.threatsFetching = false
      },
    },
  }
</script>
