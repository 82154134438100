import { UPageNotFound } from 'vuntangle'
import VueRouter from './util'

import appliances from './appliances'
import alerts from './alerts'
import { mfwPoliciesChildren } from '@/router/mfw-policies'
import PolicyManager from '@/pages/policies/mfw/policy-manager/Main'
import Container from '@/util/container'
import store from '@/store'
import NotAuthenticated from '@/components/NotAuthenticated'
import users from '@/plugins/ut/ut-users'

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_PATH,
  routes: [
    {
      path: '',
      name: 'me-policies',
      component: PolicyManager,
      redirect: { name: 'pm-overview' },
      children: mfwPoliciesChildren,
    },
    {
      path: '/not-authenticated',
      name: 'sapphire-not-allowed',
      component: NotAuthenticated,
    },
    ...appliances,
    ...alerts,
    {
      path: '*',
      name: 'not-found',
      component: UPageNotFound,
      meta: { title: 'page_not_found', statusCode: 404 },
    },
  ],
  scrollBehavior: () => ({ x: 0, y: 0 }),
})

router.beforeEach((to, from, next) => {
  const isAuth = store.state.auth.isAuth
  if (!isAuth && to.path !== '/not-authenticated') {
    next('/not-authenticated')
  } else if (to.path.startsWith('/alerts') && !users.isAlertsTabEnabled()) {
    /**
     * redirect the user to policy manager if not havin access to alerts
     * see etm router `src/router/etm.js:203`
     */
    next('/')
  } else next()
})

Container.setInstance('router', router)

export default Container.getInstance('router')
