/**
 * Mixin used only by the PolicyConditions.vue and PolicyRules.vue
 * EditPolicy.vue routes to the above two components
 */

import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import vuntangle from '@/plugins/vuntangle'

export default {
  data() {
    return {
      policyCopy: undefined,
    }
  },
  computed: {
    id: ({ $route }) => $route.params.policyId,

    originalPolicy: ({ $store, id }) => $store.getters['policyManager/getObjectById'](id),
    policy: ({ $store, id }) => $store.getters['policyManager/getEditObjectById'](id),

    /**
     * Upon rule order manually changed, it enables/disables the Update button
     */
    isChanged: ({ originalPolicy, policy }) => !isEqual(originalPolicy, policy),
  },

  watch: {
    policyCopy: {
      handler(policyCopy) {
        if (!policyCopy || isEqual(policyCopy, this.policy)) return
        /** edits made to the rule gets commited into the store to be available between routes */
        this.$store.commit('policyManager/SET_EDIT_OBJECT', policyCopy)
      },
      deep: true,
    },

    policy: {
      handler(policy) {
        if (!policy) return
        this.policyCopy = cloneDeep(policy)
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    /**
     * Dispatches action to save the Policy to the cloud
     */
    async updatePolicy() {
      this.$store.commit('SET_PAGE_LOADER', true)
      const response = await this.$store.dispatch('policyManager/saveObject', { object: this.policyCopy })
      this.$store.commit('SET_PAGE_LOADER', false)

      if (response) {
        vuntangle.toast.add(this.$t('saved_successfully', [this.$t('policy')]))
        this.$store.commit('policyManager/SET_EDIT_OBJECT', response)
      } else {
        vuntangle.toast.add(this.$t('unable_to_save', [this.$t('policy')]), 'error')
      }
    },
  },
}
