/**
 * Reports data store.  All reports are stored in raw format retrieved from cloud call
 */
import api from '@/plugins/ut/ut-api'

const getDefaultState = () => {
  return {
    reports: {
      TopApplications: null,
      TopDomains: null,
      TotalBandwidth: null,
      TopHosts: null,
      CurrentHosts: null,
      DetectedIntrusions: null,
      DetectedIntrusionClassSummary: null,
      TopBlockedCategories: null,
      TopBlockedSites: null,
      TopBlockedHostnames: null,
      NetworkPerformance: null,
    },
    fetching: {
      TopApplications: false,
      TopDomains: false,
      TotalBandwidth: false,
      TopHosts: false,
      CurrentHosts: false,
      DetectedIntrusions: false,
      DetectedIntrusionClassSummary: false,
      TopBlockedCategories: false,
      TopBlockedSites: false,
      TopBlockedHostnames: false,
      NetworkPerformance: false,
    },
  }
}

const getters = {
  topApplications: state => state.reports.TopApplications,
  fetchingTopApplications: state => state.fetching.TopApplications,
  topDomains: state => state.reports.TopDomains,
  fetchingTopDomains: state => state.fetching.TopDomains,
  totalBandwidth: state => state.reports.TotalBandwidth,
  fetchingTotalBandwidth: state => state.fetching.TotalBandwidth,
  topHosts: state => state.reports.TopHosts,
  fetchingTopHosts: state => state.fetching.TopHosts,
  currentHosts: state => state.reports.CurrentHosts,
  fetchingCurrentHosts: state => state.fetching.CurrentHosts,
  detectedIntrusions: state => state.reports.DetectedIntrusions,
  fetchingDetectedIntrusions: state => state.fetching.DetectedIntrusions,
  getByReportName: state => report => {
    return state.reports[report]
  },
  getFetchingByReportName: state => report => {
    return state.fetching[report]
  },
}

const mutations = {
  RESET: state => Object.assign(state, getDefaultState()),

  SET_REPORT: (state, { reportName, report }) => {
    state.reports[reportName] = report
  },
  SET_FETCHING: (state, { reportName, value }) => (state.fetching[reportName] = value),
}

const actions = {
  /**
   * Fetch aggregated report data for the specified report.
   *
   * @param {Function} commit
   *
   * @return {Promise<void>}
   */
  async fetchReport({ state, commit }, { reportName, uids, startDate, endDate, options }) {
    // do not call the ajax if the call is already fetching the designated report or the
    //  report exists but a 'force' was not set
    if (state.fetching[reportName] || (state.reports[reportName] !== null && !options?.force)) {
      return
    }
    commit('SET_FETCHING', { reportName, value: true })

    const response = await api.cloud('Untangle_CommandCenter', 'GetAggregatedReport', {
      reportName,
      uids,
      startDate,
      endDate,
      paramOrder: 'uids reportName startDate endDate',
    })

    if (response.success) {
      commit('SET_REPORT', { reportName, report: response.data })
    }

    commit('SET_FETCHING', { reportName, value: false })
  },
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions,
}
