<template>
  <div class="d-flex flex-grow-1">
    <ValidationProvider v-if="ops.length > 1" v-slot="{ errors }" rules="required">
      <u-select
        v-model="selectedOp"
        :label="$t('operator')"
        style="width: 120px"
        class="ml-4"
        :error-messages="errors"
        :items="ops"
        @change="$emit('update:op', $event)"
      >
        <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
      </u-select>
    </ValidationProvider>
    <ValidationProvider v-slot="{ errors }" :rules="wanCriteriaType.rules" class="flex-grow-1">
      <u-text-field
        v-if="wanCriteriaType.field === 'text'"
        v-model="selectedValue"
        :label="wanCriteriaType.labelKey ? $t(wanCriteriaType.labelKey) : $t('value')"
        type="text"
        class="ml-4"
        :error-messages="errors"
        @input="$emit('update:value', $event)"
      >
        <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
      </u-text-field>
      <v-autocomplete
        v-else-if="wanCriteriaType.field === 'autocomplete'"
        v-model="selectedValue"
        :label="wanCriteriaType.labelKey ? $t(wanCriteriaType.labelKey) : $t('value')"
        :error-messages="errors"
        class="ml-4"
        :items="values"
        dense
        outlined
        hide-details
        @change="$emit('update:value', $event)"
      >
        <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
      </v-autocomplete>
      <u-select
        v-else
        v-model="selectedValue"
        :label="wanCriteriaType.labelKey ? $t(wanCriteriaType.labelKey) : $t('value')"
        :error-messages="errors"
        class="ml-4"
        :items="values"
        @change="$emit('update:value', $event)"
      >
        <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
      </u-select>
    </ValidationProvider>
    <ValidationProvider v-if="showCustom" v-slot="{ errors }" rules="required|port_expression">
      <u-text-field
        v-model="selectedCustom"
        :label="$t('port_number')"
        type="text"
        style="width: 150px"
        class="d-inline-block ml-5"
        :error-messages="errors"
        @input="$emit('update:value', $event)"
      >
        <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
      </u-text-field>
    </ValidationProvider>
  </div>
</template>
<script>
  import { wanCriteriaTypes } from '@/util/wanRules'
  export default {
    props: {
      type: { type: String, required: true },
      op: { type: String, required: true },
      value: { type: String, required: true },
    },
    data() {
      return {
        selectedOp: this.op,
        selectedValue: this.value,
        showCustom: false,
        selectedCustom: '',
      }
    },
    computed: {
      wanCriteriaType() {
        return wanCriteriaTypes[this.type]
      },
      ops() {
        return this.wanCriteriaType.ops.map(value => ({ text: this.$t(this.$vuntangle.util.operators[value]), value }))
      },
      values() {
        if (!this.wanCriteriaType.values) {
          return []
        }

        // check for array values
        if (Array.isArray(this.wanCriteriaType.values)) {
          return this.wanCriteriaType.values.map(value => ({ text: value, value }))
        }

        // check for object value => translation key
        const values = []
        if (this.wanCriteriaType.values) {
          for (const value in this.wanCriteriaType.values) {
            values.push({ text: this.$t(this.wanCriteriaType.values[value]), value })
          }
        }

        return values
      },
    },
    watch: {
      selectedValue() {
        this.showCustom = this.type === 'DESTINATION_PORT' && this.selectedValue === '0'
      },
    },
  }
</script>
