<template>
  <u-widget
    class="full-height"
    :title="$t('policy_analytics')"
    :info="$t('info_policy_analytics')"
    :fetching="actionPending"
    v-on="$listeners"
  >
    <template #actions>
      <div class="d-flex flex-grow-1 justify-end mx-4">
        <v-btn small depressed color="primary" rounded outlined @click="runAnalytics">
          <v-icon small class="mr-2">mdi-microscope</v-icon>
          {{ $t('analyze') }}
        </v-btn>
      </div>
    </template>

    <u-grid
      id="policy-analytics"
      no-border
      :no-data-message="noDataMessage"
      :column-defs="columnDefs"
      :row-data="rowData"
      :row-actions="rowActions"
      :row-actions-floating="false"
      :fetching="fetching"
      @refresh="fetchData"
    />
  </u-widget>
</template>
<script>
  import { columnDefs } from 'vuntangle/pm'
  import taskManager from '@/plugins/ut/ut-task-manager'

  export default {
    data() {
      return {
        fetching: false,
        actionPending: false,
      }
    },

    computed: {
      rowData: ({ $store }) => $store.getters['policyManager/getAnalytics'],
      rowActions() {
        return [
          {
            icon: 'mdi-arrow-right-circle',
            tooltip: this.$t('review'),
            handler: ({ data }) => this.handleResolve(data),
          },
          {
            icon: 'mdi-delete',
            tooltip: this.$t('ignore_for_now'),
            handler: ({ data }) => this.handleIgnore(data),
          },
        ]
      },
      columnDefs: () => columnDefs.getPolicyAnalyticsColumnDefs(),
      noDataMessage() {
        return this.rowData.length > 0
          ? this.$t('no_filtered_data_policy_analytics')
          : this.$t('no_data_defined_policy_analytics')
      },
    },

    mounted() {
      this.fetchData()
    },

    methods: {
      /**
       * Dispatches action to fetch analytics data
       */
      async fetchData() {
        this.fetching = true
        await this.$store.dispatch('policyManager/fetchAnalytics')
        this.fetching = false
      },

      /**
       * task to run analytics script
       */

      runAnalytics() {
        taskManager.createTask('RunAnalyticsScript', [], task => {
          // display error
          if (task.Result?.Error || task.Result?.TaskResult?.ErrorMessage) {
            this.$vuntangle.toast.add(this.$t('run_analytics_error'), 'error')
          } else {
            this.$vuntangle.toast.add(this.$t('run_analytics_success'))
          }
        })
        this.$vuntangle.toast.add(this.$t('run_analytics_sent_successfully'))
      },

      /**
       * Takes action upon resolving an issue
       * @param {String} data - full data details for each row
       */
      async handleResolve(data) {
        const getFromStore = this.$store.getters['policyManager/getObjectById']
        // get entity (rule, policy, object, condition, template) based on its id
        const entity =
          getFromStore(data.targetId) || (await this.$store.dispatch('policyManager/fetchObjectById', data.targetId))
        this.$store.commit('policyManager/SET_EDIT_OBJECT', entity)
        switch (data.link) {
          case 'rules':
            this.$router.push({ name: 'pm-rules-rule', params: { ruleId: data.targetId } })
            break
          case 'objects':
            this.$router.push({ name: 'pm-object', params: { objectType: entity.Type, objectId: data.targetId } })
            break
          case 'conditions':
            this.$router.push({
              name: 'pm-objects-condition',
              params: { objectType: entity.Type, conditionId: data.targetId },
            })
            break
          case 'policies':
            this.$router.push({ name: 'pm-policy-conditions', params: { policyId: data.targetId } })
            break
          case 'assignment':
            this.$router.push({ name: 'pm-assignment' })
            break
          case 'templates':
            this.$router.push({ name: 'pm-templates-configuration', params: { configurationId: data.targetId } })
            break
        }
      },

      /**
       * Ingores the analytics issue
       * @param {Object} issue - the analytics issue to ignore
       */
      async handleIgnore(issue) {
        this.actionPending = true
        const response = await this.$store.dispatch('policyManager/deletePolicyAnalyticsIssue', {
          policyAnalyticsId: issue.policyAnalyticsId,
        })
        this.actionPending = false
        const type = this.$t('issue')
        if (response) {
          this.$vuntangle.toast.add(this.$t('ignore_success', [type]))
        } else {
          this.$vuntangle.toast.add(this.$t('ignore_failure', [type]), 'error')
        }
      },
    },
  }
</script>
