<template>
  <value-object v-if="useObjects" v-on="$listeners" />
  <value-autocomplete v-else :values="items" />
</template>
<script>
  import { ConditionType, OperatorType } from 'vuntangle/pm'
  import { ValueObject, ValueAutocomplete } from '../value'

  export default {
    components: { ValueObject, ValueAutocomplete },
    inject: ['$condition'],
    data() {
      return {
        apps: undefined,
      }
    },
    computed: {
      condition: ({ $condition }) => $condition(),

      useObjects: ({ condition }) =>
        [OperatorType.Match, OperatorType.NotMatch, OperatorType.In, OperatorType.NotIn].includes(condition.op),

      items: ({ condition, apps }) => {
        if (!apps || !apps.length) return []
        switch (condition.type) {
          case ConditionType.ApplicationName:
          case ConditionType.ApplicationNameInferred:
            return apps.map(app => app.name)

          case ConditionType.ApplicationCategory:
          case ConditionType.ApplicationCategoryInferred: {
            const categories = []
            apps.forEach(app => {
              if (!categories.includes(app.category)) {
                categories.push(app.category)
              }
            })
            return categories
          }
        }
      },
    },

    watch: {
      'condition.type': {
        async handler(type) {
          if (
            !this.apps &&
            [
              ConditionType.ApplicationName,
              ConditionType.ApplicationNameInferred,
              ConditionType.ApplicationCategory,
              ConditionType.ApplicationCategoryInferred,
            ].includes(type)
          ) {
            await this.$emit('get-applications', apps => (this.apps = apps))
          }
        },
        immediate: true,
      },
    },
  }
</script>
