<template>
  <div>
    <v-row dense class="my-0">
      <v-col cols="12" md="4">
        <ValidationProvider v-slot="{ errors }" rules="required|max: 100">
          <u-text-field
            v-model="policyName"
            :label="nameLabel || $t('name')"
            maxlength="100"
            :disabled="disabled"
            :error-messages="errors"
          >
            <template v-if="errors.length" #append> <u-errors-tooltip :errors="errors" /> </template>
          </u-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="8">
        <ValidationProvider v-slot="{ errors }" :rules="descriptionRules">
          <u-text-field
            v-model="policyDescription"
            :label="descriptionLabel || $t('description')"
            maxlength="150"
            :disabled="disabled"
            :error-messages="errors"
          >
            <template v-if="errors.length" #append> <u-errors-tooltip :errors="errors" /> </template>
          </u-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  export default {
    props: {
      name: { type: String, default: '' },
      description: { type: String, default: '' },
      disabled: { type: Boolean, default: false },
      nameLabel: { type: String, default: undefined },
      descriptionLabel: { type: String, default: undefined },
      descriptionRequired: { type: Boolean, default: false },
    },
    computed: {
      descriptionRules: ({ descriptionRequired }) => (descriptionRequired ? 'required|' : '') + 'max:150',
      policyName: {
        get: ({ name }) => name,
        set(value) {
          this.$emit('update:name', value)
        },
      },
      policyDescription: {
        get: ({ description }) => description,
        set(value) {
          this.$emit('update:description', value)
        },
      },
    },
  }
</script>
