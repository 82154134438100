<template>
  <u-page full-height :title="$t('notification_profiles')">
    <template #actions>
      <u-btn class="mr-2" @click="displayAddNotificationProfile">
        {{ $t('add_notification_profile') }}
      </u-btn>
      <u-btn :disabled="selectedRows.length !== 1" class="mr-2" @click="displayEditNotificationProfile">
        {{ $t('edit_notification_profile') }}
      </u-btn>
      <u-btn :disabled="selectedRows.length === 0" class="mr-2" @click="displayDeleteNotificationProfilesDialog">
        {{ $t('delete_notification_profile') }}
      </u-btn>
    </template>
    <u-grid
      id="notification-profiles-grid"
      selection-type="multiAction"
      :no-data-message="$t('no_data')"
      :column-defs="columnDefs"
      :fetching="$store.state.alerts.notificationProfilesFetching"
      :row-data="$store.state.alerts.notificationProfiles || []"
      :selection.sync="selectedRows"
      @refresh="$store.dispatch('alerts/fetchNotificationProfiles', { force: true })"
    >
    </u-grid>

    <u-dialog
      :show-dialog="deleteDialog"
      :title="$t('delete_notification_profiles')"
      :message="$t('confirm_notification_profile_delete')"
      :buttons="[
        { name: $t('cancel') },
        {
          name: $t('yes'),
          handler: 'deleteNotificationProfiles',
          showProgress: true,
        },
      ]"
      @close-dialog="deleteDialog = false"
      @deleteNotificationProfiles="deleteNotificationProfiles"
    />

    <u-dialog
      :show-dialog="deleteErrorDialog"
      :title="$t('delete_notification_profiles_error')"
      :message="$t('delete_notification_profiles_error_associated_to_alert_rules')"
      :buttons="[{ name: $t('ok') }]"
      @close-dialog="deleteErrorDialog = false"
    >
      <div
        v-for="(
          { deleteNotificationProfile, deleteAlertRules }, deleteNotificationProfileIndex
        ) in selectedNotificationProfilesAssociatedToAlertRules"
        :key="`delete-notification-profile-${deleteNotificationProfileIndex}`"
        class="mt-2"
        dense
      >
        {{ deleteNotificationProfile.Name }}
        <ul>
          <li
            v-for="(deleteAlertRule, deleteAlertRuleIndex) in deleteAlertRules"
            :key="`delete-alert-rule${deleteAlertRuleIndex}`"
          >
            {{ deleteAlertRule.Name }}
          </li>
        </ul>
      </div>
    </u-dialog>
  </u-page>
</template>

<script>
  export default {
    data() {
      return {
        selectedRows: [],
        deleteDialog: false,
        deleteErrorDialog: false,
      }
    },
    computed: {
      columnDefs() {
        return [
          {
            headerName: this.$t('name'),
            field: 'Name',
          },
          {
            headerName: this.$t('description'),
            field: 'Description',
          },
          {
            headerName: this.$t('action'),
            field: 'InitialAction',
          },
          {
            headerName: this.$t('last_updated'),
            field: 'DateUpdated',
            valueFormatter: ({ value }) => this.$vuntangle.dates.formatDateFromApi(value),
            comparator: (a, b) => this.$vuntangle.dates.compareDates(a, b),
          },
        ]
      },
      alertRules() {
        return this.$store.state.alerts.notificationProfiles && this.$store.state.alerts.alertRules
          ? this.$store.state.alerts.alertRules
          : []
      },
      selectedNotificationProfilesAssociatedToAlertRules() {
        const selectedNotificationProfilesAssociatedToAlertRules = []
        this.selectedRows.forEach(deleteNotificationProfile => {
          const deleteAlertRules = this.alertRules.filter(deleteAlertRule =>
            (deleteAlertRule.NotificationProfileIds || []).includes(deleteNotificationProfile.Id),
          )
          if (deleteAlertRules.length) {
            selectedNotificationProfilesAssociatedToAlertRules.push({
              deleteNotificationProfile,
              deleteAlertRules,
            })
          }
        })

        return selectedNotificationProfilesAssociatedToAlertRules
      },
    },
    created() {
      this.$store.commit('SET_ALERTS_SELECTION', this.$route.path)
      this.$store.dispatch('alerts/fetchNotificationProfiles')
      this.$store.dispatch('alerts/fetchAlertRules')
    },
    methods: {
      /**
       * method dispatching alert rule(s) deletion
       */
      async deleteNotificationProfiles() {
        await this.$store.dispatch('alerts/deleteNotificationProfiles', this.selectedRows)
        this.deleteDialog = false
        this.selectedRows = []
      },
      displayAddNotificationProfile() {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'NotificationProfiles',
          eventAction: 'Add Notification Profile',
        })
        this.$router.push('/alerts/notification-profiles/new')
      },
      displayDeleteNotificationProfilesDialog() {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'NotificationProfiles',
          eventAction: 'Delete Notification Profiles',
        })

        // check if any selected notification profiles attached to alert rules
        if (this.selectedNotificationProfilesAssociatedToAlertRules.length > 0) {
          this.deleteErrorDialog = true
          return
        }

        this.deleteErrorDialog = false
        this.deleteDialog = true
      },
      displayEditNotificationProfile() {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'NotificationProfiles',
          eventAction: 'Edit Notification Profile',
        })
        this.$router.push('/alerts/notification-profiles/' + this.selectedRows[0].Id)
      },
    },
  }
</script>
