<template>
  <u-page v-if="network" :title="$t('network') + ': ' + network.Name">
    <div>
      <grid-layout
        :layout.sync="baseLayout"
        :responsive-layouts.sync="responsiveLayouts"
        :row-height="300"
        :margin="[16, 16]"
        :use-css-transforms="false"
        :is-resizable="false"
        :is-draggable="false"
        responsive
        :breakpoints="$vuntangle.widgets.breakpoints"
        :cols="$vuntangle.widgets.gridSizes"
      >
        <grid-item
          v-for="widget in baseLayout"
          :key="widget.i"
          :x="widget.x"
          :y="widget.y"
          :w="widget.w"
          :h="widget.h"
          :i="widget.i"
          drag-allow-from=".drag-handle"
          @resized="onResizedWidget(widget.i)"
          @moved="storeLayout"
        >
          <component
            :is="widget.cmp"
            :network="network"
            :appliance-markers="applianceMarkers"
            :map-lines="mapLines"
            :network-performance-data="networkPerformanceData"
            :network-performance-fetching="networkPerformanceFetching"
            :appliances-row-data="appliancesRowData"
            :add-appliances-row-data="addAppliancesRowData"
            :resize.sync="widget.resize"
            @remove="onRemove(widget.id)"
          />
        </grid-item>
      </grid-layout>
    </div>
  </u-page>
  <u-page v-else-if="networkNotFound" :title="$t('network_not_found')">
    <v-list-item>
      <v-list-item-content>
        <u-alert error>
          {{ $t('network_not_found') }}
        </u-alert>
      </v-list-item-content>
    </v-list-item>
  </u-page>
</template>
<script>
  import VueGridLayout from 'vue-grid-layout'
  import cloneDeep from 'lodash/cloneDeep'
  import store from '@/store'
  import appliances from '@/plugins/ut/ut-appliances'
  import reports from '@/plugins/ut/ut-reports'
  import NetworkSummary from '@/components/networks/NetworkSummary'
  import NetworkPerformance from '@/components/networks/NetworkPerformance'
  import NetworkMap from '@/components/networks/NetworkMap'
  import NetworkAppliances from '@/components/networks/NetworkAppliances'
  import WanRules from '@/components/networks/WanRules'
  import Vpn from '@/components/networks/vpn'

  const widgetComponentMap = {
    network_info: { component: 'NetworkSummary', width: 1 },
    network_map: { component: 'NetworkMap', width: 2 },
    appliances: { component: 'NetworkAppliances', width: 3 },
    performance: { component: 'NetworkPerformance', width: 3 },
    vpn: { component: 'Vpn', width: 3 },
    rules: { component: 'WanRules', width: 3 },
  }

  export default {
    components: {
      NetworkSummary,
      NetworkPerformance,
      NetworkMap,
      NetworkAppliances,
      Vpn,
      WanRules,
      GridLayout: VueGridLayout.GridLayout,
      GridItem: VueGridLayout.GridItem,
    },
    // fetch networks for selector
    async beforeRouteEnter(to, from, next) {
      store.commit('SET_PAGE_LOADER', true)
      await store.dispatch('networks/fetchNetworks')
      store.commit('SET_PAGE_LOADER', false)
      next()
    },
    data() {
      return {
        networkNotFound: false,
        networkPerformanceData: [],
        networkPerformanceFetching: true,
        baseLayout: [],
        responsiveLayouts: {},
        mapLines: [],
      }
    },
    computed: {
      /**
       * Load appliances that are related to the network so they can be
       * selected to be deleted.
       *
       * @return {Appliance[]}
       */
      appliancesRowData() {
        // do nothing until the network is loaded
        if (this.network === null) {
          return []
        }

        // filter appliances for the selected network
        return this.$store.state.appliances.list?.filter(appliance => this.network.Uids.includes(appliance.Uid)) || []
      },

      /**
       * Load appliances that are not related to the network so they can be
       * selected to be added.
       *
       * @return {Appliance[]}
       */
      addAppliancesRowData() {
        // get appliances without a network that have a complete license
        return (
          this.$store.state.appliances.list?.filter(
            appliance => appliance.NetworkInfo === null && appliances.isLicensedForVPN(appliance),
          ) || []
        )
      },

      /**
       * Get network based on the id parameter in the url.
       *
       * @returns {Network}
       */
      network() {
        return this.$store.state.networks.list?.find(network => network.Id === this.$route.params.id) || null
      },
      /**
       * Returns appliance markers used on the network map
       *
       * @return []
       */
      applianceMarkers() {
        if (!this.appliancesRowData) {
          return []
        }

        return appliances.getApplianceMarkers(this.appliancesRowData)
      },
    },
    watch: {
      /**
       * Save the new route that was selected, set the network.
       */
      $route() {
        this.saveNetworkSelection()
        this.setNetworkSelection()
      },
      /**
       * If the networks store changes, update the selected network.
       */
      '$store.state.networks.list': {
        handler() {
          this.setNetworkSelection()
        },
      },
      /**
       * If the network changes, re-compute the lines between the network appliances
       */
      network: {
        immediate: true,
        handler() {
          this.getApplianceLines()
        },
      },
    },

    /**
     * The created runs on hard refresh and when navigating away from the area and back.  Need to
     * save the network selected and set the network.
     */
    created() {
      this.saveNetworkSelection()
      this.setNetworkSelection()
    },
    methods: {
      /**
       * Save selected network so that next time networks tab is requested, the users comes back to the same network.
       */
      saveNetworkSelection() {
        this.$store.commit('SET_NETWORKS_SELECTION', this.$route.path)
      },

      /**
       * Set if the network was found and data of the network performance graph.
       */
      async setNetworkSelection() {
        // check if the network was not found
        this.networkNotFound = this.$store.state.networks.list !== null && this.network === null

        this.setLayout()

        // clear the graph and show it loading
        this.networkPerformanceFetching = true
        this.networkPerformanceData = []

        // get network performance data when the networks have been loaded
        if (this.network?.Uids && this.network.Uids.length) {
          const data = await reports.getNetworkPerformanceGraphData(this.network.Uids)
          if (data) {
            // make sure line data actually exists, set the network performance data
            for (const line of data) {
              if (line.data.length > 0) {
                this.networkPerformanceData = data
                break
              }
            }
          }
        }
        this.networkPerformanceFetching = false
      },
      /**
       * Sets the widgets layout on the page
       */
      setLayout() {
        // make sure appliance was found
        if (!this.network) {
          return
        }
        // check if a saved layout exists, is so use that on the dashboard
        const storedLayout = this.$vuntangle.widgets.getStoredLayout('network-layout')
        if (storedLayout && storedLayout[this.network.Id]) {
          this.responsiveLayouts = storedLayout[this.network.Id]
          this.baseLayout = cloneDeep(this.responsiveLayouts[this.$vuntangle.widgets.getBreakpoint()])

          return
        }

        this.responsiveLayouts = this.$vuntangle.widgets.getLayout(Object.keys(widgetComponentMap), widgetComponentMap)
        this.baseLayout = cloneDeep(this.responsiveLayouts[this.$vuntangle.widgets.getBreakpoint()])

        //  store the grid so it does not have to recompute
        this.storeLayout()
      },

      /**
       * Set the widget to resize to fire resize events, save the newly resized layout in local storage.
       */
      onResizedWidget(i) {
        this.baseLayout[i].resize = true
        Object.keys(this.responsiveLayouts).forEach(key => {
          this.responsiveLayouts[key][i].resize = true
        })

        this.storeLayout()
      },
      onRemove(id) {
        const idx = this.baseLayout.findIndex(el => {
          return el.id === id
        })
        this.baseLayout.splice(idx, 1)
        Object.keys(this.responsiveLayouts).forEach(key => {
          this.responsiveLayouts[key].splice(idx, 1)
        })

        this.storeLayout()
      },
      /**
       * Store the new layout into the local storage which stores all items by Uid.
       */
      storeLayout() {
        // get the stored layout or create it if it doesn't exist
        let storedLayout = this.$vuntangle.widgets.getStoredLayout('network-layout')
        if (!storedLayout) {
          storedLayout = {}
        }

        // build the new layout for the appliance
        const newLayout = cloneDeep(this.responsiveLayouts)
        newLayout[this.$vuntangle.widgets.getBreakpoint()] = this.baseLayout

        // add the new layout to the stored layout and set as stored
        storedLayout[this.network.Id] = newLayout
        this.$vuntangle.widgets.setStoredLayout('network-layout', storedLayout)
      },
      /**
       * Sets appliance lines used on the network map
       *
       */
      getApplianceLines() {
        if (!this.appliancesRowData || !this.network || !this.network.NetworkAppliances) {
          return []
        }

        this.mapLines = appliances.getApplianceLines(this.network.NetworkAppliances, this.appliancesRowData)
      },
    },
  }
</script>
