<template>
  <u-dialog-complex
    v-model="show"
    :title="$t('add_license')"
    :width="'80%'"
    :submit-label="$t('add')"
    :message="$t(errorMessage)"
    :show-progress="progress"
    @cancel="show = false"
    @submit="onSubmit"
  >
    <u-grid
      id="subscriptions"
      class="mx-n4 mt-n4"
      style="height: 300px"
      selection-type="singleAction"
      :no-data-message="$t('no_data')"
      :column-defs="columnDefs"
      :fetching="
        $store.state.financial.fetching ||
        $store.state.subscriptions.fetching ||
        $store.state.subscriptions.fetchingUnassigned
      "
      :row-data="subs"
      :selection.sync="selectedRows"
      @refresh="loadSubscriptions(true)"
    />
    <v-divider />
  </u-dialog-complex>
</template>

<script>
  import grids from '@/plugins/ut/ut-grids'
  import subscriptions from '@/plugins/ut/ut-subscriptions'

  export default {
    props: {
      // boolean to show/hide dialog
      value: {
        type: Boolean,
        default: false,
      },
      appliance: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        selectedRows: [],
        errorMessage: null,
        progress: false,
        subs: [],
      }
    },
    computed: {
      columnDefs: () => grids.getSubscriptionsColumnDefs(),
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
    },
    watch: {
      selectedRows(rows) {
        if (rows.length && !rows[0].canManage) {
          this.errorMessage = 'appliance_add_licenses_sub_not_assignable'
        }
      },
      '$store.state.subscriptions.unassigned': {
        immediate: true,
        handler(allUnassigned) {
          const unassignedSubs = []
          if (Array.isArray(allUnassigned)) {
            allUnassigned.forEach(nxtSub => {
              if (subscriptions.isSubscriptionAssignableToAppliance(nxtSub, this.appliance)) {
                unassignedSubs.push(nxtSub)
              }
            })
          }

          this.subs = unassignedSubs
        },
      },
    },
    created() {
      this.loadSubscriptions()
    },
    methods: {
      loadSubscriptions(force = false) {
        this.$store.dispatch('financial/fetchBillingInfo', { force })
        this.$store.dispatch('subscriptions/fetchSubscriptions', { force })
        this.$store.dispatch('subscriptions/fetchUnassigned', { force })
      },
      async onSubmit() {
        const subscription = this.selectedRows[0]
        if (!subscription || !subscription.canManage) {
          return
        }
        this.progress = true
        await this.$store.dispatch('subscriptions/assignSubscription', {
          uid: this.appliance.Uid,
          subscriptionName: subscription.subscriptionName,
          paramOrder: 'uid subscriptionName',
        })
        this.progress = false
        this.show = false
      },
    },
  }
</script>
