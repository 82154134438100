<template>
  <div class="d-flex align-center">
    <h4 class="mr-2">{{ $t('apply_configuration') }}:</h4>

    <ValidationProvider v-slot="{ errors }" rules="required">
      <v-select
        v-model="action.policy"
        :items="configurations"
        dense
        outlined
        hide-details
        prepend-inner-icon="mdi-tune"
        :placeholder="$t('select')"
        item-value="Id"
        :error-messages="errors"
        :menu-props="{ offsetY: true, closeOnContentClick: true }"
      >
        <template #selection="{ item }">
          {{ item.Name }}
        </template>
        <template #item="{ item }">
          <v-tooltip right min-width="300" max-width="500" transition="false" color="primary" :nudge-right="10">
            <template #activator="props">
              <v-list-item-content v-bind="props.atrrs" v-on="props.on">
                <v-list-item-title> {{ item.Name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <tooltip-configuration :configuration="item" />
          </v-tooltip>
        </template>
        <template #no-data>
          <div class="d-flex align-center pa-4 justify-center">
            <span class="caption">No Wan Policies defined</span>
          </div>
        </template>

        <template #append-item>
          <v-sheet class="append-sticky py-2 mb-n2">
            <v-divider class="my-2" />
            <div class="mx-2">
              <v-btn small depressed color="primary" class="text-capitalize" @click="$emit('create-configuration')">
                <v-icon small class="mr-2">mdi-plus</v-icon> {{ $t('create_configuration') }}
              </v-btn>
            </div>
          </v-sheet>
        </template>

        <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
        <!-- appends Edit action button inside select field -->
        <template v-else-if="action.policy" #append>
          <v-btn
            small
            text
            style="margin-top: -2px; margin-right: -6px"
            color="primary"
            class="text-capitalize"
            @click="$emit('edit-configuration')"
          >
            {{ $t('edit') }}
          </v-btn>
        </template>
      </v-select>
    </ValidationProvider>
  </div>
</template>

<script>
  import TooltipConfiguration from './TooltipConfiguration.vue'

  export default {
    components: { TooltipConfiguration },
    inject: ['$action'],
    props: {
      configurations: { type: Array, default: undefined },
    },
    computed: {
      action: ({ $action }) => $action(),
    },
  }
</script>
