const ga = {
  /**
   * Include Google Analytics Script
   */
  load() {
    /* eslint-disable */
    ;(function (i, s, o, g, r, a, m) {
      i.GoogleAnalyticsObject = r
      ;(i[r] =
        i[r] ||
        function () {
          ;(i[r].q = i[r].q || []).push(arguments)
        }),
        (i[r].l = 1 * new Date())
      ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
      a.async = 1
      a.src = g
      m.parentNode.insertBefore(a, m)

      // can enable debug by changing the script to analytics_debug.js
    })(window, document, 'script', `https://www.google-analytics.com/analytics.js`, 'ga')
  }
}

export default ga

