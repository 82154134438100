<template>
  <u-dialog-complex
    v-model="show"
    :width="700"
    :title="$t('reboot_options')"
    :show-progress="fetching"
    :submit-label="$t('reboot_now')"
    :cancel-label="$t('ok')"
    @cancel="show = false"
    @submit="rebootNow()"
  >
    <div class="d-flex flex-column gap-4">
      <!-- Appliance Time -->
      <v-card outlined>
        <v-card-text class="d-flex justify-space-between align-center text-body-1">
          <div class="flex-grow-1 text--disabled">{{ $t('current_server_datetime') }}</div>
          <div v-if="applianceLocaleDateTime">{{ applianceLocaleDateTime }}</div>
          <v-divider v-if="!!applianceLocaleDateTime" vertical class="mx-4" />
          <v-btn v-if="!!applianceLocaleDateTime" icon small color="primary" @click="getApplianceDateTime(true)">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>

      <!-- Scheduled Reboot -->
      <v-card outlined>
        <!-- Existing scheduled reboot -->
        <v-card-text class="d-flex align-center text-body-1">
          <div class="flex-grow-1 text--disabled">{{ $t('reboot_scheduled') }}</div>
          <template v-if="scheduledRebootLocaleDateTime">
            <div>{{ scheduledRebootLocaleDateTime }}</div>
            <v-divider vertical class="mx-4" />
            <v-btn icon small color="error" @click="deleteSchedule()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span v-else>{{ $t('not_set') }}</span>
        </v-card-text>
        <v-divider v-if="!scheduledReboot" />

        <!-- Schedule reboot form -->
        <v-card-text v-if="!scheduledReboot && applianceDateTime">
          <div class="d-flex align-center gap-2">
            <!-- SCHEDULE DATE -->
            <v-menu
              ref="scheduleDateMenu"
              v-model="scheduleDateMenu"
              :close-on-content-click="false"
              :transition="false"
              offset-y
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedScheduleDate"
                  readonly
                  v-bind="attrs"
                  class="flex-grow-0"
                  outlined
                  hide-details
                  dense
                  style="max-width: 160px"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="scheduleDate"
                no-title
                scrollable
                :min="minRebootDate"
                :locale="$vuntangle.locale"
                :show-current="!!applianceDateTime"
                @input="scheduleDateMenu = false"
              />
            </v-menu>
            <!-- SCHEDULE HOUR -->
            <u-select v-model="scheduleHoursUi" :items="scheduleHoursOptions" style="max-width: 90px" />
            <!-- SCHEDULE MINUTE -->
            <u-select v-model="scheduleMinutes" :items="scheduleMinutesOptions" style="max-width: 90px" />
            <!-- SCHEDULE AM/PM -->
            <u-select v-if="use12" v-model="scheduleAmPm" :items="['AM', 'PM']" style="max-width: 90px" />
            <v-spacer class="flex-grow-1" />
            <u-btn :disabled="!valid" :small="false" @click="setSchedule()">
              {{ $t('set_schedule') }}
            </u-btn>
          </div>
          <div v-if="!valid" class="error--text mt-2">{{ $t('past_reboot_time') }}</div>
        </v-card-text>
      </v-card>

      <div class="text-caption text--secondary">
        <v-icon dense class="mr-2">mdi-information</v-icon>{{ $t('reboot_info') }}
      </div>
    </div>
  </u-dialog-complex>
</template>

<script>
  import api from '@/plugins/ut/ut-api'

  // reebot types known by backend
  const REBOOT_TYPE = Object.freeze({
    NOW: 'rebootNow',
    SCHEDULED: 'rebootAt',
  })

  // date format options used by locale date time strings
  const format = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }

  // method to pad hours/minutes with '0' in front if less than 10
  const pad = number => number.toString().padStart(2, '0')

  // hours/minutes options used for ui selectors
  const hours12Options = [...Array(12).keys()].map(n => ({ text: n + 1, value: n + 1 }))
  const hours24Options = [...Array(24).keys()].map(n => ({ text: pad(n), value: n }))
  const minutesOptions = [...Array(60).keys()].map(n => ({ text: pad(n), value: n }))

  export default {
    props: {
      // boolean to show/hide dialog
      value: {
        type: Boolean,
        default: false,
      },
      appliance: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        applianceDateTime: undefined, // ISO date string from appliance without offset, e.g. `2024-12-03 06:48:22`
        applianceTimezone: undefined, // appliance timezone, e.g. `EST`

        scheduledReboot: undefined, // existing scheduled reboot with { jobId, rebootTime } entries

        scheduleDate: undefined, // new schedule date for calendar
        scheduleDateMenu: false, // new schedule calendar menu selector show/hide
        scheduleHoursUi: 0, // new schedule hour for ui selector
        scheduleMinutes: 0, // new schedule minutes
        scheduleAmPm: 'AM', // AM/PM for 12h time

        fetching: false,
      }
    },
    computed: {
      // returns appliance UID
      uid: ({ appliance }) => appliance.Uid,

      // returns the locale in use, e.g. `us`, `de`, `ja`
      locale: ({ $vuntangle }) => $vuntangle.locale,

      // returns true if time uses AM/PM, e.g. for `en`, false for `ja`, `de`
      use12: ({ locale }) => Intl.DateTimeFormat(locale, { hour: 'numeric' }).resolvedOptions().hour12,

      // hours and minutes used in time selectors
      scheduleHoursOptions: ({ use12 }) => (use12 ? hours12Options : hours24Options),
      scheduleMinutesOptions: () => minutesOptions,

      // returns computed hours based on hour selector and AM/PM, e.g. `7`, `22`
      scheduleHours: ({ use12, scheduleHoursUi, scheduleAmPm }) => {
        if (!use12) return scheduleHoursUi
        if (scheduleAmPm === 'PM' && scheduleHoursUi < 12) return scheduleHoursUi + 12
        if (scheduleAmPm === 'AM' && scheduleHoursUi === 12) return 0
        return scheduleHoursUi
      },

      // returns the min schedule date to be used in calendar selector, e.g. from `2024-12-03 06:48:13` to `2024-12-03`
      minRebootDate: ({ applianceDateTime }) => applianceDateTime?.split(' ')[0],

      // returns scheduled time based on hours/minutes, e.g. '04:35:00', '17:20:00'
      scheduleTime: ({ scheduleHours, scheduleMinutes }) => `${pad(scheduleHours)}:${pad(scheduleMinutes)}:00`,

      // returns locale formated existing scheduled reboot date string
      formattedScheduleDate: ({ locale, scheduleDate }) => new Date(scheduleDate).toLocaleDateString(locale, format),

      // returns appliance date object based on date time string
      applianceDateTimeObject: ({ applianceDateTime }) => (applianceDateTime ? new Date(applianceDateTime) : null),

      // returns new schedule date object based on scheduled date and time
      scheduleDateTimeObject: ({ scheduleDate, scheduleTime }) => new Date(`${scheduleDate}T${scheduleTime}`),

      // returns appliance date time string, locale formatted
      applianceLocaleDateTime: ({ locale, applianceDateTimeObject, applianceTimezone }) => {
        if (!applianceDateTimeObject) return
        return `${applianceDateTimeObject.toLocaleDateString(locale, format)}
          ${applianceDateTimeObject.toLocaleTimeString(locale)} ${applianceTimezone}`
      },

      // returns existing scheduled reboot date time string, locale formatted
      scheduledRebootLocaleDateTime: ({ locale, scheduledReboot, applianceTimezone }) => {
        if (!scheduledReboot) return
        const date = new Date(scheduledReboot.scheduledTime)
        return `${date.toLocaleDateString(locale, format)} ${date.toLocaleTimeString(locale)} ${applianceTimezone}`
      },

      // returns true if new reboot schedule is in the appliance time future, false otherwise
      valid: ({ applianceDateTimeObject, scheduleDateTimeObject }) =>
        applianceDateTimeObject.getTime() < scheduleDateTimeObject.getTime(),

      // dialog show/hide
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
    },

    watch: {
      show(val) {
        // reset values
        this.applianceDateTime = undefined
        this.applianceTimezone = undefined
        this.scheduledReboot = undefined

        if (val) {
          // fetch data on dialog show
          this.prepareData()
        }
      },
    },

    methods: {
      prepareData() {
        this.getApplianceDateTime(true)
        this.getApplianceSchedule()
      },

      /**
       * Fetch appliance date/time and initialize schedule fields based on that
       * @param setScheduleFields - boolean
       */
      async getApplianceDateTime(setScheduleFields) {
        this.fetching = true
        const response = await api.cloud('Untangle_CommandCenter', 'GetApplianceDateTime', {
          uid: this.uid,
          paramOrder: 'uid',
        })

        if (response.success && response.data) {
          this.applianceDateTime = response.data.dateTime
          this.applianceTimezone = response.data.timezone
        }
        this.fetching = false

        if (setScheduleFields) this.setScheduleFields()
      },

      /**
       * Fetches appliance scheduled reboots and uses the first scheduled reboot entry, always will be just one
       */
      async getApplianceSchedule() {
        this.fetching = true
        const response = await api.cloud('Untangle_CommandCenter', 'GetApplianceAtQueue', {
          uid: this.uid,
          paramOrder: 'uid',
        })
        if (response.success && response.data) {
          this.scheduledReboot = response.data[0]
        }
        this.fetching = false
      },

      /**
       * Deletes existing scheduled reboot from appliance
       */
      async deleteSchedule() {
        this.fetching = true
        const response = await api.cloud('Untangle_CommandCenter', 'DeleteAtQueueEntries', {
          uid: this.uid,
          jobIds: [this.scheduledReboot.jobId],
          paramOrder: 'uid jobIds',
        })
        if (response.success) {
          this.scheduledReboot = undefined
        } else {
          this.$vuntangle.toast.add(this.$t('command_failure'), 'error')
        }
        this.fetching = false
      },

      // Sets the scheduled reboot time on the appliance
      async setSchedule() {
        if (!this.scheduleDate || !this.scheduleTime) return

        // refetches the appliance date time to make sure that scheduled reboot is not in the past
        await this.getApplianceDateTime(false)
        if (!this.valid) return

        this.fetching = true
        const response = await api.cloud('Untangle_CommandCenter', 'RebootAppliance', {
          uid: this.appliance.Uid,
          rebootType: REBOOT_TYPE.SCHEDULED,
          rebootDate: this.scheduleDate,
          rebootTime: this.scheduleTime,
          paramOrder: 'uid rebootType rebootDate rebootTime',
        })
        this.fetching = true

        if (response.success) {
          // on success it fetches the scheduled reboots to update the UI
          await this.getApplianceSchedule()
        } else {
          this.$vuntangle.toast.add(this.$t('command_failure'), 'error')
        }
      },

      // Reboots the appliance instantly
      async rebootNow() {
        this.fetching = true
        const response = await api.cloud('Untangle_CommandCenter', 'RebootAppliance', {
          uid: this.appliance.Uid,
          rebootType: REBOOT_TYPE.NOW,
          rebootDate: null,
          rebootTime: null,
          paramOrder: 'uid rebootType rebootDate rebootTime',
        })

        if (response.success && response.data) {
          this.$vuntangle.toast.add(this.$t('reboot_now_successful'))
        } else {
          this.$vuntangle.toast.add(this.$t('command_failure'), 'error')
        }
        this.fetching = false
        // close dialog
        this.show = false
      },

      // set initial scheduled fields (date, hours, minutes) based on appliance date time (on dialog open)
      setScheduleFields() {
        if (!this.applianceDateTime) return

        // the scheduled fields date/time are set initially to the nearest hour in the future
        const rounded = new Date(this.applianceDateTime)
        rounded.setHours(rounded.getHours() + 1)
        rounded.setMinutes(0, 0, 0)

        const h = rounded.getHours()

        // sets the hours UI field based on 12/24 hours display
        if (this.use12) {
          if (h === 0) {
            this.scheduleHoursUi = 12
          } else if (h > 12) {
            this.scheduleHoursUi = h - 12
          } else {
            this.scheduleHoursUi = h
          }
        } else {
          this.scheduleHoursUi = h
        }

        this.scheduleMinutes = rounded.getMinutes()
        this.scheduleAmPm = rounded.getHours() >= 12 ? 'PM' : 'AM'
        this.scheduleDate = `${rounded.getFullYear()}-${pad(rounded.getMonth() + 1)}-${pad(rounded.getDate())}`
      },
    },
  }
</script>
