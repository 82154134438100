<template>
  <u-dialog-complex
    v-model="show"
    :width="600"
    :title="$t('update_options')"
    :show-progress="fetching"
    :submit-label="$t('set_schedule')"
    @cancel="show = false"
    @submit="setSchedule"
  >
    <div>
      <span class="text--secondary"> {{ $t('current_server_datetime') }}:</span>
      {{ applianceDisplay || $t('update_no_datetime') }}
    </div>
    <div>
      <span class="text--secondary"> {{ $t('update_scheduled') }}:</span>
      {{ applianceCurrentScheduledUpgrade || $t('update_none_scheduled') }}
    </div>

    <br />

    <div class="text--secondary">{{ $t('update_picker_info') }}</div>

    <div class="d-flex flex-row mt-4">
      <u-select v-model="day" class="mr-2" :label="$t('update_day_of_week')" :items="dayOfWeek"></u-select>

      <v-menu
        ref="timeMenu"
        v-model="timeMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            append-icon="mdi-clock-outline"
            readonly
            outlined
            dense
            hide-details
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="timeMenu"
          v-model="time"
          no-title
          @click:minute="$refs.timeMenu.save(time)"
        ></v-time-picker>
      </v-menu>
    </div>

    <template #extraActions>
      <u-btn color="aristaDarkGray white--text" @click="upgradeNow">{{ $t('update_now') }}</u-btn>
    </template>
  </u-dialog-complex>
</template>

<script>
  import api from '@/plugins/ut/ut-api'
  import vuntangle from '@/plugins/vuntangle'
  export default {
    props: {
      // boolean to show/hide dialog
      value: {
        type: Boolean,
        default: false,
      },
      appliance: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        time: '00:00',
        timeMenu: false,
        day: '6',

        applianceDisplay: null,
        applianceCurrentScheduledUpgrade: null,
        dayOfWeek: [
          { text: this.$t('sunday'), value: '0' },
          { text: this.$t('monday'), value: '1' },
          { text: this.$t('tuesday'), value: '2' },
          { text: this.$t('wednesday'), value: '3' },
          { text: this.$t('thursday'), value: '4' },
          { text: this.$t('friday'), value: '5' },
          { text: this.$t('saturday'), value: '6' },
        ],

        fetching: false, // used for fetching appliance datetime/schedule and saving information
      }
    },
    computed: {
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
    },
    watch: {
      show(val) {
        if (val) {
          // fetch appliance upgrade settings on dialog display
          this.prepareUpgradeData()
        }
      },
    },
    methods: {
      onCancel() {
        this.show = false
      },

      /**
       * Upon load the following three methods load time and upgrade schedule to display. Split into multiple methods
       * so async methods to gather date and schedule can run in parallel
       */
      async prepareUpgradeData() {
        this.fetching = true
        await Promise.allSettled([this.prepareUpgradeSchedule(), this.prepareDateTime()])
        this.fetching = false
      },

      async prepareDateTime() {
        const dateTimeResponse = await api.cloud('Untangle_CommandCenter', 'GetApplianceDateTime', {
          uid: this.appliance.Uid,
          paramOrder: 'uid',
        })
        if (dateTimeResponse.success) {
          this.applianceDisplay = dateTimeResponse.data.applianceDisplay
        }
      },
      async prepareUpgradeSchedule() {
        this.applianceCurrentScheduledUpgrade = null // reset to null
        const scheduledUpgradeResponse = await api.cloud('Untangle_CommandCenter', 'GetUpgradeSettings', {
          uid: this.appliance.Uid,
          paramOrder: 'uid',
        })

        // format and set the date time from cron numbers to readable format
        if (scheduledUpgradeResponse.data?.success) {
          const time =
            scheduledUpgradeResponse.data.cron.hourOfDay.padStart(2, '0') +
            ':' +
            scheduledUpgradeResponse.data.cron.minuteOfHour.padStart(2, '0')
          const dayReceived =
            this.appliance.ProductLine === 'NGFW'
              ? scheduledUpgradeResponse.data.cron.dayOfWeek - 1
              : scheduledUpgradeResponse.data.cron.dayOfWeek
          this.applianceCurrentScheduledUpgrade =
            this.dayOfWeek.find(day => day.value === dayReceived.toString()).text + ' ' + time
          this.day = dayReceived.toString()
          this.time = time
        }
      },

      /**
       * Calls command center with a request to update the appliance now
       */
      async upgradeNow() {
        this.fetching = true
        const response = await api.cloud('Untangle_CommandCenter', 'UpdateAppliance', {
          uid: this.appliance.Uid,
          paramOrder: 'uid',
        })

        // handle response data
        if (response.data?.success) {
          vuntangle.toast.add(this.$t('command_executed_successfully'))
        } else if (response.data?.message) {
          vuntangle.toast.add(
            this.$te(response.data.message) ? this.$t(response.data.message) : response.data.message,
            'error',
          )
        } else {
          vuntangle.toast.add(this.$t('update_error'), 'error')
        }

        this.show = false
        this.fetching = false
      },

      /**
       * changes the upgrade schedule by editing the setting.json file on the appliance. The time values to be passed
       * in are crontab values of dayOfWeek hour and minute. dayOfMonth and month are not used
       */
      async setSchedule() {
        this.fetching = true
        const response = await api.cloud('Untangle_CommandCenter', 'SetUpgradeSettings', {
          uid: this.appliance.Uid,
          day: this.day,
          hour: this.time.substr(0, 2),
          minute: this.time.substr(3, 2),
          paramOrder: 'uid day hour minute',
        })

        // handle response messages
        if (response.data?.success) {
          vuntangle.toast.add(this.$t('command_executed_successfully'))
        } else if (response.data?.message) {
          vuntangle.toast.add(
            this.$te(response.data.message) ? this.$t(response.data.message) : response.data.message,
            'error',
          )
        } else {
          vuntangle.toast.add(this.$t('update_error'), 'error')
        }

        this.show = false
        this.fetching = false
      },
    },
  }
</script>
