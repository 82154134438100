<!--
  template shown as a dialog component when there are dependencies for the objects to be removed
  see ./util `deleteObjects` method
-->
<template>
  <div v-if="dependencies">
    <div class="mb-2">{{ $t('item_dependant_items') }}</div>
    <div v-for="item in prettyDependencies" :key="item.deletedObject.Id">
      <div>{{ item.deletedObject.Name }}</div>
      <ul>
        <li v-for="dependency in item.objectDependencies" :key="dependency.id">
          {{ dependency.name }}
          (<b> {{ $t(dependency.type) }} </b>)
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import { getObjectLabelByType } from '../util'

  export default {
    props: {
      dependencies: { type: Object, default: undefined },
    },
    data() {
      return {
        prettyDependencies: [],
      }
    },
    watch: {
      dependencies: {
        handler(dependencies) {
          this.mapDependenciesForDisplay(dependencies)
        },
        immediate: true,
      },
    },
    methods: {
      action() {},
      /**
       * maps passed dependencies into prettified list for display
       *
       * @param {Object} dependencies impacted dependents
       */
      async mapDependenciesForDisplay(dependencies) {
        await this.checkAndFetchAppliances(dependencies)
        this.prettyDependencies = Object.keys(dependencies).map(objId => {
          const subDependencies = dependencies[objId].map(dependency => {
            // extract the label for that specific mfwType
            let name = dependency.Name
            if (dependency.IsAppliance) {
              const appliance = this.$store.getters['appliances/getByUid'](dependency.Id)
              name = `${appliance.Hostname} / ${appliance.ApplianceTag}`
            }
            return {
              id: dependency.Id,
              name,
              type: dependency.IsAppliance ? 'appliance' : getObjectLabelByType(dependency.Type),
            }
          })

          return {
            deletedObject: this.$store.getters['policyManager/getObjectById'](objId),
            objectDependencies: subDependencies,
          }
        })
      },

      /**
       * fetches appliances if the delete response contains an appliance UID
       * @param {Object} dependencies impacted dependents
       */
      async checkAndFetchAppliances(dependencies) {
        const shouldFetch = Object.values(dependencies).some(dependents =>
          dependents.some(({ IsAppliance }) => IsAppliance),
        )
        if (shouldFetch) {
          await this.$store.dispatch('appliances/fetchAppliances')
        }
      },
    },
  }
</script>
