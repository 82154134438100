/**
 * This plugin contains functions that return information about the currently logged-in user
 */
import store from '@/store'

const users = {
  /**
   * Returns whether the current user has the access
   *
   * @param {string} feature - the feature that the user is attempting to access ('*', 'account' or 'appliances')
   * @param {string} accessLevel - requested access level ('full' or 'read')
   *
   * @returns {boolean}- whether the user has that access based upon their account role
   *
   * Below are current available role permissions:
   *
   * - admin: { appliances: 'full' }
   * - admin-account-full: { *: 'full' }
   * - admin-account-read: { account: 'read', appliances: 'full' }
   * - primary: { *: 'full' }
   * - user-account-full: { account: 'full' }
   * - user-account-read: { account: 'read' }
   * - user (!!! not returned in ccViewModel)
   *
   * Based on SelectedUserAccountSettings.Role the user might have
   * full, read or no permissions on appliances and account
   */
  userHasAccess(feature, accessLevel) {
    const ccViewModel = store.state.data.ccViewModel

    // user must be logged in in a specific account
    if (!ccViewModel?.SelectedUserAccountSettings) return false

    const role = ccViewModel.UserRolePermissions[ccViewModel.SelectedUserAccountSettings.Role]

    if (!role) return false // in case of Role = 'user'

    if (role['*'] === 'full') {
      return true
    }

    return role[feature] === accessLevel || role[feature] === 'full'
  },

  /**
   * Get the organization sections the user has access to.
   *
   * @returns {string[]}
   */
  getOrganizationSections() {
    if (!this.hasFullAccountAccess() && !this.hasReadAccountAccess()) {
      return []
    }

    let sections = null
    const ccViewModel = store.state.data.ccViewModel
    const hasVouchers = ccViewModel.Account?.VouchersAllowed
    const isPrimary = ccViewModel.SelectedUserAccountSettings?.Role === 'primary'
    const isPartner = store.getters['data/isUserPartnerForAccount']
    const hasApiAccess = store.state.data.sapphireOptions.ApiAccess

    if (isPrimary || this.hasFullAccountAccess())
      sections = ['address', 'billing', 'subscriptions', 'invoices', 'users', 'settings', 'integrations']
    else if (this.hasReadAccountAccess()) sections = ['billing', 'subscriptions', 'invoices']
    else sections = []

    if (hasApiAccess && (isPrimary || this.hasFullAccountAccess())) {
      sections.push('api-token')
    }

    if (ccViewModel.SapphireOptions.SSO) sections.push('sso')
    if (ccViewModel.SapphireOptions.Tasks) sections.push('tasks')

    if (this.hasFullAccountAccess() || this.hasReadAccountAccess()) {
      let start = isPrimary ? 2 : 1
      if (isPartner) {
        sections.splice(start, 0, 'partner-info')
      }

      if (hasVouchers) {
        start = isPrimary ? (isPartner ? 4 : 3) : isPartner ? 3 : 2
        sections.splice(start, 0, 'vouchers')
      }
    }
    return sections
  },

  /**
   * Check if the alerts tab is enabled for the user.
   *
   * @returns {boolean}
   */
  isAlertsTabEnabled() {
    return store.state.data.ccViewModel?.SelectedUserAccountSettings
  },

  /**
   * Check if the networks navigation should be enabled for a user
   *
   * @returns {boolean}
   */
  isNetworksTabEnabled() {
    return this.userHasAccess('appliances', 'full') && store.state.data.ccViewModel?.SelectedUserAccountSettings
  },

  /**
   * Check if user has full account access, used to enable or disable account management
   *
   * @returns {boolean}
   */
  hasFullAccountAccess() {
    return this.userHasAccess('account', 'full')
  },

  /**
   * Check if user has read account access
   *
   * @returns {boolean}
   */
  hasReadAccountAccess() {
    return this.userHasAccess('account', 'read')
  },
}

export default users
