import {
  Type,
  ActionType,
  formatters,
  filterConditionItems,
  conditionType,
  shortConditionType,
  equalsConditionValue,
  actionValue,
} from 'vuntangle/pm'

import { countryCodes } from 'vuntangle/constants'

import store from '@/store'
import util from '@/plugins/ut/ut-util'
import i18n from '@/plugins/vue-i18n'

/**
 * Computes the grid display of a rule action as expected by the `action` grid column definition
 * {
 *    text: "Some conf name", // the text
 *    id: '1234-5678...', // optional, e.g. the id of a configurations/wan policy
 *    icon: 'mdi-something...', // the prepend icon font
 *    iconColor: 'green', // optional, an icon color
 * }
 * @param {Object} action - the rule action
 * @returns Object
 */
function ruleActionValue(action) {
  let configuration
  if (action.type === ActionType.WanPolicy || action.type === ActionType.Configuration) {
    const object = store.getters['policyManager/getObjectById'](action.configuration_id || action.policy)
    configuration = {
      name: object?.Name,
      id: object?.Id,
    }
  }

  return actionValue(action, configuration)
}

/**
 * Computes the grid display of appliances associated with a policy for `appliances` grid column field
 * [
 *   {
 *     uid: "1234-5678...", // the appliance unique identifier
 *     host: "mfw.example.com", // the appliance hostname
 *   }
 *   ...
 * ]
 * @param {String} policyId - the policy id
 * @returns Array
 */
function associatedAppliancesValue(policyId) {
  const allAssignments = store.getters['policyManager/getApplianceAssignment']

  return allAssignments
    .filter(assignment => assignment.object_id === policyId)
    .map(assignment => assignment.appliance_id)
    .map(uid => ({ uid, host: util.uidToHostname(uid) }))
}

/**
 * Computes the appliances associated with a rule
 * [
 *   {
 *     uid: "1234-5678...", // the appliance unique identifier
 *     host: "mfw.example.com", // the appliance hostname
 *   }
 *   ...
 * ]
 * @param {String} ruleId - the rule id
 * @returns Array
 */
function associatedRuleAppliancesValue(ruleId) {
  // extract policies which are associated with given rule
  const policiesIds = associatedPolicyValue(ruleId)?.map(policy => policy.id)

  let appliances = []
  policiesIds.forEach(id => {
    // get associated appliances with the policies ids extracted above
    appliances = [...appliances, ...associatedAppliancesValue(id)]
  })
  return appliances
}

/**
 * Computes the grid display of policies associated with a rule/condition/template for `policies` grid column field
 * [
 *   {
 *     id: "1234-5678...", // the policy id
 *     name: "mfw.example.com", // the policy name
 *   }
 *   ...
 * ]
 * @param {Object} id - the rule/condition/template id
 * @returns Array
 */
function associatedPolicyValue(id) {
  const policyIds = store.getters['policyManager/getPolicyIdsByDependencyId'](id)
  const policies = store.getters['policyManager/getObjectsByIds'](policyIds)
  return policies.map(policy => ({ id: policy.Id, name: policy.Name })) || []
}

/**
 * Computes an object value to be represented inside grids as tooltip or `value` field for Objects listings
 * @param {Object} object - the etm Object
 * @returns String
 */
function objectValue(object) {
  const items = object.PolicyJson.items
  if (object.Type.includes('group')) {
    return object.PolicyJson.items.map(id => inflateObject(id))
  } else {
    switch (object.Type) {
      case Type.ObjectGeoip:
        return items.map(code => countryCodes[code]).join(', ')
      case Type.ObjectApplication:
        return items
          .map(item => `${i18n.t('port_number')}: ${item.port} - ${i18n.t('ip_addresses')}: ${item.ips.join(',')}`)
          .join(', ')
      case Type.ObjectService:
        return items
          .map(item => `${i18n.t('port_number')}: ${item.port} - ${i18n.t('protocol')}: ${item.protocol.join(',')}`)
          .join(', ')
      default:
        return items.join(', ')
    }
  }
}

/**
 * Computes the tooltip of an object or object group used in conditions columns
 * @param {Object} object - etm object
 * @returns String
 */
function getTooltip(object) {
  if (object.Type.includes('group')) {
    return object.PolicyJson.items
      .map(id => inflateObject(id))
      .map(item => `${item.name} (${item.tooltip})`)
      .join(', ')
  }
  if (object.Type === Type.ObjectCondition) {
    return formatters.conditionsFormatter({ value: conditionItemsValue(object.PolicyJson.items) })
  }
  return objectValue(object)
}

/**
 * Returns an object ready for grid display in the form
 * {
 *   name: "The object name",
 *   isGroup: true // bool
 *   tooltip: "Computed tooltip for the object"
 * }
 * @param {String} id - object id
 * @returns
 */
function inflateObject(id) {
  const object = store.getters['policyManager/getObjectById'](id)

  return {
    name: object?.Name,
    isGroup: object?.Type.includes('group') || false,
    tooltip: object ? getTooltip(object) : '',
  }
}

/**
 * Extracts SOURCE/DEST/OTHER condition items from one or multiple conditions passed by ids
 * corresponding to grid `source`/`dest`/`other` fields
 * Outputs:
 * [
 *   {
 *     type: "Source Address", // the translated condition type
 *     shortType: "Address" // the short translated condition type (without source/dest prefix)
 *     op: "Match" // the translated condition operator
 *     value: "1.2.3.4" // the condition item value in case of equal operators
 *     objects: [ // array of condition objects computed for grid display in case of Match/In operators
 *       { name: "Object name", isGroup: true, tooltip: "Object tooltip/value" }
 *       ...
 *     ]
 *   }
 * ]
 * @param {Array} ids - array of condition objects ids
 * @param {String} target - the items target type: SOURCE/DEST/OTHER/GROUP
 * @returns Array
 */
function conditionsValue(ids, target) {
  const conditions = store.getters['policyManager/getObjectsByTypeIds'](Type.ObjectCondition, ids)
  const items = conditions.map(cond => cond.PolicyJson.items).flat()
  return conditionItemsValue(items, target)
}

/**
 * Returns the conditions groups value for display inside grid
 * @param {Array} ids - array of condition objects ids
 * @returns Array
 */
function conditionGroupsValue(ids) {
  const groups = store.getters['policyManager/getObjectsByTypeIds'](Type.ObjectConditionGroup, ids)
  if (!groups?.length) return ''

  const value = []
  groups.forEach(group => value.push(objectValue(group)))
  return value
}

/**
 * Helper method which does the value computation from condition items
 * @param {Array} items - array of condition items
 * @param {String} target - the items target type: SOURCE/DEST/OTHER
 * @returns Array
 */
function conditionItemsValue(items, target) {
  return filterConditionItems(items, target).map(item => ({
    type: conditionType(item.type),
    shortType: shortConditionType(item.type, target), // type without Source/Dest prefix
    op: i18n.t(item.op),
    ...(item.value && { value: equalsConditionValue(item) }),
    ...(item.object && { objects: item.object?.map(id => inflateObject(id)) }),
  }))
}

export {
  ruleActionValue,
  objectValue,
  associatedAppliancesValue,
  associatedRuleAppliancesValue,
  associatedPolicyValue,
  conditionsValue,
  conditionGroupsValue,
  conditionItemsValue,
}
