// base functions and mfw policies object for CRUD operations on MFW policies

import cloneDeep from 'lodash/cloneDeep'
import api from '@/plugins/ut/ut-api'
import store from '@/store'

/**
 * mfw policies navigation categories
 */
export const mfwPoliciesNavCategories = [
  {
    tkey: 'policy_manager',
    cat: 'policy_manager',
    to: { name: 'mfw-policies-type', params: { category: 'policy-manager' } },
    icon: 'mdi-file-document-multiple',
    subItems: [],
  },
]

/**
 * mfw policies object
 *
 * key: the policy type stored in cloud services
 * category: category in which the object appears in drop down menu
 * policyCategory: the type of policy, rule, service, etc.
 * version: lowest version compatible with policies
 * tkey: translation key
 * bkey: backend store settings key
 * routePath: page route to navigate to
 * defaultRules: component for the default rules used in mfw
 * settingPathForBox: path in the box to save rules to
 */
export const mfwPolicies = {
  'mfw-policy-manager-policies': {
    category: 'policy_manager',
    componentType: 'service',
    version: 5.0,
    tkey: 'policies',
    bkey: 'PolicyManager',
    routePath: 'policy-manager-policies',
    defaultRules: [],
    settingPathForBox: 'policy_manager',
    securityLicenseRequired: false,
  },
  'mfw-policy-manager-objects': {
    category: 'policy_manager',
    componentType: 'service',
    version: 5.0,
    tkey: 'objects',
    bkey: 'PolicyManager',
    routePath: 'policy-manager-objects',
    defaultRules: [],
    settingPathForBox: 'policy_manager',
    securityLicenseRequired: false,
  },
  'mfw-policy-manager-flows': {
    category: 'policy_manager',
    componentType: 'service',
    version: 5.0,
    tkey: 'flows',
    bkey: 'PolicyManager',
    routePath: 'policy-manager-flows',
    defaultRules: [],
    settingPathForBox: 'policy_manager',
    securityLicenseRequired: false,
  },
}

/**
 * looks through each policy to match routePath and returns the key of the policy
 *
 * @param routePath
 * @returns policyKey
 */
export function getKeyFromRoutePath(routePath) {
  return Object.keys(mfwPolicies).find(key => mfwPolicies[key].routePath === routePath)
}

/**
 * builds the navigation items object using the mfwPoliciesNavCategories and mfwPolicies object above
 *
 * @returns mfwPoliciesNavItems object containing navigation information for all policies in the mfw policies bar
 */
export function getPoliciesNavItems() {
  // make a deep copy of mfwPoliciesNavCategories so we don't actually change mfwPoliciesNavCategories itself
  const mfwPoliciesNavItems = cloneDeep(mfwPoliciesNavCategories)
  // add all policies into the subitems of the corresponding category
  Object.entries(mfwPolicies).forEach(policy => {
    const networkObj = mfwPoliciesNavItems.find(obj => obj.cat === policy[1].category)
    if (networkObj && store.state.data.mfwPoliciesOptions[policy[1].bkey]) {
      networkObj.subItems.push({
        tkey: policy[1].tkey,
        bkey: policy[1].bkey,
        to: {
          name: 'mfw-policies-types',
          params: { policyPage: policy[1].routePath },
        },
      })
    }
  })
  // if there are no subitems in a category do not include that category
  return mfwPoliciesNavItems.filter(category => category.subItems.length !== 0)
}

/**
 * Create a new rule with settingObj by sending to the backend.
 *
 * @param updatedPolicy
 * @returns {Promise<*>}
 */
export function createPolicy(updatedPolicy) {
  return api.cloud('Untangle_CommandCenter', 'CreatePolicy', {
    name: updatedPolicy.name,
    description: updatedPolicy.description,
    type: updatedPolicy.type,
    policyJson: JSON.stringify(updatedPolicy),
    paramOrder: 'name description type version policyJson',
  })
}

/**
 * Update a rule with settingObj by sending to the backend.
 *
 * @param updatedPolicy
 * @returns {Promise<*>}
 */
export function updatePolicy(updatedPolicy) {
  return api.cloud('Untangle_CommandCenter', 'UpdatePolicy', {
    policyId: updatedPolicy.policyId,
    name: updatedPolicy.name,
    description: updatedPolicy.description,
    type: updatedPolicy.type,
    policyJson: JSON.stringify(updatedPolicy),
    paramOrder: 'policyId name description type policyJson',
  })
}

/**
 * single method for add/update crud op
 */
export function savePolicy(cloudPolicy, policyType) {
  const requestObj = {
    name: cloudPolicy.Name,
    description: cloudPolicy.Description,
    type: policyType,
    policyJson: JSON.stringify(cloudPolicy.PolicyJson),
    paramOrder: 'name description type version policyJson',
  }

  let action = 'CreatePolicy'
  if (cloudPolicy.Id) {
    action = 'UpdatePolicy'
    requestObj.policyId = cloudPolicy.Id
    requestObj.paramOrder = 'policyId name description policyJson'
  }

  return api.cloud('Untangle_CommandCenter', action, requestObj)
}

/**
 * Format array of object to be compatible with Vuntangle (ex: ETM:policy->Name; Vuntangle:policy->name)
 *
 * @param entities
 * @returns {*[]}
 */
export function formatForVuntangle(entities) {
  const formattedEntities = []
  if (!entities) return
  entities.forEach(entity => {
    formattedEntities.push(
      Object.fromEntries(
        Object.entries(entity).map(([k, v]) => {
          return k === 'Id' ? ['policyId', v] : [k.toLowerCase(), v]
        }),
      ),
    )
  })
  return formattedEntities
}

/**
 * Get rule's details based on policy id
 *
 * @param policyId
 * @returns {Promise<*>}
 */
export function getPolicy(policyId) {
  return api.cloud('Untangle_CommandCenter', 'GetPolicy', {
    policyId,
    paramOrder: 'policyId',
  })
}

/**
 * check if appliance is compatible with policy type. Appliance must be mfw, meet the minimum required software
 * version 5.1, be licensed for command center
 */
export function getFilteredAppliances(applianceList) {
  const filteredAppliances = []

  for (const appliance of applianceList) {
    if (
      appliance.ProductLine === 'MFW' &&
      appliance.IsLicensedForCommandCenter &&
      parseFloat(appliance.SoftwareVersion) >= 5.1
    ) {
      filteredAppliances.push(appliance)
    }
  }

  return filteredAppliances
}
