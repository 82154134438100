import api from '@/plugins/ut/ut-api'
import vuntangle from '@/plugins/vuntangle'
import grids from '@/plugins/ut/ut-grids'

/**
 * Handle common functionality between NFGW and MFW policies
 */
export default {
  props: {
    /**
     * string defining the policy type (or app) (e.g. phish-blocker)
     * this must match with the route /policies/ngfw/category/type
     * e.g. /policies/ngfw/protect/spam-blocker
     * or for mfw: /policies/mfw/type
     * eg. /policies/mfw/web-filter
     */
    type: { type: String, required: true },
  },
  data() {
    return {
      pendingAction: false,
      existingPolicies: [],

      // data relating to selecting policies for pushing or deleting
      existingPoliciesSelectedRows: [],
      deletePolicyDialog: false,
      rulesToDelete: [],
      deletePolicyMessage: '',
    }
  },
  computed: {
    // base columns when showing existing policies
    basePoliciesColumnDefs() {
      return [
        {
          headerName: this.$t('name'),
          field: 'Name',
        },

        {
          headerName: this.$t('description'),
          field: 'Description',
        },
        {
          headerName: this.$t('date_created'),
          field: 'DateCreated',
          valueFormatter: ({ value }) => this.$vuntangle.dates.formatDateFromApi(value),
          comparator: (a, b) => this.$vuntangle.dates.compareDates(a, b),
        },
      ]
    },
    // appliance columns to display when pushing a policy
    basePolicyAppliancesColumnDefs: ({ $store }) =>
      grids.getPolicyApplianceColumnDefs($store.state.data.ccViewModel.Account.NoLicenseEnforcement ? ['license'] : []),
  },
  /**
   * Fetch all policies and load the store if coming directly to this page.
   *
   * @return {void}
   */
  created() {
    this.fetchExistingPolicies()
    this.$store.dispatch('appliances/fetchAppliances')
  },
  methods: {
    /**
     * Fetch the existing policies using api (not using stores)
     * data is "volatile" meaning it will be refetched when coming back to this view
     * called upon gridrefresh event which fires on grid initialization or Refresh button action
     * TODO - investigate ways to "cache" the view so the data is not refetched every time
     */
    async fetchExistingPolicies() {
      this.pendingAction = true

      const response = await api.cloud('Untangle_CommandCenter', 'GetPolicies', {
        type: this.type,
        paramOrder: 'type',
      })

      if (response.success) {
        this.existingPolicies = response.data
      }

      this.pendingAction = false
    },

    /**
     * Delete selected policies.
     *
     * @return {void}
     */
    async deletePolicy() {
      let deletePolicies = this.existingPoliciesSelectedRows.map(({ Id, Name, Type }) => {
        return { Id, Name, Type }
      })

      // In case of deleting MFW Wan Policies, we have to delete Wan Rules associated to those policies
      if (this.rulesToDelete.length > 0) {
        deletePolicies = deletePolicies.concat(this.rulesToDelete)
      }

      const response = await api.cloud('Untangle_CommandCenter', 'DeleteObjectsIfUnused', {
        policies: JSON.stringify(deletePolicies),
        paramOrder: 'policies',
      })

      this.cleanUpSelectedRows()
      if (response.success) {
        // show failed to delete toast if there are dependencies
        if (!response.data?.deleted) {
          vuntangle.toast.add(this.$t('policy_group_dependency'), 'error')
        } else {
          // get policies
          await this.fetchExistingPolicies()
          vuntangle.toast.add(this.$t('policy_delete_success'))
        }
      } else {
        vuntangle.toast.add(this.$t('policy_delete_failure'), 'error')
      }
      this.deletePolicyDialog = false
    },
    /**
     * Display confirmation dialog when deleting a policy
     * @returns {Promise<void>}
     */
    displayDeletePolicyDialog() {
      this.$store.commit('SET_PAGE_LOADER', true)
      this.deletePolicyMessage = this.$t('confirm_delete_policy')
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Policies',
        eventAction: 'Delete Policy',
        eventLabel: this.type,
      })
      this.$store.commit('SET_PAGE_LOADER', false)
      this.deletePolicyDialog = true
    },
    cleanUpSelectedRows() {
      this.createPolicySelectedRows = []
      this.pushPolicySelectedRows = []
      this.existingPoliciesSelectedRows = []
    },
    /**
     * Fetch the existing wan policies and then filter them. Result should contain only those wan rules associated to
     * selected wan policies.
     */
    async getWanRulesForWanPolicies() {
      this.rulesToDelete = []
      const wanPoliciesToDelete = this.existingPoliciesSelectedRows.map(({ Id }) => {
        return Id
      })
      const response = await api.cloud('Untangle_CommandCenter', 'GetWanRulesForWanPolicies', {
        wanPoliciesToDelete,
        paramOrder: 'wanPoliciesToDelete',
      })

      if (response.success) {
        for (const policyId in response.data) {
          this.rulesToDelete.push({ 'Id': response.data[policyId].ruleId, 'Name': response.data[policyId].name })
        }
        // remove duplicates
        this.rulesToDelete = [...new Set(this.rulesToDelete)]
      }
    },
  },
}
