/**
 * components management/importing
 */
import Vue from 'vue'
import VUntangle, {
  UAlert,
  UAutocomplete,
  UBtn,
  UChart,
  UChartOverlay,
  UChartStock,
  UCheckbox,
  UDialog,
  UDialogComplex,
  UErrorsTooltip,
  UFrameworkDialog,
  UFrameworkToast,
  UGmap,
  UGrid,
  UNavList,
  UPage,
  USection,
  USectionTitle,
  USelect,
  UTextArea,
  UTextField,
  UWidget,
} from 'vuntangle'
import UtMarketingSlides from './UtMarketingSlides'
import UtCountryStateSelector from './UtCountryStateSelector'

VUntangle.registerComponents({
  UAlert,
  UAutocomplete,
  UBtn,
  UCheckbox,
  UDialog,
  UDialogComplex,
  UFrameworkDialog,
  UFrameworkToast,
  UGmap,
  UChart,
  UChartOverlay,
  UChartStock,
  UErrorsTooltip,
  UGrid,
  UNavList,
  UPage,
  USection,
  USectionTitle,
  USelect,
  UTextArea,
  UTextField,
  UWidget,
})

Vue.component('UtMarketingSlides', UtMarketingSlides)
Vue.component('UtCountryStateSelector', UtCountryStateSelector)
