<template>
  <div class="flex-grow-1 mb-4">
    <grid-layout
      :layout.sync="baseLayout"
      :responsive-layouts.sync="responsiveLayouts"
      :row-height="320"
      :margin="[16, 16]"
      :use-css-transforms="false"
      :is-resizable="false"
      :is-draggable="false"
      responsive
      :breakpoints="$vuntangle.widgets.breakpoints"
      :cols="$vuntangle.widgets.gridSizes"
    >
      <grid-item
        v-for="widget in baseLayout"
        :key="widget.i"
        :x="widget.x"
        :y="widget.y"
        :w="widget.w"
        :h="widget.h"
        :i="widget.i"
        drag-allow-from=".drag-handle"
        @resized="onResizedWidget(widget.i)"
        @moved="storeLayout"
      >
        <!--
          props:
          appliance
          appliances - needed for ApplianceMap
          uids - needed for rt of the widgets (like in dashboard)
          events:
          @appliance-location - for ApplianceMap
        -->
        <component
          :is="widget.cmp"
          :appliance="appliance"
          :appliances="[appliance]"
          :uids="enabledUids"
          :resize.sync="widget.resize"
          @remove="onRemove(widget.id)"
          @appliance-location="showLocationDialog = true"
          @add-license="showAddLicenseDialog = true"
        />
      </grid-item>
    </grid-layout>
    <ut-location-dialog v-model="showLocationDialog" :appliance="appliance" />
    <ut-subs-dialog v-model="showAddLicenseDialog" :appliance="appliance" />
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { GridItem, GridLayout } from 'vue-grid-layout'

  import {
    AlertHistory,
    ApplianceMap,
    CloudBackup,
    DetectedIntrusions,
    LinkInformation,
    RecentHosts,
    ThreatHistory,
    TopApplications,
    TopDomains,
    TopHostNames,
    TotalBandwidth,
    WidgetsOverviewMixin,
  } from '@/components/widgets'

  import AppliancePolicies from '@/components/widgets/AppliancePolicies'
  import { UtApplianceModel, UtApplianceSubscription, UtApplianceSummary, UtResources } from '@/components/appliances'
  import UtLocationDialog from '@/components/appliances/UtLocationDialog'

  import { commonWidgetMapComponents } from '@/plugins/ut/ut-widgets'
  import UtSubsDialog from '@/components/appliances/UtSubsDialog.vue'
  const widgetComponentMap = {
    ...commonWidgetMapComponents,
    info: { component: 'UtApplianceSummary', width: 1 },
    alerts: { component: 'AlertHistory', width: 2 },
    appliance_licenses: { component: 'UtApplianceSubscription', width: 2 },
    appliance_model: { component: 'UtApplianceModel', width: 1 },
    blocked_threats: { component: 'ThreatHistory', width: 2 },
    cloud_backups: { component: 'CloudBackup', width: 1 },
    link_information: { component: 'LinkInformation', width: 2 },
    location: { component: 'ApplianceMap', width: 1 },
    recent_hosts: { component: 'RecentHosts', width: 2 },
    resources: { component: 'UtResources', width: 1 },
    appliance_policies: { component: 'AppliancePolicies', width: 2 },
  }

  export default {
    components: {
      UtSubsDialog,
      GridLayout,
      GridItem,

      UtApplianceSummary,
      UtApplianceModel,
      UtApplianceSubscription,
      UtLocationDialog,
      ApplianceMap,
      UtResources,
      RecentHosts,
      AlertHistory,
      ThreatHistory,
      TotalBandwidth,
      TopApplications,
      TopDomains,
      TopHostNames,
      DetectedIntrusions,
      CloudBackup,
      LinkInformation,
      AppliancePolicies,
    },
    mixins: [WidgetsOverviewMixin],
    data() {
      return {
        showLocationDialog: false,
        showAddLicenseDialog: false,
      }
    },

    computed: {
      appliance: ({ $store, $route }) => {
        return $store.getters['appliances/getByUniqueIdentifier']($route.params.id)
      },
      enabledUids() {
        // important to avoid widget watching uids to fire multiple times
        return [this.appliance.Uid]
      },
    },

    created() {
      // settings for WidgetsOverviewMixin
      this.widgetComponentMap = widgetComponentMap

      this.storedLayoutName = `${this.appliance.ProductLine}-appliance-layout`
      this.updateLayoutPath = 'data/UPDATE_APPLIANCE_WIDGETS'
      this.getWidgetsPath = 'data/applianceWidgets'
      this.setLayout()
    },

    methods: {
      /**
       * Overwrites setLayout() in WidgetsOverviewMixin
       */
      setLayout() {
        // make sure appliance was found
        if (!this.appliance) {
          return
        }

        // check if a saved layout exists, is so use that on the dashboard
        const storedLayout = this.$vuntangle.widgets.getStoredLayout(this.storedLayoutName)
        if (storedLayout) {
          this.responsiveLayouts = storedLayout
          this.baseLayout = cloneDeep(this.responsiveLayouts[this.$vuntangle.widgets.getBreakpoint()])
          return
        }

        const enabledWidgets = ['info']
        let productLineWidgets = []

        // get the widgets based on appliance properties
        if (this.appliance.Uid) {
          if (!this.appliance.ProductLine) {
            productLineWidgets = ['location']
          } else if (this.appliance.ProductLine === 'NGFW') {
            productLineWidgets = [
              'alerts',
              'appliance_model',
              'blocked_threats',
              'cloud_backups',
              'detected_intrusions',
              'location',
              'recent_hosts',
              'resources',
              'top_applications',
              'top_domains',
              'top_hostnames',
              'total_bandwidth',
            ]
          } else if (this.appliance.ProductLine === 'MFW') {
            productLineWidgets = [
              'alerts',
              'appliance_policies',
              'appliance_model',
              'cloud_backups',
              'link_information',
              'location',
              'resources',
            ]
          }
          // hide license widget if the account doesn't need to have it
          if (!this.$store.state.data.ccViewModel.Account.NoLicenseEnforcement) {
            productLineWidgets.push('appliance_licenses')
          }
        }

        Object.keys(this.widgets).forEach(key => {
          if (this.widgets[key] && productLineWidgets.includes(key)) {
            enabledWidgets.push(key)
          }
        })
        this.responsiveLayouts = this.$vuntangle.widgets.getLayout(enabledWidgets, widgetComponentMap)
        this.baseLayout = cloneDeep(this.responsiveLayouts[this.$vuntangle.widgets.getBreakpoint()])

        //  store the grid so it does not have to recompute
        this.storeLayout()
      },
    },
  }
</script>
