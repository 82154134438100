<!--
  Route Component editing Rule
-->
<template>
  <div v-if="rule" class="d-flex flex-column flex-grow-1 pa-4">
    <breadcrumbs>
      <template #actions>
        <v-btn depressed class="text-capitalize mr-2" color="transparent" @click="onCancel">
          {{ $t('cancel') }}
        </v-btn>
        <u-btn
          v-if="allowDelete"
          :small="false"
          color="error"
          data-testid="delete-pm-entity"
          depressed
          class="text-capitalize mr-2"
          @click="onDeleteObject(rule)"
        >
          <v-icon small class="mr-2">mdi-delete</v-icon> {{ $t('delete') }}
        </u-btn>
        <v-btn :disabled="!isChanged" depressed class="text-capitalize" color="primary" @click="onSave(true)">
          <v-icon small class="mr-2">mdi-content-save</v-icon> {{ $t('save') }}
        </v-btn>
      </template>
    </breadcrumbs>

    <ValidationObserver ref="obs" tag="div" class="d-flex flex-column flex-grow-1">
      <!-- the name description component -->
      <name-description
        :name.sync="rule.Name"
        :description.sync="rule.Description"
        :name-label="$t('rule_name')"
        :description-label="$t('rule_description')"
      />

      <div class="my-4">
        <span class="font-weight-bold mr-2">{{ $t('conditions_text') }}</span>
      </div>
      <edit-condition-objects
        ref="conditionsEditor"
        :ids.sync="rule.PolicyJson.conditions"
        :object-id="rule.Id"
        :is-clone="isClone"
      />

      <!-- the action component specific to the rule type -->
      <a-rule-action
        :action.sync="rule.PolicyJson.action"
        :rule-type="rule.Type"
        :configurations="configurations"
        class="mt-2"
        @edit-configuration="onEditConfiguration"
        @create-configuration="onCreateConfiguration"
      />
    </ValidationObserver>
    <!-- cancel and save button -->
    <div></div>
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import isEqual from 'lodash/isEqual'
  import { mapGetters, mapActions } from 'vuex'
  import { Type, ActionType, rulesConfig } from 'vuntangle/pm'
  import NameDescription from '../components/NameDescription.vue'
  import ARuleAction from '../components/rule-action/ARuleAction.vue'
  import { generateDefaultMFWObject } from '../util'
  import EditConditionObjects from '../components/EditConditionObjects.vue'
  import Breadcrumbs from './Breadcrumbs.vue'
  import editorMixin from './editorMixin'

  import store from '@/store'
  import i18n from '@/plugins/vue-i18n'

  export default {
    components: { EditConditionObjects, Breadcrumbs, NameDescription, ARuleAction },
    mixins: [editorMixin],
    /**
     * Provide conditions stats down to `ConditionsEditor` component which updates the
     * `conditionsStats.changed` prop based on modifications made
     * This permits checking if one or multiple rule conditions objects have changed values
     */
    provide() {
      return {
        $conditionsStats: () => this.conditionsStats,
        $ruleType: () => this.rule?.Type,
      }
    },
    beforeRouteLeave(to, from, next) {
      // only show dialog if the rule was changed and policy not saved
      if (!this.isChanged || this.ignoreChanges) {
        next()
        return
      }
      const vm = this
      this.$vuntangle.confirm.show({
        title: this.$t('save_rule_changes'),
        message: this.$t('save_rule_changes_text'),
        buttons: [
          // cancel button - stay on page
          {
            name: this.$t('cancel'),
            props: {
              minWidth: null,
              small: false,
              text: true,
              depressed: true,
              class: 'text-capitalize',
            },
            handler() {
              this.onClose()
            },
          },
          // discard button, leave page
          {
            name: this.$t('discard'),
            props: {
              minWidth: null,
              small: false,
              color: 'error',
              depressed: true,
              class: 'text-capitalize px-4',
            },
            handler() {
              this.onClose()
              next()
            },
          },
          // save button, saves changes and leaves page after
          {
            name: this.$t('save'),
            props: {
              minWidth: null,
              small: false,
              depressed: true,
              class: 'text-capitalize px-4',
            },
            async handler() {
              this.onClose()
              const success = await vm.onSave(false)
              if (success) next()
            },
          },
        ],
      })
    },

    async beforeRouteEnter(to, from, next) {
      store.commit('SET_PAGE_LOADER', true)
      await store.dispatch('policyManager/fetchObjectsByPrefix', { prefix: 'mfw-rule' })
      store.dispatch('policyManager/fetchObjectsByPrefix', { prefix: 'mfw-config' })
      store.commit('SET_PAGE_LOADER', false)

      next(({ $store, $vuntangle, $router }) => {
        const policyId = to.params.policyId
        if (policyId) return
        const ruleId = to.params.ruleId
        const foundRule = $store.getters['policyManager/getEditObjectById'](ruleId)
        if (!foundRule) {
          $vuntangle.toast.add(i18n.t('no_record_found'), 'error')
          $router.push({ name: 'pm-rules' })
        }
      })
    },

    data() {
      return {
        rule: undefined,
        ignoreChanges: false,
        conditionsStats: {
          changed: false,
        },
      }
    },

    computed: {
      ...mapGetters('policyManager', ['getObjectById']),
      // rule configuration based on rule Type
      config: ({ rule }) => rulesConfig[rule.Type],
      // the rule conditions based on the type and id's associated with it from `conditions`
      conditions: ({ $store, rule }) => [
        ...$store.getters['policyManager/getObjectsByTypeIds'](Type.ObjectCondition, rule.PolicyJson.conditions),
        ...$store.getters['policyManager/getObjectsByTypeIds'](Type.ObjectConditionGroup, rule.PolicyJson.conditions),
      ],
      fetchingConditions: ({ $store }) =>
        [Type.ObjectCondition, Type.ObjectConditionGroup].some(type => $store.getters['policyManager/fetching'](type)),
      // gets available configurations for actions used for services (TP, WF, ...)
      configurations: ({ $store, config }) => $store.getters['policyManager/getObjectsByType'](config.templateType),
      // shows the Create Config button for services
      showAddConfiguration: ({ rule }) =>
        [
          Type.RuleApplicationControl,
          Type.RuleDns,
          Type.RuleGeoipFilter,
          Type.RuleThreatPrevention,
          Type.RuleWebFilter,
          Type.RuleCaptivePortal,
        ].includes(rule.Type),

      // shows the Create Wan Policy button for Wan Policy rules
      showAddWanPolicy: ({ rule }) => Type.RuleWanPolicy === rule.Type,
      /**
       * Upon rule order manually changed, it enables/disables the Update button
       */
      isChanged({ rule, editRule, conditionsStats }) {
        if (!rule) return false
        if (!isEqual(rule, editRule) || conditionsStats.changed) return true
        return false
      },
      /**
       * flag to determine if delete operation is allowed
       * only allowed if we are editing a rule directly,
       * and not when viewing a rule which is attached to a policy
       */
      allowDelete: ({ ruleId, policyId }) => !ruleId.startsWith('mfw-') && !policyId,
      isClone: ({ $route }) => $route.params?.isClone ?? false,
    },

    watch: {
      editRule: {
        handler(editRule) {
          if (!editRule) return
          // populates the rule with temporary `editRule` (see _editorsMixin)
          this.rule = cloneDeep(this.editRule)
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {
      // prepare the listener for if the user exits the window
      window.addEventListener('beforeunload', this.beforeUnload)
      /**
       * for services (WF, TP etc.), DNS the action is set as { type: 'SET_CONFIGURATION', configuration_id: <guid> }
       * for wan rules, the action is set as { type: 'WAN_POLICY', policy: <guid> }
       * so it is needed to fetch that configuration object corresponding to the action
       * the mongo Type `templateType` is set in the configuration of the rule
       * so it dispatches the action to fetch those configurations types
       */
      const actionType = this.rule?.PolicyJson.action?.type
      if (actionType === ActionType.Configuration || actionType === ActionType.WanPolicy) {
        this.$store.dispatch('policyManager/fetchObjectsByType', { type: this.config.templateType })
      }
    },

    beforeUnload() {
      // stop showing the dialog when user cancels or confirms leaving the page
      window.removeEventListener('beforeunload', this.beforeUnload)
    },

    methods: {
      ...mapActions('policyManager', ['editConfigurationDialog']),

      /** shows browser native confirmation dialog when refreshing the page */
      beforeUnload(event) {
        // check if rule was changed and not inside edit policy (edit policy handles this on its own)
        if (this.isChanged && !this.editPolicy) {
          // both preventDefault() and returnValue = '' are responsible for showing the popup when closing the window
          // depending on which browser is being used
          event.preventDefault()
          event.returnValue = ''
        }
      },

      /** edit configuration dialog */
      onEditConfiguration() {
        let confId
        if (this.rule.Type === Type.RuleWanPolicy) {
          confId = this.rule.PolicyJson.action.policy
        } else {
          confId = this.rule.PolicyJson.action.configuration_id
        }

        const configuration = this.getObjectById(confId)
        if (!configuration) return
        this.editConfigurationDialog({
          configuration,
        })
      },

      /** create configuration dialog */
      onCreateConfiguration() {
        const newConfiguration = generateDefaultMFWObject(this.config.templateType)

        newConfiguration.Id = 'create'

        this.editConfigurationDialog({
          configuration: newConfiguration,
          cb: createdConfiguration => {
            if (this.rule.Type === Type.RuleWanPolicy) {
              this.rule.PolicyJson.action.policy = createdConfiguration.Id
            } else {
              this.rule.PolicyJson.action.configuration_id = createdConfiguration.Id
            }
          },
        })
      },

      onCancel() {
        this.ignoreChanges = true
        this.goBack()
      },

      /**
       * Dispatches the action to save the rule object into cloud
       * by passing the rule that has been edited via editor
       *
       */
      async onSave(goBack = false) {
        const isValid = await this.$refs.obs.validate()

        if (!isValid) return false

        this.$store.commit('SET_PAGE_LOADER', true)

        // save condition objects if changed
        if (this.$refs.conditionsEditor.isChanged) {
          const result = await this.$refs.conditionsEditor.saveConditionObjects()
          if (!result) {
            this.$vuntangle.toast.add(this.$t('unable_to_save', [this.$t('global.rule')]), 'error')
            return false
          }
          this.rule.PolicyJson.conditions = result.map(object => object.Id)
        }

        if (this.rule.Id.startsWith('mfw-')) this.rule.Id = ''
        const savedRule = await this.$store.dispatch('policyManager/saveObject', { object: this.rule })
        this.$store.commit('SET_PAGE_LOADER', false)

        if (savedRule) {
          this.$vuntangle.toast.add(this.$t('saved_successfully', [this.$t('rule')]))

          if (this.editPolicy) {
            // save rule to policy
            const policy = cloneDeep(this.editPolicy)

            if (!policy.PolicyJson?.rules[savedRule.Type]) {
              policy.PolicyJson.rules[savedRule.Type] = []
            }

            if (!policy.PolicyJson?.rules[savedRule.Type].includes(savedRule.Id)) {
              policy.PolicyJson?.rules[savedRule.Type].push(savedRule.Id)
            }
            this.$store.commit('policyManager/SET_EDIT_OBJECT', policy)
          }
          if (this.editRule) {
            this.$store.commit('policyManager/SET_EDIT_OBJECT', savedRule)
          }

          this.ignoreChanges = true
          if (goBack) this.goBack()
          return true
        } else {
          this.$vuntangle.toast.add(this.$t('unable_to_save', [this.$t('global.rule')]), 'error')
          return false
        }
      },
    },
  }
</script>
