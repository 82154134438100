<template>
  <u-widget
    :title="$t('cloud_backups')"
    :info="$t('info_cloud_backups')"
    enable-refresh
    @refresh="fetchCloudBackups"
    v-on="$listeners"
  >
    <u-grid
      v-if="backups.length"
      id="cloud-backups"
      selection-type="singleAction"
      :no-data-message="$t('no_data')"
      :column-defs="columnDefs"
      :fetching="backupsFetching"
      :row-data="backups"
      :selection.sync="backupRowsSelected"
      :resize-columns.sync="resizeEvent"
      @refresh="fetchCloudBackups"
    />
    <template v-if="backups.length === 0">
      <u-alert>
        <span v-if="appliance.ProductLine === 'NGFW'" v-html="$t('no_backups_available')"></span>
        <span v-if="appliance.ProductLine === 'MFW'" v-html="$t('no_mfw_backups_available')"></span>
      </u-alert>
      <v-spacer />
    </template>

    <v-divider />

    <v-card-actions>
      <u-btn @click="showCreateBackupDialog = true">
        {{ $t('create_backup') }}
      </u-btn>
      <u-btn :disabled="backupRowsSelected.length !== 1" @click="showRestoreBackupDialog = true">
        {{ $t('restore_backup') }}
      </u-btn>
    </v-card-actions>

    <u-dialog
      :show-dialog="showCreateBackupDialog"
      :title="$t('create_backup')"
      :message="$t('cloud_backups_create_confirmation')"
      :buttons="[
        { name: $t('cancel') },
        {
          name: $t('yes'),
          handler: 'createBackup',
          showProgress: true,
        },
      ]"
      @close-dialog="showCreateBackupDialog = false"
      @createBackup="createBackup"
    />
    <u-dialog
      :show-dialog="showRestoreBackupDialog"
      :title="$t('cloud_backups_confirmation_title')"
      :buttons="[
        { name: $t('no') },
        {
          name: $t('yes'),
          handler: 'restoreBackup',
          showProgress: true,
        },
      ]"
      @close-dialog="showRestoreBackupDialog = false"
      @restoreBackup="restoreBackup"
    >
      <div v-html="$t('cloud_backups_confirmation')" />
    </u-dialog>
  </u-widget>
</template>
<script>
  import grids from '@/plugins/ut/ut-grids'
  import vuntangle from '@/plugins/vuntangle'
  import appliances from '@/plugins/ut/ut-appliances'
  import api from '@/plugins/ut/ut-api'
  import WidgetMixin from '@/components/widgets/WidgetMixin'

  export default {
    mixins: [WidgetMixin],
    props: {
      appliance: {
        type: Object,
        default() {
          return {}
        },
      },
    },
    data() {
      return {
        backups: [],
        backupsFetching: true,
        backupRowsSelected: [],
        noBackupsFound: '',
        showCreateBackupDialog: false,
        showRestoreBackupDialog: false,
      }
    },
    computed: {
      columnDefs: () => grids.getCloudBackupsColumnDefs(),
    },
    watch: {
      appliance: {
        immediate: true,
        handler(appliance) {
          if (!appliance || Object.keys(appliance).length === 0) return
          this.fetchCloudBackups()
        },
      },
    },
    methods: {
      /**
       * Initiates process to create a backup of the currently selected appliance
       * @returns {Promise<void>}
       */
      async createBackup() {
        const response = await api.cloud('Untangle_CommandCenter', 'CreateApplianceBackup', {
          uid: this.appliance.Uid,
          paramOrder: 'uid',
        })
        if (response.success && response.data) {
          this.fetchCloudBackups() // refresh grid listing
          vuntangle.toast.add(this.$t('cloud_backups_create_backup_success'))
        } else {
          const message = response.message ? response.message : 'cloud_backups_create_backup_error'
          vuntangle.toast.add(this.$t(message), 'error')
        }
        this.showCreateBackupDialog = false
      },
      /**
       * Requests a restore of the selected backup from the cloud backups grid
       */
      restoreBackup() {
        const backupSelected = this.backupRowsSelected[0]
        const targetAppliances = [this.appliance.Uid]
        vuntangle.toast.add(this.$t('restore_called'))
        this.backupsFetching = true
        appliances.restoreAppliancesFromBackup(
          this.appliance.Uid,
          targetAppliances,
          backupSelected.Name,
          backupSelected.FileMd5,
          false,
          null,
          uid => {
            vuntangle.toast.add(this.$t('sync_success_toast_message', { uid }))
          },
          (uid, message) => {
            vuntangle.toast.add(this.$t('sync_failure_toast_message', { uid, message: this.$t(message) }), 'error')
          },
        )
        this.backupsFetching = false
        this.showRestoreBackupDialog = false
        this.backupRowsSelected = []
      },
      /**
       * Gets a list of all backups currently found for the selected appliances
       * @returns {Promise<void>}
       */
      async fetchCloudBackups() {
        this.backupsFetching = true
        const response = await api.cloud('Untangle_CommandCenter', 'GetApplianceBackupListing', {
          uid: this.appliance.Uid,
          paramOrder: 'uid',
        })
        if (response.success && response.data) {
          this.backups = response.data
        } else {
          this.$vuntangle.toast.add(response.message || this.$t('an_error_occurred'), 'error')
        }
        this.backupsFetching = false
      },
    },
  }
</script>
