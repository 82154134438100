<template>
  <div class="pa-2">
    <div v-for="(conditionObject, index) in groupConditionsObjects" :key="conditionObject.Id">
      <span class="font-weight-bold">{{ conditionObject.Name }}</span>
      ( <readable-condition-items :items="conditionObject.PolicyJson.items" /> )
      <span v-if="index < groupConditionsObjects.length - 1" class="font-weight-bold text-uppercase">
        {{ $t('or') }}
      </span>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ReadableConditionItems from '../components/ReadableConditionItems.vue'

  export default {
    components: { ReadableConditionItems },
    props: {
      conditionObjectGroup: { type: Object, required: true },
    },

    computed: {
      ...mapGetters('policyManager', ['getObjectsByIds']),

      groupConditionsIds: ({ conditionObjectGroup }) => conditionObjectGroup.PolicyJson.items,
      groupConditionsObjects: ({ groupConditionsIds, getObjectsByIds }) => getObjectsByIds(groupConditionsIds),
    },
  }
</script>
