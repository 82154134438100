<!--
  Route Component editing a template configuration
  e.g. App Control, Geo Ip, DNS etc...
-->
<template>
  <div v-if="configuration" class="d-flex flex-column flex-grow-1 pa-4 overflow-auto">
    <breadcrumbs />
    <!-- based on the rule type a specific vuntangle component is used to edit the configuration -->
    <component
      :is="settingsComponent"
      v-if="applications"
      ref="component"
      :show-rules="false"
      :show-custom-rule-tab="false"
      :settings="configuration.PolicyJson"
      :applications="applications"
      :leases="false"
      :features="features"
      classic-view
      class="flex-grow-1 pa-0 ma-0"
    >
      <template #actions="{ newSettings, isDirty, isInvalid }">
        <v-btn text class="text-capitalize mr-2" @click="goBack">
          {{ $t('cancel') }}
        </v-btn>
        <u-btn
          v-if="allowDelete"
          :small="false"
          color="error"
          data-testid="delete-pm-entity"
          text
          class="text-capitalize mr-2"
          @click="onDeleteObject(configuration)"
        >
          <v-icon left>mdi-delete</v-icon> {{ $t('delete_template') }}
        </u-btn>
        <!-- Save is disabled if
         - actual settings (PolicyJson) not modified (isDirty), coming via component slot
         - configuration Name/Description not changed (isChanged)
         - and is not a new configuration (isNew, e.g. Create New, or Duplicate Existing)
         -->
        <v-btn
          :disabled="(!isDirty && !isChanged && !isNew) || isInvalid"
          color="primary"
          class="text-capitalize"
          depressed
          @click="onSaveSetting(newSettings)"
        >
          <v-icon small class="mr-2">mdi-content-save</v-icon>
          {{ $t('save') }}
        </v-btn>
      </template>
      <template #extra-fields>
        <ValidationObserver ref="extraFields">
          <!-- name and description fields -->
          <name-description
            :name.sync="configuration.Name"
            :description.sync="configuration.Description"
            :name-label="$t('template_name')"
            :description-label="$t('template_description')"
          />
        </ValidationObserver>
      </template>
    </component>
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import isEqual from 'lodash/isEqual'

  import {
    SettingsGeoipFilter,
    SettingsNetworkDiscovery,
    SettingsThreatPrevention,
    SettingsWebFilter,
    SettingsWanPolicies,
    StaticRoutes,
    SettingsApplicationControl,
    SettingsDns,
    SettingsCaptivePortal,
    SettingsDhcp,
    AnalyzersList,
    ApplianceUpgrade,
    SettingsDynamicBlockLists,
    SettingsDenialOfService,
    DatabaseList,
  } from 'vuntangle'

  import { templatesConfig } from 'vuntangle/pm'
  import Breadcrumbs from './Breadcrumbs.vue'
  import editorMixin from './editorMixin'
  import NameDescription from '@/pages/policies/mfw/policy-manager/components/NameDescription.vue'
  import i18n from '@/plugins/vue-i18n'

  export default {
    components: {
      SettingsGeoipFilter,
      SettingsNetworkDiscovery,
      SettingsThreatPrevention,
      SettingsWebFilter,
      SettingsWanPolicies,
      StaticRoutes,
      SettingsApplicationControl,
      SettingsDns,
      SettingsDynamicBlockLists,
      SettingsCaptivePortal,
      SettingsDhcp,
      SettingsDenialOfService,
      Breadcrumbs,
      NameDescription,
      AnalyzersList,
      ApplianceUpgrade,
      DatabaseList,
    },
    mixins: [editorMixin],

    beforeRouteEnter(to, from, next) {
      // if the configuration isn't found, return back to the configuration list
      next(({ $store, $vuntangle, $router }) => {
        const configurationId = to.params.configurationId
        const foundObj = $store.getters['policyManager/getEditObjectById'](configurationId)
        if (!foundObj) {
          $vuntangle.toast.add(i18n.t('no_record_found'), 'error')
          $router.push({ name: 'pm-templates' })
        }
      })
    },

    data() {
      return {
        configuration: undefined,
      }
    },

    computed: {
      config: ({ configuration }) => templatesConfig[configuration.Type],
      settingsComponent: ({ config }) => config.settingsComponent,
      applications: ({ $store }) => $store.getters['data/applications'],
      /**
       * flag to determine if delete operation is allowed
       * only allowed if we are editing a configuration directly,
       * and not when viewing a config which is attached to a rule
       */
      allowDelete: ({ configurationId, ruleId }) => !configurationId.startsWith('mfw-') && !ruleId,
      // feature flag to let vuntangle know that setting componnet is being used in template mode
      features: () => ({ isTemplateView: true, hasStaticRouteInterfaceRequired: false }),

      isChanged: ({ configuration, editConfiguration }) => !isEqual(configuration, editConfiguration),

      isNew: ({ configuration }) => configuration.Id === configuration.Type,
    },

    watch: {
      editConfiguration: {
        handler(editConfiguration) {
          if (!editConfiguration) return
          // populates the configuration with temporary `editConfiguration` (see _editorsMixin)
          this.configuration = cloneDeep(this.editConfiguration)
        },
        deep: true,
        immediate: true,
      },
    },

    methods: {
      /**
       * Saves the configuration into mongo
       * @param {Object} settingObj - the edited settings to be saved
       */
      async onSaveSetting(settingObj) {
        // validate name and description fields
        const valid = await this.$refs.extraFields.validate()
        // validate component if has extra validation
        const componentValid = this.$refs.component.validate ? await this.$refs.component.validate() : true
        if (!valid || !componentValid) {
          return
        }

        // populate PolicyJson with the configuration settings
        this.configuration.PolicyJson = settingObj

        // removes temporary created Id `mfw-...` before saving it
        if (this.configuration.Id.startsWith('mfw-')) this.configuration.Id = ''

        // dispatch action to save the template configuration
        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await this.$store.dispatch('policyManager/saveObject', { object: this.configuration })
        this.$store.commit('SET_PAGE_LOADER', false)

        if (response) {
          this.$vuntangle.toast.add(this.$t('saved_successfully', [this.$t('template')]))
          this.goBack()
        } else {
          this.$vuntangle.toast.add(this.$t('unable_to_save', [this.$t('template')]), 'error')
        }
      },
    },
  }
</script>
