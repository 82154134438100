<script>
  export default {
    props: {
      resize: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        resizeEvent: this.resize,
      }
    },
    watch: {
      resize(resize) {
        this.resizeEvent = resize
      },
      resizeEvent(resizeEvent) {
        this.$emit('update:resize', resizeEvent)
      },
    },
  }
</script>
