<!--
  generic rule editing component
  having the RulesList component routes + /ruleId that is being edited
-->
<template>
  <!--
    using vuntangle shared Rule component
    see details about it's props and usage in vuntangle
  -->
  <rule
    ref="rule"
    :title="`${$t(ruleType.replace(/-/g, '_'))} - ${rule ? $t('edit_rule') : $t('add_rule')}`"
    :rule="rule"
    :rule-type="ruleType"
    :remote-data="remoteData"
    :remote-fetching="remoteFetching"
    :classic-view="true"
    :box-settings="boxSettings"
    :features="appliance.features"
    @get-remote-data="onGetRemoteData"
  >
    <template #actions="{ updatedRule }">
      <u-btn class="mr-2" @click="$router.push({ name: 'appliances-rules' })">{{ $t('back_to_list') }}</u-btn>
      <u-btn :min-width="null" @click="onSave(updatedRule)">{{ $t('save') }}</u-btn>
    </template>
  </rule>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { Rule } from 'vuntangle'
  import settingsMixin from './settingsMixin'
  import { mfwServices } from '@/util/mfwServices'
  import vuntangle from '@/plugins/vuntangle'
  import i18n from '@/plugins/vue-i18n'

  export default {
    components: { Rule },
    mixins: [settingsMixin],
    data() {
      /**
       * remoteData holds items needed for some condition types
       * apps - the classify applications needed for app name, id, category
       * policies - for WAN_POLICY actions
       * this are getting populated only on specific event call `onGetRemoteData`
       */
      return {
        remoteData: { apps: null, zoneInterfaces: null, policies: null },
        remoteFetching: false,
      }
    },
    computed: {
      // one of the 5 rule types (4 from firewall tables and 1 from `wan`)
      ruleType: ({ $route }) => $route.params.settings,
      // rule Id from the route
      ruleId: ({ $route }) => $route.params.ruleId,
      settingsPath: ({ ruleType }) => (ruleType === 'wan-rules' ? 'wan' : `firewall/tables/${ruleType}`),
      // settings that are going to be saved to the box
      settings: ({ boxSettings, ruleType }) =>
        ruleType === 'wan-rules' ? boxSettings?.wan : boxSettings?.firewall.tables[ruleType],
      // the chains based on rule type
      chains: ({ boxSettings, ruleType }) => {
        return ruleType === 'wan-rules'
          ? boxSettings?.wan.policy_chains
          : boxSettings?.firewall.tables[ruleType]?.chains
      },
      // the chain based on rule type
      chain: ({ chains, ruleType, ruleId }) =>
        chains?.find(c =>
          ruleId === 'add'
            ? c.name === mfwServices[ruleType].chainName
            : !!c.rules.find(rule => rule.ruleId === ruleId),
        ),
      // rules within the chain
      rules: ({ chain }) => chain?.rules || [],
      // rule to be edited
      rule: ({ rules, $router, settings, ruleId }) => {
        const rule = rules?.find(rule => rule.ruleId === ruleId)
        if (!rule && ruleId !== 'add') {
          vuntangle.toast.add(i18n.t('no_record_found'), 'error')
          $router.push({ name: 'appliances-rules', params: { settingsRule: settings } })
        }
        return rule
      },
    },

    watch: {
      /** watcher used in case for Wan Rules to populate the shared component data with Wan Policies */
      ruleType: {
        handler(type) {
          if (type === 'wan-rules') {
            this.remoteData.policies = this.boxSettings?.wan.policies
          }
        },
        immediate: true,
      },
    },

    methods: {
      /**
       * Populates the remoteData with required info
       * @param {*} dataType - the data type that is required by shared component (`apps`, `zone-interfaces`)
       */
      async onGetRemoteData(dataType) {
        // for the apps case is needed to dispatch action to fetch those
        if (dataType === 'apps') {
          this.remoteFetching = true
          await this.$store.dispatch('data/fetchApplications')
          this.remoteData.apps = this.$store.getters['data/applications']
          this.remoteFetching = false
        }

        // interfaces used for zone specific condition types, filter out the ADDRESSED ones
        if (dataType === 'zone-interfaces') {
          this.remoteData.zoneInterfaces = this.$store.getters['appliances/zoneInterfaces']
        }
      },

      /**
       * Updates existing rule or adds a new one
       * @param {*} updatedRule - the updated rule set via #actions slot props
       */
      async onSave(updatedRule) {
        const isValid = await this.$refs.rule.validate()
        if (!isValid) return

        const settings = cloneDeep(this.settings)
        const chains = settings.chains || settings.policy_chains
        if (!chains) return
        const chain = chains.find(chain => chain.name === this.chain.name)
        if (!chain) return

        if (this.rule) {
          // update rule
          const ruleIndex = chain.rules.findIndex(rule => rule.ruleId === this.$route.params.ruleId)
          chain.rules.splice(ruleIndex, 1, updatedRule)
        } else {
          // add rule
          chain.rules.push(updatedRule)
        }

        const response = await this.saveSettings(this.settingsPath, settings, !this.rule, false, this.chain.name)
        if (response.success) {
          this.populateSettings(updatedRule, response, !this.rule, settings)
          this.$router.push({ name: 'appliances-rules', params: { settingsRule: this.settings } })
        }
      },

      /**
       * populates the new settings in the store after the add/edit request
       *
       * @param {Object} updatedRule the modified rule
       * @param {Objcet} response response for add/edit rule request
       * @param {Boolean} isAdd flag to determine if we added or edited a rule
       * @param {Array} settings new settings
       */
      populateSettings(updatedRule, response, isAdd, ruleSettings) {
        if (isAdd) {
          updatedRule.ruleId = response.data
        }
        this.$store.commit('appliances/SET_RULE_SETTINGS', { ruleType: this.ruleType, ruleSettings })
      },
    },
  }
</script>
