<template>
  <div style="height: 100%; position: relative">
    <!-- slot used to add custom actions at the bottom of the widget -->
    <div v-if="!!$slots['actions']" style="position: absolute; right: 0; bottom: 0; z-index: 1">
      <slot name="actions" />
    </div>
    <u-chart :fetching="fetching" :options="options" />
  </div>
</template>
<script>
  /** highcharts options to display a donut like chart  */
  const defaultOptions = {
    chart: {
      type: 'pie',
      margin: [16, 16, 16, 16],
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'Roboto',
      },
    },
    subtitle: {
      text: null,
      floating: true,
      style: { fontSize: '5em' },
      verticalAlign: 'middle',
    },
    legend: {
      enabled: true,
      align: 'left',
      verticalAlign: 'top',
      layout: 'vertical',
      title: {
        text: null,
        style: { marginBottom: '10px' },
      },
      labelFormatter() {
        return `${this.y} ${this.name}`
      },
    },
    plotOptions: {
      series: {
        borderWidth: 3,
        borderColor: 'transparent',
        colorByPoint: true,
        type: 'pie',
        size: '100%',
        innerSize: '75%',
        dataLabels: {
          enabled: true,
          connectorWidth: 1,
          formatter() {
            return this.y > 0 ? this.point.name : null
          },
        },
        showInLegend: true,
      },
    },
    series: [],
  }

  export default {
    props: {
      series: { type: Array, default: () => [] },
      fetching: { type: Boolean, default: false },
    },

    data() {
      return {
        options: defaultOptions,
      }
    },

    watch: {
      series: {
        handler(series) {
          const options = defaultOptions
          if (!series[0]) return

          // compute total count
          let count = 0
          series[0]?.data?.forEach(el => {
            count += el.y
          })
          // set the count number as subtitle (which is shown in the center of the donut chart)
          options.subtitle.text = count.toString()
          // set legend title as series name
          options.legend.title.text = series[0].name
          options.series = series
        },
        immediate: true,
        deep: true,
      },
    },
  }
</script>
