<template>
  <appliance-logging :log-prop="log" @on-reload="reload" @tab-switched="tabSwitched" />
</template>
<script>
  import { ApplianceLogging } from 'vuntangle'
  import appliance from '@/plugins/ut/ut-appliances'

  export default {
    components: { ApplianceLogging },
    data: () => ({
      log: null,
      activeTab: null,
    }),
    computed: {
      appliance: ({ $store, $route }) => $store.getters['appliances/getByUniqueIdentifier']($route.params.id),
    },
    watch: {
      activeTab: {
        handler(tab) {
          this.reload(tab)
        },
      },
    },
    methods: {
      async reload(type) {
        this.$store.commit('SET_PAGE_LOADER', true)
        if (this.appliance) {
          // type is either 'logread' or 'dmesg'
          const response = await appliance.sendToApplianceApi(this.appliance.Uid, `logging/${type}`, null, 'GET')
          this.log = response ? response?.data.logresults : ''
        }
        this.$store.commit('SET_PAGE_LOADER', false)
      },
      tabSwitched(tab) {
        this.activeTab = tab
      },
    },
  }
</script>
