<template>
  <div v-if="settingsCopy" class="d-flex flex-column fill-height pa-0">
    <h4 class="mb-4">{{ $t('interface_zone') }}</h4>
    <u-grid
      id="policy-manager-interface-zone"
      selection-type="multiAction"
      row-node-id="zone"
      class="fill-height"
      :enable-refresh="false"
      :column-defs="columnDefs"
      :row-data="zoneItems"
      :selection.sync="selectedRows"
      style="height: 400px"
    />
    <v-container class="mt-5 pl-0 ml-0">
      <v-row class="align-center">
        <v-col>
          <ValidationProvider
            ref="custom"
            v-slot="{ errors }"
            :rules="{
              unique_insensitive: { list: allZones, message: $t('unique') },
            }"
          >
            <u-text-field v-model="customZone" :label="$t('custom_interface_zone')" :error-messages="errors">
              <template v-if="errors.length" #append> <u-errors-tooltip :errors="errors" /> </template>
            </u-text-field>
          </ValidationProvider>
        </v-col>
        <v-col>
          <v-btn depressed outlined class="text-capitalize primary--text" @click="onAddCustom">
            <v-icon class="mr-2">mdi-plus</v-icon> {{ $t('add') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <slot name="actions" :new-settings="settingsCopy" :disabled="!selectedRows.length" />
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { interfaceZones } from 'vuntangle/constants'
  import i18n from '@/plugins/vue-i18n'

  export default {
    props: {
      /**
       * the object data (PolicyJson) expected in the form
       *   id: '<id that should match Policy Id>',
       *   name: '<a name>',
       *   items: ['WAN', 'LAN' ...], - array of zones
       *   type: <the type as expected on backend>,
       * */
      settings: { type: Object, default: () => {} },
    },
    data() {
      return {
        // local cloned settings
        settingsCopy: undefined,
        customZone: undefined,
        selectedRows: [],
        // the current available zones options
        zoneItems: interfaceZones.map(item => ({ zone: item })),
      }
    },
    computed: {
      // heading for the DataTable
      headers() {
        return [{ text: i18n.t('available_zones'), value: 'zone' }]
      },
      // list of interface names from the connected boxes
      applianceZoneItems() {
        let result = []
        // get all the MFW appliances for the account
        const mfwAppliances = this.$store.getters['appliances/getMfwAppliances']
        // add the interface names for each appliance, to the hard-coded list
        mfwAppliances?.forEach(app => {
          result = result.concat(app.InterfaceNames.map(name => name.toUpperCase()))
        })
        // return a unique list of interface names
        return [...new Set(result)].map(item => ({ zone: item }))
      },
      allZones({ zoneItems }) {
        return zoneItems.map(({ zone }) => zone)
      },
      columnDefs() {
        return [
          {
            headerName: this.$t('available_zones'),
            field: 'zone',
          },
        ]
      },
    },
    watch: {
      /** Initializes the local settings by cloning the input settings props */
      settings: {
        handler(settings) {
          this.settingsCopy = cloneDeep(settings)
          this.selectedRows = [...this.settingsCopy.items]
        },
        immediate: true,
        deep: true,
      },
      selectedRows: {
        handler(rows) {
          this.settingsCopy.items = rows.map(item => item.zone)
        },
      },
      applianceZoneItems: {
        handler(items) {
          // set zone items to be the existing ones plus the ones from the appliances, and make the result unique
          this.zoneItems = [...new Map([...this.zoneItems, ...items].map(item => [item.zone, item])).values()]
        },
        immediate: true,
      },
    },
    mounted() {
      this.$store.dispatch('appliances/fetchFullAppliances')
      this.populateCustomZones()
    },
    methods: {
      /**
       * Populates custom zones back to grid on Edit
       */
      populateCustomZones() {
        const customZones = this.settingsCopy.items.filter(zone => !this.zoneItems.find(z => z.zone === zone))
        if (customZones.length) {
          const newZones = customZones.map(z => ({ zone: z }))
          this.zoneItems = [...this.zoneItems, ...newZones]
        }
      },
      /**
       * Adds custom zone to the data grid
       */
      async onAddCustom() {
        const zone = this.customZone?.trim()
        if (!zone) {
          this.$refs.custom.applyResult({
            errors: [i18n.t('required')],
            failedRules: {},
          })
          return
        }

        const validStatus = await this.$refs.custom.validate()
        if (validStatus?.valid) {
          const newZone = { zone }
          this.selectedRows.push(newZone)
          this.zoneItems.push(newZone)
          this.customZone = undefined
        }
      },
    },
  }
</script>
