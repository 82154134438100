<template>
  <v-card flat class="ma-0 text--secondary">
    <u-alert>{{ $t('warning') + '!' }}</u-alert>

    <div class="mb-4">
      <p class="ma-0 font-weight-medium">{{ $t('confirm_delete_wan_policy') }}</p>
      <ul>
        <li v-for="rule in affectedRules" :key="rule.ruleID" v-html="rule.description" />
      </ul>
      <br />
      <p class="ma-0 font-weight-medium">{{ $t('confirm_proceed') }}</p>
    </div>
  </v-card>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'
  import settingsMixin from './settingsMixin'

  export default {
    mixins: [settingsMixin],
    props: {
      // index of the policy we want to delete
      deleteIndex: { type: Number, default: () => -1 },
      affectedRules: { type: Array, default: () => [] },
      settings: { type: Object, default: () => null },
    },

    computed: {
      appliance: ({ $store, $route }) => $store.getters['appliances/getByUniqueIdentifier']($route.params.id),
    },

    methods: {
      action() {
        // Here we use settings to update both policies and rules at one time
        const settings = cloneDeep(this.settings)
        // Finds the references of policies and rules in "settings" to update the values
        const policies = settings.policies
        const policyChain = settings.policy_chains.find(chain => chain.name === 'user-wan-rules')
        // Filters out rules for the selected policy
        policyChain.rules = policyChain.rules.filter(rule => rule.action.policy !== policies[this.deleteIndex].policyId)
        // Removes the selected policy
        policies.splice(this.deleteIndex, 1)
        // Push the updated settings to the server
        this.saveSettings('wan', settings)

        this.$emit('close')
      },
    },
  }
</script>
