<template>
  <div class="d-flex" data-testid="conditionValue">
    <!-- HOUR -->
    <u-select v-model="time.hh" :items="hours" solo flat :outlined="false" style="max-width: 100px" />
    <!-- MINUTE -->
    <u-select v-model="time.mm" :items="minutes" solo flat :outlined="false" class="mr-1" style="max-width: 100px" />
    <!-- AM/PM -->
    <u-select v-model="time.a" :items="['AM', 'PM']" solo flat :outlined="false" style="max-width: 100px" />
  </div>
</template>
<script>
  import mixin from './mixin'

  export default {
    mixins: [mixin],

    data() {
      return {
        time: { hh: '', mm: '', a: '' },
      }
    },

    computed: {
      // returns 00-12 hours
      hours: () => [...Array(12).keys()].map(n => (n + 1 < 10 ? `0${n + 1}` : `${n + 1}`)),
      // returns 00-59 minutes
      minutes: () => [...Array(60).keys()].map(n => (n < 10 ? `0${n}` : `${n}`)),
    },

    watch: {
      time: {
        /**
         * Updates condition value on time change
         * @param time
         */
        handler(time) {
          this.condition.value = [`${time.hh}:${time.mm}${time.a}`]
        },
        deep: true,
      },
    },

    mounted() {
      // initialize condition value to midnight if not set
      if (!this.condition.value[0]) {
        this.condition.value = ['12:00AM']
      }

      // update the time fields based on condition value
      const re = new RegExp(/^(0[0-9]|1[0-2]):([0-5]\d)\s?(AM|PM)?$/)
      const matches = this.condition.value[0].match(re)
      this.time.hh = matches[1] || '12'
      this.time.mm = matches[2] || '00'
      this.time.a = matches[3] || 'AM'
    },
  }
</script>
