var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column pa-4 fill-height align-stretch pa-4"},[(_vm.isRule)?_c('u-section',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"title":_vm.$t('select_rules_order')}},[_c('u-alert',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t('rules_order_description')))]),_c('u-grid',{attrs:{"id":"sync-rules-v2","row-node-id":"Id","toolbar":"hidden","enable-refresh":false,"no-data-message":_vm.$t('no_data'),"custom-grid-options":{
        onRowDragEnd: _vm.updateRuleOrder,
      },"column-defs":_vm.syncRulesColumnDefs,"custom-ordering":true,"row-data":_vm.rules,"framework-components":_vm.frameworkComponents}})],1):_vm._e(),_c('u-section',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"title":_vm.$t('please_select_target_appliances')}},[_c('u-grid',{attrs:{"id":"push-policy-appliance-grid","selection-type":"multiAction","no-data-message":_vm.$t('no_data'),"column-defs":_vm.basePolicyAppliancesColumnDefs,"fetching":_vm.$store.state.appliances.fetching,"row-data":_vm.filteredAppliances,"selection":_vm.appliancesSelectedRows,"enable-refresh":false},on:{"update:selection":function($event){_vm.appliancesSelectedRows=$event}}})],1),_c('u-dialog',{attrs:{"show-dialog":_vm.warningDialog,"title":_vm.$t('sync_now'),"message":_vm.$t('sync_policy_warning', { policyType: _vm.settingsName }),"buttons":[
      {
        name: _vm.$t('no'),
      },
      {
        name: _vm.$t('yes'),
        handler: 'sync-policy',
        showProgress: true,
      },
    ]},on:{"close-dialog":function($event){_vm.warningDialog = false},"sync-policy":_vm.pushRules}}),_c('u-dialog',{attrs:{"show-dialog":_vm.pushPolicyNoAppliancesDialog,"title":_vm.$t('push_policy'),"message":_vm.noCompatibleApplianceMessage},on:{"close-dialog":_vm.closeNoAppliancesDialog}}),_c('div',{staticStyle:{"margin-left":"20px"}},[_c('u-alert',{staticClass:"mb-4",attrs:{"warning":""}},[_vm._v(_vm._s(_vm.warningMessage))]),_c('u-btn',{staticClass:"mr-4",attrs:{"plain":"","to":{ name: 'mfw-policies-types' }}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('u-btn',{attrs:{"disabled":_vm.appliancesSelectedRows.length < 1},on:{"click":_vm.displayWarningDialog}},[_vm._v(" "+_vm._s(_vm.$t('sync_now'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }