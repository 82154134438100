<template>
  <u-widget :title="$t('wan_link_information')" :info="$t('info_wan_link_information')" v-on="$listeners">
    <div class="d-flex flex-column fill-height align-stretch">
      <u-grid
        v-if="selectedTab === 'summary'"
        id="LinkInformationGrid"
        class="fill-height"
        :no-data-message="$t('no_data')"
        :column-defs="columnDefs"
        :fetching="linkInformationFetching"
        :row-data="linkInformation"
        :resize-columns.sync="resizeEvent"
        @refresh="fetchData"
      />
      <!--
        for some reason the u-chart-stock has some issues with tooltip formatters
        so it is used the normal u-chart component
      -->
      <u-chart v-else :fetching="graphDataFetching" :options="options" />

      <!-- bottom nav -->
      <v-bottom-navigation v-model="selectedTab" height="40" class="align-center elevation-0 ma-0" color="green">
        <v-btn v-for="(tab, i) in tabs" :key="i" :value="tab.value">
          <span class="font-weight-bold">{{ $t(tab.text) }}</span>
        </v-btn>
      </v-bottom-navigation>
    </div>
  </u-widget>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import merge from 'lodash/merge'
  import api from '@/plugins/ut/ut-api'
  import grids from '@/plugins/ut/ut-grids'
  import reports from '@/plugins/ut/ut-reports'
  import util from '@/plugins/ut/ut-util'
  import WidgetMixin from '@/components/widgets/WidgetMixin'
  import { baseReportOptions } from '@/util/chartOptions'
  import chartTooltipFormatter from '@/util/chartTooltipFormatter'

  export default {
    mixins: [WidgetMixin],
    props: {
      appliance: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        linkInformation: [],
        linkInformationFetching: true,

        graphData: null,
        graphDataFetching: true,

        options: merge(cloneDeep(baseReportOptions), {
          chart: {
            type: 'spline',
            marginTop: 15,
          },
          xAxis: {
            type: 'datetime',
          },
          yAxis: {
            allowDecimals: true,
          },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },
        }),
      }
    },
    computed: {
      tabs() {
        return [
          { text: 'summary', value: 'summary' },
          { text: 'bandwidth', value: 'bandwidth' },
          { text: 'jitter', value: 'jitter' },
          { text: 'latency', value: 'latency' },
          { text: 'packet_loss', value: 'packet_loss' },
        ]
      },
      selectedTab: {
        get() {
          return this.$store.state.linkInformationSelectedTab
        },
        set(value) {
          this.$store.commit('SET_LINK_INFORMATION_SELECTED_TAB', value)
        },
      },
      columnDefs: () => grids.getNetworkPerformanceColumnDefs(),
    },
    watch: {
      appliance: {
        immediate: true,
        handler() {
          this.fetchData()
          // set up data for four line graphs
          this.refreshGraphData()
        },
      },
      selectedTab: {
        immediate: true,
        handler(val) {
          if (val === 'summary') {
            return
          }
          let unit = 'ms'

          if (val === 'bandwidth') {
            unit = 'bytes'
            this.options.tooltip = {
              formatter() {
                return chartTooltipFormatter.getBytesTooltipLineChart(this)
              },
            }
          }
          if (val === 'jitter' || val === 'latency') {
            unit = 'ms'
            this.options.tooltip = {
              formatter() {
                return chartTooltipFormatter.getFixedTooltipLineChart(this, unit)
              },
            }
          }
          if (val === 'packet_loss') {
            unit = '%'
            this.options.tooltip = {
              formatter() {
                return chartTooltipFormatter.getFixedTooltipLineChart(this, unit)
              },
            }
          }
          this.options.yAxis.title.text = unit

          this.processGraphData()
        },
      },
    },
    methods: {
      async fetchData() {
        // retrieve data for summary tab
        const uids = [this.appliance.Uid]
        this.linkInformationFetching = true
        const response = await reports.getReportData(
          'NetworkPerformance',
          uids,
          this.$vuntangle.dates.getDateString(new Date(), -1),
          '',
        )
        const gridData = []
        for (const key in response) {
          // check to make sure that the key is an actual property of the object, and doesn't come from the prototype.
          const row = cloneDeep(response[key])
          // These fields require some customization before sending to grid
          row.ApplianceHostname = util.uidToHostname(row.Uid)
          row.ApplianceTag = util.uidToApplianceTag(row.Uid)
          row.PacketLoss = row.AveragePacketLossSending + row.AveragePacketLossReceiving
          // Push this row onto the grid
          gridData.push(row)
        }
        this.linkInformation = gridData
        this.linkInformationFetching = false
      },
      /**
       * gets last 24 hours of performance data and separates into four possible graphs worth of series data
       */
      async refreshGraphData() {
        this.graphDataFetching = true
        const uids = [this.appliance.Uid]
        const response = await api.cloud('Untangle_CommandCenter', 'GetPerformanceDataByInterface', {
          uids,
          startDate: this.$vuntangle.dates.getDateTimeString(new Date(), -1),
          endDate: this.$vuntangle.dates.getDateTimeString(new Date(), 0),
          paramOrder: 'uids startDate endDate',
        })

        this.graphData = response.data
        this.graphDataFetching = false
        this.processGraphData()
      },

      /**
       * processes the data to be usable in the line graphs, based on selected tab
       */
      processGraphData() {
        if (!this.graphData) {
          return
        }
        const rawData = this.graphData[this.selectedTab]
        const series = []

        if (!rawData) {
          return
        }

        Object.entries(rawData).forEach(([intf, values]) => {
          const data = []
          // expected data looks like { 0: val, 2: val, ... 22: val} where the keys are hours
          Object.entries(values).forEach(([hour, val]) => {
            const date = new Date()
            date.setDate(date.getDate() - 1)
            date.setHours(date.getHours() + parseInt(hour))
            data.push([date.getTime(), val])
          })
          series.push({
            name: intf,
            data,
          })
        })
        // freeze data, don't need reactivity
        this.options.series = Object.freeze(series)
      },
    },
  }
</script>
