<template>
  <div class="d-flex flex-column flex-grow-1">
    <u-select
      v-model="selectedApplianceUid"
      :items="appliancesOptions"
      :label="$t('select_firewall')"
      class="mx-4 mt-4"
    >
      <template #selection="{ item }">
        <span class="d-flex">
          <i class="mdi mdi-circle mr-2" :style="{ color: item.color }"></i> {{ item.text }} {{ item.online }}
        </span>
      </template>

      <template #item="{ item, attrs, on }">
        <v-list-item v-bind="attrs" dense :ripple="false" v-on="on">
          <i class="mdi mdi-circle mr-2" :style="{ color: item.color }"></i> {{ item.text }} {{ item.online }}
        </v-list-item>
      </template>
    </u-select>

    <report-details
      :view-id="reportViewId"
      :appliance-uid="selectedApplianceUid"
      @fetch-data="onFetchData"
      @view-report="onViewReport"
      @report-not-found="onReportNotFound"
    />
  </div>
</template>
<script>
  import { ReportDetails } from 'vuntangle'
  import { getPolicyManagerAppliances } from '../util'
  import appliance from '@/plugins/ut/ut-appliances'
  import vuntangle from '@/plugins/vuntangle'

  export default {
    components: { ReportDetails },
    data() {
      return {
        selectedApplianceUid: undefined,
      }
    },
    computed: {
      reportViewId: ({ $route }) => $route.params.id,
      appliances: () => getPolicyManagerAppliances(),
      appliancesOptions: ({ appliances }) =>
        appliances.map(({ Uid, Hostname, IpAddress, StatusFlags }) => {
          const online = StatusFlags?.[0].Messages?.[0] === 'appliance_status_online'
          return {
            text: `${Hostname} - ${IpAddress}`,
            value: Uid,
            color: online ? '#68bd49' : 'red',
          }
        }),
    },

    // fetch appliances if not already fetched
    async mounted() {
      this.$store.commit('SET_PAGE_LOADER', true)
      await this.$store.dispatch('appliances/fetchFullAppliances')
      this.$store.commit('SET_PAGE_LOADER', false)
    },

    methods: {
      onViewReport(id) {
        this.$router.push({ name: 'pm-report-details', params: { id } })
      },

      /**
       * Fetches the report data from the box having provided uid
       * @param {String} uid - the appliance UID
       * @param {String} report - the report configuration
       * @returns {Array<Object>}
       */
      async getReportData(uid, report) {
        const data = [] // the full data from all chunks

        if (!this.$route.params.id) return []

        // create query
        const queryResponse = await appliance.sendToApplianceApi(uid, 'reports/create_query', report)
        // make sure the create query is successful before trying to get the data with that id
        if (queryResponse.success && queryResponse.data) {
          const queryId = parseInt(queryResponse.data)

          // get data chunks
          let error = false
          while (!error) {
            // if user is no longer on report page, return and close query
            if (!this.$route.params.id) {
              // close query
              await appliance.sendToApplianceApi(uid, `reports/close_query/${queryId}`)
              return []
            }

            const dataResponse = await appliance.sendToApplianceApi(uid, `reports/get_data/${queryId}`, {}, 'GET')
            // errors are sent as a message in the data but success is its own key
            if (dataResponse.data?.error || !dataResponse.success) {
              error = true
            } else if (dataResponse.data) {
              data.push(...dataResponse.data)
            }
          }

          // close query
          await appliance.sendToApplianceApi(uid, `reports/close_query/${queryId}`)
        }
        return data
      },

      /**
       * Handler for fetch event from vuntangle component
       * It builds up the chunk of promises that are fetching the data for a report
       * @param {arg} - the event data from vuntangle component
       * @param {arg.query} - the report query that gets requested
       * @param {arg.applianceUid} - the appliance UID for which report is fetched
       * @param {arg.resolve} - the callback method after the fetching is done
       * @returns {undefined}
       */
      async onFetchData({ query, applianceUid, resolve }) {
        if (!applianceUid) return resolve([])
        const data = await vuntangle.promiseQueue.add(() => this.getReportData(applianceUid, query))
        resolve(data)
      },

      /**
       * Handler for report not found event from vuntangle component
       * @returns {undefined}
       */
      onReportNotFound() {
        this.$vuntangle.toast.add(this.$t('report_not_found'), 'error')
        this.$router.push({ name: 'pm-reports' })
      },
    },
  }
</script>
