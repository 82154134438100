<template>
  <div class="d-flex align-center">
    <u-btn
      icon
      outlined
      :small="false"
      :min-width="null"
      :disabled="!hasPrev"
      @click="updateCurrentIndex(alertIndex - 1)"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </u-btn>
    <div class="my-2 mx-4 flex-grow-1">
      <v-row no-gutters>
        <v-col :cols="4">
          <strong> {{ $t('date_created') }} </strong>
        </v-col>
        <v-col :cols="8">{{ $vuntangle.dates.formatDateFromApi(extraDetails.DateCreated) }}</v-col>
      </v-row>
      <v-row no-gutters>
        <v-col :cols="4"
          ><strong> {{ $t('message') }} </strong></v-col
        >
        <v-col :cols="8">
          <span class="text-left">{{ translateMessage(extraDetails.Message) }}</span></v-col
        >
      </v-row>
      <v-row no-gutters>
        <v-col :cols="4"
          ><strong> {{ $t('device_id') }} </strong></v-col
        >
        <v-col :cols="8"
          ><span class="text-left">{{
            extraDetails.DeviceId ? $options.util.obfuscateUid(extraDetails.DeviceId) : $t('not_assigned')
          }}</span></v-col
        >
      </v-row>
      <v-row no-gutters>
        <v-col :cols="4"
          ><strong> {{ $t('source') }} </strong></v-col
        >
        <v-col :cols="8"
          ><span class="text-left">{{
            extraDetails.Message.includes(connectedString) || extraDetails.Message.includes(disconnectedString)
              ? $t('etm_cloud')
              : extraDetails.SourceIP
          }}</span></v-col
        >
      </v-row>
      <v-row no-gutters>
        <v-col :cols="4"
          ><strong>{{ $t('appliance') }}</strong></v-col
        >
        <v-col :cols="8"
          ><span class="text-left">{{ $options.util.uidToHostname(extraDetails.DeviceId) }}</span></v-col
        >
      </v-row>
      <v-row v-if="formattedDetails.length" no-gutters>
        <v-col :cols="4"
          ><strong> {{ $t('details') }} </strong></v-col
        >
        <v-col :cols="8">
          <span v-for="formattedDetail in formattedDetails" :key="formattedDetail.name">
            <v-row no-gutters>
              <v-col :cols="4" class="text-capitalize">
                <strong>{{ formattedDetail.name }}</strong>
              </v-col>
              <v-col :cols="8">
                <!-- if we have a route on this detail, build a link with it. Else, just display the value -->
                <a v-if="formattedDetail.route" @click="$router.push(formattedDetail.route)"
                  >{{ formattedDetail.value }}
                </a>
                <span v-else class="text-left">{{ formattedDetail.value }}</span>
              </v-col>
            </v-row>
          </span>
        </v-col>
      </v-row>
    </div>
    <u-btn
      icon
      outlined
      :small="false"
      :min-width="null"
      :disabled="!hasNext"
      @click="updateCurrentIndex(alertIndex + 1)"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </u-btn>
    <v-overlay :value="detailsFetching" class="text-center" absolute color="rgba(255, 255, 255, 0.5)">
      <v-progress-circular indeterminate size="32" color="aristaMediumBlue"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
  import startCase from 'lodash/startCase'
  import util from '@/plugins/ut/ut-util'
  import api from '@/plugins/ut/ut-api'
  import store from '@/store'

  export default {
    props: {
      // list of records passed to detail dialog to navigate through
      filteredAlerts: { type: Array, required: true },
      // index of the record being viewed from filteredAlerts
      alertIndex: { type: Number, required: true },
    },
    util,
    data() {
      return {
        formattedDetails: [],
        extraDetails: undefined,
        detailsFetching: false,
        connectedString: 'Appliance Connected',
        disconnectedString: 'Appliance Disconnected',
      }
    },
    computed: {
      hasPrev: ({ alertIndex }) => alertIndex > 0,
      hasNext: ({ filteredAlerts, alertIndex }) => alertIndex + 1 < filteredAlerts.length,
    },
    watch: {
      alertIndex: {
        immediate: true,
        handler(index) {
          if (index > -1) {
            this.getDetails()
          }
        },
      },
    },
    methods: {
      updateCurrentIndex(value) {
        this.$emit('update:alertIndex', value)
      },

      /**
       * Calls backend to get the alert payload as well
       */
      async getDetails() {
        this.extraDetails = this.filteredAlerts[this.alertIndex]
        this.detailsFetching = true
        const response = await api.cloud('Untangle_CommandCenter', 'GetAccountEventDetails', {
          eventId: this.extraDetails._id,
          paramOrder: 'eventId',
        })

        if (response.success && response.data) {
          const policyId = response.data.Payload?.Data?.policyId
          const ruleId = response.data.Payload?.Data?.ruleId
          const promises = []
          if (policyId) {
            promises.push(store.dispatch('policyManager/fetchObjectById', policyId))
          }
          if (ruleId) {
            promises.push(store.dispatch('policyManager/fetchObjectById', ruleId))
          }
          if (promises.length) {
            await Promise.allSettled(promises)
          }
          this.extraDetails = response.data
          this.populateAdditionalAlertDetails()
        }
        this.detailsFetching = false
      },

      /**
       * populates formattedDetails using extraDetails.Payload.Data
       */
      populateAdditionalAlertDetails() {
        const details = this.extraDetails
        this.formattedDetails = []
        // loop through the payload data and format / build display value and route value ( if any )
        for (const property in details.Payload.Data) {
          const val = details.Payload.Data[property] || this.$t('not_found')
          const displayVal = util.formatValueForDisplay(property, val)
          const routeVal = util.formatRouteForDisplay(property, val, details.Payload.Data.table, details.DeviceId)
          const nxtObj = { name: startCase(property), value: displayVal, route: routeVal }
          this.formattedDetails.push(nxtObj)
        }
      },
      translateMessage(message) {
        const messageLower = message.toLowerCase()
        // if there isn't a translation use the obfuscate method
        return this.$te(messageLower) ? this.$t(messageLower) : this.$options.util.obfuscateUidsInString(message)
      },
    },
  }
</script>
<style scoped>
  .col {
    overflow-wrap: anywhere;
  }
</style>
