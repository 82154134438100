<template>
  <u-page :title="$t('create_network')" full-height>
    <div class="d-flex align-center">
      <ValidationObserver ref="obs" class="flex-grow-1">
        <ValidationProvider v-slot="{ errors }" :rules="{ required: true, unique: existingNetworkNames }">
          <u-text-field v-model="networkName" :label="$t('network_name')" :error-messages="errors">
            <template v-if="errors.length" #append>
              <u-errors-tooltip :errors="errors" />
            </template>
          </u-text-field>
        </ValidationProvider>
      </ValidationObserver>
      <u-btn class="ml-4 mr-2" @click="createNetwork">{{ $t('create') }}</u-btn>
      <u-btn text :to="{ name: 'networks' }">
        <span :class="`${$vuetify.theme.dark ? 'white--text' : ''}`">
          {{ $t('cancel') }}
        </span>
      </u-btn>
    </div>

    <div v-if="selectedAppliances.length === 0" class="my-4">
      <v-icon small color="info">mdi-information</v-icon>
      {{ $t('select_one_appliance') }}
    </div>
    <div v-else class="my-4">
      {{ $tc('appliances_selected', selectedAppliances.length) }}
    </div>

    <u-grid
      id="network-create-network-appliances"
      style="height: 400px"
      selection-type="multiAction"
      :no-data-message="$t('no_data')"
      :column-defs="columnDefs"
      :fetching="$store.state.appliances.fetching"
      :row-data="availableAppliances"
      :selection.sync="selectedAppliances"
      @refresh="$store.dispatch('appliances/fetchAppliances', { force: true })"
    />
  </u-page>
</template>
<script>
  import store from '@/store'
  import grids from '@/plugins/ut/ut-grids'
  import appliances from '@/plugins/ut/ut-appliances'

  export default {
    /**
     * makes sure that appliances store is loaded before computing page data (appliance exists)
     * usually when accessing the appliance details page directly on app launch
     */
    async beforeRouteEnter(to, from, next) {
      store.commit('SET_PAGE_LOADER', true)
      await store.dispatch('networks/fetchNetworks')
      await store.dispatch('appliances/fetchAppliances')
      store.commit('SET_PAGE_LOADER', false)
      next()
    },
    data() {
      return {
        networkName: '',
        selectedAppliances: [],
      }
    },
    computed: {
      /**
       * Returns a list of existing network names, so we don't create duplicates
       */
      existingNetworkNames() {
        return store.state.networks.list?.map(network => network.Name) || null
      },

      columnDefs: () =>
        grids.getApplianceGridColumns({}, store.state.data.ccViewModel.Account.NoLicenseEnforcement ? ['license'] : []),
      /**
       * Get available appliances that are not already part of a network.
       */
      availableAppliances() {
        return (
          store.state.appliances.list?.filter(
            appliance => !appliance.NetworkInfo && appliances.isLicensedForVPN(appliance),
          ) || []
        )
      },
    },

    methods: {
      async createNetwork() {
        const uids = this.selectedAppliances.map(appliance => appliance.Uid)
        if (!(await this.$refs.obs.validate()) || !uids.length) {
          return
        }

        store.commit('SET_PAGE_LOADER', true)
        // save the new network
        const network = await this.$store.dispatch('networks/createNetwork', {
          name: this.networkName,
          uids,
        })
        store.commit('SET_PAGE_LOADER', false)

        // redirect to new network
        if (network) {
          this.$router.push({ name: 'networks-network-id', params: { id: network.Id } })
        }
      },
    },
  }
</script>
