import { set } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import api from '@/plugins/ut/ut-api'

const getDefaultState = () => {
  return {
    list: null,
    fetching: false,
  }
}

const getters = {
  list: state => state.list,
  fetching: state => state.fetching,
}

const mutations = {
  RESET: state => Object.assign(state, getDefaultState()),

  SET_NETWORKS: (state, networks) => {
    state.list = networks.sort((a, b) => {
      return a.DateCreated > b.DateCreated
    })
  },
  SET_FETCHING: (state, value) => (state.fetching = value),
  ADD_NETWORK: (state, network) => state.list.push(network),
  UPDATE_NETWORK: (state, network) => {
    const networkIndex = state.list.findIndex(r => r.Id === network.Id)
    set(state.list, networkIndex, network)
  },
  DELETE_NETWORK: (state, network) => {
    const networkIndex = state.list.findIndex(r => r.Id === network.Id)
    state.list.splice(networkIndex, 1)
  },
}

const actions = {
  /**
   * Fetch the network list from the server.
   *
   * @param {Function} commit
   *
   * @return {Promise<void>}
   */
  async fetchNetworks({ state, commit }, options) {
    // do not call the ajax if the call is already fetching or the list exists
    // but a 'force' was not set
    if (state.fetching || (state.list !== null && !options?.force)) {
      return
    }

    commit('SET_FETCHING', true)

    const response = await api.cloud('Untangle_CommandCenter', 'GetNetworks', {
      getFreshInfo: true,
      paramOrder: 'getFreshInfo',
    })

    if (response.success && response.data) {
      commit('SET_NETWORKS', response.data)
    }

    commit('SET_FETCHING', false)
  },

  /**
   * Create a network on the server.  Will return the new network object if
   * successful.
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} network
   *
   * @return {Promise<Object>}  return the newly created network
   */
  async createNetwork({ commit, dispatch }, network) {
    const response = await api.cloud('Untangle_CommandCenter', 'CreateNetwork', {
      name: network.name,
      uids: network.uids,
      paramOrder: 'name uids',
    })

    const newNetwork = response.data
    if (response.success && newNetwork) {
      // DateCreated is not returned from the server
      newNetwork.DateCreated = `/Date(${Date.now()})/`

      commit('ADD_NETWORK', newNetwork)

      // associate appliances to the new network
      dispatch(
        'appliances/updateAppliancesNetworkInfo',
        {
          uids: newNetwork.Uids,
          networkInfo: `${newNetwork.Name}:${newNetwork.Id}`,
        },
        { root: true },
      )

      return newNetwork
    }
  },

  /**
   * Add appliances to a network.
   *
   * @param {Function} dispatch
   * @param {Function} commit
   * @param {Function} rootGetters
   * @param {Object}   network
   * @param {Array}    addUids
   *
   * @return {Boolean}
   */
  async addNetworkAppliances({ dispatch, commit, rootGetters }, { network, addUids }) {
    // get a complete list of the uids after adding the new ones
    const uids = network.Uids.concat(addUids)

    const updatedNetwork = cloneDeep(network)
    updatedNetwork.Uids = uids
    addUids.forEach(element => {
      const addedAppliance = rootGetters['appliances/getByUid'](element)
      updatedNetwork.NetworkAppliances.push({
        'Uid': addedAppliance.Uid,
        'Hostname': addedAppliance.Hostname,
        'IP': addedAppliance.IpAddress,
        'Location': addedAppliance.ApplianceLocation,
        'VpnStatus': false,
        'SharedSubnets': [],
      })
    })

    const response = await api.cloud('Untangle_CommandCenter', 'UpdateNetwork', {
      paramOrder: 'network addedUids removedUids syncToAppliances',
      network: JSON.stringify(updatedNetwork),
      addedUids: addUids,
      removedUids: '',
      syncToAppliances: null,
    })
    if (response.success) {
      // update the network record
      commit('UPDATE_NETWORK', updatedNetwork)
      // add associations between the network and these added appliances
      dispatch(
        'appliances/updateAppliancesNetworkInfo',
        {
          uids: addUids,
          networkInfo: `${network.Name}:${network.Id}`,
        },
        { root: true },
      )
      return true
    }
    return false
  },

  /**
   * Remove appliances from a network.
   *
   * @param {Function} dispatch
   * @param {Function} commit
   * @param {Object}   network
   * @param {Array}    removeUids
   *
   * @return {Boolean}
   */
  async removeNetworkAppliances({ dispatch, commit }, { network, removeUids }) {
    // get a complete list of the uids after removing the removed ones
    const uids = network.Uids.filter(uid => !removeUids.includes(uid))

    const updatedNetwork = cloneDeep(network)
    updatedNetwork.Uids = uids
    updatedNetwork.NetworkAppliances = updatedNetwork.NetworkAppliances.filter(
      appliance => !removeUids.includes(appliance.Uid),
    )
    const response = await api.cloud('Untangle_CommandCenter', 'UpdateNetwork', {
      paramOrder: 'network addedUids removedUids syncToAppliances',
      network: JSON.stringify(updatedNetwork),
      addedUids: '',
      removedUids: removeUids,
      syncToAppliances: null,
    })
    if (response.success) {
      // update the network record
      commit('UPDATE_NETWORK', updatedNetwork)
      // remove associations between the network and these removed appliances
      dispatch(
        'appliances/updateAppliancesNetworkInfo',
        {
          uids: removeUids,
          networkInfo: null,
        },
        { root: true },
      )
      return true
    }
    return false
  },

  /**
   * Update the network rules JSON.
   *
   * @param {Function} commit
   * @param {Object}   network
   * @param {Array}    wanRules
   *
   * @return {Boolean}
   */
  async updateNetworkRulesJson({ commit }, { network, wanRules }) {
    if (!network) {
      return false
    }

    const updatedNetwork = cloneDeep(network)
    updatedNetwork.WanRulesJson = wanRules

    const response = await api.cloud('Untangle_CommandCenter', 'UpdateNetwork', {
      paramOrder: 'network addedUids removedUids syncToAppliances',
      network: JSON.stringify(updatedNetwork),
      addedUids: null,
      removedUids: null,
      syncToAppliances: null,
    })
    if (response.success) {
      // update the network record
      commit('UPDATE_NETWORK', updatedNetwork)
      return true
    }
    return false
  },

  /**
   * An optimistic update for the network rules json.  This means the store
   * will be updated before the ajax is finished.  If the ajax fails the store
   * will be reverted to it's previous state.  This is used in places to show
   * immediate updates like reordering the wan rules and not having to wait
   * for the server response.
   *
   * @param {Function} commit
   * @param {Object}   network
   * @param {Array}    wanRules
   *
   * @return {Promise<void>}
   */
  async updateNetworkRulesJsonOptimistic({ commit }, { network, wanRules }) {
    // network must be cloned and updated to change state
    const oldNetwork = cloneDeep(network)
    const updatedNetwork = cloneDeep(network)
    updatedNetwork.WanRulesJson = wanRules

    commit('UPDATE_NETWORK', updatedNetwork)

    const response = await api.cloud('Untangle_CommandCenter', 'UpdateNetwork', {
      paramOrder: 'network addedUids removedUids syncToAppliances',
      network: JSON.stringify(updatedNetwork),
      addedUids: null,
      removedUids: null,
      syncToAppliances: null,
    })

    // revert the store change if the response was not successful
    if (!response.success) {
      commit('UPDATE_NETWORK', oldNetwork)
    }
  },

  /**
   * Delete a network on the server.
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Function} network
   * @return {Promise<void>}
   */
  async deleteNetwork({ commit, dispatch }, network) {
    const response = await api.cloud('Untangle_CommandCenter', 'DeleteNetwork', {
      paramOrder: 'networkId networkName',
      networkId: network.Id,
      networkName: network.Name,
    })

    if (response.success) {
      // delete the network record
      commit('DELETE_NETWORK', network)

      // remove associations between this deleted network and it's appliances
      dispatch(
        'appliances/updateAppliancesNetworkInfo',
        {
          uids: network.Uids,
          networkInfo: null,
        },
        { root: true },
      )
    }
  },
  /**
   * Called to update the configuration on the given appliance
   *
   * @param {Function} commit
   * @param {Object}   network
   * @param {String}   selectedApplianceUid
   * @param {Array}    selectedSubnets
   * @param {boolean} automatic
   * @param {String} IP
   *
   * @return {Boolean}
   */
  async saveConfiguration(
    { commit },
    { network, selectedApplianceUid, selectedSubnets, selectedStatus, automatic, IP },
  ) {
    // network must be cloned and updated to change state
    const updatedNetwork = cloneDeep(network)
    const changedAppliance = updatedNetwork.NetworkAppliances.find(o => o.Uid === selectedApplianceUid)
    if (changedAppliance) {
      changedAppliance.SharedSubnets = selectedSubnets
      changedAppliance.VpnStatus = selectedStatus
      changedAppliance.Automatic = automatic
      changedAppliance.IP = IP
    }
    const response = await api.cloud('Untangle_CommandCenter', 'UpdateNetwork', {
      paramOrder: 'network addedUids removedUids syncToAppliances',
      network: JSON.stringify(updatedNetwork),
      addedUids: null,
      removedUids: null,
      syncToAppliances: null,
    })

    if (response.success) {
      // update the network record (also change boolean back to boolean
      if (changedAppliance) changedAppliance.VpnStatus = selectedStatus
      commit('UPDATE_NETWORK', updatedNetwork)
    }
  },

  /**
   * Called to sync the Wan rules to the given network
   *
   * @param {Function} commit
   * @param {Object}   network
   *
   * @return {Promise<void>}
   */
  async syncWanRules({}, { network }) {
    const response = await api.cloud('Untangle_CommandCenter', 'UpdateNetwork', {
      paramOrder: 'network addedUids removedUids syncToAppliances',
      network: JSON.stringify(network),
      addedUids: null,
      removedUids: null,
      syncToAppliances: true,
    })
    return response
  },

  /**
   * Calls command center to reset wan rules on all appliances in the network.  Also
   * deletes all wan rules out.
   * @param commit
   * @param network - network object being called on
   */
  async resetWanRules({ commit }, { network }) {
    network.WanRulesJson = null

    await api.cloud('Untangle_CommandCenter', 'ResetNetworkWanRules', {
      paramOrder: 'network',
      network: JSON.stringify(network),
    })

    commit('UPDATE_NETWORK', network)
  },

  /**
   * Calls command center to issue directive to delete VPN tunnels off of all appliances in the
   * specified network.  Also resets all appliances to disabled with no shared subnets
   * @param commit
   * @param network
   */
  async resetVpnTunnels({ commit }, { network }) {
    network.NetworkAppliances.forEach(appliance => {
      appliance.VpnStatus = false
      appliance.SharedSubnets = []
    })

    await api.cloud('Untangle_CommandCenter', 'ResetNetworkTunnels', {
      paramOrder: 'network',
      network: JSON.stringify(network),
    })

    commit('UPDATE_NETWORK', network)
  },
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions,
}
