<template>
  <u-page :title="$t('template_configuration')" full-height>
    <template #actions>
      <div class="d-flex flex-wrap">
        <u-btn class="mb-2" :to="{ name: 'ngfw-policies-template-configuration-add' }">
          {{ $t('add_template') }}
        </u-btn>
        <u-btn
          class="mb-2"
          :to="
            selectedTemplates.length === 1
              ? { name: 'ngfw-policies-template-configuration-id', params: { id: selectedTemplates[0].Id } }
              : ''
          "
          :disabled="selectedTemplates.length !== 1"
        >
          {{ $t('edit_template') }}
        </u-btn>
        <u-btn :disabled="selectedTemplates.length < 1" class="mb-2" @click="deleteTemplatesDialog = true">
          {{ $t('delete_templates') }}
        </u-btn>
        <u-btn
          class="mb-2"
          :disabled="
            selectedTemplates.length !== 1 ||
            !selectedTemplates[0].TargetAppliances ||
            selectedTemplates[0].TargetAppliances.length === 0
          "
          @click="checkAndRedirect"
        >
          {{ $t('sync_now') }}
        </u-btn>
      </div>
    </template>
    <u-grid
      id="policies-templates-grid"
      selection-type="multiAction"
      :no-data-message="$t('no_data')"
      :column-defs="columnDefs"
      :fetching="$store.state.appliances.fetching || $store.state.policies.fetchingTemplates"
      :row-data="templates"
      :selection.sync="selectedTemplates"
      @refresh="loadTemplates(true)"
    />
    <u-dialog
      :show-dialog="deleteTemplatesDialog"
      :title="$t('delete_templates')"
      :message="$t('confirm_delete_templates')"
      :buttons="[
        { name: $t('cancel') },
        {
          name: $t('yes'),
          handler: 'deleteTemplates',
          showProgress: true,
        },
      ]"
      @close-dialog="deleteTemplatesDialog = false"
      @deleteTemplates="deleteTemplates"
    />
  </u-page>
</template>
<script>
  import grids from '@/plugins/ut/ut-grids'
  import vuntangle from '@/plugins/vuntangle'

  export default {
    data() {
      return {
        deleteTemplatesDialog: false,
        // array holding the selected rows from the grid
        selectedTemplates: [],
        // the grid columns definitions
        columnDefs: grids.getTemplateColumnDefs(),
      }
    },
    computed: {
      /**
       * Make sure policy templates are only returned once appliances has loaded.
       *
       * @return {Array}
       */
      templates() {
        return this.$store.state.appliances.list && this.$store.state.policies.templates
          ? this.$store.state.policies.templates
          : []
      },
    },
    /**
     * Set the current navigation to template configuration
     */
    created() {
      this.$store.commit('SET_NGFW_TEMPLATES_SELECTION', this.$route.path)
      this.loadTemplates()
    },
    methods: {
      loadTemplates(force = false) {
        this.$store.dispatch('appliances/fetchAppliances', { force })
        this.$store.dispatch('policies/fetchTemplates', { force })
      },
      /**
       * Delete selected templates.
       *
       * @return {void}
       */
      async deleteTemplates() {
        // dispatch a 'delete template' action
        const response = await this.$store.dispatch('policies/deleteTemplates', this.selectedTemplates)
        if (response.success && response.data) {
          vuntangle.toast.add(this.$t('policy_delete_success'))
        } else {
          vuntangle.toast.add(this.$t('policy_delete_failure'), 'error')
        }
        this.deleteTemplatesDialog = false
        this.selectedTemplates = []
      },
      /**
       * Check if sync now could pe performed and redirect to sync-now page
       */
      checkAndRedirect() {
        if (this.selectedTemplates.length !== 1 || this.selectedTemplates[0].TargetAppliances.length === 0) {
          return
        }

        if (this.selectedTemplates[0].TargetAppliances.find(element => element === 'undefined') !== undefined) {
          vuntangle.toast.add(this.$t('target_appliance_failure_toast_message'), 'error')
          return
        }

        this.$router.push({
          name: 'ngfw-policies-template-configuration-sync-now-id',
          params: { id: this.selectedTemplates[0].Id },
        })
      },
    },
  }
</script>
