<template>
  <u-widget :title="$t('assignment_status')" enable-refresh @refresh="fetchStatus">
    <donut-chart :fetching="fetching" :series="series">
      <template #actions>
        <u-btn text class="mr-2 mb-2" @click="$router.push({ name: 'pm-assignment' })">{{ $t('assignment') }}</u-btn>
      </template>
    </donut-chart>
  </u-widget>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import DonutChart from '@/components/DonutChart.vue'
  import api from '@/plugins/ut/ut-api'

  export default {
    components: { DonutChart },

    data() {
      return {
        // the data as fetched from api
        data: undefined,
        fetching: false,
      }
    },

    computed: {
      /**
       * Compute the chart series based on the data and translate strings
       * The series is an array with a single object with the following structure highcharts expects:
       * { name: <series name>, data: [{ name: '<point name>', y: <point value>, color: <a color (optional)> }] }
       * @returns {Array} the chart series
       */
      series() {
        const data = cloneDeep(this.data)
        if (!data) return []
        // translate point names as set in `name` props
        data?.forEach(el => {
          el.name = this.$t(el.name)
        })
        return [
          {
            name: this.$t('appliances'),
            data,
            dataLabels: {
              enabled: false,
            },
          },
        ]
      },
    },

    mounted() {
      this.fetchStatus()
    },

    methods: {
      /** calls api to fetch assignemnt status for appliances */
      async fetchStatus() {
        this.fetching = true
        const response = await api.ajaxRequest('getPolicyAssignmentCount')
        this.fetching = false
        /**
         * response is expected in the form
         * {"totalMFWs":3, "allSynced":1, "outOfSync":0, "noPolicies":2}
         */
        if (response?.data) {
          this.data = [
            {
              name: 'in_sync',
              y: response.data.allSynced || 0,
              color: 'green',
            },
            {
              name: 'out_of_sync',
              y: response.data.outOfSync || 0,
              color: 'rgb(249, 168, 37)', // the default `warning` color as the out of sync button
            },
            {
              name: 'syncing',
              y: response.data.syncing || 0,
            },
            {
              name: 'unassigned',
              y: response.data.noPolicies || 0,
              color: 'lightgray',
            },
          ]
        }
      },
    },
  }
</script>
