<template>
  <v-card flat class="ma-0 text--secondary">
    <u-alert v-if="showAlert">{{ $t('delete_interface_warning') }}</u-alert>

    <!-- interfaces -->
    <div v-if="affectedInterfaces.length" class="mb-4">
      <p class="ma-0 font-weight-medium">{{ $t('following_interfaces_will_be_disabled') }}</p>
      <ul>
        <li v-for="iface in affectedInterfaces" :key="iface.interfaceId">
          <template v-if="iface.bridgedTo >= 0">
            <span v-html="$t('interface_is_bridged_to', [iface.name, intf.name])" />
          </template>
          <template v-else-if="iface.boundInterfaceId >= 0">
            <span v-html="$t('interface_is_bound_to', [iface.name, intf.name])" />
          </template>
        </li>
      </ul>
    </div>

    <!-- rules -->
    <div v-if="affectedFirewallTables || affectedWan.rules.length" class="mb-4">
      <p class="ma-0 font-weight-medium">{{ $t('following_rules_will_be_disabled') }}</p>
      <ul v-for="(table, key) in affectedFirewallTables" :key="key">
        <li
          v-for="(rule, idx) in table"
          :key="idx"
          v-html="`<strong>[${$t(key.replace(/-/g, '_'))}]</strong> ${ruleSummary(rule)}`"
        />
      </ul>
      <ul>
        <li
          v-for="(rule, idx) in affectedWan.rules"
          :key="idx"
          v-html="`<strong>[${$t('wan_rules')}]</strong> ${ruleSummary(rule)}`"
        />
      </ul>
    </div>

    <!-- wan policies -->
    <div v-if="affectedWan.policies.length" class="mb-4">
      <p class="ma-0 font-weight-medium">{{ $t('following_wan_policies_will_be_disabled') }}</p>
      <ul>
        <li v-for="policy in affectedWan.policies" :key="policy.policyId" v-html="policy.description" />
      </ul>
    </div>

    <!-- static routes -->
    <div v-if="affectedRoutes.length" class="mb-4">
      <p class="ma-0 font-weight-medium">{{ $t('following_static_routes_will_be_disabled') }}</p>
      <ul>
        <li v-for="(route, idx) in affectedRoutes" :key="idx">
          <strong>{{ route.description }}</strong> {{ route.network }}
        </li>
      </ul>
    </div>

    <h4 v-html="$t('delete_interface_confirm', [intf.name])" />
  </v-card>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import settingsMixin from './settings/settingsMixin'
  import appliance from '@/plugins/ut/ut-appliances'

  export default {
    mixins: [settingsMixin],
    props: {
      intf: { type: Object, default: () => null },
    },
    computed: {
      interfacesCopy: ({ boxSettings }) => cloneDeep(boxSettings?.network.interfaces) || [],
      fwCopy: ({ boxSettings }) => cloneDeep(boxSettings?.firewall),
      wanCopy: ({ boxSettings }) => cloneDeep(boxSettings?.wan),
      routesCopy: ({ boxSettings }) => cloneDeep(boxSettings?.routes),
      intfId() {
        return this.intf.interfaceId
      },

      showAlert() {
        return (
          this.affectedInterfaces.length > 0 ||
          this.affectedFirewallTables ||
          this.affectedWan.rules.length > 0 ||
          this.affectedWan.policies.length > 0 ||
          this.affectedRoutes.length > 0
        )
      },

      affectedInterfaces() {
        return this.interfacesCopy.filter(intf => {
          if (!intf.enabled) return false
          if (parseInt(intf.boundInterfaceId) === this.intfId || parseInt(intf.bridgedTo) === this.intfId) {
            intf.enabled = false
            return true
          }
          return false
        })
      },

      affectedFirewallTables() {
        let affectedTables = null

        Object.entries(this.fwCopy?.tables).forEach(([key, table]) => {
          table.chains?.forEach(chain => {
            if (!chain?.rules) return
            chain.rules?.forEach(rule => {
              if (!rule?.conditions) return
              rule.conditions.forEach(cond => {
                if (!rule.enabled) return
                // INTERFACE_ZONE condition check
                if (cond.type.includes('_INTERFACE_ZONE') && this.intfId === parseInt(cond.value)) {
                  rule.enabled = false // disable the rule
                  if (!affectedTables) {
                    affectedTables = {}
                  }
                  if (!affectedTables[key]) {
                    affectedTables[key] = [rule]
                  } else {
                    affectedTables[key].push(rule)
                  }
                }
              })
            })
          })
        })
        return affectedTables
      },

      affectedWan() {
        const policies = this.wanCopy.policies
        const rules = this.wanCopy.policy_chains[0]?.rules
        const affectedWan = {
          rules: [],
          policies: [],
        }
        policies?.forEach(policy => {
          if (!policy.enabled) return
          policy.interfaces?.forEach(intf => {
            if (intf.interfaceId === this.intfId) {
              policy.enabled = false
              affectedWan.policies.push(policy)
            }
          })
        })
        rules?.forEach(rule => {
          if (!rule?.conditions) return
          rule.conditions.forEach(cond => {
            if (!rule.enabled) return
            // INTERFACE_ZONE condition check
            if (cond.type.includes('_INTERFACE_ZONE') && this.intfId === parseInt(cond.value)) {
              rule.enabled = false
              affectedWan.rules.push(rule)
            }
          })
        })
        return affectedWan
      },

      affectedRoutes() {
        return this.routesCopy.filter(route => {
          if (!route.enabled) return false
          if (route.interfaceId === this.intfId) {
            route.enabled = false
            return true
          }
          return false
        })
      },
    },
    methods: {
      async action() {
        this.$emit('progress-show')

        if (this.affectedFirewallTables) {
          await appliance.sendToApplianceApi(this.uid, 'settings/firewall', this.fwCopy)
        }
        if (this.affectedWan.rules.length || this.affectedWan.policies.length) {
          await appliance.sendToApplianceApi(this.uid, 'settings/wan', this.wanCopy)
        }
        if (this.affectedRoutes.length) {
          await appliance.sendToApplianceApi(this.uid, 'settings/routes', this.routesCopy)
        }

        // remove the interface from the interfacesCopy
        const index = this.interfacesCopy.findIndex(intf => intf.interfaceId === this.intfId)
        this.interfacesCopy.splice(index, 1)

        const response = await appliance.sendToApplianceApi(
          this.uid,
          'settings/network/interfaces',
          this.interfacesCopy,
        )
        if (response.success) {
          this.$vuntangle.toast.add(this.$t('deleted_successfully', [this.$t('interface')]))
          // notify consumer of success
          this.$emit('update')
          // force a full settings load
          await this.fetchSettings()
        }

        this.$emit('progress-hide')
        this.$emit('close')
      },
    },
  }
</script>
