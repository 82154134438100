<template>
  <u-page :title="isNew ? $t('add_wan_rule') : $t('edit_wan_rule')">
    <v-list-item v-if="networkNotFound" class="pl-0 pr-0">
      <v-list-item-content>
        <u-alert error>
          {{ $t('network_not_found') }}
        </u-alert>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="ruleNotFound" class="pl-0 pr-0">
      <v-list-item-content>
        <u-alert error>
          {{ $t('rule_not_found') }}
        </u-alert>
      </v-list-item-content>
    </v-list-item>
    <!-- name field -->
    <ValidationObserver ref="validateName">
      <ValidationProvider
        v-slot="{ errors }"
        :rules="{
          required: true,
          unique: ruleNames,
        }"
      >
        <u-text-field
          v-model="rule.name"
          :label="`${$t('name')} (${$t('unique_name_for_rule')})`"
          dense
          outlined
          required
          persistent-hint
          :error-messages="errors"
        >
          <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
        </u-text-field>
      </ValidationProvider>
    </ValidationObserver>

    <h4 class="font-weight-medium px-0 mt-4 mb-2 py-0">
      {{ $t('add_criteria') }}
    </h4>

    <div class="d-flex mb-4 align-stretch">
      <ValidationObserver ref="criteriaValidator" class="d-flex flex-grow-1">
        <ValidationProvider v-slot="{ errors }" rules="required">
          <u-select
            v-model="newCriteria.type"
            :label="$t('type')"
            style="max-width: 300px"
            :error-messages="errors"
            :items="criteriaTypeArray"
            @change="changeCriteriaType"
          />
        </ValidationProvider>
        <wan-rule-criteria :key="`criteria-${criteriaKey}`" v-bind.sync="newCriteria" />
        <u-btn class="ml-4" :small="false" :disabled="networkNotFound || ruleNotFound" @click="addCriteria">
          {{ $t('add') }}
        </u-btn>
      </ValidationObserver>
    </div>
    <u-grid
      id="network-add-wan-rules"
      style="height: 300px"
      :no-data-message="noCriteria"
      :enable-refresh="false"
      :column-defs="criteriaColumnDefs"
      :fetching="$store.state.networks.fetching"
      :row-data="criteriaRowData"
      @delete-row="deleteCriteria"
    />
    <u-select v-model="rule.policy" class="my-4" :label="$t('wan_policy')" dense outlined :items="policyArray" />
    <v-card-actions class="px-0">
      <u-btn text @click="cancelButton">
        <span :class="`${$vuetify.theme.dark ? 'white--text' : ''}`">
          {{ $t('cancel') }}
        </span>
      </u-btn>
      <u-btn :disabled="networkNotFound || ruleNotFound || isFetching" @click="saveRule">
        {{ isNew ? $t('save') : $t('update') }}
      </u-btn>
    </v-card-actions>
  </u-page>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import store from '@/store'
  import { wanCriteriaTypes, wanPolicies } from '@/util/wanRules'
  import WanRuleCriteria from '@/components/networks/WanRuleCriteria'

  export default {
    components: { WanRuleCriteria },
    // fetch networks
    async beforeRouteEnter(to, from, next) {
      store.commit('SET_PAGE_LOADER', true)
      await store.dispatch('networks/fetchNetworks')
      store.commit('SET_PAGE_LOADER', false)
      next()
    },
    data() {
      return {
        networkNotFound: false,
        ruleNotFound: false,
        isFetching: false,
        createDialog: false,
        rule: {
          name: '',
          policy: 100001,
        },
        ruleNames: [],
        newCriteria: {
          type: 'APPLICATION_NAME_INFERRED',
          op: '==',
          value: '',
        },
        criteriaKey: 0,
        criteriaRowData: [],
        ruleIsSet: false,
        noCriteria: this.$t('all_traffic_local'),
        validateText: 'required',
      }
    },
    computed: {
      criteriaColumnDefs() {
        return [
          {
            headerName: this.$t('type'),
            field: 'type',
            valueGetter: ({ data }) => this.$t(wanCriteriaTypes[data.type].textKey),
          },
          {
            headerName: this.$t('operator'),
            field: 'op',
            cellRenderer: ({ value }) => this.$t(this.$vuntangle.util.operators[value]),
          },
          {
            headerName: this.$t('value'),
            field: 'value',
            cellRenderer: ({ data, value }) => {
              const values = wanCriteriaTypes[data.type].values
              return !values || Array.isArray(values) || !values[value]
                ? value
                : this.$t(wanCriteriaTypes[data.type].values[value])
            },
          },
          {
            headerName: this.$t('delete'),
            cellStyle: { display: 'flex', justifyContent: 'center' },
            cellRenderer: 'ActionButton',
            cellRendererParams: {
              label: this.$t('delete'),
              color: 'primary',
              xSmall: true,
              click: this.deleteCriteria,
            },
            pinned: 'right',
            sortable: false,
          },
        ]
      },
      /**
       * Turn the criteria type object into a text/value array for a dropdown.
       *
       * @return {Object[]}
       */
      criteriaTypeArray() {
        const results = []
        for (const value in wanCriteriaTypes) {
          results.push({ text: this.$t(wanCriteriaTypes[value].textKey), value })
        }

        return results
      },
      selectedWanCriteriaType() {
        return wanCriteriaTypes[this.newCriteria.type]
      },

      /**
       * Turn the wan policy object into a text/value array for a dropdown.
       *
       * @return {Object[]}
       */
      policyArray() {
        const results = []
        for (const value in wanPolicies) {
          results.push({ text: this.$t(wanPolicies[value]), value: parseInt(value) })
        }

        return results
      },

      /**
       * Get network based on the id parameter in the url.
       *
       * @returns {Object}
       */
      network() {
        return store.state.networks.list?.find(network => network.Id === this.$route.params.id) || null
      },

      /**
       * Check the rule being added is new.
       *
       * @return {boolean}
       */
      isNew() {
        return this.$route.params.ruleId === 'add'
      },

      /**
       * Get the existing rule name from the url
       *
       * @return {string}
       */
      existingRuleName() {
        return decodeURIComponent(this.$route.params.ruleId)
      },
    },
    watch: {
      network: {
        immediate: true,

        /**
         * If editing a rule, load the existing rule after the network has
         * loaded.  Also set the existing rule names for validation.
         *
         * @return {void}
         */
        handler() {
          // do nothing until the network is loaded
          if (!this.network) {
            return
          }

          /*
           * Only run this method once after the network is loaded to set the
           * rule and validation. When the rule is saved, the store will update,
           * causing this method to run again and is not needed.
           */
          if (this.ruleIsSet) {
            return
          }
          this.ruleIsSet = true

          // set the rule names for unique rule names validation
          this.ruleNames = this.network.WanRulesJson?.map(rule => rule.description) || []

          // do not set a rule if the user is adding a rule or the rule is already set
          if (this.isNew) {
            return
          }

          // find rule from the network
          const existingRule = this.network.WanRulesJson.find(wanRule => wanRule.description === this.existingRuleName)

          // if the rule is not found, set to display not found and return
          if (!existingRule) {
            this.ruleNotFound = true

            return
          }

          // set the rule and criteria if found
          this.rule = {
            name: existingRule.description,
            policy: existingRule.action.policy,
          }
          this.criteriaRowData = cloneDeep(existingRule.conditions)

          // remove the current rule name when editing
          this.ruleNames = this.ruleNames.filter(ruleName => ruleName !== this.rule.name)
        },
      },
      '$store.state.networks.list'() {
        this.networkNotFound = store.state.networks.list && this.network === null
      },
    },
    // UI-544: clicking on Add/Edit Wan Rule button scrolls to the middle unless we scrollTo to the top on mount
    mounted() {
      window.scrollTo(0, 0)
    },
    methods: {
      /**
       * Add a new criteria item into the datagrid.
       *
       * @return {void}
       */
      async addCriteria() {
        const isValid = await this.$refs.criteriaValidator.validate()

        if (isValid) {
          this.criteriaRowData.push({ ...this.newCriteria })
          this.clearNewCriteria()
        }
      },

      /**
       * Delete criteria from the datagrid.
       *
       * @return {Boolean}
       */
      deleteCriteria({ node }) {
        this.criteriaRowData.splice(node.id, 1)
      },

      /**
       * Change the criteria type by displaying a dynamic
       * component based on what they selected in the 'Type' dropdown.
       *
       * @param {string} The criteria type in wanRules.wanCriteriaTypes
       * @return {void}
       */
      changeCriteriaType(type) {
        this.clearNewCriteria()
        const [op] = wanCriteriaTypes[type].ops
        this.newCriteria = {
          type,
          op,
          value: '',
        }
      },

      /**
       * Clear the criteria drop downs after a criteria is added or changed.
       *
       * @return {Boolean}
       */
      clearNewCriteria() {
        this.newCriteria.op = ''
        this.newCriteria.value = ''

        // a key change is needed to force a re-render of the dynamic component
        this.criteriaKey++
      },

      /**
       * Save a rule to the server.
       *
       * @return {void}
       */
      async saveRule() {
        // validate the policy name
        const validName = await this.$refs.validateName.validate()

        // the validation errors will display, return to not add the rule
        if (!validName) {
          return
        }

        this.isFetching = true
        this.createDialog = true
        store.commit('SET_PAGE_LOADER', true)

        // loop through criteria row data to get conditions
        const conditions = []
        this.criteriaRowData.forEach(({ type, op, value }) => {
          conditions.push({ type, op, value })
        })

        // build rule for json
        const rule = {
          description: this.rule.name,
          enabled: true,
          action: {
            type: 'WAN_POLICY',
            policy: parseInt(this.rule.policy),
          },
          conditions,
        }

        // get existing rules, add/update rule (all of them are sent)
        let wanRules = []
        if (this.network.WanRulesJson !== null) {
          wanRules = cloneDeep(this.network.WanRulesJson)

          // remove the existing rule if updating
          if (!this.isNew) {
            wanRules = this.network.WanRulesJson.filter(wanRule => wanRule.description !== this.existingRuleName)
          }
        }
        wanRules.push(rule)

        // dispatch a 'update network rules' update
        const success = await store.dispatch('networks/updateNetworkRulesJson', {
          network: this.network,
          wanRules,
        })

        this.createDialog = false
        this.isFetching = false
        store.commit('SET_PAGE_LOADER', false)

        // redirect back to the network page
        if (success) {
          this.$router.push({ name: 'networks-network-id', params: { id: this.network.Id } })
        }
      },
      /**
       * When cancel button is clicked, make sure the network still exists
       * if so, go to it's page otherwise go to networks
       *
       * @return {void}
       */
      cancelButton() {
        if (this.network) {
          this.$router.replace({ name: 'networks-network-id', params: { id: this.network.Id } })
        } else {
          this.$router.replace({ name: 'networks' })
        }
      },
    },
  }
</script>
