<template>
  <rule
    v-if="rule"
    ref="rule"
    :title="$t(title)"
    :classic-view="true"
    :rule="rule.PolicyJson"
    :rule-type="serviceKey"
    :remote-data="remoteData"
    :remote-fetching="remoteFetching"
    :excluded-conditions="excludedConditions"
    :show-description="false"
    @get-remote-data="onGetRemoteData"
  >
    <template #actions="{ updatedRule }">
      <u-btn text :to="{ name: 'mfw-policies-types' }">
        <span :class="`${$vuetify.theme.dark ? 'white--text' : ''}`">
          {{ $t('back_to_list') }}
        </span>
      </u-btn>
      <u-btn :min-width="null" @click="onSave(updatedRule)">{{ $t('save') }}</u-btn>
    </template>
    <template #extra-fields>
      <!-- name and description fields -->
      <name-description :name.sync="rule.Name" :description.sync="rule.Description" description-required />
    </template>
  </rule>
</template>
<script>
  import { Rule } from 'vuntangle'
  import { ConditionType } from 'vuntangle/pm'
  import NameDescription from '@/pages/policies/mfw/policy-manager/components/NameDescription.vue'
  import vuntangle from '@/plugins/vuntangle'
  import i18n from '@/plugins/vue-i18n'
  import { mfwServices, getPolicy, savePolicy } from '@/util/mfwServices'

  export default {
    components: { Rule, NameDescription },
    // load the policy before displaying the page
    async beforeRouteEnter(to, from, next) {
      // return if no policy to load before this is an 'add' action
      if (to.params.id === 'add') {
        next()
        return
      }

      // get rule for an edit
      const response = await getPolicy(to.params.id)
      // display an error and redirect if policy of an 'edit' was not found
      if (!response.data) {
        vuntangle.toast.add(i18n.t('rule_not_found'), 'error')
        this.$router.go(-1)
        return
      }

      next(vm => {
        vm.rule = response.data
      })
    },
    data: () => ({
      rule: {
        Name: '',
        Description: '',
      },
      remoteData: { apps: null, policies: null },
      remoteFetching: false,
    }),
    computed: {
      ruleId: ({ $route }) => $route.params.id,
      serviceKey: ({ $route }) => $route.params.policyPage,
      ruleType: ({ serviceKey }) => mfwServices[serviceKey].type,
      title: ({ serviceKey }) => mfwServices[serviceKey].tkey,
      applications: ({ $store }) => $store.state.data.applications || [],
      excludedConditions: () => [ConditionType.SourceInterface, ConditionType.DestinationInterface],
    },
    methods: {
      /**
       * Populates the remoteData with required info
       * @param {*} dataType - the data that is needed to be fetched/set (`apps`, `policies`)
       */
      async onGetRemoteData(dataType) {
        // for the apps case is needed to dispatch action to fetch those
        if (dataType === 'apps') {
          this.remoteFetching = true
          await this.$store.dispatch('data/fetchApplications')
          this.remoteData.apps = this.$store.getters['data/applications']
          this.remoteFetching = false
        }
        // wan policies are already in the store, just use the dedicated getter
        if (dataType === 'policies') {
          this.remoteFetching = true
          await this.$store.dispatch('data/fetchWanPolicies')
          this.remoteData.policies = this.$store.getters['data/wanPolicies']?.sort(
            ({ description: a }, { description: b }) => a.localeCompare(b),
          )
          this.remoteFetching = false
        }
      },
      /**
       * Updates existing rule or adds a new one
       * @param {*} updatedRule - the updated rule set via #actions slot props
       */
      async onSave(updatedRule) {
        const isValid = await this.$refs.rule.validate()
        if (!isValid) return

        // than stringify it to be ready for saving
        this.rule.PolicyJson = updatedRule

        // add or update policy in the backend
        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await savePolicy(this.rule, this.ruleType)
        this.$store.commit('SET_PAGE_LOADER', false)

        if (response.success && response.data) {
          vuntangle.toast.add(this.$t('saved_successfully', [this.$t('template')]))
          // on success redirect to the rules list from the rule type
          this.$router.go(-1)
        } else {
          vuntangle.toast.add(this.$t('unable_to_save', [this.$t('template')]), 'error')
        }
      },
    },
  }
</script>
