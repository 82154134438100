import api from '@/plugins/ut/ut-api'
import vuntangle from '@/plugins/vuntangle'

/**
 * loginMessage holds the response from server if login fails.  This is outside the state function as it does
 * not get reset when going back to login.
 *
 * possible values are:
 * 'login_failed'
 * 'login_mfa_verification_required_email'
 * 'login_mfa_verification_required_totp'
 * 'login_email_verify_incomplete'
 * 'login_email_verify_resend'
 * 'mfa_verification_failed',
 * 'session_expired'
 * This values represent also locale keys (see locales/en.json)
 *
 * Hold login response for any additional information
 */
const loginMessage = null
const loginResponse = null

const getDefaultState = () => {
  return {
    /**
     * Boolean representing if user is authenticated, by checking the CCVM
     * It's important to have a separate boolean for that (instead of just checking CCVM)
     * because routing guard based on CCVM only leads to errors in redirects
     */
    isAuth: false,
  }
}

const state = () => {
  return { ...getDefaultState(), loginMessage, loginResponse }
}

const mutations = {
  RESET: state => Object.assign(state, getDefaultState()),
  SET_IS_AUTH: (state, value) => (state.isAuth = value),
  SET_LOGIN_MESSAGE: (state, value) => (state.loginMessage = value),
  SET_LOGIN_RESPONSE: (state, value) => (state.loginResponse = value),
}

const actions = {
  /**
   * Performs login api call and redirects to dashboard on success
   * @param {Function} commit
   * @param {Object}   state - The store state
   * @param {Function} dispatch
   * @param {Object}   data - The login credentials
   * @param {String}   data.userLogin - The login user email
   * @param {String}   data.password - The login password
   * @param {String}   data.mfaCode - The token used for 2-way authentication
   * @param {Boolean}  data.bypassMFA - Boolean to bypass MFA, remember user for 30 days
   */
  async login({ dispatch }, data) {
    const response = await api.ajaxRequest('logInUser', data)
    return dispatch('loginResponse', response)
  },

  /**
   * Login from an oAuth token from google or microsoft single sign on.
   *
   * @param {Function} dispatch
   * @param {Object}   data
   *
   * @returns {boolean}
   */
  async oAuthLogin({ dispatch }, data) {
    const response = await api.ajaxRequest('loginOAuthUser', data)
    return dispatch('loginResponse', response)
  },

  /**
   * Handle the login response, can come from multiple places.
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object}   response
   *
   * @returns {boolean}
   */
  loginResponse({ commit }, response) {
    if (response.data?.success && response.data?.ccViewModel) {
      // if the user logged in successfully and this is a SAML request, redirect the user
      const SAMLRequest = vuntangle.util.getUrlParameterByName('SAMLRequest')
      const RelayState = vuntangle.util.getUrlParameterByName('RelayState')
      if (SAMLRequest) {
        window.location.href =
          '/saml/idp/sso?SAMLRequest=' +
          encodeURIComponent(SAMLRequest) +
          '&RelayState=' +
          encodeURIComponent(RelayState) // redirect after successful login (need to re-encode request variables)

        return true
      }

      commit('data/SET_CC_VIEW_MODEL', response.data.ccViewModel, { root: true })
      commit('data/SET_SAPPHIRE_OPTIONS', response.data.ccViewModel.SapphireOptions, { root: true })
      commit('data/SET_MFW_POLICIES_OPTIONS', response.data.ccViewModel.MfwPolicies, { root: true })

      commit('SET_IS_AUTH', true)
      commit('SET_LOGIN_MESSAGE', null)
      commit('SET_LOGIN_RESPONSE', null)

      return true
    }

    /**
     * If for some reason we do not get a response message back, or the message is 'internal_server_error',
     * set a friendly technical difficulties response message
     */
    const responseMessage = response.data?.message
    const loginMessage =
      responseMessage && responseMessage !== 'internal_server_error' ? responseMessage : 'technical_difficulties'

    commit('SET_LOGIN_MESSAGE', loginMessage)
    commit('SET_LOGIN_RESPONSE', response.data)

    return false
  },

  /**
   * Resends verification email for the user
   * @param {Object} state - The store state
   * @param {Object} data
   * @param {String} data.userLogin - The login user email
   * @param {String} data.paramOrder - "email"
   */
  async resendVerificationEmail(state, data) {
    const response = await api.ajaxRequest('reVerifyEmail', data)
    if (response.data?.success) {
      state.commit('SET_LOGIN_MESSAGE', 'login_email_verify_resend')
    } else {
      state.commit('SET_LOGIN_MESSAGE', 'generic_error')
    }
  },

  /**
   * Resets user password based on existing email
   * @param {Object} state - The store state
   * @param {Object} data
   * @param {String} data.email - The account email
   * @param {String} data.paramOrder - "email"
   *
   * @return {String} return the response message translation string
   */
  async resetPassword(state, data) {
    const response = await api.ajaxRequest('sendPasswordRecoveryEmail', data)
    if (response.data?.success) {
      return 'reset_password_success'
    }

    return 'reset_password_failed'
  },

  /**
   * Checks if the secretkey to reset the password is still valid
   * @param {Object} data
   * @param {String} data.secretKey - The user secret key
   * @param {String} data.paramOrder - "secretKey"
   */
  async getUserBySecretKey({}, data) {
    return await api.ajaxRequest('getUserBySecretKey', data)
    // the response is handled in component, nothing to commit or handle here
  },

  /**
   * Sets a new password for the user based on secretKey
   * @param {Object} data
   * @param {String} data.secretKey - The user secret key
   * @param {String} data.newPassword - The user new password
   * @param {String} data.paramOrder - "secretKey newPassword"
   */
  async setRecoveryPasswordForUser({}, data) {
    return await api.ajaxRequest('setRecoveryPasswordForUser', data)
    // the response is handled in component, nothing to commit or handle here
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
