<template>
  <div>
    <u-dialog
      :show-dialog="show"
      :show-progress="progress"
      :title="$t('totp')"
      :width="500"
      :buttons="
        applianceIsConnected
          ? !applianceHasTotp
            ? [{ name: $t('cancel') }, { name: $t('add'), handler: 'submit' }]
            : [{ name: $t('cancel') }, { name: $t('remove'), handler: 'submit' }]
          : [{ name: $t('close'), handler: 'submit' }]
      "
      @close-dialog="show = false"
      @submit="submit"
    >
      <div v-if="applianceIsConnected">
        <p v-if="applianceHasTotp" class="font-weight-bold">
          {{ $t('appliance_remove_totp_message') }}
        </p>
        <p v-else class="font-weight-bold">{{ $t('appliance_add_totp_message') }}</p>
      </div>
      <div v-else>
        <p class="font-weight-bold">{{ $t('appliance_offline_totp_message') }}</p>
      </div>
      <totp-qr-code :qr-code-image="qrCodeImage" class="d-flex justify-start ml-auto mr-auto" />
      <u-alert v-if="!applianceIsConnected" class="mt-6 mb-n3">{{ $t('appliance_must_be_online') }}</u-alert>
    </u-dialog>
  </div>
</template>

<script>
  import vuntangle from '@/plugins/vuntangle'
  import TotpQrCode from '@/components/account/TotpQrCode'

  export default {
    components: { TotpQrCode },
    props: {
      // boolean to show/hide dialog
      value: {
        type: Boolean,
        default: false,
      },
      appliance: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        progress: false,
      }
    },
    computed: {
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
      applianceHasTotp() {
        return this.appliance.ApplianceHasTotp
      },
      applianceIsConnected() {
        return this.appliance.IsConnectedToCmd
      },
      // computed qr code to send to totp
      qrCodeImage: () => `${process.env.VUE_APP_API_URL || ''}/getAccountOTPQRCode`,
    },
    methods: {
      onCancel() {
        this.show = false
      },
      submit() {
        if (!this.applianceIsConnected) {
          this.onCancel()
        } else if (!this.applianceHasTotp) {
          this.addTotp()
        } else {
          this.removeTotp()
        }
      },
      async addTotp() {
        this.progress = true
        const response = await this.$store.dispatch('appliances/addApplianceTotp', {
          uid: this.appliance.Uid,
          paramOrder: 'uid',
        })

        // handle response
        if (response.success) {
          vuntangle.toast.add(this.$t('totp_added_successful'))
        } else {
          vuntangle.toast.add(this.$t(response.errorMessage), 'error')
        }
        this.show = false
        this.progress = false
      },
      async removeTotp() {
        this.progress = true
        const response = await this.$store.dispatch('appliances/removeApplianceTotp', {
          uid: this.appliance.Uid,
          paramOrder: 'uid',
        })

        // handle response
        if (response.success) {
          vuntangle.toast.add(this.$t('totp_removed_successful'))
        } else {
          vuntangle.toast.add(this.$t(response.errorMessage), 'error')
        }
        this.show = false
        this.progress = false
      },
    },
  }
</script>
